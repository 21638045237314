import React from "react";
import { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useStyles } from '../Propertie/PropertieStyle';
import SearchIcon from '@mui/icons-material/Search';
import {
    StyledGrid,
    StyledItemGrid,
    InfoBox,
    InfoTypography,
} from "../../Maintenance/MaintenanceStyles";
import { endpoints } from "../../../../apiEndpoints";
import axios from 'axios';
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../../utils/Loader";





const PropertyMaintenance = ({ unitId }) => {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const [maintenanceRequests, setMaintenanceRequests] = useState(null);
    const [loading, setLoading] = useState(true);




    const getmaintenanceRequests = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.getmaintenanceRequests}?priority=&id&unit_id=${unitId}`, {
                headers: { Authorization: `Token ${token}` }
            });
            setMaintenanceRequests(response.data.data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getmaintenanceRequests();
    }, [])




    const navigate = useNavigate();

    const handleCardClick = (request) => {
        navigate('/admin/maintenance-status', {
            state: {
                id: request.id,
                ...request
            }
        });
    };




    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Unit History
                </Typography>
            </Box>

            {loading ? (
                <Box
                    display={"flex"}
                    height={"100vh"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Loader />
                </Box>
            ) : (
                <StyledGrid container xs={12} style={{ margin: "0px 0px 0px 0px" }}>
                    {maintenanceRequests?.map((item, index) => (
                        <StyledItemGrid key={index} item xs={12} style={{ padding: "0px 0px 0px 0px" }}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: "Outfit",
                                        fontWeight: "500",
                                        fontSize: { xs: "15px", sm: "15px", md: "20px" },
                                    }}
                                >
                                    {item.created_at.split("T")[0]}
                                </Typography>
                            </Box>
                            <InfoBox  >
                                <InfoTypography onClick={() => handleCardClick(item)}>
                                    {item.name}
                                </InfoTypography>
                            </InfoBox>
                        </StyledItemGrid>
                    ))}
                </StyledGrid>


            )}
        </>
    )
}


export default PropertyMaintenance;