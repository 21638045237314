import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useStyles } from "./MaintenanceStyles";
import MaintenanceRequest from "./MaintenanceRequest";
import ComplaintsHistory from "./ComplaintsHistory";
import MaintenanceForm from "./MaintenanceForm";
import ComplaintForm from "./ComplaintForm";
import { useLocation, useNavigate } from "react-router-dom";


const Maintenance = () => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState("Maintenance Requests");
  const navigate = useNavigate();
  const location = useLocation();
  const passedState = location.state;

  useEffect(()=>{

    if(passedState && passedState.navigateTo){
      setSelectedOption(passedState.navigateTo)
    }
  },[passedState])

  const handleTypographyClick = (option) => {
    setSelectedOption(option);
  };

  const handleAddNew = () => {
    if (selectedOption === "Maintenance Requests") {
      navigate("/tenant/maintenance-form");
    } else if (selectedOption === "Complaints") {
      navigate("/tenant/maintenance/complaint-form");
    } else {
    }
  };
  return (
    <>
      <Box
        className={classes.mainBox}
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "24px",
          }}
        >
          {selectedOption === "Complaints"
            ? "Complaint History"
            : "Maintenance"}
        </Typography>
        <Button
          sx={{
            width: { xs: "30%", md: "12%" },
            height: "2.5rem",
            borderRadius: "0.5rem",
            backgroundColor: "#B81918",
            color: "#ffff",
            fontFamily: "Outfit",
            fontSize: "16px",
            lineHeight: "18px",
            fontWeight: "400",
            "&:hover": {
              backgroundColor: "#B81918", // Same color as default to "unset" hover
              boxShadow: "none", // Remove any shadow on hover
            },
          }}
          onClick={handleAddNew}
        >
          Add new
        </Button>
      </Box>
      <Box className={classes.maintenanceComplaintBox}>
        <Typography
          sx={
            selectedOption === "Maintenance Requests"
              ? {
                  fontFamily: "Outfit",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "20px",
                  cursor: "pointer",
                  borderBottom: "2px solid #B81918",
                  color: "#B81918",
                }
              : {
                  fontFamily: "Outfit",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "20px",
                  cursor: "pointer",
                  color: "black",
                }
          }
          onClick={() => handleTypographyClick("Maintenance Requests")}
        >
          Maintenance Requests
        </Typography>
        <Typography
          sx={
            selectedOption === "Complaints"
              ? {
                  fontFamily: "Outfit",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "20px",
                  cursor: "pointer",
                  borderBottom: "2px solid #B81918",
                  color: "#B81918",
                }
              : {
                  fontFamily: "Outfit",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "20px",
                  cursor: "pointer",
                  color: "balck",
                }
          }
          onClick={() => handleTypographyClick("Complaints")}
        >
          Complaints
        </Typography>
      </Box>
      {selectedOption === "Maintenance Requests" ? (
        <MaintenanceRequest />
      ) : (
        <ComplaintsHistory />
      )}
    </>
  );
};

export default Maintenance;
