import React from "react";
import { Grid, Box, Typography, Hidden } from "@mui/material";
import { TextField, Button, Link, Container } from "@mui/material";
import { useStyles } from "./LoginStyle";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Code from './Code';
import axios from "axios";
import toast from "react-hot-toast";
import { endpoints } from "../apiEndpoints";


const ForgetPassword = () => {
    const classes = useStyles();
    const [email, setemail] = useState({
        email: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setemail((preData) => ({
            ...preData,
            [name]: value,
        }));
    };


    const [open, setopen] = useState(false);

    const handleopen = () => {
        setopen(true)
    }
    if (open) {
        return < Code email={email} />
    }

    console.log("this main forget", email)


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(endpoints.ForgetPassword, email);
            if (response == 200) {
                toast.success(response.data.message);
            }
            handleopen();

        } catch (error) {
            toast.error(error.response.data.message);
        }


    }

    return (
        <>
            <Grid container style={{ margin: "0px" }}>
                <Grid item xs={12} sm={6} md={6}>
                    <Box className={classes.root}>
                        <Box className={classes.image}>
                            <img
                                src="/image/logo.png"
                                alt="logo pic"
                                style={{ width: "100px", height: "80px" }}
                            />
                        </Box>
                        <Box className={classes.typogone}>
                            <Typography
                                style={{
                                    font: "Raleway",
                                    fontSize: "15px",
                                    lineHeight: "23.48px",
                                    fontWeight: 400,
                                    color: "rgba(77, 77, 77, 1)",
                                    margin: "5px 0px 0px 13px",
                                }}
                            >
                                Welcome to
                            </Typography>
                        </Box>
                        <Box className={classes.typogtwo}>
                            <Typography
                                style={{
                                    font: "Raleway",
                                    fontSize: "18px",
                                    lineHeight: "48px",
                                    fontWeight: 500,
                                    color: "rgba(6, 6, 6, 1)",
                                }}
                            >
                                Capstone PMS
                            </Typography>
                        </Box>
                        <Box className={classes.typogthree}>
                            <Typography
                                style={{
                                    font: "Raleway",
                                    fontSize: "16px",
                                    lineHeight: "30px",
                                    fontWeight: 400,
                                    color: "rgba(115, 118, 121, 1)",
                                }}
                            >
                                Forget Password
                            </Typography>
                        </Box>
                        <Box className={classes.formcontrol}>
                            <Container component="main" sx={{ width: "50ch" }}>
                                <form onSubmit={handleSubmit}>
                                    <Typography
                                        style={{
                                            font: "Raleway",
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            lineHeight: "18.78px",
                                            color: "rgba(115, 118, 121, 1)",
                                        }}
                                    >
                                        Email
                                    </Typography>
                                    <TextField
                                        className={classes.emailinput}
                                        margin="normal"
                                        required
                                        id="email"
                                        placeholder="email"
                                        autoComplete="email"
                                        size="small"
                                        name="email"
                                        value={email.email}
                                        onChange={handleChange}
                                    />
                                    <Button
                                        className={classes.bph}
                                        type="submit"
                                        variant="contained"
                                        style={{
                                            margin: "3% 0% 0% 0%",
                                            fontSize: "15px",
                                            fontWeight: 500,
                                            lineHeight: "23.48px",
                                            font: "Raleway",
                                            borderRadius: "6px",
                                            height: "43px",
                                            backgroundColor: "rgba(184, 25, 24, 1)",
                                            textTransform: "none",
                                        }}
                                        
                                    >
                                        Next
                                    </Button>


                                </form>
                            </Container>
                        </Box>
                    </Box>
                </Grid>

                <Hidden smDown>
                    <Grid
                        item
                        lg={6}
                        xl={6}
                        md={6}
                        style={{
                            padding: "0px",
                            margin: "0px",
                            backgroundImage: 'url("/image/login.jpg")',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            // height: '100%',  // Adjust this to '100vh' or '100%' depending on your layout needs
                            minHeight: "100vh",
                        }}
                    ></Grid>
                </Hidden>
            </Grid>
        </>
    );
};

export default ForgetPassword;
