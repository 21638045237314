import React, { useEffect } from "react";
import { useState } from "react";
import { Box, Typography, Button, Grid, Tabs, Tab } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useStyles } from "../../../AdminComponent/Pages/Dashboard/DashboardStyle";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { UserTypesNum } from "../../../enums/UserTypes";
import DateFormatter from "../../../utils/DateFormatter";
import { Loader } from "../../../utils/Loader";

const Notification = () => {
  const classes = useStyles();
  const [checked, setChecked] = useState(true);
  const [alerts, setAlerts] = useState(null);
  const [loading, setLoading] = useState(true);



  const handleSwitch = (event) => {
    setChecked(event.target.checked);
  };

  const landlordNotifications = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        `${endpoints.getNotifications}?user_type=${UserTypesNum.landlord
        }&user_id=${localStorage.getItem("id")}`,
        requestOptions
      );
      setAlerts(response.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    landlordNotifications();
  }, []);



  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          All Notification
        </Typography>
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', color: "rgba(184, 25, 24, 1)" }}>
                        <label htmlFor="mute-notifications" style={{ marginRight: '8px' }}>Mute All Notifications</label>
                        <Switch
                          defaultChecked color="warning"
                            id="mute-notifications"
                            checked={checked}
                            onChange={handleSwitch}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                </Box> */}
      </Box>


      {loading ? (
        <Box
          display={"flex"}
          height={"100vh"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Loader />
        </Box>
      ) : alerts && alerts?.length > 0 ? (
        <Grid container height={{ sm: "30rem", xs: "50rem" }} overflow={"auto"}>
          <Grid item xs={12} sm={12} md={12}>
            <Box>
              {alerts?.map((alert) => (
                <Box
                  key={alert.id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #EAECF0",
                    margin: "15px 0px 0px 0px",
                    borderRadius: "5.8px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className={classes.our}
                  >
                    <Avatar alt="Remy Sharp" src={"/image/Notifi1.jpg"} />
                    <Typography
                      style={{
                        color: "rgba(26, 31, 54, 1)",
                        font: "Outfit",
                        fontSize: "14px",
                        lineHeight: "22px",
                        fontWeight: 600,
                        color: "#1A1F36",
                        margin: "0px 0px 0px 10px",
                      }}
                    >
                      {alert?.title || "Untitled Notification"}
                      <Typography
                        style={{
                          color: "rgba(26, 31, 54, 1)",
                          fontFamily: "Outfit",
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: 500,
                        }}
                      >
                        {alert?.body || "Content not available"}
                      </Typography>
                      <Typography
                        style={{
                          color: "rgba(29, 41, 57, 0.52)",
                          fontFamily: "Outfit",
                          fontSize: "14px",
                          lineHeight: "22px",
                          fontWeight: 500,
                        }}
                      >
                        {DateFormatter(alert?.created_at)}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} marginTop={'5rem'}>
          <Typography style={{
            color: "rgba(26, 31, 54, 1)",
            fontFamily: "Outfit",
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 600,
            color: "#1A1F36",
            margin: "0px 0px 0px 10px",
          }}>No notifications</Typography>
        </Box>
      )}
    </>
  );
};

export default Notification;
