import React, { useState } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import { useStyles } from "../Properties/Propertie/PropertieStyle";
import { newIncomeTextfield, } from './DocumentStyle';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { rentStatus, soaStatus } from "../../../enums/UserTypes";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";


const RequestDetail = () => {
    const classes = useStyles();
    const location = useLocation();
    const {SOA} = location.state || {}
    const [data,setData] = useState({
        property:`${SOA.property?.ref_id} - ${SOA.property?.title}` || 'N/A',
        dateRange:`${SOA.start_date} - ${SOA.end_date}` || 'N/A',
        description:SOA.description || 'N/A',
        
    })
    const [comments,setComments] = useState(SOA.additional_comments || null)
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate()

    const updateSOA = async (approval_status) =>{
        try{
            setLoading(true)
            const payload = {
                additional_comments : comments,
                admin_approval: approval_status
            }
            const requestOptions = {
                headers:{
                    Authorization: `token ${localStorage.getItem('token')}`
                }
            }
            const response = await axios.patch(`${endpoints.financeSOA}?id=${SOA.id}`,payload,requestOptions)
            toast.success(response.data.message)

        }catch(error){
            toast.error(error.response.data.message)

        }finally{
            setLoading(false)
            navigate('/admin/documents')

        }
    }
    return (
        <>
            <Box >
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)"
                }}>
                    Request Details
                </Typography>
            </Box>
            {loading ? (<Box display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"><Loader/></Box>):(<>
            <Grid container spacing={2} mt={3}>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Property</Typography>
                    <TextField className={classes.Input} fullWidth variant="outlined" placeholder='Ramada Plaza'
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                        sx={newIncomeTextfield}
                        value={data.property}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Date Range</Typography>
                    <TextField className={classes.Input} fullWidth variant="outlined" placeholder='Jan 2024 - Jun 2024'
                        style={{ margin: "0px 0px 25px 0px" }}
                        sx={newIncomeTextfield}
                        value={data.dateRange}
                        disabled

                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={12} md={8}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Description</Typography>
                    <TextField className={classes.Input} multiline rows={7} fullWidth variant="outlined" placeholder='any description '
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                        sx={newIncomeTextfield}
                        value={data.description}
                        disabled
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={12} md={8}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Additional Comments</Typography>
                    <TextField className={classes.Input} multiline rows={4} fullWidth variant="outlined" placeholder='Add your notes here'
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                        sx={newIncomeTextfield}
                        value={data.comments}
                        onChange={(e)=>{setComments(e.target.value)}}
                    />
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {SOA.admin_approval === rentStatus.pending.value ? (<>
                <Button style={{
                    border: "1px solid rgba(154, 25, 21, 1)",
                    color: "rgba(154, 25, 21, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 20px 50px 0px"
                }}
                    variant="outlined"
                    onClick={()=>{updateSOA(rentStatus.disapproved.value)}}

                >Disapprove</Button>
                <Button style={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 0px 50px 0px"

                }}
                    variant="contained"
                    onClick={()=>{updateSOA(rentStatus.approved.value)}}

                >Approve</Button>
                </>):SOA.admin_approval === rentStatus.approved.value ? (
                    <Button style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 0px"
    
                    }}
                        variant="contained"
                        onClick={()=>{navigate('/admin/documents')}}
    
                    >Approved</Button>
                ): SOA.admin_approval === rentStatus.disapproved.value ? (<Button style={{
                    border: "1px solid rgba(154, 25, 21, 1)",
                    color: "rgba(154, 25, 21, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 20px 50px 0px"
                }}
                    variant="outlined"
                    onClick={()=>{navigate('/admin/documents')}}

                >Disapprove</Button>):('')}
            </Box>
            </>)}
        </>
    )
}


export default RequestDetail;