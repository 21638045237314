import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import { useStyles } from "../Properties/Propertie/PropertieStyle";
import { useLocation } from "react-router-dom";
import { endpoints } from "../../../apiEndpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";



const AddRent = () => {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    const { id } = state || {};

    const [rentdata, setrentdata] = useState({
        amount: '',
        property: '',
        description: '',
        additional_comments: '',
        admin_approval: '',
        ref_id: ''

    });

    const [loading, setLoading] = useState(true);

    const Getrent = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
                `${endpoints.financeRentReimbursement}?id=${id}`,
                {
                    headers: { Authorization: `Token ${token}` },
                }
            );
            if (response.data.data.length > 0) {
                const data = response.data.data[0];
                setrentdata({
                    amount: data.amount || '',
                    property: data.property || '',
                    description: data.description || '',
                    admin_approval: data.admin_approval || '',
                    additional_comments: data.additional_comments || '',
                    ref_id: data.ref_id || ''
                });
            }
        } catch (error) {
            console.error("Error fetching rent data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        Getrent();
    }, [id]);

    const Updaterequest = async (adminApprovalValue) => {
        try {
            const token = localStorage.getItem('token');
            await axios.patch(`${endpoints.financeRentReimbursement}?id=${id}`, {
                additional_comments: rentdata.additional_comments,
                admin_approval: adminApprovalValue,
            }, {
                headers: { Authorization: `Token ${token}` }
            });
            handleopen();
        } catch (error) {
            console.error("Error updating request:", error);
        }
    };




    const handleopen = () => {
        navigate("/admin/rent");
    };

    return (
        <>

            <>
                <Box>
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontSize: "24px",
                        lineHeight: "24px",
                        fontWeight: 500,
                        color: "rgba(0, 0, 0, 1)",
                        display: "flex"
                    }}>
                        Reimbursement Request
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontSize: "15px",
                                lineHeight: "24px",
                                fontWeight: 500,
                                color: "rgba(0, 0, 0, 1)",

                            }}
                        >
                            {`(${rentdata.ref_id})`}
                        </Typography>
                    </Typography>
                </Box>

                <Grid container spacing={2} mt={3}>
                    <Grid item xs={12} md={4}>
                        <Typography style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}>Reimbursement Amount</Typography>
                        <TextField className={classes.Input} fullWidth variant="outlined"
                            value={rentdata.amount}
                            style={{ margin: "0px 0px 25px 0px" }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}>Property</Typography>
                        <TextField className={classes.Input} fullWidth variant="outlined"
                            value={rentdata.property?.ref_id || ''}
                            style={{ margin: "0px 0px 25px 0px" }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Typography style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}>Description</Typography>
                        <TextField className={classes.Input} multiline rows={4} fullWidth variant="outlined" placeholder='Description here'
                            value={rentdata.description}
                            style={{ margin: "0px 0px 25px 0px" }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <Box>
                    {rentdata?.admin_approval == 1 && (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <Typography style={{
                                        fontFamily: "Outfit",
                                        fontWeight: 400,
                                        fontSize: "18px",
                                        lineHeight: "18px",
                                        color: "rgba(0, 0, 0, 1)",
                                        margin: "0px 0px 15px 0px"
                                    }}>Additional Comments</Typography>
                                    <TextField
                                        multiline
                                        rows={4}
                                        fullWidth
                                        className={classes.Input}
                                        variant="outlined"
                                        placeholder='Add your notes here'
                                        value={rentdata.additional_comments}
                                        onChange={(e) => setrentdata({ ...rentdata, additional_comments: e.target.value })}
                                        style={{ margin: "0px 0px 25px 0px" }}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {rentdata?.admin_approval == 2 && (
                        <>
                            <Box >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <Typography style={{
                                            fontFamily: "Outfit",
                                            fontWeight: 400,
                                            fontSize: "18px",
                                            lineHeight: "18px",
                                            color: "rgba(0, 0, 0, 1)",
                                            margin: "0px 0px 15px 0px"
                                        }}>Additional Comments</Typography>
                                        <TextField
                                            multiline
                                            rows={4}
                                            fullWidth
                                            className={classes.Input}
                                            variant="outlined"
                                            placeholder='Add your notes here'
                                            value={rentdata.additional_comments}
                                            style={{ margin: "0px 0px 25px 0px" }}
                                        />
                                    </Grid>
                                </Grid>

                            </Box>
                        </>
                    )}
                    {rentdata?.admin_approval == 3 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Typography style={{
                                    fontFamily: "Outfit",
                                    fontWeight: 400,
                                    fontSize: "18px",
                                    lineHeight: "18px",
                                    color: "rgba(0, 0, 0, 1)",
                                    margin: "0px 0px 15px 0px"
                                }}>Additional Comments</Typography>
                                <TextField
                                    multiline
                                    rows={4}
                                    fullWidth
                                    className={classes.Input}
                                    variant="outlined"
                                    placeholder='Add your notes here'
                                    value={rentdata.additional_comments}
                                    style={{ margin: "0px 0px 25px 0px" }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Box>

                <Box>
                    {rentdata?.admin_approval == 1 && (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button style={{
                                    border: "1px solid rgba(154, 25, 21, 1)",
                                    color: "rgba(154, 25, 21, 1)",
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    fontWeight: 500,
                                    textTransform: "none",
                                    width: "130px",
                                    height: "40px",
                                    margin: "20px 20px 50px 0px"
                                }}
                                    variant="outlined"
                                    onClick={() => Updaterequest(3)}
                                >Disapproved</Button>
                                <Button style={{
                                    backgroundColor: "rgba(184, 25, 24, 1)",
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    fontWeight: 500,
                                    textTransform: "none",
                                    width: "130px",
                                    height: "40px",
                                    margin: "20px 0px 50px 0px"
                                }}
                                    variant="contained"
                                    onClick={() => Updaterequest(2)}
                                >Approved</Button>
                            </Box>
                        </>
                    )}
                    {rentdata?.admin_approval == 2 && (
                        <>
                            <Box >
                                <Button style={{
                                    backgroundColor: "rgba(184, 25, 24, 1)",
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    fontWeight: 500,
                                    textTransform: "none",
                                    width: "130px",
                                    height: "40px",
                                    margin: "20px 0px 50px 0px"
                                }}
                                    variant="contained"

                                >Approved</Button>

                            </Box>
                        </>
                    )}
                    {rentdata?.admin_approval == 3 && (
                        <Button style={{
                            border: "1px solid rgba(154, 25, 21, 1)",
                            color: "rgba(154, 25, 21, 1)",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            margin: "20px 20px 50px 0px"
                        }}
                            variant="outlined"

                        >Disapproved</Button>
                    )}
                </Box>
            </>

        </>
    );
};

export default AddRent;
