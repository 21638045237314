import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {};
});

export const mainTypography = {
  fontFamily: "Outfit",
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "24px",
};

export const tableHeadCell = {
  backgroundColor: "#ADADAD30",
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "18px",
  borderRight: "1px solid #ADADAD50",
};

export const tableBodyCell = {
  fontFamily: "Outfit",
  fontSize: "12px",
  lineHeight: "15px",
  fontWeight: "400",
  color: "#737679",
};
