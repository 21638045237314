import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material";
import {
  useStyles,
  mainTypography,
  createBtn,
  CustomTextField,
  tableHeadCell,
  tableBodyCell,
  aplyfilter,
} from "./ExpenseStyles";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { Loader } from "../../../utils/Loader";


function Expenses() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(null);
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([
    {
      property: "Property Name",
      landlord: "Landlord",
      propertyId: "0010",
      unit: "305",
      payee: "Any Name",
      method: "Online Transfer",
      date: "06-03-2024",
      category: "Category",
      subCat: "Sub Category",
      description: "Description",
      amount: "3000 AED",
      notes: "Note",
    },
    {
      property: "Property Name",
      landlord: "Landlord",
      propertyId: "0010",
      unit: "305",
      payee: "Any Name",
      method: "Online Transfer",
      date: "06-03-2024",
      category: "Category",
      subCat: "Sub Category",
      description: "Description",
      amount: "3000 AED",
      notes: "Note",
    },
    {
      property: "Property Name",
      landlord: "Landlord",
      propertyId: "0010",
      unit: "305",
      payee: "Any Name",
      method: "Online Transfer",
      date: "06-03-2024",
      category: "Category",
      subCat: "Sub Category",
      description: "Description",
      amount: "3000 AED",
      notes: "Note",
    },
    {
      property: "Property Name",
      landlord: "Landlord",
      propertyId: "0010",
      unit: "305",
      payee: "Any Name",
      method: "Online Transfer",
      date: "06-03-2024",
      category: "Category",
      subCat: "Sub Category",
      description: "Description",
      amount: "3000 AED",
      notes: "Note",
    },
    {
      property: "Property Name",
      landlord: "Landlord",
      propertyId: "0010",
      unit: "305",
      payee: "Any Name",
      method: "Online Transfer",
      date: "06-03-2024",
      category: "Category",
      subCat: "Sub Category",
      description: "Description",
      amount: "3000 AED",
      notes: "Note",
    },
    {
      property: "Property Name",
      landlord: "Landlord",
      propertyId: "0010",
      unit: "305",
      payee: "Any Name",
      method: "Online Transfer",
      date: "06-03-2024",
      category: "Category",
      subCat: "Sub Category",
      description: "Description",
      amount: "3000 AED",
      notes: "Note",
    },
    {
      property: "Property Name",
      landlord: "Landlord",
      propertyId: "0010",
      unit: "305",
      payee: "Any Name",
      method: "Online Transfer",
      date: "06-03-2024",
      category: "Category",
      subCat: "Sub Category",
      description: "Description",
      amount: "3000 AED",
      notes: "Note",
    },
    {
      property: "Property Name",
      landlord: "Landlord",
      propertyId: "0010",
      unit: "305",
      payee: "Any Name",
      method: "Online Transfer",
      date: "06-03-2024",
      category: "Category",
      subCat: "Sub Category",
      description: "Description",
      amount: "3000 AED",
      notes: "Note",
    },
  ]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const getExpenses = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      let url = endpoints.financeExpense;
      const response = await axios.get(url, requestOptions);
      setExpenses(response.data.data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getExpenses();
  }, []);

  return (
    <>
      <Box className={classes.mainBox}>
        <Typography sx={mainTypography}>Expenses</Typography>
        <Button
          sx={createBtn}
          onClick={() => navigate("/finance/expenses/create-expense")}
        >
          Create New
        </Button>
      </Box>
      <Box className={classes.searchFieldBox}>
        <TextField
          variant="outlined"
          placeholder="Search by Landlord"
          value={searchValue}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={CustomTextField}
        />
        <Button sx={aplyfilter}>Apply Filter</Button>
      </Box>


      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : expenses.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <Box marginTop={"2rem"}>
          <TableContainer sx={{ height: "65vh", overflowX: "auto" }}>
            <Table sx={{ border: "1px solid #EFEFEF", minWidth: "100rem" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeadCell}>Property</TableCell>
                  <TableCell sx={tableHeadCell}>
                    {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        // marginRight: 1,
                      }}
                    >
                      <IconButton
                        onClick={handleSortAscending}
                        sx={{
                          padding: "0",
                          height: "8px",
                          minHeight: "8px",
                        }}
                      >
                        <ArrowDropUpIcon
                          sx={{ fontSize: "30px", color: "black" }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={handleSortDescending}
                        sx={{ padding: "0", height: "8px", minHeight: "8px" }}
                      >
                        <ArrowDropDownIcon
                          sx={{ fontSize: "30px", color: "black" }}
                        />
                      </IconButton>
                    </Box>
                    Landlord Name
                  </Box> */}
                    Landlord Name
                  </TableCell>
                  <TableCell sx={tableHeadCell}>Sub Property Id</TableCell>
                  <TableCell sx={tableHeadCell}>Unit</TableCell>
                  <TableCell sx={tableHeadCell}>Payee</TableCell>
                  <TableCell sx={tableHeadCell}>Method</TableCell>
                  <TableCell sx={tableHeadCell}>Date</TableCell>
                  <TableCell sx={tableHeadCell}>Category</TableCell>
                  <TableCell sx={tableHeadCell}>Sub Category</TableCell>
                  <TableCell sx={tableHeadCell}>Description</TableCell>
                  <TableCell sx={tableHeadCell}>Amount</TableCell>
                  <TableCell sx={tableHeadCell}>Notes</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {expenses?.map((expense) => (
                  <TableRow key={expense.id}>
                    <TableCell sx={tableBodyCell}>
                      {expense.property?.title || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {expense.landlord?.first_name || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {expense.sub_property?.ref_id || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {expense.unit?.ref_id || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {expense.payee || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {expense.method || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {expense.date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {expense.category || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {expense.sub_category}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {expense.description || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {expense.amount || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {expense.notes || "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
}

export default Expenses;
