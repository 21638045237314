import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";


export const useStyles = makeStyles(() => {
    const theme = useTheme();
    return {
        mianroot: {
            border: "1px solid #F10A0A26",
            borderRadius: "15px",
            margin: "15px 0px 10px 0px",
            height: "100%",
            
        },
    };
});



