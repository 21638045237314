import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Menu,
  IconButton,
  Modal,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  FormControl,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { newIncomeTextfield } from "./ContractStyle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { UserTypesNum } from "../../../enums/UserTypes";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import toast from "react-hot-toast";
import Contracts from "./Contracts";

const AddTentant = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfLetter, setPdfLetter] = useState(null);
  const [contractId, setContractId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pdfError, setPdfError] = useState(null);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [numRows, setNumRows] = useState(null);
  const [rows, setRows] = useState([]);
  const [depositedAmount, setDepositedAmount] = useState({
    payment_date: null,
    amount: null,
    vat: null,
    cheque_number: null,
    bank: null,
    type: "2",
  });

  const [tenantListingData, setTenantListingData] = useState();
  const [propertyListing, setPropertyListing] = useState(null);
  const [unitListing, setUnitListing] = useState(null);

  const [tenantObj, setTenantObj] = useState(null);
  const [propertyObj, setPropertyObj] = useState(null);
  const [units, setUnits] = useState([""]);
  const [tenantCreated, setTenantCreated] = useState(false);

  const [tenantData, setTenantData] = useState({
    startDate: null,
    endDate: null,
    lesse: null,
    nationality: null,
    address: null,
    email: null,
    passportNo: null,
    uidNo: null,
    passportExpiry: null,
    emergencyContact: null,
    emiratesId: null,
  });

  const [propertyDetails, setPropertyDetails] = useState({
    // unitNo: units,
    prpNo: null,
    propertyTitle: null,
    rooms: null,
    parking: null,
    period: null,
    propertyType: null,
    rentValue: null,
    // payments: numRows,
  });

  const [clausesData, setClausesData] = useState({
    article1: null,
    article2: null,
    article3: null,
    article4: null,
    article5: null,
  });

  const addUnits = () => {
    setUnits([...units, ""]);
  };

  const handleDatesChange = (event) => {
    const { name, value } = event.target;
    setDates(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'startDate' && value && numRows && propertyDetails.rentValue) {
      const newRows = calculatePDCData(
        parseFloat(propertyDetails.rentValue),
        numRows,
        value
      );
      setRows(newRows);
    }
  };
  const handleUnitsChange = (index, value) => {
    const updatedUnits = units.map((unit, i) => {
      if (i === index) {
        return value;
      }
      return unit;
    });
    setUnits(updatedUnits);
  };

  const handleTenantDataChange = (e) => {
    const { name, value } = e.target;

    setTenantData({
      ...tenantData,
      [name]: value,
    });
  };

  const handleClausesDetailChange = (e) => {
    const { name, value } = e.target;
    setClausesData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePropertyDetailsChange = (e) => {
    const { name, value } = e.target;
    setPropertyDetails(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'rentValue' && value && numRows && dates.startDate) {
      const newRows = calculatePDCData(
        parseFloat(value),
        numRows,
        dates.startDate
      );
      setRows(newRows);
    }
  };

  const handleNumRowsChange = (event) => {
    const number = parseInt(event.target.value, 10);
    setNumRows(number);

    if (number && propertyDetails.rentValue && dates.startDate) {
      const newRows = calculatePDCData(
        parseFloat(propertyDetails.rentValue),
        number,
        dates.startDate
      );
      setRows(newRows);
    } else {
      const emptyRows = Array.from({ length: number }, (_, i) => ({
        payment_number: i + 1,
        payment_date: "",
        amount: "",
        vat: "",
        cheque_number: "",
        bank: "",
        type: "",
      }));
      setRows(emptyRows);
    }
  };

  const handleInputChange = (rowIndex, field) => (event) => {
    if (rowIndex === -1) {
      setDepositedAmount((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    } else {
      const newRows = rows.map((row, rIndex) =>
        rowIndex === rIndex ? { ...row, [field]: event.target.value } : row
      );
      setRows(newRows);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePdfLoadError = (error) => {
    setPdfError(error);
  };

  const tenantListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };

      const response = await axios.get(
        `${endpoints.getUserListing}?user_type=${UserTypesNum.tenant}`,
        requestOptions
      );
      setTenantListingData(response.data.data);
    } catch (error) { }
  };

  const getPropertyListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        endpoints.getPropertiesListing,
        requestOptions
      );
      setPropertyListing(response.data.data);
    } catch (error) { }
  };

  const getUnitListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      let url;
      if (propertyObj) {
        url = `${endpoints.getUnitsListing}?property=${propertyObj.id}`;
      } else {
        url = endpoints.getUnitsListing;
      }
      const response = await axios.get(url, requestOptions);
      setUnitListing(response.data.data);
    } catch (error) {
      setUnitListing()
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const filteredUnits = units.filter(unit => unit !== "");

      const payload = {
        start_date: dates.startDate,
        end_date: dates.endDate,
        tenant: tenantObj?.id,
        tenant_address: tenantData.address,
        tenant_emergency_contact: tenantData.emergencyContact,
        tenant_email: tenantData.email,
        tenant_emirates_id: tenantData.emiratesId,
        tenant_passport: tenantData.passportNo,
        tenant_nationality: tenantData.nationality,
        tenant_uid: tenantData.uidNo,
        passport_expiry: tenantData.passportExpiry,
        // unit: units,
        unit: filteredUnits.length > 0 ? filteredUnits : [],
        property: propertyObj?.id,
        rooms: propertyDetails.rooms,
        parking: propertyDetails.parking,
        contract_period: propertyDetails.period,
        rent_value: propertyDetails.rentValue,
        property_type: propertyDetails.propertyType,
        no_of_payments: numRows,
        article_1_clause: clausesData.article1,
        article_2_clause: clausesData.article2,
        article_3_clause: clausesData.article3,
        article_4_clause: clausesData.article4,
        article_5_clause: clausesData.article5,
        pdcs: [...rows, depositedAmount],
      };
      if (units !== "") {
        payload.unit = units
      }

      setLoading(true);
      const response = await axios.post(
        endpoints.tenantContracts,
        payload,
        requestOptions
      );
      toast.success(response.data.message);
      setPdfLetter(response.data.data.document);
      setContractId(response.data.data.id);

      setIsModalOpen(true);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const sendOfferLetter = async (draft) => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.post(
        `${endpoints.tenantContracts}send/?contract_id=${contractId}`,
        { is_draft: draft },
        requestOptions
      );
      toast.success(response.data.message);
      handleModalClose();
      setTenantCreated(true);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    tenantListing();
    getPropertyListing();
  }, []);
  useEffect(() => {
    getUnitListing();

    let propertyType =
      propertyObj?.type == "1"
        ? "Residential"
        : propertyObj?.type == "2"
          ? "Comercial"
          : propertyObj?.type == "3"
            ? "Community"
            : null;

    setPropertyDetails({
      prpNo: propertyObj?.ref_id,
      propertyTitle: propertyObj?.title,
      rooms: propertyObj?.bedrooms,
      propertyType: propertyType,
    });
  }, [propertyObj]);

  useEffect(() => {
    setTenantData({
      lesse: tenantObj?.first_name,
      email: tenantObj?.email,
      passportNo: tenantObj?.passport_no,
      emiratesId: tenantObj?.emirates_id,
    });
  }, [tenantObj]);

  useEffect(() => {
    if (numRows && propertyDetails.rentValue && dates.startDate) {
      const newRows = calculatePDCData(
        parseFloat(propertyDetails.rentValue),
        numRows,
        dates.startDate
      );
      setRows(newRows);
    }
  }, [propertyDetails.rentValue, dates.startDate]);

  const calculatePDCData = (totalAmount, numberOfPayments, startDate) => {
    if (!totalAmount || !numberOfPayments || !startDate) return [];

    const amount = Math.round(totalAmount / numberOfPayments);
    const start = new Date(startDate);

    return Array.from({ length: numberOfPayments }, (_, index) => {
      // Calculate payment date - adding months to start date
      const paymentDate = new Date(start);
      paymentDate.setMonth(paymentDate.getMonth() + index);

      return {
        payment_number: index + 1,
        payment_date: paymentDate.toISOString().split('T')[0],
        amount: amount.toString(),
        vat: "0", // You can modify this if needed
        cheque_number: "",
        bank: "",
        type: "1" // "1" represents Rental
      };
    });
  };

  return (
    <>
      {!tenantCreated ? (
        <>
          <Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              Add Contract
            </Typography>
          </Box>
          {loading && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Start Date
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  variant="outlined"
                  name="startDate"
                  value={dates.startDate}
                  onChange={handleDatesChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  End Date
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  name="endDate"
                  value={dates.endDate}
                  onChange={handleDatesChange}
                  variant="outlined"
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>
            <Box>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                <Box>
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "24px",
                      lineHeight: "24px",
                      fontWeight: 400,
                      color: "rgba(0, 0, 0, 1)",
                      margin: "30px 0px 30px 0px",
                    }}
                  >
                    Lessee details
                  </Typography>
                </Box>
              </Typography>
            </Box>

            <Grid item xs={12} md={4} lg={2}>
              <Select
                style={{
                  width: "30%",
                  height: "40px",
                  border: "1px solid rgba(239, 239, 239, 1)",
                  borderRadius: "30px",
                  padding: "0px 0px 0px 20px",
                  backgroundColor: "white",
                  appearance: "none",
                }}
                value={tenantObj || ""}
                onChange={(e) => {
                  setTenantObj(e.target.value);
                }}

                displayEmpty
              // placeholder="Select Tenant"
              >
                <MenuItem value="" disabled>
                  Select Tenant
                </MenuItem>
                {/* Add options here */}
                {tenantListingData?.map((tenant, index) => (
                  <MenuItem value={tenant} key={index}>
                    {tenant.first_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Lessee
                </Typography>
                <TextField
                  fullWidth
                  name="lesse"
                  value={tenantData.lesse}
                  onChange={handleTenantDataChange}
                  variant="outlined"
                  placeholder="Tenant Name"
                  disabled
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Nationality
                </Typography>
                <TextField
                  fullWidth
                  name="nationality"
                  value={tenantData.nationality}
                  onChange={handleTenantDataChange}
                  variant="outlined"
                  placeholder="Nationality"
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Address{" "}
                </Typography>
                <TextField
                  fullWidth
                  name="address"
                  value={tenantData.address}
                  onChange={handleTenantDataChange}
                  variant="outlined"
                  placeholder="Address"
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Email Address{" "}
                </Typography>
                <TextField
                  fullWidth
                  name="email"
                  value={tenantData.email}
                  onChange={handleTenantDataChange}
                  variant="outlined"
                  placeholder="Email"
                  sx={newIncomeTextfield}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Passport No{" "}
                </Typography>
                <TextField
                  fullWidth
                  name="passportNo"
                  value={tenantData.passportNo}
                  onChange={handleTenantDataChange}
                  variant="outlined"
                  placeholder="Passport Number"
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  UID No
                </Typography>
                <TextField
                  fullWidth
                  name="uidNo"
                  value={tenantData.uidNo}
                  onChange={handleTenantDataChange}
                  variant="outlined"
                  placeholder="UID Number"
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Emergency Contact
                </Typography>
                <TextField
                  fullWidth
                  name="emergencyContact"
                  value={tenantData.emergencyContact}
                  onChange={handleTenantDataChange}
                  variant="outlined"
                  placeholder="Emergency Contact"
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Emirates Id
                </Typography>
                <TextField
                  fullWidth
                  name="emiratesId"
                  value={tenantData.emiratesId}
                  onChange={handleTenantDataChange}
                  variant="outlined"
                  placeholder="Emirates Id"
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Passport Expiry
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  name="passportExpiry"
                  value={tenantData.passportExpiry}
                  onChange={handleTenantDataChange}
                  variant="outlined"
                  placeholder="Passport Expiry Date"
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>

            <Box>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "rgba(0, 0, 0, 1)",
                  margin: "30px 0px 30px 0px",
                }}
              >
                Property Details
              </Typography>
            </Box>

            <Grid item xs={12} md={4} lg={2}>
              <Select
                style={{
                  width: "30%",
                  height: "40px",
                  border: "1px solid rgba(239, 239, 239, 1)",
                  borderRadius: "30px",
                  padding: "0px 0px 0px 20px",
                  backgroundColor: "white",
                  appearance: "none",
                }}
                value={propertyObj || ""}
                onChange={(e) => {
                  setPropertyObj(e.target.value);
                }}

                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Property
                </MenuItem>
                {propertyListing?.map((property, index) => (
                  <MenuItem value={property} key={index}>
                    {`${property.ref_id} - ${property.title}`}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              {units.map((unit, index) => {
                return (
                  <Grid item xs={12} md={12} key={index}>
                    <Typography
                      style={{
                        fontFamily: "Outfit",
                        fontWeight: 400,
                        fontSize: "18px",
                        lineHeight: "18px",
                        margin: "0px 0px 15px 0px",
                      }}
                    >
                      Unit No {index + 1}
                    </Typography>

                    <FormControl fullWidth sx={newIncomeTextfield}>
                      <Select
                        fullWidth
                        variant="outlined"
                        placeholder="Unit Number"
                        value={unit || ''}
                        onChange={(e) =>
                          handleUnitsChange(index, e.target.value)
                        }
                        displayEmpty
                      >
                        <MenuItem disabled value=''>Select Unit</MenuItem>
                        {
                          unitListing?.map((item, index) => {
                            const filteredListing = units.filter(
                              (id) => id !== unit
                            );
                            if (!filteredListing.includes(item.id)) {
                              return (
                                <MenuItem value={item.id} key={index}>
                                  {`${item.ref_id} - ${item.title}`}
                                </MenuItem>
                              );
                            }
                          })
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                );
              })}
              <Grid item xs={12} md={12}>
                <IconButton
                  // variant="contained"
                  onClick={addUnits}
                  sx={{ color: "#B81918" }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  PRP No
                </Typography>
                <TextField
                  fullWidth
                  name="prpNo"
                  value={propertyDetails.prpNo}
                  onChange={handlePropertyDetailsChange}
                  variant="outlined"
                  placeholder="PRP Number"
                  sx={newIncomeTextfield}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Property
                </Typography>
                <TextField
                  fullWidth
                  name="propertyTitle"
                  value={propertyDetails.propertyTitle}
                  onChange={handlePropertyDetailsChange}
                  variant="outlined"
                  placeholder="Property"
                  sx={newIncomeTextfield}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Rooms
                </Typography>
                <TextField
                  fullWidth
                  name="rooms"
                  value={propertyDetails.rooms}
                  onChange={handlePropertyDetailsChange}
                  variant="outlined"
                  placeholder="Rooms"
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Parking
                </Typography>
                <TextField
                  fullWidth
                  name="parking"
                  value={propertyDetails.parking}
                  onChange={handlePropertyDetailsChange}
                  variant="outlined"
                  placeholder="Parking"
                  sx={newIncomeTextfield}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Period
                </Typography>
                <TextField
                  fullWidth
                  name="period"
                  value={propertyDetails.period}
                  onChange={handlePropertyDetailsChange}
                  variant="outlined"
                  placeholder="Time Period"
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Property Type
                </Typography>
                <TextField
                  fullWidth
                  name="propertyType"
                  value={propertyDetails.propertyType}
                  onChange={handlePropertyDetailsChange}
                  variant="outlined"
                  placeholder="Property Type"
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Rent Value
                </Typography>
                <TextField
                  fullWidth
                  name="rentValue"
                  value={propertyDetails.rentValue}
                  onChange={handlePropertyDetailsChange}
                  variant="outlined"
                  placeholder="Rent Value"
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  No. of Payments
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Number of Payments"
                  type="number"
                  value={numRows}
                  onChange={handleNumRowsChange}

                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>

            {/* additional clauses */}
            <Box>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: "rgba(0, 0, 0, 1)",
                  margin: "30px 0px 30px 0px",
                }}
              >
                Additional Clauses
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Article 1
                </Typography>
                <TextField
                  fullWidth
                  name="article1"
                  value={clausesData.article1}
                  onChange={handleClausesDetailChange}
                  variant="outlined"
                  placeholder="Article 1"
                  multiline
                  maxRows={4}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Article 2
                </Typography>
                <TextField
                  fullWidth
                  name="article2"
                  value={clausesData.article2}
                  onChange={handleClausesDetailChange}
                  variant="outlined"
                  placeholder="Article 2"
                  multiline
                  maxRows={4}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Article 3
                </Typography>
                <TextField
                  fullWidth
                  name="article3"
                  value={clausesData.article3}
                  onChange={handleClausesDetailChange}
                  variant="outlined"
                  placeholder="Article 3"
                  multiline
                  maxRows={4}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Article 4
                </Typography>
                <TextField
                  fullWidth
                  name="article4"
                  value={clausesData.article4}
                  onChange={handleClausesDetailChange}
                  variant="outlined"
                  multiline
                  maxRows={4}
                  placeholder="Article 4"
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Article 5
                </Typography>
                <TextField
                  fullWidth
                  name="article5"
                  value={clausesData.article5}
                  onChange={handleClausesDetailChange}
                  variant="outlined"
                  placeholder="Article 5"
                  multiline
                  maxRows={4}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>
            <Box>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "rgba(0, 0, 0, 1)",
                  margin: "30px 0px 30px 0px",
                }}
              >
                PDCs
              </Typography>
            </Box>

            {/* Table Start */}
            <Box style={{ overflowX: "auto" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
                  >
                    <TableCell
                      style={{
                        padding: "10px 0px 10px 20px",
                        borderRight: "1px solid #e0e0e0",
                      }}
                    >
                      Payment Number
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "10px 0px 10px 20px",
                        borderRight: "1px solid #e0e0e0",
                      }}
                    >
                      Payment Date
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "10px 0px 10px 20px",
                        borderRight: "1px solid #e0e0e0",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "10px 0px 10px 40px",
                        borderRight: "1px solid #e0e0e0",
                      }}
                    >
                      VAT
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "10px 0px 10px 20px",
                        borderRight: "1px solid #e0e0e0",
                      }}
                    >
                      Cheque No./<br></br> Money Transfer.<br></br>No. Cash
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "10px 0px 10px 20px",
                        borderRight: "1px solid #e0e0e0",
                      }}
                    >
                      Drawee Bank
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "10px 0px 10px 20px",
                        borderRight: "1px solid #e0e0e0",
                      }}
                    >
                      Type
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Add Security Deposit Row without Payment Number */}
                  <TableRow>
                    <TableCell></TableCell> {/* Empty cell for Payment Number */}
                    <TableCell>
                      <TextField
                        type="date"
                        value={depositedAmount.payment_date}
                        onChange={handleInputChange(-1, "payment_date")}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={depositedAmount.amount}
                        onChange={handleInputChange(-1, "amount")}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={depositedAmount.vat}
                        onChange={handleInputChange(-1, "vat")}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={depositedAmount.cheque_number}
                        onChange={handleInputChange(-1, "cheque_number")}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={depositedAmount.bank}
                        onChange={handleInputChange(-1, "bank")}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          value={depositedAmount.type}
                          onChange={handleInputChange(-1, "type")}
                        >
                          <MenuItem value="2">Security Deposit</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  {/* Map through rental rows */}
                  {rows?.map((row, rowIndex) => (
                    <TableRow key={rowIndex + 1}>
                      <TableCell>{rowIndex + 2}</TableCell>
                      <TableCell>
                        <TextField
                          type="date"
                          value={row.payment_date}
                          onChange={handleInputChange(rowIndex, "payment_date")}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={row.amount}
                          onChange={handleInputChange(rowIndex, "amount")}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={row.vat}
                          onChange={handleInputChange(rowIndex, "vat")}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={row.cheque_number}
                          onChange={handleInputChange(rowIndex, "cheque_number")}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={row.bank}
                          onChange={handleInputChange(rowIndex, "bank")}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <Select
                            value={row.type}
                            onChange={handleInputChange(rowIndex, "type")}
                          >
                            <MenuItem value="1">Rental</MenuItem>
                            <MenuItem value="2">Security Deposit</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

            <Box sx={{ display: "flex", margin: "20px 0 50px 0" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "130px",
                  height: "40px",
                }}
                variant="contained"
                type="submit"
              >
                Save Contract
              </Button>
            </Box>
          </form>
          <Modal
            open={isModalOpen}
            onClose={handleModalClose}
            aria-labelledby="pdf-viewer-modal"
            aria-describedby="pdf-viewer-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "60%",
                height: "100%",
                bgcolor: "white",
                boxShadow: 18,
                p: 4,
                borderRadius: "8px",
                overflow: "auto",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                {pdfError ? (
                  <div>Error loading PDF: {pdfError.message}</div>
                ) : (
                  <Viewer fileUrl={pdfLetter} onError={handlePdfLoadError} />
                )}
              </Worker>
              <Box
                display={"flex"}
                marginBottom={"0.5rem"}
                marginTop={"0.5rem"}
                gap={"10px"}
              >
                <Button
                  sx={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    color: "white",
                    fontSize: "12px",
                    lineHeight: "12px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "8rem",
                    height: "2rem",
                    borderRadius: "0.5rem",
                    '&:hover': {
                      bgcolor: 'rgba(184, 25, 24, 1)',

                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    sendOfferLetter(false);
                  }}
                >
                  Send Contract
                </Button>
                <Button
                  sx={{
                    border: "1px solid rgba(184, 25, 24, 1)",
                    color: "rgba(184, 25, 24, 1)",
                    fontSize: "12px",
                    lineHeight: "12px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "8rem",
                    height: "2rem",
                    borderRadius: "0.5rem",
                  }}
                  onClick={() => {
                    sendOfferLetter(true);
                  }}
                  disabled={loading}
                >
                  Save as Draft
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <Contracts />
      )}
    </>
  );
};

export default AddTentant;
