import React from "react";
import menuData from "./menuData";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Nav from "./Nav";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Properties from "../Pages/Properties/Properties";
import Maintenance from "../Pages/Maintenance/Maintenance";
import Documents from "../Pages/Documents/Documents";
import Chats from "../Pages/Chats/Chats";
import Notifications from "../Pages/Notifications/Notifications";
import PropertyView from "../Pages/Properties/PropertyView";
import ViewRentalAgremnt from "../Pages/Properties/ViewRentalAgremnt";
import PendingActions from "../Pages/Properties/PendingActions";
import ViewPdcs from "../Pages/Properties/ViewPdcs";
import PaidInvoice from "../Pages/Properties/PaidInvoice";
import Complaints from "../Pages/Properties/Complaints";
import ComplaintRequest from "../Pages/Properties/ComplaintRequest";
import ComplaintForm from "../Pages/Maintenance/ComplaintForm";
import MaintenanceForm from "../Pages/Maintenance/MaintenanceForm";
import FeedBack from "../Pages/Maintenance/FeedBack";
import NoitificationSetting from "../Pages/Notifications/NoitificationSetting";
import InfoProfile from "./InfoProfile";
import ChatBox from "../Pages/Chats/ChatBox";
import TermsConditions from "../Pages/Terms&Conditions/Terms&Conditions";




const useStyles = makeStyles(() => {
  const theme = useTheme();
  console.log(theme);

  return {
    root: {
      width: "100%",
      minHeight: "100vh",
      height: "auto",
      boxSizing: "border-box",
      padding: "0px 24px 0px 300px",
      [theme.breakpoints.down("lg")]: {
        padding: "0px 24px 24px 24px",
      },
    },
  };
});
const TenantPortal = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Nav menuData={menuData} />
      <Box className={classes.root}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/chat" element={<Chats />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/terms&conditions" element={<TermsConditions/>}/>
          <Route path="/properties/view-property" element={<PropertyView />} />
          <Route path="/properties/rental-agreement" element={<ViewRentalAgremnt />} />
          <Route path="/properties/pending-actions" element={<PendingActions />} />
          <Route path="/properties/pdcs" element={<ViewPdcs />} />
          <Route path="/paid-invoices" element={<PaidInvoice />} />
          <Route path="/properties/complaints" element={<Complaints />} />
          <Route path="/complaint-request" element={<ComplaintRequest />} />
          <Route path="/maintenance/complaint-form" element={<ComplaintForm />} />
          <Route path="/maintenance-form" element={<MaintenanceForm />} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/infoProfile" element={<InfoProfile />} />
          <Route path="/notifications-setting" element={<NoitificationSetting />} />
          <Route path="/message" element={<ChatBox />} />




        </Routes>
      </Box>
    </Fragment>
  );
};

export default TenantPortal;
