

// const BASE_URL = "http://capstone-vm.capstoneuae.com:8000";


// const BASE_URL = "http://127.0.0.1:8000/";

const BASE_URL = "https://capstone-be-staging-v2.95.168.174.206.nip.io";


export const endpoints = {
  LoginUsers: `${BASE_URL}/user/login`,
  SignUpUsers: `${BASE_URL}/user/user-registration`,
  getUserById: `${BASE_URL}/user/user-details`,
  updateUser: `${BASE_URL}/user/user-details`,
  getUserListing: `${BASE_URL}/user/user-listing`,
  createInquiry: `${BASE_URL}/user/inquiry`,
  getInquiry: `${BASE_URL}/user/inquiry`,
  updateInquiry: `${BASE_URL}/user/inquiry`,
  createOfferLetter: `${BASE_URL}/user/inquiry-offer-letter`,
  sendOfferLetter: `${BASE_URL}/user/inquiry-offer-letter/send`,
  getPropertiesListing: `${BASE_URL}/property-management/property/`,
  getUnitsListing: `${BASE_URL}/property-management/unit/`,
  landlordContracts: `${BASE_URL}/contracts/landlord-contract/`,
  tenantContracts: `${BASE_URL}/contracts/tenancy-contract/`,
  sendTenantContract: `${BASE_URL}/contracts/tenancy-contract/send/`,
  ForgetPassword: `${BASE_URL}/user/forget-password`,
  OTPVerify: `${BASE_URL}/user/otp-verification`,
  ResetPassword: `${BASE_URL}/user/password-reset`,
  PropertyManage: `${BASE_URL}/property-management/property/`,
  UnitManage: `${BASE_URL}/property-management/unit/`,
  Propertylist: `${BASE_URL}/property-management/property/`,
  getpropertyhistory: `${BASE_URL}/property-management/property/history/`,
  gettenantAssociation: `${BASE_URL}/property-management/tenant-property-association/`,
  getlandlordAssociation: `${BASE_URL}/property-management/landlord-property-association/`,
  Unitlist: `${BASE_URL}/property-management/unit/`,
  TenantAssociation: `${BASE_URL}/property-management/tenant-property-association/`,
  LandAssociation: `${BASE_URL}/property-management/landlord-property-association/`,
  Propertydetail: `${BASE_URL}/property-management/property-details/`,
  Unitdetail: `${BASE_URL}/property-management/unit-details/`,
  UpdateUnit: '/property-management/unit/',
  documents: `${BASE_URL}/documents-management/`,
  maintenanceRequests: `${BASE_URL}/maintenance/`,
  complaintRequests: `${BASE_URL}/maintenance/complaints/`,
  tenantUnitListing: `${BASE_URL}/property-management/tenant-units/`,
  tenantProperties: `${BASE_URL}/property-management/tenant-property/`,
  tenantPropertyDetails: `${BASE_URL}/property-management/tenant-property/details/`,
  tenantDocuments: `${BASE_URL}/documents-management/tenant-documents/`,
  getmaintenanceRequests: `${BASE_URL}/maintenance/`,
  getComplainrequest: `${BASE_URL}/maintenance/complaints/`,
  getunithistory: `${BASE_URL}/property-management/unit/history/`,
  subPropertiesList: `${BASE_URL}/property-management/sub-property/`,
  tenantDashboard: `${BASE_URL}/dashboard/tenant/`,
  renewalNotice: `${BASE_URL}/contracts/renewal/notices/`,
  tenancyContractsRenewal: `${BASE_URL}/contracts/tenancy-renewal-contract/`,
  landlordProperty: `${BASE_URL}/property-management/landlord-property/`,
  landlordPropertyDetails: `${BASE_URL}/property-management/landlord-property/details/`,
  landlordUnits: `${BASE_URL}/property-management/landlord-units/`,
  landlordUnitDetails: `${BASE_URL}/property-management/landlord-unit/details/`,
  landlordmaintenance: `${BASE_URL}/maintenance/landlord/`,
  landlordDocument: `${BASE_URL}/documents-management/landlord-documents/`,
  tenantDetails: `${BASE_URL}/user/tenant-details`,
  createTenantTerminationContract: `${BASE_URL}/contracts/tenancy-termination-contract/`,
  sendTenantTerminationContract: `${BASE_URL}/contracts/tenancy-termination-contract/send/`,
  getNotifications: `${BASE_URL}/alerts/notifications/`,
  getPendingActions: `${BASE_URL}/alerts/pending-actions/`,
  AmcSchedule: `${BASE_URL}/contracts/amc-schedule/`,
  AuditLogs: `${BASE_URL}/alerts/audit-logs/`,
  getchatuser: `${BASE_URL}/user/user-listing/chat`,
  financeCashFlow: `${BASE_URL}/finance/cash-flow/`,
  financePDCs: `${BASE_URL}/finance/pdc/`,
  financeIncome: `${BASE_URL}/finance/income/`,
  financeExpense: `${BASE_URL}/finance/expense/`,
  financeRentReimbursement: `${BASE_URL}/finance/rent-reimbursement/`,
  financeSOA: `${BASE_URL}/finance/soa-request/`,
  financeGenerateSOA: `${BASE_URL}/finance/soa-request/generate/`,
  financeTenantlisting: `${BASE_URL}/user/tenant-listing/`,
  financeVacantUnit: `${BASE_URL}/property-management/vacant-units/`,
  landlordFinancialReport: `${BASE_URL}/finance/landlord/reports/`,
  financeDashboardAnalytics: `${BASE_URL}/analytics/finance/dashboard/`,
  adminDashboardAnalytics: `${BASE_URL}/analytics/admin/dashboard/`,
  landlordPropertyAnalytics: `${BASE_URL}/analytics/landlord/property/`,
  landlordDashboard: `${BASE_URL}/analytics/landlord/dashboard/`,
  AdminMasterSheet: `${BASE_URL}/finance/landlord/mastersheet/`,
  propertyBulkUpoad: `${BASE_URL}/property-management/property/bulk-upload/`,
  unityBulkUpoad: `${BASE_URL}/property-management/unit/bulk-upload/`,
  DeleteUnit: `${BASE_URL}/property-management/unit/`,
  DeleteProperty: `${BASE_URL}/property-management/property/`,
  UpdateProperty: `${BASE_URL}/property-management/property/`,
  UpdateUnit: `${BASE_URL}/property-management/unit/`,
  Bugunit:`${BASE_URL}/property-management/unit/history`,
  

};
