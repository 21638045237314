import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { newIncomeTextfield } from "./AssociationStyle";
import toast from "react-hot-toast";
import { endpoints } from "../../../apiEndpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LandlordAssociationform = () => {
  const [PropertyData, setPropertyData] = useState([]);
  const [landlordData, setlandlordData] = useState([]);
  const [UnitData, setUnitData] = useState([]);
  const [fields, setFields] = useState([{ unit: "" }]);
  const [loading, setLoading] = useState(false); 
  const [entries, setEntries] = useState([
    { id: Date.now(), landlord: "", percentage: "" },
  ]);
  const [formdata, setformdata] = useState({
    property: "",
    primary_landlord: "",
    primary_landlord_share_percentage: "",
    primary_landlord_no_of_units: "",
  });
  const [reports, setReports] = useState({
    moveIn: null,
    moveOut: null,
  });
  

  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/admin/association");
  };

  const handleChange = (event, fieldId) => {
    const { name, value } = event.target;
    setformdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "unit") {
      const updatedFields = fields.map((field) =>
        field.id === fieldId ? { ...field, [name]: value } : field
      );
      setFields(updatedFields);
    }
  };

  const handleFileChange = (event, reportType) => {
    const file = event.target.files[0];
    if (file) {
      setReports((prevReports) => ({
        ...prevReports,
        [reportType]: file,
      }));
    }
  };

  const addField = () => {
    setFields([...fields, { id: Date.now(), unit: "" }]);
  };

  const handleEntryChange = (event, index) => {
    const { name, value } = event.target;
    const updatedEntries = [...entries];
    updatedEntries[index] = { ...updatedEntries[index], [name]: value };
    setEntries(updatedEntries);
  };

  const addEntry = () => {
    setEntries([...entries, { id: Date.now() }]);
  };

  const propertyList = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endpoints.Propertylist, {
        headers: { Authorization: `Token ${token}` },
      });
      const data = response.data.data;
      setPropertyData(data);
    } catch (error) { }
  };

  const Getlandlord = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endpoints.getUserListing}?&user_type=2`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const data = response.data.data;
      setlandlordData(data);
    } catch (error) { }
  };

  const GetUnit = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endpoints.Unitlist}?property=${formdata.property}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const data = response.data.data;
      setUnitData(data);
    } catch (error) { }
  };

  const Createuser = async () => {
    const payload = {
      property: formdata.property,
      primary_landlord: formdata.primary_landlord,
      primary_landlord_share_percentage:
        formdata.primary_landlord_share_percentage,
      primary_landlord_no_of_units: formdata.primary_landlord_no_of_units,
      // unit: fields.map((field) => field.unit),
      unit: fields
      .filter(field => field.unit) // Filter out empty units
      .map(field => field.unit),
      secondary_landlords: entries.map((entry) => ({
        secondary_landlord: entry.secondary_landlord,
        shareholding_percentage: entry.shareholding_percentage,
        secondary_landlord_no_of_units: entry.secondary_landlord_no_of_units,
      })),
    };

    try {
      setLoading(true); 
      const token = localStorage.getItem("token");
      const response = await axios.post(endpoints.LandAssociation, payload, {
        headers: { Authorization: `Token ${token}` },
      });

      if (response.status === 200) {

      }
      toast.success(response.data.message);
      handleSubmit();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    propertyList();
    Getlandlord();
    GetUnit();
  }, []);

  useEffect(() => {
    GetUnit();
  }, [formdata.property]);

  const getAvailableLandlords = (excludedLandlords) => {
    return landlordData.filter(
      (landlord) => !excludedLandlords.includes(landlord.id)
    );
  };

  const getAvailableUnits = (currentUnitId) => {
    const selectedUnitIds = fields
      .map((field) => field.unit)
      .filter((id) => id);
    return UnitData.filter(
      (unit) => !selectedUnitIds.includes(unit.id) || unit.id === currentUnitId
    );
  };

  return (
    <>
      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
            margin: "0px 0px 50px 0px",
          }}
        >
          Landlord Association
        </Typography>
      </Box>

      {/* Loader Overlay */}
      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}


      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Property
          </Typography>
          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="property"
              value={formdata.property}
              onChange={handleChange}
              displayEmpty
              required
            >
              <MenuItem value="" disabled>
                Select your Property
              </MenuItem>
              {PropertyData.map((property) => (
                <MenuItem key={property.id} value={property.id}>
                  {`${property.ref_id} - ${property.title}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Primary Landlord
          </Typography>
          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="primary_landlord"
              value={formdata.primary_landlord}
              onChange={handleChange}
              displayEmpty
              required
            >
              <MenuItem value="" disabled>
                Select your Primary landlord
              </MenuItem>
              {landlordData.map((landlord) => (
                <MenuItem key={landlord.id} value={landlord.id}>
                  {landlord.first_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Shareholding Percentage
          </Typography>
          <TextField
            sx={newIncomeTextfield}
            fullWidth
            variant="outlined"
            placeholder="Enter the Shareholding Percentage"
            name="primary_landlord_share_percentage"
            value={formdata.primary_landlord_share_percentage}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            No Of Units
          </Typography>
          <TextField
            sx={newIncomeTextfield}
            fullWidth
            variant="outlined"
            placeholder="Enter the No of unit"
            name="primary_landlord_no_of_units"
            value={formdata.primary_landlord_no_of_units}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      {entries.map((entry, index) => {
        const excludedLandlords = entries
          .filter((_, i) => i !== index)
          .map((e) => e.secondary_landlord);
        return (
          <Grid container spacing={2} key={entry.id} sx={newIncomeTextfield}>
            <Grid item xs={12} md={4} mt={1}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Secondary Landlord
              </Typography>
              <FormControl fullWidth sx={newIncomeTextfield}>
                <Select
                  name="secondary_landlord"
                  value={entry.secondary_landlord || ""}
                  onChange={(event) => handleEntryChange(event, index)}
                  displayEmpty
                  required
                >
                  <MenuItem value="" disabled>
                    Select your Secondary landlord
                  </MenuItem>
                  {getAvailableLandlords(excludedLandlords).map((landlord) => (
                    <MenuItem key={landlord.id} value={landlord.id}>
                      {landlord.first_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} mt={1}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Shareholding Percentage
              </Typography>
              <TextField
                sx={newIncomeTextfield}
                fullWidth
                variant="outlined"
                placeholder="Enter the Shareholding Percentage"
                name="shareholding_percentage"
                value={entry.shareholding_percentage}
                onChange={(event) => handleEntryChange(event, index)}
              />
            </Grid>

            <Grid item xs={12} md={4} mt={1}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                No Of Units
              </Typography>
              <TextField
                sx={newIncomeTextfield}
                fullWidth
                variant="outlined"
                placeholder="Enter the No of unit"
                name="secondary_landlord_no_of_units"
                value={entry.secondary_landlord_no_of_units}
                onChange={(event) => handleEntryChange(event, index)}
              />
            </Grid>
          </Grid>
        );
      })}

      <IconButton
        variant="contained"
        onClick={addEntry}
        sx={{ color: "#B81918" }}
      >
        <AddCircleOutlineIcon />
      </IconButton>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "20px 0px 15px 0px",
            }}
          >
            Unit
          </Typography>
          {fields.map((field, index) => (
            <Grid container spacing={2} key={field.id}>
              <Grid item xs={12}>
                <FormControl fullWidth sx={newIncomeTextfield}>
                  <Select
                    name="unit"
                    value={field.unit}
                    onChange={(e) => handleChange(e, field.id)}
                    displayEmpty
                    required
                  >
                    <MenuItem value="" disabled>
                      Select Unit
                    </MenuItem>
                    {getAvailableUnits(field.unit).map((unit) => (
                      <MenuItem key={unit.id} value={unit.id}>
                        {`${unit.ref_id} - ${unit.title}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {fields.length - 1 === index && (
                  <IconButton
                    onClick={addField}
                    style={{ color: "#B81918", marginTop: 3 }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

     
      <Box sx={{ display: "flex", margin: "20px 0 50px 0" }}>
        <Button
          style={{
            backgroundColor: "rgba(184, 25, 24, 1)",
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: 500,
            textTransform: "none",
            width: "130px",
            height: "40px",
          }}
          variant="contained"
          onClick={Createuser}
          disabled={loading} 
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default LandlordAssociationform;
