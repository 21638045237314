import React, { useState } from "react";
import {
  StyledMainTypography,
  StyledWelcomeBox,
  WelcomeTypography,
  StyledTextField,
  SubHeadingTypography,
  StyledInfoBox,
} from "./HelpNSuprtStyles";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, Grid, Box, Typography } from "@mui/material";

const HelpAndSupport = () => {
  const [searchString, setSearchString] = useState("");

  const images = {
    launching: "/image/launching.png",
    setting: "/image/setting.png",
    faq: "/image/faq.png",
  };

  const boxData = [
    {
      img: images.launching,
      heading: "Getting Started",
      description: "Start from a beginning.",
    },
    {
      img: images.setting,
      heading: "Account Settings",
      description: "You can do account settings easily.",
    },
    {
      img: images.faq,
      heading: "FAQ’S",
      description: "Clear All Your Questions.",
    },
  ];

  return (
    <>
      <StyledMainTypography>Help & Support</StyledMainTypography>

      <StyledWelcomeBox container>
        <Box>
          <WelcomeTypography>
            Welcome To <span style={{fontWeight:'500'}}>Capstone Property Management</span>
          </WelcomeTypography>
          <StyledTextField
            value={searchString}
            onChange={(event) => setSearchString(event.target.value)}
            placeholder="How we can help you?"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "#B81918" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box p={2}>
          <img
            src="/image/logo.png"
            alt="Capstone Logo"
            style={{ width: "12rem", height: "10rem" }}
          />
        </Box>
      </StyledWelcomeBox>

      <SubHeadingTypography>
        Need Help, We’ve got your back.
      </SubHeadingTypography>

      <Grid container spacing={2}>
        {boxData.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            marginBottom={"1rem"}
          >
            <StyledInfoBox>
              <img
                src={item.img}
                alt="icon"
                style={{ width: "auto", height: "auto" }}
              />
              <Typography
                textAlign={"center"}
                sx={{
                  fontFamily: "Outfit",
                  fontWeight: "500",
                  fontSize: { xs: "25px", sm: "34px", md: "38px" },
                  lineHeight: { xs: "2rem", sm: "2.5rem", md: "3.5rem" },
                  color: "#000000",
                }}
              >
                {item.heading}
              </Typography>
              <Typography
                textAlign={"center"}
                sx={{
                  fontFamily: "Outfit",
                  fontWeight: "300",
                  fontSize: { xs: "18px", sm: "22px", md: "25px" },
                  color: "#000000",
                }}
              >
                {item.description}
              </Typography>
            </StyledInfoBox>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default HelpAndSupport;
