import React from "react";
import { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./PropertieStyle";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import ViewDetail from "./ViewDetail";
import { endpoints } from "../../../../apiEndpoints";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../../utils/Loader";

const TenantHistory = ({ id }) => {
  const classes = useStyles();
  const [Tenanthistory, setTenanthistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const gettenanthistory = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endpoints.getpropertyhistory}?id=${id}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      setTenanthistory(response.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    gettenanthistory();
  }, []);
  const tenantdata = Tenanthistory?.tenant_history || [];

  const handleClick = (history, tenant, unit, moveInDate, moveOutDate) => {
    navigate("/admin/view-tenant", {
      state: {
        history,
        tenant,
        unit,
        moveInDate,
        moveOutDate,
      },
    });
  };

  return (
    <>
      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
            margin: "0px 0px 50px 0px",
          }}
        >
          Tenant History
        </Typography>
      </Box>

      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 500,
            color: "rgba(184, 25, 24, 1)",
            margin: "25px 0px 20px 0px",
          }}
        >
          Current Tenant
        </Typography>
      </Box>

      {loading ? (
        <Box
          display={"flex"}
          height={"100vh"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Loader />
        </Box>
      ) : tenantdata.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <Grid item xs={12} md={12}>
          <Box>
            {tenantdata?.map(
              (historyItem, historyIndex) =>
                historyItem.association_status == 1 &&
                historyItem.unit.map((unit, unitIndex) => (
                  <Box
                    key={`${historyIndex}-${unitIndex}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #EAECF0",
                      margin: "15px 0px 0px 0px",
                      borderRadius: "5.8px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className={classes.our}
                    >
                      <Avatar alt="Remy Sharp" />
                      <Typography
                        style={{
                          color: "#060606",
                          fontFamily: "Outfit",
                          fontSize: "14px",
                          lineHeight: "22px",
                          fontWeight: 700,
                          margin: "0px 0px 0px 10px",
                          borderRadius: "10px",
                        }}
                      >
                        {`${historyItem.tenant.first_name} || ${unit.ref_id}`}
                        <Typography
                          style={{
                            color: "rgba(26, 31, 54, 1)",
                            fontFamily: "Outfit",
                            fontSize: "14px",
                            lineHeight: "22px",
                            fontWeight: 400,
                            margin: "0px 0px 0px 0px",
                            borderRadius: "10px",
                          }}
                        >
                          {`Move-In: ${historyItem.move_in_date} - Move-Out: ${historyItem.move_out_date}`}
                        </Typography>
                        <Typography
                          style={{
                            color: "rgba(165, 172, 184, 1)",
                            fontFamily: "Outfit",
                            fontSize: "14px",
                            lineHeight: "22px",
                            fontWeight: 500,
                            margin: "0px 0px 0px 0px",
                            borderRadius: "10px",
                          }}
                        >
                          {`Duration: ${historyItem.tenancy_duration}`}
                        </Typography>
                      </Typography>
                    </Box>
                    <Button
                      style={{
                        textTransform: "none",
                        fontFamily: "Outfit",
                        fontSize: "10px",
                        lineHeight: "10px",
                        fontWeight: 500,
                        margin: "0px 25px 0px 0px",
                        padding: "5px 15px 5px 15px",
                        borderRadius: "5px",
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        color: "rgba(255, 255, 255, 1)",
                        height: "30px",
                      }}
                      // onClick={() => handleClick(historyItem.tenant, unit)}
                      onClick={() =>
                        handleClick(
                          historyItem,
                          historyItem.tenant,
                          unit,
                          historyItem.move_in_date,
                          historyItem.move_out_date
                        )
                      }
                    >
                      View Detail
                    </Button>
                  </Box>
                ))
            )}
          </Box>
        </Grid>
      )}

      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 500,
            color: "rgba(184, 25, 24, 1)",
            margin: "25px 0px 20px 0px",
          }}
        >
          Past Tenants
        </Typography>
      </Box>

      {loading ? (
        <Box
          display={"flex"}
          height={"100vh"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Loader />
        </Box>
      ) : tenantdata.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <Grid item xs={12} md={12}>
          <Box>
            {tenantdata?.map(
              (historyItem, historyIndex) =>
                historyItem.association_status == 2 &&
                historyItem.unit.map((unit, unitIndex) => (
                  <Box
                    key={`${historyIndex}-${unitIndex}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #EAECF0",
                      margin: "15px 0px 0px 0px",
                      borderRadius: "5.8px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className={classes.our}
                    >
                      <Avatar alt="Remy Sharp" />
                      <Typography
                        style={{
                          color: "#060606",
                          fontFamily: "Outfit",
                          fontSize: "14px",
                          lineHeight: "22px",
                          fontWeight: 700,
                          margin: "0px 0px 0px 10px",
                          borderRadius: "10px",
                        }}
                      >
                        {`${historyItem.tenant.first_name} || ${unit.ref_id}`}
                        <Typography
                          style={{
                            color: "rgba(26, 31, 54, 1)",
                            fontFamily: "Outfit",
                            fontSize: "14px",
                            lineHeight: "22px",
                            fontWeight: 400,
                            margin: "0px 0px 0px 0px",
                            borderRadius: "10px",
                          }}
                        >
                          {`Move-In: ${historyItem.move_in_date} - Move-Out: ${historyItem.move_out_date}`}
                        </Typography>
                        <Typography
                          style={{
                            color: "rgba(165, 172, 184, 1)",
                            fontFamily: "Outfit",
                            fontSize: "14px",
                            lineHeight: "22px",
                            fontWeight: 500,
                            margin: "0px 0px 0px 0px",
                            borderRadius: "10px",
                          }}
                        >
                          {`Duration: ${historyItem.tenancy_duration}`}
                        </Typography>
                      </Typography>
                    </Box>
                    <Button
                      style={{
                        textTransform: "none",
                        fontFamily: "Outfit",
                        fontSize: "10px",
                        lineHeight: "10px",
                        fontWeight: 500,
                        margin: "0px 25px 0px 0px",
                        padding: "5px 15px 5px 15px",
                        borderRadius: "5px",
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        color: "rgba(255, 255, 255, 1)",
                        height: "30px",
                      }}
                      onClick={() =>
                        handleClick(
                          historyItem.tenant,
                          unit,
                          historyItem.move_in_date,
                          historyItem.move_out_date
                        )
                      }
                    >
                      View Detail
                    </Button>
                  </Box>
                ))
            )}
          </Box>
        </Grid>
      )}
    </>
  );
};

export default TenantHistory;
