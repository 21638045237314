import React from "react";
import { useState } from "react";
import { Box, Typography, Button, Grid, Tabs, Tab } from "@mui/material";
import { useStyles } from "../../../AdminComponent/Pages/Properties/Propertie/PropertieStyle";
import SearchIcon from "@mui/icons-material/Search";
import AllProperty from "./AllProperty/AllProperty";
import Allunit from "./AllUnit/Allunit";

const Properties = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [open, setopen] = useState(false);
  const [activeComponent, setActiveComponent] = useState("property");
  

  const capitalize = (str) => {
    if (!str) return ""; // Return an empty string if input is falsy
    return `Welcome, Mr. ${
      str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    }!`;
  };
  const userName = capitalize(localStorage.getItem("loggedInUser"));

  return (
    <>
      <Typography
        style={{
          fontFamily: "Outfit",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        {userName}
      </Typography>

      <Box className={classes.mianroot}>
        <Box className={classes.root} sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6} lg={7}>
              <div style={{ position: "relative" }}>
                <input
                  type="search"
                  style={{
                    width: "100%",
                    height: "40px",
                    border: "1px solid rgba(239, 239, 239, 1)",
                    borderRadius: "30px",
                    paddingLeft: "40px",
                  }}
                  placeholder="Search By Location"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  size="small"
                />
                <div
                  style={{ position: "absolute", top: "10px", left: "10px" }}
                >
                  <SearchIcon />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <input
                type="search"
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid rgba(239, 239, 239, 1)",
                  borderRadius: "30px",
                  padding: "0px 0px 0px 20px",
                }}
                placeholder="Search By Apartment Number"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {activeComponent === "property" && (
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "#000000",
              }}
            >
              All Properties
            </Typography>
          )}
          {activeComponent === "unit" && (
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "#000000",
              }}
            >
              All Units
            </Typography>
          )}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              style={{
                backgroundColor: activeComponent === 'property' ? "rgba(184, 25, 24, 1)" : ('#EFEFEF'),
                color: activeComponent === 'property' ? "" : ('#000000'),
                fontFamily: "Outfit",
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "130px",
                height: "30px",
                margin: "0px 10px 0px 0px",
              }}
              variant="contained"
              onClick={() => {setActiveComponent("property") }}
            >
              All Properties
            </Button>
            <Button
              style={{
                backgroundColor: activeComponent === 'unit' ? "rgba(184, 25, 24, 1)" : ('#EFEFEF'),
                color: activeComponent === 'unit' ? "" : ('#000000'),
                fontFamily: "Outfit",
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "95px",
                height: "30px",
              }}
              variant="contained"
              onClick={() => setActiveComponent("unit")}
            >
              All Units
            </Button>
          </Box>
        </Box>

        <Box>
          {activeComponent === "property" && <AllProperty />}
          {activeComponent === "unit" && <Allunit />}
        </Box>
      </Box>
    </>
  );
};

export default Properties;
