import React from "react";
import { useState } from "react";
import { Box, Grid, Button, Typography, Divider, Tooltip } from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import {
  InfoTypography,
} from "../../../../AdminComponent/Pages/Maintenance/MaintenanceStyles";

const UploadDocument = ({ documentsDetails, mainProperty }) => {
  // const [secondSelectValue, setSecondSelectValue] = useState("");

  const downloadFile = (fileUrl, type) => {
    if (!fileUrl) {
      alert("Document not available.");
      return;
    }

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${type}.${getFileExtension(fileUrl)}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("Could not fetch the file."));
  };

  function getFileExtension(url) {
    // Use a regular expression to match the file extension
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(url)[1];
    return extension;
  }
  return (
    <>
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        Upload Documents
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12} md={6} alignItems="center">
          <Box>
            <Box sx={{ display: "flex" }}>
              <img
                src={mainProperty?.attachments[0].media}
                onError={(e) => (e.target.src = "/image/propertyicon.png")}
                alt="this image is not show"
                width={450}
                height={250}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "35px",
              margin: "40px 0px 0px 0px",
            }}
          >
            {mainProperty?.title || "Title not available"}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              margin: "10px 0px 0px 0px",
            }}
          >
            {mainProperty?.description || "Description not available"}
          </Typography>
        </Grid>
      </Grid>

      <Box style={{ margin: "25px 0px 20px 0px" }}>
        <Divider />
      </Box>

      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Select
              size="small"
              value={secondSelectValue}
              onChange={(e) => setSecondSelectValue(e.target.value)}
              displayEmpty
              style={{
                border: "0px solid rgba(239, 239, 239, 1)",
                borderRadius: "30px",
              }}
            >
              <MenuItem value="" disabled>
                Select Type
              </MenuItem>
              <MenuItem value="option1">Option 1</MenuItem>
              <MenuItem value="option2">Option 2</MenuItem>
              <MenuItem value="option3">Option 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid> */}

      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 700,
          color: "rgba(0, 0, 0, 1)",
          margin: "20px 0px 10px 0px",
        }}
      >
        Documents
      </Typography>

      <Box style={{ margin: "50px 0px 50px 0px" }}>
        {

          documentsDetails?.documents && documentsDetails?.documents.length > 0 ? (
            documentsDetails?.documents.map((document) => {
              return (
                <Box style={{ display: "flex", margin: "30px 0px 0px 0px" }}>
                  <img src="/image/pdfimage.png" />

                  <Tooltip title={"Click to download document"} arrow>
                    <Typography
                      style={{
                        fontFamily: "Raleway",
                        fontSize: "20px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        margin: "20px 0px 0px 20px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        downloadFile(document, document.split("/").pop());
                      }}
                    >
                      {document.split("/").pop()}
                    </Typography>
                  </Tooltip>
                </Box>
              );
            })
          ) : (
            <InfoTypography>No Documents</InfoTypography>
          )}
      </Box>
    </>
  );
};

export default UploadDocument;
