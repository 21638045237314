import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
} from "@mui/material";
import {
  useStyles,
  mainTypography,
  subTypography,
  subTypographySecond,
  vacantUnitsTypography,
  CustomTextField,
  tableHeadCell,
  tableBodyCell,
  tableBodyCellRed,
  aplyfilter,
} from "./VacantUnitStyles";
import { BarChart } from "@mui/x-charts/BarChart";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { PropertyList, PropertyType } from '../../../enums/UserTypes';
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";

const { RangePicker } = DatePicker;




const VacantUnits = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [vacantData, setvacantData] = useState([]);
  const [searchValues, setSearchValues] = useState({
    firstSearch: null,
    secondSearch: null,
  });


  const handleSearchChange = (name, value) => {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };


  const formattedDates = searchValues.firstSearch?.map((dateObj) => {
    return dateObj.$d.toISOString().split("T")[0];
  });




  const GetVacantUnits = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      let url;
      if (
        searchValues &&
        (formattedDates?.length === 2 || searchValues.secondSearch)
      ) {
        const startDate = formattedDates?.[0] || ""; // Use an empty string if not defined
        const endDate = formattedDates?.[1] || ""; // Use an empty string if not defined

        url = `${endpoints.financeVacantUnit
          }?start_date=${startDate}&end_date=${endDate}`;
      } else {
        url = endpoints.financeVacantUnit;
      }
      const response = await axios.get(url, requestOptions);
      setvacantData(response.data.data);
      if (
        searchValues.secondSearch ||
        (formattedDates && formattedDates.length === 2)
      ) {
        toast.success(response.data.message);
      }
    } catch (error) {
      if (
        searchValues.secondSearch ||
        (formattedDates && formattedDates.length === 2)
      ) {
        toast.error(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    GetVacantUnits();
  }, []);


  const occupied = vacantData?.monthly_data?.map((item) => item.occupied) || [];
  const vacant = vacantData?.monthly_data?.map((item) => item.vacant) || [];
  const xLabels = vacantData?.monthly_data?.map((item) => item.month) || [];





  return (
    <>
      <Box>
        <Typography sx={mainTypography}>Vacant Units</Typography>
      </Box>
      <Box className={classes.mainBox}>
        <Box className={classes.vacantBox}>
          <Box className={classes.subBox}>
            <Typography sx={subTypography}>Vacant Units</Typography>
            <Typography sx={subTypographySecond}>{vacantData.vacant_units}</Typography>
          </Box>
          <Box className={classes.subBox}>
            <Typography sx={subTypography}>Potential Loss</Typography>
            <Typography sx={subTypographySecond}>{vacantData.potential_loss}</Typography>
          </Box>
        </Box>
        <Box className={classes.graphBox}>
          <BarChart
            height={280}
            series={[
              {
                data: occupied,
                label: "occupied unit",
                id: "pvId",
                color: "#B81918",
              },
              {
                data: vacant,
                label: "vacant unit",
                id: "uvId",
                color: "#ADADAD",
              },
            ]}
            xAxis={[{ data: xLabels, scaleType: "band" }]}
            yAxis={[{ min: 0, max: 80 }]}
          />
        </Box>
      </Box>

      <Box className={classes.vacantUnitsBox}>
        <Box>
          <Typography sx={vacantUnitsTypography}>Vacant Units List</Typography>
        </Box>
      </Box>

      <Box className={classes.searchFieldBox} mt={2}>
        <Space direction="vertical" size={12}>
          <RangePicker
            style={{
              borderRadius: "50px",
              height: "2.5rem",
              borderColor: "#EFEFEF",
              width: "100%",

            }}
            value={searchValues.firstSearch}
            onChange={(dates) => handleSearchChange("firstSearch", dates)}
          />
        </Space>
        <Button sx={aplyfilter} onClick={GetVacantUnits}>
          Apply Filter
        </Button>
      </Box>


      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : vacantData.units?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (

        <Box marginTop={"2rem"}>
          <TableContainer sx={{ height: "65vh", overflowX: "auto" }}>
            <Table sx={{ border: "1px solid #EFEFEF" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeadCell}>Property</TableCell>
                  <TableCell sx={tableHeadCell}>Unit Number</TableCell>
                  <TableCell sx={tableHeadCell}>Unit Type</TableCell>
                  <TableCell sx={tableHeadCell}>Last Rented Amount</TableCell>
                  <TableCell sx={tableHeadCell}>Duration of Vacancy</TableCell>
                  <TableCell sx={tableHeadCell}>Last Occupant</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vacantData.units?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={tableBodyCell}>{item.property}</TableCell>
                    <TableCell sx={tableBodyCell}>{item.ref_id}</TableCell>
                    <TableCell sx={tableBodyCell}>
                      {item.type == PropertyType.RESIDENTIAL
                        ? "Residential"
                        : PropertyType.COMMERCIAL
                          ? "Commercial"
                          : PropertyType.COMMUNITY
                            ? "Community"
                            : "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>{item.last_rented_amount}</TableCell>
                    <TableCell sx={tableBodyCellRed}>{item.vacancy_duration}</TableCell>
                    <TableCell sx={tableBodyCell}>{item.last_occupant}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
}

export default VacantUnits;
