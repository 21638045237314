import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import * as MuiIcons from "@mui/icons-material";
import { NavLink, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import Profile from "./Profile";
import { Link } from "react-router-dom";

const ProfileData = [
  {
    id: 1,
    label: localStorage.getItem("loggedInUser"),
    image: "/image/userAvatar.jpg",
  },
];

const menData = [
  {
    id: 1,
    label: "Help & Support",
    path: "",
    icon: "",
  },
  {
    id: 2,
    label: "Log Out",
    path: "",
    icon: "",
  },
];

const drawerWidth = 240;

const Nav = ({ menuData }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [isOpen, setIsOpen] = useState(isLargeScreen);
  const [open, setOpen] = React.useState({});
  const [name, setname] = useState();
  const location = useLocation();

  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    setIsOpen(isLargeScreen);
  }, [isLargeScreen]);

  useEffect(() => {
    const userName = localStorage.getItem("loggedInUser");
    setname(userName);
  }, [])

  const toggleDrawer = (event) => {
    setIsOpen(!isOpen);
  };

  // const renderIcon = (iconName) => {
  //   const Icon = MuiIcons[iconName];
  //   return Icon ? <Icon /> : null;
  // };





  const renderIcon = (iconName, isActive) => {
    if (iconName.includes("/image/")) {
      const imageStyle = {
        width: "24px",
        height: "24px",
        filter: isActive ? "brightness(0) invert(1)" : "none",
      };
      return <img src={iconName} alt="icon" style={imageStyle} />;
    } else {
      const iconStyle = {
        color: isActive ? "white" : "#737679",
      };
      const Icon = MuiIcons[iconName];
      return Icon ? <Icon style={iconStyle} /> : null;
    }
  };







  const drawer = (
    <div style={{ backgroundColor: "white", height: "700px", width: "240px" }}>


      {ProfileData.map((item, index) => (
        <Box
          key={index}
          style={{
            margin: "50px 0px 18px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Link to="/finance/infoProfile" style={{ textDecoration: "none" }}>
            <Avatar
              alt="Remy Sharp"
              src="/image/userAvatar.jpg"
              sx={{ width: 92, height: 92, marginBottom: "10px" }}
            />
          </Link>
          <Typography
            style={{
              fontFamily:"Outfit",
              color: "#060606",
              fontSize: "24px",
              lineHeight: "24px",
              fontWeight: 700,
              marginTop: "20px"
            }}
          >
            {name}
          </Typography>
          <Typography
            style={{
              fontFamily:"Outfit",
              color: "#060606",
              fontSize: "14px",
              lineHeight: "14px",
              fontWeight: 400,
              marginTop: "15px"
            }}
          >
            Finance
          </Typography>
        </Box>
      ))}

      <List>
        {menuData.map((item, index) => {

          const isActive = location.pathname.startsWith(item.path);
          return (
            <ListItemButton
              key={index}
              component={NavLink}
              to={item.path}
              style={{
                backgroundColor: isActive ? "#B9100F" : "transparent",
                color: isActive ? "white" : "#737679",
                width: "205px",
                margin: "8px auto",
                borderRadius: "8px",
              }}
            >
              <ListItemIcon
                style={{
                  minWidth: "40px",
                }}
              >
                {renderIcon(item.icon, isActive)}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "16px",
                    fontFamily: "Outfit",
                    fontWeight: "300",
                    lineHeight: "16px",
                  },
                }}
              />
            </ListItemButton>
          );
        })}
      </List>





      {/* 
      <List>
        {menuData.map((item, index) => (
          <div key={index}>
            <ListItemButton
              component={NavLink}
              to={item.path}
              onClick={() => setActiveIndex(index)}
              style={{
                backgroundColor:
                  activeIndex === index ? "#B9100F" : "transparent",
                color: activeIndex === index ? "white" : "#737679",
                width: "205px",
                margin: "8px auto",
                borderRadius: "8px",
              }}
            >
              <ListItemIcon
                onClick={() => setActiveIndex(index)}
                style={{
                  backgroundColor:
                    activeIndex === index ? "#B9100F" : "transparent",
                  color: activeIndex === index ? "white" : "#737679",
                  minWidth: "40px",
                }}
              >
                {renderIcon(item.icon)}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "16px",
                    font: "Outfit",
                    fontWeight: "300",
                    lineHeight: "16px",
                  },
                }}
              />
            </ListItemButton>
            {index < menuData.length - 1}
          </div>
        ))}
      </List> */}

    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        style={{
          backgroundColor: "white",
          display: isLargeScreen ? "none" : "flex",
        }}
        position="fixed"
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer}
                sx={{ mr: 2, display: { sm: "block" } }}
              >
                <MenuIcon style={{ color: "rgb(101,106,110)" }} />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Profile />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant={isLargeScreen ? "permanent" : "temporary"}
          open={isOpen}
          onClose={toggleDrawer}
        >
          {drawer}
        </Drawer>
      </Box>
      <Toolbar />
    </Box>
  );
};

Nav.propTypes = {
  menuData: PropTypes.array.isRequired,
};

export default Nav;
