import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useStyles, newIncomeTextfield, button } from "./AssociationStyle";
import toast from "react-hot-toast";
import { endpoints } from "../../../apiEndpoints";
import axios from "axios";
import { AssociationStatus } from "../../../enums/UserTypes";
import { useNavigate } from "react-router-dom";

const TenantAssociation = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([{ unit: "" }]);
  const [PropertyData, setPropertyData] = useState([]);
  const [TenantData, setTenantData] = useState([]);
  const [UnitData, setUnitData] = useState([]);
  const [formdata, setformdata] = useState({
    property: "",
    tenant: "",
    association_status: "",
  });
  const [reports, setReports] = useState({
    moveIn: null,
    moveOut: null,
  });
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/admin/association");
  };

  const handleChange = (event, fieldId) => {
    const { name, value } = event.target;
    setformdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "unit") {
      const updatedFields = fields.map((field) =>
        field.id === fieldId ? { ...field, [name]: value } : field
      );
      setFields(updatedFields);
    }
  };
  const handleFileChange = (event, reportType) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      setReports((prevReports) => ({
        ...prevReports,
        [reportType]: file, // Update the specific report type
      }));
    }
  };

  const addField = () => {
    setFields([...fields, { id: Date.now(), unit: "" }]);
  };

  const propertyList = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endpoints.Propertylist, {
        headers: { Authorization: `Token ${token}` },
      });
      const data = response.data.data;
      setPropertyData(data);
    } catch (error) {

    }
  };

  const GetTenant = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endpoints.getUserListing}?&user_type=3`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const data = response.data.data;
      setTenantData(data);
    } catch (error) {

    }
  };

  const GetUnit = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endpoints.Unitlist}?property=${formdata.property}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const data = response.data.data;
      setUnitData(data);
    } catch (error) {

    }
  };


  const Createuser = async (e) => {
    e.preventDefault();
    const unitsArray = fields.map((field) => field.unit);
    const tenantFormData = new FormData();
    tenantFormData.append("tenant", formdata.tenant);
    tenantFormData.append("property", formdata.property);
    tenantFormData.append("association_status", formdata.association_status);
    if(unitsArray[0] !== ""){
    tenantFormData.append("unit", JSON.stringify(unitsArray));
    }
    if (reports.moveIn) {
      tenantFormData.append("move_in_report", reports.moveIn);
    }

    if (reports.moveOut) {
      tenantFormData.append("move_out_report", reports.moveOut);
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endpoints.TenantAssociation,
        tenantFormData,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      if (response.status === 200 || 201) {
        handleSubmit();
      }
      toast.success(response.data.message);
     
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    propertyList();
    GetTenant();
    GetUnit();
  }, []);

  useEffect(() => {
    GetUnit();
  }, [formdata.property]);

  const getAvailableUnits = (currentUnitId) => {
    const selectedUnitIds = fields
      .map((field) => field.unit)
      .filter((id) => id);
    return UnitData.filter(
      (unit) => !selectedUnitIds.includes(unit.id) || unit.id === currentUnitId
    );
  };

  return (
    <>
      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
            margin: "0px 0px 50px 0px",
          }}
        >
          Tenant Association
        </Typography>
      </Box>


      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}



<form onSubmit={Createuser}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Property
          </Typography>
          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="property"
              value={formdata.property}
              onChange={handleChange}
              displayEmpty
              
            >
              <MenuItem value="" disabled>
                Select your Property
              </MenuItem>
              {PropertyData.map((property) => (
                <MenuItem key={property.id} value={property.id}>
                  {`${property.ref_id} - ${property.title}` || "N/A"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Tenant
          </Typography>
          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="tenant"
              value={formdata.tenant}
              onChange={handleChange}
              displayEmpty
              
            >
              <MenuItem value="" disabled>
                Select your tenant
              </MenuItem>
              {TenantData.map((Tenant) => (
                <MenuItem key={Tenant.id} value={Tenant.id}>
                  {Tenant.first_name || "N/A"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "20px 0px 15px 0px",
            }}
          >
            Unit
          </Typography>
          {fields.map((field, index) => (
            <Grid container spacing={2} key={field.id}>
              <Grid item xs={12}>
                <FormControl fullWidth sx={newIncomeTextfield}>
                  <Select
                    name="unit"
                    value={field.unit}
                    onChange={(e) => handleChange(e, field.id)}
                    displayEmpty
                    
                  >
                    <MenuItem value="" disabled>
                      Select Unit
                    </MenuItem>
                    {getAvailableUnits(field.unit).map((unit) => (
                      <MenuItem key={unit.id} value={unit.id}>
                        {`${unit.ref_id} - ${unit.title}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {fields.length - 1 === index && (
                  <IconButton
                    onClick={addField}
                    style={{ color: "#B81918", marginTop: 3 }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "20px 0px 15px 0px",
            }}
          >
            Status
          </Typography>
          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="association_status"
              value={formdata.association_status}
              onChange={handleChange}
              displayEmpty
              
            >
              <MenuItem value="" disabled>
                Select your Status
              </MenuItem>
              <MenuItem value={AssociationStatus.ACTIVE}>Active</MenuItem>
              <MenuItem value={AssociationStatus.INACTIVE}>In-Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Move-in Report
          </Typography>
          <Box
            sx={{
              p: 5,
              border: 1,
              borderColor: "grey.300",
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <input
              accept="*/*"
              type="file"
              onChange={(e) => handleFileChange(e, "moveIn")}
              style={{ display: "none" }}
              id="file-input-move-out"
            />
            <label htmlFor="file-input-move-out">
              <Button
                variant="contained"
                component="span"
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  textTransform: "none",
                  borderRadius: "8px",
                  margin: "100px 0px 100px 0px",
                }}
                startIcon={<CloudUploadIcon />}
              >
                Upload File
              </Button>
            </label>
            {reports.moveIn && <Typography>{reports.moveIn.name}</Typography>}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Move-out Report
          </Typography>
          <Box
            sx={{
              p: 5,
              border: 1,
              borderColor: "grey.300",
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <input
              accept="*/*"
              type="file"
              onChange={(e) => handleFileChange(e, "moveOut")}
              style={{ display: "none" }}
              id="file-input-move-in"
            />
            <label htmlFor="file-input-move-in">
              <Button
                variant="contained"
                component="span"
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  textTransform: "none",
                  borderRadius: "8px",
                  margin: "100px 0px 100px 0px",
                }}
                startIcon={<CloudUploadIcon />}
              >
                Upload File
              </Button>
            </label>
            {reports.moveOut && <Typography>{reports.moveOut.name}</Typography>}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", margin: "20px 0 50px 0" }}>
        <Button
          style={{
            backgroundColor: "rgba(184, 25, 24, 1)",
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: 500,
            textTransform: "none",
            width: "130px",
            height: "40px",
          }}
          variant="contained"
          type="submit"
          disabled={loading}
        >
          Save
        </Button>
      </Box>
      </form>
    </>
  );
};

export default TenantAssociation;
