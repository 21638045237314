import React, { useEffect } from "react";
import { useState } from "react";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Grid,
} from "@mui/material";
import { Box, Typography, Button } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import EventIcon from "@mui/icons-material/Event";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RenewPopup from "./RenewPopup";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";
import { renewalNoticeStatus } from "../../../enums/UserTypes";

const RenewalNotice = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [renewalNotices, setRenewalNotices] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [startOpen, setStartOpen] = useState(false);
  const [endOpen, setEndSecondOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const[loading,setLoading] = useState(false);
  const [hasActiveNotice, setHasActiveNotice] = useState(true);

  const handleopenPopup = (id) => {
    setSelectedId(id);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  useEffect(() => {
    if (Array.isArray(renewalNotices)) {
      const activeNoticeExists = renewalNotices.some(notice => notice?.notice_status === renewalNoticeStatus.awaiting.value);
      setHasActiveNotice(activeNoticeExists);
     
      
      
    }
  }, [renewalNotices]); // Runs whenever notices changes

  
  

  const getRenewalNotices = async () => {
    let url;
    if (startDate || endDate) {
      url = `${endpoints.renewalNotice}?start_date=${startDate}&end_date=${endDate}`;
    } else {
      url = `${endpoints.renewalNotice}`;
    }
    try {
      setLoading(true)
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(url, requestOptions);

      setRenewalNotices(response.data.data);
      if (startDate || endDate) {
       
      }
    } catch (error) {
      if (startDate || endDate) {
        
      }
    }finally{
      setLoading(false);
    }
  };

  const sendRenewalNotice = async () =>{
    try{
      setLoading(true);
      const requestOptions ={
        headers:{
          Authorization: `token ${localStorage.getItem('token')}`
        }
      }
      const response = await axios.post(endpoints.renewalNotice,{},requestOptions)
      toast.success(response.data.message);
      if(response.status === 200){
      getRenewalNotices();
      }


    }catch(error){
      toast.error(error.response.data.message)

    }finally{
      setLoading(false)

    }
  }

  useEffect(() => {
    getRenewalNotices();
  }, []);




  const handleApplyFilter = () => {
    getRenewalNotices();
  };

  return (
    <>
      {showPopup && (
        <RenewPopup
          onClose={handleClosePopup}
          id={selectedId}
          renewalNotice={getRenewalNotices}
        />
      )}
      <Box marginBottom={"2rem"}>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          Renewal Notices
        </Typography>
      </Box>
      <Box mb={5} sx={{ width: "100%" }}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} md={6} spacing={2} container>
            <Grid item xs={12} sm={4}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: "16px",
                  lineHeight: "34px",
                  fontWeight: 350,
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                Select Start Date
              </Typography>
              <input
                type="date"
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid rgba(239, 239, 239, 1)",
                  borderRadius: "30px",
                  padding: "0px 10px 0px 20px",
                  color: "#737679",
                }}
                placeholder="Search By Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: "16px",
                  lineHeight: "34px",
                  fontWeight: 350,
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                Select End Date
              </Typography>
              <input
                type="date"
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid rgba(239, 239, 239, 1)",
                  borderRadius: "30px",
                  padding: "0px 10px 0px 20px",
                  color: "#737679",
                }}
                placeholder="Search By Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4} marginTop={"2.3rem"}>
              <Button
                sx={{
                  border: "1px solid rgba(184, 25, 24, 1)",
                  borderRadius: "30px",
                  color: "rgba(184, 25, 24, 1)",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#ffff",
                    borderColor: "rgba(184, 25, 24, 1)",
                  },
                }}
                variant="outlined"
                onClick={handleApplyFilter}
              >
                Apply Filters
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {loading? (<Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'50vh'}> 
        <Loader/>
      </Box>):(<>
      
      <Box style={{ overflowX: "auto" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
              <TableCell
                style={{
                  padding: "10px 0px 10px 20px",
                  borderRight: "1px solid #e0e0e0",
                }}
              >
                Contract Name
              </TableCell>
              <TableCell
                style={{
                  padding: "10px 0px 10px 20px",
                  borderRight: "1px solid #e0e0e0",
                }}
              >
                Tenant Name
              </TableCell>
              <TableCell
                style={{
                  padding: "10px 0px 10px 20px",
                  borderRight: "1px solid #e0e0e0",
                }}
              >
                Unit
              </TableCell>
              <TableCell
                style={{
                  padding: "10px 0px 10px 40px",
                  borderRight: "1px solid #e0e0e0",
                }}
              >
                Amount
              </TableCell>
              <TableCell
                style={{
                  padding: "10px 0px 10px 20px",
                  borderRight: "1px solid #e0e0e0",
                }}
              >
                Due Date
              </TableCell>
              <TableCell
                style={{
                  padding: "10px 0px 10px 20px",
                  borderRight: "1px solid #e0e0e0",
                }}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  padding: "10px 0px 10px 20px",
                  borderRight: "1px solid #e0e0e0",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renewalNotices?.map((notice) => (
              <TableRow key={notice.id}>
                <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                  {notice?.contract?.ref_id || "-"}
                </TableCell>
                <TableCell style={{ padding: "10px 0px 10px 40px" }}>
                  {notice?.contract?.tenant?.first_name || "-"}
                </TableCell>
                <TableCell style={{ padding: "10px 0px 10px 40px" }}>
                  {/* {notice?.contract?.unit || "-"} */}
                  {Array.isArray(notice?.contract?.unit) &&
                  notice?.contract?.unit.length > 0
                    ? notice?.contract?.unit.length
                    : "-"}
                </TableCell>
                <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                  {notice?.new_amount || notice?.contract?.rent_value || "-"}
                </TableCell>
                <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                  {notice?.contract?.end_date || "-"}
                </TableCell>
                <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                  {notice?.notice_status === renewalNoticeStatus.awaiting.value ? (renewalNoticeStatus.awaiting.label) : notice?.notice_status === renewalNoticeStatus.sent.value ? (renewalNoticeStatus.sent.label) : "-"}
                </TableCell>
                <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                  <>
                    <EditNoteIcon
                      onClick={() => {
                        handleopenPopup(notice.id);
                      }}
                      style={{ color: "rgba(184, 25, 24, 1)" }}
                    />
                  </>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} marginTop={"1rem"}>
        <Button
          sx={{
            backgroundColor: "rgba(184, 25, 24, 1)",
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: 500,
            textTransform: "none",
            minWidth: "130px",
            height: "35px",
            "&:hover": {
              backgroundColor: "rgba(184, 25, 24, 1)",
            },
          }}
          variant="contained"

          onClick={sendRenewalNotice}
          disabled={!hasActiveNotice}
        >
          Send Renewal Notice
        </Button>
      </Box>
      </>)}
    </>
  );
};

export default RenewalNotice;
