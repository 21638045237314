import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  complaintForm: {},
};

const complaintFormSlice = createSlice({
  name: "complaintForms",
  initialState,
  reducers: {
    updateComplaintForm(state, action) {
      state.complaintForm = action.payload;
    },
  },
});

export const { updateComplaintForm } = complaintFormSlice.actions;
export default complaintFormSlice.reducer;
