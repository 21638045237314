import React, { useEffect } from "react";
import { useState } from "react";
import { Box, Typography, Button, Grid, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useStyles } from "../Properties/Propertie/PropertieStyle";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../utils/Loader";

const DraftContract = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchByTenant, setSearchByTenant] = useState(null);
  const [searchByUnit, setSearchByUnit] = useState(null);
  const [searchByDate, setSearchByDate] = useState(null);
  const [tenantsDraftContracts, setTenantDraftContracts] = useState([]);

  const handleApplyFilter = () => {
    getDraftContracts();
  };

  const getDraftContracts = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      let queryParams = [];
      if (searchByTenant) {
        queryParams.push(`tenant=${searchByTenant}`);
      }
      if (searchByUnit) {
        queryParams.push(`property=${searchByUnit}`);
      }
      if (searchByDate) {
        queryParams.push(`date=${searchByDate}`);
      }
      let url = `${endpoints.tenantContracts}?is_draft=true`;
      if (queryParams.length > 0) {
        url = `${endpoints.tenantContracts}?is_draft=true&${queryParams.join(
          "&"
        )}`;
      }
      const response = await axios.get(url, requestOptions);
      setTenantDraftContracts(response.data.data);
      if (queryParams.length > 0) {

      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDraftContracts();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          Draft Contracts
        </Typography>
        {/* <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{
              backgroundColor: "rgba(184, 25, 24, 1)",
              fontSize: "14px",
              lineHeight: "16px",
              fontWeight: 500,
              textTransform: "none",
              width: "130px",
              height: "30px",
            }}
            variant="contained"
          >
            Create New
          </Button>
        </Box> */}
      </Box>
      <Box className={classes.root} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4} lg={3}>
            <div style={{ position: "relative" }}>
              <input
                type="search"
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid rgba(239, 239, 239, 1)",
                  borderRadius: "30px",
                  paddingLeft: "40px",
                }}
                placeholder="Search by Tenant name"
                value={searchByTenant}
                onChange={(e) => setSearchByTenant(e.target.value)}
                size="small"
              />
              <div style={{ position: "absolute", top: "10px", left: "10px" }}>
                <SearchIcon />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <input
              type="search"
              style={{
                width: "100%",
                height: "40px",
                border: "1px solid rgba(239, 239, 239, 1)",
                borderRadius: "30px",
                padding: "0px 0px 0px 20px",
              }}
              placeholder="Search by Unit/Property"
              value={searchByUnit}
              onChange={(e) => setSearchByUnit(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <input
              type="date"
              style={{
                width: "100%",
                height: "40px",
                border: "1px solid rgba(239, 239, 239, 1)",
                borderRadius: "30px",
                padding: "0px 0px 0px 20px",
              }}
              placeholder="Search By Date"
              value={searchByDate}
              onChange={(e) => setSearchByDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Button
              fullWidth
              style={{
                border: "1px solid rgba(184, 25, 24, 1)",
                borderRadius: "30px",
                color: "rgba(184, 25, 24, 1)",
              }}
              variant="outlined"
              onClick={handleApplyFilter}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "22px",
            lineHeight: "22px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 1)",
            margin: "10px 0px 40px 0px",
          }}
        >
          Draft Contracts
        </Typography>
      </Box> */}

      {/* Table Start */}

      <Box style={{ overflowX: "auto" }}>
        {loading ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"50vh"}
          >
            <Loader />
          </Box>
        ) : tenantsDraftContracts?.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <Typography>No data available</Typography>
          </Box>
        ) : (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
              >
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Contract Name
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Tenant Name
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Unit
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 40px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Created at
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Created by
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Pending Action
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tenantsDraftContracts?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                    {row.ref_id || '-'}
                  </TableCell>
                  <TableCell style={{ padding: "10px 0px 10px 40px" }}>
                    {row.tenant?.first_name || '-'}
                  </TableCell>
                  <TableCell style={{ padding: "10px 0px 10px 40px" }}>
                    {Array.isArray(row.unit) && row.unit?.length > 0
                      ? row.unit?.length
                      : "No Units"}
                  </TableCell>
                  <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                    {row.created_at || '-'}
                  </TableCell>
                  <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                    {row.created_by?.first_name || '-'}
                  </TableCell>
                  <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                    Pending
                  </TableCell>
                  <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                    <Tooltip title="Edit Contract">
                      <EditNoteIcon
                        style={{ color: "rgba(184, 25, 24, 1)" }}
                        onClick={() => {
                          navigate("/admin/contracts/edit-tenant", {
                            state: { tenantContract: row },
                          });
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </>
  );
};

export default DraftContract;
