import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  CustomTextField,
  addPdcBtn,
  aplyfilter,
  mainTypography,
  modalBorder,
  modalTypography,
  newPdcTextfield,
  savePdcBtn,
  tableBodyCell,
  tableBodyCellGreen,
  tableBodyCellRed,
  tableHeadCell,
  useStyles,
} from "./PDCStyles";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import {
  financePdcsTypes,
  PdcStatus,
  PropertyType,
  UserTypesNum,
} from "../../../enums/UserTypes";
import { endpoints } from "../../../apiEndpoints";
import axios from "axios";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";




const PDCs = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const [searchValues, setSearchValues] = useState({
    searchPDC: "",
    searchChequeNo: "",
  });

  const [data, setData] = useState({
    date: "",
    tenantName: "",
    cheque: "",
    property: "",
    unit: "",
    chequeDate: "",
    vat: "",
    amount: "",
    installments: "",
    chequeStatus: "",
    comments: "",
    contract: "",
    chequeType: "",
  });
  const [tenantsListing, setTenantsListing] = useState(null);
  const [propertyListing, setPropertyListing] = useState(null);
  const [unitsListing, setUnitsListing] = useState(null);
  const [contracts, setContracts] = useState(null);
  const [pdcsData, setPdcsData] = useState();
  const [loading, setLoading] = useState({ get: true, post: false });
  const [modalOpen, setModalOpen] = useState(false);

  const handleSearchChange = (name, value) => {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleApply = () => {
    getPDCs();
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchData = async (endpoint, setState, loaderStatus) => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(endpoint, requestOptions);
      setState(response.data.data);


    } catch (error) {
      setState()

    } finally {
      setLoading(loaderStatus);
    }
  };

  const fetchTenants = () => {
    fetchData(
      `${endpoints.getUserListing}?user_type=${UserTypesNum.tenant}`,
      setTenantsListing
    );
  };

  const fetchProperties = () => {
    fetchData(endpoints.getPropertiesListing, setPropertyListing);
  };

  const fetchUnits = () => {
    fetchData(endpoints.getUnitsListing, setUnitsListing);
  };

  const fetchContracts = () => {
    fetchData(endpoints.tenantContracts, setContracts);
  };

  useEffect(() => {
    fetchTenants();
    fetchProperties();
    fetchUnits();
    fetchContracts();
    getPDCs();
  }, []);

  const createPDC = async () => {
    try {
      setLoading({ post: true });
      const formData = new FormData();
      formData.append("tenant", data.tenantName);
      formData.append("contract", data.contract);
      formData.append("payment_number", data.installments);
      formData.append("payment_date", data.date);
      formData.append("amount", data.amount);
      formData.append("vat", data.vat);
      formData.append("cheque_number", data.cheque);
      formData.append("cheque_status", data.chequeStatus);
      if (data.chequeDate) {
        formData.append("cleared_date", data.chequeDate);
      }
      formData.append("comments", data.comments);
      formData.append("unit", JSON.stringify(data.unit ? [data.unit] : []));
      formData.append("property", data.property);
      formData.append("type", data.chequeType);

      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        endpoints.financePDCs,
        formData,
        requestOptions
      );
      toast.success(response.data.message);
      getPDCs();
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading((prev) => ({ ...prev, post: false })); // Reset loading for post
      handleModalClose();
    }
  };

  const getPDCs = () => {
    let pdcsUrl;

    if (
      searchValues &&
      (searchValues.searchPDC || searchValues.searchChequeNo)
    ) {
      pdcsUrl = `${endpoints.financePDCs}?tenant=${searchValues.searchPDC}&cheque_no=${searchValues.searchChequeNo}`;
      fetchData(pdcsUrl, setPdcsData, false);
    } else {
      pdcsUrl = endpoints.financePDCs;
      fetchData(pdcsUrl, setPdcsData, false);
    }
  };


  
  return (
    <>
      <Box>
        <Typography sx={mainTypography}>PDC Document</Typography>
      </Box>
      <Box className={classes.mainBox}>
        <Box className={classes.searchFieldBox}>
          <TextField
            variant="outlined"
            placeholder="Search by Tenant"
            value={searchValues.searchPDC}
            onChange={(e) => handleSearchChange("searchPDC", e.target.value)}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            sx={CustomTextField}
          />
          <TextField
            variant="outlined"
            placeholder="Search by Cheque No"
            value={searchValues.searchChequeNo}
            onChange={(e) =>
              handleSearchChange("searchChequeNo", e.target.value)
            }
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            sx={CustomTextField}
          />
          <Button sx={aplyfilter} onClick={handleApply}>
            Apply Filter
          </Button>
        </Box>
        <Box sx={{ display: "flex", xs: { justifyContent: "flex-start" } }}>
          <Button onClick={handleModalOpen} sx={addPdcBtn}>
            Add PDCs
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : pdcsData?.length === 0 ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={'3.5rem'}>
          <Typography>
            No data available
          </Typography>
        </Box>
      ) : (
        <Box marginTop={"2rem"}>
          <TableContainer sx={{ height: "65vh", overflowX: "auto" }}>
            <Table sx={{ border: "1px solid #EFEFEF", minWidth: "100rem" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeadCell}>Date</TableCell>
                  <TableCell sx={tableHeadCell}>
                    {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        // marginRight: 1,
                      }}
                    >
                      <IconButton
                        sx={{
                          padding: "0",
                          height: "8px",
                          minHeight: "8px",
                        }}
                      >
                        <ArrowDropUpIcon
                          sx={{ fontSize: "30px", color: "black" }}
                        />
                      </IconButton>
                      <IconButton
                        sx={{ padding: "0", height: "8px", minHeight: "8px" }}
                      >
                        <ArrowDropDownIcon
                          sx={{ fontSize: "30px", color: "black" }}
                        />
                      </IconButton>
                    </Box>
                    Tenant Name
                  </Box> */}
                    Tenant Name
                  </TableCell>
                  <TableCell sx={tableHeadCell}>Cheque No</TableCell>
                  <TableCell sx={tableHeadCell}>Property</TableCell>
                  <TableCell sx={tableHeadCell}>Unit</TableCell>
                  <TableCell sx={tableHeadCell}>Unit Type</TableCell>
                  <TableCell sx={tableHeadCell}>Amount</TableCell>
                  <TableCell sx={tableHeadCell}>Installment Number</TableCell>
                  <TableCell sx={tableHeadCell}>Status</TableCell>
                  <TableCell sx={tableHeadCell}>Action</TableCell>
                  <TableCell sx={tableHeadCell}>Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pdcsData?.map((pdc) => (
                  <TableRow key={pdc.id}>
                    <TableCell sx={tableBodyCell}>
                      {pdc.created_at.split("T")[0] || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {pdc.tenant?.first_name || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {pdc.cheque_number || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {pdc.property?.title || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>{pdc.unit?.length}</TableCell>
                    <TableCell sx={tableBodyCell}>
                      {pdc.type == PropertyType.RESIDENTIAL
                        ? "Residential"
                        : pdc.type == PropertyType.COMMERCIAL
                          ? "Commercial"
                          : pdc.type == PropertyType.COMMUNITY
                            ? "Community"
                            : "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {pdc.amount || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {pdc.payment_number || "-"}
                    </TableCell>
                    <TableCell
                      sx={
                        pdc.cheque_status == 3
                          ? tableBodyCellGreen
                          : pdc.cheque_status === 4
                            ? tableBodyCellRed
                            : tableBodyCell
                      }
                    >
                      {pdc.cheque_status == PdcStatus.Awaiting.value
                        ? PdcStatus.Awaiting.label
                        : pdc.cheque_status == PdcStatus.Bounced.value
                          ? PdcStatus.Bounced.label
                          : pdc.cheque_status == PdcStatus.Cleared.value
                            ? PdcStatus.Cleared.label
                            : pdc.cheque_status == PdcStatus.Deposited.value
                              ? PdcStatus.Deposited.label
                              : "-"}
                    </TableCell>
                    <TableCell
                      sx={tableBodyCellRed}
                      onClick={() => {
                        navigate("/finance/edit-pdc", { state: { pdc } });
                      }}
                    >
                      <Tooltip title="Edit PDC" arrow>
                        <EditIcon />
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {pdc.comments || "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Dialog open={modalOpen} onClose={handleModalClose} >
        
        <DialogTitle sx={mainTypography} marginBottom={"1rem"}>
          Add New PDC
        </DialogTitle>
        {loading?.post ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"50vh"}
          >
            <Loader />
          </Box>
        ) : (
          <>
            <DialogContent>
              <Grid container spacing={3}>
                {/* Select Tenant */}
                <Grid item xs={12} sm={6}>
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>Tenant Name</Typography>
                    <FormControl sx={newPdcTextfield} fullWidth>
                      <Select
                        name="tenantName"
                        value={data.tenantName || ""}
                        onChange={handleModalChange}
                        displayEmpty
                        required
                      >
                        <MenuItem value="">Select Tenant</MenuItem>
                        {tenantsListing?.map((tenant) => (
                          <MenuItem key={tenant.id} value={tenant.id}>
                            {tenant.first_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {/* Property */}
                <Grid item xs={12} sm={6}>
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>Property</Typography>
                    <FormControl sx={newPdcTextfield} fullWidth>
                      <Select
                        name="property"
                        value={data.property || ""}
                        onChange={handleModalChange}
                        displayEmpty
                        required
                      >
                        <MenuItem value="">Select Property</MenuItem>
                        {propertyListing?.map((property) => (
                          <MenuItem key={property.id} value={property.id}>
                            {`${property.ref_id} - ${property.title}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {/* Unit  */}
                <Grid item xs={12} sm={6}>
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>Unit</Typography>
                    <FormControl sx={newPdcTextfield} fullWidth>
                      <Select
                        name="unit"
                        value={data.unit || ""}
                        onChange={handleModalChange}
                        displayEmpty
                        required
                      >
                        <MenuItem value="">Select Unit</MenuItem>
                        {unitsListing?.map((unit) => (
                          <MenuItem key={unit.id} value={unit.id}>
                            {`${unit.ref_id} - ${unit.title}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {/* Contracts  */}
                <Grid item xs={12} sm={6}>
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>Contracts</Typography>
                    <FormControl sx={newPdcTextfield} fullWidth>
                      <Select
                        name="contract"
                        value={data.contract || ""}
                        onChange={handleModalChange}
                        displayEmpty
                        required
                      >
                        <MenuItem value="">Select Contract</MenuItem>
                        {contracts?.map((contract) => (
                          <MenuItem key={contract.id} value={contract.id}>
                            {contract.ref_id}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {/*installments Number */}
                <Grid item xs={12} sm={6}>
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>
                      Installment Number
                    </Typography>

                    <TextField
                      sx={newPdcTextfield}
                      placeholder="Installment Number"
                      name="installments"
                      value={data.installments}
                      onChange={handleModalChange}
                      fullWidth
                    />
                  </Box>
                </Grid>
                {/* Date */}
                <Grid item xs={12} sm={6}>
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>Payment Date</Typography>
                    <TextField
                      type="date"
                      value={data.date}
                      name="date"
                      onChange={handleModalChange}
                      sx={newPdcTextfield}
                      fullWidth
                    />
                  </Box>
                </Grid>

                {/* Amount */}
                <Grid item xs={12} sm={6}>
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>Amount</Typography>
                    <TextField
                      sx={newPdcTextfield}
                      placeholder="Add Amount"
                      name="amount"
                      value={data.amount}
                      onChange={handleModalChange}
                      fullWidth
                    />
                  </Box>
                </Grid>

                {/* Vat */}
                <Grid item xs={12} sm={6}>
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>VAT</Typography>
                    <TextField
                      sx={newPdcTextfield}
                      placeholder="VAT"
                      name="vat"
                      value={data.vat}
                      onChange={handleModalChange}
                      fullWidth
                    />
                  </Box>
                </Grid>

                {/* Cheque No */}
                <Grid item xs={12} sm={6}>
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>Cheque Number</Typography>
                    <TextField
                      sx={newPdcTextfield}
                      placeholder="Cheque Number"
                      name="cheque"
                      value={data.cheque}
                      onChange={handleModalChange}
                      fullWidth
                      required
                    />
                  </Box>
                </Grid>

                {/*Cheque Type */}
                <Grid item xs={12} sm={6}>
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>Cheque Type</Typography>
                    <FormControl sx={newPdcTextfield} fullWidth>
                      <Select
                        name="chequeType"
                        value={data.chequeType || ""}
                        onChange={handleModalChange}
                        displayEmpty
                      >
                        <MenuItem value="">Select Type</MenuItem>
                        {Object.entries(financePdcsTypes).map(([key, type]) => (
                          <MenuItem key={key} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                {/*Cheque Status */}
                <Grid
                  item
                  xs={12}
                  sm={data.chequeStatus == PdcStatus.Cleared.value ? 6 : 12}
                >
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>Cheque Status</Typography>
                    <FormControl sx={newPdcTextfield} fullWidth>
                      <Select
                        name="chequeStatus"
                        value={data.chequeStatus || ""}
                        onChange={handleModalChange}
                        displayEmpty
                      >
                        <MenuItem value="">Select Status</MenuItem>
                        {Object.entries(PdcStatus).map(([key, status]) => (
                          <MenuItem key={key} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                {/* Cheque Clear Date */}
                {data.chequeStatus == PdcStatus.Cleared.value && (
                  <Grid item xs={12} sm={6}>
                    <Box className={classes.gridItemBox}>
                      <Typography sx={modalTypography}>
                        Cheque Clear Date
                      </Typography>

                      <TextField
                        sx={newPdcTextfield}
                        type="date"
                        name="chequeDate"
                        value={data.chequeDate}
                        onChange={handleModalChange}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                )}
                {/* Comments */}
                <Grid item xs={12}>
                  <Box className={classes.gridItemBox}>
                    <Typography sx={modalTypography}>Comments</Typography>
                    <TextField
                      sx={newPdcTextfield}
                      placeholder="Comments here"
                      name="comments"
                      value={data.comments}
                      onChange={handleModalChange}
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Box>
                <Button onClick={createPDC} sx={savePdcBtn}>
                  Add PDC
                </Button>
              </Box>
            </DialogActions>
          </>
        )}
        
      </Dialog>
    </>
  );
}

export default PDCs;
