import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Tooltip,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import Popup from "./Popup";
import { tableCell, useStyles } from "../Properties/Propertie/PropertieStyle";
import SearchIcon from "@mui/icons-material/Search";
import { endpoints } from "../../../apiEndpoints";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";

const DataTable = ({ Data, columns }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [contractsData, setContractsData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [searchLandlord, setSearchLandlord] = useState(null);
  const [searchTenant, setSearchTenant] = useState(null);
  const [searchByUnit, setSearchByUnit] = useState(null);
  const [searchByDate, setSearchByDate] = useState(null);
  const tableName = Data;
  const navigate = useNavigate();

  const handleopenPopup = (id) => {
    setSelectedId(id);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Download function for contract documents
  const handleDownloadContract = async (documentUrl) => {
    try {
      if (!documentUrl) {
        toast.error("No document available");
        return;
      }

      const token = localStorage.getItem("token");
      const response = await axios({
        url: documentUrl,
        method: 'GET',
        responseType: 'blob', // Important
        headers: {
          Authorization: `token ${token}`,
        },
      });

      // Create a link element to trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      
      // Extract filename from the URL or use a default name
      const filename = documentUrl.split('/').pop() || 'contract.pdf';
      link.setAttribute('download', filename);
      
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
      toast.error("Failed to download document");
    }
  };

  const getContractsListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };

      let url;
      let queryParams = [];

      if (Data === "Landlord") {
        url = endpoints.landlordContracts;

        if (searchLandlord) {
          queryParams.push(`landlord=${searchLandlord}`);
        }
        if (searchByUnit) {
          queryParams.push(`property=${searchByUnit}`);
        }
        if (searchByDate) {
          queryParams.push(`date=${searchByDate}`);
        }

        // Check if any query parameters were added
        if (queryParams.length > 0) {
          // Join all query parameters with '&' and append to the URL
          url = `${url}?${queryParams.join("&")}`;
        }
      } else if (Data === "Tenant") {
        url = endpoints.tenantContracts;

        if (searchTenant) {
          queryParams.push(`tenant=${searchTenant}`);
        }
        if (searchByUnit) {
          queryParams.push(`property=${searchByUnit}`);
        }
        if (searchByDate) {
          queryParams.push(`date=${searchByDate}`);
        }

        // Check if any query parameters were added
        if (queryParams.length > 0) {
          // Join all query parameters with '&' and append to the URL
          url = `${url}?${queryParams.join("&")}`;
        }
      } else if (Data === "TenantRenewal") {
        url = endpoints.tenancyContractsRenewal;
        if (searchTenant) {
          queryParams.push(`tenant=${searchTenant}`);
        }
        if (searchByUnit) {
          queryParams.push(`property=${searchByUnit}`);
        }
        if (searchByDate) {
          queryParams.push(`date=${searchByDate}`);
        }
        // Check if any query parameters were added
        if (queryParams.length > 0) {
          // Join all query parameters with '&' and append to the URL
          url = `${url}?${queryParams.join("&")}`;
        }
      }

      const response = await axios.get(url, requestOptions);
      const data = response.data.data;
      setContractsData(data);
    } catch (error) {
      setContractsData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row) => {
    navigate(`/admin/client/profiles`, { state: { row, tableName } });
  };

  useEffect(() => {
    getContractsListing();
  }, []);

  return (
    <>
      {showPopup && (
        <Popup
          onClose={handleClosePopup}
          id={selectedId}
          getLandlords={getContractsListing}
          selectedTab={Data}
        />
      )}
      <Box className={classes.root} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4} lg={3}>
            <div style={{ position: "relative" }}>
              <input
                type="search"
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid rgba(239, 239, 239, 1)",
                  borderRadius: "30px",
                  paddingLeft: "40px",
                }}
                // placeholder="Search by Tenant name"
                // value={searchTenant}
                // onChange={(e) => setSearchTenant(e.target.value)}
                placeholder={`Search by ${Data === "Tenant" || Data === "TenantRenewal"
                  ? "Tenant"
                  : "Landlord"
                  } name`}
                value={
                  Data === "Tenant" || Data === "TenantRenewal"
                    ? searchTenant
                    : searchLandlord
                }
                onChange={(e) =>
                  Data === "Tenant" || Data === "TenantRenewal"
                    ? setSearchTenant(e.target.value)
                    : setSearchLandlord(e.target.value)
                }
                size="small"
              />
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                }}
              >
                <SearchIcon />
              </div>
            </div>
          </Grid>

          {/* <Grid item xs={12} md={4} lg={3}>
            <input
              type="search"
              style={{
                width: "100%",
                height: "40px",
                border: "1px solid rgba(239, 239, 239, 1)",
                borderRadius: "30px",
                padding: "0px 0px 0px 20px",
              }}
              placeholder="Search by Unittt/Property"
              value={searchByUnit}
              onChange={(e) => setSearchByUnit(e.target.value)}
            />
          </Grid> */}
          {/* <Grid item xs={12} md={4} lg={3}>
            <input
              type="date"
              style={{
                width: "100%",
                height: "40px",
                border: "1px solid rgba(239, 239, 239, 1)",
                borderRadius: "30px",
                padding: "0px 0px 0px 20px",
                color: "#737679",
              }}
              placeholder="Search By Date"
              value={searchByDate}
              onChange={(e) => setSearchByDate(e.target.value)}
            />
          </Grid> */}
          <Grid item xs={12} md={6} lg={2}>
            <Button
              fullWidth
              style={{
                border: "1px solid rgba(184, 25, 24, 1)",
                borderRadius: "30px",
                color: "rgba(184, 25, 24, 1)",
              }}
              variant="outlined"
              onClick={() => {
                getContractsListing(
                  searchTenant,
                  searchLandlord,
                  searchByUnit,
                  searchByDate
                );
              }}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ overflowX: "auto", height: "55vh" }}>
        {loading ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"50vh"}
          >
            <Loader />
          </Box>
        ) : contractsData?.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <Typography>No data available</Typography>
          </Box>
        ) : (
          <Table sx={{ border: "1px solid #EFEFEF" }}>
            <TableHead>
              <TableRow
                style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
              >
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      padding: "10px 0px 10px 20px",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {contractsData?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.ref_id || "-"}</TableCell>
                  <TableCell
                    sx={tableCell}
                    // onClick={() => {
                    //   handleRowClick(
                    //     Data == "Tenant"
                    //       ? item.tenant.id || "-"
                    //       : item.primary_landlord.id || "-"
                    //   );
                    // }}
                    onClick={() => {
                      handleRowClick(
                        Data == "Tenant" || Data == "TenantRenewal"
                          ? item.tenant?.id || '-'
                          : item.primary_landlord?.id || '-'
                      );
                    }}
                  >
                    {Data === "Landlord"
                      ? item.primary_landlord?.first_name
                      : item.tenant?.first_name}
                  </TableCell>
                  <TableCell>
                    {Data === "Landlord"
                      ? item.property?.ref_id
                      : Array.isArray(item.unit) && item.unit?.length > 0
                        ? item.unit?.length
                        : "No units"}
                  </TableCell>

                  {Data !== "TenantRenewal" && Data !== "LandlordRenewal" && (
                    <TableCell>{item.start_date || '-'}</TableCell>
                  )}
                  {Data !== "TenantRenewal" && Data !== "LandlordRenewal" && (
                    <TableCell>{item.end_date || '-'}</TableCell>
                  )}
                  {/* <TableCell>{item.created_at}</TableCell> */}
                  {Data !== "TenantRenewal" && Data !== "LandlordRenewal" && (
                    <TableCell>{item.created_by?.first_name || '-'}</TableCell>
                  )}

                  {/* <TableCell>{item.created_by}</TableCell> */}

                  <TableCell
                    sx={{
                      color:
                        item.status === "active"
                          ? "rgba(0, 227, 23, 1)"
                          : item.status === "pending"
                            ? "rgba(0, 227, 23, 1)"
                            : "rgba(0, 227, 23, 1)",
                    }}
                  >
                    {item.status}
                  </TableCell>
                  <TableCell>
                    {
                      <>
                        <Tooltip title="Edit Contract">
                          <EditNoteIcon
                            onClick={() => {
                              if (Data === "Landlord") {
                                navigate("/admin/contracts/edit-landlord", {
                                  state: { contractData: item },
                                });
                              } else if (Data === "Tenant") {
                                navigate("/admin/contracts/edit-tenant", {
                                  state: { tenantContract: item },
                                });
                              } else if (Data === "TenantRenewal") {
                                navigate("/admin/edit-renew-form", {
                                  state: { renewContract: item },
                                });
                              }
                            }}
                            style={{ color: "rgba(184, 25, 24, 1)" }}
                          />
                        </Tooltip>
                        <Tooltip title="Delete Contract">
                          <DeleteIcon
                            onClick={() => {
                              handleopenPopup(item.id);
                            }}
                            style={{ color: "rgba(184, 25, 24, 1)" }}
                          />
                        </Tooltip>
                        {item.document && (
                        <Tooltip title="Download Contract">
                          <DownloadIcon
                            onClick={() => handleDownloadContract(item.document)}
                            style={{ color: "rgba(184, 25, 24, 1)" }}
                          />
                        </Tooltip>
                        )}
                      </>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </>
  );
};

export default DataTable;
