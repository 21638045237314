import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Avatar,
  Tooltip,
} from "@mui/material";
import { Card, CardContent, CardActionArea } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { useStyles } from "./PropertieStyle";
import { useLocation } from "react-router-dom";
import { endpoints } from "../../../../apiEndpoints";
import axios from "axios";
import toast from "react-hot-toast";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";

const ViewDetail = () => {
  const classes = useStyles();
  const location = useLocation();
  const { history, tenant, unit, moveInDate, moveOutDate } =
    location.state || {};
  const [PropertyData, setPropertyData] = useState([]);

  const propertyList = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endpoints.Propertylist, {
        headers: { Authorization: `Token ${token}` },
      });
      const data = response.data.data;
      setPropertyData(data);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    propertyList();
  }, []);

  const downloadFile = (fileUrl, type) => {
    if (!fileUrl) {
      alert("Document not available.");
      return;
    }

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${type}.${getFileExtension(fileUrl)}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("Could not fetch the file."));
  };

  function getFileExtension(url) {
    // Use a regular expression to match the file extension
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(url)[1];
    return extension;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4} alignItems="center">
          <Box>
            <Box>
              <img
                src="/image/propertyicon.png"
                alt="this image is not show"
                width={140}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                margin: "10px 0px 0px 0px",
                color: "rgba(184, 25, 24, 1)",
              }}
            >
              <LocationOnRoundedIcon style={{ width: "20px" }} />
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "16px",
                  margin: "0px 0px 0px 10px",
                }}
              >
                {unit?.address}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Empty Grid */}
        </Grid>
      </Grid>

      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
            margin: "40px 0px 30px 0px",
          }}
        >
          Tenant Details
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              color: "rgba(0, 0, 0, 1)",
              margin: "0px 0px 15px 0px",
            }}
          >
            First Name
          </Typography>
          <TextField
            className={classes.Input}
            fullWidth
            variant="outlined"
            value={tenant?.first_name || ""}
            disabled
            size="small"
            style={{
              margin: "0px 0px 25px 0px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Last Name
          </Typography>
          <TextField
            className={classes.Input}
            fullWidth
            variant="outlined"
            value={tenant?.last_name || ""}
            disabled
            size="small"
            style={{ margin: "0px 0px 25px 0px" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Email
          </Typography>
          <TextField
            className={classes.Input}
            fullWidth
            variant="outlined"
            value={tenant?.email || ""}
            disabled
            size="small"
            style={{ margin: "0px 0px 25px 0px" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Phone Number
          </Typography>
          <TextField
            className={classes.Input}
            fullWidth
            variant="outlined"
            value={tenant?.phone || ""}
            disabled
            size="small"
            style={{ margin: "0px 0px 25px 0px" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Emirates Id
          </Typography>
          <TextField
            className={classes.Input}
            fullWidth
            variant="outlined"
            value={tenant?.emirates_id || ""}
            disabled
            size="small"
            style={{ margin: "0px 0px 25px 0px" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Passport Number
          </Typography>
          <TextField
            className={classes.Input}
            fullWidth
            variant="outlined"
            value={tenant?.passport_no || ""}
            disabled
            size="small"
            style={{ margin: "0px 0px 25px 0px" }}
          />
        </Grid>
      </Grid>
      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "20px",
            lineHeight: "20px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
            margin: "20px 0px 30px 0px",
          }}
        >
          Unit Details
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Address
          </Typography>
          <TextField
            className={classes.Input}
            fullWidth
            variant="outlined"
            value={unit?.address || ""}
            disabled
            size="small"
            style={{ margin: "0px 0px 25px 0px" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Unit Number
          </Typography>
          <TextField
            className={classes.Input}
            fullWidth
            variant="outlined"
            value={unit?.ref_id || ""}
            disabled
            size="small"
            style={{ margin: "0px 0px 25px 0px" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Move-In Date
          </Typography>
          <TextField
            className={classes.Input}
            fullWidth
            variant="outlined"
            value={moveInDate || ""}
            disabled
            size="small"
            style={{ margin: "0px 0px 15px 0px" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Move-Out Date
          </Typography>
          <TextField
            className={classes.Input}
            fullWidth
            variant="outlined"
            value={moveOutDate || ""}
            disabled
            size="small"
            style={{ margin: "0px 0px 15px 0px" }}
          />
        </Grid>
      </Grid>
      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
            margin: "40px 0px 30px 0px",
          }}
        >
          Reports
        </Typography>
      </Box>
      <Grid
        container
        spacing={5}
        // border={"1px solid black"}
        // height={"15rem"}
        mb={5}
      >
        {history.move_in_report && (
          <Grid item xs={12} md={8}>
            <Box>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Move-in Report
              </Typography>
            </Box>
            <Box className={classes.reportsBox}>
              <Box className={classes.pdfBox}>
                <Avatar
                  src="/image/pdfimage.png"
                  alt="image"
                  variant="square"
                />
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                  }}
                >
                  {history.move_in_report.split("/").pop()}
                </Typography>
              </Box>

              <Tooltip title={"Download"} arrow>
                <SystemUpdateAltOutlinedIcon
                  style={{ color: "rgba(185, 16, 15, 1)", cursor: "pointer" }}
                  onClick={() => {
                    if (history.move_in_report) {
                      downloadFile(
                        history.move_in_report,
                        history.move_in_report.split("/").pop()
                      );
                    } else {
                      toast.error("Document not available.");
                    }
                  }}
                />
              </Tooltip>
            </Box>
          </Grid>
        )}
        {history.move_out_report && (
          <Grid item xs={12} md={8}>
            <Box>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Move-out Report
              </Typography>
            </Box>
            <Box className={classes.reportsBox}>
              <Box className={classes.pdfBox}>
                <Avatar
                  src="/image/pdfimage.png"
                  alt="image"
                  variant="square"
                />
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                  }}
                >
                  {history.move_out_report.split("/").pop()}
                </Typography>
              </Box>
              <Tooltip title={"Download"} arrow>
                <SystemUpdateAltOutlinedIcon
                  style={{ color: "rgba(185, 16, 15, 1)", cursor: "pointer" }}
                  onClick={() => {
                    if (history.move_out_report) {
                      downloadFile(
                        history.move_out_report,
                        history.move_out_report.split("/").pop()
                      );
                    } else {
                      toast.error("Document not available.");
                    }
                  }}
                />
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ViewDetail;
