import React, { useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  FormControl,
  Typography,
  Select,
  IconButton,
  Box,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { customTextField, useStyles } from "./ClientStyle";
import UserAdd from "./UserAdd";
import { UserTypesNum, UserTypes } from "../../../enums/UserTypes";
import { auth, createUserWithEmailAndPassword } from '../../../firebase';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'




const AddUser = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    userType: "",
    name: "",
    number: "",
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [countryCode, setCountryCode] = useState("+971");
  const [value, setValue] = useState();





  const handelsignup = async () => {
    try {
      // Combine country code and phone number
      const fullNumber = `${countryCode}${formData.number}`; 
  
      // Save the full number in the formData before calling the API
      const updatedFormData = {
        ...formData,
        number: fullNumber, // Update the phone number to include the country code
      };
  
      // Use updatedFormData for signup
      await createUserWithEmailAndPassword(auth, updatedFormData.email, updatedFormData.password);
      console.log("Full Number with Country Code: ", updatedFormData.number);
    } catch (error) {
      console.error("Signup error:", error);
    }
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowSign(true);
    handelsignup();
  };

  const loggedInUserType = JSON.parse(localStorage.getItem("usertype"));

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };


  if (showSign) {
    return <UserAdd userFormData={formData} />;
  }
  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.Sroot}>
          <Box className={classes.rootimage}>
            <Box className={classes.image}>
              <img
                src="/image/logo.png"
                alt="logo pic"
                style={{ width: "100px", height: "80px" }}
              />
            </Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: 400,
                color: "rgba(6, 6, 6, 1)",
              }}
            >
              User Management
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            {loggedInUserType == UserTypes.SuperAdmin ?
              <FormControl className={classes.form}>
                <Typography className={classes.typography}>User*</Typography>
                <Select
                  sx={{
                    backgroundColor: "rgba(239, 239, 239, 1)",
                    borderRadius: "6px",
                    margin: "10px 0px 0px 0px",
                  }}
                  size="small"
                  name="userType"
                  value={formData.userType}
                  onChange={handleChange}
                  displayEmpty
                  
                >
                  <MenuItem value="" disabled>
                    Select User Type
                  </MenuItem>
                  <MenuItem value={UserTypesNum.SuperAdmin}>Admin</MenuItem>
                  <MenuItem value={UserTypesNum.landlord}>Landlord</MenuItem>
                  <MenuItem value={UserTypesNum.tenant}>Tenant</MenuItem>
                  <MenuItem value={UserTypesNum.finance}>Finance</MenuItem>
                </Select>
              </FormControl> :
              <FormControl className={classes.form}>
                <Typography className={classes.typography}>User</Typography>
                <Select
                  sx={{
                    backgroundColor: "rgba(239, 239, 239, 1)",
                    borderRadius: "6px",
                    margin: "10px 0px 0px 0px",
                  }}
                  size="small"
                  name="userType"
                  value={formData.userType}
                  onChange={handleChange}
                  displayEmpty
                  
                >
                  <MenuItem value="" disabled>
                    Select User Type
                  </MenuItem>
                  <MenuItem value={UserTypesNum.landlord}>Landlord</MenuItem>
                  <MenuItem value={UserTypesNum.tenant}>Tenant</MenuItem>
                </Select>
              </FormControl>}

            <Typography
              className={classes.typography}
              sx={{
                margin: "12px 0px 3px 0px",
              }}
            >
              Name
            </Typography>
            <TextField
              className={classes.INput}
              inputProps={{
                style:{
                  backgroundColor:'rgba(239, 239, 239, 0.5)'
                }
              }}
              size="small"
              placeholder="Michael  Rayan"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {/* <Typography
              className={classes.typography}
              sx={{
                margin: "12px 0px 3px 0px",
              }}
            >
              Country Code
            </Typography> */}
            <Typography
              className={classes.typography}
              sx={{
                margin: "12px 0px 3px 0px",
              }}
            >
              Phone No
            </Typography>
            <PhoneInput
              placeholder="Enter phone number"
              value={value}
              onChange={setValue}
              defaultCountry="AE"
              style={{
                backgroundColor: "rgba(239, 239, 239, 0.5)",
                borderRadius: "6px",
                padding: "10px 12px",
                fontSize: "14px",
                width: "100%",
              }}
            />
            <Typography
              className={classes.typography}
              sx={{
                margin: "12px 0px 3px 0px",
              }}
            >
              Email
            </Typography>
            <TextField
              className={classes.INput}
              inputProps={{
                style:{
                  backgroundColor:'rgba(239, 239, 239, 0.5)'
                }
              }}
              
              id="email"
              placeholder="Email"
              name="email"
              autoComplete="email"
              size="small"
              value={formData.email}
              onChange={handleChange}
              fullWidth
            />
            <Typography
              className={classes.typography}
              sx={{
                margin: "12px 0px 3px 0px",
              }}
            >
              Password
            </Typography>
            <TextField
              className={classes.INput}
              
              size="small"
              placeholder="******"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <Button
              className={classes.bph}
              type="submit"
              variant="contained"
              style={{
                fontSize: "15px",
                fontWeight: 500,
                lineHeight: "23.48px",
                font: "Raleway",
                borderRadius: "6px",
                height: "10%",
                backgroundColor: "rgba(184, 25, 24, 1)",
                textTransform: "none",
                margin: "35px 0px 3px 0px",
              }}
            >
              Continue
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AddUser;
