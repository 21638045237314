import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Divider,
  TextField,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";

const CoData = [
  {
    id: "1",
    image: "/image/CO1.png",
    label: "Amer Al Ahbabi",
  },
  {
    id: "2",
    image: "/image/CO2.png",
    label: "Shahmeer Ali",
  },
  {
    id: "3",
    image: "/image/CO3.png",
    label: "Amer Al Ahbabi",
  },
  {
    id: "4",
    image: "/image/CO4.png",
    label: "Amer Al Ahbabi",
  },
  {
    id: "5",
    image: "/image/CO5.png",
    label: "Amer Al Ahbabi",
  },
];

const UnitCoOwners = ({ unit, details }) => {
  const navigate = useNavigate();

  return (
    <>
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        Co-Owners
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12} md={6} alignItems="center">
          <Box>
            <Box sx={{ display: "flex" }}>
              <img
                src={
                  unit?.attachments && unit.attachments.length > 0
                    ? unit?.attachments[0].media
                    : "No image Available"
                }
                onError={(e) => (e.target.src = "/image/propertyicon.png")}
                alt="this image is not show"
                width={450}
                height={250}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "35px",
              margin: "40px 0px 0px 0px",
            }}
          >
            {unit?.title || "Title not available"}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              margin: "10px 0px 0px 0px",
            }}
          >
            {unit?.description || "Description not available"}
          </Typography>
        </Grid>
      </Grid>
      <Box style={{ margin: "25px 0px 0px 0px" }}>
        <Divider />
      </Box>
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 700,
          color: "rgba(0, 0, 0, 1)",
          margin: "20px 0px 10px 0px",
        }}
      >
        Primary Landlords
      </Typography>

      <Grid container spacing={3} mt={1} marginBottom={"2rem"}>
        {details?.co_owners?.map((item, index) => {
          return (
            <Grid item xs={6} sm={4} md={4} lg={4} key={index}>
              <Box style={{ display: "flex" }}>
                <Avatar
                  alt="Travis Howard"
                  src={"/image/userAvatar.jpg"}
                  style={{ width: "60px", height: "60px" }}
                />
                <Typography
                  style={{
                    fontFamily: "Raleway",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "23px",
                    margin: "15px 0px 0px 15px",
                  }}
                >
                  {item.primary_landlord.first_name || "Name not available"}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      {/* Seocndary Landlords */}
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 700,
          color: "rgba(0, 0, 0, 1)",
          margin: "20px 0px 10px 0px",
          display: "inline",
        }}
      >
        Secondary Landlords
      </Typography>
      <Grid container spacing={3} mt={1}>
        {details?.co_owners?.map((item, index) => {
          return (
            <Grid item xs={6} sm={4} md={4} lg={4} key={index}>
              <Box style={{ display: "flex" }}>
                <Avatar
                  alt="Travis Howard"
                  src={"/image/userAvatar.jpg"}
                  style={{ width: "60px", height: "60px" }}
                />
                <Typography
                  style={{
                    fontFamily: "Raleway",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "23px",
                    margin: "15px 0px 0px 15px",
                  }}
                >
                  {item.secondary_landlord.map(
                    (secondaryLandlord) =>
                      secondaryLandlord.secondary_landlord.first_name ||
                      "Name not available"
                  )}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default UnitCoOwners;
