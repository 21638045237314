import React from "react";
import {
    TextField, Button, Grid, IconButton, Select, MenuItem, FormControl, CircularProgress,
    Backdrop,
} from '@mui/material';
import { Typography, Box } from "@mui/material";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Units from "./Units";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useStyles, newIncomeTextfield, } from './UnitStyle';
import { PropertyType, PropertyStatus } from '../../../../enums/UserTypes';
import { endpoints } from '../../../../apiEndpoints';
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const EditUnit = ({ id }) => {
    const classes = useStyles();
    const navigate = useNavigate();


    const [loading, setLoading] = useState(true);
    const [propertyData, setPropertyData] = useState([]);
    const [editable, setEditable] = useState(false);
    const [additionalFields, setAdditionalFields] = useState([]);
    const [flooraddfield, setflooraddfield] = useState([]);
    const [featuresadd, setfeaturesadd] = useState({});
    const [UnitData, setUnitData] = useState({
        title: "",
        property: "",
        description: "",
        type: "",
        property_status: "",
        currency: "",
        lease_price: "",
        price: "",
        price2: "",
        price_prefix: "",
        bedrooms: "",
        bathrooms: "",
        area_size: "",
        size_postfix: "",
        garages: "",
        garage_size: "",
        build_year: "",
    });

    const [firstValue, setFirstValue] = useState('');
    const [secondValue, setSecondValue] = useState('');
    const [thirdValue, setThirdValue] = useState('');


    const handleBack = () => {
        navigate(`/admin/selection/unit`);
    }





    const propertyList = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(endpoints.Propertylist, {
                headers: { Authorization: `Token ${token}` }
            });
            const data = response.data.data;
            setPropertyData(data);

        } catch (error) {

        }
    };

    useEffect(() => {
        propertyList();
    }, []);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setUnitData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }


    const handlefieldChange = (index, e) => {
        const updatedFields = [...additionalFields];
        updatedFields[index][e.target.name] = e.target.value;
        setAdditionalFields(updatedFields);
    };

    const handleAddNew = () => {
        setAdditionalFields([
            ...additionalFields,
            { id: Date.now(), title: '', value: '' },
        ]);
    };


    const handleAddFloor = () => {
        setflooraddfield([
            ...flooraddfield,
            {
                id: Date.now(),
                title: '',
                bedrooms: '',
                bathrooms: '',
                price: '',
                price_postfix: '',
                plan_size: '',
                description: '',
            },
        ]);
    };


    const handlefloorChange = (index, e) => {
        const updatedFields = [...flooraddfield];
        updatedFields[index][e.target.name] = e.target.value;
        setflooraddfield(updatedFields);
    };


    const handleInputChange = (event, label) => {
        // const { checked } = event.target;
        setfeaturesadd(prevfeaturesadd => ({
            ...prevfeaturesadd,
            [label]: event.target.checked
        }));
    };


    const labels = [
        'Covered Parking', 'Visitor Parking', 'Bicycle Racks / Storage', 'Car Wash Station', '1 Car Parking',
        '2 Car Parking', '3 Car Parking', '4 Car Parking', '5 Car Parking', '6 Car Parking', '7 Car Parking',
        '8 Car Parking', '9 Car Parking', '10 Car Parking'

    ];

    const checkboxes = labels.map((label, index) => ({
        id: `checkbox-${index + 1}`,
        label: label
    }));





    const UnitList = async () => {
        setLoading(true);

        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
                `${endpoints.Unitdetail}?id=${id}`,
                {
                    headers: { Authorization: `Token ${token}` },
                }
            );
            const data = response.data.data;
            setUnitData(data);
            setAdditionalFields(data.additional)
            setflooraddfield(data.unit_floor_plans)
            if (data.features && data.features.length > 0) {
                setfeaturesadd(data.features[0]);

            }



        } catch (error) {
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        UnitList();
    }, []);




    const firstOptions = ['Residential', 'Commercial'];
    const secondOptions = {
        'Residential': ['Apartments', 'Townhouses', 'Villa', 'Residential Land'],
        'Commercial': ['Office', 'Restaurant', 'Cloud Kitchen', 'Gym', 'Retail Store',
            'Supermarket', 'Baqala', 'Warehouse', 'Recording Studio', 'Hotel', 'Hotel Apartments',
            'Clinic', 'Hospital', 'Other Commercial Space'
        ],

    };
    const thirdOptions = {
        'Apartments': ['Studio Apartment', '1 Bedroom Apartment', '2 Bedroom Apartment', '3 Bedroom Apartment',
            '4 Bedroom Apartment', '5 Bedroom Apartment', '6 Bedroom Apartment', '7 Bedroom Apartment'],
        'Townhouses': ['1 Bedroom Townhouse', '2 Bedroom Townhouse', '3 Bedroom Townhouse', '4 Bedroom Townhouse',
            '5 Bedroom Townhouse', '6 Bedroom Townhouse', '7 Bedroom Townhouse', '8 Bedroom Townhouse', '9 Bedroom Townhouse',
            '10 Bedroom Townhouse'],
        'Villa': ['2 Bedroom Villa', '3 Bedroom Villa', '4 Bedroom Villa', '5 Bedroom Villa', '6 Bedroom Villa',
            '7 Bedroom Villa', '8 Bedroom Villa', "9 Bedroom Villa", '10 Bedroom Villa', '11 Bedroom Villa',
            '12 Bedroom Villa', '13 Bedroom Villa', '14 Bedroom Villa', '15 Bedroom Villa'
        ],

    };

    const handleFirstChange = (event) => {
        setFirstValue(event.target.value);
        setSecondValue('');
        setThirdValue('');
    };

    const handleSecondChange = (event) => {
        setSecondValue(event.target.value);
        setThirdValue('');
    };

    const handleThirdChange = (event) => {
        setThirdValue(event.target.value);
    };






    const updateUnit = async () => {
        const formData = new FormData();


        const addFieldToFormData = (formData, key, value) => {
            if (value !== undefined && value !== null && value !== '') {
                formData.append(key, value);
            }
        };

        addFieldToFormData(formData, 'title', UnitData.title);
        addFieldToFormData(formData, 'property', UnitData.property);
        addFieldToFormData(formData, 'description', UnitData.description);
        addFieldToFormData(formData, 'type', UnitData.type);
        addFieldToFormData(formData, 'unit_status', UnitData.unit_status);
        addFieldToFormData(formData, 'currency', UnitData.currency);
        addFieldToFormData(formData, 'lease_price', UnitData.lease_price);
        addFieldToFormData(formData, 'price', UnitData.price);
        addFieldToFormData(formData, 'price2', UnitData.price2);
        addFieldToFormData(formData, 'price_prefix', UnitData.price_prefix);
        addFieldToFormData(formData, 'bedrooms', UnitData.bedrooms);
        addFieldToFormData(formData, 'bathrooms', UnitData.bathrooms);
        addFieldToFormData(formData, 'area_size', UnitData.area_size);
        addFieldToFormData(formData, 'size_postfix', UnitData.size_postfix);
        addFieldToFormData(formData, 'garages', UnitData.garages);
        addFieldToFormData(formData, 'garage_size', UnitData.garage_size);
        addFieldToFormData(formData, 'build_year', UnitData.build_year);
        addFieldToFormData(formData, 'address', UnitData.address);
        addFieldToFormData(formData, 'country', UnitData.country);
        addFieldToFormData(formData, 'city', UnitData.city);
        addFieldToFormData(formData, 'postal_code', UnitData.postal_code);
        addFieldToFormData(formData, 'latitude', UnitData.latitude);
        addFieldToFormData(formData, 'longitude', UnitData.longitude);
        addFieldToFormData(formData, 'private_note', UnitData.note);


        formData.append('additional', JSON.stringify(additionalFields));
        formData.append('features', JSON.stringify([featuresadd]));
        formData.append('unit_floors', JSON.stringify(flooraddfield));


        try {
            const token = localStorage.getItem('token');
            const response = await axios.patch(`${endpoints.UpdateUnit}?id=${id}`, formData, {
                headers: { Authorization: `Token ${token}` }
            });
            toast.success(response.data.message);
            handleBack();
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
    };




    const handleEditClick = () => {
        setEditable(true);
    };


    return (
        <>

            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', margin: "0px 0px 30px 0px" }}>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)"
                }}>
                    Edit  Unit
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        variant="contained"
                        onClick={handleEditClick}

                    >
                        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)", fontSize: "40px" }} />
                    </IconButton>
                </Box>
            </Box>

            {/* Unit Data */}
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}

                    >Unit Title</Typography>
                    <TextField fullWidth variant="outlined" placeholder='unit name'
                        disabled={!editable}
                        name="title"
                        value={UnitData.title || ''}
                        onChange={handleChange}
                        sx={newIncomeTextfield}
                        style={{
                            backgroundColor: "rgba(241, 10, 10, 0.03)",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}

                    >Select Property</Typography>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            id="property-select"
                            name="property"
                            value={UnitData.property || ''}
                            onChange={handleChange}
                        >
                            {propertyData.map((property) => (
                                <MenuItem key={property.id} value={property.id}>
                                    {`${property.ref_id} - ${property.title}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Content</Typography>
                    <TextField fullWidth multiline rows={4} variant="outlined" placeholder='Content details'
                        disabled={!editable}
                        name="description"
                        value={UnitData.description || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Type </Typography>
                    {/* <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            name="type"
                            value={UnitData.type || ''}
                            onChange={handleChange}
                            displayEmpty

                        >
                            <MenuItem value="" disabled>
                                Select your Type
                            </MenuItem>
                            <MenuItem value={PropertyType.RESIDENTIAL}>Residential</MenuItem>
                            <MenuItem value={PropertyType.COMMERCIAL}>Commercial</MenuItem>
                        </Select>
                    </FormControl> */}




                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth sx={newIncomeTextfield}>
                                    <Select value={firstValue} onChange={handleFirstChange}
                                      style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                                    >
                                        {firstOptions.map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {firstValue && (
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth style={{ marginTop: 0 }}
                                     
                                    >
                                        <Select value={secondValue} onChange={handleSecondChange}
                                          style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                                        >
                                            {secondOptions[firstValue]?.map((option) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}

                            {secondValue && (
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth style={{ marginTop: 0 }}
                                 
                                    >
                                        <Select value={thirdValue} onChange={handleThirdChange}
                                          style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                                        >
                                            {thirdOptions[secondValue]?.map((option) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </div>








                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Status</Typography>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            name="unit_status"
                            value={UnitData.unit_status || ''}
                            onChange={handleChange}
                            displayEmpty

                        >
                            <MenuItem value="" disabled>
                                Select your Status
                            </MenuItem>
                            <MenuItem value={PropertyStatus.OCCUPIED}>Occupied</MenuItem>
                            <MenuItem value={PropertyStatus.AVAILABALE}>Available</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Label</Typography>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            name="Brand"
                            value={UnitData.Brand || ''}
                            onChange={handleChange}
                            displayEmpty

                        >
                            <MenuItem value="" disabled>
                                Select your Brand
                            </MenuItem>
                            <MenuItem value="Penthouse">Penthouse</MenuItem>
                            <MenuItem value="Duplex">Duplex</MenuItem>
                            <MenuItem value="Maisonette">Maisonette</MenuItem>
                            <MenuItem value="Loft">Loft</MenuItem>
                            <MenuItem value="Standalone">Standalone</MenuItem>
                            <MenuItem value="Sea-Facing">Sea-Facing</MenuItem>
                            <MenuItem value="Waterfront">Waterfront</MenuItem>
                            <MenuItem value="Canal-Facing">Canal-Facing</MenuItem>
                            <MenuItem value="City-Facing">City-Facing</MenuItem>
                            <MenuItem value="Eco-Friendly">Eco-Friendly</MenuItem>
                            <MenuItem value="Burj Khalifa View">Burj Khalifa View</MenuItem>
                            <MenuItem value="Ocean View">Ocean View</MenuItem>
                            <MenuItem value="Yacht Dock">Yacht Dock</MenuItem>
                            <MenuItem value="Helipad">Helipad</MenuItem>
                            <MenuItem value="Vehicle Elevator">Vehicle Elevator</MenuItem>
                            <MenuItem value="Private Elevator">Private Elevator</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12}>
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontWeight: 400,
                        fontSize: "20px",
                        lineHeight: "20px",
                        margin: "10px 0px 10px 0px"
                    }}>
                        Price
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Currency</Typography>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                          style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            disabled={!editable}
                            name="currency"
                            value={UnitData.currency || ''}
                            onChange={handleChange}
                            displayEmpty
                            required
                        >
                            <MenuItem value="" disabled>
                                Select your Currency
                            </MenuItem>
                            <MenuItem value='AED'>AED</MenuItem>
                            <MenuItem value='USD'>USD</MenuItem>
                            <MenuItem value='SAR'>SAR</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Sale or Rent Price</Typography>
                    <TextField fullWidth variant="outlined" placeholder='1000'
                        disabled={!editable}
                        name="lease_price"
                        value={UnitData.lease_price || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Second Price</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the second price (optional)'
                        disabled={!editable}
                        name="price"
                        value={UnitData.price || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >After the price</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the after price'
                        disabled={!editable}
                        name="price2"
                        value={UnitData.price2 || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Price Prefix</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the price prefix'
                        disabled={!editable}
                        name="price_prefix"
                        value={UnitData.price_prefix || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>

            {/* Add Media Data */}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Media
                </Typography>
            </Box>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "18px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Images
                </Typography>
            </Box>
            <Grid container >
                {UnitData?.attachments?.length > 0 ? (
                    UnitData?.attachments?.map((attachment, index) => (
                        <Grid item xs={6} sm={4} md={3} key={index}>
                            <img
                                src={attachment.media}
                                alt={`Image ${index}`}
                                style={{ height: "170px", width: "180px" }}
                            />
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            Image not available
                        </div>
                    </Grid>
                )}

            </Grid>


            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "18px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Video
                </Typography>
            </Box>
            <Grid item xs={12} sm={6} md={2}>
                {UnitData.media_url ? (
                    <iframe
                        width="100%"
                        height="415"
                        src={UnitData.media_url}
                        title="Video"
                        frameBorder="0"
                        allowFullScreen
                        style={{ borderRadius: "20px" }}
                    ></iframe>
                ) : (
                    <div style={{ height: "415px", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #ccc", borderRadius: "20px" }}>
                        No video available
                    </div>
                )}
            </Grid>

            {/* Features */}

            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Features
                </Typography>
            </Box>



            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "18px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "10px 0px 10px 0px"
                }}>
                    Parking Spaces
                </Typography>

                <Grid container >
                    {checkboxes.map((checkbox) => (
                        <Grid item xs={4} sm={4} md={3} key={checkbox.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={featuresadd[checkbox.label] || false}
                                        onChange={(e) => handleInputChange(e, checkbox.label)}
                                        name={checkbox.id}
                                    />
                                }
                                label={checkbox.label}
                            />
                        </Grid>
                    ))}
                </Grid>

            </Box>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Details
                </Typography>
            </Box>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}

                    >Bedrooms</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the number of bedrooms (only digits)'
                        disabled={!editable}
                        name="bedrooms"
                        value={UnitData.bedrooms || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Bathrooms</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the number of bedrooms (only digits)'
                        disabled={!editable}
                        name="bathrooms"
                        value={UnitData.bathrooms || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Area Size</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Property Area Size (only digits)'
                        disabled={!editable}
                        name="area_size"
                        value={UnitData.area_size || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Size Postfix</Typography>
                    <TextField fullWidth variant="outlined" placeholder='ft* (Sq Ft)'
                        disabled={!editable}
                        name="size_postfix"
                        value={UnitData.size_postfix || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Garages</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the number of garages (only digits)'
                        disabled={!editable}
                        name="garages"
                        value={UnitData.garages || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Garages Size</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the Garage size (Ex 200 Sq Ft)'
                        disabled={!editable}
                        name="garage_size"
                        value={UnitData.garage_size || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Year Build</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Year Build (only digits)'
                        disabled={!editable}
                        name="build_year"
                        value={UnitData.build_year || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "20px",
                    margin: "10px 0px 10px 0px"
                }}>
                    Additional  Details
                </Typography>
            </Grid>

            {additionalFields.map((additional, index) => (
                <Grid container spacing={2} mt={1} key={additional.id}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >
                            Title
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Eg: Equipment"
                            value={additional.title}
                            name="title"
                            onChange={(e) => handlefieldChange(index, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >
                            Value
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Eg: Grill- Gas"
                            value={additional.value}
                            name="value"
                            onChange={(e) => handlefieldChange(index, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        />
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Button
                    style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 20px 0px"
                    }}
                    variant="contained"
                    onClick={handleAddNew} // Add new field when clicked
                >
                    Add New
                </Button>
            </Grid>


            {/* Add Location  */}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Location
                </Typography>
            </Box>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}

                    >Address</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Address'
                        disabled={!editable}
                        name="address"
                        value={UnitData.address || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Country</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the Country'
                        disabled={!editable}
                        name="country"
                        value={UnitData.country || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >City</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter City'
                        disabled={!editable}
                        name="city"
                        value={UnitData.city || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Area</Typography>
                    <TextField fullWidth variant="outlined" placeholder='ft* (Sq Ft)'
                        disabled={!editable}
                        name="area"
                        value={UnitData.area || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={12} >
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Postal number/zip</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Postal number/zip'
                        disabled={!editable}
                        name="postal_code"
                        value={UnitData.postal_code || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "20px",
                            lineHeight: "20px",
                            margin: "20px 0px 15px 0px"
                        }}
                    >Map</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    map
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Latitude</Typography>
                    <TextField fullWidth variant="outlined" placeholder="25° 16' 37.1532'' N"
                        disabled={!editable}
                        name="latitude"
                        value={UnitData.latitude || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "15px 0px 15px 0px"
                        }}
                    >Longitude</Typography>
                    <TextField fullWidth variant="outlined" placeholder="55° 17' 46.4964'' E"
                        disabled={!editable}
                        name="longitude"
                        value={UnitData.longitude || ''}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>

            {/* 360 Virtual tour */}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    360 Virtual Tour
                </Typography>
            </Box>
            <Grid item xs={12} sm={6} md={2}>
                {/* <iframe
                    width="100%"
                    height="415"
                    src="https://www.youtube.com/embed/your-video-id"
                    title="Video"
                    frameBorder="0"
                    allowFullScreen
                    style={{ borderRadius: "20px" }}
                ></iframe> */}
            </Grid>
            {/* Add Floor Data */}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Floor Listing
                </Typography>
            </Box>


            {flooraddfield.map((additional, index) => (
                <Grid container spacing={2} alignItems="center" justifyContent="center" key={additional.id}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}

                        >Title</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the Plan Title'
                            disabled={!editable}
                            name="title"
                            value={additional.title}
                            onChange={(e) => handlefloorChange(index, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Bedroom</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the number of bedrooms (only digits)'
                            disabled={!editable}
                            name="bedrooms"
                            value={additional.bedrooms}
                            onChange={(e) => handlefloorChange(index, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Bathroom</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the number of bathroom (only digits)'
                            disabled={!editable}
                            name="bathrooms"
                            value={additional.bathrooms}
                            onChange={(e) => handlefloorChange(index, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Price</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the Price'
                            disabled={!editable}
                            name="price"
                            value={additional.price}
                            onChange={(e) => handlefloorChange(index, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Price Postfix</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the Price Postfix'
                            disabled={!editable}
                            name="price_postfix"
                            value={additional.price_postfix}
                            onChange={(e) => handlefloorChange(index, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Plan Size</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter your Plan Size'
                            disabled={!editable}
                            name="plan_size"
                            value={additional.plan_size}
                            onChange={(e) => handlefloorChange(index, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Plan Image</Typography>
                        {/* <input
                            type="file"
                            style={{ width: '100%', padding: '10px 0' }}
                        />
                        {images[index] && (
                            <img src={URL.createObjectURL(images[index])} alt="Floor Plan" width={15} />
                        )} */}

                    </Grid>
                    <Grid item xs={12} md={12} mb={2}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Description</Typography>
                        <TextField fullWidth multiline rows={4} variant="outlined" placeholder='Enter the plan description.'
                            disabled={!editable}
                            name="description"
                            value={additional.description}
                            onChange={(e) => handlefloorChange(index, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                </Grid>

            ))}
            <Box>
                <Button style={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 0px 20px 0px"

                }}
                    variant="contained"
                    onClick={handleAddFloor}
                >Add New</Button>
            </Box>



            {/* Add Note Data */}

            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Private Note
                </Typography>
            </Box>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <TextField fullWidth multiline rows={5} variant="outlined" placeholder='Enter note here.'
                        disabled={!editable}
                        name="note"
                        value={UnitData.private_note || ''}
                        // onChange={(event) => handleInputChange('note', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Signed Agreement
                </Typography>
            </Box>
            <Grid container>
                <Box style={{
                    backgroundColor: "rgba(241, 10, 10, 0.03)",
                    borderRadius: "8px",
                    padding: "25px 25px 25px 25px",
                    margin: "0px 0px 0px 0px"
                }}>
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontWeight: 400,
                        fontSize: "18px",
                        lineHeight: "38px"
                    }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.<br />
                        Tempor incididunt ut labore et dolore magna aliqua.<br />
                        Ut enim ad minim  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea  commodo consequat.<br />
                    </Typography>
                </Box>
            </Grid>
            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                <Button style={{
                    border: "1px solid rgba(154, 25, 21, 1)",
                    color: "rgba(154, 25, 21, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "170px",
                    height: "40px",
                    margin: "20px 0px 50px 15px"
                }}
                    variant="outlined"
                    onClick={handleBack}

                >Back</Button>
                <Box sx={{ display: 'flex', }}>
                    <Button style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "170px",
                        height: "40px",
                        margin: "20px 0px 50px 0px"

                    }}
                        variant="contained"
                        onClick={updateUnit}
                    >Update Unit</Button>
                </Box>
            </Box>
        </>
    );
}

export default EditUnit;


