import {
  Box,
  Typography,
  Grid,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Button
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  fieldHeading,
  mainTypography,
  tableBodyCell,
  tableBodyCellRed,
  tableBodyCellgreen,
  tableHeadCell,
  tenantTextfield,
  useStyles,
} from "./TenantStyles";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { useLocation } from "react-router-dom";
import { PropertyList, PropertyType } from '../../../enums/UserTypes';
import { Modal, TableCell as MuiTableCell } from "@mui/material";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";







const EditTenant = () => {
  const classes = useStyles();
  const [tenantData, settenantData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [cashFlowUnits, setCashFlowUnits] = useState(null);
  const location = useLocation();
  const { state } = location;
  const { id } = state || {};


  console.log("This is recived id", id)

  const GetTenantData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${endpoints.financeTenantlisting}?id=${id}`, {
        headers: { Authorization: `Token ${token}` }
      });
      const result = (response.data.data[0] || {})
      settenantData(result);

    } catch (error) {
      console.log("Error fetching user data", error);

    }
  };

  useEffect(() => {
    GetTenantData();
  }, []);



  const tenant = tenantData?.contract || [];

  console.log("this is contract data", tenant)


  console.log("this is pdcs data", tenant.pdcs)


  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    id: "",
    startDate: "",
    endDate: "",
    property: "",
    type: "",
    status: "",
    tawteeqNo: "",
    unit: "",
  });

  const tableRows = [
    {
      date: "06-07-2024",
      name: "Rayyan",
      number: "1234567889965",
      Property: "PropertyName",
      unit: "305123456789",
      type: "Type",
      amount: "3000000 AED",
      installmennts: "10000",
      status: "Cleared",
    },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };


  const getStatusText = (status) => {
    return PropertyList[status] || "Unknown Status";
  };



  const handleOpen = (units) => {
    setCashFlowUnits(units);
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);

  return (
    <>
      <Box>
        <Typography sx={mainTypography}>Tenant Details</Typography>
      </Box>
      <Grid container spacing={3} marginTop={"2rem"} marginBottom={"1rem"}>
        {/* Name */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Name</Typography>
            </Box>
            <Box>
              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
                
                placeholder="Rayyan"
                name="name"
                value={tenantData?.first_name}

                sx={tenantTextfield}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        {/* Email */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Email</Typography>
            </Box>
            <Box>
              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
                placeholder="Rayyan@uae.com"
                name="email"
                value={tenantData?.email}
                sx={tenantTextfield}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        {/* Phone Number */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Phone Number</Typography>
            </Box>
            <Box>
              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
             
                placeholder="+12345678"
                name="phone"
                value={tenantData?.phone}
                sx={tenantTextfield}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        {/* Emirates Id */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Emirates Id</Typography>
            </Box>
            <Box>
              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
              
                placeholder="12345678"
                name="id"
                value={tenantData?.emirates_id}
                sx={tenantTextfield}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        {/* Starting Date */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Contract Start Date</Typography>
            </Box>
            <Box>
              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
              
                value={tenant?.start_date}

                sx={tenantTextfield}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        {/* Ending Date */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Contract End Date</Typography>
            </Box>
            <Box>
              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
               
                value={tenant?.end_date}
                sx={tenantTextfield}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        {/* Property */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Property</Typography>
            </Box>
            <Box>
              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
              
                placeholder="Property Name"
                name="property"
                value={tenant.property?.title}

                sx={tenantTextfield}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        {/* Prperty Type */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Property Type</Typography>
            </Box>
            <Box>
              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
              
                value={getStatusText(tenant.property?.type)}

                sx={tenantTextfield}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        {/* Status */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Status</Typography>
            </Box>
            <Box>

              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
              
                name="status"
                value={tenant?.contract_status}
                onChange={handleChange}
                sx={tenantTextfield}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        {/* Tawteeq no */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Tawteeq No</Typography>
            </Box>
            <Box>
              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
                placeholder="123456787432343"
                name="tawteeqNo"
                value={data.tawteeqNo}
                onChange={handleChange}
                sx={tenantTextfield}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        {/* Unit */}



        <Grid item xs={12} sm={10}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Unit</Typography>
            </Box>
            <Box>
              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
                disabled
                value={tenant?.unit
                  ?.map((item) => item.ref_id)
                  .join(", ") || "units not available"}

                sx={tenantTextfield}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>








        {/* <Grid item sm={3}>
          <Button onClick={handleSubmit} sx={procesBtn}>
            Process
          </Button>
        </Grid> */}
      </Grid>

      <Box marginTop={"2rem"}>
        <Box marginBottom={"1rem"}>
          <Typography sx={mainTypography}>PDCs</Typography>
        </Box>
        <TableContainer sx={{ height: "65vh", overflowX: "auto" }}>
          <Table sx={{ border: "1px solid #EFEFEF", minWidth: "85rem " }}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeadCell}>Date</TableCell>

                <TableCell sx={tableHeadCell}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        // marginRight: 1,
                      }}
                    >
                      <IconButton
                        sx={{
                          padding: "0",
                          height: "8px",
                          minHeight: "8px",
                        }}
                      >
                        <ArrowDropUpIcon
                          sx={{ fontSize: "30px", color: "black" }}
                        />
                      </IconButton>
                      <IconButton
                        sx={{ padding: "0", height: "8px", minHeight: "8px" }}
                      >
                        <ArrowDropDownIcon
                          sx={{ fontSize: "30px", color: "black" }}
                        />
                      </IconButton>
                    </Box>
                    Tenant Name
                  </Box>
                </TableCell>
                <TableCell sx={tableHeadCell}>Cheque No</TableCell>
                <TableCell sx={tableHeadCell}>Property</TableCell>
                <TableCell sx={tableHeadCell}>Unit</TableCell>
                <TableCell sx={tableHeadCell}>Unit Type </TableCell>
                <TableCell sx={tableHeadCell}>Amount</TableCell>
                <TableCell sx={tableHeadCell}>
                  Num of Installments Bank
                </TableCell>
                <TableCell sx={tableHeadCell}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tenant.pdcs?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={tableBodyCell}>{item.payment_date}</TableCell>
                  <TableCell sx={tableBodyCell}>{item.tenant.first_name}</TableCell>
                  <TableCell sx={tableBodyCell}>{item.cheque_number}</TableCell>
                  <TableCell sx={tableBodyCell}>{item.property.title}</TableCell>
                  <TableCell sx={tableBodyCell}>
                    {(item.unit && item.unit.length > 0 && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <span>{item.unit[0].ref_id}</span>
                        <Tooltip title="View All Units" arrow>
                          <ArrowDropDownCircleOutlinedIcon
                            onClick={() => handleOpen(item.unit)}
                            sx={{ marginLeft: "1rem" }}
                          />
                        </Tooltip>
                      </Box>
                    )) ||
                      "-"}
                  </TableCell>
                  <TableCell sx={tableBodyCell}>
                    {item.unit && item.unit.length > 0
                      ? item.unit[0].type == PropertyType.RESIDENTIAL
                        ? "Residential"
                        : item.unit[0].type == PropertyType.COMMERCIAL
                          ? "Commercial"
                          : item.unit[0].type == PropertyType.COMMUNITY
                            ? "Community"
                            : "-"
                      : "-"}
                  </TableCell>
                  <TableCell sx={tableBodyCell}>{item.amount}</TableCell>
                  <TableCell sx={tableBodyCell}>{item.payment_number}</TableCell>
                  <TableCell
                    sx={
                      item.status === "Cleared"
                        ? tableBodyCellgreen
                        : item.status === "Bounced"
                          ? tableBodyCellRed
                          : tableBodyCell
                    }
                  >
                    {item.check_status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>






      <Modal open={modalOpen} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "80%",
            maxWidth: "600px",
          }}
        >
          <Typography sx={mainTypography} gutterBottom>
            Unit Information
          </Typography>
          <Table sx={{ border: "1px solid #EFEFEF" }}>
            <TableHead>
              <TableRow>
                <MuiTableCell sx={tableHeadCell}>Unit Number</MuiTableCell>
                <MuiTableCell sx={tableHeadCell}>Unit Type</MuiTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cashFlowUnits?.length > 0 ? (
                cashFlowUnits?.map((unit) => (
                  <TableRow key={unit.id}>
                    <MuiTableCell sx={tableBodyCell}>
                      {unit.ref_id}
                    </MuiTableCell>
                    <MuiTableCell sx={tableBodyCell}>
                      {unit.type == PropertyType.RESIDENTIAL
                        ? "Residential"
                        : PropertyType.COMMERCIAL
                          ? "Commercial"
                          : PropertyType.COMMUNITY
                            ? "Community"
                            : "-"}
                    </MuiTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <MuiTableCell colSpan={2}>
                    No units available
                  </MuiTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Button
            onClick={handleClose}
            variant="contained"
            color="success"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>







    </>
  );
}

export default EditTenant;
