import React from "react";
import { Box, Typography, Button } from "@mui/material";
import AddLocation from "./AddLocation";
import AddFloor from "./AddFloor";
import { useState } from "react";



const AddVirtualTour = () => {
    const [open, setopen] = useState(false);
    const [backcom, setbackcom] = useState(false);


    const handleBack = () => {
        setbackcom(true)
    }
    if (backcom) {
        return (
            <AddLocation />
        )
    }
    const handleNext = () => {
        setopen(true)
    }
    if (open) {
        return (
            <AddFloor />
        )
    }
    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Add Property
                </Typography>
            </Box>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    360 Virtual Tour
                </Typography>
            </Box>
            {/* <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                    <Button style={{
                        border: "1px solid rgba(154, 25, 21, 1)",
                        color: "rgba(154, 25, 21, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"
                    }}
                        variant="outlined"
                        onClick={handleBack}

                    >Back</Button>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            margin: "20px 0px 50px 15px"

                        }}
                            variant="contained"
                            onClick={handleNext}
                        >Next</Button>
                    </Box>
                </Box> */}



            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Box sx={{ marginTop: 'auto', display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                    <Button style={{
                        border: "1px solid rgba(154, 25, 21, 1)",
                        color: "rgba(154, 25, 21, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"
                    }}
                        variant="outlined"
                        onClick={handleBack}
                    >Back</Button>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            margin: "20px 0px 50px 15px"
                        }}
                            variant="contained"
                            onClick={handleNext}
                        >Next</Button>
                    </Box>
                </Box>
            </Box>



        </>
    )
}


export default AddVirtualTour;