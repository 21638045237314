import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';


export const useStyles = makeStyles(() => {
    const theme = useTheme();
    return {
        graph: {
            backgroundColor: "rgb(255,255,255)",
            border: "1px solid rgba(173, 173, 173, 1)",
            borderRadius: "5.8px",
            // boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)',
            margin: "25px 0px 0px 0px",
            padding: "0px 0px 0px 0px"
        },
        box: {
            backgroundColor: "rgb(255,255,255)",
            border: "1px solid rgba(173, 173, 173, 1)",
            borderRadius: "5.8px",
            margin: "0px 0px 0px 0px",
            padding: "0px 0px 0px 0px"
        },
        boxone: {
            display: "flex",
        },
        grap: {
            backgroundColor: "rgb(255,255,255)",
            border: "1px solid rgba(173, 173, 173, 1)",
            borderRadius: "5.8px",
            // boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)',
            margin: "25px 0px 0px 0px",
            padding: "15px"
        },
        legendDot: {
            height: '15px',
            width: '15px',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '5px',
        },
        occupied: {
            backgroundColor: '#B81918',
        },
        vacant: {
            backgroundColor: '#808080',
            margin: "0px 0px 0px 13px"
        },
        profit: {
            backgroundColor: '#4AC440',
            margin: "0px 0px 0px 13px"
        },
    };
});

