import React, { useEffect } from "react";
import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AMCPopup from "./AMCPopup";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { PropertyType } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";
import toast from "react-hot-toast";

const AMCSchedule = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [Amcs, setAmcs] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleopen = () => {
    setShowPopup(true);
  };

  const AmcScheduleListing = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(endpoints.AmcSchedule, requestOptions);
      setAmcs(response.data.data);
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  const calculateInstallments = (totalAmount, numberOfPayments, startDate) => {
    if (!totalAmount || !numberOfPayments || !startDate) return [];
    
    const installmentAmount = totalAmount / numberOfPayments;
    const installments = [];
    const baseDate = new Date(startDate);
    
    for (let i = 0; i < numberOfPayments; i++) {
      const date = new Date(baseDate);
      date.setMonth(date.getMonth() + i);
      
      installments.push({
        date: date.toISOString().split('T')[0],
        amount: installmentAmount.toFixed(2)
      });
    }
    
    return installments;
  };

  const handleAmcSubmit = async (amcData) => {
    try {
      const installments = calculateInstallments(
        amcData.amount,
        amcData.number_of_payments,
        amcData.start_date
      );

      const requestData = {
        ...amcData,
        installments: installments
      };

      const response = await axios.post(endpoints.AmcSchedule, requestData, {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        toast.success('AMC Schedule created successfully');
        AmcScheduleListing();
        handleClosePopup();
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error creating AMC Schedule');
    }
  };

  useEffect(() => {
    AmcScheduleListing();
  }, []);

  return (
    <>
      {showPopup && (
        <AMCPopup
          onClose={handleClosePopup}
          AmcScheduleListing={AmcScheduleListing}
          onSubmit={handleAmcSubmit}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          AMC Schedule
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{
              backgroundColor: "rgba(184, 25, 24, 1)",
              fontSize: "14px",
              lineHeight: "16px",
              fontWeight: 500,
              textTransform: "none",
              width: "130px",
              height: "30px",
            }}
            variant="contained"
            onClick={handleopen}
          >
            Add New
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box
          display={"flex"}
          height={"100vh"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Loader />
        </Box>
      ) : Amcs.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <Box style={{ overflowX: "auto" }} mt={4}>
          <Table
            aria-label="simple table"
            sx={{ border: "1px solid #EFEFEF", minWidth: "200rem" }}
          >
            <TableHead>
              <TableRow
                style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
              >
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Serial Number
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Reference No
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Date
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Property
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Type
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Category
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Service Provider
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Start Date
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  End Date
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Description
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Amount
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Number of Payment
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  1st Date
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  1st Amount
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  2nd Date
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  2nd Amount
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  3rd Date
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  3rd Amount
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  4th Date
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  4th Amount
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  5th Date
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  5th Amount
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  6th Date
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  6th Amount
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Comments
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Amcs?.map((amc, index) => (
                <TableRow key={amc.id}>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{`S. No ${index + 1}`}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.reference_no || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.created_at.split("T")[0] || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc?.property?.ref_id || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                    {amc?.property?.type === PropertyType.RESIDENTIAL
                      ? "Residential"
                      : amc?.property?.type === PropertyType.COMMERCIAL
                        ? "Commercial"
                        : amc?.property?.type === PropertyType.COMMUNITY
                          ? "Community"
                          : "" || "-"}
                  </TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.category || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.service_provider || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.start_date || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.end_date || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.description || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.amount || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.number_of_payments || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.first_date || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.first_amount || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.second_date || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.second_amount || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.third_date || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.third_amount || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.fourth_date || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.fourth_amount || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.fifth_date || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.fifth_amount || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.sixth_date || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.sixth_amount || "-"}</TableCell>
                  <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{amc.comments || "-"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </>
  );
};

export default AMCSchedule;
