import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Backdrop,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useStyles, newIncomeTextfield, selectTextField } from "./PopupStyle";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { AMCcategorize, AMCserviceProvider } from "../../../enums/UserTypes";

const AMCPopup = ({ onClose, AmcScheduleListing, onSubmit }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState(null);
  const [formData, setFormData] = useState({
    reference_no: '',
    property: '',
    category: '',
    service_provider: '',
    start_date: '',
    end_date: '',
    description: '',
    amount: '',
    number_of_payments: '',
    comments: ''
  });

  const [installments, setInstallments] = useState([]);

  const calculateInstallments = useCallback(() => {
    if (!formData.amount || !formData.number_of_payments || !formData.start_date) return;

    const totalAmount = parseFloat(formData.amount);
    const numberOfPayments = parseInt(formData.number_of_payments);
    const installmentAmount = totalAmount / numberOfPayments;
    
    const newInstallments = [];
    const baseDate = new Date(formData.start_date);
    
    for (let i = 0; i < numberOfPayments; i++) {
      const date = new Date(baseDate);
      date.setMonth(date.getMonth() + i);
      
      newInstallments.push({
        date: date.toISOString().split('T')[0],
        amount: installmentAmount.toFixed(2)
      });
    }
    
    setInstallments(newInstallments);
  }, [formData.amount, formData.number_of_payments, formData.start_date]);

  useEffect(() => {
    calculateInstallments();
  }, [calculateInstallments]);

  const propertyListing = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        endpoints.getPropertiesListing,
        requestOptions
      );

      setPropertyList(response.data.data);
    } catch { }
  };

  const handleFormChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value
    });
  };

  const createAmcSchedule = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        property: formData.property,
        category: formData.category,
        service_provider: formData.service_provider,
        start_date: formData.start_date,
        end_date: formData.end_date,
        description: formData.description,
        comments: formData.comments,
        amount: formData.amount,
        number_of_payments: formData.number_of_payments,
        installments: installments
      };
      
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      
      setLoading(true);
      const response = await axios.post(
        endpoints.AmcSchedule,
        payload,
        requestOptions
      );
      toast.success(response.data.message);
      onClose();
      AmcScheduleListing();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    propertyListing();
  }, []);

  return (
    <>
      <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="popup-modal-title"
        aria-describedby="popup-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className={classes.Poproot}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              Add New AMC
            </Typography>
          </Box>
          {loading && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <form onSubmit={createAmcSchedule}>
            <Grid container spacing={2} mt={3} mb={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Property
                </Typography>

                <FormControl fullWidth sx={selectTextField}>
                  <Select
                    displayEmpty
                    value={formData.property || ""}
                    onChange={handleFormChange("property")}
                    
                  >
                    <MenuItem value="">Select Property</MenuItem>
                    {propertyList?.map((property) => (
                      <MenuItem value={property.id} key={property.id}>
                        {`${property.ref_id} - ${property.title}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Category
                </Typography>
                <FormControl sx={selectTextField} fullWidth>
                  <Select
                    value={formData.category || ""}
                    onChange={handleFormChange("category")}
                    displayEmpty
                    
                  >
                    <MenuItem value="">Select Category</MenuItem>
                    {Object.values(AMCcategorize).map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} mb={2}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Service Provider
                </Typography>
                <FormControl sx={selectTextField} fullWidth>
                  <Select
                    value={formData.service_provider || ""}
                    onChange={handleFormChange("service_provider")}
                    displayEmpty
                    
                  >
                    <MenuItem value="">Select Service Provider</MenuItem>
                    {Object.values(AMCserviceProvider).map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Start Date
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  size="small"
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                  onChange={handleFormChange("start_date")}
                  value={formData.start_date || ""}
                  
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  End Date
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  size="small"
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                  onChange={handleFormChange("end_date")}
                  value={formData.end_date || ""}
                  
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Amount
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Amount"
                  size="small"
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                  onChange={handleFormChange("amount")}
                  value={formData.amount || ""}
                  
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Number Of Payments
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Number Of Payments"
                  size="small"
                  sx={newIncomeTextfield}
                  onChange={handleFormChange("number_of_payments")}
                  value={formData.number_of_payments || ""}
                  
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Description
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  placeholder="Description"
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                  onChange={handleFormChange("description")}
                  value={formData.description || ""}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Comments
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  placeholder="Additional Comments"
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                  onChange={handleFormChange("comments")}
                  value={formData.comments || ""}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Installments
                </Typography>
                <Table
                  aria-label="installments table"
                  sx={{ border: "1px solid #EFEFEF", minWidth: "100%" }}
                >
                  <TableHead>
                    <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                      <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Payment No</TableCell>
                      <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Amount</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {installments.map((installment, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{index + 1}</TableCell>
                        <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>{installment.amount}</TableCell>
                        <TableCell>{installment.date}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", margin: "20px 0 50px 0" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "130px",
                  height: "40px",
                }}
                variant="contained"
                type="submit"
                disabled={loading}
              >
                Add
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AMCPopup;
