import React, { useEffect, useState } from "react";
import {
  useStyles,
  tableHeadCell,
  tableBodyCell,
  tableBodyCellRed,
  CustomTextField,
  aplyfilter,
} from "./ContractsStyles";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  TextField,
  Button,
  Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import {
  PropertyAssociation,
  PropertyList,
  PropertyType,
} from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";
import toast from "react-hot-toast";

function LandlordTable() {
  const classes = useStyles();
  const [tableRows, setTableRows] = useState([
    {
      landlord: "Landlord0",
      property: "Property Name",
      unitType: "Resedential",
      unitNumber: "305",
      startDate: "04-02-2024",
      endDate: "06-03-2024",
      amount: "Category",
      status: "New",
      terminationDate: "-",
      Action: "View",
    },
    {
      landlord: "Landlord0",
      property: "Property Name",
      unitType: "Resedential",
      unitNumber: "305",
      startDate: "04-02-2024",
      endDate: "06-03-2024",
      amount: "180k AED",
      status: "Renewed",
      terminationDate: "-",
      Action: "View",
    },
    {
      landlord: "Landlord0",
      property: "Property Name",
      unitType: "Resedential",
      unitNumber: "305",
      startDate: "04-02-2024",
      endDate: "06-03-2024",
      amount: "180k AED",
      status: "Terminated",
      terminationDate: "-",
      Action: "View",
    },
    {
      landlord: "Landlord0",
      property: "Property Name",
      unitType: "Resedential",
      unitNumber: "305",
      startDate: "04-02-2024",
      endDate: "06-03-2024",
      amount: "180k AED",
      status: "Terminated",
      terminationDate: "-",
      Action: "View",
    },
    {
      landlord: "Landlord0",
      property: "Property Name",
      unitType: "Resedential",
      unitNumber: "305",
      startDate: "04-02-2024",
      endDate: "06-03-2024",
      amount: "180k AED",
      status: "Terminated",
      terminationDate: "06-03-2024",
      Action: "View",
    },
    {
      landlord: "Landlord0",
      property: "Property Name",
      unitType: "Resedential",
      unitNumber: "305",
      startDate: "04-02-2024",
      endDate: "06-03-2024",
      amount: "180k AED",
      status: "Terminated",
      terminationDate: "06-03-2024",
      Action: "View",
    },
    {
      landlord: "Landlord0",
      property: "Property Name",
      unitType: "Resedential",
      unitNumber: "305",
      startDate: "04-02-2024",
      endDate: "06-03-2024",
      amount: "180k AED",
      status: "Terminated",
      terminationDate: "-",
      Action: "View",
    },
    {
      landlord: "Landlord0",
      property: "Property Name",
      unitType: "Resedential",
      unitNumber: "305",
      startDate: "04-02-2024",
      endDate: "06-03-2024",
      amount: "180k AED",
      status: "Terminated",
      terminationDate: "-",
      Action: "View",
    },
  ]);
  const [landlordData, setLandlordData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchLandlord, setSearchLandlord] = useState([]);

  const getLandlords = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      let url;
      if (searchLandlord) {
        url = `${endpoints.landlordContracts}?landlord=${searchLandlord}`;
      } else {
        url = endpoints.landlordContracts;
      }
      const response = await axios.get(url, requestOptions);
      setLandlordData(response.data.data);
      if (searchLandlord) {
        toast.success(response.data.message);
      }
    } catch (error) {
      if (searchLandlord) {
        toast.error(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLandlords();
  }, []);
  return (
    <>
      <Box className={classes.searchFieldBox}>
        <>
          <TextField
            variant="outlined"
            placeholder="Search by Landlord"
            value={searchLandlord}
            onChange={(e) => {
              setSearchLandlord(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            sx={CustomTextField}
          />

          <Button sx={aplyfilter} onClick={getLandlords}>
            Apply Filter
          </Button>
        </>
      </Box>
      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : landlordData?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <Box marginTop={"2rem"}>
          <TableContainer sx={{ height: "65vh" }}>
            <Table sx={{ border: "1px solid #EFEFEF", minWidth: "85rem" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeadCell}>
                    {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      // marginRight: 1,
                    }}
                  >
                    <IconButton
                      sx={{
                        padding: "0",
                        height: "8px",
                        minHeight: "8px",
                      }}
                    >
                      <ArrowDropUpIcon
                        sx={{ fontSize: "30px", color: "black" }}
                      />
                    </IconButton>
                    <IconButton
                      sx={{ padding: "0", height: "8px", minHeight: "8px" }}
                    >
                      <ArrowDropDownIcon
                        sx={{ fontSize: "30px", color: "black" }}
                      />
                    </IconButton>
                  </Box>
                  Landlord Name
                </Box> */}
                    Landlord Name
                  </TableCell>
                  <TableCell sx={tableHeadCell}>Property</TableCell>
                  <TableCell sx={tableHeadCell}>Type</TableCell>
                  <TableCell sx={tableHeadCell}>Number of Units</TableCell>
                  <TableCell sx={tableHeadCell}>Start Date</TableCell>
                  <TableCell sx={tableHeadCell}>End Date </TableCell>
                  <TableCell sx={tableHeadCell}>Amount</TableCell>
                  <TableCell sx={tableHeadCell}>Status</TableCell>
                  <TableCell sx={tableHeadCell}>Termination Date</TableCell>
                  <TableCell sx={tableHeadCell}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {landlordData?.map((landlord) => (
                  <TableRow key={landlord.id}>
                    <TableCell sx={tableBodyCell}>
                      {landlord.primary_landlord.first_name || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {landlord.property.title || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {landlord.property.type == PropertyType.RESIDENTIAL
                        ? PropertyList[1]
                        : landlord.property.type == PropertyType.COMMERCIAL
                          ? PropertyList[2]
                          : landlord.property.type == PropertyType.COMMUNITY
                            ? PropertyList[3]
                            : "-" || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {landlord.unit.length || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {landlord.start_date}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {landlord.end_date}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {landlord.amount || "-"}
                    </TableCell>
                    <TableCell
                      sx={
                        landlord.status == PropertyAssociation[2]
                          ? tableBodyCellRed
                          : tableBodyCell
                      }
                    >
                      {landlord.status}
                    </TableCell>
                    <TableCell sx={tableBodyCellRed} align="center">
                      {landlord.termination_date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCellRed}>View</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
}

export default LandlordTable;
