import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import { endpoints } from "../../../apiEndpoints";
import axios from "axios";
import TenantPopup from "./TenantPopup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { PropertyAssociation } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";

const DataTable = ({ Data, columns }) => {
  const [loading, setLoading] = useState(true);
  const [Getassociationdata, setGetassociationdata] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();

  const propertyassociation = async () => {
    try {
      const token = localStorage.getItem("token");

      let url;

      if (Data === "Landlord") {
        url = endpoints.getlandlordAssociation;
      } else if (Data === "Tenant") {
        url = endpoints.gettenantAssociation;
      }

      const response = await axios.get(url, {
        headers: { Authorization: `Token ${token}` },
      });
      setGetassociationdata(response.data.data);
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    propertyassociation();
  }, []);

  const handleopenPopup = (id) => {
    setSelectedId(id);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getStatusText = (status) => {
    return PropertyAssociation[status] || "Unknown Status";
  };

  return (
    <>
      {showPopup && (
        <TenantPopup
          onClose={handleClosePopup}
          id={selectedId}
          getTenant={propertyassociation}
          selectedTab={Data}
        />
      )}
      <Box style={{ overflowX: "auto" }}>
        {loading ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"50vh"}
          >
            <Loader />
          </Box>
        ) : Getassociationdata.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <Typography>No data available</Typography>
          </Box>
        ) : (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      padding: "10px 0px 10px 20px",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Getassociationdata?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {Data === "Landlord"
                      ? item.primary_landlord?.first_name
                      : item.property?.ref_id}
                  </TableCell>
                  <TableCell>
                    {Data === "Landlord"
                      ? item.property?.ref_id
                      : item.unit.length > 0
                        ? item.unit[0].ref_id
                        : "No Unit Title"}
                  </TableCell>
                  <TableCell>
                    {Data === "Landlord"
                      ? item.sub_property_list[0]?.unit[0]?.ref_id || "No Title"
                      : item.tenant?.first_name}
                  </TableCell>
                  <TableCell>
                    {Data === "Landlord"
                      ? item.secondary_landlord[0]?.secondary_landlord
                        ?.first_name || "No Name"
                      : item.created_at.split("T")[0]}
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        item.association_status === "1"
                          ? "rgba(0, 227, 23, 1)"
                          : item.association_status === "2"
                            ? "rgba(184, 25, 24, 1)"
                            : "",
                    }}
                  >
                    {Data === "Landlord"
                      ? item.created_at.split("T")[0]
                      : getStatusText(item.association_status)}
                  </TableCell>
                  <TableCell>
                    <>
                      <Tooltip title="Edit Tenant">
                        <EditNoteIcon
                          onClick={() => {
                            if (Data === "Landlord") {
                              navigate("/admin/association/landlord-infoupdate", {
                                state: { landlordupdate: item },
                              });
                            } else if (Data === "Tenant") {
                              navigate("/admin/association/tenant-infoupdate", {
                                state: { tenantData: item },
                              });
                            }
                          }}
                          style={{ color: "rgba(184, 25, 24, 1)" }}
                        />
                      </Tooltip>
                      <Tooltip title="Delete Tenant">
                        <DeleteIcon
                          onClick={() => {
                            handleopenPopup(item.id);
                          }}
                          style={{ color: "rgba(184, 25, 24, 1)" }}
                        />
                      </Tooltip>
                    </>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </>
  );
};

export default DataTable;
