import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid, Divider } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useStyles } from "./SOAStyle";
import RentReimbursement from "./RentReimbursement";
import RentPopup from "./RentPopup";
import SOARequest from "./SOARequest";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { rentStatus, soaStatus } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";

const Document = [
  {
    id: "1",
    Date: "8 Sep, 2020",
    unit: "1207",
    status: "Approved",
    Action: "View",
  },
  {
    id: "2",
    Date: "21 Sep, 2020",
    unit: "1207",
    status: "Clarification Request",
    Action: "View",
  },
  {
    id: "3",
    Date: "24 May, 2020",
    unit: "1207",
    status: "Approved",
    Action: "View",
  },
  {
    id: "4",
    Date: "17 Oct, 2020",
    unit: "1207",
    status: "Clarification Request",
    Action: "View",
  },
  {
    id: "5",
    Date: "1 Feb, 2020",
    unit: "1207",
    status: "Clarification Request",
    Action: "View",
  },
  {
    id: "6",
    Date: "8 Sep, 2020",
    unit: "1207",
    status: "Approved",
    Action: "View",
  },
  {
    id: "7",
    Date: "8 Sep, 2020",
    unit: "1207",
    status: "Approved",
    Action: "View",
  },
  {
    id: "8",
    Date: "8 Sep, 2020",
    unit: "1207",
    status: "Clarification Request",
    Action: "View",
  },
  {
    id: "9",
    Date: "8 Sep, 2020",
    unit: "1207",
    status: "Approved",
    Action: "View",
  },
  {
    id: "10",
    Date: "8 Sep, 2020",
    unit: "1207",
    status: "Approved",
    Action: "View",
  },
];

const SOA = () => {
  const classes = useStyles();
  const [Rent, setRent] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [SoAPopup, setSoAPopup] = useState(false);
  const [SOAs, setSOAs] = useState([]);
  const [loading,setLoading] = useState(true);
  const [selectedSoa,setSelectedSoa] = useState(null)

  const getSOAs = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(endpoints.financeSOA, requestOptions);
      setSOAs(response.data.data);
    } catch {
    } finally {
        setLoading(false)
        
    }
  };

  useEffect(() => {
    getSOAs();
    const interval = setInterval(getSOAs, 5000);
    return () => clearInterval(interval);
  }, []);

  const handlerent = () => {
    setRent(true);
  };
  if (Rent) {
    return <RentReimbursement />;
  }
  

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleopen = () => {
    setShowPopup(true);
  };
  const handleSOAclose = () => {
    setSoAPopup(false);
  };
  const handleSOAopen = (soa) => {
    setSoAPopup(true);
    setSelectedSoa(soa)
  };

  return (
    <>
      {showPopup && <RentPopup onClose={handleClosePopup} />}
      {SoAPopup && <SOARequest onClose={handleSOAclose}  soa={selectedSoa}/>}
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          SOA
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{
              backgroundColor: "rgba(184, 25, 24, 1)",
              fontSize: "14px",
              lineHeight: "16px",
              fontWeight: 500,
              textTransform: "none",
              width: "130px",
              height: "30px",
            }}
            variant="contained"
            onClick={handleopen}
          >
            Request SOA
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Box className={classes.graph}>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "20px",
                  lineHeight: "20px",
                  fontWeight: 500,
                  color: "#000000",
                  margin: "20px 0px 20px 20px",
                }}
              >
                All SOA
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "190px",
                    height: "35px",
                    marginRight:'5px'
                  }}
                  variant="contained"
                  onClick={handlerent}
                >
                  Rent Reimbursement
                </Button>
              </Box>
            </Box>
            <Box style={{ margin: "0px 0px 15px 0px" }}>
              <Divider />
            </Box>

            {/* Table start */}
            {loading ? (<Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'50vh'}
            ><Loader/></Box>):(
               
            <Box style={{ overflowX: "auto" }}>  
            {SOAs?.length > 0 ? ( 
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
                  >
                    <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                      Date
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                      Property
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                      Request Status
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {SOAs && SOAs?.map((soa) => (
                    <TableRow key={soa.id}>
                      <TableCell>{soa.start_date || "-"} to {soa.end_date || "-"}</TableCell>
                      <TableCell>{soa.property?.ref_id || "-"}</TableCell>
                      <TableCell
                        style={{
                          color:
                            soa.admin_approval === soaStatus?.approved?.value
                              ? "#0EB000"
                              : soa.admin_approval === soaStatus?.approved?.value
                              ? "#E60000"
                              : "#FFD100",
                        }}
                      >
                        {soa.admin_approval === soaStatus.clarification.value ?  soaStatus.clarification.label:(
                        soa.finance_approval === soaStatus?.approved?.value
                          ? soaStatus?.approved?.label
                          : soa.finance_approval === soaStatus?.disapproved?.value
                          ? soaStatus?.disapproved?.label
                          : soa.finance_approval === soaStatus?.pending?.value
                          ? soaStatus?.pending?.label
                          : "-")}
                      </TableCell>
                      <TableCell
                        style={{ color: "#B81918" }}
                        onClick={()=>{handleSOAopen(soa)}}
                      >
                        View
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              ):(
                <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="50vh"
              >
                <Typography>No data available</Typography>
              </Box>
              )}

            </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SOA;
