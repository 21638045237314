import React, { useEffect, useState } from "react";
import {
  CustomTextField,
  aplyfilter,
  mainTypography,
  tableBodyCell,
  tableBodyCellRed,
  tableBodyCellgreen,
  tableBodyCellyelow,
  tableHeadCell,
  useStyles,
} from "./RentStyles";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { rentStatus } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";

function RentReimburstment() {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(null);
  const [rentData, setRentData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const rows = [
    {
      id: 0,
      property: "New Tower",
      type: "Commercial",
      unit: "305",
      installment: "5",
      chequeDate: "3-29-2024",
      amount: "Approved",
    },
    {
      id: 1,
      property: "New Tower",
      type: "Commercial",
      unit: "305",
      installment: "5",
      chequeDate: "3-29-2024",
      amount: "Pending",
    },
    {
      id: 2,
      property: "New Tower",
      type: "Commercial",
      unit: "305",
      installment: "5",
      chequeDate: "3-29-2024",
      amount: "Disapproved",
    },
    {
      id: 3,
      property: "New Tower",
      type: "Commercial",
      unit: "305",
      installment: "5",
      chequeDate: "3-29-2024",
      amount: "Approved",
    },
    {
      id: 4,
      property: "New Tower",
      type: "Commercial",
      unit: "305",
      installment: "5",
      chequeDate: "3-29-2024",
      amount: "Pending",
    },
    {
      id: 5,
      property: "New Tower",
      type: "Commercial",
      unit: "305",
      installment: "5",
      chequeDate: "3-29-2024",
      amount: "Disapproved",
    },
    {
      id: 6,
      property: "New Tower",
      type: "Commercial",
      unit: "305",
      installment: "5",
      chequeDate: "3-29-2024",
      amount: "Pending",
    },
    {
      id: 7,
      property: "New Tower",
      type: "Commercial",
      unit: "305",
      installment: "5",
      chequeDate: "3-29-2024",
      amount: "Pending",
    },
    {
      id: 8,
      property: "New Tower",
      type: "Commercial",
      unit: "305",
      installment: "5",
      chequeDate: "3-29-2024",
      amount: "Pending",
    },
    {
      id: 9,
      property: "New Tower",
      type: "Commercial",
      unit: "305",
      installment: "5",
      chequeDate: "3-29-2024",
      amount: "Disapproved",
    },
    {
      id: 10,
      property: "New Tower",
      type: "Commercial",
      unit: "305",
      installment: "5",
      chequeDate: "3-29-2024",
      amount: "Approved",
    },
  ];

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const getRentReimbursement = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        endpoints.financeRentReimbursement,
        requestOptions
      );
      setRentData(response.data.data);
    } catch {
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    getRentReimbursement();
  }, []);

  const handleClick = (rent) => {
    navigate('/finance/rent-reimburstment/view-form', { state: { rent } });
  };
  return (
    <>
    {loading ? (<Box display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"50vh"}>
      <Loader/>
    </Box>):(<>
      <Box>
        <Typography sx={mainTypography}>Rent Reimbursement</Typography>
      </Box>
      <Box className={classes.searchBox}>
        <TextField
          variant="outlined"
          placeholder="Search by Landlord"
          value={searchValue}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={CustomTextField}
        />
        <Button sx={aplyfilter}>Apply filter</Button>
      </Box>
      {rentData?.length === 0 ? ( <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>):(
      <Box marginTop={"2rem"}>
        <TableContainer sx={{ height: "65vh", overflowX: "auto" }}>
          <Table sx={{ border: "1px solid #EFEFEF" }}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeadCell}>Request Name/No</TableCell>
                <TableCell sx={tableHeadCell}>Landlord Name</TableCell>
                <TableCell sx={tableHeadCell}>Admin Name</TableCell>
                <TableCell sx={tableHeadCell}>Amount</TableCell>
                <TableCell sx={tableHeadCell}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rentData?.map((rent) => (
                <TableRow key={rent.id} onClick={()=>{handleClick(rent)}}>
                  <TableCell sx={tableBodyCell}>{rent.ref_id || "-"}</TableCell>
                  <TableCell sx={tableBodyCell}>
                    {rent.landlord?.first_name || "-"}
                  </TableCell>
                  <TableCell sx={tableBodyCell}>
                    {rent.approved_by_admin?.first_name || "-"}
                  </TableCell>
                  <TableCell sx={tableBodyCell}>{rent.amount || "-"}</TableCell>
                  <TableCell
                    sx={
                      rent.finance_approval === rentStatus.approved.value
                        ? tableBodyCellgreen
                        : rent.finance_approval === rentStatus.disapproved.value
                        ? tableBodyCellyelow
                        : tableBodyCellRed
                    }
                  >
                    {rent.finance_approval === rentStatus.pending.value
                      ? rentStatus.pending.label
                      : rent.finance_approval === rentStatus.approved.value
                      ? rentStatus.approved.label
                      : rent.finance_approval === rentStatus.disapproved.value
                      ? rentStatus.disapproved.label
                      : "-" }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      )}
      </>)}
    </>
  );
}

export default RentReimburstment;
