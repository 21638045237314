import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { uploadDocument } from "../../../../Redux/AdminReducer/unitSlice";
import AddUfloor from "./AddUfloor";
import AddUnote from "./AddUnote";




const AddUdocument = () => {
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState('');
    const [backcom, setbackcom] = useState(false);
    const [open, setOpen] = useState(false);
    const uploadedDocuments = useSelector(state => state.unit.uploadedDocuments);

    useEffect(() => {

        if (uploadedDocuments.length > 0) {
            setFileName(uploadedDocuments[0].name);
        }
    }, [uploadedDocuments]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            dispatch(uploadDocument(file));
        }
    };

    const handleBack = () => {
        setbackcom(true);
    };

    if (backcom) {
        return <AddUfloor />;
    }

    const handleNext = () => {
        setOpen(true)
    }
    if (open) {
        return (
            <AddUnote />
        )
    }

    return (
        <>
            <Typography variant="h6" style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "0px 0px 50px 0px"
            }}>
                Add Property
            </Typography>
            <Typography variant="subtitle1" style={{
                fontFamily: "Outfit",
                fontSize: "20px",
                lineHeight: "20px",
                fontWeight: 400,
                color: "rgba(0, 0, 0, 1)",
                margin: "60px 0px 30px 0px"
            }}>
                Property Documents
            </Typography>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "18px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 20px 0px"
                }}>
                    PDF Files, Map, or Documents
                </Typography>
            </Box>
            <Box style={{ marginTop: 20 }}>
                <input
                    accept=".pdf,.doc,.docx"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={handleFileChange}
                />
                <label htmlFor="raised-button-file">
                    <Button style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 0px 15px"

                    }}
                        variant="contained"
                        component="span"
                    > Select Files</Button>
                </label>
                {fileName && (
                    <Typography variant="subtitle1" style={{ marginTop: 8 }}>
                        {fileName}
                    </Typography>
                )}
            </Box>
            {/* <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>                     <Button style={{
                    border: "1px solid rgba(154, 25, 21, 1)",
                    color: "rgba(154, 25, 21, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 0px 50px 15px"
                }}
                    variant="outlined"
                    onClick={handleBack}

                >Back</Button>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"

                    }}
                        variant="contained"
                        onClick={handleNext}
                    >Next</Button>
                </Box>
            </Box> */}
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Box sx={{ marginTop: 'auto', display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                    <Button style={{
                        border: "1px solid rgba(154, 25, 21, 1)",
                        color: "rgba(154, 25, 21, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"
                    }}
                        variant="outlined"
                        onClick={handleBack}
                    >Back</Button>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            margin: "20px 0px 50px 15px"
                        }}
                            variant="contained"
                            onClick={handleNext}
                        >Next</Button>
                    </Box>
                </Box>
            </Box>


        </>
    );
};

export default AddUdocument;

