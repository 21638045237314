export const UserTypesNum = Object.freeze({
  SuperAdmin: 1,
  landlord: 2,
  tenant: 3,
  finance: 4,
});

export const UserTypes = Object.freeze({
  SuperAdmin: "SuperAdmin",
  admin: "Admin",
  landlord: "Landlord",
  tenant: "Tenant",
  finance: "Finance",
});

export const PropertyType = Object.freeze({
  RESIDENTIAL: 1,
  COMMERCIAL: 2,
  COMMUNITY: 3,
});

export const PropertyList = Object.freeze({
  1: "Residential",
  2: "Commerical",
  3: "Community",
});

export const PropertyStatus = Object.freeze({
  OCCUPIED: 1,
  AVAILABALE: 2,
});

export const AssociationStatus = Object.freeze({
  ACTIVE: 1,
  INACTIVE: 2,
});
export const PropertyAssociation = Object.freeze({
  1: "Active",
  2: "in-Active",
});

export const PdcType = Object.freeze({
  Rental: 1,
  SecurityDeposit: 2,
});

export const documentTypes = Object.freeze({
  EID: { value: "EID", label: "Emirates ID" },
  PPT: { value: "PPT", label: "Passport" },
  OEID: { value: "OEID", label: "Occupant Emirates ID" },
  UBILL: { value: "UBILL", label: "Utility Bill" },
  TTQ: { value: "TTQ", label: "Tawtheeq" },
  BS: { value: "BS", label: "Bank Statements" },
  PS: { value: "PS", label: "Payment Slip" },
  TL: { value: "TL", label: "Trade License" },
  POA: { value: "POA", label: "Power of Attorney" },
  SEID: { value: "SEID", label: "Signatory EID" },
  IBAN: { value: "IBAN", label: "Bank IBAN" },
  "AMC-HVAC": { value: "AMC-HVAC", label: "AMC-HVAC" },
  "AMC-CIVIL": { value: "AMC-CIVIL", label: "AMC-CIVIL" },
  "AMC-GENERAL": { value: "AMC-GENERAL", label: "AMC-GENERAL" },
  "AMC-ELEVATOR": { value: "AMC-ELEVATOR", label: "AMC-ELEVATOR" },
  WN: { value: "WN", label: "Watchman" },
  HK: { value: "HK", label: "Hassantuk" },
  I: { value: "I", label: "Insurance" },
  MR: { value: "MR", label: "Maintenance Report" },
});

export const PropertyDetailenums = Object.freeze({
  1: "Rented",
  2: "Vacant",
});

export const Priorities = Object.freeze({
  high: "1",
  medium: "2",
  low: "3",
});

export const maintenanceStatus = Object.freeze({
  pending: "1",
  progress: "2",
  resolved: "3",
});

export const identificationDocuments = Object.freeze({
  EID: { value: "EID", label: "Emirates ID" },
  PPT: { value: "PPT", label: "Passport" },
  OEID: { value: "OEID", label: "Occupant Emirates ID" },
  UBILL: { value: "UBILL", label: "Utility Bill" },
  TTQ: { value: "TTQ", label: "Tawtheeq" },
  BS: { value: "BS", label: "Bank Statements" },
  PS: { value: "PS", label: "Payment Slip" },
  TL: { value: "TL", label: "Trade License" },
  POA: { value: "POA", label: "Power of Attorney" },
  SEID: { value: "SEID", label: "SIGNATORY EID" },
});

export const MaintenanceRequestsenums = Object.freeze({
  High: 1,
  Medium: 2,
  Low: 3,
});
export const updateRequestsenums = Object.freeze({
  1: "High Urgency",
  2: "Medium",
  3: "Low",
});
export const statusMap = Object.freeze({
  1: "Pending",
  2: "Processing",
  3: "Resolved",
});

export const AMCcategorize = Object.freeze({
  CD: { value: "CD", label: "Civil Defence" },
  "H&C": { value: " H&C", label: "Havac  & Civil" },
  GMH: { value: "GMH", label: "GM & Havac" },
  EL: { value: "EL", label: "Elevator" },
  HT: { value: "HT", label: "Hassantuk" },
  CH: { value: "CH", label: "Chiller" },
  GM: { value: "GM", label: "General Maintenance" },
  INS: { value: "INS", label: "Insurance" },
  HVAC: { value: "HVAC", label: "HVAC" },
});

export const AMCserviceProvider = Object.freeze({
  SFPE: { value: "SFPE", label: "Starco Fire Protection Engineers LLC" },
  SFM: { value: "SFM", label: "Servo Facility Management LLC" },
  AGME: { value: "AGME", label: "Arabian Gulf Melco Elevator LLC" },
  AAWE: { value: "AAWE", label: "Al Amani Elevator Works" },
  MOI: { value: "MOI", label: "Ministry of Interior" },
  JC: { value: "JC", label: "Johnson Controls" },
  FSFS: { value: "FSFS", label: "FireSecure Fire and Safety LLC" },
  AAEMS: {
    value: "AAEMS",
    label: "Al Arabia Elevators and Moving Systems LLC",
  },
  OTPJSC: { value: "OTPJSC", label: "Orient Takaful PJSC" },
  RDE: { value: "RDE", label: "Red Dot Electromechanical LLC" },
  ADNTP: { value: "ADNTP", label: "Abu Dhabi National Takaful Co. PSC" },
});

export const PdcStatus = Object.freeze({
  Awaiting: { value: 1, label: "Awaiting to Process" },
  Deposited: { value: 2, label: "Deposited" },
  Cleared: { value: 3, label: "Cleared" },
  Bounced: { value: 4, label: "Bounced" },
});

export const financePdcsTypes = Object.freeze({
  rent: { value: 1, label: "Rental" },
  security: { value: 2, label: "Security Deposit" },
});

export const rentStatus = Object.freeze({
  pending:{value:'1',label:'Pending'},
  approved:{value:'2',label:'Approved'},
  disapproved:{value:'3',label:'Disapproved'}
})

export const rentenums = Object.freeze({
  1: "Pending",
  2: "Approved",
  3: "Disapproved",
});
export const updaterentenums = Object.freeze({
  
});

export const soaStatus = Object.freeze({
  pending :{value:'1',label:'Pending'},
  approved :{value:'2',label:'Approved'},
  clarification :{value:'3',label:'Clarification Requested'}
})

export const renewalNoticeStatus = Object.freeze({
  awaiting:{value:'1',label:'Awaiting to Process'},
  sent:{value:'2',label:'Sent'}
})

export const inquiryStatus = Object.freeze({
  notQualified:{value:'1',label:'Not Qualified'},
  qualified:{value:'2',label:'Qualified'}
})
