import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Grid, } from "@mui/material";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import PropertyHistory from "./PropertyHistory";
import TenantHistory from "./TenantHistory";
import PropertyUnitHistory from './PropertyUnitHistory';
import UnitStatus from '../Units/UnitStatus';
import { useLocation } from "react-router-dom";
import { endpoints } from "../../../../apiEndpoints";
import axios from 'axios';
import toast from "react-hot-toast";
import { PropertyDetailenums } from '../../../../enums/UserTypes';
import SubPropertyDocument from './SubPropertyDocument';
import DeleteProperty from "./DeleteProperty";
import EditProperty from "./EditProperty";







const SecondTable = [
    {
        id: "0",
        name: "Document Name 1",
        status: "Received"
    },
    {
        id: "1",
        name: "Document Name 1",
        status: "Not-Received"
    },
    {
        id: "2",
        name: "Document Name 1",
        status: "Received"
    },
    {
        id: "3",
        name: "Document Name 1",
        status: "Not-Received"
    },
]





const PropertyDetail = () => {
    const location = useLocation();
    const { state } = location;
    const { id, image, address, title } = state || {};


    const [PHistory, setPHistory] = useState(false);
    const [THistory, setTHistory] = useState(false);
    const [UHistory, setUHistory] = useState(false);
    const [SubProperty, setSubProperty] = useState(false);
    const [ustatus, setustatus] = useState(false);
    const [PropertyData, setPropertyData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [editProperty, seteditProperty] = useState(false);


    const [expandedLandlord, setExpandedLandlord] = useState(null);

    const handleExpandClick = (index) => {
        setExpandedLandlord(expandedLandlord === index ? null : index);
    };




    const getPropertydetail = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.Propertydetail}?id=${id}`, {
                headers: { Authorization: `Token ${token}` }
            });
            setPropertyData(response.data.data);
        } catch (error) {

        }
    };

    useEffect(() => {
        getPropertydetail();
    }, [])

    const landlordAssociation = PropertyData?.landlord_association || [];
    const tenantAssociation = PropertyData?.tenant_association || [];




    const getStatusText = (status) => {
        return PropertyDetailenums[status] || "Unknown Status";
    };


    const handlePHistory = () => {
        setPHistory(true)
    }
    if (PHistory) {
        return <PropertyHistory id={id} />
    }

    const handleTHistory = () => {
        setTHistory(true)
    }
    if (THistory) {
        return <TenantHistory id={id} />
    }

    const handleUhistory = () => {
        setUHistory(true)
    }
    if (UHistory) {
        return <PropertyUnitHistory id={id} />
    }
    const handleSubProperty = () => {
        setSubProperty(true)
    }
    if (SubProperty) {
        return <SubPropertyDocument id={id} image={image} title={title} />
    }

    const handleUstatus = () => {
        setustatus(true)
    }
    if (ustatus) {
        return <UnitStatus />
    }

    const handleEditProperry = () => {
        seteditProperty(true)
    }
    if (editProperty) {
        return <EditProperty id={id}/>
    }

    const handleDelete = () => {
        setShowPopup(true);
    }

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <>
            {showPopup && <DeleteProperty onClose={handleClosePopup} id={id} />}
            <Box>
                <Box>
                    <img src={image} width={140} />
                </Box>
                <Box sx={{ display: "flex", margin: "10px 0px 0px 5px", color: "rgba(184, 25, 24, 1)" }}>
                    <LocationOnRoundedIcon style={{ width: "20px" }} />
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontWeight: 400,
                        fontSize: "20px",
                        lineHeight: "20px",
                        margin: "0px 0px 0px 10px"
                    }}>
                        {address}
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={2} mt={2} >
                <Grid item xs={12} md={2}>
                    <Button sx={{
                        border: "1px solid rgba(184, 25, 24, 1)",
                        color: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                    }}
                        variant="outlined"
                        onClick={handlePHistory}
                    >
                        Property History
                    </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button sx={{
                        border: "1px solid rgba(184, 25, 24, 1)",
                        color: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                    }}
                        variant="outlined"
                        onClick={handleUhistory}
                    >
                        Unit History
                    </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button sx={{
                        border: "1px solid rgba(184, 25, 24, 1)",
                        color: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                    }}
                        variant="outlined"
                        onClick={handleTHistory}
                    >
                        Tenant History
                    </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button sx={{
                        border: "1px solid rgba(184, 25, 24, 1)",
                        color: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                    }}
                        variant="outlined"
                        onClick={handleSubProperty}
                    >
                        Sub-Property Documents
                    </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button sx={{
                        border: "1px solid rgba(184, 25, 24, 1)",
                        color: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                    }}
                        variant="outlined"
                        onClick={handleDelete}
                    >
                        Delete Property
                    </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button sx={{
                        border: "1px solid rgba(184, 25, 24, 1)",
                        color: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                    }}
                        variant="outlined"
                        onClick={handleEditProperry}
                    >
                        Edit Propperty
                    </Button>
                </Grid>
            </Grid>

            <Box style={{ overflowX: 'auto' }} mt={4}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                            <TableCell style={{ padding: "10px 0px 10px 20px", borderRight: "1px solid #e0e0e0" }}>Landlord Names</TableCell>
                            <TableCell style={{ padding: "10px 0px 10px 20px", borderRight: "1px solid #e0e0e0" }}>Num Of Units</TableCell>
                            <TableCell style={{ padding: "10px 0px 10px 20px", borderRight: "1px solid #e0e0e0" }}>Share Percentage</TableCell>
                            <TableCell style={{ padding: "10px 0px 10px 40px", borderRight: "1px solid #e0e0e0" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {landlordAssociation.length > 0 ? (
                            landlordAssociation.map((item, index) => (
                                <React.Fragment key={index}>
                                    <TableRow>
                                        <TableCell style={{ padding: "10px 0px 10px 20px" }}>{item.primary_landlord?.first_name}</TableCell>
                                        <TableCell style={{ padding: "10px 0px 10px 40px" }}>{item.primary_landlord_no_of_units}</TableCell>
                                        <TableCell style={{ padding: "10px 0px 10px 40px" }}>{item.primary_landlord_share_percentage}</TableCell>
                                        <TableCell style={{ padding: "10px 0px 10px 20px" }} onClick={() => handleExpandClick(index)}>
                                            {expandedLandlord === index ? (
                                                <ExpandLessIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
                                            ) : (
                                                <ExpandMoreIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    {expandedLandlord === index && (
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Box sx={{ overflowX: 'auto', mt: 2 }}>
                                                    <Table aria-label="Secondary Landlord Table">
                                                        <TableHead>
                                                            <TableRow sx={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                                                                <TableCell sx={{ padding: "10px 20px", borderRight: "1px solid #e0e0e0" }}>Secondary Landlord Names</TableCell>
                                                                <TableCell sx={{ padding: "10px 20px", borderRight: "1px solid #e0e0e0" }}>Num Of Units</TableCell>
                                                                <TableCell sx={{ padding: "10px 20px", borderRight: "1px solid #e0e0e0" }}>Share Percentage</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {item.secondary_landlord.length > 0 ? (
                                                                item.secondary_landlord.map((secondaryLandlordData) => (
                                                                    <TableRow key={secondaryLandlordData.id}>
                                                                        <TableCell sx={{ padding: "10px 20px" }}>
                                                                            {secondaryLandlordData?.secondary_landlord?.first_name}
                                                                        </TableCell>
                                                                        <TableCell sx={{ padding: "10px 20px" }}>
                                                                            {secondaryLandlordData?.secondary_landlord_no_of_units}
                                                                        </TableCell>
                                                                        <TableCell sx={{ padding: "10px 20px" }}>
                                                                            {secondaryLandlordData?.shareholding_percentage}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            ) : (
                                                                <TableRow>
                                                                    <TableCell colSpan={3} sx={{ textAlign: "center" }}>No Data Available</TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} style={{ textAlign: "center" }}>No Data Available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
            {/* Start Second table */}
            {/* <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "24px",
                    margin: "30px 0px 30px 0px"
                }}>
                    Documents
                </Typography>
            </Box>

            <Box style={{ overflowX: 'auto' }} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                            <TableCell style={{ padding: "10px 0px 10px 20px", borderRight: "1px solid #e0e0e0" }}>Document Name</TableCell>
                            <TableCell style={{ padding: "10px 0px 10px 20px", borderRight: "1px solid #e0e0e0" }}>Document Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {SecondTable.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell style={{ padding: "10px 0px 10px 20px" }}>{row.name}</TableCell>
                                <TableCell style={{ padding: "10px 0px 10px 40px", color: row.status === 'Received' ? 'rgba(0, 227, 23, 1)' : 'rgba(184, 25, 24, 1)' }}>{row.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box> */}

            {/* Start Third Table */}
            <Box style={{ overflowX: 'auto', marginTop: 50 }}>
                <Table aria-label="tenant table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                            <TableCell style={{ padding: "10px" }}>Tenant Name</TableCell>
                            <TableCell style={{ padding: "10px" }}>Emirates Id</TableCell>
                            <TableCell style={{ padding: "10px" }}>Unit Number</TableCell>
                            <TableCell style={{ padding: "10px" }}>Rent</TableCell>
                            <TableCell style={{ padding: "10px" }}>Renting Since</TableCell>
                            <TableCell style={{ padding: "10px" }}>Next Rent Due</TableCell>
                            <TableCell style={{ padding: "10px" }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tenantAssociation.length > 0 ? (
                            tenantAssociation.flatMap((item, index) =>
                                item.unit.map((unit, unitIndex) => (
                                    <TableRow key={`${index}-${unitIndex}`}>
                                        <TableCell style={{ padding: "10px" }}>{item.tenant?.first_name}</TableCell>
                                        <TableCell style={{ padding: "10px" }}>{item.tenant?.emirates_id}</TableCell>
                                        <TableCell style={{ padding: "10px" }}>{unit.ref_id}</TableCell>
                                        <TableCell style={{ padding: "10px" }}>{item.rent}</TableCell>
                                        <TableCell style={{ padding: "10px" }}>{item.renting_since}</TableCell>
                                        <TableCell style={{ padding: "10px" }}>{item.upcoming_rent_due}</TableCell>
                                        <TableCell style={{ padding: "10px" }}
                                            sx={{
                                                color:
                                                    item.association_status === "1"
                                                        ? "#B81918"
                                                        : item.association_status === "2"
                                                            ? "#000000BF"
                                                            : "",
                                            }}
                                            onClick={handleUstatus}
                                        >{getStatusText(item.association_status)}</TableCell>
                                    </TableRow>
                                ))
                            )
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7} style={{ textAlign: "center" }}>No Data Available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>

        </>
    )
}

export default PropertyDetail;



















