import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const newIncomeTextfield = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    // height: "10.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },

  "& .MuiOutlinedInput-input[type='date']": {
    color: "#73767960", // Change the color of the input text for date type
  },
  "& .MuiSvgIcon-root": {
    color: "#737679", // Change the color of the calendar icon
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679", // Change placeholder color here
  },
};

export const uploadBtn = {
  backgroundColor: "rgba(184, 25, 24, 1)",
  textTransform: "none",
  borderRadius: "8px",
  margin: "100px 0px 100px 0px",
};

export const saveBtn = {
  backgroundColor: "rgba(184, 25, 24, 1)",
  fontSize: "14px",
  lineHeight: "16px",
  fontWeight: 500,
  textTransform: "none",
  width: "140px",
  height: "40px",
};
