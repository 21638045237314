import React from "react";
import { Box, Grid, Button, Typography, Divider, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";

const UnitData = [
  {
    id: "1",
    Detail: "RentalAgreement.pdf",
    Action: "Download",
    action: "/image/pdfimage.png",
  },
];

const UnitRental = ({ unit, tenantDetails }) => {
  const downloadFile = (fileUrl, type) => {
    if (!fileUrl) {
      alert("Document not available.");
      return;
    }

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${type}.${getFileExtension(fileUrl)}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("Could not fetch the file."));
  };

  function getFileExtension(url) {
    // Use a regular expression to match the file extension
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(url)[1];
    return extension;
  }

  return (
    <>
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        Rental Agreement
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12} md={6} alignItems="center">
          <Box>
            <Box sx={{ display: "flex" }}>
              <img
                src={
                  unit?.attachments.length > 0
                    ? unit?.attachments[0]?.media
                    : "No Image Available"
                }
                onError={(e) => (e.target.src = "/image/propertyicon.png")}
                alt="this image is not show"
                width={450}
                height={250}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "35px",
              margin: "40px 0px 0px 0px",
            }}
          >
            {unit?.title || "Title not available"}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              margin: "10px 0px 0px 0px",
            }}
          >
            {unit?.description || "Description not available"}
          </Typography>
        </Grid>
      </Grid>

      <Box style={{ margin: "25px 0px 0px 0px" }}>
        <Divider />
      </Box>

      <Box style={{ margin: "20px 0px 0px 0px" }}>
        <Typography
          style={{
            fontFamily: "Raleway",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "28px",
          }}
        >
          Current Tenant
        </Typography>
      </Box>

      <Box
        style={{
          display: "flex",
          alignItems: "center",
          margin: "20px 0px 0px 0px",
        }}
      >
        <Avatar
          alt="Remy Sharp"
          src="/image/userAvatar.jpg"
          sx={{ width: 56, height: 56 }}
        />
        <Typography
          style={{
            color: "#060606",
            font: "Outfit",
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 400,
            margin: "0px 0px 0px 10px",
          }}
        >
          {tenantDetails?.first_name || "Name not available"}
          <Typography
            style={{
              color: "rgba(29, 41, 57, 0.52)",
              font: "Outfit",
              fontSize: "11px",
              lineHeight: "22px",
              fontWeight: 400,
              margin: "-5px 0px 0px 0px",
            }}
          >
            {`Apartment ${
              tenantDetails?.reference_id || "Number not available"
            }`}
          </Typography>
        </Typography>
      </Box>

      <Box style={{ margin: "20px 0px 0px 0px" }}>
        <Typography
          style={{
            fontFamily: "Raleway",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "24px",
          }}
        >
          Contact
        </Typography>
      </Box>

      <Box>
        <Box style={{ display: "flex", margin: "15px 0px 0px 0px" }}>
          <img src="/image/sms.png" />
          <Typography
            style={{
              fontSize: "17px",
              fontFamily: "Raleway",
              fontWeight: 400,
              lineHeight: "19px",
              margin: "2px 0px 0px 10px",
            }}
          >
            {tenantDetails?.email || "Email not available"}
          </Typography>
        </Box>
        <Box style={{ display: "flex", margin: "13px 0px 0px 0px" }}>
          <img src="/image/call.png" />
          <Typography
            style={{
              fontSize: "17px",
              fontFamily: "Raleway",
              fontWeight: 400,
              lineHeight: "19px",
              margin: "2px 0px 0px 7px",
            }}
          >
            {tenantDetails?.phone || "Phone Number not available"}
          </Typography>
        </Box>
        <Box style={{ display: "flex", margin: "13px 0px 0px 0px" }}>
          <img src="/image/chat.png" />
          <Typography
            style={{
              fontSize: "17px",
              fontFamily: "Raleway",
              fontWeight: 400,
              lineHeight: "19px",
              margin: "2px 0px 0px 10px",
            }}
          >
            chat now
          </Typography>
        </Box>
      </Box>

      <Box style={{ margin: "25px 0px 0px 0px" }}>
        <Divider />
      </Box>

      <Box style={{ margin: "20px 0px 0px 0px" }}>
        <Typography
          style={{
            fontFamily: "Raleway",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "28px",
          }}
        >
          Documents
        </Typography>
      </Box>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <Box style={{ overflowX: "auto" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
                >
                  <TableCell>Documents</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tenantDetails?.rental_agreement?.map((agreement, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {" "}
                      <Box style={{ display: "flex" }}>
                        <img
                          src="/image/pdfimage.png"
                          alt="pdf"
                          style={{ cursor: "pointer" }}
                        />
                        <Typography
                          style={{
                            fontFamily: "Raleway",
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "24px",
                            margin: "15px 0px 0px 5px",
                          }}
                        >
                          {agreement.split("/").pop() || "-"}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Tooltip title={"Download File"}>
                        <SystemUpdateAltOutlinedIcon
                          style={{
                            color: "rgba(185, 16, 15, 1)",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            downloadFile(agreement, agreement.split("/").pop());
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </>
  );
};

export default UnitRental;
