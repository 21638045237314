import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import UnitPdcs from "./UnitPdcs";
import UnitPaidInvoice from "./UnitPaidInvoice";
import UnitRental from "./UnitRental";
import UnitComplain from "./UnitComplain";
import axios from "axios";
import { endpoints } from "../../../../apiEndpoints";
import TenantComplaints from "./TenantComplaints";

const UnitData = [
  {
    id: "1",
    Detail: "Renting Since",
    Date: "24 May, 2020",
    Action: "View",
  },
  {
    id: "2",
    Detail: "Last Rent Paid",
    Date: "17 Oct, 2020",
    Action: "View",
  },
  {
    id: "3",
    Detail: "Next Rent Due",
    Date: "1 Feb, 2020",
    Action: "View",
  },
];

const TenantsUnit = ({ details, unit }) => {
  const [pdcs, setpdcs] = useState(false);
  const [Paid, setPaid] = useState(false);
  const [rental, setrental] = useState(false);
  const [complain, setcomplain] = useState(false);
  const [tenantDetails, setTenantDetails] = useState(null);

  // const tenantId = details.tenants.slice(0, 1)[0].id;

  const getTenantDetails = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.get(
        `${endpoints.tenantDetails}?id=${4}&unit_id=${unit.id}`,
        requestOptions
      );
      setTenantDetails(response.data.data);
    } catch {}
  };

  useEffect(() => {
    getTenantDetails();
  }, []);
  const rentDetails = [
    { label: "Renting Since", date: tenantDetails?.renting_since },
    { label: "Last Rent Paid", date: tenantDetails?.last_rent_paid },
    { label: "Next Rent Due", date: tenantDetails?.upcoming_rent_due },
  ];

  const handlepdcs = () => {
    setpdcs(true);
  };
  if (pdcs) {
    return <UnitPdcs unit={unit} tenantDetails={tenantDetails} />;
  }

  const handlepaid = () => {
    setPaid(true);
  };
  if (Paid) {
    return <UnitPaidInvoice />;
  }
  const handlerental = () => {
    setrental(true);
  };
  if (rental) {
    return <UnitRental unit={unit} tenantDetails={tenantDetails} />;
  }

  const handleComplain = () => {
    setcomplain(true);
  };
  if (complain) {
    return <TenantComplaints tenantDetails={tenantDetails} />;
  }

  return (
    <>
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        Tenant
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12} md={6} alignItems="center">
          <Box>
            <Box sx={{ display: "flex" }}>
              <img
                src={
                  unit?.attachments && unit.attachments.length > 0
                    ? unit?.attachments[0]?.media
                    : "No Image Availabe"
                }
                onError={(e) => (e.target.src = "/image/propertyicon.png")}
                alt="this image is not show"
                width={450}
                height={250}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "35px",
              margin: "40px 0px 0px 0px",
            }}
          >
            {unit?.title || "Title not available"}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              margin: "10px 0px 0px 0px",
            }}
          >
            {unit?.description || "Description not available"}
          </Typography>
        </Grid>
      </Grid>

      <Box style={{ margin: "25px 0px 0px 0px" }}>
        <Divider />
      </Box>

      <Box style={{ margin: "20px 0px 0px 0px" }}>
        <Typography
          style={{
            fontFamily: "Raleway",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "28px",
          }}
        >
          Tenant Details
        </Typography>
      </Box>

      <Box
        style={{
          display: "flex",
          alignItems: "center",
          margin: "20px 0px 0px 0px",
        }}
      >
        <Avatar
          alt="Remy Sharp"
          src="/image/userAvatar.jpg"
          sx={{ width: 56, height: 56 }}
        />
        <Typography
          style={{
            color: "#060606",
            font: "Outfit",
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 400,
            margin: "0px 0px 0px 10px",
          }}
        >
          {details?.tenants && details.tenants.length < 0
            ? details?.tenants[0]?.first_name
            : "Name not available"}
          <Typography
            style={{
              color: "rgba(29, 41, 57, 0.52)",
              font: "Outfit",
              fontSize: "11px",
              lineHeight: "22px",
              fontWeight: 400,
              margin: "-5px 0px 0px 0px",
            }}
          >
            {details?.tenants && details.tenants.length < 0
              ? details?.tenants[0]?.reference_id
              : "Appartment Number not available"}
          </Typography>
        </Typography>
      </Box>

      <Box style={{ margin: "20px 0px 0px 0px" }}>
        <Typography
          style={{
            fontFamily: "Raleway",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "24px",
          }}
        >
          Contact
        </Typography>
      </Box>

      <Box>
        <Box style={{ display: "flex", margin: "15px 0px 0px 0px" }}>
          <img src="/image/sms.png" />
          <Typography
            style={{
              fontSize: "17px",
              fontFamily: "Raleway",
              fontWeight: 400,
              lineHeight: "19px",
              margin: "2px 0px 0px 10px",
            }}
          >
            {details?.tenants && details.tenants.length < 0
              ? details?.tenants[0]?.email
              : "Email not available"}
          </Typography>
        </Box>
        <Box style={{ display: "flex", margin: "13px 0px 0px 0px" }}>
          <img src="/image/call.png" />
          <Typography
            style={{
              fontSize: "17px",
              fontFamily: "Raleway",
              fontWeight: 400,
              lineHeight: "19px",
              margin: "2px 0px 0px 7px",
            }}
          >
            {details?.tenants && details.tenants.length < 0
              ? details?.tenants[0]?.phone
              : "Contact not available"}
          </Typography>
        </Box>
        <Box style={{ display: "flex", margin: "13px 0px 0px 0px" }}>
          <img src="/image/chat.png" />
          <Typography
            style={{
              fontSize: "17px",
              fontFamily: "Raleway",
              fontWeight: 400,
              lineHeight: "19px",
              margin: "2px 0px 0px 10px",
            }}
          >
            chat now
          </Typography>
        </Box>
      </Box>
      <Box style={{ margin: "25px 0px 0px 0px" }}>
        <Divider />
      </Box>

      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 700,
          color: "rgba(0, 0, 0, 1)",
          margin: "20px 0px 15px 0px",
        }}
      >
        Rent Details
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box style={{ overflowX: "auto" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
                >
                  <TableCell>Details</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rentDetails?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.label || "-"}</TableCell>
                    <TableCell>{item.date || "-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>

      <Box style={{ margin: "25px 0px 0px 0px" }}>
        <Divider />
      </Box>

      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 700,
          color: "rgba(0, 0, 0, 1)",
          margin: "20px 0px 15px 0px",
        }}
      >
        Actions
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Rental Agreement
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handlerental}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              PDCs
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handlepdcs}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Paid Invoices
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handlepaid}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1} mb={3}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Complaints
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handleComplain}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TenantsUnit;
