import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddPrivateNote from './AddPrivateNote';
import { useState } from "react";
import Popup from "./Popup";
import ViewProperty from "./ViewProperty";
import { useNavigate } from "react-router-dom";


const AddAgreement = () => {
    const [checked, setChecked] = React.useState(false);
    const [open, setopen] = useState(false);
    const [backcom, setbackcom] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };


    const handleBack = () => {
        setbackcom(true)
    }
    if (backcom) {
        return (
            <AddPrivateNote />
        )
    }

    const handleClosePopup = () => {
        // setShowPopup(false); 
    };

    const handleNext = () => {
        navigate(`/admin/viewProperty`);
    }
    // const handleNext = () => {
    //     // setShowPopup(true); 
    //     setopen(true)
    // }
    // if(open){
    //     return(
    //         <ViewProperty/>
    //     )
    // }

    return (
        <>
            {showPopup && <Popup onClose={handleClosePopup} />}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Add Property
                </Typography>
            </Box>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 20px 0px"
                }}>
                    Agreement
                </Typography>
            </Box>
            <Box>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleChange} />}
                    label={<Typography style={{ fontSize: '12px' }}>I consent to having this website to store my submitted information, read more information below</Typography>}
                />
            </Box>
            <Box style={{
                backgroundColor: "rgba(250, 250, 250, 1)",
                borderRadius: "8px",
                padding: "25px 25px 25px 25px",
                margin: "20px 0px 0px 25px"
            }}>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "38px"
                }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.<br />
                    Tempor incididunt ut labore et dolore magna aliqua.<br />
                    Ut enim ad minim  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea  commodo consequat.<br />
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                <Button style={{
                    border: "1px solid rgba(154, 25, 21, 1)",
                    color: "rgba(154, 25, 21, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 0px 50px 15px"
                }}
                    variant="outlined"
                    onClick={handleBack}

                >Back</Button>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "150px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"

                    }}
                        variant="contained"
                        onClick={handleNext}
                    >Submit Property</Button>
                </Box>
            </Box>
        </>
    )
}


export default AddAgreement;