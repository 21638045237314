import React, { useState, useEffect } from "react";
import { Typography, Box, FormControl } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import {
  StyledGrid,
  StyledItemGrid,
  InfoBox,
  InfoTypography,
} from "./MaintenanceStyles";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../../../apiEndpoints";
import axios from "axios";
import { MaintenanceRequestsenums } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";

function MaintenanceRequests() {
  const [loading, setLoading] = useState(true);
  const [selectedValue, setSelectedValue] = useState({
    priority: "",
  });
  const [maintenanceRequests, setMaintenanceRequests] = useState([]);

  const getmaintenanceRequests = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endpoints.getmaintenanceRequests}?priority=${selectedValue.priority}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const data = response.data.data;
      setMaintenanceRequests(data.length > 0 ? data : []);
    } catch (error) {
      setMaintenanceRequests([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getmaintenanceRequests();
  }, [selectedValue.priority]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const handleCardClick = (request) => {
    navigate("/admin/maintenance-status", {
      state: {
        id: request.id,
        ...request,
      },
    });
  };

  return (
    <>
      <FormControl
        sx={{
          width: "17rem",
          height: "2.5rem",
          marginTop: "2rem",
          marginBottom: "2rem",
          color: "#737679",
          border: "none",
          position: "relative",
        }}
      >
        <Select
          name="priority"
          value={selectedValue.priority}
          onChange={handleInputChange}
          sx={{ borderRadius: "30px" }}
          displayEmpty
          required
        >
          <MenuItem value="">
            All Type
          </MenuItem>
          <MenuItem value={MaintenanceRequestsenums.High}>
            High Urgency
          </MenuItem>
          <MenuItem value={MaintenanceRequestsenums.Medium}>Medium</MenuItem>
          <MenuItem value={MaintenanceRequestsenums.Low}>Low</MenuItem>
        </Select>
      </FormControl>

      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : maintenanceRequests.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <StyledGrid container xs={12} spacing={1}>
          {maintenanceRequests.map((item, index) => (
            <StyledItemGrid key={index} item xs={12}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontWeight: "500",
                    fontSize: { xs: "15px", sm: "15px", md: "20px" },
                  }}
                >
                  {item.created_at.split("T")[0]}
                </Typography>
              </Box>
              <InfoBox>
                <InfoTypography onClick={() => handleCardClick(item)}>
                  {item.name}
                </InfoTypography>
              </InfoBox>
            </StyledItemGrid>
          ))}
        </StyledGrid>
      )}
    </>
  );
}

export default MaintenanceRequests;
