import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserTypesNum } from "../../../enums/UserTypes";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import SearchIcon from "@mui/icons-material/Search";
import toast from "react-hot-toast";
import { customSearchField } from "./ClientStyle";
import { Loader } from "../../../utils/Loader";

const DataTable = ({ Data, tableName }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  function truncateText(text, maxLength) {
    if (maxLength <= 3) {
      return text.substring(0, maxLength);  // Avoid appending '...' if the maxLength is too small.
    }
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  const handleRowClick = (row) => {
    navigate(`/admin/client/profiles`, { state: { row, tableName } });
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  let userType = UserTypesNum.SuperAdmin;
  if (tableName === "Tenant") {
    userType = UserTypesNum.tenant;
  } else if (tableName === "Landlord") {
    userType = UserTypesNum.landlord;
  } else if (tableName === "Admin") {
    userType = UserTypesNum.SuperAdmin;
  } else if (tableName === "Finance") {
    userType = UserTypesNum.finance;
  }

  const getUserListing = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const apiUrl =
        userType && searchQuery
          ? `${endpoints.getUserListing}?search=${searchQuery}&user_type=${userType}`
          : `${endpoints.getUserListing}?user_type=${userType}`;
      const response = await axios.get(apiUrl, requestOptions);
      const Data = response.data.data
      setData(Data);
      if (!Data || Data.length === 0) {

      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserListing(userType);
  }, []);


  const handleApplyFilter = () => {
    setData([]);
    getUserListing(userType, searchQuery);
  };


  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search by name"
          style={{ margin: "15px 0px 0px 0px", borderRadius: "80px" }}
          sx={customSearchField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box marginTop={"1rem"}>
          <Button
            sx={{
              border: "1px solid #B81918",
              color: "#B81918",
              fontFamily: "Outfit",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "12px",
              borderRadius: "30px",
              height: "2.5rem",
              padding: "0.5rem 1rem 0.5rem",
              textTransform: "none",
            }}
            // onClick={() => {
            //   getUserListing(userType, searchQuery);
            // }}
            onClick={handleApplyFilter }
          >
            Apply Filter
          </Button>
        </Box>
      </Box>
      <Box style={{ overflowX: "auto" }}>
        {loading ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"50vh"}
          >
            <Loader />
          </Box>
        ) : data.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <Typography>No data available</Typography>
          </Box>
        ) : (
          <Table sx={{ border: "1px solid #EFEFEF",maxWidth:'65rem' }}>
            <TableHead>
              <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                <TableCell
                  style={{
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Contact No
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => handleRowClick(row.id)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell >
                    {row.first_name || '-'}
                  </TableCell>
                  <TableCell >
                    {row.email}
                    {/* {truncateText(row.email,20) || '-'} */}
                  </TableCell>
                  <TableCell >
                    {row.phone || '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </>
  );
};

export default DataTable;
