import React from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  InfoTypography,
} from "../../../../AdminComponent/Pages/Maintenance/MaintenanceStyles";
import { PropertyType } from "../../../../enums/UserTypes";




const UnitDetail = ({ unitDetails, mainProperty }) => {
  return (
    <>
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        All Units
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12} md={6} alignItems="center">
          <Box>
            <Box sx={{ display: "flex" }}>
              <img
                src={
                  mainProperty?.attachments &&
                    mainProperty.attachments.length > 0
                    ? mainProperty?.attachments[0].media
                    : "No image available"
                }
                onError={(e) => (e.target.src = "/image/propertyicon.png")}
                // alt="this image is not show"
                width={450}
                height={250}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "35px",
              margin: "40px 0px 0px 0px",
            }}
          >
            {mainProperty?.title || "Title not available"}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              margin: "10px 0px 0px 0px",
            }}
          >
            {mainProperty?.description || "Description not available"}
          </Typography>
        </Grid>
      </Grid>

      <Box style={{ margin: "25px 0px 0px 0px" }}>
        <Divider />
      </Box>

      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
          margin: "20px 0px 10px 0px",
        }}
      >
        Number Of Units
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box style={{ overflowX: "auto" }}>
            <Table
              aria-label="simple table"
              sx={{ border: "1px solid #EFEFEF" }}
            >
              <TableHead>
                <TableRow
                  style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
                >
                  <TableCell>No.</TableCell>
                  <TableCell>Unit Title</TableCell>
                  <TableCell>Unit No.</TableCell>
                  <TableCell>Unit Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unitDetails?.units && unitDetails?.units.length > 0 ? (


                  unitDetails?.units?.map((unit, index) => (
                    <TableRow key={unit.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{unit.title || "-"}</TableCell>
                      <TableCell>{unit.ref_id || "-"}</TableCell>
                      <TableCell>
                        {unit.type == PropertyType.RESIDENTIAL
                          ? "Residential"
                          : unit.type == PropertyType.COMMERCIAL
                            ? "Commercial"
                            : unit.type == PropertyType.COMMUNITY
                              ? "Community"
                              : "-"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <InfoTypography>No Data Available</InfoTypography>
                )}
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </>
  );
};

export default UnitDetail;
