import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Grid,
  Button,
  Tooltip,
  Typography,
} from "@mui/material";
import { useStyles } from "../Properties/Propertie/PropertieStyle";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteWarning from "./DeleteWarning";
import SearchIcon from "@mui/icons-material/Search";
import { Loader } from "../../../utils/Loader";
import { soaStatus } from "../../../enums/UserTypes";
import { DatePicker, Space } from "antd";
import moment from "moment";
const {RangePicker} = DatePicker;

const DataTable = ({ Data, columns, selectedTab }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [documentsData, setDocumentsData] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const [searchName, setSearchName] = useState(null);
  const [searchUnit, setSearchUnit] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [searchDateRanges,setSearchDateRanges] = useState(null);

  const handleDeleteClick = (document) => {
    setSelectedDocument(document);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedDocument(null);
  };

  const getDocuments = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      let url;
      let queryParams = [];
      if (selectedTab === "Documents") {
        url = endpoints.documents;
        if (searchName) {
          queryParams.push(`landlord=${searchName}`);
        }
        if (searchUnit) {
          queryParams.push(`property=${searchUnit}`);
        }
        if (searchDate) {
          queryParams.push(`date=${searchDate}`);
        }

        if (queryParams.length > 0) {
          // Join all query parameters with '&' and append to the URL
          url = `${url}?${queryParams.join("&")}`;
        }
      } else if (selectedTab === "Maintenance") {
        url = `${endpoints.documents}?type=MR`;
        if (searchName) {
          queryParams.push(`landlord=${searchName}`);
        }
        if (searchUnit) {
          queryParams.push(`property=${searchUnit}`);
        }
        if (searchDate) {
          queryParams.push(`date=${searchDate}`);
        }

        if (queryParams.length > 0) {
          // Join all query parameters with '&' and append to the URL
          url = `${url}&${queryParams.join("&")}`;
        }
      } else if (selectedTab === "SOA") {
        url = endpoints.financeSOA;
        if (searchName) {
          queryParams.push(`landlord=${searchName}`);
        }
        if (searchUnit) {
          queryParams.push(`property=${searchUnit}`);
        }
        if (searchDateRanges) {
          queryParams.push(`start_date=${formattedDates[0]}&end_date=${formattedDates[1]}`);
        }
        if (queryParams.length > 0) {
          url = `${url}?${queryParams.join("&")}`;
        }
      } else {
        return;
      }
      const response = await axios.get(url, requestOptions);
      const data = response.data.data;
      setDocumentsData(data);
      if (queryParams.length > 0) {
      }
      if (!data || data?.length === 0) {
      }
    } catch (error) {
      setDocumentsData([]);
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = (fileUrl, type) => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${type}.${getFileExtension(fileUrl)}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("Could not fetch the file."));
  };

  function getFileExtension(url) {
    // Use a regular expression to match the file extension
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(url)[1];
    return extension;
  }

  

  const formattedDates = searchDateRanges?.map((dateObj) => {
    return moment(dateObj.$d).format('YYYY-MM-DD');
  });


  useEffect(() => {
    getDocuments();
  }, []);

  return (
    <>
      {selectedTab == "Documents" ||
      selectedTab == "Maintenance" ||
      selectedTab === "SOA" ? (
        <Box className={classes.root} sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4} lg={3}>
              <div style={{ position: "relative" }}>
                <input
                  type="search"
                  style={{
                    width: "100%",
                    height: "40px",
                    border: "1px solid rgba(239, 239, 239, 1)",
                    borderRadius: "30px",
                    paddingLeft: "40px",
                  }}
                  placeholder="Search by Landlord name"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  size="small"
                />
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                  }}
                >
                  <SearchIcon />
                </div>
              </div>
            </Grid>
            {/* {selectedTab === "SOA"? (
            <Grid item xs={12} md={4} lg={3}>
              <input
                type="search"
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid rgba(239, 239, 239, 1)",
                  borderRadius: "30px",
                  padding: "0px 0px 0px 20px",
                }}
                placeholder="Search by Unit/Property"
                value={searchUnit}
                onChange={(e) => setSearchUnit(e.target.value)}
              />
            </Grid>
            ): null} */}
            {selectedTab === "SOA"? (
              <Grid item>
                <Space>
                  <RangePicker 
                    style={{
                      borderRadius: "50px",
                      height: "2.5rem",
                      borderColor: "#EFEFEF",
                      width: "100%",
                      // Add more styles here if needed
                    }}
                    value={searchDateRanges}
                    onChange={(dates)=>{setSearchDateRanges(dates)}}
                  />
                </Space>
              </Grid>
            ): null}
            {/* {selectedTab === "SOA"? (
              <Grid item xs={12} md={4} lg={3}>
                <div style={{ position: "relative" }}>
                  <input
                    type="date"
                    style={{
                      width: "100%",
                      height: "40px",
                      border: "1px solid rgba(239, 239, 239, 1)",
                      borderRadius: "30px",
                      padding: "0px 0px 0px 20px",
                    }}
                    placeholder="Search By Date"
                    value={searchDate}
                    onChange={(e) => setSearchDate(e.target.value)}
                  />
                </div>
              </Grid>
            ): null} */}
            <Grid item xs={12} md={6} lg={2}>
              <Button
                fullWidth
                style={{
                  border: "1px solid rgba(184, 25, 24, 1)",
                  borderRadius: "30px",
                  color: "rgba(184, 25, 24, 1)",
                }}
                variant="outlined"
                onClick={() => {
                  getDocuments(searchName, searchUnit, searchDate);
                }}
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : documentsData?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (
        ""
      )}
      <Box style={{ overflowX: "auto" }}>
        {loading ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"50vh"}
          >
            <Loader />
          </Box>
        ) : documentsData?.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <Typography>No data available</Typography>
          </Box>
        ) : (
          <Table sx={{ border: "1px solid #EFEFEF" }}>
            <TableHead>
              <TableRow
                style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      padding: "10px 0px 10px 20px",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedTab === "Documents" || selectedTab === "Maintenance"
                ? documentsData?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row?.type || "-"}</TableCell>
                      <TableCell>{row?.landlord?.first_name || "-"}</TableCell>
                      <TableCell>{row?.tenant?.first_name || "-"}</TableCell>
                      <TableCell>{row?.unit?.ref_id || "-"}</TableCell>
                      <TableCell>{row?.sub_property?.ref_id || "-"}</TableCell>

                      <TableCell>
                        <>
                          <a
                            onClick={() => {
                              downloadFile(row.file, row.type);
                            }}
                          >
                            <Tooltip title="Download">
                              <SystemUpdateAltOutlinedIcon
                                style={{ color: "rgba(185, 16, 15, 1)" }}
                              />
                            </Tooltip>
                          </a>
                          <Tooltip title="Delete">
                            <DeleteIcon
                              style={{ color: "rgba(185, 16, 15, 1)" }}
                              onClick={() => {
                                handleDeleteClick(row);
                              }}
                            />
                          </Tooltip>
                        </>
                      </TableCell>
                    </TableRow>
                  ))
                : documentsData?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{`${row?.start_date} - ${row.end_date} `}</TableCell>
                      <TableCell>{row?.property?.ref_id || "-"}</TableCell>
                      <TableCell>{row?.landlord?.first_name || "-"}</TableCell>
                      <TableCell>
                        {row?.finance_approval === soaStatus.approved.value
                          ? row.landlord_approval === soaStatus.approved.value
                            ? soaStatus.approved.label
                            : row.landlord_approval === soaStatus.pending.value
                            ? soaStatus.pending.label
                            : row.landlord_approval ===
                              soaStatus.clarification.value
                            ? soaStatus.clarification.label
                            : "-"
                          : "-"}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          navigate("/admin/requestDetail", {
                            state: { SOA: row },
                          });
                        }}
                        sx={{ cursor: "pointer  " }}
                      >
                        View
                      </TableCell>

                      <TableCell>
                        <>
                          <a
                            onClick={() => {
                              downloadFile(row.landlord?.docs);
                            }}
                          >
                            <Tooltip title="Download">
                              <SystemUpdateAltOutlinedIcon
                                style={{ color: "rgba(185, 16, 15, 1)" }}
                              />
                            </Tooltip>
                          </a>
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        )}
        {selectedDocument && (
          <DeleteWarning
            open={deleteModalOpen}
            onClose={handleCloseDeleteModal}
            // itemName={selectedRow.type}
            id={selectedDocument.id} // Pass the item id or other necessary data\
            getDocuments={getDocuments}
          />
        )}
      </Box>
     
    </>
  );
};

export default DataTable;
