import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { endpoints } from '../../apiEndpoints';



const initialState = {
    propertyData: {},
    mediaData: {},
    detailData: {},
    featureData: [],
    locationData: {},
    privateData: {},
    additionalFields: [
        { title: '', value: '' }
    ],
    floorData: [{ title: '', price: '', price_postfix: '', plan_size: '', description: '' }],
    uploadedImages: [],
    images: {},
    uploadedDocuments: [],
};

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        updatePropertyData(state, action) {
            state.propertyData = action.payload;
        },
        updateMediaData(state, action) {
            state.mediaData = action.payload;
        },
        updateDetailData(state, action) {
            state.detailData = action.payload;
        },
        updateFeatureData(state, action) {
            state.featureData = action.payload;
        },
        updateLocationData(state, action) {
            state.locationData = action.payload;
        },
        updateFloorData(state, action) {
            state.floorData = action.payload;
        },
        addFloorField(state) {
            const newField = {
                id: state.floorData.length + 1,
                title: '',
                price: '',
                price_postfix: '',
                plan_size: '',
                description: ''
            };
            state.floorData.push(newField);
        },
        updatePrivateData(state, action) {
            state.privateData = action.payload;
        },
        addAdditionalField(state, action) {
            state.additionalFields.push(action.payload); // Add a new field
        },
        updateAdditionalField(state, action) {
            const { index, data } = action.payload;
            state.additionalFields[index] = { ...state.additionalFields[index], ...data }; // Update specific field
        },

        uploadImage(state, action) {
            const file = action.payload;
            console.log("file: ", file)
            state.uploadedImages = [...state.uploadedImages, file]; // Store the uploaded image file using its ID
        },

        clearUploadedImages(state) {
            state.uploadedImages = {}; // Clear all uploaded images
        },


        uploadFloorImage(state, action) {
            const { id, file } = action.payload;
            state.images[id] = file;
        },
       

        uploadDocument(state, action) {
            const file = action.payload;
            state.uploadedDocuments.push(file); // Store the uploaded document file
        },

        clearUploadedDocuments(state) {
            state.uploadedDocuments = []; // Clear all uploaded documents
        },
        // resetFormData(state) {
        //     Object.keys(initialState).forEach(key => {
        //         state[key] = initialState[key];
        //     });
        // },

        resetFormData: (state) => {
            console.log("Resetting state to initialState");
            return initialState; // Directly return the initial state
        },
    },

});

export const {
    updatePropertyData,
    updateFloorData,
    addFloorField,
    uploadFloorImage,
    updateMediaData,
    updateDetailData,
    updateFeatureData,
    updateLocationData,
    updatePrivateData,
    addAdditionalField,
    updateAdditionalField,
    uploadImage, clearUploadedImages,
    uploadDocument, clearUploadedDocuments,
    resetFormData
} = formSlice.actions;

export default formSlice.reducer;



