import React from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    searchFieldBox: {
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      gap: "1rem",
      marginTop: "2rem",
    },
    gridBox: {
      padding: "2rem",
      borderRadius: "10px",
      // boxShadow: "1.5px 1.5px 0.8px 0.8px rgba(0, 0, 0, 0.5)",
      boxShadow: '4px 4px 8px 0px #0000000A'
    },
  };
});

export const mainTypography = {
  fontFamily: "Outfit",
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "24px",
};

export const CustomTextField = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    height: "2.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },
};

export const gridItemTitle = {
  fontFamily: "Outfit",
  fontSize: "24px",
  lineHeight: "20px",
  fontWeight: "400",
};

export const gridItemAmount = {
  fontFamily: "Outfit",
  fontSize: "28px",
  fontWeight: "600",
  color: "#B81918",
};

export const tableHeadCell = {
  backgroundColor: "#ADADAD30",
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "19px",
  lineHeight: "23px",
  borderRight: "1px solid #ADADAD50",
};

export const tableBodyCell = {
  fontFamily: "Outfit",
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: "400",
  color: "#737679",
};
export const tableBodyCellRed = {
  fontFamily: "Outfit",
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: "500",
  color: "#B81918",
};

export const aplyfilter = {
  border: "1px solid #B81918",
  color: "#B81918",
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "12px",
  borderRadius: "30px",
  height: "2.5rem",
  padding: "0.5rem 1rem 0.5rem",
  textTransform: "none",
};
