import React from 'react';
import { TextField, Button, Grid , Select, MenuItem, FormControl, } from '@mui/material';
import { Typography, Box } from "@mui/material";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateDetailData, addAdditionalField, updateAdditionalField } from '../../../../Redux/AdminReducer/formsSlice';
import AddFeatures from './AddFeatures';
import AddMedia from './AddMedia';
import { useStyles, newIncomeTextfield, } from './PropertieStyle';





const AddDetails = () => {

    const dispatch = useDispatch();
    const [open, setopen] = useState(false);
    const [backcom, setbackcom] = useState(false);

    const detailData = useSelector(state => state.form.detailData);
    const [localData, setLocalData] = useState(detailData);
    const fields = useSelector(state => state.form.additionalFields);

    useEffect(() => {
        setLocalData(detailData);
    }, [detailData]);

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        dispatch(updateAdditionalField({ index, data: { [name]: value } }));
    };

    const handleAddField = () => {
        dispatch(addAdditionalField({ title: '', value: '' }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLocalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleBack = () => {
        dispatch(updateDetailData(localData));
        setbackcom(true);
    };

    if (backcom) {
        return <AddMedia />;
    }

    const handleNext = () => {
        dispatch(updateDetailData(localData));
        setopen(true);
    };

    if (open) {
        return <AddFeatures />;
    }

    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Add Property
                </Typography>
            </Box>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Details
                </Typography>
            </Box>

            <Grid container spacing={2} >
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}

                    >Bedrooms</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the number of bedrooms (only digits)'
                        name="bedrooms"
                        value={localData.bedrooms || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Bathrooms</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the number of bedrooms (only digits)'
                        name="bathrooms"
                        value={localData.bathrooms || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Area Size</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Property Area Size (only digits)'
                        name="area_size"
                        value={localData.area_size || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Size Postfix</Typography>
                    {/* <TextField fullWidth variant="outlined" placeholder='ft* (Sq Ft)'
                        name="size_postfix"
                        value={localData.size_postfix || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    /> */}



                    <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                            name="size_postfix"
                            value={localData.size_postfix || ''}
                            onChange={handleInputChange}
                            displayEmpty
                            required
                        >
                            <MenuItem value="" disabled>
                                Select Postfix Size
                            </MenuItem>
                            <MenuItem value="Sq.Ft">Sq.Ft</MenuItem>
                            <MenuItem value="Sq.M">Sq.M</MenuItem>

                        </Select>
                    </FormControl>




                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Garages</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the number of garages (only digits)'
                        name="garages"
                        value={localData.garages || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Garages Size</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the Garage size (Ex 200 Sq Ft)'
                        name="garage_size"
                        value={localData.garage_size || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Year Build</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Year Build (only digits)'
                        name="build_year"
                        value={localData.build_year || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "20px",
                    margin: "10px 0px 0px 0px"
                }}>
                    Additional  Details
                </Typography>
            </Grid>
            {fields.map((field, index) => (
                <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} md={6} key={field.id}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Title</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Eg: Equipment'
                            name="title"
                            value={field.title}
                            onChange={(e) => handleChange(index, e)}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Value</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Grill- Gas'
                            name="value"
                            value={field.value || ''}
                            onChange={e => handleChange(index, e)}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12} >
                <Button style={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 0px 20px 0px"

                }}
                    variant="contained"
                    onClick={handleAddField}
                >Add New</Button>
            </Grid>
            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                <Button style={{
                    border: "1px solid rgba(154, 25, 21, 1)",
                    color: "rgba(154, 25, 21, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 0px 50px 15px"
                }}
                    variant="outlined"
                    onClick={handleBack}

                >Back</Button>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"

                    }}
                        variant="contained"
                        onClick={handleNext}
                    >Next</Button>
                </Box>
            </Box>

        </>
    );
}

export default AddDetails;



