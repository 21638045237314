import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Avatar, Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();


  const Logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("loggedInUser")
    localStorage.removeItem("usertype")
    localStorage.removeItem("id")
    navigate('/')
  }


  const handlesubmit = () => {
    navigate('/admin/help&support')
    setAnchorEl(null);

  }

  const OpenProfile = () => {
    navigate('/admin/infoProfile')
    setAnchorEl(null);
  }


  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Box>
          <Avatar
            alt="Remy Sharp"
            src="/image/profile.jpg"
            sx={{ width: 50, height: 50 }}
          />
        </Box>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={OpenProfile}>My Profile</MenuItem>
        <MenuItem onClick={handlesubmit}>Help & Support</MenuItem>
        <Divider />
        <MenuItem onClick={Logout}>LogOut</MenuItem>
      </Menu>
    </div>
  );
};

export default Profile;
