import React from "react";
import { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useStyles } from '../Properties/Propertie/PropertieStyle';
import SearchIcon from '@mui/icons-material/Search';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";



const FirstTable = [
    {
        id: "1",
        name: "Landlord 1",
        icon: <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    },
    {
        id: "2",
        name: "Landlord 1",
        icon: <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    },
    {
        id: "3",
        name: "Landlord 1",
        icon: <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    },
    {
        id: "4",
        name: "Landlord 1",
        icon: <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    },
    {
        id: "5",
        name: "Landlord 1",
        icon: <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    },
    {
        id: "6",
        name: "Landlord 1",
        icon: <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    },
    {
        id: "7",
        name: "Landlord 1",
        icon: <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    },
    {
        id: "8",
        name: "Landlord 1",
        icon: <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    },


]




const MasterSheet = () => {
    const classes = useStyles();
    const [userData, setuserData] = useState([]);
    const [userfile, setuserfile] = useState([]);
    const [selectedValue, setSelectedValue] = useState({
        search: "",
    });



    const getUserListing = async () => {
        try {
            const token = localStorage.getItem("token");
            const requestOptions = {
                headers: {
                    Authorization: `token ${token}`,
                },
            };
            const response = await axios.get(`${endpoints.getUserListing}?search=${selectedValue.search}&user_type=2`, requestOptions);
            const Data = response.data.data
            setuserData(Data);
        } catch (error) {

        }
    };


    const downloadMasterSheet = async (id) => {
        try {
            const token = localStorage.getItem("token");
            const requestOptions = {
                headers: {
                    Authorization: `token ${token}`,
                },
                responseType: 'blob' // Important for downloading files
            };
            const response = await axios.get(`${endpoints.AdminMasterSheet}?landlord_id=${id}`, requestOptions);

            const blob = response.data; // In axios, the data is directly accessible via response.data
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Landlord_${id}_MasterSheet.xlsx`); // Corrected filename string
            document.body.appendChild(link);
            link.click();

            link.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };


    useEffect(() => {
        getUserListing();

    }, [selectedValue.search]);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSelectedValue((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    MasterSheet
                </Typography>
            </Box>

            <Box className={classes.root} sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6} lg={5}>
                        <div style={{ position: 'relative' }}>
                            <input
                                type="search"
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    border: '1px solid rgba(239, 239, 239, 1)',
                                    borderRadius: '30px',
                                    paddingLeft: '40px'
                                }}
                                placeholder="Search by Landlord name"
                                name="search"
                                value={selectedValue.search}
                                onChange={handleInputChange}
                                size="small"
                            />
                            <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
                                <SearchIcon />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>


            <Box style={{ overflowX: 'auto' }} mt={4}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Landlord Names</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userData.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell >{row.first_name || "N/A"}</TableCell>
                                <TableCell onClick={() => downloadMasterSheet(row.id)}>{<SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>


        </>
    )
}

export default MasterSheet;