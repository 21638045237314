import { Avatar, Box, Grid, Typography } from "@mui/material";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import React, { useEffect, useState } from "react";
import {
  mainTypography,
  muteTypography,
  notificationTitleTypography,
  notificationTypography,
  timeTypography,
  useStyles,
} from "./NotificationStyles";
import {
  parseISO,
  isToday,
  isThisMonth,
  isWithinInterval,
  startOfWeek,
  endOfWeek,
  isSameMonth,
} from "date-fns";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { UserTypesNum } from "../../../enums/UserTypes";
import DateFormatter from "../../../utils/DateFormatter";

const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#B81918",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 1.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#B81918",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function Notification() {
  const classes = useStyles();
  const [muteNotification, setMuteNotification] = useState(false);

  const [alerts, setAlerts] = useState(null);

  const handleClick = () => {
    console.log(`Switch is ${muteNotification ? "checked" : "unchecked"}`);
  };
  const handleSwitchChange = (event) => {
    setMuteNotification(event.target.checked);
  };
  const filterNotifications = (data) => {
    const recent = [];
    const weekly = [];
    const monthly = [];

    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
    const sevenDaysAgo = new Date(today - 7 * oneDay);
    const thirtyDaysAgo = new Date(today - 30 * oneDay);

    data?.forEach((entry) => {
      const createdAt = new Date(entry.created_at);

      if (createdAt.toDateString() === today.toDateString()) {
        recent.push(entry);
      } else if (createdAt >= sevenDaysAgo) {
        weekly.push(entry);
      } else if (createdAt >= thirtyDaysAgo) {
        monthly.push(entry);
      }
    });

    return { recent, weekly, monthly };
  };

  const financeNotifications = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        `${endpoints.getNotifications}?user_type=${
          UserTypesNum.finance
        }&user_id=${localStorage.getItem("id")}`,
        requestOptions
      );
      setAlerts(response.data.data);
    } catch {}
  };
  useEffect(() => {
    financeNotifications();
  }, []);

  const { recent, weekly, monthly } = filterNotifications(alerts);

  return (
    <>
      <Box className={classes.mainBox}>
        <Box>
          <Typography sx={mainTypography}>All Notification</Typography>
        </Box>
        {/* <Box className={classes.subBox}>
          <Typography sx={muteTypography}>Mute All Notification</Typography>
          <IOSSwitch
            checked={muteNotification}
            onChange={handleSwitchChange}
            value={muteNotification}
            onClick={handleClick}
          />
        </Box> */}
      </Box>

      {/* Recent Notifications */}
      <Box marginTop={"2.5rem"}>
        <Typography sx={muteTypography}>Recent</Typography>
      </Box>
      <Box height={"50vh"} overflow={"auto"}>
        <Grid
          container
          marginTop={"10px"}
          // border={"1px solid black"}
          // height={"30rem"}
        >
          {recent.length === 0 || muteNotification === true ? (
            <Grid item className={classes.emptyNotification} xs={12}>
              <Typography sx={notificationTitleTypography}>
                No recent Notification
              </Typography>
            </Grid>
          ) : (
            recent.map((alert) => (
              <Grid
                item
                className={classes.gridItem}
                xs={12}
                marginBottom={"0.5rem"}
                key={alert.id}
              >
                <Box>
                  <Avatar src={"/image/profile.jpg"} />
                </Box>
                <Box className={classes.gridTypoBox}>
                  <Box>
                    <Typography sx={notificationTitleTypography}>
                      {alert?.title || "Untitled Notification"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={notificationTypography}>
                      {alert?.body || "Content not available"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={timeTypography}>
                      {DateFormatter(alert?.created_at)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      {/* Weekly */}
      <Box marginTop={"10px"}>
        <Typography sx={muteTypography}>Weekly</Typography>
      </Box>
      <Box height={"50vh"} overflow={"auto"}>
        <Grid
          container
          marginTop={"10px"}
          // border={"1px solid black"}
          // height={"30rem"}
        >
          {weekly.length === 0 || muteNotification === true ? (
            <Grid item className={classes.emptyNotification} xs={12}>
              <Typography sx={notificationTitleTypography}>
                No Weekly Notification
              </Typography>
            </Grid>
          ) : (
            weekly.map((alert) => (
              <Grid
                item
                className={classes.gridItem}
                xs={12}
                marginBottom={"0.5rem"}
                key={alert.id}
              >
                <Box>
                  <Avatar src={"/image/profile.jpg"} />
                </Box>
                <Box className={classes.gridTypoBox}>
                  <Box>
                    <Typography sx={notificationTitleTypography}>
                      {alert?.title || "Untitled Notification"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={notificationTypography}>
                      {alert?.body || "Content not available"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={timeTypography}>
                      {DateFormatter(alert?.created_at)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      {/* Monthly */}
      <Box marginTop={"10px"}>
        <Typography sx={muteTypography}>Monthly</Typography>
      </Box>
      <Box height={"70vh"} overflow={"auto"}>
        <Grid
          container
          marginTop={"10px"}
          // border={"1px solid black"}
          // height={"30rem"}
        >
          {monthly.length === 0 || muteNotification === true ? (
            <Grid item className={classes.emptyNotification} xs={12}>
              <Typography sx={notificationTitleTypography}>
                No Monthly Notification
              </Typography>
            </Grid>
          ) : (
            monthly.map((alert) => (
              <Grid
                item
                className={classes.gridItem}
                xs={12}
                marginBottom={"0.5rem"}
                key={alert.id}
              >
                <Box>
                  <Avatar src={"/image/profile.jpg"} />
                </Box>
                <Box className={classes.gridTypoBox}>
                  <Box>
                    <Typography sx={notificationTitleTypography}>
                      {alert?.title || "Untitled Notification"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={notificationTypography}>
                      {alert?.body || "Content not available"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={timeTypography}>
                      {DateFormatter(alert?.created_at)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </>
  );
}

export default Notification;
