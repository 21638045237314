import { Box, Typography, Grid, Avatar, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  adressTypography,
  dropDownStyles,
  headingTypography,
  inputLabelStyle,
  uploadTypography,
  useStyles,
} from "./DocumentStyles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined"; // Custom outlined icon
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { useDispatch, useSelector } from "react-redux";
import {
  setFile,
  updateSelectedType,
} from "../../../Redux/TenantReducer/documentSlice";
import { identificationDocuments } from "../../../enums/UserTypes";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";

const Documents = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const [documents, setDocuments] = useState([]);
  const fileInput = useRef(null);
  const dispatch = useDispatch();
  const selectedType = useSelector((state) => state.document.selectedType);
  const selectedUploadFile = useSelector((state) => state.document.file);

  const handleChange = (e) => {
    // setselectType(event.target.value);
    const type = e.target.value;
    setDocumentType(type); // Update local state
    dispatch(updateSelectedType(type)); // Dispatch action to update Redux state
  };

  const getDocuments = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `toke ${token}`,
        },
      };
      let url = endpoints.tenantDocuments;
      if (documentType) {
        url = `${url}?type=${documentType}`;
      }

      const response = await axios.get(url, requestOptions);
      const data = response.data.data;
      setDocuments(data);

    } catch (error) {
      setDocuments([]);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   getDocuments();
  // }, [documentType]);

  useEffect(() => {
    getDocuments();
    if (documentType) {  // Fetch documents only when a document type is selected.
      getDocuments();
    }
  }, [documentType]);

  const handleUploadClick = () => {
    fileInput.current.click();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    dispatch(setFile(file));
    console.log("RDK", selectedUploadFile, selectedType);
  };

  const downloadFile = (fileUrl, type) => {
    if (!fileUrl) {
      alert("Document not available.");
      return;
    }

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${type}.${getFileExtension(fileUrl)}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("Could not fetch the file."));
  };

  function getFileExtension(url) {
    // Use a regular expression to match the file extension
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(url)[1];
    return extension;
  }

  return (
    <>
      <Box display={"flex"} justifyContent={{ xs: "center", sm: "flex-start" }}>
        <Typography sx={headingTypography}>All Documents</Typography>
      </Box>
      <Box className={classes.mainBox}>
        {/* DropDown */}
        <Box width={{ xs: "40%", sm: "20%", md: "20%" }}>
          <FormControl fullWidth>
            <Select
              value={documentType}
              onChange={handleChange}
              IconComponent={ExpandMoreOutlinedIcon} // Change the icon to outlined version
              sx={dropDownStyles}
              displayEmpty
            >
              <MenuItem value="" selected>
                Select Doc Type
              </MenuItem>
              {Object.values(identificationDocuments).map((document) => (
                <MenuItem value={document.value} key={document.value}>
                  {document.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {/*  */}
      {loading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : documents.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <Box marginTop={"2.5rem"} maxHeight={"38rem"} overflow={"auto"}>
          {documents?.map((document) => (
            <Box key={document.id}>
              <Box marginBottom={"0.5rem"} marginTop={"1rem"}>
                <Typography sx={adressTypography}>
                  {document?.type || "Not available"}
                </Typography>
              </Box>
              <Grid
                item
                border={"1px solid #F1F1F1"}
                display={"flex"}
                justifyContent={"space-between"}
                padding={"1rem"}
                borderRadius={"1rem"}
                marginBottom={"0.5rem"}
                alignItems={"center"}
              >
                <Typography>
                  {document?.file.split("/").pop() || "Not available"}
                </Typography>
                <Tooltip title="Download">
                  <SystemUpdateAltIcon
                    className={classes.iconStyle}
                    onClick={() => {
                      if (document.file) {
                        downloadFile(
                          document.file,
                          document.file.split("/").pop()
                        );
                      } else {
                        toast.error("Document not available.");
                      }
                    }}
                  />
                </Tooltip>
                {/* <Avatar
                src="/image/download.png"
                variant="square"
                sx={{ width: "1.5rem", height: "1.5rem" }}
              /> */}
              </Grid>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default Documents;
