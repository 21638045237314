import React from "react";
import { useState, useEffect } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from './PropertieStyle';
import ViewDetail from "./ViewDetail";
import { endpoints } from "../../../../apiEndpoints";
import axios from 'axios';
import toast from "react-hot-toast";
import PropertyMaintenance from './PropertyMaintenance';
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../../utils/Loader";




const Disdata = [
    {
        id: 1,
        image: "/image/history.png",
        label: "Landlord Name || Property Name",
        title: "Approved",
    },
    {
        id: 2,
        image: "/image/history.png",
        label: "Landlord Name || Property Name",
        title: "Rejected",

    },

]



const PropertyHistory = ({ id }) => {
    const classes = useStyles();
    const [propertyhistory, setpropertyhistory] = useState(null);
    const [close, setClose] = useState({ open: false, unitId: null });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);




    const getpropertyhistory = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.getpropertyhistory}?id=${id}`, {
                headers: { Authorization: `Token ${token}` }
            });
            setpropertyhistory(response.data.data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        getpropertyhistory();
    }, [])




    const tenantdata = propertyhistory?.tenant_history || [];
    const UnitHistory = propertyhistory?.unit_association_history || [];


    const handleOpen = (unitId) => {
        setClose({ open: true, unitId });
    };

    if (close.open) {
        return <PropertyMaintenance unitId={close.unitId} />;
    }


    const handleClick = (tenant, unit, moveInDate, moveOutDate) => {
        navigate('/admin/view-tenant', {
            state: {
                tenant,
                unit,
                moveInDate,
                moveOutDate
            }
        });
    };


    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Property History
                </Typography>
            </Box>


            {/* First Box */}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: 500,
                    color: "rgba(184, 25, 24, 1)",
                    margin: "10px 0px 20px 0px"
                }}>
                    Rent Reimbursement Requests
                </Typography>
            </Box>

            <Grid item xs={12} md={12}>
                <Box>
                    {Disdata.map((item, index) => (
                        <Box key={index} style={{
                            display: "flex",
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: "1px solid #EAECF0",
                            margin: "15px 0px 0px 0px",
                            borderRadius: "5.8px"
                        }} >
                            <Box style={{
                                display: "flex",
                                alignItems: 'center',
                            }} className={classes.our}>
                                <Avatar alt="Remy Sharp" src={item.image} />
                                <Typography style={{
                                    color: "#060606",
                                    fontFamily: "Outfit",
                                    fontSize: "14px",
                                    lineHeight: "22px",
                                    fontWeight: 700,
                                    margin: "0px 0px 0px 10px",
                                    borderRadius: '10px'
                                }}>
                                    {item.label}
                                </Typography>
                            </Box>
                            <Button
                                style={{
                                    textTransform: "none",
                                    fontFamily: "Outfit",
                                    fontSize: "10px",
                                    lineHeight: "10px",
                                    fontWeight: 500,
                                    margin: "0px 25px 0px 0px",
                                    color: item.title === 'Approved' ? 'blue' : item.title === 'Rejected' ? '#DD2025' : 'black',

                                    padding: "5px 15px 5px 15px",
                                    borderRadius: "5px"

                                }}>
                                {item.title}
                            </Button>
                        </Box>
                    ))}

                </Box>
            </Grid>

            {/* Second Box */}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: 500,
                    color: "rgba(184, 25, 24, 1)",
                    margin: "25px 0px 20px 0px"
                }}>
                    Tenant Details
                </Typography>
            </Box>

            {loading ? (
                <Box
                    display={"flex"}
                    height={"100vh"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Loader />
                </Box>
            ) : UnitHistory.length === 0 ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50vh"
                >
                    <Typography>No data available</Typography>
                </Box>
            ) : (
                <Grid item xs={12} md={12}>
                    <Box>
                        {tenantdata?.map((historyItem, historyIndex) => (
                            historyItem.unit.map((unit, unitIndex) => (
                                <Box key={`${historyIndex}-${unitIndex}`} style={{
                                    display: "flex",
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: "1px solid #EAECF0",
                                    margin: "15px 0px 0px 0px",
                                    borderRadius: "5.8px"
                                }} >
                                    <Box style={{
                                        display: "flex",
                                        alignItems: 'center',
                                    }} className={classes.our}>
                                        <Avatar alt="Remy Sharp" />
                                        <Typography style={{
                                            color: "#060606",
                                            fontFamily: "Outfit",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            fontWeight: 700,
                                            margin: "0px 0px 0px 10px",
                                            borderRadius: '10px'
                                        }}>
                                            {`${historyItem.tenant.first_name} || ${unit.ref_id}`}
                                            <Typography style={{
                                                color: "rgba(26, 31, 54, 1)",
                                                fontFamily: "Outfit",
                                                fontSize: "14px",
                                                lineHeight: "22px",
                                                fontWeight: 400,
                                                margin: "0px 0px 0px 0px",
                                                borderRadius: '10px'
                                            }}>
                                                {`Move-In: ${historyItem.move_in_date} - Move-Out: ${historyItem.move_out_date}`}
                                            </Typography>
                                            <Typography style={{
                                                color: "rgba(165, 172, 184, 1)",
                                                fontFamily: "Outfit",
                                                fontSize: "14px",
                                                lineHeight: "22px",
                                                fontWeight: 500,
                                                margin: "0px 0px 0px 0px",
                                                borderRadius: '10px'
                                            }}
                                            >
                                                {historyItem.tenancy_duration}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                    <Button
                                        style={{
                                            textTransform: "none",
                                            fontFamily: "Outfit",
                                            fontSize: "10px",
                                            lineHeight: "10px",
                                            fontWeight: 500,
                                            margin: "0px 25px 0px 0px",
                                            padding: "5px 15px 5px 15px",
                                            borderRadius: "5px",
                                            backgroundColor: "rgba(184, 25, 24, 1)",
                                            color: "rgba(255, 255, 255, 1)",
                                            height: "30px"

                                        }}
                                        onClick={() => handleClick(historyItem.tenant, unit, historyItem.move_in_date, historyItem.move_out_date)}
                                    >
                                        View Detail
                                    </Button>
                                </Box>
                            ))

                        ))}

                    </Box>
                </Grid>
            )}





            {/* Third Box */}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: 500,
                    color: "rgba(184, 25, 24, 1)",
                    margin: "25px 0px 20px 0px"
                }}>
                    Unit Details
                </Typography>
            </Box>

            {loading ? (
                <Box
                    display={"flex"}
                    height={"100vh"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Loader />
                </Box>
            ) : UnitHistory.length === 0 ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50vh"
                >
                    <Typography>No data available</Typography>
                </Box>
            ) : (
                <Grid item xs={12} md={12}>
                    <Box>
                        {UnitHistory.map((historyItem, historyIndex) => (
                            historyItem.unit.map((unit, unitIndex) => (
                                <Box key={`${historyIndex}-${unitIndex}`} style={{
                                    display: "flex",
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: "1px solid #EAECF0",
                                    margin: "15px 0px 0px 0px",
                                    borderRadius: "5.8px"
                                }}>
                                    <Box style={{
                                        display: "flex",
                                        alignItems: 'center',
                                    }} className={classes.our}>
                                        <Avatar />
                                        <Typography style={{
                                            color: "#060606",
                                            fontFamily: "Outfit",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            fontWeight: 700,
                                            margin: "0px 0px 0px 10px",
                                            borderRadius: '10px'
                                        }}>
                                            {`${historyItem.tenant.first_name} ||`}
                                        </Typography>
                                        <Typography style={{
                                            color: "#060606",
                                            fontFamily: "Outfit",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            fontWeight: 700,
                                            margin: "0px 0px 0px 10px",
                                            borderRadius: '10px'
                                        }}>
                                            {`${historyItem.landlord.first_name} ||`}
                                        </Typography>
                                        <Typography style={{
                                            color: "#060606",
                                            fontFamily: "Outfit",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            fontWeight: 700,
                                            margin: "0px 0px 0px 10px",
                                            borderRadius: '10px'
                                        }}>
                                            {unit.ref_id}
                                        </Typography>
                                    </Box>
                                    <Button
                                        style={{
                                            textTransform: "none",
                                            fontFamily: "Outfit",
                                            fontSize: "10px",
                                            lineHeight: "10px",
                                            fontWeight: 500,
                                            margin: "0px 25px 0px 0px",
                                            padding: "5px 15px 5px 15px",
                                            borderRadius: "5px",
                                            backgroundColor: "rgba(184, 25, 24, 1)",
                                            color: "rgba(255, 255, 255, 1)",
                                            height: "30px"
                                        }}
                                        onClick={() => handleOpen(unit.id)}
                                    >
                                        Maintenance Requests
                                    </Button>
                                </Box>
                            ))
                        ))}



                    </Box>
                </Grid>
            )}


        </>
    )
}


export default PropertyHistory;