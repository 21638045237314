import React, { useState, useEffect } from "react";
import { Grid, Box, InputLabel } from "@mui/material";
import {
  StatusTypography,
  StatusButton,
  UserDetailsTypography,
  UserDetailsTypography2,
  UserDetailsBox,
  ImgsBox,
} from "./MaintenanceStyles";
import {
  Select,
  MenuItem,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
} from "@mui/material";
import { endpoints } from "../../../apiEndpoints";
import axios from 'axios';
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { updateRequestsenums } from '../../../enums/UserTypes';

function MaintenanceStatus() {
  const [maintenanceStatus, setMaintenanceStatus] = useState([]);
  const [formData, setFormData] = useState({
    maintenance_status: ""
  });
  const location = useLocation();
  const { state } = location;
  const { id } = state || {};



  useEffect(() => {
    const getMaintenanceRequests = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${endpoints.getmaintenanceRequests}?id=${id}`, {
          headers: { Authorization: `Token ${token}` }
        });
        console.log("API Response Data:", response.data.data);
        setMaintenanceStatus(response.data.data[0] || {});
        const fetchedStatus = response.data.data[0]?.maintenance_status || "";
        setFormData({ maintenance_status: fetchedStatus });
      } catch (error) {
        console.error("API Error:", error);
        toast.error(error.response.data.message);
      }
    };

    if (id) {
      getMaintenanceRequests();
    }
  }, [id]);

  const Updaterequest = async (approvalValue) => {
    const data = { admin_approval: approvalValue.toString() };
    try {
      const token = localStorage.getItem('token');
      const response = await axios.patch(`${endpoints.getmaintenanceRequests}?maintenance_id=${id}`, data,
        {
          headers: { Authorization: `Token ${token}` }
        });
      setMaintenanceStatus(prevState => ({
        ...prevState,
        admin_approval: approvalValue
      }));


    } catch (error) {
      console.error("API Error:", error);
      toast.error(error.response.data.message);
    }
  };

  const images = [
    "/image/ktchn1 (1).png",
    "/image/ktchn1 (2).png",
    "/image/ktchn1 (3).png",
    "/image/ktchn1 (4).png",
    "/image/ktchn1 (3).png",
  ];

  const getStatusText = (status) => {
    return updateRequestsenums[status] || "Unknown Status";
  };



  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    await updateMaintenanceStatus(value);
  };

  const updateMaintenanceStatus = async (status) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.patch(`${endpoints.getmaintenanceRequests}?maintenance_id=${id}`, {
        maintenance_status: status
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success("Maintenance status updated successfully!");
    } catch (error) {
      console.error("API Error:", error);
      toast.error(error.response?.data.message || "Failed to update status");
    }
  };

  return (
    <>
      <Grid height={"72.188rem"}>
        <Grid item>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
            }}
          >
            <StatusTypography
              sx={{ fontSize: { xs: "25px", sm: "25px", md: "36px" } }}
            >
              Maintenance Requests
            </StatusTypography>
            <Box>
              {maintenanceStatus?.admin_approval == 1 && (
                <>
                  <StatusButton style={{ margin: "0px 15px 0px 0px" }} onClick={() => Updaterequest(3)}>Disapprove</StatusButton>
                  <StatusButton style={{ margin: "0px 0px 0px 0px" }} onClick={() => Updaterequest(2)}>Approve</StatusButton>
                </>
              )}
              {maintenanceStatus?.admin_approval == 2 && (
                <>
                  <Box >
                    <StatusButton>Approved</StatusButton>

                  </Box>
                </>
              )}
              {maintenanceStatus?.admin_approval == 3 && (
                <StatusButton>Disapproved</StatusButton>
              )}
            </Box>
          </Box>


          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{
              flexDirection: {
                xs: "column-reverse",
                sm: "column-reverse",
                md: "row",
              },
            }}
          >
            <Box>
              {/* Name */}
              <UserDetailsBox>
                <UserDetailsTypography>Full Name</UserDetailsTypography>
                <UserDetailsTypography2>
                  {maintenanceStatus?.name || "Loading..."}
                </UserDetailsTypography2>
              </UserDetailsBox>

              {/* Email */}
              <UserDetailsBox>
                <UserDetailsTypography>Email</UserDetailsTypography>
                <UserDetailsTypography2>
                  {maintenanceStatus?.email || "Loading..."}
                </UserDetailsTypography2>
              </UserDetailsBox>

              {/* Unit Number */}
              <UserDetailsBox>
                <UserDetailsTypography>Unit Number</UserDetailsTypography>
                <UserDetailsTypography2>
                  {maintenanceStatus?.unit || "Loading..."}
                </UserDetailsTypography2>
              </UserDetailsBox>

              {/* Address */}
              <UserDetailsBox>
                <UserDetailsTypography>Full Address</UserDetailsTypography>
                <UserDetailsTypography2>
                  {maintenanceStatus?.address || "Loading..."}
                </UserDetailsTypography2>
              </UserDetailsBox>

              {/* Dropdown */}
              <UserDetailsBox>
                <UserDetailsTypography>Urgency</UserDetailsTypography>
                <UserDetailsTypography2>
                  {getStatusText(maintenanceStatus?.priority || "Loading...")}
                </UserDetailsTypography2>
              </UserDetailsBox>

              {/* Images/Video */}
              <UserDetailsBox sx={{ height: "11.72rem" }}>
                <UserDetailsTypography>Images/Videos</UserDetailsTypography>
                <ImgsBox>
                  {/* {images.map((item, index) => ( */}
                  <img  src={maintenanceStatus?.media} alt="ktchn" style={{ width: "7rem" }} />
                  {/* ))} */}
                </ImgsBox>
              </UserDetailsBox>

              {/* Description */}
              <UserDetailsBox sx={{ height: "14.73rem" }}>
                <UserDetailsTypography>
                  Description of Request
                </UserDetailsTypography>
                <UserDetailsTypography2
                  sx={{
                    height: "11.188rem",
                    display: "flex",
                    alignItems: "flex-start",
                    paddingTop:'0.5rem'
                  }}
                >
                  {maintenanceStatus?.description || "Loading..."}
                </UserDetailsTypography2>
              </UserDetailsBox>
            </Box>





            {maintenanceStatus?.admin_approval == 2 && (
              <>
                <Box >
                  <Grid item>
                    <Box
                      border={"1px solid #9A1915"}
                      width={"20.181rem"}
                      height={"9.688rem"}
                      borderRadius={"8px"}
                      p={2}
                      paddingLeft={"1.5rem"}
                    >
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="maintenance_status"
                          value={formData.maintenance_status}
                          onChange={handleChange}
                          sx={{ flexDirection: "column" }}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio sx={{ color: '#B81918', '&.Mui-checked': { color: '#B81918' } }} />}
                            label="Pending"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio sx={{ color: '#B81918', '&.Mui-checked': { color: '#B81918' } }}/>}
                            label="In-processing"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio sx={{ color: '#B81918', '&.Mui-checked': { color: '#B81918' } }}/>}
                            label="Resolved"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>

                </Box>
              </>
            )}

          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default MaintenanceStatus;


