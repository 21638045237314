import { useState } from "react";
import MaintenanceRequests from "./MaintenanceRequests";
import Complaints from "./Complaints";
import {
  StyledTypography,
  StyledMainBox,
  StyledContainerBox,
  TitleTypography,
} from "./MaintenanceStyles";

const Maintenance = () => {
  const [selectedOption, setSelectedOption] = useState("maintenance");

  const handleTypographyClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <StyledMainBox>
        <TitleTypography>
          {selectedOption === "maintenance"
            ? "Maintenance Requests"
            : "Complaints"}
        </TitleTypography>
      </StyledMainBox>
      <StyledContainerBox>
        <StyledTypography
          onClick={() => handleTypographyClick("maintenance")}
          isActive={selectedOption === "maintenance"}
        >
          Maintenance Requests
        </StyledTypography>
        <StyledTypography
          onClick={() => handleTypographyClick("complaints")}
          isActive={selectedOption === "complaints"}
        >
          Complaints
        </StyledTypography>
      </StyledContainerBox>

      {selectedOption === "complaints" ? (
        <Complaints />
      ) : (
        <MaintenanceRequests />
      )}
    </>
  );
};

export default Maintenance;
