import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  mainTypography,
  modalTypography,
  newPdcTextfield,
  savePdcBtn,
  useStyles,
} from "./PDCStyles";
import { PdcStatus } from "../../../enums/UserTypes";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";

function EditPDC() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { pdc } = location.state || {};
  // const statuses = ["Active", "Inactive", "Pending"];
  const [data, setData] = useState({
    status: null,
    date: null,
    comments: null,
  });

  useEffect(() => {
    if (pdc) {
      setData({
        status: pdc.cheque_status,
        date: pdc.cleared_date,
        comments: pdc.comments,
      });
    }
  }, [pdc]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Store the value in local storage
    if (name === "comments") {
      localStorage.setItem("comments", value);
    }
  };

  

  const updatePDC = async () => {
    try {
      const formData = new FormData();
      formData.append('cheque_status',data.status);
      formData.append('comments',data.comments);
      if(data.date){
        formData.append('cleared_date',data.date)
      }

      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.patch(
        `${endpoints.financePDCs}?id=${pdc.id}`,
        formData,
        requestOptions
      );
      toast.success(response.data.message);
      navigate("/finance/pdcs");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Box>
        <Typography sx={mainTypography}>PDC Document</Typography>
      </Box>

      <Grid container spacing={3} marginTop={"2rem"}>
        <Grid item xs={12} sm={data.status == PdcStatus.Cleared.value ? 5 : 10}>
          <Box className={classes.gridItemBox}>
            <Typography sx={modalTypography}>Status</Typography>
            <FormControl sx={newPdcTextfield} fullWidth>
              {/* <InputLabel sx={{ color: "#73767960" }}>Change Status</InputLabel> */}
              <Select
                name="status"
                value={data.status ?? ""}
                onChange={handleChange}
                // label="Change Status"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Status
                </MenuItem>
                {Object.entries(PdcStatus).map(([key, status]) => (
                  <MenuItem key={key} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {data.status == PdcStatus.Cleared.value ? (
          <Grid item xs={12} sm={5}>
            <Box className={classes.gridItemBox}>
              <Typography sx={modalTypography}>Cleared-Cheque Date</Typography>
              <TextField
                type="date"
                value={data.date || ""}
                name="date"
                onChange={handleChange}
                sx={newPdcTextfield}
                fullWidth
              />
            </Box>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12} sm={10}>
          <Box className={classes.gridItemBox}>
            <Typography sx={modalTypography}>Comments</Typography>
            <TextField
              sx={newPdcTextfield}
              placeholder="Comments here"
              name="comments"
              value={data.comments}
              onChange={handleChange}
              fullWidth
              multiline
              rows={5}
            />
          </Box>
        </Grid>
        <Grid item sm={3}>
          <Button onClick={updatePDC} sx={savePdcBtn}>
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default EditPDC;
