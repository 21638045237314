import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, IconButton, Button } from "@mui/material";
import { useStyles } from '../../../AdminComponent/Pages/Properties/Propertie/PropertieStyle';
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
import ChatIcon from '@mui/icons-material/Chat';
import { firestore, collection, addDoc, query, orderBy, onSnapshot, doc, updateDoc } from '../../../firebase';
import { auth, onAuthStateChanged } from '../../../firebase';
import toast from "react-hot-toast";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { useNavigate } from "react-router-dom";
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';







const Chats = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [selectedValue, setSelectedValue] = useState({ name: "" });
    const [user, setUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState({});

    const GetUserData = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.getchatuser}?search=${selectedValue.name}`, {
                headers: { Authorization: `Token ${token}` }
            });
            setUserData(response.data.data);
        } catch (error) {
            console.error("Error fetching user data", error);
            toast.error("Failed to fetch user data.");
        }
    };

    useEffect(() => {
        GetUserData();
    }, [selectedValue.name]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, setUser);
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        userData?.forEach(user => {
            if (user && user.email) {
                const chatId = [auth.currentUser.email, user.email].sort().join('_');
                const q = query(collection(firestore, `chats/${chatId}/messages`), orderBy('timestamp'));
                const unsubscribe = onSnapshot(q, (snapshot) => {
                    const fetchedMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    const unreadCount = fetchedMessages.filter(msg => msg.receiverId === auth.currentUser.email && !msg.read).length;
                    setUnreadMessages(prev => ({ ...prev, [user.email]: unreadCount }));
                });
                return () => unsubscribe();
            }
        });
    }, [userData]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSelectedValue({ ...selectedValue, [name]: value });
    };

    const handleSubmit = (user) => {
        navigate('/tenant/message', {
            state: {
                first_name: user.first_name,
                email: user.email
            }
        });
    };

    return (
        <>
            <Box>
                <Typography style={{ fontFamily: "Outfit", fontSize: "24px", lineHeight: "24px", fontWeight: 500, color: "rgba(0, 0, 0, 1)", margin: "0px 0px 40px 0px" }}>
                    Communication Hub
                </Typography>
            </Box>
            <Box>
                <Typography style={{ fontFamily: "Outfit", fontSize: "18px", lineHeight: "20px", fontWeight: 500, color: "#B81918", margin: "0px 0px 35px 0px" }}>
                    Start Your Chat easily!
                </Typography>
            </Box>
            <Box className={classes.root} sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6} lg={5}>
                        <div style={{ position: 'relative' }}>
                            <input
                                type="search"
                                style={{ width: '100%', height: '40px', border: '1px solid rgba(239, 239, 239, 1)', borderRadius: '30px', paddingLeft: '40px' }}
                                placeholder="Search by Name"
                                name="name"
                                value={selectedValue.name}
                                onChange={handleInputChange}
                                size="small"
                            />
                            <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
                                <SearchIcon />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Grid item xs={12} md={12}>
                <Box>
                    {userData?.map((item, index) => (
                        <Box key={index} style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', border: "1px solid #EAECF0", margin: "15px 0px 0px 0px", borderRadius: "5.8px" }}>
                            <Box style={{ display: "flex", alignItems: 'center' }} className={classes.our}>
                                <Avatar src={item.image} />
                                <Typography style={{ color: "#060606", fontFamily: "Outfit", fontSize: "14px", lineHeight: "22px", fontWeight: 700, margin: "0px 0px 0px 10px", borderRadius: '10px' }}>
                                    {item.first_name}
                                </Typography>
                            </Box>
                            {/* <IconButton onClick={() => handleSubmit(item)}>
                                <ChatIcon style={{ margin: "0px 20px 0px 0px", color: "#B81918" }} />
                                {unreadMessages[item.email] || 0}
                            </IconButton> */}


                            <Badge onClick={() => handleSubmit(item)}
                         
                                style={{ margin: "0px 30px 0px 0px", color: "#B81918" , }}
                                badgeContent={unreadMessages[item.email] || 0} >
                                <ChatIcon style={{ margin: "0px 0px 0px 0px", color: "#B81918" }} />
                            </Badge>


                        </Box>
                    ))}
                </Box>
            </Grid>
        </>
    );
};

export default Chats;
