import React from "react";
import menuData from "./menuData";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Nav from "./Nav";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Selection from "../Pages/Properties/Selection";
import Documents from "../Pages/Documents/Documents";
import Notification from "../Pages/Notification/Notification";
import Maintenance from "../Pages/Maintenance/Maintenance";
import Client from "../Pages/Client/Client";
import Chat from "../Pages/Chat/Chat";
import Inquiries from "../Pages/Inquiries/Inquiries";
import Contracts from "../Pages/Contracts/Contracts";
import HelpAndSupport from "../Pages/HelpAndSupport/HelpAndSupport";
import MaintenanceStatus from "../Pages/Maintenance/MaintenanceStatus";
import ComplaintStatus from "../Pages/Maintenance/ComplaintStatus";
import AddUser from "../Pages/Client/AddUser";
import Propertie from "../Pages/Properties/Propertie/Property";
import Units from "../Pages/Properties/Units/Units";
import Profiles from "../Pages/Client/Profiles";
import InfoProfile from "./InfoProfile";
import InquiriesForm from "../Pages/Inquiries/InquiriesForm";
import PropertyDetail from "../Pages/Properties/Propertie/PropertyDetail";
import UnitDetail from "../Pages/Properties/Units/UnitDetail";
import RequestDetail from "../Pages/Documents/RequestDetail";
import Rent from "../Pages/Rent/Rent";
import MasterSheet from "../Pages/MasterSheet/MasterSheet";
import AMCSchedule from "../Pages/AMCSchedule/AMCSchedule";
import PropertyAssociation from "../Pages/PropertyAssociation/PropertyAssociation";
import ViewProperty from "../Pages/Properties/Propertie/ViewProperty";
import EditLandlord from "../Pages/Contracts/EditLandlord";
import TenantAssociation from "../Pages/PropertyAssociation/TenantAssociation";
import Tenantinfopdate from "../Pages/PropertyAssociation/Tenantinfopdate";
import LandlordInfoUpdate from "../Pages/PropertyAssociation/LandlordInfoUpdate";
import LandlordAssociationform from "../Pages/PropertyAssociation/LandlordAssociationform";
import EditTenant from "../Pages/Contracts/EditTenant";
import PropertyUnitHistory from "../Pages/Properties/Propertie/PropertyUnitHistory";
import ViewDetail from "../Pages/Properties/Propertie/ViewDetail";
import EditRenewForm from "../Pages/Contracts/EditRenewForm";
import ChatBox from "../Pages/Chat/ChatBox";
import AddRent from "../Pages/Rent/AddRent";
import AddUnit from "../Pages/Properties/Units/AddUnit";
import AddInquiries from "../Pages/Inquiries/AddInquiries";
import AddDocument from "../Pages/Documents/AddDocument";
import AddTentant from "../Pages/Contracts/AddTentant";
import AddLandlord from "../Pages/Contracts/AddLandlord";
import DraftContract from "../Pages/Contracts/DraftContract";




const useStyles = makeStyles(() => {
  const theme = useTheme();
  console.log(theme);

  return {
    root: {
      width: "100%",
      minHeight: "100vh",
      height: "auto",
      boxSizing: "border-box",
      padding: "0px 24px 0px 300px",
      [theme.breakpoints.down("lg")]: {
        padding: "0px 24px 24px 24px",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "50px 0px 0px 0px",
      },
    },
  };
});
const AdminPortal = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Nav menuData={menuData} />
      <Box className={classes.root}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/selection" element={<Selection />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/client" element={<Client />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/inquiries" element={<Inquiries />} />
          <Route path="/contracts" element={<Contracts />} />
          <Route path="/help&support" element={<HelpAndSupport />} />
          <Route path="/maintenance-status" element={<MaintenanceStatus />} />
          <Route path="/complaints-status" element={<ComplaintStatus />} />
          <Route path="/client/addUser" element={<AddUser />} />
          <Route path="/selection/property" element={<Propertie />} />
          <Route path="/selection/unit" element={<Units />} />
          <Route path="/client/profiles" element={<Profiles />} />
          <Route path="/infoProfile" element={<InfoProfile />} />
          <Route path="/inquiriesForm " element={<InquiriesForm />} />
          <Route path="/selection/propertyDetail" element={<PropertyDetail />} />
          <Route path="/selection/unitDetail" element={<UnitDetail />} />
          <Route path="/requestDetail" element={<RequestDetail />} />
          <Route path="/rent" element={<Rent />} />
          <Route path="/masterSheet" element={<MasterSheet />} />
          <Route path="/amcschedule" element={<AMCSchedule />} />
          <Route
            path="/association"
            element={<PropertyAssociation />}
          />
          <Route path="/association/tenant-association" element={<TenantAssociation />} />
          <Route path="/viewProperty" element={<ViewProperty />} />
          <Route path="/contracts/edit-landlord" element={<EditLandlord />} />
          <Route path="/association/tenant-infoupdate" element={<Tenantinfopdate />} />
          <Route path="/association/landlord-infoupdate" element={<LandlordInfoUpdate />} />
          <Route
            path="/association/landlord-associationform"
            element={<LandlordAssociationform />}
          />
          {/* <Route
            path="/propertyassociation"
            element={<PropertyAssociation />}
          /> */}
          {/* <Route path="/viewProperty" element={<ViewProperty />} /> */}
          {/* <Route path="/edit-landlord" element={<EditLandlord />} /> */}
          <Route path="/contracts/edit-tenant" element={<EditTenant />} />
          <Route path="/Property-unit-history" element={<PropertyUnitHistory />} />
          <Route path="/view-tenant" element={<ViewDetail />} />
          <Route path="/message" element={<ChatBox />} />
          <Route
            path="/Property-unithistory"
            element={<PropertyUnitHistory />}
          />
          <Route path="/view-tenant" element={<ViewDetail />} />
          <Route path="/edit-renew-form" element={<EditRenewForm />} />
          <Route path="/message" element={<ChatBox />} />
          <Route path="/rent/update" element={<AddRent />} />
          <Route path="/create-unit" element={<AddUnit />} />
          <Route path="/inquiries/add-inquiries" element={<AddInquiries />} />
          <Route path="/documents/add-document" element={<AddDocument />} />
          <Route path="/contracts/add-tentant" element={<AddTentant />} />
          <Route path="/contracts/add-landlord" element={<AddLandlord />} />
          <Route path="/contracts/draft-contract" element={<DraftContract />} />
        </Routes>
      </Box>
    </Fragment>
  );
};

export default AdminPortal;
