import React, { useEffect, useState } from "react";
import { useStyles } from "./MaintenanceStyles";
import { Grid, Box, Typography, Button } from "@mui/material";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { maintenanceStatus, rentStatus } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";

function ComplaintsHistory() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [complaintsData, setComplaintsData] = useState(null);

  // Function to determine the color of the button based on the btn property
  const getButtonColor = (btn) => {
    switch (btn) {
      case "pending":
        return "#B81918"; // Orange color for pending status
      case "in progress":
        return "#558FFF"; // Yellow color for in progress status
      default:
        return "#00E317"; // Green color for other statuses
    }
  };

  const getComplaints = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        endpoints.complaintRequests,
        requestOptions
      );
      setComplaintsData(response.data.data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  useEffect(() => {
    getComplaints();
  }, []);
  return (
    <>
      {loading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : complaintsData && complaintsData?.length > 0 ? (
        <Grid container xs={12} spacing={1} className={classes.mainGrid}>
          {complaintsData?.map((complaint) => (
            <Grid item key={complaint.id} xs={12} className={classes.gridItem}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontWeight: "500",
                    fontSize: { xs: "13px", sm: "15px" },
                  }}
                >
                  {complaint.created_at.split("T")[0]}
                </Typography>
              </Box>
              <Box
                className={classes.infoBox}
                display={"flex"}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontWeight: "500",
                    fontSize: { xs: "13px", sm: "15px" },
                    cursor: "pointer",
                  }}
                >
                  {/* {complaint.description} */}
                  {truncateText(complaint.description, 30)}
                </Typography>
                {complaint.admin_approval === rentStatus.pending.value ? (
                  <Button
                    variant="outlined"
                    sx={{
                      // color: getButtonColor(complaint.btn),
                      color: "#B81918",
                      borderColor: "#B81918",
                      // borderColor: getButtonColor(complaint.btn),
                      width: { xs: "42%", sm: "18%" },
                      fontSize: "10px",
                      fontWeight: "400",
                      "&:hover": {
                        // backgroundColor: "#B81918", // Same color as default to "unset" hover
                        color: "#B81918",
                        borderColor: "#B81918",
                        boxShadow: "none", // Remove any shadow on hover
                      },
                    }}
                  >
                    Pending
                  </Button>
                ) : complaint.admin_approval === rentStatus.approved.value ? (
                  <Button
                    variant="outlined"
                    sx={{
                      // color: getButtonColor(complaint.btn),
                      // borderColor: getButtonColor(complaint.btn),
                      color: "#558FFF",
                      borderColor: "#558FFF",
                      width: { xs: "42%", sm: "18%" },
                      fontSize: "10px",
                      fontWeight: "400",
                      "&:hover": {
                        // backgroundColor: "#B81918", // Same color as default to "unset" hover
                        color: "#558FFF",
                        borderColor: "#558FFF",
                        boxShadow: "none", // Remove any shadow on hover
                      },
                    }}
                  >
                    Approved
                  </Button>
                ) : complaint.admin_approval === rentStatus.disapproved.value ? (
                  <Button
                    variant="outlined"
                    sx={{
                      // color: getButtonColor(complaint.btn),
                      // borderColor: getButtonColor(complaint.btn),
                      color: "#00E317",
                      borderColor: "#00E317",
                      width: { xs: "42%", sm: "18%" },
                      fontSize: "10px",
                      fontWeight: "400",
                      "&:hover": {
                        color: "#00E317",
                        borderColor: "#00E317",
                        boxShadow: "none", // Remove any shadow on hover
                      },
                    }}
                  >
                    Disapproved
                  </Button>
                ) : ('-')}
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Typography>No data available</Typography>
        </Box>
      )}
    </>
  );
}

export default ComplaintsHistory;
