import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  gridItemTypography,
  notificationSettingHeading,
  notificationSettingSubHeading,
  switchStyle,
  useStyles,
} from "./NotificationStyles";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

function NoitificationSetting() {
  const classes = useStyles();
  const IOSSwitch = styled(Switch)(({ theme }) => ({
    width: 52,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(26px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#B81918",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 1.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#B81918",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const [switchStates, setSwitchStates] = useState({
    allNotifications: false,
    generalNotification: false,
    dueRentNotification: false,
  });

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setSwitchStates((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <>
      <Box>
        <Typography sx={notificationSettingHeading}>
          Notification Personalize
        </Typography>
      </Box>
      <Box className={classes.switchBox}>
        <Typography sx={notificationSettingSubHeading}>
          Mute All Notifications
        </Typography>
        {/* <Switch {...label} defaultChecked sx={switchStyle} /> */}
        <IOSSwitch
          name="allNotifications"
          checked={switchStates.allNotifications}
          onChange={handleSwitchChange}
        />
      </Box>
      <Grid container>
        <Grid item xs={12} className={classes.setingGridItem}>
          <Box className={classes.itemBox}>
            <Typography sx={gridItemTypography}>General</Typography>

            {/* <Switch {...label} defaultChecked sx={switchStyle} /> */}
            <IOSSwitch
              name="generalNotification"
              checked={switchStates.generalNotification}
              onChange={handleSwitchChange}
            />
          </Box>

          <Box className={classes.itemBox}>
            <Typography sx={gridItemTypography}>Rent Due</Typography>

            {/* <Switch {...label} defaultChecked sx={switchStyle} /> */}
            <IOSSwitch
              name="dueRentNotification"
              checked={switchStates.dueRentNotification}
              onChange={handleSwitchChange}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default NoitificationSetting;
