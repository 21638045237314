import React, { useEffect } from "react";
import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { PropertyType } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";



const FirstTable = [
    {
        id: "1",
        SerNo: "S.No 1",
        Reference: "0001",
        Date: "25-04-2024",
        property: "Property Name",
        type: "Residential",
        Category: "Civil Defense",
        provide: "Ministry Of Interior",
        startDate: "24-04-2022",
        endDate: "24-04-2023",
        Descri: "Any Description",
        Amount: "5000 AED",
        Nopayment: "06",
        IstDate: "24-04-2022",
        IstAmount: "1000 AED",
        ndDate: "24-05-2022",
        ndAmount: "1000 AED",
        rdDate: "24-06-2022",
        rdAmount: "1000 AED",
        thDate: "24-07-2022",
        thAmount: "1000 AED",
        fiveDate: "24-08-2022",
        fiveAmount: "1000 AED",
        sixDate: "24-09-2022",
        sixAmount: "1000 AED",
        Comments: "Any Comments",
    },
    {
        id: "2",
        SerNo: "S.No 1",
        Reference: "0001",
        Date: "25-04-2024",
        property: "Property Name",
        type: "Residential",
        Category: "Civil Defense",
        provide: "Ministry Of Interior",
        startDate: "24-04-2022",
        endDate: "24-04-2023",
        Descri: "Any Description",
        Amount: "5000 AED",
        Nopayment: "06",
        IstDate: "24-04-2022",
        IstAmount: "1000 AED",
        ndDate: "24-05-2022",
        ndAmount: "1000 AED",
        rdDate: "24-06-2022",
        rdAmount: "1000 AED",
        thDate: "24-07-2022",
        thAmount: "1000 AED",
        fiveDate: "24-08-2022",
        fiveAmount: "1000 AED",
        sixDate: "24-09-2022",
        sixAmount: "1000 AED",
        Comments: "Any Comments",
    },
    {
        id: "3",
        SerNo: "S.No 1",
        Reference: "0001",
        Date: "25-04-2024",
        property: "Property Name",
        type: "Residential",
        Category: "Civil Defense",
        provide: "Ministry Of Interior",
        startDate: "24-04-2022",
        endDate: "24-04-2023",
        Descri: "Any Description",
        Amount: "5000 AED",
        Nopayment: "06",
        IstDate: "24-04-2022",
        IstAmount: "1000 AED",
        ndDate: "24-05-2022",
        ndAmount: "1000 AED",
        rdDate: "24-06-2022",
        rdAmount: "1000 AED",
        thDate: "24-07-2022",
        thAmount: "1000 AED",
        fiveDate: "24-08-2022",
        fiveAmount: "1000 AED",
        sixDate: "24-09-2022",
        sixAmount: "1000 AED",
        Comments: "Any Comments",
    },
    {
        id: "4",
        SerNo: "S.No 1",
        Reference: "0001",
        Date: "25-04-2024",
        property: "Property Name",
        type: "Residential",
        Category: "Civil Defense",
        provide: "Ministry Of Interior",
        startDate: "24-04-2022",
        endDate: "24-04-2023",
        Descri: "Any Description",
        Amount: "5000 AED",
        Nopayment: "06",
        IstDate: "24-04-2022",
        IstAmount: "1000 AED",
        ndDate: "24-05-2022",
        ndAmount: "1000 AED",
        rdDate: "24-06-2022",
        rdAmount: "1000 AED",
        thDate: "24-07-2022",
        thAmount: "1000 AED",
        fiveDate: "24-08-2022",
        fiveAmount: "1000 AED",
        sixDate: "24-09-2022",
        sixAmount: "1000 AED",
        Comments: "Any Comments",
    },
    {
        id: "5",
        SerNo: "S.No 1",
        Reference: "0001",
        Date: "25-04-2024",
        property: "Property Name",
        type: "Residential",
        Category: "Civil Defense",
        provide: "Ministry Of Interior",
        startDate: "24-04-2022",
        endDate: "24-04-2023",
        Descri: "Any Description",
        Amount: "5000 AED",
        Nopayment: "06",
        IstDate: "24-04-2022",
        IstAmount: "1000 AED",
        ndDate: "24-05-2022",
        ndAmount: "1000 AED",
        rdDate: "24-06-2022",
        rdAmount: "1000 AED",
        thDate: "24-07-2022",
        thAmount: "1000 AED",
        fiveDate: "24-08-2022",
        fiveAmount: "1000 AED",
        sixDate: "24-09-2022",
        sixAmount: "1000 AED",
        Comments: "Any Comments",
    },
    {
        id: "6",
        SerNo: "S.No 1",
        Reference: "0001",
        Date: "25-04-2024",
        property: "Property Name",
        type: "Residential",
        Category: "Civil Defense",
        provide: "Ministry Of Interior",
        startDate: "24-04-2022",
        endDate: "24-04-2023",
        Descri: "Any Description",
        Amount: "5000 AED",
        Nopayment: "06",
        IstDate: "24-04-2022",
        IstAmount: "1000 AED",
        ndDate: "24-05-2022",
        ndAmount: "1000 AED",
        rdDate: "24-06-2022",
        rdAmount: "1000 AED",
        thDate: "24-07-2022",
        thAmount: "1000 AED",
        fiveDate: "24-08-2022",
        fiveAmount: "1000 AED",
        sixDate: "24-09-2022",
        sixAmount: "1000 AED",
        Comments: "Any Comments",
    },
    {
        id: "7",
        SerNo: "S.No 1",
        Reference: "0001",
        Date: "25-04-2024",
        property: "Property Name",
        type: "Residential",
        Category: "Civil Defense",
        provide: "Ministry Of Interior",
        startDate: "24-04-2022",
        endDate: "24-04-2023",
        Descri: "Any Description",
        Amount: "5000 AED",
        Nopayment: "06",
        IstDate: "24-04-2022",
        IstAmount: "1000 AED",
        ndDate: "24-05-2022",
        ndAmount: "1000 AED",
        rdDate: "24-06-2022",
        rdAmount: "1000 AED",
        thDate: "24-07-2022",
        thAmount: "1000 AED",
        fiveDate: "24-08-2022",
        fiveAmount: "1000 AED",
        sixDate: "24-09-2022",
        sixAmount: "1000 AED",
        Comments: "Any Comments",
    },
    {
        id: "8",
        SerNo: "S.No 1",
        Reference: "0001",
        Date: "25-04-2024",
        property: "Property Name",
        type: "Residential",
        Category: "Civil Defense",
        provide: "Ministry Of Interior",
        startDate: "24-04-2022",
        endDate: "24-04-2023",
        Descri: "Any Description",
        Amount: "5000 AED",
        Nopayment: "06",
        IstDate: "24-04-2022",
        IstAmount: "1000 AED",
        ndDate: "24-05-2022",
        ndAmount: "1000 AED",
        rdDate: "24-06-2022",
        rdAmount: "1000 AED",
        thDate: "24-07-2022",
        thAmount: "1000 AED",
        fiveDate: "24-08-2022",
        fiveAmount: "1000 AED",
        sixDate: "24-09-2022",
        sixAmount: "1000 AED",
        Comments: "Any Comments",
    },
    {
        id: "9",
        SerNo: "S.No 1",
        Reference: "0001",
        Date: "25-04-2024",
        property: "Property Name",
        type: "Residential",
        Category: "Civil Defense",
        provide: "Ministry Of Interior",
        startDate: "24-04-2022",
        endDate: "24-04-2023",
        Descri: "Any Description",
        Amount: "5000 AED",
        Nopayment: "06",
        IstDate: "24-04-2022",
        IstAmount: "1000 AED",
        ndDate: "24-05-2022",
        ndAmount: "1000 AED",
        rdDate: "24-06-2022",
        rdAmount: "1000 AED",
        thDate: "24-07-2022",
        thAmount: "1000 AED",
        fiveDate: "24-08-2022",
        fiveAmount: "1000 AED",
        sixDate: "24-09-2022",
        sixAmount: "1000 AED",
        Comments: "Any Comments",
    },
    {
        id: "10",
        SerNo: "S.No 1",
        Reference: "0001",
        Date: "25-04-2024",
        property: "Property Name",
        type: "Residential",
        Category: "Civil Defense",
        provide: "Ministry Of Interior",
        startDate: "24-04-2022",
        endDate: "24-04-2023",
        Descri: "Any Description",
        Amount: "5000 AED",
        Nopayment: "06",
        IstDate: "24-04-2022",
        IstAmount: "1000 AED",
        ndDate: "24-05-2022",
        ndAmount: "1000 AED",
        rdDate: "24-06-2022",
        rdAmount: "1000 AED",
        thDate: "24-07-2022",
        thAmount: "1000 AED",
        fiveDate: "24-08-2022",
        fiveAmount: "1000 AED",
        sixDate: "24-09-2022",
        sixAmount: "1000 AED",
        Comments: "Any Comments",
    },
    {
        id: "11",
        SerNo: "S.No 1",
        Reference: "0001",
        Date: "25-04-2024",
        property: "Property Name",
        type: "Residential",
        Category: "Civil Defense",
        provide: "Ministry Of Interior",
        startDate: "24-04-2022",
        endDate: "24-04-2023",
        Descri: "Any Description",
        Amount: "5000 AED",
        Nopayment: "06",
        IstDate: "24-04-2022",
        IstAmount: "1000 AED",
        ndDate: "24-05-2022",
        ndAmount: "1000 AED",
        rdDate: "24-06-2022",
        rdAmount: "1000 AED",
        thDate: "24-07-2022",
        thAmount: "1000 AED",
        fiveDate: "24-08-2022",
        fiveAmount: "1000 AED",
        sixDate: "24-09-2022",
        sixAmount: "1000 AED",
        Comments: "Any Comments",
    },
]


const AMCSchedule = () => {

    const [amcData, setAmcData] = useState([]);
    const [loading, setLoading] = useState(true);


    const getAMCs = async () => {
        try {
            const requestOptions = {
                headers: {
                    Authorization: `token ${localStorage.getItem('token')}`
                }
            }
            const response = await axios.get(endpoints.AmcSchedule, requestOptions)
            setAmcData(response.data.data)

        } catch {

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getAMCs()
    }, [])

    return (
        <>

            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)"
                }}>
                    AMC Schedule
                </Typography>
            </Box>
            {loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50vh"
                >
                    <Loader />
                </Box>
            ) : amcData?.length === 0 ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50vh"
                >
                    <Typography>No data available</Typography>
                </Box>
            ) : (

                <Box style={{ overflowX: 'auto' }} mt={4}>
                    <Table aria-label="simple table" sx={{ minWidth: "180rem", border: '1px solid #EFEFEF' , }}>
                        <TableHead>
                            <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Serial Number</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Reference No</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Date</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Property</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Type</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Category</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Service Provider</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Start Date</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>End Date</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Description</TableCell>
                                {/* <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Amount</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Number of Payment</TableCell> */}
                                {/* <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>1st Date</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>1st Amount</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>2nd Date</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>2nd Amount</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>3rd Date</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>3rd Amount</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>4th Date</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>4th Amount</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>5th Date</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>5th Amount</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>6th Date</TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>6th Amount</TableCell> */}
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Comments</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {amcData?.map((amc, index) => (
                                <TableRow key={amc.id}>
                                    <TableCell>{`S.No ${index + 1}`}</TableCell>
                                    <TableCell>{amc.reference_no || '-'}</TableCell>
                                    <TableCell>{amc.created_at.split("T")[0] || '-'}</TableCell>
                                    <TableCell>{amc.property?.ref_id|| '-'}</TableCell>
                                    <TableCell> {amc?.property?.type == PropertyType.RESIDENTIAL
                                        ? "Residential"
                                        : amc?.property?.type == PropertyType.COMMERCIAL
                                            ? "Comercial"
                                            : amc?.property?.type == PropertyType.COMMUNITY
                                                ? "Community"
                                                : ""}</TableCell>
                                    <TableCell>{amc.category || '-'}</TableCell>
                                    <TableCell>{amc.service_provider || '-'}</TableCell>
                                    <TableCell>{amc.start_date || '-'}</TableCell>
                                    <TableCell>{amc.end_date || '-'}</TableCell>
                                    <TableCell >{amc.description || '-'}</TableCell>
                                    {/* <TableCell >{amc.amount || '-'}</TableCell>
                                    <TableCell >{amc.number_of_payments || '-'}</TableCell> */}
                                    {/* <TableCell >{row.IstDate}</TableCell>
                                <TableCell >{row.IstAmount}</TableCell>
                                <TableCell >{row.ndDate}</TableCell>
                                <TableCell >{row.ndAmount}</TableCell>
                                <TableCell >{row.rdDate}</TableCell>
                                <TableCell >{row.rdAmount}</TableCell>
                                <TableCell >{row.thDate}</TableCell>
                                <TableCell >{row.thAmount}</TableCell>
                                <TableCell >{row.fiveDate}</TableCell>
                                <TableCell >{row.fiveAmount}</TableCell>
                                <TableCell >{row.sixDate}</TableCell>
                                <TableCell >{row.sixAmount}</TableCell> */}
                                    <TableCell >{amc.comments || '-'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>

            )}
        </>
    )
}

export default AMCSchedule;