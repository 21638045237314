import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import TenantsDetail from "./TenantsDetail";
import PropertyUnitDetail from "./PropertyUnitDetail";
import MasterSheets from "./MasterSheets";
import PendingAction from "./PendingAction";
import CoWorker from "./CoWorker";
import TenantComplain from "./TenantComplain";
import UploadDocument from "./UploadDocument";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../../apiEndpoints";




const PropertyDetail = () => {
  const location = useLocation();
  // const { image, address } = location.state;
  const { property } = location.state;
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [Tenant, setTenant] = useState(false);
  const [Unit, setUnit] = useState(false);
  const [Master, setMaster] = useState(false);
  const [Pending, setPending] = useState(false);
  const [Worker, setWorker] = useState(false);
  const [Complain, setComplain] = useState(false);
  const [document, setdocument] = useState(false);

  const getPropertyDetails = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        `${endpoints.landlordPropertyDetails}?id=${property.id}`,
        requestOptions
      );
      setPropertyDetails(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getPropertyDetails();
  }, []);

  const handletenant = () => {
    setTenant(true);
  };
  if (Tenant) {
    return (
      <TenantsDetail tenantDetails={propertyDetails} mainProperty={property} />
    );
  }
  const handleunit = () => {
    setUnit(true);
  };
  if (Unit) {
    return (
      <PropertyUnitDetail
        unitDetails={propertyDetails}
        mainProperty={property}
      />
    );
  }

  const handlemaster = () => {
    setMaster(true);
  };
  if (Master) {
    return <MasterSheets />;
  }

  const handleaction = () => {
    setPending(true);
  };
  if (Pending) {
    return (
      <PendingAction
        pendingActionsDetails={propertyDetails}
        mainProperty={property}
      />
    );
  }

  const handleworker = () => {
    setWorker(true);
  };
  if (Worker) {
    return (
      <CoWorker coWorkersDetails={propertyDetails} mainProperty={property} />
    );
  }

  const handleComplain = () => {
    setComplain(true);
  };
  if (Complain) {
    return <TenantComplain complaintsDetails={propertyDetails} />;
  }

  const handledocument = () => {
    setdocument(true);
  };
  if (document) {
    return (
      <UploadDocument
        documentsDetails={propertyDetails}
        mainProperty={property}
      />
    );

  }




  return (
    <>
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        Property Details
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12} md={6} alignItems="center">
          <Box>
            <Box sx={{ display: "flex" }}>
              <img
                src={
                  property?.attachments.length > 0
                    ? property?.attachments[0].media
                    : "No Image Available"
                }
                onError={(e) => (e.target.src = "/image/propertyicon.png")}
                // alt="this image is not show"
                width={450}
                height={250}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "35px",
              margin: "40px 0px 0px 0px",
            }}
          >
            {`${propertyDetails?.title} (${propertyDetails?.ref_id || "-"})` ||
              "Title not available"}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              margin: "10px 0px 0px 0px",
            }}
          >
            {propertyDetails?.description || "Description not available"}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              margin: "10px 0px 0px 0px",
            }}
          ></Typography>
        </Grid>
      </Grid>

      <Box style={{ margin: "25px 0px 0px 0px" }}>
        <Divider />
      </Box>

      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 700,
          color: "rgba(0, 0, 0, 1)",
          margin: "25px 0px 0px 0px",
        }}
      >
        Details
      </Typography>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Tenants
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handletenant}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Documents
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handledocument}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Units
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handleunit}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Pending Actions
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handleaction}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Tenant Compaints
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handleComplain}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Master Sheets
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handlemaster}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid> */}
      </Grid>
      <Grid container spacing={2} mt={1} mb={4}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Co-Owner
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handleworker}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </>
  );
};

export default PropertyDetail;
