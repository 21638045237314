import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';


export const useStyles = makeStyles(() => {
    const theme = useTheme();
    return {

        rootlogin: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            margin: "12% 0% 0% 0%",
            [theme.breakpoints.down("sm")]: {
                margin: "40% 2% 0% 0%"
            },
        },
        root: {
            margin: "8% 0% 0% 0%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            [theme.breakpoints.down("sm")]: {
                margin: "45% 0% 0% 2%",
            },
        },

        formcontrol: {
            margin: "5% 0% 0% 0% ",
            [theme.breakpoints.down("sm")]: {
                margin: "10% 0% 0% 27%",
            },
        },

        emailinput: {

            backgroundColor: "rgba(239, 239, 239, 1)",
            borderRadius: "6px",
            width: "100%",
            margin: "3% 0% 0% 0%",
            [theme.breakpoints.down("sm")]: {
                width: "85%"
            },

        },

        passwordinput: {

            backgroundColor: "rgba(239, 239, 239, 1)",
            borderRadius: "6px",
            margin: "2% 0% 3% 0%",
            width: "99%",
            [theme.breakpoints.down("sm")]: {
                width: "85%"
            },

        },
        bph: {
            width: "100%",
            height: "2.5em",
            [theme.breakpoints.down("sm")]: {
                width: "85%"
            },
        },

        image: {
            [theme.breakpoints.down("sm")]: {
                margin: "0% 0% 0% 15%"
            },
        },
        typogone: {
            [theme.breakpoints.down("sm")]: {
                margin: "0% 0% 0% 15%"
            },
        },
        typogtwo: {
            [theme.breakpoints.down("sm")]: {
                margin: "0% 0% 0% 15%"
            },
        },
        typogthree: {
            [theme.breakpoints.down("sm")]: {
                margin: "0% 0% 0% 15%"
            },
        }

    }
});
