import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';




export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    mianroot: {
      border: "1px solid rgba(239, 239, 239, 1)",
      borderRadius: "15px",
      margin: "30px 0px 0px 0px",
      height: "100%",
      padding: "0px 20px 0px 20px"
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: "30px auto",
      [theme.breakpoints.down("sm")]: {
        display: "column"
      }

    },

    //Add Popup style

    Poproot: {
      width: "420px",
      height: "250px",
      transform: 'translate(-70%, -50%)',
      border: "1px solid rgba(154, 25, 21, 1)",
      borderRadius: "30px",
      margin: "0px 0px 40px 220px",
      [theme.breakpoints.down("sm")]: {
        width: "350px",
        transform: 'translate(-50%, -50%)',
        margin: "0px 0px 0px 0px"
      }
    },
    img: {
      width: "60px",
      margin: "35px 0px 0px 150px",
      [theme.breakpoints.down("sm")]: {
        margin: "30px 0px 5px 125px",
      }
    },
    heading: {

      fontFamily: "Outfit",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "15px",
      color: "rgba(0, 0, 0, 1)",
      margin: "0px 0px 0px 90px",
      [theme.breakpoints.down("sm")]: {
        margin: "0px 0px 0px 30px",
      }

    },
    btns: {
      margin: "15px 0px 0px 88px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px 0px 0px 60px",
      }
    },
    button: {
      border: "1px solid rgba(154, 25, 21, 1)",
      color: "rgba(154, 25, 21, 1)",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 500,
      textTransform: "none",
      width: "170px",
      height: "40px",
      margin: "20px 0px 50px 15px"
    }

  }

});


export const newIncomeTextfield = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    // height: "10.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },

  "& .MuiOutlinedInput-input[type='date']": {
    color: "#73767960", // Change the color of the input text for date type
  },
  "& .MuiSvgIcon-root": {
    color: "#737679", // Change the color of the calendar icon
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679", // Change placeholder color here
  },
};