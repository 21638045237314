import { Box, Typography, Grid, Avatar } from "@mui/material";
import React from "react";
import { useStyles } from "./PropertiesStyles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

function PaidInvoice() {
  const classes = useStyles();

  return (
    <>
      <Box marginBottom={"2rem"}>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "24px",
            color: "#000000",
          }}
        >
          Paid Invoice
        </Typography>
      </Box>
      <Grid
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row", md: "row" }}
        gap={"2rem"}
        marginBottom={"3rem"}
      >
        <Box className={classes.propertyViewBox}>
          <Box className={classes.imgBox}>
            <img
              src="/image/proimage1.jpg"
              className={classes.propertyViewImg}
            />
          </Box>
          <Box className={classes.locationBox}>
            <LocationOnIcon sx={{ color: "#B81918" }} />
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#B81918",
              }}
            >
              Appartment 305
            </Typography>
          </Box>
        </Box>
        <Box className={classes.appartmentDetail}>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "24px",
              color: "#B81918",
            }}
          >
            Apartment 305
          </Typography>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "40px",
              color: "#000000",
            }}
          >
            Full Address:{" "}
            <span style={{ fontWeight: "400" }}>305 block C, Abu Dhabi</span>
          </Typography>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "20px",
              color: "#000000",
            }}
          >
            Current Tenant Details:
          </Typography>
          <Avatar
            src="/image/profile.jpg"
            alt="img"
            className={classes.tenantImg}
            sx={{ width: "6rem", height: "6rem", color: "#ADADAD" }}
          />
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#000000",
            }}
          >
            Michael Rayan<br></br>
            +1 234 567 890<br></br>
            michaelrayan@capstone.uae.com
          </Typography>
        </Box>
      </Grid>
      <Box>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "30.24px",
            color: "#000000",
          }}
        >
          Documents
        </Typography>
      </Box>
      <Box
        className={classes.documntBox}
        width={{
          xs: "90%",
          sm: "45%",
          md: "35%",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "25px",
            color: "#000000",
          }}
        >
          Details
        </Typography>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "25px",
            color: "#000000",
          }}
        >
          Date
        </Typography>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "25px",
            color: "#000000",
          }}
        >
          Action
        </Typography>
      </Box>
      <Box
        className={classes.documntDownloadBox}
        width={{
          xs: "90%",
          sm: "45%",
          md: "35%",
        }}
      >
        <Typography>PDCs.pdf</Typography>
        <Typography>5/8/2024</Typography>
        <SystemUpdateAltIcon sx={{ color: "#B9100F" }} />
      </Box>
    </>
  );
}

export default PaidInvoice;
