import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    mianroot: {
      border: "1px solid rgba(239, 239, 239, 1)",
      borderRadius: "15px",
      margin: "30px 0px 0px 0px",
      height: "100%",
      padding: "0px 20px 0px 20px",
    },
    root: {
      display: "flex",
      alignItems: "center",
      margin: "30px auto",
      [theme.breakpoints.down("sm")]: {
        display: "column",
      },
    },

    //Add Popup style

    Poproot: {
      width: "420px",
      height: "250px",
      transform: "translate(-70%, -50%)",
      border: "1px solid rgba(154, 25, 21, 1)",
      borderRadius: "30px",
      margin: "0px 0px 40px 220px",
      [theme.breakpoints.down("sm")]: {
        width: "350px",
        transform: "translate(-50%, -50%)",
        margin: "0px 0px 0px 0px",
      },
    },
    img: {
      width: "60px",
      margin: "35px 0px 0px 150px",
      [theme.breakpoints.down("sm")]: {
        margin: "30px 0px 5px 125px",
      },
    },
    heading: {
      fontFamily: "Outfit",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "15px",
      color: "rgba(0, 0, 0, 1)",
      margin: "0px 0px 0px 90px",
      [theme.breakpoints.down("sm")]: {
        margin: "0px 0px 0px 30px",
      },
    },
    btns: {
      margin: "15px 0px 0px 88px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px 0px 0px 60px",
      },
    },
    graph: {
      backgroundColor: "rgb(255,255,255)",
      border: "1px solid rgb(235,235,235)",
      borderRadius: "5.8px",
      boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.1)",
      margin: "25px 0px 0px 0px",
      padding: "0px 22px 22px 22px",
    },
    our: {
      padding: "10px 0px 10px 20px",
    },

    // View Detail Style

    Input: {
      backgroundColor: "rgba(241, 10, 10, 0.03)",
    },

    propertyViewBox: {
      width: "22rem",
      height: "21rem",
      boxShadow: "5px 5px 10px 0px rgba(185, 16, 15, 0.03)",
      backgroundColor: "#FFFFFFFF",
      display: "flex",
      flexDirection: "column",
      // alignItems: "center",
      borderRadius: "1rem",
    },
    propertyViewImg: {
      width: "20rem",
      height: "18rem",
      objectFit: "contain",
    },
    imgBox: {
      display: "flex",
      justifyContent: "center",
    },

    appartmentBox: {
      width: "22rem",
      height: "22rem",
      border: "1px solid #B81918",
      // marginLeft: "2rem",
      borderRadius: "0.625rem",
      padding: "1rem",
    },
    rentBox: {
      display: "flex",
      alignItems: "center",
      marginTop: "1rem",
      width: "20rem",
      height: "3rem",
      borderRadius: "6px",
      border: "1.5px solid #F10A0A26",
      padding: "1rem",
    },
    rentBoxICon: {
      color: "#B81918",
      marginRight: "1rem",
    },

    actionBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "2rem",
      width: { xs: "22rem", sm: "22rem", md: "30rem" },
      height: "22rem",
      border: "1px solid #EFEFEF",
      borderRadius: "1rem",
      paddingBottom: "1rem",
      paddingTop: "1rem",
    },

    viewBox: {
      display: "flex",
      alignItems: "start",
      justifyContent: "space-between",
      width: "95%",
      height: "3rem",
      borderBottom: "1px solid #F10A0A26",
    },
    reportsBox: {
      backgroundColor: "#F10A0A08",
      height: "3.5rem",
      display: "flex",
      alignItems: "center",
      paddingLeft: "10px",
      paddingRight: "10px",
      justifyContent: "space-between",
      borderRadius: "6px",
    },
    pdfBox: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
});

export const newIncomeTextfield = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    // height: "10.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px",
    // },
    "& fieldset": {
      borderColor: "#EFEFEF",
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF",
    },
  },

  "& .MuiOutlinedInput-input[type='date']": {
    color: "#73767960",
  },
  "& .MuiSvgIcon-root": {
    color: "#737679",
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679",
  },
};

export const tableCell = {
  cursor: "pointer",
};
