import React, { useState } from "react";
import { Box, Button, Modal, TextField } from "@mui/material";
import { useStyles } from "./PopupStyle";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";

const RenewPopup = ({ onClose, id, renewalNotice }) => {
  const classes = useStyles();
  const [rentalValue, setRentalValue] = useState(null);
  const handleUpdateNotice = async (e) => {
    e.preventDefault();
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const payload = { new_amount: rentalValue };
      const response = await axios.patch(
        `${endpoints.renewalNotice}?notice_id=${id}`,
        payload,
        requestOptions
      );
     

      renewalNotice();
    } catch (error) {
      console.log(error.response);
    }
    onClose();
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="popup-modal-title"
      aria-describedby="popup-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        className={classes.Poproot}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h6 style={{}} className={classes.heading}>
          Enter New Rental Amount
        </h6>
        <form onSubmit={handleUpdateNotice}>
          <Box className={classes.input}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter rental amount"
              value={rentalValue}
              onChange={(e) => {
                setRentalValue(e.target.value);
              }}
              required
              // style={{width:"300px"}}
              size="small"
            />
          </Box>
          <Box className={classes.btns}>
            <Button
              variant="outlined"
              style={{
                border: "1px solid rgba(154, 25, 21, 1)",
                color: "rgba(154, 25, 21, 1)",
                textTransform: "none",
                width: "90px",
                height: "30px",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                textTransform: "none",
                margin: "0px 0px 0px 10px",
                width: "90px",
                height: "30px",
              }}
              //   onClick={handleUpdateNotice}
              type="submit"
            >
              Confirm
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default RenewPopup;
