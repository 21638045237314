import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, Divider, Button } from "@mui/material";
import { useStyles } from "./MaintenanceStyle";
import MaintenanceReport from './MaintenanceReport';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { statusMap } from '../../../enums/UserTypes';
import { Loader } from "../../../utils/Loader";


const MaintenanceData = [
    {
        id: "1",
        Dates: "11-Apr-2024",
        name: "Maintenance Request Name",
        button: "New Request"
    },
    {
        id: "2",
        Dates: "11-March-2024",
        name: "Maintenance Request Name",
        button: "Pending"

    },
    {
        id: "3",
        Dates: "12-Dec-2023",
        name: "Maintenance Request Name",
        button: "Pending"
    },
    {
        id: "4",
        Dates: "12-Dec-2023",
        name: "Maintenance Request Name",
        button: "Resolved"
    },
    {
        id: "5",
        Dates: "12-Dec-2023",
        name: "Maintenance Request Name",
        button: "Resolved"
    },
    {
        id: "6",
        Dates: "12-Dec-2023",
        name: "Maintenance Request Name",
        button: "Resolved"
    }
]




const Maintenance = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [maintenanceData, setmaintenanceData] = useState([]);
    const [loading, setLoading] = useState(true);


    const getMaintenance = async () => {

        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.landlordmaintenance}`, {
                headers: { Authorization: `Token ${token}` }
            });
            const data = response.data.data;
            setmaintenanceData(data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMaintenance();
    }, [])



    const handleClick = (request) => {
        navigate('/landlord/maintenancereport', {
            state: {
                id: request.id,
                ...request
            }
        });
    };

    const getmaintenance_status = (maintenance_status) => {
        return statusMap[maintenance_status] || "Unknown Status";
    }


    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "#000000"
                }}>
                    Maintenance Reports
                </Typography>
            </Box>
            {loading ? (
                <Box
                    display={"flex"}
                    height={"100vh"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Loader />
                </Box>
            ) : maintenanceData?.length === 0 ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50vh"
                >
                    <Typography>No data available</Typography>
                </Box>
            ) : (
                <Grid container spacing={3} >
                    <Grid item xs={12} sm={12} md={12}  >
                        <Box className={classes.graph}>
                            {maintenanceData?.map((item, index) => (
                                <Box style={{ padding: "25px 20px 20px 20px" }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Outfit",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            lineHeight: "16px",
                                            margin: "0px 0px 10px 0px"
                                        }}
                                    >
                                        {item.created_at.split("T")[0]}
                                    </Typography>
                                    <Box className={classes.tab}>
                                        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                                            <Grid item xs={12} sm={8}>
                                                <Typography style={{
                                                    fontFamily: "Outfit",
                                                    fontSize: "18px",
                                                    lineHeight: "18px",
                                                    fontWeight: 500,
                                                    color: "rgba(0, 0, 0, 1)",
                                                    margin: "20px 0px 20px 20px"
                                                }}
                                                    onClick={() => handleClick(item)}
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end">
                                                <Button
                                                    style={{
                                                        fontSize: "14px",
                                                        lineHeight: "16px",
                                                        fontWeight: 500,
                                                        // textTransform: "none",
                                                        width: "140px",
                                                        height: "40px",
                                                        color: item?.maintenance_status === "1" ? "blue" :
                                                            item?.maintenance_status === "3" ? "white" :
                                                                item?.maintenance_status === "2" ? "red" : "transparent",
                                                        backgroundColor: item?.maintenance_status === "Pending" ? "" :
                                                            item?.maintenance_status === "3" ? "#00E317" :
                                                                item?.maintenance_status === "New Request" ? "transparent" : "transparent",
                                                        borderColor: item?.maintenance_status === "2" ? "red" : "",
                                                        margin: "0px 15px 0px 0px"
                                                    }}
                                                    variant={item?.maintenance_status === "1" ? "outlined" :
                                                        item?.maintenance_status === "3" ? "contained" :
                                                            item?.maintenance_status === "2" ? "outlined" : "text"}
                                                >
                                                    {getmaintenance_status(item?.maintenance_status)}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default Maintenance;