import React from "react";
import menuData from "./menuData";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Nav from "./Nav";
import Dashboard from "../Pages/Dashboard/Dashboard";
import CashFlow from "../Pages/CashFlow/CashFlow";
import SOA from "../Pages/SOA/SOA";
import MasterSheet from "../Pages/MasterSheet/MasterSheet";
import PDCs from "../Pages/PDCs/PDCs";
import Income from "../Pages/Income/Income";
import Expenses from "../Pages/Expenses/Expenses";
import Contracts from "../Pages/Contracts/Contracts";
import VacantUnits from "../Pages/VacantUnits/VacantUnits";
import RentReimburstment from "../Pages/RentReimburstment/RentReimburstment";
import TenantDetails from "../Pages/TenantDetails/TenantDetails";
import Chat from "../Pages/Chat/Chat";
import Notification from "../Pages/Notifications/Notification";
import ViewSOA from "../Pages/SOA/ViewSOA";
import CreateNew from "../Pages/Income/CreateNew";
import CreateExpense from "../Pages/Expenses/CreateExpense";
import ViewForm from "../Pages/RentReimburstment/ViewForm";
import EditTenant from "../Pages/TenantDetails/EditTenant";
import EditPDC from "../Pages/PDCs/EditPDC";
import AmcSchedule from "../Pages/AMC/AmcSchedule";
import InfoProfile from "./InfoProfile";
import ChatBox from "../Pages/Chat/ChatBox";




const useStyles = makeStyles(() => {
  const theme = useTheme();
  console.log(theme);

  return {
    root: {
      width: "100%",
      minHeight: "100vh",
      height: "auto",
      boxSizing: "border-box",
      padding: "0px 24px 0px 300px",
      [theme.breakpoints.down("lg")]: {
        padding: "0px 24px 24px 24px",
      },
    },
  };
});
const FinancePortal = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Nav menuData={menuData} />
      <Box className={classes.root}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/cashflow" element={<CashFlow />} />
          <Route path="/soa" element={<SOA />} />
          <Route path="/mastersheet" element={<MasterSheet />} />
          <Route path="/pdcs" element={<PDCs />} />
          <Route path="/income" element={<Income />} />
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/contracts" element={<Contracts />} />
          <Route path="/vacant-units" element={<VacantUnits />} />
          <Route path="/rent-reimburstment" element={<RentReimburstment />} />
          <Route path="/tenant-details" element={<TenantDetails />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/soa/view-soa" element={<ViewSOA />} />
          <Route path="/income/create-income" element={<CreateNew />} />
          <Route path="/expenses/create-expense" element={<CreateExpense />} />
          <Route path="/rent-reimburstment/view-form" element={<ViewForm />} />
          <Route path="/edit-tenant" element={<EditTenant />} />
          <Route path="/edit-pdc" element={<EditPDC />} />
          <Route path="amc-schedule" element={<AmcSchedule />} />
          <Route path="/infoProfile" element={<InfoProfile />} />
          <Route path="/message" element={<ChatBox />} />


          
        </Routes>
      </Box>
    </Fragment>
  );
};

export default FinancePortal;
