import React, { useCallback, useEffect, useRef } from "react";
import { Grid, Box, Typography, Hidden } from "@mui/material";
import { TextField, Button, Link, Container } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useStyles } from "./LoginStyle";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { UserTypes } from "../enums/UserTypes";
import { endpoints } from "../apiEndpoints";
import toast from "react-hot-toast";
import ForgetPassword from "./ForgetPassword";
import { auth, signInWithEmailAndPassword } from '../firebase';


const Sign = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);



  const handleClickShowPassword = () => {setShowPassword((show) => !show)
  };
  
  
 
  const [login, setlogin] = useState({
    email: "",
    password: "",
  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');



  const handleChange = (e) => {
    const { name, value } = e.target;
    setlogin((preData) => ({
      ...preData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();





  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(endpoints.LoginUsers, {
        email: email,
        password: password,
      });

      if (response.status === 200) {
        const usertype = response.data.data.user_type;
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("usertype", JSON.stringify(response.data.data.user_type));
        localStorage.setItem("loggedInUser", response.data.data.first_name);
        localStorage.setItem("id", response.data.data.id);

        
        switch (usertype) {
          case UserTypes.SuperAdmin:
            console.log("Navigating to /superadmin");
            navigate("/admin/dashboard");
            break;
          case UserTypes.admin:
            console.log("Navigating to /Admin");
            navigate("/admin/dashboard");
            break;
          case UserTypes.landlord:
            console.log("Navigating to /landlord");
            navigate("/landlord/dashboard");
            break;
          case UserTypes.tenant:
            console.log("Navigating to /tenant");
            navigate("/tenant/dashboard");
            break;
          case UserTypes.finance:
            console.log("Navigating to /finance");
            navigate("/finance/dashboard");
            break;
          default:
            console.log("Unknown user type:", usertype);
            toast.error("Unauthorized access or unknown user type.");
        }
        toast.success(response.data.message);
        handleLogin();
      } else {
        toast.error("Login failed with status: " + response.message);
        console.log("Login failed with status: ", response.status);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };


  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);

    } catch (error) {
     
    }
  };



  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  return (
    <>
      <Grid container style={{ margin: "0px" }}>
        <Grid item xs={12} sm={6} md={6}>
          <Box className={classes.root}>
            <Box className={classes.image}>
              <img
                src="/image/logo.png"
                alt="logo pic"
                style={{ width: "100px", height: "80px" }}
              />
            </Box>
            <Box className={classes.typogone}>
              <Typography
                style={{
                  font: "Raleway",
                  fontSize: "15px",
                  lineHeight: "23.48px",
                  fontWeight: 400,
                  color: "rgba(77, 77, 77, 1)",
                  margin: "5px 0px 0px 13px",
                }}
              >
                Welcome to
              </Typography>
            </Box>
            <Box className={classes.typogtwo}>
              <Typography
                style={{
                  font: "Raleway",
                  fontSize: "18px",
                  lineHeight: "48px",
                  fontWeight: 500,
                  color: "rgba(6, 6, 6, 1)",
                }}
              >
                Capstone PMS
              </Typography>
            </Box>
            <Box className={classes.typogthree}>
              <Typography
                style={{
                  font: "Raleway",
                  fontSize: "16px",
                  lineHeight: "30px",
                  fontWeight: 400,
                  color: "rgba(115, 118, 121, 1)",
                }}
              >
                Please Sign in to your account.
              </Typography>
            </Box>
            <Box className={classes.formcontrol}>
              <Container component="main" maxWidth="xs">
                <form onSubmit={handleSubmit}>
                  <Typography
                    style={{
                      font: "Raleway",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "18.78px",
                      color: "rgba(115, 118, 121, 1)",
                    }}
                  >
                    Email
                  </Typography>
                  <TextField
                    className={classes.emailinput}
                    margin="normal"
                    required
                    id="email"
                    placeholder="Admin"
                    autoComplete="email"
                    size="small"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}

                  />
                  <FormControl sx={{ width: "50ch" }} variant="outlined">
                    <Typography
                      style={{
                        font: "Raleway",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "18.78px",
                        color: "rgba(115, 118, 121, 1)",
                        margin: "4% 0% 0% 0%",
                      }}
                    >
                      Password
                    </Typography>
                    <OutlinedInput
                      className={classes.passwordinput}
                      size="small"
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="*******"

                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={(event) => event.target.select()}
                    />
                  </FormControl>
                  <Grid container>
                    <Grid item xs>
                      <Link
                        component={RouterLink}
                        to="/forget-password"
                        variant="body2"
                        style={{
                          color: "rgba(184, 25, 24, 1)",
                          font: "Raleway",
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "18.78px",
                          textDecoration:'none'
                        }}
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                  <Button
                    className={classes.bph}
                    type="submit"
                    variant="contained"
                    style={{
                      margin: "3% 0% 0% 0%",
                      fontSize: "15px",
                      fontWeight: 500,
                      lineHeight: "23.48px",
                      font: "Raleway",
                      borderRadius: "6px",
                      height: "43px",
                      backgroundColor: "rgba(184, 25, 24, 1)",
                      textTransform: "none",
                    }}
                  >
                    Sign In
                  </Button>


                </form>
              </Container>
            </Box>
          </Box>
        </Grid>

        <Hidden smDown>
          <Grid
            item
            lg={6}
            xl={6}
            md={6}
            style={{
              padding: "0px",
              margin: "0px",
              backgroundImage: 'url("/image/login.jpg")',
              backgroundSize: "cover",
              backgroundPosition: "center",
              // height: '100%',  // Adjust this to '100vh' or '100%' depending on your layout needs
              minHeight: "100vh",
            }}
          ></Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default Sign;
