import React from "react";
import { Box, Typography, Divider, Grid, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useLocation } from "react-router-dom";

const Data = [
  {
    id: 1,
    image: "/image/pdfimage.png",
    label: "Companydetails.pdf",
  },
  {
    id: 2,
    image: "/image/pdfimage.png",
    label: "Companydetails.pdf",
  },
  {
    id: 3,
    image: "/image/pdfimage.png",
    label: "Companydetails.pdf",
  },
  {
    id: 4,
    image: "/image/pdfimage.png",
    label: "Companydetails.pdf",
  },
];

const PropertyProfile = () => {
  const location = useLocation();
  const { profile } = location.state;

  return (
    <>
      <Box style={{ display: "flex" }}>
        <Avatar
          alt="Remy Sharp"
          src={"/image/userAvatar.jpg"}
          sx={{ width: "130px", height: "130px" }}
        />
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 700,
            color: "rgba(0, 0, 0, 1)",
            margin: "40px 0px 0px 20px",
          }}
        >
          {profile?.primary_landlord?.first_name || ""}
        </Typography>
      </Box>

      <Box>
        <Divider style={{ margin: "50px 0px 30px 0px" }} />
      </Box>

      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 600,
            color: "rgba(0, 0, 0, 1)",
            margin: "0px 0px 20px 0px",
          }}
        >
          Personal Information
        </Typography>
      </Box>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              margin: "0px 0px 15px 0px",
            }}
          >
            First Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            // placeholder="John"
            size="small"
            value={profile.primary_landlord.first_name || ""}
            sx={{ backgroundColor: "rgba(239, 239, 239, 1)" }}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Last Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            // placeholder="Smith"
            size="small"
            value={profile.primary_landlord.last_name || ""}
            sx={{ backgroundColor: "rgba(239, 239, 239, 1)" }}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Email
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            // placeholder="johnsmith@gmail.com"
            size="small"
            value={profile.primary_landlord.email || ""}
            sx={{ backgroundColor: "rgba(239, 239, 239, 1)" }}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Phone Number
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            // placeholder="012 123 1234"
            size="small"
            value={profile.primary_landlord.phone || ""}
            sx={{ backgroundColor: "rgba(239, 239, 239, 1)" }}
            disabled
          />
        </Grid>
      </Grid>

      <Box>
        <Divider style={{ margin: "50px 0px 30px 0px" }} />
      </Box>

      {/* <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 600,
            color: "rgba(0, 0, 0, 1)",
            margin: "30px 0px 20px 0px",
          }}
        >
          Documents & Uploads
        </Typography>
      </Box> */}
      {/* {Data.map((item) => {
        return (
          <Box style={{ display: "flex", margin: "30px 0px 0px 0px" }}>
            <img src={item.image} />
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "24px",
                margin: "20px 0px 0px 20px",
              }}
            >
              {item.label}
            </Typography>
          </Box>
        );
      })} */}
    </>
  );
};

export default PropertyProfile;
