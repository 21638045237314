import React, { useState, useEffect } from "react";
import { 
  Box, 
  Typography, 
  Grid, 
  TextField, 
  Button, 
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";
import { 
  GoogleMap, 
  LoadScript, 
  Marker 
} from "@react-google-maps/api";
import { useSelector, useDispatch } from 'react-redux';
import { updateLocationData } from '../../../../Redux/AdminReducer/formsSlice';
import { newIncomeTextfield } from './PropertieStyle';
import AddFeatures from "./AddFeatures";
import AddVirtualTour from './AddVirtualTour';


const COUNTRY_CODES = [
  { code: 'AE', name: 'United Arab Emirates', dialCode: '+971' },
  { code: 'SA', name: 'Saudi Arabia', dialCode: '+966' },
  { code: 'QA', name: 'Qatar', dialCode: '+974' },
  { code: 'BH', name: 'Bahrain', dialCode: '+973' },
  { code: 'KW', name: 'Kuwait', dialCode: '+965' },
  { code: 'OM', name: 'Oman', dialCode: '+968' },
  { code: 'PK', name: 'Pakistan', dialCode: '+92' },
  // Add more countries as needed
];

const AddLocation = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [backCom, setBackCom] = useState(false);
    const locationData = useSelector(state => state.form.locationData);
    const [localData, setLocalData] = useState(locationData);
    const [geocodeError, setGeocodeError] = useState(null);
    const [countryCode, setCountryCode] = useState('AE'); // UAE as default
    
    // Google Maps State
    const [mapCenter, setMapCenter] = useState({ 
      lat: 25.2744, // Default coordinates (you can change these)
      lng: 55.2962 
    });
    const [marker, setMarker] = useState(null);

    const mapContainerStyle = {
        width: '100%',
        height: '400px',
        borderRadius: '8px'
    };

    useEffect(() => {
        if (localData.latitude && localData.longitude) {
            const lat = parseFloat(localData.latitude);
            const lng = parseFloat(localData.longitude);
            setMapCenter({ lat, lng });
            setMarker({ lat, lng });
        }
    }, [localData.latitude, localData.longitude]);

    const geocodeLocation = async () => {
        if (!localData.address || !localData.city || !localData.country) return;

        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    `${localData.address}, ${localData.city}, ${localData.country}`
                )}&key=AIzaSyDrNXs4ZCTaKycbB6n9eUMCng9YUhQrNWs`
            );
            const data = await response.json();

            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                setMapCenter({ lat, lng });
                setMarker({ lat, lng });
                setLocalData(prev => ({
                    ...prev,
                    latitude: lat.toString(),
                    longitude: lng.toString()
                }));
            } else {
                setGeocodeError("Location not found. Please check the address.");
            }
        } catch (error) {
            setGeocodeError("Error finding location. Please try again.");
            console.error("Geocoding error:", error);
        }
    };

    useEffect(() => {
        geocodeLocation();
    }, [localData.address, localData.city, localData.country]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLocalData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleMapClick = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMarker({ lat, lng });
        setMapCenter({ lat, lng });
        setLocalData(prev => ({
            ...prev,
            latitude: lat.toString(),
            longitude: lng.toString()
        }));
    };

    const handleBack = () => {
        dispatch(updateLocationData(localData));
        setBackCom(true);
    };

    const handleNext = () => {
        dispatch(updateLocationData(localData));
        setOpen(true);
    };

    const handleCountryCodeChange = (event) => {
        const selectedCode = event.target.value;
        setCountryCode(selectedCode);
        
        // Find the country name from the selected code
        const selectedCountry = COUNTRY_CODES.find(country => country.code === selectedCode);
        if (selectedCountry) {
            setLocalData(prev => ({
                ...prev,
                country: selectedCountry.name
            }));
        }
    };

    if (backCom) return <AddFeatures />;
    if (open) return <AddVirtualTour />;

    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Add Property
                </Typography>
            </Box>

            {/* Form Fields */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: 2 }}>Address</Typography>
                    <TextField 
                        fullWidth 
                        name="address"
                        value={localData.address || ''}
                        onChange={handleInputChange}
                        placeholder="Enter Address"
                        sx={newIncomeTextfield}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: 2 }}>Country</Typography>
                    <FormControl fullWidth>
                        <InputLabel id="country-code-label">Country Code</InputLabel>
                        <Select
                            labelId="country-code-label"
                            id="country-code"
                            value={countryCode}
                            label="Country Code"
                            onChange={handleCountryCodeChange}
                        >
                            {COUNTRY_CODES.map(country => (
                                <MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: 2 }}>City</Typography>
                    <TextField 
                        fullWidth 
                        name="city"
                        value={localData.city || ''}
                        onChange={handleInputChange}
                        placeholder="Enter City"
                        sx={newIncomeTextfield}
                    />
                </Grid>

                {/* Map Section */}
                <Grid item xs={12}>
                    <Typography sx={{ my: 2 }}>Location on Map</Typography>
                    <LoadScript googleMapsApiKey="AIzaSyDrNXs4ZCTaKycbB6n9eUMCng9YUhQrNWs">
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={mapCenter}
                            zoom={13}
                            onClick={handleMapClick}
                        >
                            {marker && <Marker position={marker} />}
                        </GoogleMap>
                    </LoadScript>
                </Grid>

                {/* Coordinates Fields */}
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: 2 }}>Latitude</Typography>
                    <TextField 
                        fullWidth 
                        name="latitude"
                        value={localData.latitude || ''}
                        onChange={handleInputChange}
                        placeholder="Latitude"
                        sx={newIncomeTextfield}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: 2 }}>Longitude</Typography>
                    <TextField 
                        fullWidth 
                        name="longitude"
                        value={localData.longitude || ''}
                        onChange={handleInputChange}
                        placeholder="Longitude"
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>

            {/* Navigation Buttons */}
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                mt: 4 
            }}>
                <Button 
                    variant="outlined" 
                    onClick={handleBack}
                    sx={{
                        border: "1px solid rgba(154, 25, 21, 1)",
                        color: "rgba(154, 25, 21, 1)",
                    }}
                >
                    Back
                </Button>
                <Button 
                    variant="contained" 
                    onClick={handleNext}
                    sx={{
                        bgcolor: "rgba(184, 25, 24, 1)",
                    }}
                >
                    Next
                </Button>
            </Box>

            {/* Error Snackbar */}
            <Snackbar 
                open={!!geocodeError} 
                autoHideDuration={6000} 
                onClose={() => setGeocodeError(null)}
            >
                <Alert 
                    onClose={() => setGeocodeError(null)} 
                    severity="error"
                >
                    {geocodeError}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AddLocation;



