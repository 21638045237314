import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Grid, TextField, Button, OutlinedInput, InputAdornment } from "@mui/material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useStyles } from "../../Login/LoginStyle";
import toast from "react-hot-toast";
import axios from "axios";
import { endpoints } from "../../apiEndpoints";
import { newIncomeTextfield, } from "./ProfileStyle";
import ClearIcon from '@mui/icons-material/Clear';
import { Divider } from "@mui/material";
import Switch from '@mui/material/Switch';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';


const SecondTable = [
    {
        id: "0",
        name: "Document Name 1",
        status: "Received"
    },
    {
        id: "1",
        name: "Document Name 1",
        status: "Not-Received"
    },
    {
        id: "2",
        name: "Document Name 1",
        status: "Received"
    },
    {
        id: "3",
        name: "Document Name 1",
        status: "Not-Received"
    },
]


const Data = [
    {
        id: 1,
        image: "/image/pdfimage.png",
        label: "Companydetails.pdf",

    },
    {
        id: 2,
        image: "/image/pdfimage.png",
        label: "Companydetails.pdf",

    },
    {
        id: 3,
        image: "/image/pdfimage.png",
        label: "Companydetails.pdf",

    },
    {
        id: 4,
        image: "/image/pdfimage.png",
        label: "Companydetails.pdf",

    },
]


const InfoProfile = () => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [editable, setEditable] = useState(false);
    const [showUpdatePassword, setShowUpdatePassword] = useState(false);
    const [userData, setUserData] = useState({});
    const [formData, setFormData] = useState({});
    const [originalUserData, setOriginalUserData] = useState({});

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleEditClick = () => {
        setEditable(true);
        setShowUpdatePassword(!showUpdatePassword);
        setFormData(userData);
        setOriginalUserData(userData);
    };

    const handleCloseClick = () => {
        setEditable(false);
        setShowUpdatePassword(false);
    };


    const GetUserData = async () => {
        try {
            const token = localStorage.getItem('token');
            const id = localStorage.getItem("id");
            const response = await axios.get(`${endpoints.getUserById}?id=${id}`, {
                headers: { Authorization: `Token ${token}` }
            });
            const result = response.data.data;
            setUserData(result);
            // toast.success(response.data.message);
        } catch (error) {
            console.log("Error fetching user data", error);
            // toast.error(error.response.data.message);
        }
    };

    useEffect(() => {
        GetUserData();
    }, []);

    const handleUpdateUserData = async () => {
        try {
            const token = localStorage.getItem('token');
            const id = localStorage.getItem("id");
            const updatedFields = {};
            for (const key in formData) {
                if (formData[key] !== originalUserData[key]) {
                    updatedFields[key] = formData[key];
                }
            }
            const response = await axios.patch(`${endpoints.getUserById}?id=${id}`, updatedFields, {
                headers: { Authorization: `Token ${token}` }
            });
            toast.success(response.data.message);
            setEditable(false);
            setShowUpdatePassword(false);
            setUserData(prevUserData => ({
                ...prevUserData,
                ...updatedFields
            }));
        } catch (error) {
            console.error("Error updating user data", error);
            toast.error(error.response.data.message);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };



    const documentStatus = Object.entries(userData?.document_status || {}).map(
        ([key, value]) => ({
          id: key,
          name: key.replace(/AMC-/, ""),
          status: value ? "Received" : "Not Received",
        })
      );
    



    return (
        <>
            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', margin: "0px 0px 30px 0px" }}>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)"
                }}>
                    Personal Information
                </Typography>
                {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        variant="contained"
                        onClick={handleEditClick}
                    >
                        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)"  }} />
                    </IconButton>
                </Box> */}

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {editable ? (
                        <IconButton onClick={handleCloseClick}> {/* CloseIcon to exit editing mode */}
                            <ClearIcon style={{ color: "rgba(184, 25, 24, 1)", fontSize: "40px" }} />
                        </IconButton>
                    ) : (
                        <IconButton variant="contained" onClick={handleEditClick}>
                            <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)", fontSize: "40px" }} />
                        </IconButton>
                    )}
                </Box>
            </Box>


            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >First Name</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Admin'
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                        sx={newIncomeTextfield}
                        name="first_name"
                        value={editable ? formData.first_name || '' : userData.first_name || ''}
                        onChange={handleChange}
                        disabled={!editable}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Last Name</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Name'
                        style={{ margin: "0px 0px 25px 0px" }}
                        sx={newIncomeTextfield}
                        disabled={!editable}
                        value={editable ? formData.last_name || '' : userData.last_name || ''}
                        onChange={handleChange}
                        name="last_name"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Email</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Email'
                        style={{ margin: "0px 0px 25px 0px" }}
                        sx={newIncomeTextfield}
                        disabled={!editable}
                        value={editable ? formData.email || '' : userData.email || ''}
                        onChange={handleChange}
                        name="email"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Phone Number</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Phone Number'
                        style={{ margin: "0px 0px 25px 0px" }}
                        sx={newIncomeTextfield}
                        disabled={!editable}
                        value={editable ? formData.phone || '' : userData.phone || ''}
                        onChange={handleChange}
                        name="phone"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Emirates Id</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Emirates Id'
                        style={{ margin: "0px 0px 25px 0px" }}
                        sx={newIncomeTextfield}
                        disabled={!editable}
                        value={editable ? formData.emirates_id || '' : userData.emirates_id || ''}
                        onChange={handleChange}
                        name="emirates_id"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Passport Number</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Passport Number'
                        style={{ margin: "0px 0px 25px 0px" }}
                        sx={newIncomeTextfield}
                        disabled={!editable}
                        value={editable ? formData.passport_no || '' : userData.passport_no || ''}
                        onChange={handleChange}
                        name="passport_no"
                    />
                </Grid>
            </Grid>
            {
                showUpdatePassword && (
                    <>
                        <Grid item xs={12} md={12}>
                            <Typography
                                style={{
                                    fontFamily: "Outfit",
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    lineHeight: "18px",
                                    margin: "30px 0px 25px 0px"
                                }}
                            >
                                Update Password
                            </Typography>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography
                                    style={{
                                        fontFamily: "Outfit",
                                        fontWeight: 400,
                                        fontSize: "18px",
                                        lineHeight: "18px",
                                        margin: "0px 0px 15px 0px"
                                    }}>
                                    Current Password
                                </Typography>
                                <OutlinedInput
                                    sx={newIncomeTextfield}
                                    style={{ margin: "0px 0px 25px 0px" }}
                                    name="current_password"
                                    disabled={!editable}
                                    onChange={handleChange}
                                    value={editable ? formData.current_password || '' : ''}
                                    fullWidth
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    placeholder='*******'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography
                                    style={{
                                        fontFamily: "Outfit",
                                        fontWeight: 400,
                                        fontSize: "18px",
                                        lineHeight: "18px",
                                        margin: "0px 0px 15px 0px"
                                    }}>
                                    New Password
                                </Typography>
                                <OutlinedInput
                                    sx={newIncomeTextfield}
                                    style={{ margin: "0px 0px 25px 0px" }}
                                    disabled={!editable}
                                    name="new_password"
                                    onChange={handleChange}
                                    value={editable ? formData.new_password || '' : ''}
                                    fullWidth
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    placeholder='*******'
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', margin: '20px 0 50px 0' }}>
                            <Button
                                style={{
                                    backgroundColor: "rgba(184, 25, 24, 1)",
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    fontWeight: 500,
                                    textTransform: "none",
                                    width: "130px",
                                    height: "40px",
                                }}
                                variant="contained"
                                onClick={handleUpdateUserData}
                            >
                                Save Change
                            </Button>
                        </Box>

                    </>
                )
            }

            {/* Account Setting */}

            {/* <Box style={{ margin: "15px 0px 25px 0px" }}>
                <Divider />
            </Box>

            <Typography style={{
                font: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 700,
                color: "rgba(0, 0, 0, 1)",
                margin: "0px 0px 0px 0px"
            }}>
                Account Settings
            </Typography>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                        <Typography style={{
                            fontFamily: "Raleway",
                            fontSize: "18px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 0px 0px"
                        }}>
                            Option 1
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Switch defaultChecked />
                        </Box>
                    </Box>
                </Grid>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                        <Typography style={{
                            fontFamily: "Raleway",
                            fontSize: "18px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 0px 0px"
                        }}>
                            Option 2
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Switch />
                        </Box>
                    </Box>
                </Grid>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                        <Typography style={{
                            fontFamily: "Raleway",
                            fontSize: "18px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 0px 0px"
                        }}>
                            Option 3
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Switch defaultChecked />
                        </Box>
                    </Box>
                </Grid>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                        <Typography style={{
                            fontFamily: "Raleway",
                            fontSize: "18px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 0px 0px"
                        }}>
                            Option 4
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Switch />
                        </Box>
                    </Box>
                </Grid>

            </Grid>

            <Box style={{ margin: "15px 0px 25px 0px" }}>
                <Divider />
            </Box>


            <Box>
                <Typography
                    style={{
                        fontFamily: "Outfit",
                        fontSize: "24px",
                        lineHeight: "28px",
                        fontWeight: 600,
                        color: "rgba(0, 0, 0, 1)",
                        margin: "30px 0px 20px 0px"
                    }}
                >
                    Documents & Uploads
                </Typography>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    {Data.map((item) => {
                        return (
                            <Box style={{ display: "flex", margin: "30px 0px 0px 0px" }}>
                                <img src={item.image} />
                                <Typography style={{
                                    fontFamily: "Raleway",
                                    fontSize: "20px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    margin: "20px 0px 0px 20px"
                                }}>
                                    {item.label}
                                </Typography>

                            </Box>
                        )

                    })}

                </Grid>

            </Grid> */}

            {/* Notification Setting */}

            {/* <Box style={{ margin: "15px 0px 25px 0px" }}>
                <Divider />
            </Box>

            <Typography style={{
                font: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 700,
                color: "rgba(0, 0, 0, 1)",
                margin: "0px 0px 0px 0px"
            }}>
                Notification Settings
            </Typography>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                        <Typography style={{
                            fontFamily: "Raleway",
                            fontSize: "18px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 0px 0px"
                        }}>
                            Notification Option 1
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Switch />
                        </Box>
                    </Box>
                </Grid>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                        <Typography style={{
                            fontFamily: "Raleway",
                            fontSize: "18px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 0px 0px"
                        }}>
                            Notification Option 2
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Switch />
                        </Box>
                    </Box>
                </Grid>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                        <Typography style={{
                            fontFamily: "Raleway",
                            fontSize: "18px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 0px 0px"
                        }}>
                            Notification Option 3
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Switch />
                        </Box>
                    </Box>
                </Grid>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                        <Typography style={{
                            fontFamily: "Raleway",
                            fontSize: "18px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 0px 0px"
                        }}>
                            Notification Option 4
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Switch />
                        </Box>
                    </Box>
                </Grid>
            </Grid> */}

            {/* Table Data */}

            <Box style={{ overflowX: 'auto' }} mt={5}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                            <TableCell style={{ padding: "10px 0px 10px 20px", borderRight: "1px solid #e0e0e0" }}>Document Name</TableCell>
                            <TableCell style={{ padding: "10px 0px 10px 20px", borderRight: "1px solid #e0e0e0" }}>Document Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentStatus.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell style={{ padding: "10px 0px 10px 20px" }}>{row.name}</TableCell>
                                <TableCell style={{ padding: "10px 0px 10px 40px", color: row.status === 'Received' ? 'rgba(0, 227, 23, 1)' : 'rgba(184, 25, 24, 1)' }}>{row.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>

        </>
    );
};

export default InfoProfile;




