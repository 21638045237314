import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {
  useStyles,
  tableHeadCell,
  tableBodyCell,
  tableBodyCellRed,
} from "../Dashboard/DashboardStyle";
import { useLocation } from "react-router-dom";
import { customTextField } from "./ClientStyle";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";

const Data = [
  {
    id: 1,
    image: "/image/bank.png",
    label: "Block C, Unit 305",
    title: "Remove",
  },
  {
    id: 2,
    image: "/image/bank.png",
    label: "Block C, Unit 305",
    title: "Remove",
  },
];

const Profiles = () => {
  const classes = useStyles();
  const location = useLocation();
  const { row, tableName } = location.state || {};
  const { label, email, number } = row || {};
  const [userDetail, setUserDetail] = useState(null);
  const [secondTable, setSecondTable] = useState([]);
  const [tenantData, setTenantData] = useState([]);

  const getUserDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        `${endpoints.getUserById}?id=${row}`,
        requestOptions
      );
      console.log("this is response: ", response);
      setUserDetail(response.data.data);
      setTenantData(response.data.data.tenant_property_association || []);
      
    } catch (error) {
     
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const documentStatus = Object.entries(userDetail?.document_status || {}).map(
    ([key, value]) => ({
      id: key,
      name: key.replace(/AMC-/, ""),
      status: value ? "Received" : "Not Received",
    })
  );

  const DeleteTenant = async (associationId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${endpoints.TenantAssociation}?association_id=${associationId}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      // Update the state to remove the deleted tenant
      setTenantData((prevData) =>
        prevData.filter((item) => item.id !== associationId)
      );
      toast.success("Tenant removed successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
            margin: "0px 0px 15px 0px",
          }}
        >
          {tableName ? `${tableName} Profile` : "Selected Profile Name"}
        </Typography>
      </Box>

      <Box style={{ display: "flex" }}>
        <Avatar
          alt="Remy Sharp"
          src="/image/userAvatar.jpg"
          sx={{ width: "130px", height: "130px" }}
        />
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 700,
            color: "rgba(0, 0, 0, 1)",
            margin: "40px 0px 0px 20px",
          }}
        >
          {label}
        </Typography>
      </Box>

      <Box>
        <Divider style={{ margin: "50px 0px 30px 0px" }} />
      </Box>

      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 600,
            color: "rgba(0, 0, 0, 1)",
            margin: "0px 0px 12px 0px",
          }}
        >
          Personal Information
        </Typography>
      </Box>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={12}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={userDetail?.first_name || ""}
            InputProps={{
              readOnly: true,
            }}
            sx={customTextField}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Last Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={userDetail?.last_name || ""}
            InputProps={{
              readOnly: true,
            }}
            sx={customTextField}
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Email
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={userDetail?.email || ""}
            InputProps={{
              readOnly: true,
            }}
            sx={customTextField}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Phone Number
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={userDetail?.phone || ""}
            InputProps={{
              readOnly: true,
            }}
            sx={customTextField}
          />
        </Grid>
      </Grid>

      {tableName == "Tenant" || tableName == "Landlord" ? (
        <>
          <Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "28px",
                fontWeight: 600,
                color: "rgba(0, 0, 0, 1)",
                margin: "30px 0px 20px 0px",
              }}
            >
              Property
            </Typography>
          </Box>

          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              {tenantData.map((item, index) => (
                <Box
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #EAECF0",
                    margin: "15px 0px 0px 0px",
                    borderRadius: "5.8px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className={classes.our}
                  >
                    <Avatar alt="Remy Sharp" src="/image/bank.png" />
                    <Typography
                      style={{
                        color: "#060606",
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        lineHeight: "22px",
                        fontWeight: 400,
                        margin: "0px 0px 0px 10px",
                        borderRadius: "10px",
                      }}
                    >
                      {`${item.property.ref_id} || ${
                        item.unit?.map((item) => item.ref_id).join(", ") ||
                        "units not available"
                      }`}
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "10px",
                      lineHeight: "10px",
                      fontWeight: 500,
                      margin: "0px 25px 0px 0px",
                      color: "rgba(184, 25, 24, 1)",
                      border: `1px solid rgba(184, 25, 24, 1)`,
                      padding: "5px 15px 5px 15px",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      DeleteTenant(item.id);
                    }}
                  >
                    Remove
                  </Typography>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {/* this */}
            </Grid>
          </Grid>

          <Box style={{ overflowX: "auto" }} mt={5}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
                >
                  <TableCell
                    style={{
                      padding: "10px 0px 10px 20px",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Document Name
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "10px 0px 10px 20px",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Document Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documentStatus.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                      {row.name}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "10px 0px 10px 40px",
                        color:
                          row.status === "Received"
                            ? "rgba(0, 227, 23, 1)"
                            : "rgba(184, 25, 24, 1)",
                      }}
                    >
                      {row.status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Profiles;
