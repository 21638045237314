import React from "react";
import { useState } from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  InfoTypography,
} from "../../../../AdminComponent/Pages/Maintenance/MaintenanceStyles";

const Data = [
  {
    id: 1,
    image: "/image/pdfimage.png",
    label: "TenancyContract.pdf",
  },
  {
    id: 2,
    image: "/image/pdfimage.png",
    label: "PropertyAgreement.pdf",
  },
];

const UnitDocuments = ({ details, unit }) => {
  const [secondSelectValue, setSecondSelectValue] = useState("");



  return (
    <>
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        Upload Documents
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12} md={6} alignItems="center">
          <Box>
            <Box sx={{ display: "flex" }}>
              <img
                src={
                  unit?.attachments && unit.attachments.length > 0
                    ? unit?.attachments[0]?.media
                    : "No Image Available"
                }
                onError={(e) => (e.target.src = "/image/propertyicon.png")}
                alt="this image is not show"
                width={450}
                height={250}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "35px",
              margin: "40px 0px 0px 0px",
            }}
          >
            {unit?.title || "Title not available"}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              margin: "10px 0px 0px 0px",
            }}
          >
            {unit?.description || "Description not available"}
          </Typography>
        </Grid>
      </Grid>

      <Box style={{ margin: "25px 0px 20px 0px" }}>
        <Divider />
      </Box>

      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Select
              size="small"
              value={secondSelectValue}
              onChange={(e) => setSecondSelectValue(e.target.value)}
              displayEmpty
              style={{
                border: "0px solid rgba(239, 239, 239, 1)",
                borderRadius: "30px",
              }}
            >
              <MenuItem value="" disabled>
                Select Type
              </MenuItem>
              <MenuItem value="option1">Option 1</MenuItem>
              <MenuItem value="option2">Option 2</MenuItem>
              <MenuItem value="option3">Option 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid> */}

      <Box style={{ margin: "50px 0px 50px 0px" }}>
        {
          Data && Data.length > 0 ? (
            Data.map((item) => {
              return (
                <Box style={{ display: "flex", margin: "30px 0px 0px 0px" }}>
                  <img src={item.image} />
                  <Typography
                    style={{
                      fontFamily: "Raleway",
                      fontSize: "20px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      margin: "20px 0px 0px 20px",
                    }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <InfoTypography>No Complaints</InfoTypography>
          )}
      </Box>
    </>
  );
};

export default UnitDocuments;
