import React from 'react';
import { TextField, Button, Grid } from '@mui/material';
import { Typography, Box, Select, MenuItem, FormControl, } from "@mui/material";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updatePropertyData } from '../../../../Redux/AdminReducer/formsSlice';
import AddMedia from './AddMedia';
import Propertie from './Property';
import { useStyles, newIncomeTextfield, } from './PropertieStyle';
import { PropertyType, PropertyStatus } from '../../../../enums/UserTypes';




const AddProperty = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [backcom, setBackcom] = useState(false);

  const [firstValue, setFirstValue] = useState('');
  const [secondValue, setSecondValue] = useState('');
  const [thirdValue, setThirdValue] = useState('');

  const propertyData = useSelector(state => state.form.propertyData);
  const [localData, setLocalData] = useState(propertyData);


  useEffect(() => {
    setLocalData(propertyData);
    if (propertyData.type) {
      setFirstValue(propertyData.type);
    }
    if (propertyData.subtype) {
      setSecondValue(propertyData.subtype);
    }
    if (propertyData.detailedType) {
      setThirdValue(propertyData.detailedType);
    }
  }, [propertyData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedData = { ...localData, [name]: value };
    setLocalData(updatedData);
    dispatch(updatePropertyData(updatedData));
  };

  const handleFirstChange = (event) => {
    const value = event.target.value;
    setFirstValue(value);
    setSecondValue(''); 
    setThirdValue('');
    const updatedData = { ...localData, type: value, subtype: '', detailedType: '' }; 
    setLocalData(updatedData);
    dispatch(updatePropertyData(updatedData));
  };

  const handleSecondChange = (event) => {
    const value = event.target.value;
    setSecondValue(value);
    setThirdValue(''); // Reset third value when subtype changes
    const updatedData = { ...localData, subtype: value, detailedType: '' };
    setLocalData(updatedData);
    dispatch(updatePropertyData(updatedData));
  };

  const handleThirdChange = (event) => {
    const value = event.target.value;
    setThirdValue(value);
    const updatedData = { ...localData, detailedType: value };
    setLocalData(updatedData);
    dispatch(updatePropertyData(updatedData));
  };

  const handleBack = () => {
    dispatch(updatePropertyData(localData));
    setBackcom(true);
  };

  if (backcom) {
    return <Propertie />;
  }

  const handleNext = () => {
    dispatch(updatePropertyData(localData));
    setOpen(true);
  };

  if (open) {
    return <AddMedia />;
  }

  const firstOptions = ['Residential', 'Commercial', 'Labour Camp'];
  const secondOptions = {
    'Residential': ['Apartments', 'Townhouses', 'Villa', 'Residential Land'],
    'Commercial': ['Office', 'Restaurant', 'Cloud Kitchen', 'Gym', 'Retail Store', 'Supermarket', 'Baqala', 'Warehouse', 'Recording Studio', 'Hotel', 'Hotel Apartments', 'Clinic', 'Hospital', 'Other Commercial Space'],
    'Option 3': ['Option 3-1', 'Option 3-2'],
  };
  const thirdOptions = {
    'Apartments': ['Studio Apartment', '1 Bedroom Apartment', '2 Bedroom Apartment', '3 Bedroom Apartment', '4 Bedroom Apartment', '5 Bedroom Apartment', '6 Bedroom Apartment', '7 Bedroom Apartment'],
    'Townhouses': ['1 Bedroom Townhouse', '2 Bedroom Townhouse', '3 Bedroom Townhouse', '4 Bedroom Townhouse', '5 Bedroom Townhouse', '6 Bedroom Townhouse', '7 Bedroom Townhouse', '8 Bedroom Townhouse', '9 Bedroom Townhouse', '10 Bedroom Townhouse'],
    'Villa': ['2 Bedroom Villa', '3 Bedroom Villa', '4 Bedroom Villa', '5 Bedroom Villa', '6 Bedroom Villa', '7 Bedroom Villa', '8 Bedroom Villa', '9 Bedroom Villa', '10 Bedroom Villa', '11 Bedroom Villa'],
    'Option 3-1': ['Final 5', 'Final 6'],
  };



  return (
    <>
      <Box>
        <Typography style={{
          fontFamily: "Outfit",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
          margin: "0px 0px 50px 0px"
        }}>
          Add Property
        </Typography>
      </Box>

      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px"
            }}

          >Property Title</Typography>
          <TextField fullWidth variant="outlined" placeholder='Title name'
            name="title"
            value={localData.title || ''}
            onChange={handleInputChange}
            sx={newIncomeTextfield}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px"
            }}
          >Content</Typography>
          <TextField fullWidth multiline rows={4} variant="outlined" placeholder='Content details'
            name="description"
            value={localData.description || ''}
            onChange={handleInputChange}
            sx={newIncomeTextfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px"
            }}
          >Type </Typography>
          {/* 
          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="type"
              value={localData.type || ''}
              onChange={handleInputChange}
              displayEmpty

            >
              <MenuItem value="" disabled>
                Select your Type
              </MenuItem>
              <MenuItem value={PropertyType.RESIDENTIAL}>Residential</MenuItem>
              <MenuItem value={PropertyType.COMMERCIAL}>Commercial</MenuItem>
              <MenuItem value={PropertyType.COMMUNITY}>Community</MenuItem>
            </Select>
          </FormControl> */}


          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth sx={newIncomeTextfield}>
                  <Select value={firstValue} onChange={handleFirstChange}>
                    {firstOptions.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {firstValue && (
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth style={{ marginTop: 0 }}>
                    <Select value={secondValue} onChange={handleSecondChange}>
                      {secondOptions[firstValue]?.map((option) => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {secondValue && (
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth style={{ marginTop: 0 }}>
                    <Select value={thirdValue} onChange={handleThirdChange}>
                      {thirdOptions[secondValue]?.map((option) => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </div>

        </Grid >
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px"
            }}
          >Status</Typography>
          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="property_status"
              value={localData.property_status || ''}
              onChange={handleInputChange}
              displayEmpty

            >
              <MenuItem value="" disabled>
                Select your Status
              </MenuItem>
              <MenuItem value={PropertyStatus.OCCUPIED}>Occupied</MenuItem>
              <MenuItem value={PropertyStatus.AVAILABALE}>Available</MenuItem>

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px"
            }}
          >Label</Typography>
          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="Brand"
              value={localData.Brand || ''}
              onChange={handleInputChange}
              displayEmpty

            >
              <MenuItem value="" disabled>
                Select your Brand
              </MenuItem>
              <MenuItem value="Penthouse">Penthouse</MenuItem>
              <MenuItem value="Duplex">Duplex</MenuItem>
              <MenuItem value="Maisonette">Maisonette</MenuItem>
              <MenuItem value="Loft">Loft</MenuItem>
              <MenuItem value="Standalone">Standalone</MenuItem>
              <MenuItem value="Sea-Facing">Sea-Facing</MenuItem>
              <MenuItem value="Waterfront">Waterfront</MenuItem>
              <MenuItem value="Canal-Facing">Canal-Facing</MenuItem>
              <MenuItem value="City-Facing">City-Facing</MenuItem>
              <MenuItem value="Eco-Friendly">Eco-Friendly</MenuItem>
              <MenuItem value="Burj Khalifa View">Burj Khalifa View</MenuItem>
              <MenuItem value="Ocean View">Ocean View</MenuItem>
              <MenuItem value="Yacht Dock">Yacht Dock</MenuItem>
              <MenuItem value="Helipad">Helipad</MenuItem>
              <MenuItem value="Vehicle Elevator">Vehicle Elevator</MenuItem>
              <MenuItem value="Private Elevator">Private Elevator</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{
            fontFamily: "Outfit",
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "20px",
            margin: "10px 0px 10px 0px"
          }}>
            Price
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px"
            }}
          >Currency</Typography>
          {/* <TextField fullWidth variant="outlined" placeholder='AED'
                        name="currency"
                        value={localData.currency || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    /> */}



          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="currency"
              value={localData.currency || ''}
              onChange={handleInputChange}
              displayEmpty

            >
              <MenuItem value="" disabled>
                Select your Currency
              </MenuItem>
              <MenuItem value='AED'>AED</MenuItem>
              <MenuItem value='USD'>USD</MenuItem>
              <MenuItem value='SAR'>SAR</MenuItem>
            </Select>
          </FormControl>

        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px"
            }}
          >Sale or Rent Price</Typography>
          <TextField fullWidth variant="outlined" placeholder='1000'
            name="lease_price"
            value={localData.lease_price || ''}
            onChange={handleInputChange}
            sx={newIncomeTextfield}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px"
            }}
          >Second Price</Typography>
          <TextField fullWidth variant="outlined" placeholder='Enter the second price (optional)'
            name="price"
            value={localData.price || ''}
            onChange={handleInputChange}
            sx={newIncomeTextfield}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px"
            }}
          >After the price</Typography>
          <TextField fullWidth variant="outlined" placeholder='Enter the after price'
            name="price2"
            value={localData.price2 || ''}
            onChange={handleInputChange}
            sx={newIncomeTextfield}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px"
            }}
          >Price Prefix </Typography>
          <TextField fullWidth variant="outlined" placeholder='Enter the price prefix'
            name="price_prefix"
            value={localData.price_prefix || ''}
            onChange={handleInputChange}
            sx={newIncomeTextfield}
          />
        </Grid>
        <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
          <Button style={{
            border: "1px solid rgba(154, 25, 21, 1)",
            color: "rgba(154, 25, 21, 1)",
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: 500,
            textTransform: "none",
            width: "130px",
            height: "40px",
            margin: "20px 0px 50px 15px"
          }}
            variant="outlined"
            onClick={handleBack}
          >Cancel</Button>
          <Box sx={{ display: 'flex', }}>
            <Button style={{
              backgroundColor: "rgba(184, 25, 24, 1)",
              fontSize: "14px",
              lineHeight: "16px",
              fontWeight: 500,
              textTransform: "none",
              width: "130px",
              height: "40px",
              margin: "20px 0px 50px 0px"

            }}
              variant="contained"
              onClick={handleNext}
            >Next</Button>
          </Box>
        </Box>
      </Grid >
    </>
  );
}

export default AddProperty;















// import React from "react";
// import Extracode from "./Extracode";



// const AddProperty = () => {

//   return (
//     <>
//       <Extracode />
//     </>
//   )


// }

// export default AddProperty;





