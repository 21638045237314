import {
  useStyles,
  mainTypography,
  CustomTextField,
  subTypography,
  subTypographyRed,
  aplyfilter,
} from "./ContractsStyles";
import {
  TextField,
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import LandlordTable from "./LandlordTable";
import TenantTable from "./TenantTable";

function Contracts() {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(null);
  const [selectedTypography, setSelectedTypography] = useState("Tenants");

  const handleTypographyClick = (typography) => {
    setSelectedTypography(typography);
  };
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  return (
    <>
      <Box>
        <Typography sx={mainTypography}>Contracts</Typography>
      </Box>
      {/* <Box className={classes.searchFieldBox}>
        {selectedTypography === "Tenants" ? (
          <>
            <TextField
              variant="outlined"
              placeholder="Search by Tenants"
              value={searchValue}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              sx={CustomTextField}
            />
            <Button sx={aplyfilter}>Apply Filter</Button>
          </>
        ) : (
          <>
            {" "}
            <TextField
              variant="outlined"
              placeholder="Search by Landlords"
              value={searchValue}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              sx={CustomTextField}
            />
            <Button sx={aplyfilter}>Apply Filter</Button>
          </>
        )}
      </Box> */}
      <Box className={classes.mainBox}>
        <Typography
          sx={
            selectedTypography === "Tenants" ? subTypographyRed : subTypography
          }
          onClick={() => handleTypographyClick("Tenants")}
        >
          Tenants
        </Typography>
        <Typography
          sx={
            selectedTypography === "Landlord" ? subTypographyRed : subTypography
          }
          onClick={() => handleTypographyClick("Landlord")}
        >
          Landlords
        </Typography>
      </Box>

      {selectedTypography === "Landlord" ? <LandlordTable /> : <TenantTable />}
    </>
  );
}

export default Contracts;
