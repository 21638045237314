import React, { useEffect, useState } from "react";
import {
  useStyles,
  mainTypography,
  CustomTextField,
  createBtn,
  tableHeadCell,
  tableBodyCell,
  aplyfilter,
} from "./IncomeStyles";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";

function Income() {
  const classes = useStyles();
  const [searchLandlord, setSearchLandlord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [incomes, setIncomes] = useState([]);

  const navigate = useNavigate();


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchLandlord(value);
  };

  const fetchIncomes = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      let url;
      if (searchLandlord) {
        url = `${endpoints.financeIncome}?landlord=${searchLandlord}`;
      } else {
        url = endpoints.financeIncome;
      }
      const response = await axios.get(url, requestOptions);
      setIncomes(response.data.data);
      if (searchLandlord) {
        toast.success(response.data.message);
      }
    } catch (error) {
      if (searchLandlord) {
        toast.error(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIncomes();
  }, []);

  
  return (
    <>
      <Box className={classes.mainBox}>
        <Typography sx={mainTypography}>Income</Typography>
        <Button
          sx={createBtn}
          onClick={() => navigate("/finance/income/create-income")}
        >
          Create New
        </Button>
      </Box>
      <Box className={classes.searchFieldBox}>
        <TextField
          variant="outlined"
          placeholder="Search by Landlord"
          value={searchLandlord}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={CustomTextField}
        />
        <Button sx={aplyfilter} onClick={fetchIncomes}>
          Apply Filter
        </Button>
      </Box>

      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
          ) : incomes.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50vh"
            >
              <Typography>No data available</Typography>
            </Box>
      ) : (
        <Box marginTop={"2rem"}>
          <TableContainer sx={{ height: "65vh", overflowX: "auto" }}>
            <Table sx={{ border: "1px solid #EFEFEF", minWidth: "100rem" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeadCell}>Property</TableCell>
                  <TableCell sx={tableHeadCell}>
                    {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        // marginRight: 1,
                      }}
                    >
                      <IconButton
                        onClick={handleSortAscending}
                        sx={{
                          padding: "0",
                          height: "8px",
                          minHeight: "8px",
                        }}
                      >
                        <ArrowDropUpIcon
                          sx={{ fontSize: "30px", color: "black" }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={handleSortDescending}
                        sx={{ padding: "0", height: "8px", minHeight: "8px" }}
                      >
                        <ArrowDropDownIcon
                          sx={{ fontSize: "30px", color: "black" }}
                        />
                      </IconButton>
                    </Box>
                    Landlord Name
                  </Box> */}
                    Landlord Name
                  </TableCell>
                  <TableCell sx={tableHeadCell}>Sub Property Id</TableCell>
                  <TableCell sx={tableHeadCell}>Unit</TableCell>
                  <TableCell sx={tableHeadCell}>Recieved From</TableCell>
                  <TableCell sx={tableHeadCell}>Method</TableCell>
                  <TableCell sx={tableHeadCell}>Date</TableCell>
                  <TableCell sx={tableHeadCell}>Category</TableCell>
                  <TableCell sx={tableHeadCell}>Sub Category</TableCell>
                  <TableCell sx={tableHeadCell}>Description</TableCell>
                  <TableCell sx={tableHeadCell}>Amount</TableCell>
                  <TableCell sx={tableHeadCell}>Notes</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {incomes?.map((income) => (
                  <TableRow key={income.id}>
                    <TableCell sx={tableBodyCell}>
                      {income.property?.title || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {income.landlord?.first_name || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {income.sub_property?.ref_id || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {income.unit?.ref_id || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {income.received_from || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {income.method || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {income.date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {income.category || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {income.sub_category || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {income.description || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {income.amount || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {income.notes || "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
}

export default Income;
