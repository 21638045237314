import React from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";

import { useState, useEffect } from 'react';
import AddUfeatures from "./AddUfeatures";
import AddUVirtualTour from "./AddUVirtualTour";
import { useSelector, useDispatch } from 'react-redux';
import { updateUlocationData } from '../../../../Redux/AdminReducer/unitSlice';
import { useStyles, newIncomeTextfield, } from './UnitStyle';






const AddUlocation = () => {
    const dispatch = useDispatch();
    const [open, setopen] = useState(false);
    const [backcom, setbackcom] = useState(false);

    const ulocationData = useSelector(state => state.unit.ulocationData);
    const [localData, setLocalData] = useState(ulocationData);


    useEffect(() => {
        setLocalData(ulocationData);
    }, [ulocationData]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLocalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleBack = () => {
        dispatch(updateUlocationData(localData));
        setbackcom(true)
    }
    if (backcom) {
        return (
            <AddUfeatures />
        )
    }
    const handleNext = () => {
        dispatch(updateUlocationData(localData));
        setopen(true)
    }
    if (open) {
        return (
            <AddUVirtualTour />
        )
    }
    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Add Unit
                </Typography>
            </Box>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Location
                </Typography>
            </Box>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}

                    >Address</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Address'
                        name="address"
                        value={localData.address || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Country</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the Country'
                        name="country"
                        value={localData.country || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >City</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter City'
                        name="city"
                        value={localData.city || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Area</Typography>
                    <TextField fullWidth variant="outlined" placeholder='ft* (Sq Ft)'
                        name="area"
                        value={localData.area || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={12} >
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Postal number/zip</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Postal number/zip'
                        name="postal_code"
                        value={localData.postal_code || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "20px",
                            lineHeight: "20px",
                            margin: "20px 0px 15px 0px"
                        }}
                    >Map</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    map
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Latitude</Typography>
                    <TextField fullWidth variant="outlined" placeholder="25° 16' 37.1532'' N"
                        name="latitude"
                        value={localData.latitude || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "15px 0px 15px 0px"
                        }}
                    >Longitude</Typography>
                    <TextField fullWidth variant="outlined" placeholder="55° 17' 46.4964'' E"
                        name="longitude"
                        value={localData.longitude || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                    <Button style={{
                        border: "1px solid rgba(154, 25, 21, 1)",
                        color: "rgba(154, 25, 21, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"
                    }}
                        variant="outlined"
                        onClick={handleBack}

                    >Back</Button>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            margin: "20px 0px 50px 15px"

                        }}
                            variant="contained"
                            onClick={handleNext}
                        >Next</Button>
                    </Box>
                </Box>
            </Grid>

        </>
    )
}

export default AddUlocation;