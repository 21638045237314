import { Grid, Box, Typography, TextField,Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider, } from "@mui/material";
import React, { useState } from "react";
import {
  CustomTextField,
  gridItem2ndTypography,
  gridItemTypography,
  mainTypography,
  modalSubmit,
  modalTitleTypography,
  modalDateTypography,
  useStyles,
} from "./SOAStyles";
import { rentStatus } from "../../../enums/UserTypes";
// import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

function ViewSOA() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { soa } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [pdfError, setPdfError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handlePdfLoadError = (error) => {
    setPdfError(error);
  };

  const updateSOA = async (approvalStatus) => {
    try {
      setLoading(false);
      const payload = {
        finance_approval: approvalStatus,
      };
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.patch(
        `${endpoints.financeSOA}?id=${soa.id}`,
        payload,
        requestOptions
      );
      toast.success(response.data.message);
      if (response.status === 200) {
        navigate("/finance/soa");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    
    setModalOpen(false);
    
  };
  return (
    <>
      <Box>
        <Typography sx={mainTypography}>Request Details</Typography>
      </Box>
      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : (
        <>
          <Grid container marginTop={"2rem"} spacing={2}>
            <Grid item xs={12} sm={5}>
              <Box className={classes.gridItemMainBox}>
                <Box>
                  <Typography sx={gridItemTypography}>Property</Typography>
                </Box>
                <Box className={classes.gridItemSubBox}>
                  <Typography sx={gridItem2ndTypography}>
                    {soa?.property?.ref_id || "N/A"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box className={classes.gridItemMainBox}>
                <Box>
                  <Typography sx={gridItemTypography}>Date Range</Typography>
                </Box>
                <Box className={classes.gridItemSubBox}>
                  <Typography sx={gridItem2ndTypography}>
                    {soa?.start_date || "-"} to {soa?.end_date || "-"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Box className={classes.gridItemMainBox}>
                <Box>
                  <Typography sx={gridItemTypography}>Description</Typography>
                </Box>
                <Box className={classes.gridItemSubBox} height={"12rem"}>
                  <Typography sx={gridItem2ndTypography}>
                    {soa?.description || "-"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {soa?.finance_approval === rentStatus.pending.value ? (
              <>
                <Button
                  style={{
                    border: "1px solid rgba(154, 25, 21, 1)",
                    color: "rgba(154, 25, 21, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 20px 50px 0px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    updateSOA(rentStatus.disapproved.value);
                  }}
                >
                  Disapprove
                </Button>
                <Button
                  style={{
                    border: "rgba(184, 25, 24, 1)",
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    color: "#FFFF",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 0px 50px 0px",
                  }}
                  variant="contained"
                  onClick={() => {
                    updateSOA(rentStatus.approved.value);
                  }}
                >
                  Approve
                </Button>
              </>
            ) : soa?.finance_approval === rentStatus.approved.value ? (
              <Box sx={{ display: "flex", alignItems: "center",gap:'10px' }}>
              <Button
                style={{
                  border: "rgba(184, 25, 24, 1)",
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  color: "#FFFF",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "130px",
                  height: "40px",
                  margin: "20px 0px 50px 0px",
                }}
                variant="contained"
                onClick={() => {
                  navigate("/finance/soa");
                }}
              >
                Approved
              </Button>
              {soa.document && (
              <Button
                style={{
                  border: "1px solid rgba(154, 25, 21, 1)",
                  color: "rgba(154, 25, 21, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "130px",
                  height: "40px",
                  margin: "20px 20px 50px 0px",
                }}
                variant="outlined"
                onClick={() => {
                  setModalOpen(true)
                }}
              >
                View SOA
              </Button>
              )}
              </Box>
            ) : soa?.finance_approval === rentStatus.disapproved.value ? (
              <Button
                style={{
                  border: "1px solid rgba(154, 25, 21, 1)",
                  color: "rgba(154, 25, 21, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "130px",
                  height: "40px",
                  margin: "20px 20px 50px 0px",
                }}
                variant="outlined"
                onClick={() => {
                  navigate("/finance/soa");
                }}
              >
                Disapproved
              </Button>
            ) : (
              ""
            )}
          </Box>
        </>
      )}

<Dialog open={modalOpen} onClose={handleModalClose}>
        <DialogTitle sx={mainTypography}>SOA</DialogTitle>
        <Divider></Divider>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <DialogTitle sx={modalTitleTypography}>
            Statement of Account. {soa?.landlord?.first_name || '-'}
          </DialogTitle>
          <DialogTitle sx={modalDateTypography}>{soa?.start_date || '-'} to {soa?.end_date || '-'}</DialogTitle>
        </Box>
        <Box>
          <DialogContent>
         
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                {pdfError ? (
                  <div>Error loading PDF: {pdfError.message}</div>
                ) : (
                  <Viewer fileUrl={soa.document || 'N/A'} onError={handlePdfLoadError} />
                )}
              </Worker>
          </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleModalClose} sx={modalSubmit} >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ViewSOA;
