import React, { useEffect } from "react";
import { useState } from "react";
import { Box, Typography, Button, Grid, Tabs, Tab } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useStyles } from "../Dashboard/DashboardStyle";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { UserTypesNum } from "../../../enums/UserTypes";
import DateFormatter from "../../../utils/DateFormatter";
import { Loader } from "../../../utils/Loader";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";




const Notification = () => {
  const classes = useStyles();
  const  location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const value = queryParams.get('value') || '0';
  const [selectedTab, setSelectedTab] = useState(Number(value));
  const [checked, setChecked] = useState(true);
  const [alerts, setAlerts] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const notificationsAndPendingActions = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      let url;
      {
        selectedTab === 0
          ? (url = `${endpoints.getNotifications}?user_type=${
              UserTypesNum.SuperAdmin
            }&user_id=${localStorage.getItem("id")}`)
          : selectedTab === 1
          ? (url = `${endpoints.getPendingActions}?user_type=${
              UserTypesNum.SuperAdmin
            }&user_id=${localStorage.getItem("id")}`)
          : (url = endpoints.AuditLogs);
      }

      const response = await axios.get(url, requestOptions);
      setAlerts(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    notificationsAndPendingActions();
    console.log('this:',selectedTab);
    
    
    
  }, [selectedTab]);

  const TabPanel = ({ children, value, index }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ padding: "20px 0px 0px 0px" }}>{children}</Box>
      )}
    </div>
  );

  const a11yProps = (index) => ({
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  });

  const handleSwitch = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Box>
        {/* Notification Data */}
        {selectedTab === 0 && (
          <>
            <>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  All Notification
                </Typography>
                {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "rgba(184, 25, 24, 1)",
                    }}
                  >
                    <label
                      htmlFor="mute-notifications"
                      style={{ marginRight: "8px" }}
                    >
                      Mute All Notifications
                    </label>
                    <Switch
                      style={{ color: "rgba(184, 25, 24, 1)" }}
                      id="mute-notifications"
                      checked={checked}
                      onChange={handleSwitch}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                </Box> */}
              </Box>
            </>
          </>
        )}
        {/* All Pending Actions */}
        {selectedTab === 1 && (
          <>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                All Pending Actions
              </Typography>
            </Box>
          </>
        )}
        {selectedTab === 2 && (
          <>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                All Recent Activities
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box style={{ margin: "18px 0px 0px 0px" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="navigation tabs"
          TabIndicatorProps={{
            style: { backgroundColor: "rgba(184, 25, 24, 1)", height: "1px" },
          }}
        >
          <Tab
            style={{
              color: selectedTab === 0 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 0 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Notifications"
            {...a11yProps(0)}
          />
          <Tab
            style={{
              color: selectedTab === 1 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 1 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Pending Actions"
            {...a11yProps(1)}
          />
          <Tab
            style={{
              color: selectedTab === 1 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 2 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Recent Activities"
            {...a11yProps(2)}
          />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <Grid
            container
            height={{ sm: "30rem", xs: "50rem" }}
            overflow={"auto"}
          >
            {loading ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={"100%"}
              >
                <Loader />
              </Box>
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <Box>
                  {alerts?.map((alert) => (
                    <Box
                      key={alert.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid #EAECF0",
                        margin: "15px 0px 0px 0px",
                        borderRadius: "5.8px",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className={classes.our}
                      >
                        <Avatar alt="Remy Sharp" src={"/image/Notifi1.jpg"} />
                        <Typography
                          style={{
                            color: "rgba(26, 31, 54, 1)",
                            fontFamily: "Outfit",
                            fontSize: "14px",
                            lineHeight: "22px",
                            fontWeight: 600,
                            margin: "0px 0px 0px 10px",
                          }}
                        >
                          {alert?.title || "Untitled Notification"}
                          <Typography
                            style={{
                              color: "rgba(26, 31, 54, 1)",
                              font: "Outfit",
                              fontSize: "14px",
                              lineHeight: "22px",
                              fontWeight: 500,
                            }}
                          >
                            {alert?.body || "Content not available"}
                          </Typography>
                          <Typography
                            style={{
                              color: "rgba(29, 41, 57, 0.52)",
                              font: "Outfit",
                              fontSize: "14px",
                              lineHeight: "22px",
                              fontWeight: 500,
                            }}
                          >
                            {DateFormatter(alert?.created_at) ||
                              "Date not available"}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Grid container height={"30rem"} overflow={"auto"}>
            {loading ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={"100%"}
              >
                <Loader />
              </Box>
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <Box>
                  {alerts?.map((alert) => (
                    <Box
                      key={alert.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid #EAECF0",
                        margin: "15px 0px 0px 0px",
                        borderRadius: "5.8px",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className={classes.our}
                      >
                        <Avatar alt="Remy Sharp" src={"/image/Pend1.jpg"} />
                        <Typography
                          style={{
                            color: "rgba(26, 31, 54, 1)",
                            font: "Outfit",
                            fontSize: "14px",
                            lineHeight: "22px",
                            fontWeight: 600,
                            margin: "0px 0px 0px 10px",
                          }}
                        >
                          {alert?.tenant?.first_name || "Untitled Notification"}
                          <Typography
                            style={{
                              color: "rgba(26, 31, 54, 1)",
                              font: "Outfit",
                              fontSize: "14px",
                              lineHeight: "22px",
                              fontWeight: 500,
                            }}
                          >
                            {alert?.action || "Content not available"}
                          </Typography>
                          <Typography
                            style={{
                              color: "rgba(29, 41, 57, 0.52)",
                              font: "Outfit",
                              fontSize: "14px",
                              lineHeight: "22px",
                              fontWeight: 500,
                            }}
                          >
                            {/* {alert?.created_at?.split("T")[0] ||
                            "Date not available"} */}
                            {DateFormatter(alert?.created_at)}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <Grid container height={"30rem"} overflow={"auto"}>
            {loading ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={"100%"}
              >
                <Loader />
              </Box>
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <Box>
                  {alerts?.map((alert) => (
                    <Box
                      key={alert.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid #EAECF0",
                        margin: "15px 0px 0px 0px",
                        borderRadius: "5.8px",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className={classes.our}
                      >
                        <Avatar alt="Remy Sharp" src={"/image/Pend1.jpg"} />
                        <Typography
                          style={{
                            color: "rgba(26, 31, 54, 1)",
                            font: "Outfit",
                            fontSize: "14px",
                            lineHeight: "22px",
                            fontWeight: 600,
                            margin: "0px 0px 0px 10px",
                          }}
                        >
                          {alert?.created_by?.user_type ||
                            "Untitle Notification"}
                          <Typography
                            style={{
                              color: "rgba(26, 31, 54, 1)",
                              font: "Outfit",
                              fontSize: "14px",
                              lineHeight: "22px",
                              fontWeight: 500,
                            }}
                          >
                            {alert.action || "Content not available"}
                          </Typography>
                          <Typography
                            style={{
                              color: "rgba(29, 41, 57, 0.52)",
                              font: "Outfit",
                              fontSize: "14px",
                              lineHeight: "22px",
                              fontWeight: 500,
                            }}
                          >
                            {DateFormatter(alert?.created_at)}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </TabPanel>
      </Box>
    </>
  );
};

export default Notification;
