import React, { useState } from "react";
import { Box, Button, Modal, Tab } from "@mui/material";
import { useStyles } from "../Client/ClientStyle";
import axios from "axios";
import toast from "react-hot-toast";
import { endpoints } from "../../../apiEndpoints";




const DeleteWarning = ({ open, onClose, id, getDocuments }) => {
  const classes = useStyles();
  const [isDeleted, setIsDeleted] = useState(false);
  const deleteContract = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      let url = endpoints.documents;

      const response = await axios.delete(
        `${url}?document_id=${id}`,
        requestOptions
      );
      toast.success(response.data.message);
      onClose();
      getDocuments();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="popup-modal-title"
        aria-describedby="popup-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className={classes.Poproot}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img
            className={classes.img}
            src="/image/Group.png"
            alt="popup image"
          />
          <h6 className={classes.heading}>Are You Sure, You want to delete?</h6>
          <Box className={classes.btns}>
            <Button
              variant="outlined"
              style={{
                border: "1px solid rgba(154, 25, 21, 1)",
                color: "rgba(154, 25, 21, 1)",
                textTransform: "none",
                width: "90px",
                height: "30px",
              }}
              onClick={onClose}
            >
              Skip
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                textTransform: "none",
                margin: "0px 0px 0px 10px",
                width: "90px",
                height: "30px",
              }}
              onClick={deleteContract}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteWarning;
