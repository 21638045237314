import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
const localizer = momentLocalizer(moment);

function Dashboard() {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [myEvents, setMyEvents] = useState([]);
  const colorsData = [
    { color: "#45A9EA", text: "Last Rent" },
    { color: "#B81918", text: "Due Rent" },
    { color: "#00E317", text: "Upcoming Rent" },
    { color: "#FF5B19", text: "Outstanding Payment" },
  ];
  // const events = [
  //   {
  //     id: 1,
  //     title: "Last Rent",
  //     start: new Date(2024, 3, 30, 10, 0), // Year, Month (0-indexed), Day, Hour, Minute
  //     end: new Date(2024, 3, 30, 24, 0),
  //     color: "#45A9EA",
  //   },
  //   {
  //     id: 2,
  //     title: "Due Rent",
  //     start: new Date(2024, 5, 2, 14, 0),
  //     end: new Date(2024, 5, 2, 16, 0),
  //     color: "#B81918",
  //   },
  //   {
  //     id: 3,
  //     title: "Due Rent",
  //     start: new Date(2024, 7, 15, 14, 0),
  //     end: new Date(2024, 7, 17, 16, 0),
  //     color: "#B81918",
  //     desc: "this is description",
  //   },
  // ];

  const getMyEventsList = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        endpoints.tenantDashboard,
        requestOptions
      );
      // setMyEvents(response.data.data);
      setMyEvents(response.data?.data?.map(event => ({
        ...event,
        start_date: new Date(event.start_date),
        end_date: new Date(event.end_date)
    })));
    } catch {}
  };
  useEffect(() => {
    getMyEventsList();
  }, []);
  // Custom event prop getter function to set color for each event
  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.color, // Set background color based on event's color property
      borderRadius: "5px", // Optional: Add border radius
      opacity: 0.8, // Optional: Set opacity
      color: "white", // Optional: Set text color
      border: "none", // Optional: Remove border
      boxShadow: "none", // Optional: Remove box shadow
    };
    return {
      style,
    };
  };

  const CustomHeader = ({ label, onNavigate }) => {
    const goToPrev = () => {
      onNavigate("PREV");
    };

    const goToNext = () => {
      onNavigate("NEXT");
    };

    return (
      <div className="custom-header">
        <div className="header-label">
          <h2>{label}</h2>
        </div>
        <div className="header-right">
          <Button
            onClick={goToPrev}
            sx={{
              color: "#737679",
              fontFamily: "Outfit",
              fontSize: "15px",
              fontWeight: "500",
              textTransform: "unset",
              "&:hover": {
                background: "#ffff",
                color: "#73767990",
              },
            }}
          >
            Previous
          </Button>
          {/* <button onClick={goToToday}>Today</button> */}
          <Button
            onClick={goToNext}
            sx={{
              color: "#9A1915",
              fontFamily: "Outfit",
              fontSize: "15px",
              fontWeight: "500",
              textTransform: "unset",
              "&:hover": {
                background: "#ffff",
                color: "#9A191590",
              },
            }}
          >
            Next
          </Button>
        </div>
      </div>
    );
  };

  const handleEventClick = (event) => {
    // setSelectedEvent(event);
    if (event && event.title && event.start_date && event.end_date) {
      setSelectedEvent(event);
      
    } else {
      console.error("Event data is incomplete:", event);
    }
  };

  const handleClose = () => {
    setSelectedEvent(null);
  };

  return (
    <>
      <Box>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "24px",
            color: "#000000",
          }}
        >
          Dashboard
        </Typography>
      </Box>
      <div>
        <Calendar
          localizer={localizer}
          components={{
            toolbar: CustomHeader,
          }}
          events={myEvents}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={handleEventClick}
          startAccessor="start_date"
          endAccessor="end_date"
          style={{ height: "38rem" }}
        />
        <Modal
          open={!!selectedEvent}
          onClose={handleClose}
          aria-labelledby="event-modal-title"
          aria-describedby="event-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: 300, sm: 450 },
              minHeight: { xs: 150, sm: 250 },
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 10,
              p: 4,
            }}
          >
            <Typography id="event-modal-title" variant="h6" component="h2">
              {`Amount:${selectedEvent?.title}`}
            </Typography>
            <Typography id="event-modal-description" sx={{ mt: 2 }}>
              <strong>Start:</strong>{" "}
              {moment(selectedEvent?.start_date).format("MMMM Do YYYY")}
            </Typography>
            <Typography>
              <strong>End:</strong>{" "}
              {moment(selectedEvent?.end_date).format("MMMM Do YYYY")}
            </Typography>
            <Typography>
              <strong>Description:</strong>{" "}
              {selectedEvent?.type || "No description available"}
            </Typography>
            <Button
              onClick={handleClose}
              variant="contained"
              color={"error"}
              sx={{ mt: 3 }}
            >
              Close
            </Button>
          </Box>
        </Modal>
      </div>

      <Box
        // marginTop={"0.5rem"}
        justifyContent={"space-between"}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          width: {
            xs: "20rem",
            sm: "20rem",
            md: "50rem",
            lg: "50rem",
            xl: "65rem",
          },
          height: { xs: "10rem" },
          marginTop: { xs: "1rem", md: "-2.5rem" },
        }}
      >
        {colorsData.map((data, index) => (
          <Box
            key={index}
            display={"flex"}
            alignItems={"center"}
            gap={"0.5rem"}
          >
            <Box
              width={"1.125rem"}
              height={"1.125rem"}
              borderRadius={"50%"}
              backgroundColor={data.color}
            />
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontWeight: "500",
                fontSize: "15px",
                color: "#000000",
              }}
            >
              {data.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default Dashboard;
