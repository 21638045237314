import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';


export const useStyles = makeStyles(() => {
    const theme = useTheme();
    return {
        graph: {
            backgroundColor: "rgb(255,255,255)",
            border: "1px solid rgba(173, 173, 173, 1)",
            borderRadius: "5.8px",
            // boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)',
            margin: "25px 0px 0px 0px",
            padding: "0px 0px 0px 0px"
        },

        Poproot: {
            width: "600px",
            height: "550px",
            transform: 'translate(-70%, -50%)',
            border: "1px solid rgba(154, 25, 21, 1)",
            borderRadius: "30px",
            margin: "0px 0px 40px 70px",
            [theme.breakpoints.down("sm")]: {
                width: "350px",
                transform: 'translate(-50%, -50%)',
                margin: "0px 0px 0px 0px"
            }
        },

    };
});

