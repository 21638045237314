import {
  Box,
  Typography,
  Grid,
  Avatar,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import { tableBodyCell, tableHeadCell, useStyles } from "./PropertiesStyles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { useLocation } from "react-router-dom";





const ViewPdcs = () => {
  const classes = useStyles();
  const location = useLocation();
  const { details, property } = location.state || {};
  const tableHeaders = [
    "Payment No.",
    "Payment Date",
    "Amount",
    "Vat",
    "Cheque No.",
    "Bank",
    "Type",
  ];
  return (
    <>
      <Grid
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row", md: "row" }}
        gap={"2rem"}
        marginBottom={"6rem"}
      >
        <Box className={classes.propertyViewBox}>
          <Box className={classes.imgBox}>
            <img
              // src="/image/proimage1.jpg"
              src={property?.attachments[0]?.media || ""}
              alt="property image"
              onError={(e) => (e.target.src = "/image/propertyicon.png")}
              className={classes.propertyViewImg}
            />
          </Box>
          <Box className={classes.locationBox}>
            <LocationOnIcon sx={{ color: "#B81918" }} />
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#B81918",
              }}
            >
              {property?.address || "Not available"}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.appartmentDetail}>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "24px",
              color: "#B81918",
            }}
          >
            {property?.address || "Not available"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "40px",
              color: "#000000",
            }}
          >
            Full Address:{" "}
            <span style={{ fontWeight: "400" }}>
              {details?.agreements?.tenant_address || "not available"}
            </span>
          </Typography>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "20px",
              color: "#000000",
            }}
          >
            Current Tenant Details:
          </Typography>
          <Avatar
            src="/image/userAvatar.jpg"
            alt="img"
            className={classes.tenantImg}
            sx={{ width: "6rem", height: "6rem", color: "#ADADAD" }}
          />
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#000000",
            }}
          >
            {details?.agreements?.tenant?.first_name || "not available"}
            <br></br>
            {details?.agreements?.tenant?.phone || "not available"}
            <br></br>
            {details?.agreements?.tenant?.email || "not available"}
          </Typography>
        </Box>
      </Grid>

      {/* <Box marginBottom={"2rem"}>
        <Box marginBottom={"2rem"}>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
            }}
          >
            PDCs
          </Typography>
        </Box>
        <TableContainer sx={{ border: "1px solid #EFEFEF" }}>
          <Table>
            <TableHead sx={tableHeadCell}>
              {tableHeaders.map((header, index) => (
                <TableCell key={index} sx={tableHeadCell}>
                  {header}
                </TableCell>
              ))}
            </TableHead>

            <TableBody>
              {details?.agreements.pdcs
                ?.slice()
                .sort(
                  (a, b) => (a.payment_number || 0) - (b.payment_number || 0)
                )
                .map((pdc) => (
                  <TableRow key={pdc.id}>
                    <TableCell sx={tableBodyCell}>
                      {pdc?.payment_number || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {pdc?.payment_date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {pdc?.amount || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>{pdc?.vat || "-"}</TableCell>
                    <TableCell sx={tableBodyCell}>
                      {pdc?.cheque_number || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>{pdc?.bank || "-"}</TableCell>
                    <TableCell sx={tableBodyCell}>
                      {pdc?.type === "1"
                        ? "Rent"
                        : pdc?.type === "2"
                          ? "Security Deposit"
                          : "-"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}

      <Box marginBottom={"2rem"}>
        <Box marginBottom={"2rem"}>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "24px",
              color: "#000000",
            }}
          >
            PDCs
          </Typography>
        </Box>
        {details?.agreements.pdcs && details.agreements.pdcs.length > 0 ? (
          <TableContainer sx={{ border: "1px solid #EFEFEF" }}>
            <Table>
              <TableHead sx={tableHeadCell}>
                {tableHeaders.map((header, index) => (
                  <TableCell key={index} sx={tableHeadCell}>
                    {header}
                  </TableCell>
                ))}
              </TableHead>
              <TableBody>
                {details.agreements.pdcs
                  .slice()
                  .sort((a, b) => (a.payment_number || 0) - (b.payment_number || 0))
                  .map((pdc) => (
                    <TableRow key={pdc.id}>
                      <TableCell sx={tableBodyCell}>
                        {pdc?.payment_number || "-"}
                      </TableCell>
                      <TableCell sx={tableBodyCell}>
                        {pdc?.payment_date || "-"}
                      </TableCell>
                      <TableCell sx={tableBodyCell}>
                        {pdc?.amount || "-"}
                      </TableCell>
                      <TableCell sx={tableBodyCell}>{pdc?.vat || "-"}</TableCell>
                      <TableCell sx={tableBodyCell}>
                        {pdc?.cheque_number || "-"}
                      </TableCell>
                      <TableCell sx={tableBodyCell}>{pdc?.bank || "-"}</TableCell>
                      <TableCell sx={tableBodyCell}>
                        {pdc?.type === "1"
                          ? "Rent"
                          : pdc?.type === "2"
                            ? "Security Deposit"
                            : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            No Data Available
          </Typography>
        )}
      </Box>

    </>
  );
}

export default ViewPdcs;
