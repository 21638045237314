import React from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import AddUVirtualTour from "./AddUVirtualTour";
import AddUdocument from "./AddUdocument";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateFloorData, addFloorField, uploadFloorImage } from '../../../../Redux/AdminReducer/unitSlice';
import { useStyles, newIncomeTextfield, } from './UnitStyle';






const AddUfloor = () => {
    const dispatch = useDispatch();
    const [open, setopen] = useState(false);
    const [backcom, setbackcom] = useState(false);
    const floorData = useSelector(state => state.unit.floorData);
    const images = useSelector(state => state.unit.images);


    const handleInputChange = (id, event) => {
        const { name, value } = event.target;
        const updatedFields = floorData.map(field =>
            field.id === id ? { ...field, [name]: value } : field
        );
        dispatch(updateFloorData(updatedFields));
    };

    const handleUploadImage = (id, event) => {
        const file = event.target.files[0];
        if (file) {
            dispatch(uploadFloorImage({ id, file }));
        }
    };

    const handleAddField = () => {
        dispatch(addFloorField());
    };

    const handleBack = () => {
        setbackcom(true);
    };
    if (backcom) {
        return <AddUVirtualTour />;
    }

    const handleNext = () => {
        setopen(true);
    };
    if (open) {
        return <AddUdocument />;
    }


    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Add Property
                </Typography>
            </Box>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Floor Plans
                </Typography>
            </Box>
            {floorData.map((field, index) => (
                <Grid container spacing={2} key={field.id} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={12}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}

                        >Title</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the Plan Title'
                            name="title"
                            value={field.title} onChange={(e) => handleInputChange(field.id, e)}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Bedroom</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the number of bedrooms (only digits)'
                            name="bedroom"
                            value={field.bedroom} onChange={(e) => handleInputChange(field.id, e)}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Bathroom</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the number of bathroom (only digits)'
                            name="bathroom"
                            value={field.bathroom} onChange={(e) => handleInputChange(field.id, e)}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Price</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the Price'
                            name="price"
                            value={field.price} onChange={(e) => handleInputChange(field.id, e)}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Price Postfix</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the Price Postfix'
                            name="postfix"
                            value={field.postfix} onChange={(e) => handleInputChange(field.id, e)}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Plan Size</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter your Plan Size'
                            name="plan"
                            value={field.plan} onChange={(e) => handleInputChange(field.id, e)}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Plan Image</Typography>
                        <input
                            type="file"
                            onChange={(e) => handleUploadImage(index, e)}
                            style={{ width: '100%', padding: '10px 0' }}
                        />
                        {images[index] && (
                            <img src={URL.createObjectURL(images[index])} alt="Floor Plan" width={15} />
                        )}

                    </Grid>
                    <Grid item xs={12} md={12} mb={2}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Description</Typography>
                        <TextField fullWidth multiline rows={4} variant="outlined" placeholder='Enter the plan description.'
                            name="description"
                            value={field.description} onChange={(e) => handleInputChange(field.id, e)}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                </Grid>
            ))}
            <Box>
                <Button style={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 0px 50px 15px"

                }}
                    variant="contained"
                    onClick={handleAddField}

                >Add New</Button>
            </Box>
            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                <Button style={{
                    border: "1px solid rgba(154, 25, 21, 1)",
                    color: "rgba(154, 25, 21, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 0px 50px 15px"
                }}
                    variant="outlined"
                    onClick={handleBack}

                >Back</Button>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"

                    }}
                        variant="contained"
                        onClick={handleNext}
                    >Next</Button>
                </Box>
            </Box>
        </>
    )
}

export default AddUfloor;



