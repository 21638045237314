import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Grid, TextField, FormControl, Select, MenuItem, IconButton, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import toast from "react-hot-toast";
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { endpoints } from "../../../apiEndpoints";
import { newIncomeTextfield } from './AssociationStyle';
import { useNavigate } from "react-router-dom";





const LandlordInfoUpdate = () => {
    const [propertyData, setPropertyData] = useState([]);
    const [landlordData, setLandlordData] = useState([]);
    const [unitData, setUnitData] = useState([]);
    const [fields, setFields] = useState([{ unit: '' }]);
    const [entries, setEntries] = useState([{ id: Date.now(), secondary_landlord: '', shareholding_percentage: '' }]);
    const [formData, setFormData] = useState({
        property: '',
        primary_landlord: '',
        primary_landlord_share_percentage: '',
        primary_landlord_no_of_units: ''
    });

    const navigate = useNavigate();
    const location = useLocation();
    const landlordUpdate = location.state?.landlordupdate;


    const handleSubmit = () => {
        navigate('/admin/association')
    }

    const propertyList = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(endpoints.Propertylist, {
                headers: { Authorization: `Token ${token}` }
            });
            setPropertyData(response.data.data);
        } catch (error) {

        }
    };

    const getLandlords = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.getUserListing}?user_type=2`, {
                headers: { Authorization: `Token ${token}` }
            });
            setLandlordData(response.data.data);
        } catch (error) {

        }
    };

    const getUnits = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.Unitlist}?property=${formData.property}`, {
                headers: { Authorization: `Token ${token}` }
            });
            setUnitData(response.data.data);
        } catch (error) {

        }
    };

    // const updateUser = async () => {
    //     const payload = {
    //         property: formData.property,
    //         primary_landlord: formData.primary_landlord,
    //         primary_landlord_share_percentage: formData.primary_landlord_share_percentage,
    //         primary_landlord_no_of_units: formData.primary_landlord_no_of_units,
    //         unit: fields.map(field => field.unit),
    //         secondary_landlords: entries.map(entry => ({
    //             secondary_landlord: entry.secondary_landlord,
    //             shareholding_percentage: entry.shareholding_percentage,
    //             secondary_landlord_no_of_units: entry.secondary_landlord_no_of_units,
    //         })),
    //     };

    //     try {
    //         const token = localStorage.getItem('token');
    //         const response = await axios.patch(`${endpoints.LandAssociation}?association_id=${landlordUpdate.id}`, payload, {
    //             headers: { Authorization: `Token ${token}` }
    //         });
    //         toast.success(response.data.message);
    //         handleSubmit();
    //     } catch (error) {
    //         toast.error(error.response?.data?.message || "An error occurred");
    //     }
    // };




    const updateUser = async () => {
        const payload = {
            property: formData.property,
            primary_landlord: formData.primary_landlord,
            primary_landlord_share_percentage: formData.primary_landlord_share_percentage,
            primary_landlord_no_of_units: formData.primary_landlord_no_of_units,
            unit: fields
                .filter(field => field.unit) // Filter out empty units
                .map(field => field.unit),
            secondary_landlords: entries.map(entry => ({
                secondary_landlord: entry.secondary_landlord,
                shareholding_percentage: entry.shareholding_percentage,
                secondary_landlord_no_of_units: entry.secondary_landlord_no_of_units,
            })),
        };
    
        try {
            const token = localStorage.getItem('token');
            const response = await axios.patch(`${endpoints.LandAssociation}?association_id=${landlordUpdate.id}`, payload, {
                headers: { Authorization: `Token ${token}` }
            });
            toast.success(response.data.message);
            handleSubmit();
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
    };
    






    useEffect(() => {
        if (landlordUpdate) {
            setFormData({
                property: landlordUpdate.property.id,
                primary_landlord: landlordUpdate.primary_landlord.id,
                primary_landlord_no_of_units: landlordUpdate.primary_landlord_no_of_units,
                primary_landlord_share_percentage: landlordUpdate.primary_landlord_share_percentage
            });

            if (landlordUpdate.sub_property_list) {
                const units = landlordUpdate.sub_property_list.flatMap(sp => sp.unit.map(u => ({ unit: u.id })));
                setFields(units);
            }

            if (landlordUpdate.secondary_landlord) {
                setEntries(landlordUpdate.secondary_landlord.map((entry, index) => ({
                    id: Date.now() + index,
                    secondary_landlord: entry.secondary_landlord.id,
                    shareholding_percentage: entry.shareholding_percentage,
                    secondary_landlord_no_of_units: entry.secondary_landlord_no_of_units,
                })));
            }
        }

        propertyList();
        getLandlords();
    }, [landlordUpdate]);


    useEffect(() => {
        getUnits(formData.property);
    }, [formData.property]);


    const getAvailableLandlords = (currentIndex) => {
        const selectedIds = entries.map((entry, index) => (index !== currentIndex ? entry.secondary_landlord : null)).filter(id => id);
        return landlordData.filter(landlord => !selectedIds.includes(landlord.id));
    };


    const handleEntryChange = useCallback((event, index) => {
        const { name, value } = event.target;
        setEntries(prevEntries => {
            const updatedEntries = [...prevEntries];
            updatedEntries[index] = { ...updatedEntries[index], [name]: value };
            return updatedEntries;
        });
    }, []);





    const addField = () => {
        setFields([...fields, { id: Date.now(), unit: "" }]);
    };

    const handleChange = (event, fieldId) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === "unit") {
            const updatedFields = fields.map((field) =>
                field.id === fieldId ? { ...field, [name]: value } : field
            );
            setFields(updatedFields);
        }
    };



    const addEntry = () => {
        setEntries(prevEntries => [...prevEntries, { id: Date.now(), secondary_landlord: '', shareholding_percentage: '' }]);
    };



    const getAvailableUnits = (currentUnitId) => {
        const selectedUnitIds = fields
            .map((field) => field.unit)
            .filter((id) => id);
        return unitData.filter(
            (unit) => !selectedUnitIds.includes(unit.id) || unit.id === currentUnitId
        );
    };



    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Landlord Association
                </Typography>
            </Box>
            <Grid container spacing={2} mb={3}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Property</Typography>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                            name="property"
                            value={formData.property}
                            onChange={handleChange}
                        >
                            {propertyData.map((property) => (
                                <MenuItem key={property.id} value={property.id}>
                                    {`${property.ref_id} - ${property.title}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2} mb={3}>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Primary Landlord</Typography>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                            name="primary_landlord"
                            value={formData.primary_landlord}
                            onChange={handleChange}
                            displayEmpty
                            required
                        >
                            <MenuItem value="" disabled>
                                Select your Primary landlord
                            </MenuItem>
                            {landlordData.map((landlord) => (
                                <MenuItem key={landlord.id} value={landlord.id}>
                                    {landlord.first_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >
                        Shareholding Percentage
                    </Typography>
                    <TextField
                        sx={newIncomeTextfield}
                        fullWidth
                        variant="outlined"
                        placeholder='Enter the Shareholding Percentage'
                        name="primary_landlord_share_percentage"
                        value={formData.primary_landlord_share_percentage}
                        onChange={handleChange}

                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >
                        No of Units
                    </Typography>
                    <TextField
                        sx={newIncomeTextfield}
                        fullWidth
                        variant="outlined"
                        placeholder='Enter the No of unit'
                        name="primary_landlord_no_of_units"
                        value={formData.primary_landlord_no_of_units}
                        onChange={handleChange}

                    />
                </Grid>
            </Grid>

            {entries.map((entry, index) => (
                <Grid container spacing={2} key={entry.id}>
                    <Grid item xs={12} md={4} mt={1}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >
                            Secondary Landlord
                        </Typography>
                        <FormControl fullWidth sx={newIncomeTextfield}>
                            <Select
                                name="secondary_landlord"
                                value={entry.secondary_landlord}
                                onChange={(event) => handleEntryChange(event, index)}
                            >
                                {getAvailableLandlords(index).map((landlord) => (
                                    <MenuItem key={landlord.id} value={landlord.id}>
                                        {landlord.first_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} mt={1}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >
                            Shareholding Percentage
                        </Typography>
                        <TextField
                            sx={newIncomeTextfield}
                            fullWidth
                            variant="outlined"
                            name="shareholding_percentage"
                            placeholder='Enter the Shareholding Percentage'
                            value={entry.shareholding_percentage}
                            onChange={(event) => handleEntryChange(event, index)}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} mt={1}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >
                            No Of Units
                        </Typography>
                        <TextField
                            sx={newIncomeTextfield}
                            fullWidth
                            variant="outlined"
                            placeholder='Enter the No of unit'
                            name="secondary_landlord_no_of_units"
                            value={entry.secondary_landlord_no_of_units}
                            onChange={(event) => handleEntryChange(event, index)}

                        />
                    </Grid>

                </Grid>
            ))}
            <IconButton variant="contained" color="primary" onClick={addEntry} sx={{ color: "#B81918" }}>
                <AddCircleOutlineIcon />
            </IconButton>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "20px 0px 15px 0px"
                        }}
                    >Unit</Typography>
                    {fields.map((field, index) => (
                        <Grid container spacing={2} key={field.id}>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={newIncomeTextfield}>
                                    <Select
                                        name="unit"
                                        value={field.unit}
                                        onChange={(e) => handleChange(e, field.id)}
                                        displayEmpty
                                        required
                                    >
                                        <MenuItem value="" disabled>
                                            Select Unit
                                        </MenuItem>
                                        {getAvailableUnits(field.unit).map((unit) => (
                                            <MenuItem key={unit.id} value={unit.id}>
                                                {`${unit.ref_id} - ${unit.title }`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {fields.length - 1 === index && (
                                    <IconButton
                                        onClick={addField}
                                        style={{ color: "#B81918", marginTop: 3 }}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', margin: '20px 0 50px 0' }}>
                <Button
                    style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                    }}
                    variant="contained"
                    onClick={updateUser}
                >
                    Update
                </Button>
            </Box>
        </>
    );
};

export default LandlordInfoUpdate;






