import React from "react";
import { Box, Button, Modal } from "@mui/material";
import { useStyles } from "../../Client/ClientStyle";
import axios from "axios";
import toast from "react-hot-toast";
import { endpoints } from "../../../../apiEndpoints";
import { useNavigate } from "react-router-dom";






const DeleteProperty = ({ onClose, id, }) => {
    const classes = useStyles();
    const navigate = useNavigate();

   
    const handleBack = () => {
        navigate(`/admin/selection/property`);
    }

    const DeleteProperty = async () => {

        try {
            const token = localStorage.getItem("token");
            const requestOptions = {
                headers: {
                    Authorization: `token ${token}`,
                },
            };

            const response = await axios.delete(
                `${endpoints.DeleteProperty}?id=${id}`,
                requestOptions
            );
            console.log("this is delete response", response.data);
            toast.success(response.data.message);
            onClose();
            handleBack();
        } catch (error) {
            console.log("this error in deleting", error);
            toast.error(error.response.data.message);
        }
    };


    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="popup-modal-title"
            aria-describedby="popup-modal-description"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                className={classes.Poproot}
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    backgroundColor: "#fff",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
            >
                <img className={classes.img} src="/image/Group.png" alt="popup image" />
                <h6 className={classes.heading}>Are You Sure, You want to delete?</h6>
                <Box className={classes.btns}>
                    <Button
                        variant="outlined"
                        style={{
                            border: "1px solid rgba(154, 25, 21, 1)",
                            color: "rgba(154, 25, 21, 1)",
                            textTransform: "none",
                            width: "90px",
                            height: "30px",
                        }}
                        onClick={onClose}
                    >
                        Skip
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            textTransform: "none",
                            margin: "0px 0px 0px 10px",
                            width: "90px",
                            height: "30px",
                        }}
                        onClick={DeleteProperty}
                    >
                        Yes
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteProperty;
