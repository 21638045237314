import React from "react";
import { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Grid, } from "@mui/material";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import UnitHistory from "./UnitHistory";
import UnitTenanthistory from './UnitTenanthistory';
import UnitStatus from './UnitStatus';
import { useLocation } from "react-router-dom";
import { endpoints } from "../../../../apiEndpoints";
import axios from 'axios';
import toast from "react-hot-toast";
import { PropertyDetailenums } from '../../../../enums/UserTypes';
import DeletePopup from "./DeletePopup";
import EditUnit from "./EditUnit";





const SecondTable = [
    {
        id: "0",
        name: "Document Name 1",
        status: "Received"
    },
    {
        id: "1",
        name: "Document Name 1",
        status: "Not-Received"
    },
    {
        id: "2",
        name: "Document Name 1",
        status: "Received"
    },
    {
        id: "3",
        name: "Document Name 1",
        status: "Not-Received"
    },
]


const UnitDetail = () => {

    const location = useLocation();
    const { state } = location;

    const { id, image, address } = state || {};
    const [UHistory, setUHistory] = useState(false);
    const [THistory, setTHistory] = useState(false);
    const [Ustatus, setUstatus] = useState(false);
    const [UnitData, setUnitData] = useState([]);
    const [Uedit, setUedit] = useState(false);
    const [showPopup, setShowPopup] = useState(false);


    const getUnitdetail = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.Unitdetail}?id=${id}`, {
                headers: { Authorization: `Token ${token}` }
            });
            setUnitData(response.data.data);
        } catch (error) {

        }
    };

    useEffect(() => {
        getUnitdetail();
    }, [])


    const tenantAssociation = UnitData?.tenant_association || [];


    const getStatusText = (status) => {
        return PropertyDetailenums[status] || "Unknown Status";
    };

    const handleUhistory = () => {
        setUHistory(true)
    }
    if (UHistory) {
        return <UnitHistory id={id} />
    }

    const handleThistory = () => {
        setTHistory(true)
    }
    if (THistory) {
        return <UnitTenanthistory id={id} />
    }

    const handleUstatus = () => {
        setUstatus(true);
    }
    if (Ustatus) {
        return <UnitStatus />
    }


    const handleDelete = () => {
        setShowPopup(true);
    }

    const handleEdit = () => {
        setUedit(true)
    }
    if (Uedit) {
        return <EditUnit id={id} />
    }

    const handleClosePopup = () => {
        setShowPopup(false);
    };
    return (
        <>
            {showPopup && <DeletePopup onClose={handleClosePopup} id={id} />}
            <Grid container >
                <Grid item xs={12} md={5} alignItems="center">
                    <Box>
                        <Box >
                            <img src={image} alt="this image is not show" width={140} />
                        </Box>
                        <Box sx={{ display: "flex", margin: "10px 0px 0px 5px", color: "rgba(184, 25, 24, 1)" }}>
                            <LocationOnRoundedIcon style={{ width: "20px" }} />
                            <Typography style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "16px",
                                margin: "0px 0px 0px 10px"
                            }}>
                                {address}
                            </Typography>
                        </Box>
                        <Typography style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "12px",
                            margin: "0px 0px 0px 50px"
                        }}>
                            {`  No of Bedrooms : ${UnitData?.bedrooms || '-'}`}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontWeight: 500,
                        fontSize: "28px",
                        lineHeight: "35px",
                        margin: "60px 0px 0px 0px"
                    }}>
                        Landlords Name
                    </Typography>
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontWeight: 400,
                        fontSize: "20px",
                        lineHeight: "37px",
                        margin: "20px 0px 0px 30px"
                    }}>
                        Ameer Al Habibi
                    </Typography>
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontWeight: 400,
                        fontSize: "20px",
                        lineHeight: "37px",
                        margin: "0px 0px 0px 30px"
                    }}>
                        Muzammil Sami
                    </Typography>

                </Grid>
            </Grid>


            <Grid container spacing={2} mt={2} >
                <Grid item xs={12} md={2}>
                    <Button sx={{
                        border: "1px solid rgba(184, 25, 24, 1)",
                        color: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                    }}
                        variant="outlined"
                        onClick={handleThistory}
                    >
                        Tenant History
                    </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button sx={{
                        border: "1px solid rgba(184, 25, 24, 1)",
                        color: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                    }}
                        variant="outlined"
                        onClick={handleUhistory}
                    >
                        Unit History
                    </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button sx={{
                        border: "1px solid rgba(184, 25, 24, 1)",
                        color: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                    }}
                        // variant="outlined"
                        onClick={handleDelete}
                    >
                        Delete Unit
                    </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button sx={{
                        border: "1px solid rgba(184, 25, 24, 1)",
                        color: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                    }}
                        // variant="outlined"
                        onClick={handleEdit}
                    >
                        Edit Unit
                    </Button>
                </Grid>
            </Grid>

            {/* <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "24px",
                    margin: "30px 0px 30px 0px"
                }}>
                    Documents
                </Typography>
            </Box>
            <Box style={{ overflowX: 'auto' }} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                            <TableCell style={{ padding: "10px 0px 10px 20px", borderRight: "1px solid #e0e0e0" }}>Document Name</TableCell>
                            <TableCell style={{ padding: "10px 0px 10px 20px", borderRight: "1px solid #e0e0e0" }}>Document Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {SecondTable.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell style={{ padding: "10px 0px 10px 20px" }}>{row.name}</TableCell>
                                <TableCell style={{ padding: "10px 0px 10px 40px", color: row.status === 'Received' ? 'rgba(0, 227, 23, 1)' : 'rgba(184, 25, 24, 1)' }}>{row.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box> */}

            {/* Start Third Table */}
            <Box style={{ overflowX: 'auto', marginTop: 50 }}>
                <Table aria-label="tenant table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                            <TableCell style={{ padding: "10px" }}>Tenant Name</TableCell>
                            <TableCell style={{ padding: "10px" }}>Emirates Id</TableCell>
                            <TableCell style={{ padding: "10px" }}>Unit Number</TableCell>
                            <TableCell style={{ padding: "10px" }}>Rent</TableCell>
                            <TableCell style={{ padding: "10px" }}>Renting Since</TableCell>
                            <TableCell style={{ padding: "10px" }}>Next Rent Due</TableCell>
                            <TableCell style={{ padding: "10px" }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tenantAssociation.length > 0 ? (
                            tenantAssociation.flatMap((item, index) =>
                                item.unit.map((unit, unitIndex) => (
                                    <TableRow key={`${index}-${unitIndex}`}>
                                        <TableCell style={{ padding: "10px" }}>{item.tenant?.first_name}</TableCell>
                                        <TableCell style={{ padding: "10px" }}>{item.tenant?.emirates_id}</TableCell>
                                        <TableCell style={{ padding: "10px" }}>{unit.ref_id}</TableCell>
                                        <TableCell style={{ padding: "10px" }}>{item.rent}</TableCell>
                                        <TableCell style={{ padding: "10px" }}>{item.renting_since}</TableCell>
                                        <TableCell style={{ padding: "10px" }}>{item.upcoming_rent_due}</TableCell>
                                        <TableCell style={{ padding: "10px" }}
                                            sx={{
                                                color:
                                                    item.association_status === "1"
                                                        ? "#B81918"
                                                        : item.association_status === "2"
                                                            ? "#000000BF"
                                                            : "",
                                            }}
                                        >{getStatusText(item.association_status)}</TableCell>
                                    </TableRow>
                                ))
                            )
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7} style={{ textAlign: "center" }}>No Data Available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>



        </>
    )
}

export default UnitDetail;