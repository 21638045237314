import React from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    chart: {
      '&:focus, & svg:focus, & svg *:focus': {
        outline: 'none !important',
        border: 'none !important',
      },
     
     
    },
    calendarBox: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "1rem",
    },
    gridBox: {
      padding: "2rem",
      borderRadius: "10px",
      // boxShadow: "1.5px 1.5px 0.8px 0.8px rgba(0, 0, 0, 0.5)",
      boxShadow: '4px 4px 8px 0px #0000000A'
      

    },
    mainBox: {
      marginTop: "1rem",
      display: "flex",
      gap: "1rem",
      [theme.breakpoints.up("xs")]: {
        flexDirection: "column",
      },
      [theme.breakpoints.up("md")]: {
        flexDirection: "column",
      },
    },
    pdcBox: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
      },
      [theme.breakpoints.up("xs")]: {
        gap: "0",
      },
      [theme.breakpoints.up("sm")]: {
        gap: "1rem",
      },
      // flexDirection: "column",
      // justifyContent: "space-between",
      // gap: "1rem",
      // border: "1px solid red",
      // [theme.breakpoints.up("sm")]: {
      //   width: "50%",
      // },

      // [theme.breakpoints.down("md")]: {
      //   width: "100%",
      // },
    },
    notificationBox: {
      border: "1px solid #EFEFEF",
      borderRadius: "6px",
      padding: "1rem",
      maxHeight: "43.2rem",
      overflowX: "auto",
      // [theme.breakpoints.up("sm")]: {
      //   width: "50%",
      // },

      // [theme.breakpoints.down("md")]: {
      //   width: "100%",
      // },
      width: "100%",
      marginBottom: "1rem",
    },
    pdcSubBox: {
      border: "1px solid #EFEFEF",
      borderRadius: "6px",
      marginBottom: "1rem",
      padding: "1rem",
      [theme.breakpoints.up("md")]: {
        width: "50%",
      },

      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    notificationSubBox: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
    },
    notificationGridItem: {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      border: "1px solid #EAECF0",
      borderRadius: "6px",
      padding: "0.5rem",
    },
  };
});

export const mainTypography = {
  fontFamily: "Outfit",
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "24px",
};

export const calendarTypography = {
  fontFamily: "Outfit",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: "500",
  color: "#737679",
  cursor: "pointer",
};

export const selectedCalendarTypography = {
  fontFamily: "Outfit",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: "700",
  color: "#B81918",
};

export const gridItemText = {
  fontFamily: "Outfit",
  fontSize: "24px",
  lineHeight: "20px",
  fontWeight: "400",
};

export const gridItemNumbers = {
  fontFamily: "Outfit",
  fontSize: "28px",
  fontWeight: "600",
  color: "#B81918",
};

export const boxHeading = {
  fontFamily: "Outfit",
  fontSize: "20px",
  fontWeight: "600",
  lineHeight: "20px",
};

export const boxSubHeading = {
  fontFamily: "Outfit",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "23px",
  cursor:'pointer'
};

export const barBox = {
  width: "90rem",
  height: "40rem",
};
