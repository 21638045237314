import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  dropDown,
  mainTypography,
  newIncomeTextfield,
  saveBtn,
  subTypography,
  useStyles,
} from "./IncomeStyles";
import { endpoints } from "../../../apiEndpoints";
import axios from "axios";
import { UserTypesNum } from "../../../enums/UserTypes";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";

function CreateNew() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [propertyList, setPropertyList] = useState([]);
  const [subPropertiesList, setSubPropertiesList] = useState([]);
  const [landlordList, setLandlordList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [data, setData] = useState({
    property: "",
    landlord: "",
    subProperty: "",
    unit: "",
    recievedFrom: "",
    method: "",
    date: "",
    category: "",
    subCategory: "",
    amount: "",
    description: "",
    notes: "",
  });
  const [loading,setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const createIncome = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("property", data.property);
      formData.append("sub_property", data.subProperty);
      formData.append("unit", data.unit);
      formData.append("landlord", data.landlord);
      formData.append("received_from", data.recievedFrom);
      formData.append("method", data.method);
      formData.append("date", data.date);
      formData.append("category", data.category);
      formData.append("sub_category", data.subCategory);
      formData.append("amount", data.amount);
      formData.append("description", data.description);
      formData.append("notes", data.notes);

      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        endpoints.financeIncome,
        formData,
        requestOptions
      );
      toast.success(response.data.message);
      if(response.status === 200){
      navigate("/finance/income");

      }
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false)
    }finally{
      setLoading(false)

    }
  };

  const fetchData = async (endpoint, setState) => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(endpoint, requestOptions);
      setState(response.data.data);
    } catch (error) {}
  };

  const fetchProperties = async () => {
    fetchData(endpoints.getPropertiesListing, setPropertyList);
  };

  const fetchSubProperties = async () => {
    fetchData(endpoints.subPropertiesList, setSubPropertiesList);
  };

  const fetchLanldlords = async () => {
    fetchData(
      `${endpoints.getUserListing}?user_type=${UserTypesNum.landlord}`,
      setLandlordList
    );
  };
  const fetchUnits = async () => {
    fetchData(endpoints.getUnitsListing, setUnitList);
  };
  useEffect(() => {
    fetchProperties();
    fetchSubProperties();
    fetchLanldlords();
    fetchUnits();
  }, []);
  return (
    <>
      <Box>
        <Typography sx={mainTypography}>Create New Income</Typography>
      </Box>
      <Box marginTop={"5rem"}>
        <Typography sx={subTypography}>Details</Typography>
      </Box>
      {loading ? (<Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>):(
      <Grid container marginTop={"2.5rem"} spacing={2} marginBottom={"1rem"}>
        {/* Property */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Property</Typography>
            <FormControl fullWidth sx={newIncomeTextfield}>
              <Select
                value={data.property || ""}
                name="property"
                onChange={handleChange}
                displayEmpty
                required
              >
                <MenuItem value="">Select Property</MenuItem>
                {propertyList?.map((property) => (
                  <MenuItem key={property.id} value={property.id}>
                    {`${property.ref_id} - ${property.title}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {/* Landlord Name */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Landlord Name</Typography>
            <FormControl fullWidth sx={newIncomeTextfield}>
              <Select
                value={data.landlord || ""}
                name="landlord"
                onChange={handleChange}
                displayEmpty
                required
              >
                <MenuItem value="">Select Landlord</MenuItem>
                {landlordList?.map((landlord) => (
                  <MenuItem key={landlord.id} value={landlord.id}>
                    {landlord.first_name || "N/A"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <TextField
              placeholder={"Name"}
              name="name"
              value={data.name}
              onChange={handleChange}
              sx={newIncomeTextfield}
            /> */}
          </Box>
        </Grid>
        {/* Sub-Property Id */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Sub-Property </Typography>
            <FormControl fullWidth sx={newIncomeTextfield}>
              <Select
                name="subProperty"
                value={data.subProperty || ""}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="">Select Sub-Property</MenuItem>
                {subPropertiesList?.map((subProperty) => (
                  <MenuItem key={subProperty.id} value={subProperty.id}>
                    {subProperty.ref_id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {/* Unit   */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Unit</Typography>
            <FormControl fullWidth sx={newIncomeTextfield}>
              <Select
                value={data.unit || ""}
                name="unit"
                onChange={handleChange}
                displayEmpty
                required
              >
                <MenuItem value="">Select Unit</MenuItem>
                {unitList?.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {`${unit.ref_id} - ${unit.title}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <TextField
              placeholder={"Name"}
              name="name"
              value={data.name}
              onChange={handleChange}
              sx={newIncomeTextfield}
            /> */}
          </Box>
        </Grid>
        {/* Recieved Form */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Recieved Form</Typography>


            <TextField
              placeholder={"Any name"}
              name="recievedFrom"
              value={data.recievedFrom}
              onChange={handleChange}
              sx={newIncomeTextfield}
            />
          </Box>
        </Grid>
        {/* Method */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Method</Typography>
            <FormControl fullWidth sx={newIncomeTextfield}>
              <Select
                name="method"
                value={data.method || ""}
                onChange={handleChange}
                displayEmpty
                required
              >
                <MenuItem value="">Select Method</MenuItem>
                <MenuItem value={"Bank"}>Bank</MenuItem>
                <MenuItem value={"Cash"}>Cash</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {/* Date */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Date</Typography>
            <TextField
              name="date"
              value={data.date}
              onChange={handleChange}
              type="date"
              sx={newIncomeTextfield}
            />
          </Box>
        </Grid>
        {/* Category */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Category</Typography>

            <TextField
              placeholder={"Category"}
              name="category"
              value={data.category}
              onChange={handleChange}
              sx={newIncomeTextfield}
            />
          </Box>
        </Grid>
        {/* Sub Category */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Sub Category</Typography>
            <TextField
              placeholder={"Sub Category"}
              name="subCategory"
              value={data.subCategory}
              onChange={handleChange}
              sx={newIncomeTextfield}
            />
          </Box>
        </Grid>
        {/* Amount */}
        <Grid item xs={12} sm={5}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Amount</Typography>
            <TextField
              placeholder={"Any Amount"}
              name="amount"
              value={data.amount}
              onChange={handleChange}
              sx={newIncomeTextfield}
              required
            />
          </Box>
        </Grid>
        {/* Description */}
        <Grid item xs={12} sm={10}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Description</Typography>
            <TextField
              placeholder={"Any Details/Description"}
              name="description"
              value={data.description}
              onChange={handleChange}
              sx={newIncomeTextfield}
              multiline
              rows={5}
              fullWidth
            />
          </Box>
        </Grid>
        {/* Notes */}
        <Grid item xs={12} sm={10}>
          <Box className={classes.gridItemBox}>
            <Typography sx={subTypography}>Notes</Typography>
            <TextField
              placeholder={"Any Notes"}
              name="notes"
              value={data.notes}
              onChange={handleChange}
              sx={newIncomeTextfield}
              multiline
              rows={5}
              fullWidth
            />
          </Box>
        </Grid>
        {/* Save Button */}
        <Grid item xs={6} sm={2.5}>
          <Button sx={saveBtn} fullWidth onClick={createIncome}>
            Save
          </Button>
        </Grid>
      </Grid>
      )}
    </>
  );
}

export default CreateNew;
