import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedType: "",
  file: null,
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    updateSelectedType(state, action) {
      state.selectedType = action.payload;
    },
    setFile(state, action) {
      state.file = action.payload;
    },
  },
});
export const { updateSelectedType, setFile } = documentSlice.actions;
export default documentSlice.reducer;
