import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddDetails from "./AddDetails";
import AddLocation from "./AddLocation";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateFeatureData } from '../../../../Redux/AdminReducer/formsSlice';




const AddFeatures = () => {
    const dispatch = useDispatch();
    const [open, setopen] = useState(false);
    const [backcom, setbackcom] = useState(false);
    const [checked, setChecked] = useState('none');
    const featureData = useSelector(state => state.form.featureData);
    const [localData, setLocalData] = useState(featureData);


    useEffect(() => {
        setLocalData(featureData);
    }, [featureData]);


    const handleInputChange = (event, label) => {
        const { checked } = event.target;
        setLocalData(prevData => ({
            ...prevData,
            [label]: checked
        }));
    };

    const labels = [
        'Covered Parking', 'Visitor Parking', 'Bicycle Racks / Storage', 'Car Wash Station', '1 Car Parking',
        '2 Car Parking', '3 Car Parking', '4 Car Parking', '5 Car Parking', '6 Car Parking', '7 Car Parking',
        '8 Car Parking', '9 Car Parking', '10 Car Parking'

    ];

    // const labe = [
    //     'Central AC', 'Split AC', "Window AC", "VRV/VRF System (Variable Refrigerant Volume/Flow)",
    //     "Individual Climate Control in Rooms", "Smart Thermostats", "Zoned Air Conditioning"
    // ]
    // const Hlabel = [
    //     'Fresh Air Ventilation', 'Exhaust Fans (kitchen, bathroom)', "Air Purifiers", "Humidity Control System",
    //     "Window Ventilation System", "Mechanical Ventilation with Heat Recovery (MVHR)",
    // ]


    const handleBack = () => {
        dispatch(updateFeatureData(localData));
        setbackcom(true)
    }
    if (backcom) {
        return (
            <AddDetails />
        )
    }


    const handleNext = () => {
        dispatch(updateFeatureData(localData));
        setopen(true)
    }
    if (open) {
        return (
            <AddLocation selectedFeatures={localData} />
        )
    }


    const checkboxes = labels.map((label, index) => ({
        id: `checkbox-${index + 1}`,
        label: label
    }));

    // const checkbo = labe.map((label, index) => ({
    //     id: `checkbox-${index + 1}`,
    //     label: label
    // }));
    // const heating = Hlabel.map((label, index) => ({
    //     id: `checkbox-${index + 1}`,
    //     label: label
    // }));

    return (
        <>

            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Add Property
                </Typography>
            </Box>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Features
                </Typography>
            </Box>




            {/* <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "18px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 10px 0px"
                }}>
                    Air Conditioning
                </Typography>
                <Grid container >
                    {checkbo.map((checkbox) => (
                        <Grid item xs={4} sm={4} md={3} key={checkbox.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={localData[checkbox.label] || false}
                                        onChange={(e) => handleInputChange(e, checkbox.label)}
                                        name={checkbox.id}
                                    />
                                }
                                label={checkbox.label}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box> */}





            {/* 
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "18px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "10px 0px 10px 0px"
                }}>
                    Heating Systems
                </Typography>
                <Grid container >
                    {heating.map((checkbox) => (
                        <Grid item xs={4} sm={4} md={3} key={checkbox.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={localData[checkbox.label] || false}
                                        onChange={(e) => handleInputChange(e, checkbox.label)}
                                        name={checkbox.id}
                                    />
                                }
                                label={checkbox.label}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box> */}

            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "18px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "10px 0px 10px 0px"
                }}>
                    Parking Spaces
                </Typography>
                <Grid container >
                    {checkboxes.map((checkbox) => (
                        <Grid item xs={4} sm={4} md={3} key={checkbox.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={localData[checkbox.label] || false}
                                        onChange={(e) => handleInputChange(e, checkbox.label)}
                                        name={checkbox.id}
                                    />
                                }
                                label={checkbox.label}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>

















            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                <Button style={{
                    border: "1px solid rgba(154, 25, 21, 1)",
                    color: "rgba(154, 25, 21, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 0px 50px 0px"
                }}
                    variant="outlined"
                    onClick={handleBack}

                >Back</Button>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 0px"

                    }}
                        variant="contained"
                        onClick={handleNext}
                    >Next</Button>
                </Box>
            </Box>
        </>
    )

}

export default AddFeatures;

