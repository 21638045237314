import React from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  InfoTypography,
} from "../../../../AdminComponent/Pages/Maintenance/MaintenanceStyles";


const TenantData = [
  {
    id: "1",
    No: "01",
    name: "Mr. Michael Ryan",
    Apartment: "305",
  },
  {
    id: "2",
    No: "02",
    name: "Mr. Michael Ryan",
    Apartment: "307",
  },
  {
    id: "3",
    No: "03",
    name: "Mr. Michael Ryan",
    Apartment: "304",
  },
  {
    id: "4",
    No: "04",
    name: "Mr. Michael Ryan",
    Apartment: "305",
  },
];

const TenantsDetail = ({ tenantDetails, mainProperty }) => {
  return (
    <>
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        Tenants Detail
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12} md={6} alignItems="center">
          <Box>
            <Box sx={{ display: "flex" }}>
              <img
                src={
                  mainProperty?.attachments &&
                    mainProperty.attachments.length > 0
                    ? mainProperty?.attachments[0].media
                    : "No image available"
                }
                onError={(e) => (e.target.src = "/image/propertyicon.png")}
                // alt="this image is not show"
                width={450}
                height={250}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "35px",
              margin: "40px 0px 0px 0px",
            }}
          >
            {mainProperty?.title || "Title not available"}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              margin: "10px 0px 0px 0px",
            }}
          >
            {mainProperty?.description || "Description not available"}
          </Typography>
        </Grid>
      </Grid>

      <Box style={{ margin: "25px 0px 0px 0px" }}>
        <Divider />
      </Box>

      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
          margin: "20px 0px 10px 0px",
        }}
      >
        Tenants
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <Table
              aria-label="simple table"
              sx={{
                overflowX: "auto",
                minWidth: "40rem",
                border: "1px solid #EFEFEF",
              }}
            >
              <TableHead>
                <TableRow
                  style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
                >
                  <TableCell>No.</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Units</TableCell>
                  {/* <TableCell>Apartment Number</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {tenantDetails?.tenants && tenantDetails?.tenants.length > 0 ? (
                  tenantDetails?.tenants?.map((tenant, index) => (
                    <TableRow key={tenant.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{tenant.tenant.first_name || "-"}</TableCell>
                      <TableCell>{tenant.tenant.email || "-"}</TableCell>
                      <TableCell>
                        {tenant.unit?.map((item) => item.ref_id).join(", ") ||
                          "-"}
                      </TableCell>
                    </TableRow>
                  ))) : (
                  <InfoTypography>No Complaints</InfoTypography>
                )}
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </>
  );
};

export default TenantsDetail;
