import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';


export const useStyles = makeStyles(() => {
    const theme = useTheme();
    return {
        graph: {
            backgroundColor: "rgb(255,255,255)",
            border: "1px solid rgba(173, 173, 173, 1)",
            borderRadius: "5.8px",
            // boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)',
            margin: "25px 0px 0px 0px",
            padding: "0px 0px 0px 0px",
             height: "380px"
            
        },
        graph3: {
            backgroundColor: "rgb(255,255,255)",
            border: "1px solid rgba(173, 173, 173, 1)",
            borderRadius: "5.8px",
            // boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)',
            margin: "25px 0px 0px 0px",
            padding: "0px 0px 0px 0px",
            
            
        },
        graph2: {
            backgroundColor: "rgb(255,255,255)",
            border: "1px solid rgba(173, 173, 173, 1)",
            borderRadius: "5.8px",
            // boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)',
            margin: "25px 0px 0px 0px",
            padding: "0px 0px 0px 0px",
            height: "380px"
        },
        option: {
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            margin: "10px 0px 0px 0px",
            padding: "0px 22px 0px 22px"
        },
        tab: {
            border: "1px solid rgba(239, 239, 239, 1)",
            borderRadius: "8px",
            margin: "5px 0px 0px 0px"


        },
        boxone: {
            border: "1px solid rgba(239, 239, 239, 1)",
            margin: "5px 20px 0px 0px",
            borderRadius: "6px"
        }


    };
});
export const calendarTypography = {
    fontFamily: "Outfit",
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: "500",
    color: "#737679",
    cursor: "pointer",
};

export const selectedCalendarTypography = {
    fontFamily: "Outfit",
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: "700",
    color: "#B81918",
};
