import { Grid, Box, Typography, IconButton, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./PropertiesStyles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import ContractTermination from "./ContractTermination";

function PropertyView() {
  const classes = useStyles();
  const location = useLocation();
  const { property } = location.state;
  const [termination, setTermination] = useState(false);
  const [propertyDetail, setPropertyDetail] = useState(null);
  const boxData = [
    {
      icon: <HomeOutlinedIcon />,
      detail: `Rent: ${property.tenant_association.rent ?? "----"} AED/Month`,
    },
    {
      icon: <CalendarTodayOutlinedIcon />,
      detail: `Renting since : ${
        property.tenant_association.renting_since ?? "----"
      }`,
    },
    // {
    //   icon: <CalendarTodayOutlinedIcon />,
    //   detail: `Last Rent Paid : ${
    //     property.tenant_association.created_at.split("T")[0] ?? "----"
    //   }`,
    // },
    {
      icon: <CalendarTodayOutlinedIcon />,
      detail: `Next Rent Due : ${
        property.tenant_association.upcoming_rent_due ?? "----"
      }`,
    },
  ];

  const actionBoxData = [
    { id: 1, detail: "View Rental Agreement" },
    { id: 2, detail: "View Pending Actions" },
    { id: 3, detail: "View PDCs" },
    // { id: 4, detail: "View Paid Invoices" },
    { id: 5, detail: "View Complains" },
  ];

  const navigate = useNavigate();
  const handleClick = (item) => {
    // Generate path based on item detail and id
    let path = "";
    switch (item.detail) {
      case "View Rental Agreement":
        path = `/tenant/properties/rental-agreement?id=${item.id}`;
        break;
      case "View Pending Actions":
        path = `/tenant/properties/pending-actions?id=${item.id}`;
        break;
      case "View PDCs":
        path = `/tenant/properties/pdcs?id=${item.id}`;
        break;
      // case "View Paid Invoices":
      //   path = `/tenant/paid-invoices?id=${item.id}`;
      //   break;
      case "View Complains":
        path = `/tenant/properties/complaints?id=${item.id}`;
        break;
      default:
        break;
    }
    navigate(path, { state: { details: propertyDetail, property: property } });
  };

  const getPropertyDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        `${endpoints.tenantPropertyDetails}?id=${property.id}`,
        requestOptions
      );
      setPropertyDetail(response.data.data);
    } catch {}
  };

  useEffect(() => {
    getPropertyDetails();
  }, []);

  return (
    <>
      {termination ? (
        <ContractTermination property={propertyDetail} />
      ) : (
        <>
          <Grid
            display={"flex"}
            flexDirection={{ xs: "column", sm: "row", md: "row" }}
            alignItems={"center"}
            gap={"2rem"}
          >
            <Box className={classes.propertyViewBox}>
              <Box className={classes.imgBox}>
                <img
                  // src="/image/proimage1.jpg"
                  src={property?.attachments[0]?.media || ""}
                  alt="Property Image"
                  onError={(e) => (e.target.src = "/image/propertyicon.png")}
                  className={classes.propertyViewImg}
                />
              </Box>
              <Box className={classes.locationBox}>
                <LocationOnIcon sx={{ color: "#B81918" }} />
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "#B81918",
                  }}
                >
                  {property?.address || "Not available"}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.appartmentBox}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "10px",
                    color: "#212121BF",
                  }}
                >
                  {property?.tenant_association?.unit
                    ?.map((item) => item.ref_id)
                    .join(", ") || "units not available"}
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: "50px",
                    color: "#B81918",
                    borderBottom: "1px solid #21212140",
                  }}
                >
                  {property.address}
                </Typography>
                {boxData.map((item, index) => (
                  <Box className={classes.rentBox} key={index}>
                    <IconButton className={classes.rentBoxICon} size="large">
                      {item.icon}
                    </IconButton>
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "15px",
                        fontWeight: "500",
                        lineHeight: "18px",
                        color: "#B81918",
                      }}
                    >
                      {item.detail}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid>
            <Box className={classes.actionBox}>
              {actionBoxData.map((item, index) => (
                <Box key={index} className={classes.viewBox}>
                  <Typography
                    sx={{
                      fontFamily: "Outfit",
                      fontWeight: "500",
                      fontSize: "18x",
                      lineHeight: "18px",
                    }}
                  >
                    {item.detail}
                  </Typography>
                  <Button
                    sx={{
                      backgroundColor: "#B81918",
                      color: "#FFFFFF",
                      fontFamily: "Outfit",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "12px",
                      borderRadius: "4px",
                      textTransform: "unset",
                      "&:hover": {
                        backgroundColor: "#B81918", // Same color as default to "unset" hover
                        boxShadow: "none", // Remove any shadow on hover
                      },
                    }}
                    onClick={() => handleClick(item)}
                  >
                    View
                  </Button>
                </Box>
              ))}
            </Box>
          </Grid>
          <Box
            marginTop={"1rem"}
            marginBottom={"1rem"}
            display={"flex"}
            justifyContent={{ xs: "center", sm: "center", md: "start" }}
          >
            <Button
              sx={{
                width: { xs: "11rem", sm: "11rem", md: "11rem" },
                height: "2.5rem",
                backgroundColor: "#B81918",
                color: "#FFFFFF",
                fontFamily: "Outfit",
                fontSize: "15px",
                lineHeight: "16px",
                fontWeight: "500",
                borderRadius: "0.5rem",
                textTransform: "unset",
                "&:hover": {
                  backgroundColor: "#B81918", // Same color as default to "unset" hover
                  boxShadow: "none", // Remove any shadow on hover
                },
              }}
              onClick={() => {
                setTermination(true);
              }}
            >
              Request Termination
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

export default PropertyView;
