import {
  Box,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  CustomTextField,
  aplyfilter,
  mainTypography,
  tableBodyCell,
  tableHeadCell,
  useStyles,
} from "./TenantStyles";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import { Modal, TableCell as MuiTableCell } from "@mui/material";
import { PropertyType } from '../../../enums/UserTypes';



const TenantDetails = () => {
  const classes = useStyles();
  const [tenantData, settenantData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [cashFlowUnits, setCashFlowUnits] = useState(null);
  const [searchValues, setSearchValues] = useState({
    firstSearch: "",
    secondSearch: "",
  });

  const [rows, setRows] = useState([
    { name: "Landlord", email: "Rayan@uae.com", unit: "305" },
    { name: "Landlord", email: "Rayan@uae.com", unit: "305" },
    { name: "Landlord", email: "Rayan@uae.com", unit: "305" },
    { name: "Landlord", email: "Rayan@uae.com", unit: "305" },
    { name: "Landlord", email: "Rayan@uae.com", unit: "305" },
    { name: "Landlord", email: "Rayan@uae.com", unit: "305" },
    { name: "Landlord", email: "Rayan@uae.com", unit: "305" },
    { name: "Landlord", email: "Rayan@uae.com", unit: "305" },
    { name: "Landlord", email: "Rayan@uae.com", unit: "305" },
  ]);

  const navigate = useNavigate();

  const handleSearchChange = (name, value) => {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };




  const GetTenantData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${endpoints.financeTenantlisting}`, {
        headers: { Authorization: `Token ${token}` }
      });
      const result = response.data.data;
      settenantData(result);

    } catch (error) {
      console.log("Error fetching user data", error);

    }
  };

  useEffect(() => {
    GetTenantData();
  }, []);



  const handleNavigate = (request) => {
    navigate("/finance/edit-tenant", {
      state: {
        id: request.id,
        ...request,
      },
    });
  };

  const handleOpen = (units) => {
    setCashFlowUnits(units);
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);

  return (
    <>
      <Box>
        <Typography sx={mainTypography}>Tenant</Typography>
      </Box>
      <Box className={classes.searchFieldBox}>
        <TextField
          variant="outlined"
          placeholder="Search by Date Range"
          value={searchValues.firstSearch}
          onChange={(e) => handleSearchChange("firstSearch", e.target.value)}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={CustomTextField}
        />
        <TextField
          variant="outlined"
          placeholder="Search by Installment"
          value={searchValues.secondSearch}
          onChange={(e) => handleSearchChange("secondSearch", e.target.value)}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={CustomTextField}
        />
        <Button sx={aplyfilter}>Apply Filter</Button>
      </Box>
      <Box marginTop={"2rem"}>
        <TableContainer sx={{ height: "75vh", overflowX: "auto" }}>
          <Table sx={{ border: "1px solid #EFEFEF" }}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeadCell}>Tenant Name</TableCell>
                <TableCell sx={tableHeadCell}>Email</TableCell>
                <TableCell sx={tableHeadCell}>Unit No</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tenantData?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={tableBodyCell} onClick={() => { handleNavigate(item) }}>
                    {item.first_name}
                  </TableCell>
                  <TableCell sx={tableBodyCell}>{item.email}</TableCell>
                  <TableCell sx={tableBodyCell}>
                    {(item.contract?.unit && item.contract?.unit.length > 0 && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <span>{item.contract?.unit[0].ref_id}</span>
                        <Tooltip title="View All Units" arrow>
                          <ArrowDropDownCircleOutlinedIcon
                            onClick={() => handleOpen(item.contract?.unit)}
                            sx={{ marginLeft: "1rem" }}
                          />
                        </Tooltip>
                      </Box>
                    )) ||
                      "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>






      <Modal open={modalOpen} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "80%",
            maxWidth: "600px",
          }}
        >
          <Typography sx={mainTypography} gutterBottom>
            Unit Information
          </Typography>
          <Table sx={{ border: "1px solid #EFEFEF" }}>
            <TableHead>
              <TableRow>
                <MuiTableCell sx={tableHeadCell}>Unit Number</MuiTableCell>
                <MuiTableCell sx={tableHeadCell}>Unit Type</MuiTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cashFlowUnits?.length > 0 ? (
                cashFlowUnits?.map((unit) => (
                  <TableRow key={unit.id}>
                    <MuiTableCell sx={tableBodyCell}>
                      {unit.ref_id}
                    </MuiTableCell>
                    <MuiTableCell sx={tableBodyCell}>
                      {unit.type == PropertyType.RESIDENTIAL
                        ? "Residential"
                        : PropertyType.COMMERCIAL
                          ? "Commercial"
                          : PropertyType.COMMUNITY
                            ? "Community"
                            : "-"}
                    </MuiTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <MuiTableCell colSpan={2}>
                    No units available
                  </MuiTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Button
            onClick={handleClose}
            variant="contained"
            color="success"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>








    </>
  );
}

export default TenantDetails;
