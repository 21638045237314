import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./PropertiesStyles";
function Complaints() {
  const classes = useStyles();
  const location = useLocation();
  const { details } = location.state || {};
  const requests = [
    { reqName: "Complaint Request Name", reqTime: "15-Mar-2024", id: "1" },
    { reqName: "Complaint Request Name", reqTime: "15-Mar-2024", id: "2" },
    { reqName: "Complaint Request Name", reqTime: "15-Mar-2024", id: "3" },
    { reqName: "Complaint Request Name", reqTime: "15-Mar-2024", id: "4" },
    { reqName: "Complaint Request Name", reqTime: "15-Mar-2024", id: "5" },
  ];
  const navigate = useNavigate();

  return (
    <>
      <Box marginBottom={"2rem"}>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "24px",
            color: "#000000",
          }}
        >
          Complaints
        </Typography>
      </Box>

      <Grid container xs={12} spacing={1} className={classes.mainGrid}>
        {/* {details?.complaints?.map((complaint) => (
          <Grid item key={complaint.id} xs={12} className={classes.gridItem}>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: { xs: "15px", sm: "15px", md: "20px" },
                }}
              >
                {complaint?.created_at.split("T")[0] || "Creation date here"}
              </Typography>
            </Box>
            <Box
              className={classes.infoBox}
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/tenant/complaint-request", {
                  state: { complaintDetail: complaint },
                })
              }
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: { xs: "15px", sm: "15px", md: "20px" },
                  cursor: "pointer",
                }}
              >
                {complaint?.first_name}
              </Typography>
            </Box>
          </Grid>
        ))} */}
        {details?.complaints && details.complaints.length > 0 ? (
          details.complaints.map((complaint) => (
            <Grid item key={complaint.id} xs={12} className={classes.gridItem}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontWeight: "500",
                    fontSize: { xs: "15px", sm: "15px", md: "20px" },
                  }}
                >
                  {complaint?.created_at.split("T")[0] || "Creation date here"}
                </Typography>
              </Box>
              <Box
                className={classes.infoBox}
                onClick={() =>
                  navigate("/tenant/complaint-request", {
                    state: { complaintDetail: complaint },
                  })
                }
              >
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontWeight: "500",
                    fontSize: { xs: "15px", sm: "15px", md: "20px" },
                    cursor: "pointer",
                  }}
                >
                  {complaint?.first_name}
                </Typography>
              </Box>
            </Grid>
          ))
        ) : (
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontWeight: "500",
              fontSize: { xs: "15px", sm: "15px", md: "20px" },
              color: "red",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            No complaints available.
          </Typography>
        )}
      </Grid>
    </>
  );
}

export default Complaints;
