// old firebase account


// firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged as firebaseOnAuthStateChanged,
} from "firebase/auth"; // Import auth functions
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  query,
  doc,
  where,
  orderBy,
  onSnapshot,
  getDocs,
} from "firebase/firestore"; // Import Firestore functions
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAv0XxJtmDjvDABRt9r1Ll0L_wk1b1X6Ls",
  authDomain: "website-chat-8916d.firebaseapp.com",
  projectId: "website-chat-8916d",
  storageBucket: "website-chat-8916d.appspot.com",
  messagingSenderId: "1069170450276",
  appId: "1:1069170450276:web:19644b57849dc15279a471",
  measurementId: "G-XWF6V82GK0",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export {
  auth,
  firestore,
  storage,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  firebaseSignOut as signOut,
  firebaseOnAuthStateChanged as onAuthStateChanged,
  collection,
  addDoc,
  query,
  where,
  updateDoc,
  orderBy,
  onSnapshot,
  getDocs,
  doc,
  ref,
  uploadBytes,
  getDownloadURL,
};










// // firebase.js
// import { initializeApp } from "firebase/app";
// import {
//   getAuth,
//   signInWithEmailAndPassword,
//   createUserWithEmailAndPassword,
//   signOut as firebaseSignOut,
//   onAuthStateChanged as firebaseOnAuthStateChanged,
// } from "firebase/auth"; // Import auth functions
// import {
//   getFirestore,
//   collection,
//   addDoc,
//   updateDoc,
//   query,
//   doc,
//   where,
//   orderBy,
//   onSnapshot,
//   getDocs,
// } from "firebase/firestore"; // Import Firestore functions
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";


// const firebaseConfig = {
//   apiKey: "AIzaSyBqzGYcrsYKRLE8zWz2EJUw3IyaKrvFUfM",
//   authDomain: "capstone-ccc26.firebaseapp.com",
//   projectId: "capstone-ccc26",
//   storageBucket: "capstone-ccc26.firebasestorage.app",
//   messagingSenderId: "897855988716",
//   appId: "1:897855988716:web:da51fdd30a3252335716c9",
//   measurementId: "G-8MXJ4Q6HGV"
// };


// const app = initializeApp(firebaseConfig);

// const auth = getAuth(app);
// const firestore = getFirestore(app);
// const storage = getStorage(app);

// export {
//   auth,
//   firestore,
//   storage,
//   signInWithEmailAndPassword,
//   createUserWithEmailAndPassword,
//   firebaseSignOut as signOut,
//   firebaseOnAuthStateChanged as onAuthStateChanged,
//   collection,
//   addDoc,
//   query,
//   where,
//   updateDoc,
//   orderBy,
//   onSnapshot,
//   getDocs,
//   doc,
//   ref,
//   uploadBytes,
//   getDownloadURL,
// };


