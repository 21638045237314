import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Box, Typography, Grid, Avatar, CircularProgress } from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import { useStyles } from "./PropertyStyle";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { Loader } from "../../../utils/Loader";








const PropertyTable = () => {
    const classes = useStyles();
    const [monthlyRevenueData, setMonthlyRevenueData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState({
        revenue_filter: "",

    });


    const getPropertyAnalytics = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.landlordPropertyAnalytics}?revenue_filter=${selectedValue.revenue_filter}`, {
                headers: { Authorization: `Token ${token}` }
            });

            const data = response.data.data;

            const revenueData = Object.entries(data.monthly_revenue).map(([name, revenues]) => ({
                Name: name,
                ...revenues.reduce((acc, { month, revenue }) => {
                    acc[month] = `${revenue.toLocaleString()} AED`;
                    return acc;
                }, {})
            }));
            setMonthlyRevenueData(revenueData);
        } catch (error) {

        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        getPropertyAnalytics();
    }, [selectedValue.revenue_filter]);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSelectedValue((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    return (
        <>

            <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={12} md={12}>
                    <Box className={classes.grap}>
                        <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                            <Typography style={{
                                fontFamily: "Outfit",
                                fontSize: "20px",
                                lineHeight: "20px",
                                fontWeight: 500,
                                color: "#000000",
                                margin: "0px 0px 20px 0px"
                            }}>
                                Revenue From Properties <span
                                    style={{
                                        fontFamily: "Outfit",
                                        fontSize: "20px",
                                        lineHeight: "20px",
                                        fontWeight: 300,
                                        color: "#000000",
                                    }}
                                >
                                    (AED)
                                </span>
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControl fullWidth>
                                    <Select
                                        name="revenue_filter"
                                        value={selectedValue.revenue_filter}
                                        onChange={handleInputChange}
                                        displayEmpty
                                        required
                                        style={{
                                            height: "35px",
                                            backgroundColor: "#EFEFEF",
                                            fontFamily: "Outfit",
                                            borderRadius: "5px",
                                            margin: "-8px 0px 20px 45px",
                                        }}
                                    >
                                        <MenuItem value="">
                                            All
                                        </MenuItem>
                                        <MenuItem value='quarter'>
                                            Quarter
                                        </MenuItem>
                                        <MenuItem value='half_year'>
                                            Half_year
                                        </MenuItem>
                                        <MenuItem value='year'>Year</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>


                        {loading ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="50vh"
                            >
                                <Loader />
                            </Box>
                        ) : monthlyRevenueData.length === 0 ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="50vh"
                            >
                                <Typography>No data available</Typography>
                            </Box>
                        ) : (

                            <Box style={{ overflowX: 'auto' }}>
                                <Table aria-label="property revenue table">
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Jan</TableCell>
                                            <TableCell>Feb</TableCell>
                                            <TableCell>Mar</TableCell>
                                            <TableCell>Apr</TableCell>
                                            <TableCell>May</TableCell>
                                            <TableCell>Jun</TableCell>
                                            <TableCell>Jul</TableCell>
                                            <TableCell>Aug</TableCell>
                                            <TableCell>Sep</TableCell>
                                            <TableCell>Oct</TableCell>
                                            <TableCell>Nov</TableCell>
                                            <TableCell>Dec</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {monthlyRevenueData.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                                        {/* <Avatar alt={row.Name} src="/image/unimage1.jpg" /> */}
                                                        <Typography style={{ marginLeft: "10px" }}>{row.Name}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{row.Jan || "N/A"}</TableCell>
                                                <TableCell>{row.Feb || "N/A"}</TableCell>
                                                <TableCell>{row.Mar || "N/A"}</TableCell>
                                                <TableCell>{row.Apr || "N/A"}</TableCell>
                                                <TableCell>{row.May || "N/A"}</TableCell>
                                                <TableCell>{row.Jun || "N/A"}</TableCell>
                                                <TableCell>{row.Jul || "N/A"}</TableCell>
                                                <TableCell>{row.Aug || "N/A"}</TableCell>
                                                <TableCell>{row.Sep || "N/A"}</TableCell>
                                                <TableCell>{row.Oct || "N/A"}</TableCell>
                                                <TableCell>{row.Nov || "N/A"}</TableCell>
                                                <TableCell>{row.Dec || "N/A"}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>

        </>
    );
}

export default PropertyTable;
