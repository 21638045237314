import React from "react";
import { useState, useEffect } from "react";
import { Typography, Grid, Box, Divider, Button } from "@mui/material";
import {
  useStyles,
  calendarTypography,
  selectedCalendarTypography,
} from "./DashboardStyle";
import Tab from "./Tab";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { useNavigate } from "react-router-dom";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false, // Hide the legend (dataset labels)
    },
  },
  scales: {
    x: {
      display: true, // Hide the x-axis labels (month labels)
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 10000,
        callback: function (value) {
          return value > 0 ? value / 10000 + "k" : ""; // Display nothing for 0, format the rest
        },
      },
    },
  },
};

const Dashboard = () => {
  const classes = useStyles();
  const lines = ["Month", "Year","Half year"];
  const [selected, setSelected] = useState(options[0]);
  const [DashboardData, setDashboardData] = useState([]);
  const navigate = useNavigate();

  const getDashboardData = async () => {
    try {
      let filter;
      selected === "Month"
        ? (filter = "month")
        : selected === "Half year"
        ? (filter = "half_year")
        : (filter = "year");
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endpoints.landlordDashboard}?filter=${filter}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      const data = response.data.data;
      setDashboardData(data);
    } catch (error) {}
  };

  useEffect(() => {
    getDashboardData();
  }, [selected]);

  // To select Quarter, Half Year, Yearly
  const handleSelect = (type) => {
    setSelected(type);
  };

  const totalincome =
    DashboardData?.monthly_income_expense?.map((item) => item.income) || [];
  const totalExpenses =
    DashboardData?.monthly_income_expense?.map((item) => item.expenses) || [];
  const Month =
    DashboardData?.monthly_income_expense?.map((item) => item.month) || [];

  const data = {
    labels: Month,
    datasets: [
      {
        label: "expenses",
        data: totalExpenses,
        backgroundColor: "rgba(173, 173, 173, 1)",
      },
      {
        label: "income",
        data: totalincome,
        backgroundColor: "rgba(184, 25, 24, 1)",
      },
    ],
  };

  return (
    <>
      <Typography
        style={{
          fontFamily: "Outfit",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        Dashboard
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={8} md={8}>
          <Box className={classes.graph}>
            <Box>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "24px",
                  fontWeight: 600,
                  color: "rgba(0, 0, 0, 1)",
                  margin: "20px 0px 0px 0px",
                  padding: "0px 0px 0px 22px",
                }}
              >
                Property Revenue Overview
              </Typography>
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} sm={7}>
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "18px",
                      lineHeight: "18px",
                      fontWeight: 500,
                      color: "#101828",
                      margin: "10px 0px 0px 20px",
                    }}
                  >
                    Showing overview from Jan 2023 - Dec 2024
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Box className={classes.option}>
                    {lines.map((item) => (
                      <Typography
                        key={item}
                        sx={{
                          ...calendarTypography,
                          ...(selected === item && selectedCalendarTypography),
                        }}
                        onClick={() => handleSelect(item)}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ margin: "10px 0px 10px 0px" }}>
              <Divider />
            </Box>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={8} md={8}>
                <Box sx={{ margin: "30px 0px 0px 0px", width: "100%" }}>
                  <Bar data={data} options={options} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Box className={classes.boxone}>
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "16px",
                      lineHeight: "16px",
                      fontWeight: 700,
                      margin: "40px 0px 0px 10px",
                    }}
                  >
                    Income
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "28px",
                      lineHeight: "28px",
                      fontWeight: 700,
                      color: "rgba(184, 25, 24, 1)",
                      margin: "0px 0px 35px 10px",
                    }}
                  >
                    {DashboardData.total_rent_received}
                  </Typography>
                </Box>
                {/* Second Box */}

                <Box
                  className={classes.boxone}
                  style={{ margin: "20px 20px 20px 0px" }}
                >
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "16px",
                      lineHeight: "16px",
                      fontWeight: 700,
                      margin: "40px 0px 0px 10px",
                    }}
                  >
                    Expenses
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "28px",
                      lineHeight: "28px",
                      fontWeight: 700,
                      color: "rgba(173, 173, 173, 1)",
                      margin: "0px 0px 35px 10px",
                    }}
                  >
                    {DashboardData.total_expenses}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* Last Box */}
          </Box>
        </Grid>

        {/* usedByDevices  */}
        <Grid item xs={12} sm={4} md={4}>
          <Box className={classes.graph2}>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box style={{ display: "flex", padding: "15px 0px 15px 25px" }}>
                <img src="/image/house.png"></img>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "2px 0px 0px 15px",
                  }}
                >
                  {DashboardData.total}
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "18px",
                      lineHeight: "18px",
                      fontWeight: 500,
                      color: "rgba(0, 0, 0, 1)",
                    }}
                  >
                    Properties
                  </Typography>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "12px",
                    lineHeight: "12px",
                    fontWeight: 600,
                    color: "rgba(0, 0, 0, 1)",
                    textTransform: "none",
                    margin: "0px 10px 0px 0px",
                  }}
                  onClick={() => {
                    navigate("/landlord/properties");
                  }}
                >
                  All properties
                </Button>
              </Box>
            </Box>
            <Box style={{ margin: "0px 0px 10px 0px" }}>
              <Divider />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "29px",
                  fontWeight: 600,
                  color: "rgba(115, 118, 121, 1)",
                  margin: "40px 0px 40px 35px",
                }}
              >
                Occupied Properties
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "32px",
                    lineHeight: "37px",
                    fontWeight: 600,
                    color: "rgba(184, 25, 24, 1)",
                    margin: "0px 40px 0px 0px",
                  }}
                >
                  {DashboardData.occupied}
                </Typography>
              </Box>
            </Box>
            <Box style={{ margin: "0px 0px 10px 0px" }}>
              <Divider />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "29px",
                  fontWeight: 600,
                  color: "rgba(115, 118, 121, 1)",
                  margin: "40px 0px 40px 30px",
                }}
              >
                Vacant Properties
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "32px",
                    lineHeight: "37px",
                    fontWeight: 600,
                    color: "rgba(184, 25, 24, 1)",
                    margin: "0px 60px 0px 0px",
                  }}
                >
                  {DashboardData.vacant}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Tab />
    </>
  );
};

export default Dashboard;
