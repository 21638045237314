import React, { useState } from "react";
import Propertie from "./Propertie/Property";
import Units from "./Units/Units";
import { Box, Typography, Button } from "@mui/material";
import LensOutlinedIcon from '@mui/icons-material/LensOutlined';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import {
    MainHeading,
    MainBox
} from "./PropertiesStyle";
import { Link } from 'react-router-dom';




const useStyles = makeStyles(() => {
    const theme = useTheme();
    console.log(theme);

    return {

        gridContainer: {
            display: 'grid',
            placeItems: 'center',
            width: 'calc(100% - 20px)',
            maxWidth: '320px',
            border: '1px solid rgba(115, 118, 121, 1)',
            borderRadius: '10px',
            margin: '15px auto',
            boxSizing: 'border-box',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                maxWidth: 'none',
            },
        },
    }
});

const Selection = () => {
    const classes = useStyles();


    const [Opencom, setOpencom] = useState(false);


    const handleunitsubmit = () => {
        setOpencom(true)
    }
    if (Opencom) {
        return (<Units />)
    }
    return (
        <>
            <Box>
                <MainHeading>
                    Select Property / Unit
                </MainHeading>
            </Box>
            <MainBox>
                <Box className={classes.gridContainer}>
                    <Link to="/admin/selection/property" style={{ textDecoration: 'none' }}>
                        <Button
                            variant="text"
                            style={{
                                color: "rgba(115, 118, 121, 1)",
                                fontFamily: "Outfit",
                                textTransform: "none",
                                fontSize: "24px",
                                padding: "30px 0px 30px 15px"
                            }}
                            endIcon={
                                <LensOutlinedIcon
                                    sx={{
                                        marginLeft: '110px',
                                        color: "rgba(115, 118, 121, 1)"
                                    }}
                                />
                            }
                        >
                            Properties
                        </Button>
                    </Link>
                </Box>
                <Box className={classes.gridContainer}>
                    <Link to="/admin/selection/unit" style={{ textDecoration: 'none' }}>
                        <Button
                            variant="text"
                            style={{
                                color: "rgba(115, 118, 121, 1)",
                                fontFamily: "Outfit",
                                textTransform: "none",
                                fontSize: "24px",
                                padding: "30px 0px 30px 15px"
                            }}
                            endIcon={
                                <LensOutlinedIcon
                                    sx={{
                                        marginLeft: '170px',
                                        color: "rgba(115, 118, 121, 1)"
                                    }}
                                />
                            }

                        >
                            Units
                        </Button>
                    </Link>
                </Box>
            </MainBox>
        </>
    )
}

export default Selection;













// import React, { useState } from 'react';
// import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

// const CascadingDropdowns = () => {
//   const [firstValue, setFirstValue] = useState('');
//   const [secondValue, setSecondValue] = useState('');
//   const [thirdValue, setThirdValue] = useState('');

//   const firstOptions = ['Residential', 'Commercial', 'Option 3'];
//   const secondOptions = {
//     'Residential': ['Apartments', 'Townhouses', 'Villa', 'Residential Land'],
//     'Commercial': ['Office', 'Restaurant', 'Cloud Kitchen', 'Gym', 'Retail Store',
//       'Supermarket', 'Baqala', 'Warehouse', 'Recording Studio', 'Hotel', 'Hotel Apartments',
//       'Clinic', 'Hospital', 'Other Commercial Space'
//     ],
//     'Option 3': ['Option 3-1', 'Option 3-2']
//   };
//   const thirdOptions = {
//     'Apartments': ['Studio Apartment', '1 Bedroom Apartment', '2 Bedroom Apartment', '3 Bedroom Apartment',
//       '4 Bedroom Apartment', '5 Bedroom Apartment', '6 Bedroom Apartment', '7 Bedroom Apartment'],
//     'Townhouses': ['1 Bedroom Townhouse', '2 Bedroom Townhouse', '3 Bedroom Townhouse', '4 Bedroom Townhouse',
//       '5 Bedroom Townhouse', '6 Bedroom Townhouse', '7 Bedroom Townhouse', '8 Bedroom Townhouse', '9 Bedroom Townhouse',
//       '10 Bedroom Townhouse'],
//     'Villa': ['2 Bedroom Villa', '3 Bedroom Villa', '4 Bedroom Villa', '5 Bedroom Villa', '6 Bedroom Villa',
//       '7 Bedroom Villa', '8 Bedroom Villa', "9 Bedroom Villa", '10 Bedroom Villa', '11 Bedroom Villa',
//       '12 Bedroom Villa', '13 Bedroom Villa', '14 Bedroom Villa', '15 Bedroom Villa'
//     ],
//     'Option 2-1': ['Final 3', 'Final 4'],
//     'Option 3-1': ['Final 5', 'Final 6']
//   };

//   const handleFirstChange = (event) => {
//     setFirstValue(event.target.value);
//     setSecondValue('');
//     setThirdValue('');
//   };

//   const handleSecondChange = (event) => {
//     setSecondValue(event.target.value);
//     setThirdValue('');
//   };

//   const handleThirdChange = (event) => {
//     setThirdValue(event.target.value);
//   };

//   return (
//     <div>
//       <FormControl fullWidth>

//         <Select
//           value={firstValue}
//           onChange={handleFirstChange}
//         >
//           {firstOptions.map((option) => (
//             <MenuItem key={option} value={option}>{option}</MenuItem>

//           ))}
//         </Select>
//       </FormControl>
//       {firstValue && (
//         <FormControl fullWidth style={{ marginTop: 20 }}>

//           <Select
//             value={secondValue}
//             onChange={handleSecondChange}
//           >
//             {secondOptions[firstValue]?.map((option) => (
//               <MenuItem key={option} value={option}>{option}</MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       )}

//       {secondValue && (
//         <FormControl fullWidth style={{ marginTop: 20 }}>

//           <Select
//             value={thirdValue}

//             onChange={handleThirdChange}
//           >
//             {thirdOptions[secondValue]?.map((option) => (
//               <MenuItem key={option} value={option}>{option}</MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       )}
//     </div>
//   );
// };

// export default CascadingDropdowns;
