import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  feedBack: {},
};

const complaintFeedbackSlice = createSlice({
  name: "complaintFeedback",
  initialState,
  reducers: {
    updateFeedBack(state, action) {
      state.feedBack = action.payload;
    },
  },
});

export const { updateFeedBack } = complaintFeedbackSlice.actions;
export default complaintFeedbackSlice.reducer;
