import React from "react";
import { TextField, Button, Grid, IconButton, Select, MenuItem, FormControl, } from '@mui/material';
import { Typography, Box } from "@mui/material";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Propertie from './Property';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    updatePropertyData, updateDetailData, updateFloorData, addFloorField, uploadFloorImage
    , updateLocationData, updatePrivateData, addAdditionalField, updateAdditionalField, resetFormData
} from '../../../../Redux/AdminReducer/formsSlice';
import { useStyles, newIncomeTextfield, } from './PropertieStyle';
import { endpoints } from '../../../../apiEndpoints';
import axios from "axios";
import Popup from "./Popup";
import { PropertyType, PropertyStatus } from '../../../../enums/UserTypes';
import toast from "react-hot-toast";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";




const ViewProperty = () => {

    const dispatch = useDispatch();
    const propertyData = useSelector(state => state.form.propertyData);
    const mediaData = useSelector(state => state.form.mediaData);
    const uploadedImages = useSelector(state => state.form.uploadedImages)
    const detailData = useSelector(state => state.form.detailData);
    const fields = useSelector(state => state.form.additionalFields);
    const locationData = useSelector(state => state.form.locationData);
    const floorData = useSelector(state => state.form.floorData);
    const images = useSelector(state => state.form.images);
    const featureData = useSelector(state => state.form.featureData);
    const privateData = useSelector(state => state.form.privateData);
    const uploadedDocuments = useSelector(state => state.form.uploadedDocuments);
    const [editable, setEditable] = useState(false);
    const [backcom, setbackcom] = useState(false);

    const [checkedItems, setCheckedItems] = useState({});

    const [localPropertyData, setLocalPropertyData] = useState(propertyData);
    const [localDetailData, setLocalDetailData] = useState(detailData);
    const [localNoteData, setLocalNoteData] = useState(privateData);
    const [localLocationData, setLocalLocationData] = useState(locationData);
    const [localMediaData, setlocalMediaData] = useState(mediaData);
    const [showEditButton, setShowEditButton] = useState(false);
    const [showPopup, setShowPopup] = useState(false);



    const [firstValue, setFirstValue] = useState('');
    const [secondValue, setSecondValue] = useState('');
    const [thirdValue, setThirdValue] = useState('');


    const handleClosePopup = () => {
        setShowPopup(false);
    };




    useEffect(() => {
        setLocalPropertyData(propertyData);
        if (propertyData.type) {
            setFirstValue(propertyData.type);
        }
        if (propertyData.subtype) {
            setSecondValue(propertyData.subtype);
        }
        if (propertyData.detailedType) {
            setThirdValue(propertyData.detailedType);
        }
    }, [propertyData]);

    useEffect(() => {
        setLocalDetailData(detailData);
    }, [detailData]);
    useEffect(() => {
        setLocalNoteData(privateData);
    }, [privateData]);
    useEffect(() => {
        setLocalLocationData(locationData);
    }, [locationData]);
    useEffect(() => {
        setlocalMediaData(mediaData);
    }, [mediaData]);


    const handleInputChange = (dataSet, event) => {
        const { name, value } = event.target;
        if (dataSet === 'Property') {
            setLocalPropertyData(prevData => ({
                ...prevData,
                [name]: value
            }));
        } else if (dataSet === 'detail') {
            setLocalDetailData(prevData => ({
                ...prevData,
                [name]: value
            }));

        } else if (dataSet === 'note') {
            setLocalNoteData(prevData => ({
                ...prevData,
                [name]: value
            }));
        } else if (dataSet === 'location') {
            setLocalLocationData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleEditClick = () => {
        setEditable(true);
        setShowEditButton(true);

    };

    const handleBack = () => {
        dispatch(updatePropertyData(localPropertyData));
        dispatch(updateDetailData(localDetailData));
        dispatch(updateLocationData(localLocationData));
        dispatch(updatePrivateData(localNoteData));
        setbackcom(true)
    }
    if (backcom) {
        return (
            <Propertie />
        )
    }


    //Addition Detail
    const handleChange = (index, e) => {
        const { name, value } = e.target;
        dispatch(updateAdditionalField({ index, data: { [name]: value } }));
    };

    const handleAddField = () => {
        dispatch(addAdditionalField({ title: '', value: '' }));
    };

    ///Add Floor Data
    const handleInputChangefloor = (id, event) => {
        const { name, value } = event.target;
        const updatedFields = floorData.map(field =>
            field.id === id ? { ...field, [name]: value } : field
        );
        dispatch(updateFloorData(updatedFields));
    };

    const handleUploadImage = (id, event) => {
        const file = event.target.files[0];
        if (file) {
            dispatch(uploadFloorImage({ id, file }));
        }
    };

    const handleAddFloorField = () => {
        dispatch(addFloorField());
    };




    const handleFirstChange = (event) => {
        const value = event.target.value;
        setFirstValue(value);
        setSecondValue('');
        setThirdValue('');

        // Map string values to their corresponding integer values
        const typeMapping = {
            'Residential': 1,
            'Commercial': 2,
            'Community': 3,
        };

        const updatedData = { 
            ...localPropertyData, 
            type: typeMapping[value], // Use the mapped integer value
            subtype: '', 
            detailedType: '' 
        };
        setLocalPropertyData(updatedData);
        dispatch(updatePropertyData(updatedData));
    };

    const handleSecondChange = (event) => {
        const value = event.target.value;
        setSecondValue(value);
        setThirdValue('');
        const updatedData = { ...localPropertyData, subtype: value, detailedType: '' };
        setLocalPropertyData(updatedData);
        dispatch(updatePropertyData(updatedData));
    };

    const handleThirdChange = (event) => {
        const value = event.target.value;
        setThirdValue(value);
        const updatedData = { ...localPropertyData, detailedType: value };
        setLocalPropertyData(updatedData);
        dispatch(updatePropertyData(updatedData));
    };




    const firstOptions = ['Residential', 'Commercial', 'Labour Camp'];
    const secondOptions = {
        'Residential': ['Apartments', 'Townhouses', 'Villa', 'Residential Land'],
        'Commercial': ['Office', 'Restaurant', 'Cloud Kitchen', 'Gym', 'Retail Store', 'Supermarket', 'Baqala', 'Warehouse', 'Recording Studio', 'Hotel', 'Hotel Apartments', 'Clinic', 'Hospital', 'Other Commercial Space'],

    };
    const thirdOptions = {
        'Apartments': ['Studio Apartment', '1 Bedroom Apartment', '2 Bedroom Apartment', '3 Bedroom Apartment', '4 Bedroom Apartment', '5 Bedroom Apartment', '6 Bedroom Apartment', '7 Bedroom Apartment'],
        'Townhouses': ['1 Bedroom Townhouse', '2 Bedroom Townhouse', '3 Bedroom Townhouse', '4 Bedroom Townhouse', '5 Bedroom Townhouse', '6 Bedroom Townhouse', '7 Bedroom Townhouse', '8 Bedroom Townhouse', '9 Bedroom Townhouse', '10 Bedroom Townhouse'],
        'Villa': ['2 Bedroom Villa', '3 Bedroom Villa', '4 Bedroom Villa', '5 Bedroom Villa', '6 Bedroom Villa', '7 Bedroom Villa', '8 Bedroom Villa', '9 Bedroom Villa', '10 Bedroom Villa', '11 Bedroom Villa'],

    };


    console.log("this is type", localPropertyData.type)
    console.log("this is subtype", localPropertyData.subtype)
    console.log("this is detailtype", localPropertyData.detailedType)



    const handleSave = async () => {
        const formData = new FormData();

        const addFieldToFormData = (formData, key, value) => {
            if (value !== undefined && value !== null && value !== '') {
                formData.append(key, value);
            }
        };

        addFieldToFormData(formData, 'title', localPropertyData.title);
        addFieldToFormData(formData, 'description', localPropertyData.description);
        // addFieldToFormData(formData, 'type', localPropertyData.type);
        // addFieldToFormData(formData, 'type', localPropertyData.subtype);
        addFieldToFormData(formData, 'type', localPropertyData.detailedType);
        addFieldToFormData(formData, 'property_status', localPropertyData.property_status);
        addFieldToFormData(formData, 'currency', localPropertyData.currency);
        addFieldToFormData(formData, 'lease_price', localPropertyData.lease_price);
        addFieldToFormData(formData, 'price', localPropertyData.price);
        addFieldToFormData(formData, 'price2', localPropertyData.price2);
        addFieldToFormData(formData, 'price_prefix', localPropertyData.price_prefix);
        addFieldToFormData(formData, 'bedrooms', localDetailData.bedrooms);
        addFieldToFormData(formData, 'bathrooms', localDetailData.bathrooms);
        addFieldToFormData(formData, 'area_size', localDetailData.area_size);
        addFieldToFormData(formData, 'size_postfix', localDetailData.size_postfix);
        addFieldToFormData(formData, 'garages', localDetailData.garages);
        addFieldToFormData(formData, 'garage_size', localDetailData.garage_size);
        addFieldToFormData(formData, 'build_year', localDetailData.build_year);
        addFieldToFormData(formData, 'address', localLocationData.address);
        addFieldToFormData(formData, 'country', localLocationData.country);
        addFieldToFormData(formData, 'city', localLocationData.city);
        addFieldToFormData(formData, 'postal_code', localLocationData.postal_code);
        addFieldToFormData(formData, 'latitude', localLocationData.latitude);
        addFieldToFormData(formData, 'longitude', localLocationData.longitude);
        addFieldToFormData(formData, 'private_note', localNoteData.note);
        addFieldToFormData(formData, 'media_url', mediaData.media_url);



        formData.append('property_floors', JSON.stringify(floorData));
        formData.append('features', JSON.stringify([featureData]));
        formData.append('property_additional', JSON.stringify(fields));

        Object.values(uploadedImages).forEach(image => {
            formData.append('property_media', image);
        });

        Object.values(uploadedDocuments).forEach(document => {
            formData.append('property_media', document);
        });

        Object.values(images).forEach(image => {
            formData.append('property_floor_media', image);
        });



        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(endpoints.PropertyManage,
                formData,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            dispatch(resetFormData());

        } catch (error) {

        }

        setShowPopup(true);
    };



    return (
        <>
            {showPopup && <Popup onClose={handleClosePopup} />}
            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', margin: "0px 0px 30px 0px" }}>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)"
                }}>
                    View  Property
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        variant="contained"
                        onClick={handleEditClick}

                    >
                        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)", fontSize: "40px" }} />
                    </IconButton>
                </Box>
            </Box>
            {/* Property Data */}
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}

                    >Property Title</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Title of this property'
                        disabled={!editable}
                        name="title"
                        value={localPropertyData.title || ''}
                        onChange={(event) => handleInputChange('Property', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Content</Typography>
                    <TextField fullWidth multiline rows={4} variant="outlined" placeholder='Content details'
                        disabled={!editable}
                        name="description"
                        value={localPropertyData.description || ''}
                        onChange={(event) => handleInputChange('Property', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Type</Typography>
                    {/* <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            name="type"
                            value={localPropertyData.type || ''}
                            onChange={(event) => handleInputChange('Property', event)}
                            displayEmpty
                            required
                            disabled={!editable}
                        >
                            <MenuItem value="" disabled>
                                Select your Type
                            </MenuItem>
                            <MenuItem value={PropertyType.RESIDENTIAL}>Residential</MenuItem>
                            <MenuItem value={PropertyType.COMMERCIAL}>Commercial</MenuItem>
                            <MenuItem value={PropertyType.COMMUNITY}>Community</MenuItem>
                        </Select>
                    </FormControl> */}




                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <Select value={firstValue} onChange={handleFirstChange}
                                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                                    >
                                        {firstOptions.map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {firstValue && (
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth style={{ marginTop: 0 }}>
                                        <Select value={secondValue} onChange={handleSecondChange}
                                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                                        >
                                            {secondOptions[firstValue]?.map((option) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}

                            {secondValue && (
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth style={{ marginTop: 0 }}>
                                        <Select value={thirdValue} onChange={handleThirdChange}
                                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                                        >
                                            {thirdOptions[secondValue]?.map((option) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </div>





















                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Status</Typography>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            name="property_status"
                            value={localPropertyData.property_status || ''}
                            onChange={(event) => handleInputChange('Property', event)}
                            displayEmpty
                            required
                            disabled={!editable}
                        >
                            <MenuItem value="" disabled>
                                Select your Status
                            </MenuItem>
                            <MenuItem value={PropertyStatus.OCCUPIED}>Occupied</MenuItem>
                            <MenuItem value={PropertyStatus.AVAILABALE}>Available</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Label</Typography>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            name="Brand"
                            value={localPropertyData.Brand || ''}
                            onChange={(event) => handleInputChange('Property', event)}
                            displayEmpty

                        >
                            <MenuItem value="" disabled>
                                Select your Brand
                            </MenuItem>
                            <MenuItem value="Penthouse">Penthouse</MenuItem>
                            <MenuItem value="Duplex">Duplex</MenuItem>
                            <MenuItem value="Maisonette">Maisonette</MenuItem>
                            <MenuItem value="Loft">Loft</MenuItem>
                            <MenuItem value="Standalone">Standalone</MenuItem>
                            <MenuItem value="Sea-Facing">Sea-Facing</MenuItem>
                            <MenuItem value="Waterfront">Waterfront</MenuItem>
                            <MenuItem value="Canal-Facing">Canal-Facing</MenuItem>
                            <MenuItem value="City-Facing">City-Facing</MenuItem>
                            <MenuItem value="Eco-Friendly">Eco-Friendly</MenuItem>
                            <MenuItem value="Burj Khalifa View">Burj Khalifa View</MenuItem>
                            <MenuItem value="Ocean View">Ocean View</MenuItem>
                            <MenuItem value="Yacht Dock">Yacht Dock</MenuItem>
                            <MenuItem value="Helipad">Helipad</MenuItem>
                            <MenuItem value="Vehicle Elevator">Vehicle Elevator</MenuItem>
                            <MenuItem value="Private Elevator">Private Elevator</MenuItem>
                        </Select>
                    </FormControl>


                </Grid>
                <Grid item xs={12}>
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontWeight: 400,
                        fontSize: "20px",
                        lineHeight: "20px",
                        margin: "10px 0px 10px 0px"
                    }}>
                        Price
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Currency </Typography>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                        <Select
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            disabled={!editable}
                            name="currency"
                            value={localPropertyData.currency || ''}
                            onChange={(event) => handleInputChange('Property', event)}
                            displayEmpty

                        >
                            <MenuItem value="" disabled>
                                Select your Currency
                            </MenuItem>
                            <MenuItem value='AED'>AED</MenuItem>
                            <MenuItem value='USD'>USD</MenuItem>
                            <MenuItem value='SAR'>SAR</MenuItem>
                        </Select>
                    </FormControl>



                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Sale or Rent Price</Typography>
                    <TextField fullWidth variant="outlined" placeholder='1000'
                        disabled={!editable}
                        name="lease_price"
                        value={localPropertyData.lease_price || ''}
                        onChange={(event) => handleInputChange('Property', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Second Price</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the second price (optional)'
                        disabled={!editable}
                        name="price"
                        value={localPropertyData.price || ''}
                        onChange={(event) => handleInputChange('Property', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >After the price</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the after price'
                        disabled={!editable}
                        name="price2"
                        value={localPropertyData.price2 || ''}
                        onChange={(event) => handleInputChange('Property', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Price Prefix</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the price prefix'
                        disabled={!editable}
                        name="price_prefix"
                        value={localPropertyData.price_prefix || ''}
                        onChange={(event) => handleInputChange('Property', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            {/* Add Media Data */}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Media
                </Typography>
            </Box>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "18px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Images
                </Typography>
            </Box>
            {/* <Grid container >
                {uploadedImages.map((file, index) => (
                    <Grid item xs={6} sm={4} md={3} key={index}>
                        <img src={URL.createObjectURL(file)} alt={`Image ${index}`} style={{ height: "170px", width: "180px" }} />

                    </Grid>
                ))}
            </Grid> */}
            <Grid container >
                {uploadedImages.length > 0 ? (
                    uploadedImages.map((file, index) => (
                        <Grid item xs={6} sm={4} md={3} key={index}>
                            <img src={URL.createObjectURL(file)} alt={`Image ${index}`} style={{ height: "170px", width: "180px" }} />
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            Image not available
                        </div>
                    </Grid>
                )}

            </Grid>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "18px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Video
                </Typography>
            </Box>
            {/* 
            <Grid item xs={12} sm={6} md={2}>
                <iframe
                    width="100%"
                    height="415"
                    src={localMediaData.media_url || ''}
                    title="Video"
                    frameBorder="0"
                    allowFullScreen
                    style={{ borderRadius: "20px" }}
                ></iframe>
            </Grid> */}


            <Grid item xs={12} sm={6} md={2}>
                {localMediaData.media_url ? (
                    <iframe
                        width="100%"
                        height="415"
                        src={localMediaData.media_url}
                        title="Video"
                        frameBorder="0"
                        allowFullScreen
                        style={{ borderRadius: "20px" }}
                    ></iframe>
                ) : (
                    <div style={{ height: "415px", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #ccc", borderRadius: "20px" }}>
                        No video available
                    </div>
                )}
            </Grid>

            {/* Features Data */}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Features
                </Typography>
            </Box>
            {Object.keys(featureData).map((label) => (
                <FormControlLabel
                    key={label}
                    control={<Checkbox checked={featureData[label]} />}
                    label={label}
                />
            ))}

            {/* Detail Data */}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Details
                </Typography>
            </Box>
            <Grid container spacing={2} >
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}

                    >Bedrooms</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the number of bedrooms (only digits)'
                        disabled={!editable}
                        name="bedrooms"
                        value={localDetailData.bedrooms || ''}
                        onChange={(event) => handleInputChange('detail', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Bathrooms</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the number of bedrooms (only digits)'
                        disabled={!editable}
                        name="bathrooms"
                        value={localDetailData.bathrooms || ''}
                        onChange={(event) => handleInputChange('detail', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Area Size</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Property Area Size (only digits)'
                        disabled={!editable}
                        name="area_size"
                        value={localDetailData.area_size || ''}
                        onChange={(event) => handleInputChange('detail', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Size Postfix</Typography>
                    <TextField fullWidth variant="outlined" placeholder='ft* (Sq Ft)'
                        disabled={!editable}
                        name="size_postfix"
                        value={localDetailData.size_postfix || ''}
                        onChange={(event) => handleInputChange('detail', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Garages</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the number of garages (only digits)'
                        disabled={!editable}
                        name="garages"
                        value={localDetailData.garages || ''}
                        onChange={(event) => handleInputChange('detail', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Garages Size</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the Garage size (Ex 200 Sq Ft)'
                        disabled={!editable}
                        name="garage_size"
                        value={localDetailData.garage_size || ''}
                        onChange={(event) => handleInputChange('detail', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Email</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter your Email'
                        disabled={!editable}
                        name="Email"
                        value={localDetailData.Email || ''}
                        onChange={(event) => handleInputChange('detail', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Phone Number</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter your phone number'
                        disabled={!editable}
                        name="phone"
                        value={localDetailData.phone || ''}
                        onChange={(event) => handleInputChange('detail', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Year Build</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Year Build (only digits)'
                        disabled={!editable}
                        name="build_year"
                        value={localDetailData.build_year || ''}
                        onChange={(event) => handleInputChange('detail', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "20px",
                    margin: "10px 0px 10px 0px"
                }}>
                    Additional  Details
                </Typography>
            </Grid>

            {fields.map((field, index) => (
                <Grid container spacing={2} mt={1} >

                    <Grid item xs={12} md={6} key={field.id}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Title</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Eg: Equipment'
                            disabled={!editable}
                            name="title"
                            value={field.title}
                            onChange={(e) => handleChange(index, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Value</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Grill- Gas'
                            disabled={!editable}
                            name="value"
                            value={field.value || ''}
                            onChange={e => handleChange(index, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                </Grid>
            ))}
            {
                showEditButton && (
                    <Grid item xs={12} >
                        <Button style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            margin: "20px 0px 20px 0px"

                        }}
                            variant="contained"
                            onClick={handleAddField}
                        >Add New</Button>
                    </Grid>
                )
            }

            {/* Add Location  */}
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 30px 0px"
                }}>
                    Location
                </Typography>
            </Box>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}

                    >Address</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Address'
                        disabled={!editable}
                        name="address"
                        value={localLocationData.address || ''}
                        onChange={(event) => handleInputChange('location', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Country</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter the Country'
                        disabled={!editable}
                        name="country"
                        value={localLocationData.country || ''}
                        onChange={(event) => handleInputChange('location', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >City</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter City'
                        disabled={!editable}
                        name="city"
                        value={localLocationData.city || ''}
                        onChange={(event) => handleInputChange('location', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Area</Typography>
                    <TextField fullWidth variant="outlined" placeholder='ft* (Sq Ft)'
                        disabled={!editable}
                        name="area"
                        value={localLocationData.area || ''}
                        onChange={(event) => handleInputChange('location', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={12} >
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Postal number/zip</Typography>
                    <TextField fullWidth variant="outlined" placeholder='Enter Postal number/zip'
                        disabled={!editable}
                        name="postal_code"
                        value={localLocationData.postal_code || ''}
                        onChange={(event) => handleInputChange('location', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "20px",
                            lineHeight: "20px",
                            margin: "20px 0px 15px 0px"
                        }}
                    >Map</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <LoadScript googleMapsApiKey="AIzaSyDrNXs4ZCTaKycbB6n9eUMCng9YUhQrNWs">
                        <GoogleMap
                            mapContainerStyle={{
                                width: '100%',
                                height: '400px',
                                borderRadius: '8px'
                            }}
                            center={{
                                lat: parseFloat(localLocationData.latitude) || 25.276987, // Default latitude
                                lng: parseFloat(localLocationData.longitude) || 55.296249 // Default longitude
                            }}
                            zoom={12}
                        >
                            {localLocationData.latitude && localLocationData.longitude && (
                                <Marker
                                    position={{
                                        lat: parseFloat(localLocationData.latitude),
                                        lng: parseFloat(localLocationData.longitude)
                                    }}
                                />
                            )}
                        </GoogleMap>
                    </LoadScript>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Latitude</Typography>
                    <TextField fullWidth variant="outlined" placeholder="25° 16' 37.1532'' N"
                        disabled={!editable}
                        name="latitude"
                        value={localLocationData.latitude || ''}
                        onChange={(event) => handleInputChange('location', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "15px 0px 15px 0px"
                        }}
                    >Longitude</Typography>
                    <TextField fullWidth variant="outlined" placeholder="55° 17' 46.4964'' E"
                        disabled={!editable}
                        name="longitude"
                        value={localLocationData.longitude || ''}
                        onChange={(event) => handleInputChange('location', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            {/* 360 Virtual tour */}
            {/* <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    360 Virtual Tour
                </Typography>
            </Box> */}
            <Grid item xs={12} sm={6} md={2}>
                {/* <iframe
                    width="960"
                    height="415"
                    src="https://www.youtube.com/embed/your-video-id"
                    title="Video"
                    frameBorder="0"
                    allowFullScreen
                    style={{ borderRadius: "20px" }}
                ></iframe> */}
            </Grid>

            {/* Add Floor Data */}


            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Floor Listing
                </Typography>
            </Box>


            {floorData.map((field, index) => (
                <Grid container spacing={2} key={field.id} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={12}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}

                        >Title</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the Plan Title'
                            disabled={!editable}
                            name="title"
                            value={field.title} onChange={(e) => handleInputChangefloor(field.id, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Bedroom</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the number of bedrooms (only digits)'
                            disabled={!editable}
                            name="bedrooms"
                            value={field.bedrooms} onChange={(e) => handleInputChangefloor(field.id, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Bathroom</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the number of bathroom (only digits)'
                            disabled={!editable}
                            name="bathrooms"
                            value={field.bathrooms} onChange={(e) => handleInputChangefloor(field.id, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Price</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the Price'
                            disabled={!editable}
                            name="price"
                            value={field.price} onChange={(e) => handleInputChangefloor(field.id, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Price Postfix</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter the Price Postfix'
                            disabled={!editable}
                            name="price_postfix"
                            value={field.price_postfix} onChange={(e) => handleInputChangefloor(field.id, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Plan Size</Typography>
                        <TextField fullWidth variant="outlined" placeholder='Enter your Plan Size'
                            disabled={!editable}
                            name="plan_size"
                            value={field.plan_size} onChange={(e) => handleInputChangefloor(field.id, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Plan Image</Typography>

                        <input
                            type="file"
                            onChange={(e) => handleUploadImage(index, e)}
                            style={{ width: '100%', padding: '10px 0' }}
                        />
                        {images[index] && (
                            <img src={URL.createObjectURL(images[index])} alt="Floor Plan" width={15} />
                        )}



                    </Grid>
                    <Grid item xs={12} md={12} mb={2}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "18px",
                                margin: "0px 0px 15px 0px"
                            }}
                        >Description</Typography>
                        <TextField fullWidth multiline rows={4} variant="outlined" placeholder='Enter the plan description.'
                            disabled={!editable}
                            name="description"
                            value={field.description} onChange={(e) => handleInputChangefloor(field.id, e)}
                            style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                            sx={newIncomeTextfield}
                        />
                    </Grid>
                </Grid>
            ))}
            {
                showEditButton && (
                    <Box>
                        <Button style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            margin: "20px 0px 20px 0px"

                        }}
                            variant="contained"
                            onClick={handleAddFloorField}

                        >Add New</Button>
                    </Box>
                )
            }
            {/* Add Note Data */}

            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 30px 0px"
                }}>
                    Private Note
                </Typography>
            </Box>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <TextField fullWidth multiline rows={5} variant="outlined" placeholder='Enter note here.'
                        disabled={!editable}
                        name="note"
                        value={localNoteData.note || ''}
                        onChange={(event) => handleInputChange('note', event)}
                        style={{ backgroundColor: "rgba(241, 10, 10, 0.03)" }}
                        sx={newIncomeTextfield}
                    />
                </Grid>
            </Grid>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "30px 0px 30px 0px"
                }}>
                    Signed Agreement
                </Typography>
            </Box>
            <Grid container>
                <Box style={{
                    backgroundColor: "rgba(241, 10, 10, 0.03)",
                    borderRadius: "8px",
                    padding: "25px 25px 25px 25px",
                    margin: "0px 0px 0px 0px"
                }}>
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontWeight: 400,
                        fontSize: "18px",
                        lineHeight: "38px"
                    }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.<br />
                        Tempor incididunt ut labore et dolore magna aliqua.<br />
                        Ut enim ad minim  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea  commodo consequat.<br />
                    </Typography>
                </Box>
            </Grid>
            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                <Button style={{
                    border: "1px solid rgba(154, 25, 21, 1)",
                    color: "rgba(154, 25, 21, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "170px",
                    height: "40px",
                    margin: "20px 0px 50px 15px"
                }}
                    variant="outlined"
                    onClick={handleBack}

                >Back To Listing</Button>
                <Box sx={{ display: 'flex', }}>
                    <Button style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "170px",
                        height: "40px",
                        margin: "20px 0px 50px 0px"

                    }}
                        variant="contained"
                        onClick={handleSave}
                    >Submit Property</Button>
                </Box>
            </Box>

        </>
    )
}

export default ViewProperty;


