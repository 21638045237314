import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    // For Notifications.jsx:
    mainBox: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "10px",
      },
      // flexDirection: { xs: "column", sm: "column", md: "row" },
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "2rem",
    },

    gridItem: {
      border: "1px solid #EFEFEF",
      // paddingLeft: "1rem",
      // paddingRight: "1rem",
      // paddingBottom: "0.5rem",
      // paddingTop: "0.5rem",
      padding: "1rem",
      borderRadius: "1rem",
    },

    notificationBox: {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
    },

    // For NotificationSetting.jsx:
    switchBox: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      marginTop: "3.5rem",
      alignItems: "center",
      marginBottom: "1.5rem",
      gap: "1rem",
      // justifyContent: "space-between",
    },

    itemBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0.5rem",
    },
    setingGridItem: {
      border: "1px solid #EFEFEF",
      borderRadius: "20px",
      padding: "2rem",
    },
  };
});

// For Notifications.jsx:

export const switchStyle = {
  "& .MuiSwitch-switchBase": {
    color: "#FFFFFF", // Customize thumb color when unchecked
    "&.Mui-checked": {
      color: "#FFFFFF", // Customize thumb color when checked
      "& + .MuiSwitch-track": {
        backgroundColor: "#B81918", // Customize track color when checked
      },
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#B81918", // Customize track color when unchecked
  },
};

export const notificationTypography = {
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#1A1F36",
};
export const notificationTimeTypography = {
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#A5ACB8",
};
export const headingTypography = {
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "24px",
  lineHeight: "24px",
};

export const rightHeadingTypography = {
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#B81918",
};

// For NotificationSetting.jsx:
export const notificationSettingHeading = {
  fontFamily: "Outfit",
  fontSize: "24px",
  lineHeight: "24px",
  fontWeight: "500",
};

export const notificationSettingSubHeading = {
  fontFamily: "Outfit",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "500",
  color: "#B81918",
};

export const gridItemTypography = {
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  letter: "0.25px",
};
