import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    // For Maintenance.jsx
    mainBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: { xs: "column", sm: "column", md: "row" },
      marginTop: "2rem",
      gap: "1rem",
    },

    maintenanceComplaintBox: {
      display: "flex",
      gap: "3rem",
      marginTop: "3rem",
      marginBottom: "3rem",
    },

    // for maintenaceRequests.jsx and ComplaintHistory.jsx
    mainGrid: {
      border: "1px solid #EFEFEF",
      borderRadius: "15px",
      maxHeight: "40rem",
      padding: "1rem",
      overflow: "auto",
      marginTop: "2rem",
    },

    gridItem: {
      padding: "2rem",
    },

    infoBox: {
      display: "flex",
      flexDirection: { xs: "column", sm: "row" },

      alignItems: "center",
      border: "1px solid #EFEFEF",
      borderRadius: "15px",
      padding: "1rem",
      marginTop: "0.5rem",
      height: { xs: "5rem", sm: "3.25rem" },
      justifyContent: "space-between",
      gap: "0.5rem",
    },
    btnBox: {
      display: "flex",
      // justifyContent: "center",
      width: "40%",
      gap: "0.5rem",
      alignItems: "center",
    },

    // MaintenanceForm.jsx:
    UserInputBox: {
      display: "flex",
      alignItems: "center",
      border: "1px solid #EFEFEF",
      borderRadius: "0.5rem",
      marginTop: "0.5rem",
      // alignItems: "center",
      padding: "0.5rem",
      // width: "98%",
    },

    // For Feedbaack.jsx
    // used mainGrid defined for ComplaintHistory.jsx
    // used infoBox defined for ComplaintHistory.jsx
    ratingBox: {
      display: "flex",
      justifyContent: "space-around",
      paddingLeft: "20rem",
      paddingRight: "10rem",
    },
    radionBtnsBox: {
      display: "flex",
      justifyContent: "space-between",
      // paddingLeft: "20rem",
      // paddingRight: "10rem",
    },
    feedBackDescriptionBox: {
      width: "100%",
      border: "1px solid #EFEFEF",
      borderRadius: "15px",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    // feedBackSubmitBtn: {
    //   width: "7rem",
    //   height: "1rem",
    //   backgroundColor: "#B81918",
    //   fontFamily: "Poppins",
    // },
  };
});

// For MaintenaceForm.jsx and ComplaintForm.jsx Typography

export const textField = {
  padding: "0.5rem",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EFEFEF", // Change the border color here
      borderRadius: "0.5rem",
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Change the border color on hover here
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Change the border color when focused here
    },
  },
};

export const decsriptionTextField = {
  padding: "0.5rem",
  "& .MuiOutlinedInput-root": {
    height: "8rem",
    alignItems: "start",
    "& fieldset": {
      borderColor: "#EFEFEF", // Change the border color here
      borderRadius: "0.5rem",
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Change the border color on hover here
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Change the border color when focused here
    },
  },
};

export const textFieldTypography = {
  fontFamily: "Outfit",
  fontSize: "18px",
  lineHeight: "22px",
  fontWeight: "400",
};

export const submitBtnTypo = {
  width: { xs: "25%", sm: "15%" },
  backgroundColor: "#B81918",
  color: "#FFFFFF",
  fontFamily: "Outfit",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: "400",
  marginBottom: "1rem",
  height: "2rem",
  borderRadius: "0.5rem",
  textTransform: "unset",
  '&:hover':{
    backgroundColor: "#B81918",
  }
};

// FeedBack.jsx typography

export const feedBackHeading = {
  fontFamily: "Outfit",
  fontSize: "24px",
  lineHeight: "24px",
  fontWeight: "500",
};

export const feedBackTxt = {
  fontFamily: "Outfit",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "500",
  color: "#B81918",
  marginTop: "1rem",
};

export const feedBackItem = {
  fontFamily: "Outfit",
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "400",
  color: "#000000",
  "& .MuiFormControlLabel-label": {
    fontSize: "12px", // Smaller font size for the label
    fontFamily: "Outfit",
  },
  "& .MuiRadio-root": {
    transform: "scale(0.8)", // Smaller size for the radio button
  },
};

export const feedBackDescriptionField = {
  padding: "0.5rem",
  "& .MuiOutlinedInput-root": {
    height: "8rem",
    alignItems: "start",
    "& fieldset": {
      borderColor: "#EFEFEF", // Change the border color here
      borderRadius: "0.5rem",
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Change the border color on hover here
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Change the border color when focused here
    },
  },
};

export const feedBackSubmitBtn = {
  display: "flex",
  alignItems: "center",
  width: "11rem",
  height: "2rem",
  backgroundColor: "#B81918",
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "16px",
  color: "#FFFFFF",
  textTransform: "none",
};
