import React, { useEffect } from "react";
import { Box, Typography, Grid, TextField } from "@mui/material";
import { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { newIncomeTextfield } from "./ContractStyle";
import axios from "axios";
import { UserTypesNum } from "../../../enums/UserTypes";
import toast from "react-hot-toast";
import { endpoints } from "../../../apiEndpoints";
import Contracts from "./Contracts";
import { Loader } from "../../../utils/Loader";




const AddLandlord = () => {
  const [fields, setFields] = useState([""]);
  const [inputs, setInputs] = useState([""]);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [propertyAndPrimaryLandlord, setPropertyAndPrimaryLandlord] = useState({
    property: "",
    primaryLandlord: "",
  });
  const [propertyListing, setPropertyListing] = useState(null);
  const [unitListing, setUnitListing] = useState(null);
  const [landlordListing, setLandlordListing] = useState(null);

  const [isUpdateScreen, setIsUpdateScreen] = useState(false);

  const handleDatesChange = (e) => {
    const { name, value } = e.target;
    setDates({
      ...dates,
      [name]: value,
    });
  };
  const handlePropertyAndPrimaryLandlordChange = (e) => {
    const { name, value } = e.target;
    setPropertyAndPrimaryLandlord({
      ...propertyAndPrimaryLandlord,
      [name]: value,
    });
  };

  const addField = () => {
    setFields([...fields, ""]);
  };

  const addInput = () => {
    setInputs([...inputs, ""]);
  };

  const handleSelectionChange = (index, value) => {
    const updatedSelections = fields.map((selection, i) => {
      if (i === index) {
        return value;
      }
      return selection;
    });
    setFields(updatedSelections);
  };

  const handleInputChange = (index, value) => {
    const updatedInputs = inputs.map((input, i) => {
      if (i === index) {
        return value;
      }
      return input;
    });
    setInputs(updatedInputs);
  };

  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile1(file);
      // setFileUrl1(URL.createObjectURL(file));
    }
  };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile2(file);
      // setFileUrl2(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("start_date", dates.startDate);
      formData.append("end_date", dates.endDate);
      formData.append("unit", JSON.stringify(fields));
      formData.append("property", propertyAndPrimaryLandlord.property);
      formData.append(
        "primary_landlord",
        propertyAndPrimaryLandlord.primaryLandlord
      );
      formData.append("secondary_landlord", JSON.stringify(inputs));
      if (file2) {
        formData.append("agreement_doc", file2);
      }
      
      if (file1) {
          formData.append("title_deed_doc", file1);
      }
      // formData.append("agreement_doc", file2);
      // formData.append("title_deed_doc", file1);

      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      setLoading(true);
      const response = await axios.post(
        endpoints.landlordContracts,
        formData,
        requestOptions
      );
      toast.success(response.data.message);
      setIsUpdateScreen(true);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const getPropertyListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        endpoints.getPropertiesListing,
        requestOptions
      );
      setPropertyListing(response.data.data);
    } catch (error) { }
  };
  const getUnitListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      let url;
      if (propertyAndPrimaryLandlord.property) {
        url = `${endpoints.getUnitsListing}?property=${propertyAndPrimaryLandlord.property}`;
      } else {
        url = endpoints.getUnitsListing;
      }
      const response = await axios.get(url, requestOptions);
      setUnitListing(response.data.data);
    } catch (error) { }
  };
  const getLandlordsListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        `${endpoints.getUserListing}?user_type=${UserTypesNum.landlord}`,
        requestOptions
      );
      setLandlordListing(response.data.data);
    } catch (error) { }
  };

  useEffect(() => {
    getPropertyListing();
    getUnitListing();
    getLandlordsListing();
  }, []);

  useEffect(() => {
    getUnitListing();
  }, [propertyAndPrimaryLandlord.property]);
  return (
    <>
      {isUpdateScreen ? (
        <Contracts />
      ) : (
        <>
          <Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              Add Contract
            </Typography>
          </Box>
          {loading && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              {/* <CircularProgress color="inherit" /> */}
              <Loader/>
            </Backdrop>
          )}
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Start Date
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  name="startDate"
                  value={dates.startDate}
                  onChange={handleDatesChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  End Date
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  name="endDate"
                  value={dates.endDate}
                  onChange={handleDatesChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Property
                </Typography>

                <Grid item xs={12}>
                  <FormControl fullWidth sx={newIncomeTextfield}>
                    <Select
                      name="property"
                      value={propertyAndPrimaryLandlord.property}
                      onChange={handlePropertyAndPrimaryLandlordChange}
                      displayEmpty
                      
                    >
                      <MenuItem value="" disabled>
                        Select Property
                      </MenuItem>
                      {propertyListing?.map((item, index) => (
                        <MenuItem value={item.id} key={index}>
                          {`${item.ref_id} - ${item.title}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Unit
                </Typography>
                {fields.map((field, index) => (
                  <Grid item xs={12} key={index} marginBottom={"1rem"}>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                      <Select
                        value={field}
                        onChange={(e) =>
                          handleSelectionChange(index, e.target.value)
                        }
                        displayEmpty
                        
                      >
                        <MenuItem value="" disabled>
                          Select Unit
                        </MenuItem>
                        {unitListing?.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            {`${item.ref_id} - ${item.title}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ))}
                <IconButton
                  // variant="contained"
                  onClick={addField}
                  sx={{ color: "#B81918" }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Primary Landlord
                </Typography>
                <FormControl fullWidth sx={newIncomeTextfield}>
                  <Select
                    name="primaryLandlord"
                    value={propertyAndPrimaryLandlord.primaryLandlord}
                    onChange={handlePropertyAndPrimaryLandlordChange}
                    displayEmpty
                    
                  >
                    <MenuItem value="" disabled>
                      Select Primary Landlord
                    </MenuItem>
                    {landlordListing?.map((item, index) => (
                      <MenuItem value={item.id}>{item.first_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Secondary Landlord
                </Typography>
              </Grid>
              {inputs.map((input, index) => (
                <>
                  {" "}
                  <Grid item xs={12} sm={12} key={index}>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                      <Select
                        value={input}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                        displayEmpty
                        
                      >
                        <MenuItem value="" disabled>
                          Select Secondary Landlord
                        </MenuItem>
                        {landlordListing?.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            {item.first_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ))}

              <Grid item xs={12} md={12}>
                <IconButton
                  // variant="contained"
                  onClick={addInput}
                  sx={{ color: "#B81918" }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>

              {/* First file uploader */}
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Title Deed
                </Typography>
                <Box
                  sx={{
                    p: 5,
                    border: 1,
                    borderColor: "grey.300",
                    borderRadius: 2,
                    textAlign: "center",
                  }}
                >
                  <input
                    accept="*/*"
                    type="file"
                    onChange={handleFileChange1}
                    style={{ display: "none" }}
                    id="file-input-1"
                    
                  />
                  <label htmlFor="file-input-1">
                    <Button
                      variant="contained"
                      component="span"
                      style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        textTransform: "none",
                        borderRadius: "8px",
                        margin: "100px 0px 100px 0px",
                      }}
                      
                    >
                      Select and Upload File
                    </Button>
                  </label>
                  {file1 && <Typography>{file1.name}</Typography>}
                </Box>
              </Grid>
              {/* Second file uploader */}
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  PMA
                </Typography>
                <Box
                  sx={{
                    p: 5,
                    border: 1,
                    borderColor: "grey.300",
                    borderRadius: 2,
                    textAlign: "center",
                  }}
                >
                  <input
                    accept="*/*"
                    type="file"
                    onChange={handleFileChange2}
                    style={{ display: "none" }}
                    id="file-input-2"
                    
                  />
                  <label htmlFor="file-input-2">
                    <Button
                      variant="contained"
                      component="span"
                      style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        textTransform: "none",
                        borderRadius: "8px",
                        margin: "100px 0px 100px 0px",
                      }}
                    >
                      Select and Upload File
                    </Button>
                  </label>
                  {file2 && <Typography>{file2.name}</Typography>}
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", margin: "20px 0 50px 0" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "130px",
                  height: "40px",
                }}
                variant="contained"
                type="submit"
                disabled={loading}
              >
                Save Contract
              </Button>
            </Box>
          </form>
        </>
      )}
    </>
  );
};

export default AddLandlord;
