import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';


export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    Poproot: {
      width: "420px",
      height: "250px",
      transform: 'translate(-70%, -50%)',
      border: "1px solid rgba(154, 25, 21, 1)",
      borderRadius: "30px",
      margin: "0px 0px 40px 220px",
      [theme.breakpoints.down("sm")]: {
        width: "350px",
        transform: 'translate(-50%, -50%)',
        margin: "0px 0px 0px 0px"
      }
    },
    img: {
      width: "60px",
      margin: "35px 0px 0px 150px",
      [theme.breakpoints.down("sm")]: {
        margin: "30px 0px 5px 125px",
      }
    },
    heading: {

      fontFamily: "Outfit",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "15px",
      color: "rgba(0, 0, 0, 1)",
      margin: "0px 0px 0px 65px",
      [theme.breakpoints.down("sm")]: {
        margin: "0px 0px 0px 30px",
      }

    },
    btns: {
      margin: "15px 0px 0px 30px",
      [theme.breakpoints.down("sm")]: {
        display:"flex",
        margin: "15px 0px 0px 0px",
      }
    },
  };
});
