import React from "react";
import { Grid, Box, Typography, Hidden } from "@mui/material";
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useState } from "react";
import { useStyles } from "./LoginStyle";
import Sign from "./Sign";






const Login = () => {
    const classes = useStyles();
    const [language, setLanguage] = useState('English');
    const [expanded, setExpanded] = useState(false);
    const [showSign, setShowSign] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        setExpanded(false);
    };


    const handleContinue = () => {
        setShowSign(true); // Set true to display the Sign component
    };

    if (showSign) {
        return <Sign />;
    }

    return (
        <>
            <Grid container style={{ margin: "0px" }} >
                <Grid item xs={12} sm={6} md={6} style={{ padding: "0px 0px 0px 0px" }}>
                    <img src="/image/logo.png" alt="logo pic" style={{
                        width: '15%', height: 'auto',
                        margin: "15px 0px 0px 15px"

                    }} />

                    <Box className={classes.rootlogin}>

                        <Box>
                            <img src="/image/lang.png" width={75} height={75}></img>
                        </Box>
                        <Typography
                            style={{
                                font: "Outfit",
                                fontSize: "24px",
                                lineHeight: "30.24px",
                                fontWeight: 600,
                                color: "rgba(184, 25, 24, 1)",
                                margin: "15px 0px 0px 0px"
                            }}
                        >
                            Please Continue to Login
                        </Typography>
                        {/* Just hide it as we haven't added other languages yet */}
                        {/* <Box style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                            <Button
                                style={{
                                    width: "160px",
                                    height: "50px",
                                    backgroundColor: "rgba(239, 239, 239, 1)",
                                    color: "rgba(184, 25, 24, 1)",
                                    textTransform: "none",
                                    margin: "20px 0px 0px 0px"
                                }}
                                endIcon={expanded ? <ExpandLessIcon style={{ color: "rgba(184, 25, 24, 1)" }} /> : <ExpandMoreIcon style={{ color: "rgba(184, 25, 24, 1)" }} />}
                                onClick={toggleExpand}
                            >
                                {language}
                            </Button>
                            {expanded && (
                                <Box style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}>
                                    {language !== 'English' && (
                                        <Button
                                            onClick={() => handleLanguageChange('English')}
                                            style={{
                                                width: "160px",
                                                height: "50px",
                                                backgroundColor: "rgba(239, 239, 239, 1)",
                                                color: "rgba(184, 25, 24, 1)",
                                                textTransform: "none"
                                            }}
                                        >
                                            English
                                        </Button>
                                    )}
                                    {language !== 'Arabic' && (
                                        <Button
                                            onClick={() => handleLanguageChange('Arabic')}
                                            style={{
                                                width: "160px",
                                                height: "50px",
                                                backgroundColor: "rgba(239, 239, 239, 1)",
                                                color: "rgba(184, 25, 24, 1)",
                                                textTransform: "none"
                                            }}
                                        >
                                            Arabic
                                        </Button>
                                    )}


                                </Box>
                            )}
                        </Box> */}
                        <Button
                            style={{
                                width: "112px", height: "44px",
                                backgroundColor: "rgba(184, 25, 24, 1)",
                                borderRadius: "6px",
                                color: "rgba(255, 255, 255, 1)",
                                textTransform: "none",
                                margin: "50px 0px 0px 0px"
                            }}
                            onClick={handleContinue}
                        >
                            Continue
                        </Button>
                    </Box>
                </Grid>
                <Hidden smDown>
                    <Grid
                        item
                        lg={6} xl={6} md={6}
                        style={{
                            padding: "0px",
                            margin: "0px",
                            backgroundImage: 'url("/image/login.jpg")',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // height: '100%',  // Adjust this to '100vh' or '100%' depending on your layout needs
                            minHeight: '100vh'

                        }}
                    >
                    </Grid>
                </Hidden>
            </Grid>
        </>
    )
}

export default Login;
