import {
  Box,
  Typography,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import {
  CustomTextField,
  aplyfilter,
  gridItemAmount,
  gridItemTitle,
  mainTypography,
  tableBodyCell,
  tableBodyCellRed,
  tableHeadCell,
  useStyles,
} from "./CashFlowStyles";
import { Modal, TableCell as MuiTableCell } from "@mui/material";

import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { DatePicker, Space } from "antd";
import { PropertyType } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";
import toast from "react-hot-toast";
import moment from "moment";
const { RangePicker } = DatePicker;

function CashFlow() {
  const classes = useStyles();

  const [cashFlowData, setCashFlowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValues, setSearchValues] = useState({
    firstSearch: null,
    secondSearch: null,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [cashFlowUnits, setCashFlowUnits] = useState(null);
  const handleOpen = (units) => {
    setCashFlowUnits(units);
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);

  const handleSearchChange = (name, value) => {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };


  // const formattedDates = searchValues.firstSearch?.map((dateObj) => {
  //   return dateObj.$d.toISOString().split("T")[0]; // Get the date in YYYY-MM-DD format
  // });
  const formattedDates = searchValues.firstSearch?.map((dateObj) => {
    return moment(dateObj.$d).format('YYYY-MM-DD');
  });

  const cashFlowAmounts = [
    {
      title: "Total Earned",
      amount: cashFlowData?.total_amount || '0',
    },
    {
      title: "Cleared PDC",
      amount: cashFlowData?.cleared_pdc || '0',
    },
    {
      title: "Deposited PDC",
      amount: cashFlowData?.deposited_pdc || '0',
    },
    {
      title: "Bounced PDC",
      amount: cashFlowData?.bounced_pdc || '0',
    },
  ];

  const headers = [
    "Property",
    "Unit Type",
    "Unit Number",
    "Installment Number",
    "Payment Date",
    "Amount",
  ];

  const getCashFlow = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      let url;
      if (
        searchValues &&
        (formattedDates?.length === 2 || searchValues.secondSearch)
      ) {
        const startDate = formattedDates?.[0] || ""; // Use an empty string if not defined
        const endDate = formattedDates?.[1] || ""; // Use an empty string if not defined

        url = `${endpoints.financeCashFlow
          }?start_date=${startDate}&end_date=${endDate}&installment=${searchValues.secondSearch || ""
          }`;
      } else {
        url = endpoints.financeCashFlow;
      }
      const response = await axios.get(url, requestOptions);
      setCashFlowData(response.data.data);

      if (
        searchValues.secondSearch ||
        (formattedDates && formattedDates.length === 2)
      ) {
        // toast.success(response.data.message);
      }
    } catch (error) {
      if (
        searchValues.secondSearch ||
        (formattedDates && formattedDates.length === 2)
      ) {
        // toast.error(error.response.data.message);
      }
      setCashFlowData()
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCashFlow();
  }, []);
  return (
    <>
      <Box>
        <Typography sx={mainTypography}>Cash Flow</Typography>
      </Box>
      <Box className={classes.searchFieldBox}>
        <Space direction="vertical" size={12}>
          <RangePicker
            style={{
              borderRadius: "50px",
              height: "2.5rem",
              borderColor: "#EFEFEF",
              width: "100%",
              // Add more styles here if needed
            }}
            value={searchValues.firstSearch} // Bind the value here
            onChange={(dates) => handleSearchChange("firstSearch", dates)}
          />
        </Space>

        <TextField
          variant="outlined"
          placeholder="Search by Installment"
          value={searchValues.secondSearch}
          onChange={(e) => handleSearchChange("secondSearch", e.target.value)}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={CustomTextField}
        />
        <Button sx={aplyfilter} onClick={getCashFlow}>
          Apply Filter
        </Button>
      </Box>

      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : (
        <>
          <Grid container spacing={2} marginTop={"2rem"}>
            {cashFlowAmounts?.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box className={classes.gridBox}>
                  <Typography sx={gridItemTitle}>{item.title}</Typography>
                  <Typography sx={gridItemAmount}>{item.amount}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>


          {cashFlowData?.cashflow_data && cashFlowData?.cashflow_data?.length > 0 ? (<>
            <Box marginTop={"2rem"}>
              <TableContainer sx={{ height: "45vh", overflowX: "auto" }}>
                <Table sx={{ border: "1px solid #EFEFEF", minWidth: "65rem" }}>
                  <TableHead>
                    <TableRow>
                      {headers?.map((header, index) => (
                        <TableCell key={index} sx={tableHeadCell}>
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cashFlowData?.cashflow_data?.map((cashFlow) => (
                      <TableRow key={cashFlow.id}>
                        <TableCell sx={tableBodyCell}>
                          {cashFlow.property.title || "-"}
                        </TableCell>

                        <TableCell sx={tableBodyCell}>
                          {cashFlow.unit && cashFlow.unit.length > 0
                            ? cashFlow.unit[0].type == PropertyType.RESIDENTIAL
                              ? "Residential"
                              : cashFlow.unit[0].type == PropertyType.COMMERCIAL
                                ? "Commercial"
                                : cashFlow.unit[0].type == PropertyType.COMMUNITY
                                  ? "Community"
                                  : "-"
                            : "-"}
                        </TableCell>
                        <TableCell sx={tableBodyCell}>
                          {(cashFlow.unit && cashFlow.unit.length > 0 && (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <span>{cashFlow.unit[0].ref_id}</span>
                              <Tooltip title="View All Units" arrow>
                                <ArrowDropDownCircleOutlinedIcon
                                  onClick={() => handleOpen(cashFlow.unit)}
                                  sx={{ marginLeft: "1rem" }}
                                />
                              </Tooltip>
                            </Box>
                          )) ||
                            "-"}
                        </TableCell>
                        <TableCell sx={tableBodyCell}>
                          {cashFlow.payment_number || "-"}
                        </TableCell>
                        <TableCell sx={tableBodyCellRed}>
                          {cashFlow.payment_date || "-"}
                        </TableCell>
                        <TableCell sx={tableBodyCellRed}>
                          {cashFlow.amount || "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>


            <Modal open={modalOpen} onClose={handleClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  width: "80%",
                  maxWidth: "600px",
                }}
              >
                <Typography sx={mainTypography} gutterBottom>
                  Unit Information
                </Typography>
                <Table sx={{ border: "1px solid #EFEFEF" }}>
                  <TableHead>
                    <TableRow>
                      <MuiTableCell sx={tableHeadCell}>Unit Number</MuiTableCell>
                      <MuiTableCell sx={tableHeadCell}>Unit Type</MuiTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cashFlowUnits?.length > 0 ? (
                      cashFlowUnits?.map((unit) => (
                        <TableRow key={unit.id}>
                          <MuiTableCell sx={tableBodyCell}>
                            {unit.ref_id}
                          </MuiTableCell>
                          <MuiTableCell sx={tableBodyCell}>
                            {unit.type == PropertyType.RESIDENTIAL
                              ? "Residential"
                              : PropertyType.COMMERCIAL
                                ? "Commercial"
                                : PropertyType.COMMUNITY
                                  ? "Community"
                                  : "-"}
                          </MuiTableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <MuiTableCell colSpan={2}>
                          No units available
                        </MuiTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="success"
                  sx={{ mt: 2 }}
                >
                  Close
                </Button>
              </Box>
            </Modal>
          </>) : (

            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} marginTop={'2rem'}>
              <Typography>No data available</Typography>
            </Box>

          )}
        </>
      )}
    </>
  );
}

export default CashFlow;
