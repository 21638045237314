import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    mainBox: {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      marginTop: "1.5rem",
    },

    uploadBox: {
      display: "flex",
      alignItems: "center",
    },

    gridItem: {
      border: "1px solid #F1F1F1",
      display: "flex",
      justifyContent: "space-between",
      padding: "1rem",
      borderRadius: "1rem",
      // margin: "1rem",
      alignItems: "center",
    },
    iconStyle: {
      color: "#B9100F",
      cursor: "pointer",
    },
  };
});

export const headingTypography = {
  fontFamily: "Outfit",
  fontSize: "24px",
  lineHeight: "24px",
  fontWeight: "500",
};

export const dropDownStyles = {
  height: "2.5rem", // Adjust height of the select input
  borderRadius: "50px", // Set the border radius
  display: "flex",
  alignItems: "center", // Align items (label and icon) in the center
  "& .MuiOutlinedInput-root": {
    // paddingRight: "24px", // Adjust right padding for icon alignment
    "& fieldset": {
      borderColor: "#737679", // Default border color
      borderRadius: "50px", // Ensure border radius is applied to the fieldset
    },
    "&:hover fieldset": {
      borderColor: "#737679", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#737679", // Border color when focused
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "50px", // Apply border radius to the outline
  },
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    // paddingLeft: "16px", // Adjust left padding for text alignment
    lineHeight: "normal", // Ensure line height is normal for alignment
  },
  "& .MuiSelect-icon": {
    color: "#ADADAD", // Change the icon color if needed
    right: "0.5rem", // Adjust icon position if needed
    paddingTop: "4px",
  },
};

export const inputLabelStyle = {
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "12px",
  color: "#737679",
  paddingLeft: "0.5rem",
  // paddingBottom: "1rem",
};

export const uploadTypography = {
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "12px",
  color: "#ADADAD",
};

export const adressTypography = {
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "20px",
};
