import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useStyles } from "./PropertiesStyles";
import { useLocation } from "react-router-dom";
import { maintenanceStatus } from "../../../enums/UserTypes";

function ComplaintRequest() {
  const classes = useStyles();
  const location = useLocation();
  const { complaintDetail } = location.state || {};

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "1rem" },
          justifyContent: { xs: "center", sm: "space-between" },
          marginBottom: "2rem",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "24px",
            color: "#000000",
          }}
        >
          Complaint
        </Typography>
        <Button
          sx={{
            width: "9rem",
            height: "2.5rem",
            borderRadius: "8px",
            backgroundColor: "#B81918",
            color: "#FFFFFF",
            textTransform: "unset",
            fontSize: "16px",
            lineHeight: "16px",
            fontWeight: "500",
            fontFamily: "Outfit",
          }}
        >
          {complaintDetail?.status === maintenanceStatus.pending
            ? "Pending"
            : complaintDetail?.status === maintenanceStatus.progress
            ? "In Progress"
            : complaintDetail?.status === maintenanceStatus.resolved
            ? "Resolved"
            : "Unavailable"}
        </Button>
      </Box>

      {/* name fields */}
      <Grid container spacing={3} marginBottom={"2rem"}>
        <Grid item xs={12} sm={12} md={4} className={classes.nameContctGrid}>
          <Box>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "18px",
                lineHeight: "18px",
                fontWeight: "400",
              }}
            >
              First Name
            </Typography>
          </Box>
          <Box className={classes.nameFieldBox}>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: "400",
                color: "#737679",
              }}
            >
              {complaintDetail?.first_name || "Not available"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.nameContctGrid}>
          <Box>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "18px",
                lineHeight: "18px",
                fontWeight: "400",
              }}
            >
              Last Name
            </Typography>
          </Box>
          <Box className={classes.nameFieldBox}>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: "400",
                color: "#737679",
              }}
            >
              {complaintDetail?.last_name || "Not available"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* contact fields */}
      <Grid container spacing={3} marginBottom={"2rem"}>
        <Grid item xs={12} sm={12} md={4} className={classes.nameContctGrid}>
          <Box>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "18px",
                lineHeight: "18px",
                fontWeight: "400",
              }}
            >
              Phone Number
            </Typography>
          </Box>
          <Box className={classes.nameFieldBox}>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: "400",
                color: "#737679",
              }}
            >
              {complaintDetail?.phone || "Not available"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.nameContctGrid}>
          <Box>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "18px",
                lineHeight: "18px",
                fontWeight: "400",
              }}
            >
              Email
            </Typography>
          </Box>
          <Box className={classes.nameFieldBox}>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: "400",
                color: "#737679",
              }}
            >
              {complaintDetail?.email || "Not available"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* address field */}
      <Grid container marginBottom={"2rem"}>
        <Grid item xs={12} sm={12} md={8} className={classes.nameContctGrid}>
          <Box>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "18px",
                lineHeight: "18px",
                fontWeight: "400",
              }}
            >
              Full Address
            </Typography>
          </Box>
          <Box className={classes.nameFieldBox}>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: "400",
                color: "#737679",
              }}
            >
              {complaintDetail?.address || "Not available"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* description field */}
      <Grid container marginBottom={"3.5rem"}>
        <Grid item xs={12} sm={12} md={8} className={classes.nameContctGrid}>
          <Box>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "18px",
                lineHeight: "18px",
                fontWeight: "400",
              }}
            >
              Description of Request
            </Typography>
          </Box>
          <Box className={classes.descriptionBox}>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: "400",
                color: "#737679",
              }}
            >
              {complaintDetail?.description || "Not available"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ComplaintRequest;
