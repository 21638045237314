import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';


export const useStyles = makeStyles(() => {
    const theme = useTheme();
    return {
        graph: {
            backgroundColor: "rgb(255,255,255)",
            border: "1px solid #ADADAD",
            borderRadius: "5.8px",
            // boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)',
            margin: "0px 0px 0px 0px",
            padding: "0px 0px 0px 0px"
        },

        legendDot: {
            height: '15px',
            width: '15px',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '5px',
        },
        occupied: {
            backgroundColor: '#B81918',
        },
        vacant: {
            backgroundColor: '#808080',
            margin: "0px 0px 0px 13px"
        },
        option: {
            display: "flex",
            // justifyContent: "flex-end",
            gap: "1rem",
            margin: "0px 0px 0px 0px",
            padding: "0px 0px 0px 0px"
        },
        grap: {
            backgroundColor: "rgb(255,255,255)",
            border: "1px solid rgba(173, 173, 173, 1)",
            borderRadius: "5.8px",
            // boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)',
            margin: "10px 0px 0px 0px",
            padding: "15px"
        },

    };
});

export const calendarTypography = {
    fontFamily: "Outfit",
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: "500",
    color: "#737679",
    cursor: "pointer",
};

export const selectedCalendarTypography = {
    fontFamily: "Outfit",
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: "700",
    color: "#B81918",
};





