import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { headings, textField } from "./PropertiesStyles";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { endpoints } from "../../../apiEndpoints";
import axios from "axios";
import toast from "react-hot-toast";
import Properties from "./Properties";

function ContractTermination({ property }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContractSend, setIsContractSend] = useState(false);
  const [pdfLetter, setPdfLetter] = useState(null);
  const [contractId, setContractId] = useState(null);
  const [pdfError, setPdfError] = useState(null);

  const [tenantInfo, setTenantInfo] = useState({
    fullName: null,
    companyName: null,
    unifiedNo: null,
    phone: null,
    eidNo: null,
    email: null,
    authorised: null,
    address: null,
  });

  const [propertyInfo, setPropertyInfo] = useState({
    appartment: null,
    securityDeposit: null,
    // unit: [],
    premise: null,
    tawtheeqNo: null,
    expiryDate: null,
  });
  const [propertyUnit, setPropertyUnit] = useState([]);

  const [terminationInfo, setTerminationInfo] = useState({
    noticePeriod: null,
    addcAccount: null,
    furnitureClearance: null,
    maintenanceClearance: null,
    keyHandover: null,
    addcClearance: null,
    defaultsApply: null,
    cost: null,
  });

  const [unitList, setUnitList] = useState(null);

  const handleTenantInfoChange = (e) => {
    const { name, value } = e.target;
    setTenantInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handlePropertyInfoChange = (e) => {
    const { name, value } = e.target;
    setPropertyInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleTerminationInfoChange = (e) => {
    const { name, value } = e.target;
    setTerminationInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePdfLoadError = (error) => {
    setPdfError(error);
  };

  const tenantUnitListing = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        endpoints.tenantUnitListing,
        requestOptions
      );
      setUnitList(response.data.data);
    } catch {}
  };

  const tenantTerminationContract = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        // Tenant Info
        full_name: tenantInfo.fullName,
        company_name: tenantInfo.companyName,
        tenant_uid: tenantInfo.unifiedNo,
        tenant_emirates_id: tenantInfo.eidNo,
        tenant_address: tenantInfo.address,
        tenant_authorized: tenantInfo.authorised,
        tenant_emergency_contact: tenantInfo.phone,

        // Property Info
        property: property.id,
        apartment_number: propertyInfo.appartment,
        premise_number: propertyInfo.premise,
        tawtheeq_number: propertyInfo.tawtheeqNo,
        security_deposit: propertyInfo.securityDeposit,
        expiry: propertyInfo.expiryDate,
        unit: propertyUnit,

        // Termination Info
        notice_period: terminationInfo.noticePeriod,
        addc_account_no: terminationInfo.addcAccount,
        addc_clearance: terminationInfo.addcClearance,
        furniture_clearance: terminationInfo.furnitureClearance,
        maintenance_clearance: terminationInfo.maintenanceClearance,
        key_handover: terminationInfo.keyHandover,
        default: terminationInfo.defaultsApply,
        default_cost: terminationInfo.cost,
      };
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        endpoints.createTenantTerminationContract,
        payload,
        requestOptions
      );
      setPdfLetter(response.data.data.document);
      setContractId(response.data.data.id);
      setIsModalOpen(true);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const SendContract = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        `${endpoints.sendTenantTerminationContract}?contract_id=${contractId}`,
        contractId,
        requestOptions
      );
      toast.success(response.data.message);
      handleModalClose();
      setIsContractSend(true);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    tenantUnitListing();
  }, []);

  return (
    <>
      {isContractSend ? (
        <Properties />
      ) : (
        <>
          <form onSubmit={tenantTerminationContract}>
            <Grid
              container
              border={"1px solid #EFEFEF"}
              borderRadius={"1rem"}
              marginBottom={"1rem"}
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <Typography sx={headings}>
                  Tenant/Company Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Full Name"
                  name="fullName"
                  value={tenantInfo.fullName || ""}
                  onChange={handleTenantInfoChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Company Name"
                  name="companyName"
                  value={tenantInfo.companyName || ""}
                  onChange={handleTenantInfoChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Unified Number"
                  name="unifiedNo"
                  value={tenantInfo.unifiedNo || ""}
                  onChange={handleTenantInfoChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Phone Number"
                  name="phone"
                  value={tenantInfo.phone || ""}
                  onChange={handleTenantInfoChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="EID Number"
                  name="eidNo"
                  value={tenantInfo.eidNo || ""}
                  onChange={handleTenantInfoChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Authorised (if)"
                  name="authorised"
                  value={tenantInfo.authorised || ""}
                  onChange={handleTenantInfoChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Address"
                  name="address"
                  value={tenantInfo.address || ""}
                  onChange={handleTenantInfoChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography sx={headings}>The Property</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  helperText="Property Reference Id"
                  value={property.ref_id || ""}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Appartment Number"
                  name="appartment"
                  value={propertyInfo.appartment || ""}
                  onChange={handlePropertyInfoChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Security Deposit paid"
                  name="securityDeposit"
                  value={propertyInfo.securityDeposit || ""}
                  onChange={handlePropertyInfoChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth sx={textField}>
                  <Select
                    displayEmpty
                    // name="unit"
                    // value={propertyUnit || ""}
                    // onChange={handlePropertyInfoChange}
                    value={propertyUnit.length > 0 ? propertyUnit[0] : ""}
                    onChange={(e) => {
                      const selectedUnit = e.target.value;
                      setPropertyUnit([selectedUnit]); // Save the selected unit in an array
                    }}
                    required
                  >
                    <MenuItem value="">Unit Number</MenuItem>
                    {unitList?.map((unit) => (
                      <MenuItem value={unit.id} key={unit.id}>
                        {unit.ref_id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Premise Number"
                  name="premise"
                  value={propertyInfo.premise || ""}
                  onChange={handlePropertyInfoChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Tawtheeq Number"
                  name="tawtheeqNo"
                  value={propertyInfo.tawtheeqNo || ""}
                  onChange={handlePropertyInfoChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  helperText="Expiry Date"
                  type="date"
                  name="expiryDate"
                  value={propertyInfo.expiryDate || ""}
                  onChange={handlePropertyInfoChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography sx={headings}>Termination</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Notice Period"
                  name="noticePeriod"
                  value={terminationInfo.noticePeriod || ""}
                  onChange={handleTerminationInfoChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="ADDC Account Number"
                  name="addcAccount"
                  value={terminationInfo.addcAccount || ""}
                  onChange={handleTerminationInfoChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Furniture Clearance"
                  name="furnitureClearance"
                  value={terminationInfo.furnitureClearance || ""}
                  onChange={handleTerminationInfoChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Maintenance Clearance"
                  name="maintenanceClearance"
                  value={terminationInfo.maintenanceClearance || ""}
                  onChange={handleTerminationInfoChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Key Handover"
                  name="keyHandover"
                  value={terminationInfo.keyHandover || ""}
                  onChange={handleTerminationInfoChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="ADDC Clearance"
                  name="addcClearance"
                  value={terminationInfo.addcClearance || ""}
                  onChange={handleTerminationInfoChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="Defaults Apply"
                  name="defaultsApply"
                  value={terminationInfo.defaultsApply || ""}
                  onChange={handleTerminationInfoChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={textField}
                  placeholder="If Yes, Cost"
                  name="cost"
                  value={terminationInfo.cost || ""}
                  onChange={handleTerminationInfoChange}
                  fullWidth
                />
              </Grid>
              <Grid item paddingBottom={"1rem"}>
                <Button
                  sx={{
                    width: "8rem",
                    height: "2.5rem",
                    borderRadius: "8px",
                    backgroundColor: "#B81918",
                    color: "#FFFFFF",
                    textTransform: "unset",
                    fontSize: "16px",
                    lineHeight: "16px",
                    fontWeight: "350",
                    fontFamily: "Outfit",
                  }}
                  type="submit"
                >
                  Review Info
                </Button>
              </Grid>
            </Grid>
          </form>

          <Modal
            open={isModalOpen}
            onClose={handleModalClose}
            aria-labelledby="pdf-viewer-modal"
            aria-describedby="pdf-viewer-modal-description"
            // marginBottom="1rem"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "60%",
                height: "90%",
                bgcolor: "white",
                boxShadow: 18,
                p: 4,
                borderRadius: "8px",
                overflow: "auto",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                {pdfError ? (
                  <div>Error loading PDF: {pdfError.message}</div>
                ) : (
                  <Viewer fileUrl={pdfLetter} onError={handlePdfLoadError} />
                )}
              </Worker>
              <Box
                display={"flex"}
                marginBottom={"0.5rem"}
                marginTop={"0.5rem"}
                gap={"10px"}
              >
                <Button
                  sx={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    color: "white",
                    fontSize: "12px",
                    lineHeight: "12px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "8rem",
                    height: "2rem",
                    borderRadius: "0.5rem",
                  }}
                  onClick={SendContract}
                >
                  Send
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default ContractTermination;
