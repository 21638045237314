import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import {
  Grid,
  Card,
  CardContent,
  Container,
  CardActionArea,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { PropertyType, PropertyList } from "../../../../enums/UserTypes";
import { endpoints } from "../../../../apiEndpoints";
import { Loader } from "../../../../utils/Loader";

const UnitData = [
  {
    id: "1",
    image: "/image/landlordU1.png",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "305 block C, Abu Dhabi ",
    city: "Residential",
  },
  {
    id: "2",
    image: "/image/landlordU2.png",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "Terry Lane, Golden CO 80403",
    city: "Residential",
  },
  {
    id: "3",
    image: "/image/landlordU3.png",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "305 block C, Abu Dhabi ",
    city: "Commercial",
  },
  {
    id: "4",
    image: "/image/landlordU4.png",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "305 block C, Abu Dhabi ",
    city: "Commercial",
  },
  {
    id: "5",
    image: "/image/landlordU5.png",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "305 block C, Abu Dhabi ",
    city: "Residential",
  },
  {
    id: "6",
    image: "/image/landlordU6.png",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "305 block C, Abu Dhabi ",
    city: "Residential-Commercial",
  },
  {
    id: "7",
    image: "/image/landlordU7.png",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "305 block C, Abu Dhabi ",
    city: "Commercial",
  },
  {
    id: "8",
    image: "/image/landlordU8.png",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "305 block C, Abu Dhabi ",
    city: "Commercial",
  },
  {
    id: "9",
    image: "/image/unimage6.jpg",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "305 block C, Abu Dhabi ",
    city: "Residential",
  },
  {
    id: "10",
    image: "/image/unimage7.jpg",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "305 block C, Abu Dhabi ",
    city: "Residential-Commercial",
  },
  {
    id: "11",
    image: "/image/proimage1.jpg",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "305 block C, Abu Dhabi ",
    city: "Commercial",
  },
  {
    id: "12",
    image: "/image/proimage1.jpg",
    icon: <LocationOnRoundedIcon style={{ width: "20px" }} />,
    address: "305 block C, Abu Dhabi ",
    city: "Residential-Commercial",
  },
];

const Allunit = () => {
  const navigate = useNavigate();
  const [allUnits, setAllUnits] = useState(null);
  const [loading, setLoading] = useState(true);

  const getAllUnits = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(endpoints.landlordUnits, requestOptions);
      setAllUnits(response.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUnits();
  }, []);

  const handleCardClick = (unit) => {
    navigate("/landlord/properties/allUnitdetail", {
      state: { unit },
    });
  };

  const getStatusText = (status) => {
    return PropertyList[status] || "Unknown Status";
  };

  return (
    <>
      {loading ? (
        <Box
          display={"flex"}
          height={"100vh"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Loader />
        </Box>
      ) : allUnits && allUnits.length === 0 ? (
        <Box display={"flex"} justifyContent={"center"}>
          <Typography>No Units available</Typography>
        </Box>
      ) : (
        <Grid container spacing={3} mt={2}>
          {allUnits?.map((item, index) => {
            return (
              <Grid item xs={12} sm={4} md={4} lg={3} key={index}>
                <Card
                  sx={{ maxWidth: 345 }}
                  style={{ marginBottom: "5px" }}
                  onClick={() => handleCardClick(item)}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={
                        item?.attachments.length > 0
                          ? item?.attachments[0]?.media
                          : "No Image Available"
                      }
                      onError={(e) =>
                        (e.target.src = "/image/propertyicon.png")
                      }
                    />
                    <CardContent>
                      <Typography
                        style={{
                          fontFamily: "Outfit",
                          fontWeight: 400,
                          fontSize: "10px",
                          lineHeight: "12px",
                          margin: "0px 0px 0px 5px",
                          color: "#000000",
                        }}
                      >
                        {item.ref_id}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Outfit",
                          fontWeight: 900,
                          fontSize: "14px",
                          lineHeight: "12px",
                          margin: "8px 0px 7px 5px",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Box style={{ display: "flex" }}>
                        <Box style={{ color: "rgba(115, 118, 121, 1)" }}>
                          <LocationOnRoundedIcon style={{ fontSize: "15px" }} />
                        </Box>
                        <Typography
                          style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "12px",
                            margin: "2px 0px 0px 3px",
                            color: "#737679",
                          }}
                        >
                          {`${item.address}, ${getStatusText(item.type)}`}
                        </Typography>
                      </Box>
                      <Typography
                        style={{
                          fontFamily: "Outfit",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "12px",
                          margin: "0px 0px 0px 25px",
                        }}
                      ></Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default Allunit;
