import React from "react";
import { Box, Button, Modal } from "@mui/material";
import { useStyles } from "./ClientStyle";
import axios from "axios";
import toast from "react-hot-toast";
import { endpoints } from "../../../apiEndpoints";
import { useNavigate } from "react-router-dom";

const Popup = ({ onClose, userFormData, userAddFormData, userFile }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  console.log("userAddFormData", userAddFormData.EmiratesId)
  const handleSubmit = async (event) => {
    event.preventDefault();
    onClose();
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const data = new FormData();

      if (userFormData.userType) {
        data.append("user_type", userFormData.userType);
      }
      if (userFormData.name) {
        data.append("first_name", userFormData.name);
      }
      if (userFormData.number) {
        data.append("phone", userFormData.number);
      }
      if (userAddFormData.EmiratesId) {
        data.append("emirates_id", userAddFormData.EmiratesId);
      }
      if (userAddFormData.PassportNumber) {
        data.append("passport_no", userAddFormData.PassportNumber);
      }
      if (userFile) {
        data.append("docs", userFile);
      }

      data.append("email", userFormData.email);
      data.append("password", userFormData.password);

      console.log("user data", data)


      const response = await axios.post(
        endpoints.SignUpUsers,
        data,
        requestOptions
      );
      toast.success(response.data.message);

      // navigate("/admin/client")
      {
        userFormData.userType == 1
          ? navigate("/admin/client?value=2")
          : userFormData.userType == 2
            ? navigate("/admin/client?value=1")
            : userFormData.userType == 3
              ? navigate("/admin/client?value=0")
              : navigate("/admin/client?value=3");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="popup-modal-title"
      aria-describedby="popup-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        className={classes.Poproot}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <img className={classes.img} src="/image/Group.png" alt="popup image" />
        <h6 className={classes.heading}>
          Confirmation The Registration Process
        </h6>
        <Box className={classes.btns}>
          <Button
            variant="outlined"
            style={{
              border: "1px solid rgba(154, 25, 21, 1)",
              color: "rgba(154, 25, 21, 1)",
              textTransform: "none",
              width: "90px",
              height: "30px",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "rgba(184, 25, 24, 1)",
              textTransform: "none",
              margin: "0px 0px 0px 10px",
              width: "90px",
              height: "30px",
            }}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default Popup;
