import { makeStyles } from "@mui/styles";
import { styled, Box,Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';


export const useStyles = makeStyles(() => {
    const theme = useTheme();
    return {
        graph: {
            backgroundColor: "rgb(255,255,255)",
            border: "1px solid #EFEFEF",
            borderRadius: "15px",
            margin: "25px 0px 0px 0px",
            padding: "0px 0px 0px 0px"
        },
        option: {
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            margin: "10px 0px 0px 0px",
            padding: "0px 22px 0px 22px"
        },
        tab: {
            border: "1px solid rgba(239, 239, 239, 1)",
            borderRadius: "8px",
            margin: "5px 0px 0px 0px"


        },
        boxone: {
            border: "1px solid rgba(239, 239, 239, 1)",
            margin: "5px 20px 0px 0px",
            borderRadius: "6px"
        },

        input: {
            display: "flex",
            alignItems: "center",
            paddingLeft: "2rem",
            backgroundColor: "#F10A0A08",
            color: "#737679",
            fontFamily: "Outfit",
            fontWeight: "400",
            fontSize: "16px",
            borderRadius: "8px",
            height: "2.5rem",
            border: "none",
            width: "100%"
        },
        box: {
            backgroundColor: "#F10A0A08",
            padding: "15px 15px 15px 15px",
            borderRadius: "8px",
            margin: "0px 0px 20px 0px"
        },


    };
});

export const ImgsBox = styled(Box)({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    backgroundColor: "#F10A0A08",
    overflow: "auto",

    borderRadius: "8px",
    height: "8.365rem",
});


export const UserDetailsTypography = styled(Typography)({
    padding: "0.5rem",
    fontFamily: "Outfit",
    fontWeight: "400",
    fontSize: "20px",
});

export const UserDetailsTypography2 = styled(Typography)({
    // padding: "0.5rem",
    display: "flex",
    alignItems: "center",
    paddingLeft: "2rem",
    backgroundColor: "#F10A0A08",
    color: "#737679",
    fontFamily: "Outfit",
    fontWeight: "400",
    fontSize: "16px",
    borderRadius: "8px",
    height: "3rem",
});

export const UserDetailsBox = styled(Box)(({ theme }) => ({
   
    // [theme.breakpoints.up("md")]: {
    //     width: "42rem", // Width for md and above breakpoints
    // },
    // height: "5.625rem",
    // marginTop: "1rem",
    // padding: "3rem",
}));