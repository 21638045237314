import React from "react";
import { useState, useEffect } from "react";
import { Typography, Grid, Box, Divider, Button } from "@mui/material";
import { useStyles } from "./DashboardStyle";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { statusMap } from '../../../enums/UserTypes';
import { Loader } from "../../../utils/Loader";
import { useNavigate } from "react-router-dom";




const MaintenanceData = [
    {
        id: "1",
        Dates: "11-Apr-2024",
        name: "This kitchen pipe is broken and it is leaking continuesly",
        button: "Pending Request"
    },
    {
        id: "2",
        Dates: "11-March-2024",
        name: "This kitchen pipe is broken and it is leaking continuesly",
        button: "Pending Request"

    },
    {
        id: "3",
        Dates: "12-Dec-2023",
        name: "This kitchen pipe is broken and it is leaking continuesly",
        button: "Pending Request"
    },
    {
        id: "4",
        Dates: "12-Dec-2023",
        name: "This kitchen pipe is broken and it is leaking continuesly",
        button: "Resolved"
    },
    {
        id: "5",
        Dates: "12-Dec-2023",
        name: "This kitchen pipe is broken and it is leaking continuesly",
        button: "Resolved"
    },
    {
        id: "6",
        Dates: "12-Dec-2023",
        name: "This kitchen pipe is broken and it is leaking continuesly",
        button: "Resolved"
    }
]


const Tab = () => {
    const classes = useStyles();
    const [maintenanceData, setmaintenanceData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    const getMaintenance = async () => {

        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.landlordmaintenance}`, {
                headers: { Authorization: `Token ${token}` }
            });
            const data = response.data.data;
            setmaintenanceData(data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMaintenance();
    }, [])


    const getmaintenance_status = (maintenance_status) => {
        return statusMap[maintenance_status] || "Unknown Status";
    }


    const handleClick = () => {
        navigate('/landlord/maintenance');
    };
    return (
        <>
            <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={12}  >
                    <Box className={classes.graph3}>
                        <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                            <Typography style={{
                                fontFamily: "Outfit",
                                fontSize: "24px",
                                lineHeight: "24px",
                                fontWeight: 600,
                                color: "rgba(0, 0, 0, 1)",
                                margin: "20px 0px 20px 20px"
                            }}>
                                Property Maintenance
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button style={{
                                    fontFamily: "Outfit",
                                    fontSize: "12px",
                                    lineHeight: "12px",
                                    fontWeight: 600,
                                    color: "rgba(0, 0, 0, 1)",
                                    textTransform: "none",
                                    margin: "0px 10px 0px 0px"
                                }}

                                    onClick={handleClick}
                                >All maintenance requests</Button>
                            </Box>
                        </Box>
                        <Box style={{ margin: "0px 0px 30px 0px", }}>
                            <Divider />
                        </Box>


                        {loading ? (
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                height={"50vh"}
                            >
                                <Loader />
                            </Box>
                        ) : maintenanceData?.length === 0 ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="50vh"
                            >
                                <Typography>No data available</Typography>
                            </Box>
                        ) : (
                            <Box>

                                {maintenanceData?.map((item, index) => (
                                    <Box style={{ padding: "0px 15px 15px 15px" }}>
                                        <Typography
                                            sx={{
                                                fontFamily: "Outfit",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                lineHeight: "16px"
                                            }}
                                        >
                                            {item.created_at.split("T")[0]}
                                        </Typography>
                                        <Box className={classes.tab}>
                                            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                                                <Grid item xs={12} sm={8}>
                                                    <Typography style={{
                                                        fontFamily: "Outfit",
                                                        fontSize: "18px",
                                                        lineHeight: "18px",
                                                        fontWeight: 500,
                                                        color: "rgba(0, 0, 0, 1)",
                                                        margin: "20px 0px 20px 20px"
                                                    }}>
                                                        {item.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end">
                                                    <Button style={{
                                                        fontSize: "14px",
                                                        lineHeight: "16putpx",
                                                        fontWeight: 500,
                                                        textTransform: "none",
                                                        height: "40px",
                                                        // color: item.button === "Pending Request" ? "blue" : (item.button === "Resolved" ? "white" : "transparent"),
                                                        // backgroundColor: item.button === "Pending Request" ? "" : (item.button === "Resolved" ? "rgba(0, 227, 23, 1)" : "transparent"),

                                                        color: item?.maintenance_status === "1" ? "blue" :
                                                            item?.maintenance_status === "3" ? "white" :
                                                                item?.maintenance_status === "2" ? "red" : "transparent",
                                                        backgroundColor: item?.maintenance_status === "Pending" ? "" :
                                                            item?.maintenance_status === "3" ? "#00E317" :
                                                                item?.maintenance_status === "New Request" ? "transparent" : "transparent",
                                                        borderColor: item?.maintenance_status === "2" ? "red" : "",

                                                        margin: "0px 15px 0px 0px"
                                                    }}
                                                        // variant={item.button === "Pending Request" ? "outlined" : (item.button === "Resolved" ? "contained" : "text")}
                                                        variant={item?.maintenance_status === "1" ? "outlined" :
                                                            item?.maintenance_status === "3" ? "contained" :
                                                                item?.maintenance_status === "2" ? "outlined" : "text"}
                                                    >
                                                        {getmaintenance_status(item?.maintenance_status)}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>

        </>
    )
}

export default Tab;