import React from "react";
import { Navigate } from "react-router-dom";
import AdminPortal from "../AdminComponent/Sidebar/AdminPortal";
import TenantPortal from "../TenantComponent/Sidebar/TenantPortal";
import LandlordPortal from "../LandlordComponent/Sidebar/LandlordPortal";
import FinancePortal from "../FinanceComponent/Sidebar/FinancePortal";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ usertypeRequired }) => {
  const token = localStorage.getItem("token");
  const usertype = localStorage.getItem("usertype");
  
  const navigate = useNavigate();

  if (!token) {
    return <Navigate to="/" />;
  }

  const cleanString = (input) => input.replace(/[^a-zA-Z]/g, "");
  const usertypeCleaned = cleanString(usertype);

  const userTypes = Array.isArray(usertypeRequired)
    ? usertypeRequired
    : [usertypeRequired];

    console.log(usertypeCleaned)

  if (userTypes.includes(usertypeCleaned)) {
    switch (usertypeCleaned) {
      case "SuperAdmin":
      case "Admin":
        return <AdminPortal />;
      case "Tenant":
        return <TenantPortal />;
      case "Landlord":
        return <LandlordPortal />;
      case "Finance":
        return <FinancePortal />;
      default:
        return <Navigate to="/" />;
    }
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
