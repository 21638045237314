import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Tooltip,
  InputLabel,
} from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import AddInquiries from "./AddInquiries";
import SearchIcon from "@mui/icons-material/Search";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SaveAsSharpIcon from "@mui/icons-material/SaveAsSharp";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import InquiriesForm from "./InquiriesForm";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import UpdateInquiry from "./UpdateInquiry";
import { Loader } from "../../../utils/Loader";
import { inquiryStatus } from "../../../enums/UserTypes";
import { useNavigate } from "react-router-dom";




const Inquiries = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setopen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [leadValue, setLeadValue] = useState("");
  const [close, setclose] = useState(false);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [editInquiry, setEditInquiry] = useState(null);



  const getInquiries = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };

      let apiUrl;
      if (leadValue || searchValue) {
        apiUrl = `${endpoints.getInquiry}?lead=${leadValue}&search=${searchValue}`;
      } else {
        apiUrl = endpoints.getInquiry;
      }
      const response = await axios.get(apiUrl, requestOptions);
      const data = response.data.data;
      setTableData(data);
      // if (leadValue || searchValue) {
      //   toast.success(response.data.message);
      // }
      if (!data || data?.length === 0) {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInquiries();
  }, []);




  const handleopen = () => {
    navigate('/admin/inquiries/add-inquiries');
  };
 

  const handleclose = (rowData) => {
    setSelectedInquiry(rowData);
    setclose(true);
  };

  const handleUpdate = (updateDate) => {
    setEditInquiry(updateDate);
    setclose(true);
  };

  if (close && selectedInquiry) {
    return <InquiriesForm inquiryData={selectedInquiry} />;
  }

  if (close && editInquiry) {
    return <UpdateInquiry editInquiryData={editInquiry} />;
  }

  const handleApplyFilter = () => {
    setTableData([]);
    getInquiries();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          Inquiries
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{
              backgroundColor: "rgba(184, 25, 24, 1)",
              fontSize: "14px",
              lineHeight: "16px",
              fontWeight: 500,
              textTransform: "none",
              width: "130px",
              height: "30px",
            }}
            variant="contained"
            onClick={handleopen}
          >
            Add New
          </Button>
        </Box>
      </Box>
      <Box>
        <Box sx={{ flexGrow: 1, margin: "30px 0px 30px 0px" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={12} lg={6}>
              <div style={{ position: "relative" }}>
                <input
                  type="search"
                  style={{
                    width: "100%",
                    height: "40px",
                    border: "1px solid rgba(239, 239, 239, 1)",
                    borderRadius: "30px",
                    paddingLeft: "40px",
                  }}
                  placeholder="Search by name"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  size="small"
                />
                <div
                  style={{ position: "absolute", top: "10px", left: "10px" }}
                >
                  <SearchIcon />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={leadValue}
                  onChange={(e) => setLeadValue(e.target.value)}
                  displayEmpty
                  style={{
                    border: "0px solid rgba(239, 239, 239, 1)",
                    borderRadius: "30px",
                  }}
                >
                  <MenuItem value="">All Leads</MenuItem>

                  <MenuItem value="2">Qualified</MenuItem>
                  <MenuItem value="1">Not Qualified</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  border: "1px solid #B81918",
                  color: "#B81918",
                  fontFamily: "Outfit",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "12px",
                  borderRadius: "30px",
                  height: "2.5rem",
                  padding: "0.5rem 1rem 0.5rem",
                  textTransform: "none",
                }}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Table Code */}

      <Box style={{ overflowX: "auto" }}>
        {loading ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"50vh"}
          >
            <Loader />
          </Box>
        ) : tableData?.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <Typography>No data available</Typography>
          </Box>
        ) : (
          <Table sx={{ border: "1px solid #EFEFEF", minWidth: "70rem" }}>
            <TableHead>
              <TableRow
                style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
              >
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Name
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Emirates Id
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Passport No
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Email
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Offer
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Status
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Leads
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.emirates_id}</TableCell>
                  <TableCell>{row.passport_no}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    {row.offer_status === "Awaiting To Send" ? (
                      <Button
                        style={{
                          backgroundColor: "rgba(184, 25, 24, 1)",
                          color: "white",
                          width: "130px",
                          textTransform: "none",
                        }}
                        onClick={() => handleclose(row)}
                      >
                        Send Offer Letter
                      </Button>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: "rgba(0, 227, 23, 1)",
                          color: "white",
                          width: "130px",
                          textTransform: "none",
                        }}
                      >
                        Sent
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{row.offer_status}</TableCell>
                  <TableCell>{row.inquiry_status === inquiryStatus.qualified.value ? (inquiryStatus.qualified.label):row.inquiry_status === inquiryStatus.notQualified.value ? (inquiryStatus.notQualified.label):'-'}</TableCell>
                  <TableCell>
                    {
                      <Box display={'flex'} alignItems={'center'}>
                        <Tooltip title="Edit Inquiry">
                          <EditNoteIcon
                            style={{
                              color: "rgba(184, 25, 24, 1)",
                              cursor: "pointer",
                            }}
                            onClick={() => handleUpdate(row)}
                          />
                        </Tooltip>
                        {row.inquiry_status === inquiryStatus.qualified.value ?(
                          <Tooltip title="Create Contract">
                        <SaveAsSharpIcon
                          style={{
                            color: "rgba(184, 25, 24, 1)",
                            cursor: "pointer",
                          }}
                          onClick = {()=>{navigate('/admin/contracts/add-tentant')}}
                        />
                        </Tooltip>
                      ):('')}
                      </Box>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </>
  );
};

export default Inquiries;
