import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      width: "70%",
      margin: "0 auto",
      padding: theme.spacing(2),
      boxSizing: "border-box",
      border: "1px solid #ccc",
      borderRadius: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "70vh",
        margin: "100px 0px 0px 0px",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        minHeight: "70vh",
        margin: "110px 0px 0px 0px",
      },
    },
    Sroot: {
      width: "100%",
      maxWidth: "600px",
      padding: theme.spacing(3),
      boxSizing: "border-box",
    },
    rootimage: {
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        margin: "0px 0px 30px 0px",
      },
    },
    image: {
      display: "inline-block",
    },
    form: {
      width: "100%",
    },
    INput: {
      width: "100%",
      marginBottom: theme.spacing(1),
      backgroundColor: "rgba(239, 239, 239, 1)",
      borderRadius: "6px",
      // border: "1px solid rgba(0, 0, 0, 0.23)", // Default border for inputs
      // padding: "10px 15px", // Normal padding for larger screens
    },
    bph: {
      width: "100%",
    },

    Poproot: {
      width: "420px",
      height: "250px",
      transform: "translate(-70%, -50%)",
      border: "1px solid rgba(154, 25, 21, 1)",
      borderRadius: "30px",
      margin: "0px 0px 40px 220px",
      [theme.breakpoints.down("sm")]: {
        width: "350px",
        transform: "translate(-50%, -50%)",
        margin: "0px 0px 0px 0px",
      },
    },
    img: {
      width: "60px",
      margin: "35px 0px 0px 150px",
      [theme.breakpoints.down("sm")]: {
        margin: "30px 0px 5px 125px",
      },
    },
    heading: {
      fontFamily: "Outfit",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "15px",
      color: "rgba(0, 0, 0, 1)",
      margin: "0px 0px 0px 65px",
      [theme.breakpoints.down("sm")]: {
        margin: "0px 0px 0px 30px",
      },
    },
    btns: {
      margin: "15px 0px 0px 88px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px 0px 0px 60px",
      },
    },
  };
});

export const customSearchField = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    height: "2.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },
};

export const customTextField = {
  "& .MuiOutlinedInput-root": {
    // borderRadius: "50px",
    // height: "3.5rem", // Adjust the height here
    backgroundColor: "rgba(239, 239, 239, 1)",
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },
};
