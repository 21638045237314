import { styled, Typography, Box, Grid, Button } from "@mui/material";

// Maintenance.jsx Styles:

export const StyledTypography = styled(Typography)(({ theme, isActive }) => ({
  fontSize: theme.breakpoints.up("md") ? "20px" : "15px",
  fontWeight: "500",
  fontFamily: "Outfit",
  color: isActive ? "#B81918" : "#ADADAD",
  borderBottom: isActive ? "2.5px solid #B81918" : "none",
  cursor: "pointer",
}));

export const StyledMainBox = styled(Box)({
  marginBottom: "2rem",
});

export const StyledContainerBox = styled(Box)({
  display: "flex",
  gap: "3rem",
});

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.breakpoints.up("md") ? "25px" : "20px",
  fontWeight: "500",
  fontFamily: "Outfit",
  color: "#000000",
}));

// MaintenanceRequests and Complaints.jsx Styles:
export const StyledGrid = styled(Grid)({
  border: "1px solid #EFEFEF",
  borderRadius: "15px",
  maxHeight: "40rem",
  padding: "1rem",
  overflow: "auto",
  marginTop: "2rem",
});

export const StyledItemGrid = styled(Grid)({
  padding: "2rem",
});

export const InfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  border: "1px solid #EFEFEF",
  borderRadius: "15px",
  padding: "2rem",
  marginTop: "0.5rem",
  height: "3rem",
}));

export const InfoTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "15px",
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
  cursor: "pointer",
}));

// MaintenanceStatus.jsx

export const StatusTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Outfit",
  fontWeight: "500",
  color: "#000000",
}));

export const StatusButton = styled(Button)(({ theme }) => ({
  // variant: filled,
  backgroundColor: "#B81918",
  color: "#FFFFFF",
  height: "2.625rem",
  width: "8.938rem",
  borderRadius: "0.5rem",
  marginBottom: "1rem",
  [theme.breakpoints.down("lg")]: {
    marginTop: "0.5rem",
  },
  '&:hover':{
    backgroundColor:'#B81918',
    boxShadow: 4
  }
}));

export const UserDetailsTypography = styled(Typography)({
  padding: "0.5rem",
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "20px",
});

export const UserDetailsTypography2 = styled(Typography)({
  // padding: "0.5rem",
  display: "flex",
  alignItems: "center",
  paddingLeft: "2rem",
  backgroundColor: "#F10A0A08",
  color: "#737679",
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "16px",
  borderRadius: "8px",
  height: "3rem",
});

export const UserDetailsBox = styled(Box)(({ theme }) => ({
  width: "22rem",
  [theme.breakpoints.up("md")]: {
    width: "42rem", // Width for md and above breakpoints
  },
  height: "5.625rem",
  marginTop: "1rem",
  // padding: "3rem",
}));

export const UrgencyBox = styled(Box)({
  display: "flex",
  alignItems: "center",

  backgroundColor: "#F10A0A08",

  borderRadius: "8px",
  height: "3rem",
});

export const ImgsBox = styled(Box)({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  backgroundColor: "#F10A0A08",
  overflow: "auto",

  borderRadius: "8px",
  height: "8.365rem",
});

// Complaints Status:

export const NameBox = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  justifyContent: "space-between",
  width: "82rem",
}));

export const ComplaintsDetailBox = styled(Box)(({ theme }) => ({
  width: "22rem",
  [theme.breakpoints.up("md")]: {
    width: "30rem", // Width for md and above breakpoints
  },
  height: "5.625rem",
  marginTop: "2rem",
  // padding: "3rem",
}));

export const ComplaintAdresBox = styled(Box)(({ theme }) => ({
  // // width: "25rem",

  // [theme.breakpoints.up("sm")]: {
  //   width: "62rem", // Width for md and above breakpoints
  // },
  height: "5.625rem",
  marginTop: "2rem",
  // padding: "3rem",
}));

export const ComplaintDescriptionBox = styled(Box)(({ theme }) => ({
  width: "62rem",
  [theme.breakpoints.down("sm")]: {
    width: "22rem",
  },
}));
