import React, { useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Grid, Card, CardContent, CardActionArea } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { FormControl, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useStyles } from "./PropertieStyle";
import AddProperty from "./AddProperty";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { endpoints } from "../../../../apiEndpoints";
import axios from "axios";
import { PropertyType, PropertyList } from "../../../../enums/UserTypes";
import { Loader } from "../../../../utils/Loader";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BulkUpload from "../BlukUpload";

const Propertie = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    type: "",
    price: "",
    name: "",
  });
  const [OpenCom, setOpenCom] = useState(false);
  const [PropertyData, setPropertyData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  // Open the modal
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  
  //   setBulkUploadFile(e.target.files[0])

  // };

  const propertyList = async () => {
    setLoading(true); // Start loading when applying the filter
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endpoints.Propertylist}?type=${selectedValue.type}&price=${selectedValue.price}&name=${selectedValue.name}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const data = response.data.data;
      setPropertyData(data);

      if (!data || data.length === 0) {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    propertyList();
  }, []);

  useEffect(() => {
    if (selectedValue.type === "") {
      propertyList();
    }
  }, [selectedValue.type]);

  const handleApplyFilter = () => {
    setPropertyData([]);
    propertyList();
  };

  const OpenComponent = () => {
    setOpenCom(true);
  };

  if (OpenCom) {
    return <AddProperty />;
  }

  const handleCardClick = (property) => {
    const imageAttachment = property.attachments?.find(
      (attachment) =>
        attachment.media.endsWith(".jpg") || attachment.media.endsWith(".png")
    );

    navigate("/admin/selection/propertyDetail", {
      state: {
        id: property.id,
        image: imageAttachment
          ? imageAttachment.media
          : "/image/propertyicon.png",
        address: property.address,
        title: property.title,
        ...property,
      },
    });
  };

  const getStatusText = (status) => {
    return PropertyList[status] || "Unknown Status";
  };

  

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          width: "100%",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "1rem" },
        }}
      >
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          Property
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: "1rem" },
          }}
        >
          <Button
            style={{
              backgroundColor: "rgba(184, 25, 24, 1)",
              fontSize: "14px",
              lineHeight: "16px",
              fontWeight: 500,
              textTransform: "none",
              width: "140px",
              height: "30px",
            }}
            variant="contained"
            onClick={OpenComponent}
          >
            Add New
          </Button>
          <Box>
            <Button
              startIcon={<CloudUploadIcon />}
              style={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "140px",
                height: "30px",
              }}
              variant="contained"
              component="span"
              onClick={handleOpenModal}
            >
              Bulk Upload
            </Button>
            <BulkUpload open={modalOpen} onClose={handleCloseModal} component = {'Property'} listing = {propertyList}/>
          </Box>
        </Box>
      </Box>

      <Box className={classes.mianroot}>
        <Box className={classes.root} sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4} lg={3}>
              <div style={{ position: "relative" }}>
                <input
                  type="search"
                  style={{
                    width: "100%",
                    height: "40px",
                    border: "1px solid rgba(239, 239, 239, 1)",
                    borderRadius: "30px",
                    paddingLeft: "40px",
                  }}
                  placeholder="Search by Name"
                  name="name"
                  value={selectedValue.name}
                  onChange={handleInputChange}
                  size="small"
                />
                <div
                  style={{ position: "absolute", top: "10px", left: "10px" }}
                >
                  <SearchIcon />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <input
                type="search"
                name="price"
                value={selectedValue.price}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid rgba(239, 239, 239, 1)",
                  borderRadius: "30px",
                  padding: "0px 0px 0px 20px",
                }}
                placeholder="Search Price"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl fullWidth>
                <Select
                  name="type"
                  value={selectedValue.type}
                  onChange={handleInputChange}
                  displayEmpty
                  required
                  style={{
                    width: "100%",
                    height: "40px",
                    border: "1px solid rgba(239, 239, 239, 1)",
                    borderRadius: "30px",
                    padding: "0px 0px 0px 20px",
                  }}
                >
                  <MenuItem value="">All Properties</MenuItem>
                  <MenuItem value={PropertyType.RESIDENTIAL}>
                    Residential
                  </MenuItem>
                  <MenuItem value={PropertyType.COMMERCIAL}>
                    Commercial
                  </MenuItem>
                  <MenuItem value={PropertyType.COMMUNITY}>Community</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Button
                fullWidth
                style={{
                  border: "1px solid rgba(184, 25, 24, 1)",
                  borderRadius: "30px",
                  color: "rgba(184, 25, 24, 1)",
                  textTransform:'none'
                }}
                variant="outlined"
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: "24px",
              lineHeight: "24px",
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            All Properties
          </Typography>
        </Box>

        {loading ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"70vh"}
          >
            <Loader />
          </Box>
        ) : PropertyData.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <Typography>No data available</Typography>
          </Box>
        ) : (
          <Grid container spacing={3} mt={2} mb={3}>
            {PropertyData?.map((item, index) => {
              const imageAttachment = item.attachments?.find(
                (attachment) =>
                  attachment.media.endsWith(".jpg") ||
                  attachment.media.endsWith(".png")
              );
              return (
                <Grid item xs={12} sm={4} md={4} lg={3} key={index}>
                  <Card
                    sx={{ maxWidth: 345 }}
                    style={{ marginBottom: "5px", height: "230px" }}
                    onClick={() => handleCardClick(item)}
                  >
                    <CardActionArea>
                      <CardMedia>
                        {imageAttachment ? (
                          <img
                            src={imageAttachment.media}
                            alt={`Property ${item.id} Attachment`}
                            width="100%"
                            height="140"
                          />
                        ) : (
                          <img
                            src="/image/propertyicon.png"
                            alt="Default"
                            width="100%"
                            height="140"
                          />
                        )}
                      </CardMedia>
                      <CardContent>
                        <Typography
                          style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "10px",
                            lineHeight: "12px",
                            margin: "0px 0px 0px 5px",
                            color: "#000000",
                          }}
                        >
                          {item.ref_id}
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Outfit",
                            fontWeight: 900,
                            fontSize: "14px",
                            lineHeight: "12px",
                            margin: "8px 0px 7px 5px",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Box style={{ display: "flex" }}>
                          <Box style={{ color: "rgba(115, 118, 121, 1)" }}>
                            <LocationOnRoundedIcon
                              style={{ fontSize: "15px" }}
                            />
                          </Box>
                          <Typography
                            style={{
                              fontFamily: "Outfit",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "12px",
                              margin: "2px 0px 0px 3px",
                              color: "#737679",
                            }}
                          >
                            {`${item.address}, ${getStatusText(item.type)}`}
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "12px",
                            margin: "0px 0px 0px 25px",
                          }}
                        ></Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Propertie;
