import React, { useState, useEffect } from "react";
import {
  StyledGrid,
  StyledItemGrid,
  InfoBox,
  InfoTypography,
} from "./MaintenanceStyles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../../../apiEndpoints";
import axios from "axios";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";

function Complaints() {
  const [loading, setLoading] = useState(true);
  const [complaintdata, setcomplaintdata] = useState([]);
  const navigate = useNavigate();

  const handleCardClick = (request) => {
    navigate("/admin/complaints-status", {
      state: {
        id: request.id,
        ...request,
      },
    });
  };

  const getcomplain = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${endpoints.getComplainrequest}`, {
        headers: { Authorization: `Token ${token}` },
      });
      setcomplaintdata(response.data.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
     
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getcomplain();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
          ) : complaintdata?.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50vh"
            >
              <Typography>No data available</Typography>
            </Box>
      ) : (
        <StyledGrid container xs={12} spacing={1}>
          {complaintdata?.map((item, index) => (
            <StyledItemGrid key={index} item xs={12}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontWeight: "500",
                    fontSize: { xs: "15px", sm: "15px", md: "20px" },
                  }}
                >
                  {item.created_at.split("T")[0]}
                </Typography>
              </Box>
              <InfoBox>
                <InfoTypography onClick={() => handleCardClick(item)}>
                  {item.first_name}
                </InfoTypography>
              </InfoBox>
            </StyledItemGrid>
          ))}
        </StyledGrid>
      )}
    </>
  );
}

export default Complaints;
