import React from "react";
import { useState } from "react";
import { Box, Typography, Button, Grid, Tabs, Tab } from "@mui/material";
import { useStyles } from "../Properties/Propertie/PropertieStyle";
import DataTable from "./Table";
import SearchIcon from "@mui/icons-material/Search";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import AddDocument from "./AddDocument";
import RequestDetail from "./RequestDetail";
import { useNavigate } from "react-router-dom";


const SOAColumns = [
  { id: "Date", label: "Date Range Requested" },
  { id: "property", label: "Property" },
  { id: "Landlord", label: "Landlord" },
  { id: "landlordApproval", label: "Landlord Approval" },,
  { id: "Action", label: "Action" },
  { id: "Download", label: "Download" },
];

const MaintenanceColumns = [
  { id: "Document", label: "Document Name" },
  { id: "LName", label: "Landlord Name" },
  { id: "TName", label: "Tenant Name" },
  { id: "unit", label: "Unit" },
  { id: "CreatedAt", label: "Sub Property" },
  { id: "Action", label: "Action" },
];
const DocumentColumns = [
  { id: "Document", label: "Document Name" },
  { id: "LName", label: "Landlord Name" },
  { id: "TName", label: "Tenant Name" },
  { id: "unit", label: "Unit" },
  { id: "CreatedAt", label: "Sub Property" },
  { id: "Action", label: "Action" },
];

const SOAData = [
  {
    id: "1",
    Date: "25 Jan -4 May 2024 ",
    property: "New Tower",
    Landlord: "Rayan",
    Action: "View",
    Download: (
      <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    ),
  },
  {
    id: "2",
    Date: "25 Jan -4 May 2024 ",
    property: "New Tower",
    Landlord: "Rayan",
    Action: "View",
    Download: (
      <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    ),
  },
  {
    id: "3",
    Date: "25 Jan -4 May 2024 ",
    property: "New Tower",
    Landlord: "Rayan",
    Action: "View",
    Download: (
      <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    ),
  },
  {
    id: "4",
    Date: "25 Jan -4 May 2024 ",
    property: "New Tower",
    Landlord: "Rayan",
    Action: "View",
    Download: (
      <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    ),
  },
  {
    id: "5",
    Date: "25 Jan -4 May 2024 ",
    property: "New Tower",
    Landlord: "Rayan",
    Action: "View",
    Download: (
      <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    ),
  },
  {
    id: "6",
    Date: "25 Jan -4 May 2024 ",
    property: "New Tower",
    Landlord: "Rayan",
    Action: "View",
    Download: (
      <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    ),
  },
  {
    id: "7",
    Date: "25 Jan -4 May 2024 ",
    property: "New Tower",
    Landlord: "Rayan",
    Action: "View",
    Download: (
      <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    ),
  },
  {
    id: "8",
    Date: "25 Jan -4 May 2024 ",
    property: "New Tower",
    Landlord: "Rayan",
    Action: "View",
    Download: (
      <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    ),
  },
  {
    id: "9",
    Date: "25 Jan -4 May 2024 ",
    property: "New Tower",
    Landlord: "Rayan",
    Action: "View",
    Download: (
      <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    ),
  },
  {
    id: "10",
    Date: "25 Jan -4 May 2024 ",
    property: "New Tower",
    Landlord: "Rayan",
    Action: "View",
    Download: (
      <SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
    ),
  },
];

const MaintenanceData = [
  {
    id: "1",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "2",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "3",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "4",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "5",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "6",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "7",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
];
const DocumentData = [
  {
    id: "1",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "2",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "3",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "4",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "5",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "6",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
  {
    id: "7",
    Document: "Maintenance Reports",
    LName: "Landlord 1",
    TName: "Tenant 1",
    unit: "305",
    CreatedAt: "05-04-2024",
    Action: (
      <>
        <SystemUpdateAltOutlinedIcon
          style={{ color: "rgba(185, 16, 15, 1)" }}
        />
        <DeleteIcon style={{ color: "rgba(185, 16, 15, 1)" }} />
      </>
    ),
  },
];

const Documents = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [Open, setOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const TabPanel = ({ children, value, index }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ padding: "20px 0px 0px 0px" }}>{children}</Box>
      )}
    </div>
  );

  const a11yProps = (index) => ({
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  });

  // Tentants Data
  const [searchValue, setSearchValue] = useState("");
  const [secondSelectValue, setSecondSelectValue] = useState("");
  const [thirdSelectValue, setThirdSelectValue] = useState("");

  const handleApplyFilter = () => {
    console.log("Search Value:", searchValue);
    console.log("Second Select Value:", secondSelectValue);
    console.log("Third Select Value:", thirdSelectValue);
  };

  const handleDocument = () => {
    navigate('/admin/documents/add-document');
  };
 
  return (
    <>
      <Box>
        {/* SOA Data */}
        {selectedTab === 0 && (
          <>
            <>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Documents
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    style={{
                      backgroundColor: "rgba(184, 25, 24, 1)",
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: 500,
                      textTransform: "none",
                      width: "130px",
                      height: "30px",
                    }}
                    variant="contained"
                    onClick={handleDocument}
                  >
                    Add New
                  </Button>
                </Box>
              </Box>
              {/* <Box className={classes.root} sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4} lg={3}>
                    <div style={{ position: "relative" }}>
                      <input
                        type="search"
                        style={{
                          width: "100%",
                          height: "40px",
                          border: "1px solid rgba(239, 239, 239, 1)",
                          borderRadius: "30px",
                          paddingLeft: "40px",
                        }}
                        placeholder="Search by Landlord name"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        size="small"
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "10px",
                        }}
                      >
                        <SearchIcon />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <input
                      type="search"
                      style={{
                        width: "100%",
                        height: "40px",
                        border: "1px solid rgba(239, 239, 239, 1)",
                        borderRadius: "30px",
                        padding: "0px 0px 0px 20px",
                      }}
                      placeholder="Search by Unit/Property"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <input
                      type="search"
                      style={{
                        width: "100%",
                        height: "40px",
                        border: "1px solid rgba(239, 239, 239, 1)",
                        borderRadius: "30px",
                        padding: "0px 0px 0px 20px",
                      }}
                      placeholder="Search By Date"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <Button
                      fullWidth
                      style={{
                        border: "1px solid rgba(184, 25, 24, 1)",
                        borderRadius: "30px",
                        color: "rgba(184, 25, 24, 1)",
                      }}
                      variant="outlined"
                      onClick={handleApplyFilter}
                    >
                      Apply Filter
                    </Button>
                  </Grid>
                </Grid>
              </Box> */}
            </>
            {/* Tentants Components End */}
          </>
        )}
        {/* Maintenance Data */}
        {selectedTab === 1 && (
          <>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                Documents
              </Typography>
            </Box>
            {/* <Box className={classes.root} sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4} lg={3}>
                  <div style={{ position: "relative" }}>
                    <input
                      type="search"
                      style={{
                        width: "100%",
                        height: "40px",
                        border: "1px solid rgba(239, 239, 239, 1)",
                        borderRadius: "30px",
                        paddingLeft: "40px",
                      }}
                      placeholder="Search by Landlord name"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      size="small"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                      }}
                    >
                      <SearchIcon />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <input
                    type="search"
                    style={{
                      width: "100%",
                      height: "40px",
                      border: "1px solid rgba(239, 239, 239, 1)",
                      borderRadius: "30px",
                      padding: "0px 0px 0px 20px",
                    }}
                    placeholder="Search by Unit/Property"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <input
                    type="search"
                    style={{
                      width: "100%",
                      height: "40px",
                      border: "1px solid rgba(239, 239, 239, 1)",
                      borderRadius: "30px",
                      padding: "0px 0px 0px 20px",
                    }}
                    placeholder="Search By Date"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <Button
                    fullWidth
                    style={{
                      border: "1px solid rgba(184, 25, 24, 1)",
                      borderRadius: "30px",
                      color: "rgba(184, 25, 24, 1)",
                    }}
                    variant="outlined"
                    onClick={handleApplyFilter}
                  >
                    Apply Filter
                  </Button>
                </Grid>
              </Grid>
            </Box> */}
          </>
        )}
        {selectedTab === 2 && (
          <>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                Documents
              </Typography>
            </Box>
            {/* <Box className={classes.root} sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4} lg={3}>
                  <div style={{ position: "relative" }}>
                    <input
                      type="search"
                      style={{
                        width: "100%",
                        height: "40px",
                        border: "1px solid rgba(239, 239, 239, 1)",
                        borderRadius: "30px",
                        paddingLeft: "40px",
                      }}
                      placeholder="Search by Landlord name"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      size="small"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                      }}
                    >
                      <SearchIcon />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <input
                    type="search"
                    style={{
                      width: "100%",
                      height: "40px",
                      border: "1px solid rgba(239, 239, 239, 1)",
                      borderRadius: "30px",
                      padding: "0px 0px 0px 20px",
                    }}
                    placeholder="Search by Unit/Property"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <input
                    type="search"
                    style={{
                      width: "100%",
                      height: "40px",
                      border: "1px solid rgba(239, 239, 239, 1)",
                      borderRadius: "30px",
                      padding: "0px 0px 0px 20px",
                    }}
                    placeholder="Search By Date"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <Button
                    fullWidth
                    style={{
                      border: "1px solid rgba(184, 25, 24, 1)",
                      borderRadius: "30px",
                      color: "rgba(184, 25, 24, 1)",
                    }}
                    variant="outlined"
                    onClick={handleApplyFilter}
                  >
                    Apply Filter
                  </Button>
                </Grid>
              </Grid>
            </Box> */}
          </>
        )}
      </Box>
      <Box style={{ margin: "18px 0px 0px 0px" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="navigation tabs"
          TabIndicatorProps={{
            style: { backgroundColor: "rgba(184, 25, 24, 1)", height: "1px" },
          }}
        >
          <Tab
            style={{
              color: selectedTab === 0 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 0 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label=" Documents"
            {...a11yProps(0)}
          />
          <Tab
            style={{
              color: selectedTab === 1 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 1 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="SOA"
            {...a11yProps(1)}
          />
          <Tab
            style={{
              color: selectedTab === 2 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 2 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Maintenance Reports"
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <DataTable
            Data={DocumentData}
            columns={DocumentColumns}
            selectedTab={"Documents"}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <DataTable Data={SOAData} columns={SOAColumns} selectedTab={"SOA"} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <DataTable
            Data={MaintenanceData}
            columns={MaintenanceColumns}
            selectedTab={"Maintenance"}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default Documents;
