import { styled, Typography, TextField, Grid, Box } from "@mui/material";


export const MainHeading = styled(Typography)({
    fontFamily: "Outfit",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 1)"
});

export const MainBox = styled(Box)(({ theme })=>({
    margin: "150px auto 0px ",
    width: "50%",
    [theme.breakpoints.down("sm")]:{
        margin: "200px auto", 
        width: "calc(100% - 46px)"
    },
}))
