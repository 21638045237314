import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate()

    useEffect(()=>{
        localStorage.removeItem("token")
        localStorage.removeItem("loggedInUser")
        localStorage.removeItem("usertype")
        localStorage.removeItem("id")
        navigate('/')
        
    })
    return (
        <>
        </>
    )
}

export default Logout;
