import React from 'react';
import menuData from './menuData';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Fragment } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Nav from './Nav';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Properties from '../Pages/Properties/Properties';
import Property from '../Pages/Property/Property';
import Financial from '../Pages/Financial/Financial';
import SOA from '../Pages/SOA/SOA';
import Documents from '../Pages/Documents/Documents';
import Maintenance from '../Pages/Maintenance/Maintenance';
import Chats from '../Pages/Chats/Chats';
import Notification from '../Pages/Notifications/Notifications';
import PropertyDetail from '../Pages/Properties/AllProperty/PropertyDetail';
import PropertyProfile from '../Pages/Properties/AllProperty/PropertyProfile';
import AllUnitDetail from '../Pages/Properties/AllUnit/AllUnitDetail';
import ComplainProfile from '../Pages/Properties/AllProperty/ComplainProfile';
import UnitComplainProfile from '../Pages/Properties/AllUnit/UnitComplainProfile';
import AMCSchedule from '../Pages/AMCSchedule/AMCSchedule';
import MaintenanceReport from '../Pages/Maintenance/MaintenanceReport';
import InfoProfile from './InfoProfile';
import ChatBox from '../Pages/Chats/ChatBox';
import TermsConditions from '../Pages/Terms&Conditions/Terms&Conditions';





const useStyles = makeStyles(() => {
  const theme = useTheme();
  console.log(theme);

  return {
    root: {
      width: "100%",
      minHeight: "100vh",
      height: "auto",
      boxSizing: "border-box",
      padding: "0px 24px 0px 300px",
      [theme.breakpoints.down("lg")]: {
        padding: "0px 24px 24px 24px",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "50px 0px 0px 0px",
      },
    },

  };
});
const LandlordPortal = () => {
  const classes = useStyles();
  return (

    <Fragment>
      <Nav menuData={menuData} />
      <Box className={classes.root}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/property" element={<Property />} />
          <Route path="/financial" element={<Financial />} />
          <Route path="/soa" element={<SOA />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/chats" element={<Chats />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/propertiesdetail" element={<PropertyDetail />} />
          <Route path="/propertyprofile" element={<PropertyProfile />} />
          <Route path="/properties/allUnitdetail" element={<AllUnitDetail />} />
          <Route path="/complainprofile" element={<ComplainProfile />} />
          <Route path="/unitcomplainprofile" element={<UnitComplainProfile />} />
          <Route path="/amcSchedule" element={<AMCSchedule />} />
          <Route path="terms&conditions" element={<TermsConditions/>}/>
          <Route path="/maintenancereport" element={<MaintenanceReport />} />
          <Route path="/infoProfile" element={<InfoProfile />} />
          <Route path="/message" element={<ChatBox />} />



        </Routes>
      </Box>
    </Fragment>

  );
};

export default LandlordPortal;
