import React, { useEffect } from "react";
import { Box, Typography, Grid, TextField, Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Button,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { newIncomeTextfield } from "./ContractStyle";
import axios from "axios";
import { UserTypesNum } from "../../../enums/UserTypes";
import toast from "react-hot-toast";
import { endpoints } from "../../../apiEndpoints";
import { useLocation, useNavigate } from "react-router-dom";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const EditLandlord = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { contractData } = location.state || {};
  const [units, setUnits] = useState(contractData?.unit || []);

  const [unitsIds, setUnitsIds] = useState(
    contractData?.unit?.map((unit) => unit.id) || []
  );

  const [secondaryLandlords, setSecondaryLandlords] = useState(
    contractData?.secondary_landlord || []
  );
  const [secondaryLandlordsIds, setSecondaryLandlordsIds] = useState(
    contractData?.secondary_landlord?.map((landlord) => landlord.id) || []
  );

  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState(null);
  const [dates, setDates] = useState({
    startDate: contractData.start_date,
    endDate: contractData.end_date,
  });
  const [propertyAndPrimaryLandlord, setPropertyAndPrimaryLandlord] = useState({
    property: contractData.property,
    primaryLandlord: contractData.primary_landlord,
  });
  const [propertyListing, setPropertyListing] = useState(null);
  const [unitListing, setUnitListing] = useState(null);
  const [landlordListing, setLandlordListing] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfLetter, setPdfLetter] = useState(null);
  const [error, setError] = useState(null);

  const handlePdfLoadError = (error) => {
    setError(error);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (pdfUrl) => {
    setPdfLetter(pdfUrl);
    setIsModalOpen(true);
  };

  const handleDatesChange = (e) => {
    const { name, value } = e.target;
    setDates({
      ...dates,
      [name]: value,
    });
  };

  const handlePropertyAndPrimaryLandlordChange = (e) => {
    const { name, value } = e.target;
    setPropertyAndPrimaryLandlord((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        id: value,
      },
    }));
  };
  const addField = () => setUnits((prevFields) => [...prevFields, ""]);

  const addInput = () =>
    setSecondaryLandlords((prevInputs) => [...prevInputs, {}]);

  const handleUnitsChange = (index, value) => {
    const unitIdsList = [...unitsIds];
    if (!unitsIds.includes(value)) {
      unitIdsList[index] = value;
      setUnitsIds([...unitIdsList]);
      setUnits((prevInputs) =>
        prevInputs.map((unit, i) =>
          i === index ? { ...unit, id: value } : unit
        )
      );
    } else {
    }
    // setUnits((prevFields) =>
    //   prevFields.map((field, i) => (i === index ? value : field))
    // );
  };

  const handleLandlordChange = (index, value) => {
    const idsList = [...secondaryLandlordsIds];
    if (!secondaryLandlordsIds.includes(value)) {
      idsList[index] = value;
      setSecondaryLandlordsIds([...idsList]);
      setSecondaryLandlords((prevInputs) =>
        prevInputs.map((input, i) =>
          i === index ? { ...input, id: value } : input
        )
      );
    } else {
    }
  };

  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile1(file);
      // setFileUrl1(URL.createObjectURL(file));
    }
  };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile2(file);
      // setFileUrl2(URL.createObjectURL(file));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("start_date", dates.startDate);
      formData.append("end_date", dates.endDate);
      formData.append("unit", JSON.stringify(unitsIds));
      formData.append("property", propertyAndPrimaryLandlord.property.id);
      formData.append(
        "primary_landlord",
        propertyAndPrimaryLandlord.primaryLandlord.id
      );
      formData.append(
        "secondary_landlord",
        JSON.stringify(secondaryLandlordsIds)
      );
      if (file2) {
        formData.append("agreement_doc", file2);
      }
      if (file1) {
        formData.append("title_deed_doc", file1);
      }

      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };

      const response = await axios.patch(
        `${endpoints.landlordContracts}?contract_id=${contractData.id}`,
        formData,
        requestOptions
      );
      toast.success(response.data.message);
      navigate("/admin/contracts");
    } catch (error) { }
  };
  const getPropertyListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        endpoints.getPropertiesListing,
        requestOptions
      );
      setPropertyListing(response.data.data);
    } catch (error) { }
  };
  const getUnitListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        endpoints.getUnitsListing,
        requestOptions
      );
      setUnitListing(response.data.data);
    } catch (error) { }
  };
  const getLandlordsListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        `${endpoints.getUserListing}?user_type=${UserTypesNum.landlord}`,
        requestOptions
      );
      setLandlordListing(response.data.data);
    } catch (error) { }
  };

  useEffect(() => {
    getPropertyListing();
    getUnitListing();
    getLandlordsListing();
  }, []);

  return (
    <>
      <>
        <Box>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontSize: "24px",
              lineHeight: "24px",
              fontWeight: 500,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            Add Contract
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            mt={2}
          >
            <Grid item xs={12} md={6}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Start Date
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                type="date"
                name="startDate"
                value={dates.startDate}
                onChange={handleDatesChange}
                sx={newIncomeTextfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                End Date
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                type="date"
                name="endDate"
                value={dates.endDate}
                onChange={handleDatesChange}
                sx={newIncomeTextfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Property
              </Typography>

              <Grid item xs={12}>
                <FormControl fullWidth sx={newIncomeTextfield}>
                  <Select
                    name="property"
                    value={propertyAndPrimaryLandlord.property.id}
                    onChange={handlePropertyAndPrimaryLandlordChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Property
                    </MenuItem>
                    {propertyListing?.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {`${item.ref_id} - ${item.title}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Unit
              </Typography>
              {units.map((unit, index) => {
                return (
                  <Grid item xs={12} key={index} marginBottom={"1rem"}>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                      <Select
                        value={unit.id}
                        onChange={(e) =>
                          handleUnitsChange(index, e.target.value)
                        }
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select Unit
                        </MenuItem>
                        {unitListing?.map((item, index) => {
                          {
                            const filteredListing = unitsIds.filter(
                              (id) => id !== unit.id
                            );
                            if (!filteredListing.includes(item.id)) {
                              return (
                                <MenuItem value={item.id} key={index}>
                                  {`${item.ref_id} - ${item.title}`}
                                </MenuItem>
                              );
                            }
                          }
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                );
              })}

              <IconButton
                // variant="contained"
                onClick={addField}
                sx={{ color: "#B81918" }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Primary Landlord
              </Typography>
              <FormControl fullWidth sx={newIncomeTextfield}>
                <Select
                  name="primaryLandlord"
                  value={propertyAndPrimaryLandlord.primaryLandlord.id}
                  onChange={handlePropertyAndPrimaryLandlordChange}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Primary Landlord
                  </MenuItem>
                  {landlordListing?.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.first_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Secondary Landlord
              </Typography>
            </Grid>
            {secondaryLandlords.map((input, index) => {
              return (
                <>
                  {" "}
                  <Grid item xs={12} sm={12} key={index}>
                    <FormControl fullWidth sx={newIncomeTextfield}>
                      <Select
                        value={input.id}
                        onChange={(e) =>
                          handleLandlordChange(index, e.target.value)
                        }
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select Secondary Landlord
                        </MenuItem>
                        {landlordListing?.map((item, listingIndex) => {
                          {
                            const filteredListing =
                              secondaryLandlordsIds.filter(
                                (id) => id !== input.id
                              );
                            if (!filteredListing.includes(item.id)) {
                              return (
                                <MenuItem value={item.id} key={listingIndex}>
                                  {item.first_name}
                                </MenuItem>
                              );
                            }
                          }
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              );
            })}

            <Grid item xs={12} md={12}>
              <IconButton
                // variant="contained"
                onClick={addInput}
                sx={{ color: "#B81918" }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>

            {/* First file uploader */}
            <Grid item xs={12} md={6}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Title Deed
              </Typography>
              <Box
                sx={{
                  p: 5,
                  border: 1,
                  borderColor: "grey.300",
                  borderRadius: 2,
                  textAlign: "center",
                }}
              >
                <Box display={"flex"} gap={"1rem"}>
                  <input
                    accept="*/*" // Accept all file types
                    type="file"
                    onChange={handleFileChange1}
                    style={{ display: "none" }}
                    id="file-input-1"
                    required
                  />

                  <label htmlFor="file-input-1">
                    <Button
                      variant="contained"
                      component="span"
                      style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        textTransform: "none",
                        borderRadius: "8px",
                        margin: "100px 0px 100px 0px",
                      }}
                    >
                      Select and Upload File
                    </Button>
                  </label>
                  <Button
                    variant="contained"
                    component="span"
                    style={{
                      backgroundColor: "rgba(184, 25, 24, 1)",
                      textTransform: "none",
                      borderRadius: "8px",
                      margin: "100px 0px 100px 0px",
                    }}
                    onClick={() => {
                      handleModalOpen(contractData.title_deed_doc);
                    }}
                  >
                    View Uploaded File
                  </Button>
                </Box>
                {file1 && <Typography>{file1.name}</Typography>}
              </Box>
            </Grid>
            {/* Second file uploader */}
            <Grid item xs={12} md={6}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                PMA
              </Typography>
              <Box
                sx={{
                  p: 5,
                  border: 1,
                  borderColor: "grey.300",
                  borderRadius: 2,
                  textAlign: "center",
                }}
              >
                <Box display={"flex"} gap={"1rem"}>
                  <input
                    accept="*/*" // Accept all file types
                    type="file"
                    onChange={handleFileChange2}
                    style={{ display: "none" }}
                    id="file-input-2"
                    required
                  />
                  <label htmlFor="file-input-2">
                    <Button
                      variant="contained"
                      component="span"
                      style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        textTransform: "none",
                        borderRadius: "8px",
                        margin: "100px 0px 100px 0px",
                      }}
                    >
                      Select and Upload File
                    </Button>
                  </label>
                  <Button
                    variant="contained"
                    component="span"
                    style={{
                      backgroundColor: "rgba(184, 25, 24, 1)",
                      textTransform: "none",
                      borderRadius: "8px",
                      margin: "100px 0px 100px 0px",
                    }}
                    onClick={() => {
                      handleModalOpen(contractData.agreement_doc);
                    }}
                  >
                    View Uploaded File
                  </Button>
                </Box>
                {file2 && <Typography>{file2.name}</Typography>}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", margin: "20px 0 50px 0" }}>
            <Button
              style={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "130px",
                height: "40px",
              }}
              variant="contained"
              type="submit"
            >
              Save Changes
            </Button>
          </Box>
        </form>

        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          aria-labelledby="pdf-viewer-modal"
          aria-describedby="pdf-viewer-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              height: "90%",
              bgcolor: "white",
              boxShadow: 24,
              p: 4,
              borderRadius: "8px",
              overflow: "auto",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography gutterBottom>Uploaded File</Typography>
              <Button
                sx={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  color: "white",
                  fontSize: "18px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "5rem",
                  height: "2.5rem",
                  borderRadius: "0.5rem",
                  // margin: "20px 0px 50px 0px",
                }}
                onClick={handleModalClose}
              >
                Close
              </Button>
            </Box>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              {error ? (
                <div>Error loading PDF: {error.message}</div>
              ) : (
                <Viewer fileUrl={pdfLetter} />
              )}
            </Worker>
          </Box>
        </Modal>
      </>
    </>
  );
}

export default EditLandlord;
