import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  Input,
  Select,
  MenuItem,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  useStyles,
  textField,
  decsriptionTextField,
  textFieldTypography,
  submitBtnTypo,
} from "./MaintenanceStyles";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  setPhoto,
  updateMaintenanceForm,
} from "../../../Redux/TenantReducer/maintenanceSlice";
import { endpoints } from "../../../apiEndpoints";
import axios from "axios";
import { complaintPriority, Priorities } from "../../../enums/UserTypes";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";





function MaintenanceForm() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [maintenanceForm, setMaintenanceForm] = useState({
    name: "",
    email: "",
    adress: "",
    unitNumber: "",
    description: "",
    priority: "",
    photo: "",
  });
  const [units, setUnits] = useState(null);
  const [tenantDetails, setTenantDetails] = useState({});
  const [selectedUnit, setSelectedUnit] = useState(null);

  // For handleUpload image change
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile(file);
  };

  // For textFields change
  const handleFieldChange = (fieldName) => (event) => {
    setMaintenanceForm({
      ...maintenanceForm,
      [fieldName]: event.target.value,
    });
  };

  const getUnitsListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        endpoints.tenantUnitListing,
        requestOptions
      );
      setUnits(response.data.data);
    } catch { }
  };

  const getTenantDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(endpoints.tenantUnitListing, requestOptions);
      const tenantData = response.data.data[0].tenant_association[0].tenant;
      setMaintenanceForm((prev) => ({
        ...prev,
        email: tenantData.email,
      }));
    } catch (error) {
      console.error("Error fetching tenant details:", error);
    }
  };

  const handleUnitChange = (event) => {
    const selectedUnitId = event.target.value;
    const unit = units.find(unit => unit.id === selectedUnitId);
    setSelectedUnit(unit);
    setMaintenanceForm((prev) => ({
      ...prev,
      unitNumber: selectedUnitId,
      adress: unit ? unit.address : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const formData = new FormData();
      formData.append("name", maintenanceForm.name);
      formData.append("email", maintenanceForm.email);
      formData.append("unit", maintenanceForm.unitNumber);
      formData.append("priority", maintenanceForm.priority);
      if (maintenanceForm.adress) {
        formData.append("address", maintenanceForm.adress);
      }
      if (maintenanceForm.description) {
        formData.append("description", maintenanceForm.description);
      }
      if (selectedFile) {
        formData.append("media", selectedFile);
      }
      setLoading(true);
      const response = await axios.post(
        endpoints.maintenanceRequests,
        formData,
        requestOptions
      );
      toast.success(response.data.message);
      navigate("/tenant/maintenance");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUnitsListing();
    getTenantDetails();
  }, []);

  return (
    <>
      <Box>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: "500",
          }}
        >
          Maintenance Request Form
        </Typography>
      </Box>
      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <form onSubmit={handleSubmit}>
        <Grid
          container
          border={"1px solid #EFEFEF"}
          borderRadius={"1rem"}
          marginTop={"2rem"}
          // padding={"1rem"}
          spacing={2}
        >
          {/* Request Title */}
          <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Request Title</Typography>
            </Box>
            <TextField
              sx={textField}
              value={maintenanceForm.name}
              onChange={handleFieldChange("name")}
              fullWidth
            
            />
          </Grid>
          {/* Email */}
          <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Email</Typography>
            </Box>
            <TextField
              sx={textField}
              value={maintenanceForm.email}
              onChange={handleFieldChange("email")}
              type="email"
              fullWidth
              
            />
          </Grid>
          {/* Full Address */}
          <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Full Address</Typography>
            </Box>
            <TextField
              sx={textField}
              value={maintenanceForm.adress}
              onChange={handleFieldChange("adress")}
              fullWidth
            />
          </Grid>
          {/* Unit Number */}
          <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Unit Number</Typography>
            </Box>

            <FormControl fullWidth sx={{ ...textField }}>
              {/* <InputLabel id="unitNumber-label">Unit Number</InputLabel> */}
              <Select
                // labelId="unitNumber-label"
                // id="unitNumber"
                value={maintenanceForm.unitNumber}
                onChange={handleUnitChange}
                fullWidth
                // label="Unit Number"
                
                displayEmpty
              >
                <MenuItem value="">Select unit</MenuItem>
                {units?.map((unit) => (
                  <MenuItem value={unit.id} key={unit.id}>
                    {`${unit.ref_id} - ${unit.title}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Select Priority */}
          <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Urgency</Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: "400",
                }}
              >
                Select the priority
              </Typography>
            </Box>
            <Box className={classes.UserInputBox}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="options"
                  name="options"
                  value={maintenanceForm.priority}
                  onChange={handleFieldChange("priority")}
                  sx={{ display: "flex", flexDirection: "column" }} // Ensure the items are in a column
                >
                  <FormControlLabel
                    value={Priorities.high}
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#B81918", // Change the color of the checked dot
                          },
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "400",
                            color: "#000000",
                            fontFamily: "Outfit",
                          }}
                        >
                          High
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            lineHeight: "14px",
                            fontWeight: "400",
                            color: "#ADADAD",
                            fontFamily: "Outfit",
                          }}
                        >
                          (Must be done in 24 hours)
                        </Typography>{" "}
                        {/* Adjust spacing here */}
                      </Box>
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        // Target the label
                        fontSize: "16px", // Change the font size
                        lineHeight: "16px",
                        fontWeight: "500",
                        color: "#000000", // Change the color
                      },
                    }}
                  />
                  <FormControlLabel
                    value={Priorities.medium}
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#B81918", // Change the color of the checked dot
                          },
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "400",
                            color: "#000000",
                            fontFamily: "Outfit",
                          }}
                        >
                          Medium
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            lineHeight: "14px",
                            fontWeight: "400",
                            color: "#ADADAD",
                            fontFamily: "Outfit",
                          }}
                        >
                          (With in a week)
                        </Typography>{" "}
                        {/* Adjust spacing here */}
                      </Box>
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        // Target the label
                        fontSize: "16px", // Change the font size
                        lineHeight: "16px",
                        fontWeight: "500",
                        color: "#000000", // Change the color
                      },
                    }}
                  />
                  <FormControlLabel
                    value={Priorities.low}
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#B81918", // Change the color of the checked dot
                          },
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "400",
                            color: "#000000",
                            fontFamily: "Outfit",
                          }}
                        >
                          Low
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            lineHeight: "14px",
                            fontWeight: "400",
                            color: "#ADADAD",
                            fontFamily: "Outfit",
                          }}
                        >
                          (With in a week)
                        </Typography>{" "}
                        {/* Adjust spacing here */}
                      </Box>
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        // Target the label
                        fontSize: "16px", // Change the font size
                        lineHeight: "16px",
                        fontWeight: "500",
                        color: "#000000", // Change the color
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
          {/* Add Image/Video */}
          <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Add Image/Video</Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: "400",
                }}
              >
                Select the Image/Video
              </Typography>
            </Box>
            <Box className={classes.UserInputBox}>
              <label htmlFor="fileInput">
                <AddPhotoAlternateOutlinedIcon
                  sx={{ fontSize: "34px", color: "#B81918", cursor: "pointer" }}
                />
              </label>
              <Input
                id="fileInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: "10px",
                  lineHeight: "12px",
                  fontWeight: "500",
                }}
              >
                {selectedFile ? selectedFile.name : "Select File"}
              </Typography>
            </Box>
          </Grid>
          {/* Request Description */}
          <Grid item xs={12} sm={12} md={10}>
            <Box>
              <Typography sx={textFieldTypography}>
                Description of Request
              </Typography>
            </Box>
            <TextField
              sx={textField}
              value={maintenanceForm.description}
              onChange={handleFieldChange("description")}
              multiline
              rows={5}
              fullWidth
            />
          </Grid>
          {/* Submit Button */}
          <Grid item xs={12} sm={12} md={10}>
            <Button sx={submitBtnTypo} type="submit" disabled={loading} >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default MaintenanceForm;