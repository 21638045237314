import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    searchFieldBox: {
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      gap: "1rem",
      marginTop: "2rem",
    },
    mainBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "1rem",
      },
    },
    gridItemBox: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
  };
});

export const mainTypography = {
  fontFamily: "Outfit",
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "24px",
};

export const CustomTextField = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    height: "2.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },
};

export const createBtn = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#B81918",
  borderRadius: "8px",
  fontFamily: "Outfit",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: "400",
  color: "#FFFFFF",
  textTransform: "none",
  padding: "12px 1.5rem 12px",
  "&:hover": {
    backgroundColor: "#B81918", // Set the background color to inherit
  },
};

export const tableHeadCell = {
  backgroundColor: "#ADADAD30",
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "18px",
  borderRight: "1px solid #ADADAD50",
};

export const tableBodyCell = {
  fontFamily: "Outfit",
  fontSize: "12px",
  lineHeight: "15px",
  fontWeight: "400",
  color: "#737679",
};

export const subTypography = {
  fontFamily: "Outfit",
  fontSize: "18px",
  lineHeight: "18px",
  fontWeight: "400",
  color: "#000000",
};

export const newIncomeTextfield = {
  "& .MuiOutlinedInput-root": {
    // borderRadius: "50px",
    // height: "10.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },

  "& .MuiOutlinedInput-input[type='date']": {
    color: "#73767960", // Change the color of the input text for date type
  },
  "& .MuiSvgIcon-root": {
    color: "#737679", // Change the color of the calendar icon
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679", // Change placeholder color here
  },
};

export const newIncomeDescriptionfield = {
  "& .MuiOutlinedInput-root": {
    height: "10.5rem", // Adjust the height here
    alignItems: "flex-start",

    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679", // Change placeholder color here
  },
};

export const saveBtn = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#B81918",
  borderRadius: "8px",
  fontFamily: "Outfit",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: "350",
  color: "#FFFFFF",
  textTransform: "none",
  padding: "0.5rem",
  "&:hover": {
    backgroundColor: "#B81918",
  },
};

export const aplyfilter = {
  border: "1px solid #B81918",
  color: "#B81918",
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "12px",
  borderRadius: "30px",
  height: "2.5rem",
  padding: "0.5rem 1rem 0.5rem",
  textTransform: "none",
};
