import React from "react";
import { useState } from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import {
  InfoTypography,
} from "../../../../AdminComponent/Pages/Maintenance/MaintenanceStyles";



const PendingAction = ({ pendingActionsDetails, mainProperty }) => {
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + images.length > 5) {
      alert("You can only upload up to 5 images.");
      return;
    }
    setImages((prev) => [...prev, ...files]);
    const newImageUrls = files.map((file) => URL.createObjectURL(file));
    setImageURLs((prev) => [...prev, ...newImageUrls]);
  };

  return (
    <>
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        Pending Actions
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12} md={6} alignItems="center">
          <Box>
            <Box sx={{ display: "flex" }}>
              <img
                src={
                  mainProperty?.attachments &&
                    mainProperty.attachments.length > 0
                    ? mainProperty?.attachments[0].media
                    : "No image available"
                }
                onError={(e) => (e.target.src = "/image/propertyicon.png")}
                alt="this image is not show"
                width={450}
                height={250}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "35px",
              margin: "40px 0px 0px 0px",
            }}
          >
            {mainProperty?.title || "Title not available"}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              margin: "10px 0px 0px 0px",
            }}
          >
            {mainProperty?.description || "Description not available"}
          </Typography>
        </Grid>
      </Grid>

      <Box style={{ margin: "25px 0px 0px 0px" }}>
        <Divider />
      </Box>

      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 700,
          color: "rgba(0, 0, 0, 1)",
          margin: "20px 0px 10px 0px",
        }}
      >
        Pending Actions
      </Typography>

      <Grid
        container
        maxHeight={"20rem"}
        overflow={"auto"}
        marginBottom={"2rem"}
      >
        {
          pendingActionsDetails?.pending_actions && pendingActionsDetails?.pending_actions.length > 0 ? (

            pendingActionsDetails?.pending_actions?.map((action, index) => (
              <Grid
                item
                marginBottom={"0.5rem"}
                border={"1px solid #F1F1F1"}
                padding={2.5}
                borderRadius={"1rem"}
                xs={8}
                key={index}
              >
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: "400",
                  }}
                >
                  {index + 1}- {action.action}
                </Typography>
              </Grid>
            ))
          ) : (
            <InfoTypography>No Pending Action</InfoTypography>
          )}
      </Grid>

      {/* <Box style={{ margin: "20px 0px 40px 0px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Upload Emirates ID
            </Typography>
            <Box
              sx={{
                p: 5,
                border: 1,
                borderColor: "grey.300",
                borderRadius: 2,
                textAlign: "center",
              }}
            >
              <input
                accept="image/*"
                type="file"
                multiple
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="raised-button-file"
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="contained"
                  component="span"
                  style={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    textTransform: "none",
                    borderRadius: "8px",
                    margin: "100px 0px 100px 0px",
                  }}
                >
                  Select and Upload
                </Button>
              </label>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                {imageURLs.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Uploaded Preview ${index}`}
                    style={{ width: 100, height: 100, margin: 10 }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Upload Rental Agreement
            </Typography>
            <Box
              sx={{
                p: 5,
                border: 1,
                borderColor: "grey.300",
                borderRadius: 2,
                textAlign: "center",
              }}
            >
              <input
                accept="image/*"
                type="file"
                multiple
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="raised-button-file"
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="contained"
                  component="span"
                  style={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    textTransform: "none",
                    borderRadius: "8px",
                    margin: "100px 0px 100px 0px",
                  }}
                >
                  Select and Upload
                </Button>
              </label>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                {imageURLs.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Uploaded Preview ${index}`}
                    style={{ width: 100, height: 100, margin: 10 }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
};

export default PendingAction;
