import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    //Styles for Properties.jsx Component:
    itemBox: {
      //   width: "42rem",
      display: "flex",
      flexDirection: "column",
      padding: "0.5rem",
      height: "90%",
      borderRadius: "1rem",
      marginTop: "3rem",
      backgroundColor: "#FFFFFFFF",
      boxShadow: "5px 5px 10px 0px rgba(185, 16, 15, 0.03)",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
    },
    boxImg: {
      width: "100%",
      height: "95%",
      objectFit: "contain",
    },

    locationBox: {
      display: "flex",

      alignItems: "center",
      // marginTop: "1rem",
      paddingLeft: "1rem",
      justifyContent: "flex-start",
    },
    icon: {
      color: "#737679",
      marginRight: "0.5rem",
    },

    // Styles for PropertyView.jsx

    propertyViewBox: {
      width: "22rem",
      height: "21rem",
      boxShadow: "5px 5px 10px 0px rgba(185, 16, 15, 0.03)",
      backgroundColor: "#FFFFFFFF",
      display: "flex",
      flexDirection: "column",
      // alignItems: "center",
      borderRadius: "1rem",
    },
    propertyViewImg: {
      width: "20rem",
      height: "18rem",
      objectFit: "contain",
    },
    imgBox: {
      display: "flex",
      justifyContent: "center",
    },

    appartmentBox: {
      width: "22rem",
      maxHeight: "22rem",
      border: "1px solid #B81918",
      // marginLeft: "2rem",
      borderRadius: "0.625rem",
      padding: "1rem",
      overflowX: "auto",
    },
    rentBox: {
      display: "flex",
      alignItems: "center",
      marginTop: "1rem",
      width: "20rem",
      height: "3rem",
      borderRadius: "6px",
      border: "1.5px solid #F10A0A26",
      // padding: "1rem",
    },
    rentBoxICon: {
      color: "#B81918",
      cursor: "text", // Keep cursor as default
      "&:hover": {
        backgroundColor: "transparent", // Prevent background color change on hover
      },
    },

    actionBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "2rem",
      width: { xs: "22rem", sm: "22rem", md: "30rem" },
      height: "22rem",
      border: "1px solid #EFEFEF",
      borderRadius: "1rem",
      paddingBottom: "1rem",
      paddingTop: "1rem",
    },

    viewBox: {
      display: "flex",
      alignItems: "start",
      justifyContent: "space-between",
      width: "95%",
      height: "3rem",
      borderBottom: "1px solid #F10A0A26",
    },

    // Styles for ViewRentalAgremnt.jsx

    appartmentDetail: {
      display: "flex",
      flexDirection: "column",
      width: "23rem",
      height: "17rem",
      padding: "1rem",
    },
    tenantImg: {
      width: "15.25rem",
      height: "15.25rem",
    },
    documntBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "2.5rem",
      backgroundColor: "#EFEFEF",
      marginTop: "1.5rem",
      // gap: "15rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    documntDownloadBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "3.5rem",
      marginTop: "0.5rem",

      // gap: "12rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },

    // Styles for pending actions:
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "20rem",
      height: "13rem",
      border: "1px solid #B81918",
      borderRadius: "10px",
    },
    uploadIcon: {
      width: "5rem",
      height: "3rem",
    },

    // Styles for Complaints.jsx:
    mainGrid: {
      border: "1px solid #EFEFEF",
      borderRadius: "15px",
      maxHeight: "30rem",
      padding: "1rem",
      overflowX: "auto",
      marginTop: "2rem",
    },

    gridItem: {
      padding: "2rem",
      // padding: "1rem",
      height: "7.5rem",
    },

    infoBox: {
      display: "flex",
      alignItems: "center",
      border: "1px solid #EFEFEF",
      borderRadius: "15px",
      padding: "2rem",
      // padding: "1rem",
      marginTop: "0.5rem",
      height: "3.25rem",
      cursor: "pointer",
    },

    // Styles for ComplaintRequests.jsx:
    nameContctGrid: {
      height: "5.625rem",
    },
    nameFieldBox: {
      display: "flex",
      alignItems: "center",
      height: "3.25rem",
      backgroundColor: "#FEF7F7",
      borderRadius: "0.5rem",
      marginTop: "1rem",
      paddingLeft: "1.5rem",
    },
    descriptionBox: {
      display: "flex",
      alignItems: "start",
      height: "11rem",
      backgroundColor: "#FEF7F7",
      borderRadius: "0.5rem",
      marginTop: "1rem",
      paddingTop: "1rem",
      paddingLeft: "1.5rem",
    },
  };
});

export const tableHeadCell = {
  backgroundColor: "#ADADAD20",
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "18px",
  borderRight: "1px solid #ADADAD50",
};

export const tableBodyCell = {
  fontFamily: "Outfit",
  fontSize: "12px",
  lineHeight: "15px",
  fontWeight: "400",
  color: "#737679",
};

// for contract termination

export const textField = {
  padding: "0.5rem",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EFEFEF", // Change the border color here
      borderRadius: "0.5rem",
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Change the border color on hover here
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Change the border color when focused here
    },
  },
};
export const headings = {
  fontFamily: "Outfit",
  fontSize: "18px",
  lineHeight: "19px",
  fontWeight: "500",
};
