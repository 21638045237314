import React from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import { useStyles } from "../../../../AdminComponent/Pages/Properties/Propertie/PropertieStyle";
import { useLocation } from "react-router-dom";
import {
    StatusButton,
} from "../../../../AdminComponent/Pages/Maintenance/MaintenanceStyles";





const ComplainProfile = () => {
    const classes = useStyles();
    const location = useLocation();
    const { complaintDetails } = location.state;




    return (
        <>
            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%' }}>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    marginTop:"15px"
                }}>
                    Complaint
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {complaintDetails?.admin_approval == 1 && (
                        <>
                            <StatusButton>Pending</StatusButton>
                        </>
                    )}
                    {complaintDetails?.admin_approval == 2 && (
                        <>
                            <StatusButton>Approved</StatusButton>
                        </>
                    )}
                    {complaintDetails?.admin_approval == 3 && (
                        <StatusButton>Disapproved</StatusButton>
                    )}

                </Box>
            </Box>
         
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Full Name</Typography>
                    <TextField className={classes.Input} fullWidth variant="outlined"

                        value={complaintDetails?.first_name || ""}
                        placeholder='Michael'
                        size="small"
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Last Name</Typography>
                    <TextField className={classes.Input} fullWidth variant="outlined"
                        value={complaintDetails?.last_name || ""}
                        placeholder='Rayan'
                        size="small"
                        style={{ margin: "0px 0px 25px 0px" }}

                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Phone Number</Typography>
                    <TextField className={classes.Input} fullWidth variant="outlined"

                        value={complaintDetails?.phone || ""}
                        placeholder='+1 234 456 789'
                        size="small"
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Email</Typography>
                    <TextField className={classes.Input} fullWidth variant="outlined"
                        value={complaintDetails?.email || ""}
                        placeholder='Michaelrayan@capstoneuae.com'
                        size="small"
                        style={{ margin: "0px 0px 25px 0px" }}

                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={12} md={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Full Address</Typography>
                    <TextField className={classes.Input} fullWidth variant="outlined"
                        value={complaintDetails?.address || ""}
                        placeholder='305 apartment uae'
                        size="small"
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={12} md={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Description of Request</Typography>
                    <TextField className={classes.Input} multiline rows={7} fullWidth variant="outlined"
                        value={complaintDetails?.description || ""}
                        placeholder='Write your problem here. '
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                    />
                </Grid>
            </Grid>

        </>
    )
}


export default ComplainProfile;