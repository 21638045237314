import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    mainBox: {
      display: "flex",
      marginTop: "3.5rem",
      //   border: "1px solid red",

      gap: "2rem",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "1rem",
      },
    },

    vacantBox: {
      width: "30%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        width: "100%",
      },

      gap: "1rem",
      display: "flex",
      flexDirection: "column",
    },

    subBox: {
      display: "flex",
      flexDirection: "column",
      // boxShadow: "0.5px 0.5px 0 0",
      borderRadius: "10px",
      height: "8rem",
      gap: "1rem",
      padding: "2rem",
      boxShadow: '4px 4px 8px 0px #0000000A'

    },

    graphBox: {
      display: "flex",
      // boxShadow: "0.5px 0.5px 0 0",
      boxShadow: '4px 4px 8px 0px #0000000A',
      padding: "1rem",
      borderRadius: "10px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      width: "70%",
      height: "17rem",
    },
    vacantUnitsBox: {
      marginTop: "2rem",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "1rem",
      },
      justifyContent: "space-between",
      alignItems: "center",
    },

    fieldsBox: {
      display: "flex",
      gap: "5px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    btnBox: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
      marginTop: "0.5rem",
    },
  };
});

export const mainTypography = {
  fontFamily: "Outfit",
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "16px",
};

export const subTypography = {
  fontFamily: "Outfit",
  fontSize: "24px",
  fontWeight: "400",
  lineHeight: "16px",
};
export const subTypographySecond = {
  fontFamily: "Outfit",
  fontSize: "28px",
  fontWeight: "600",
  lineHeight: "26px",
  color: "#B81918",
};

export const vacantUnitsTypography = {
  fontFamily: "Outfit",
  fontSize: "25px",
  fontWeight: "500",
  lineHeight: "30px",
};

export const CustomTextField = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    height: "2.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
    "& .MuiOutlinedInput-input[type='date']": {
      color: "#73767960", // Change the color of the input text for date type
    },
    "& .MuiSvgIcon-root": {
      color: "#737679", // Change the color of the calendar icon
    },
    "& .MuiOutlinedInput-input::placeholder": {
      color: "#737679", // Change placeholder color here
    },
  },
};

export const tableHeadCell = {
  backgroundColor: "#ADADAD30",
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "23px",
  borderRight: "1px solid #ADADAD50",
};

export const tableBodyCell = {
  fontFamily: "Outfit",
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: "400",
  color: "#737679",
};

export const tableBodyCellRed = {
  fontFamily: "Outfit",
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: "500",
  color: "#B81918",
};

export const aplyfilter = {
  border: "1px solid #B81918",
  color: "#B81918",
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "12px",
  borderRadius: "30px",
  height: "2.5rem",
  padding: "0.5rem 1rem 0.5rem",
  textTransform: "none",
  margin:"0px 0px 0px 30px"
};
