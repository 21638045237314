import React, { useState } from "react";
import { useStyles } from "./PropertiesStyles";
import { Box, Typography, Grid, Avatar, Button } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useLocation } from "react-router-dom";



const PendingActions = () => {
  const classes = useStyles();
  const location = useLocation();
  const { details, property } = location.state || {};
  const pendingActions = [
    "Contract dates not uploaded",
    "Property Images not ulpoaded",
    "Unit Size not uploaded",
    "Tenant Email not provided",
    "Offer Letter against contract not send.",
    "Feedback for complaint not added",
  ];


  return (
    <>
      <Grid
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row", md: "row" }}
        gap={"2rem"}
        marginBottom={"3rem"}
      >
        <Box className={classes.propertyViewBox}>
          <Box className={classes.imgBox}>
            <img
              // src="/image/proimage1.jpg"
              src={property?.attachments[0]?.media || ""}
              alt="property image"
              onError={(e) => (e.target.src = "/image/propertyicon.png")}
              className={classes.propertyViewImg}
            />
          </Box>
          <Box className={classes.locationBox}>
            <LocationOnIcon sx={{ color: "#B81918" }} />
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#B81918",
              }}
            >
              {property?.address || "Not available"}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.appartmentDetail}>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "24px",
              color: "#B81918",
            }}
          >
            {property?.address || "Not available"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "40px",
              color: "#000000",
            }}
          >
            Full Address:{" "}
            <span style={{ fontWeight: "400" }}>
              {details?.agreements?.tenant_address || "not available"}
            </span>
          </Typography>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "20px",
              color: "#000000",
            }}
          >
            Current Tenant Details:
          </Typography>
          <Avatar
            src="/image/userAvatar.jpg"
            alt="img"
            className={classes.tenantImg}
          // sx={{ width: "1rem", height: "1rem", color: "#ADADAD" }}
          />
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#000000",
            }}
          >
            {details?.agreements?.tenant?.first_name || "not available"}
            <br></br>
            {details?.agreements?.tenant?.phone || "not available"}
            <br></br>
            {details?.agreements?.tenant?.email || "not available"}
          </Typography>
        </Box>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "350",
              lineHeight: "30px",
            }}
          >
            Upload Emirates ID
          </Typography>
          <Box className={classes.uploadBox}>
            <FileUploadOutlinedIcon
              fontSize="large"
              sx={{ color: "#B81918" }}
            />
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              Drag and drop <br></br>
              <span style={{ color: "#B81918" }}>or browse files</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "350",
              lineHeight: "30px",
            }}
          >
            Upload Rental Agreement
          </Typography>
          <Box className={classes.uploadBox}>
            <FileUploadOutlinedIcon
              fontSize="large"
              sx={{ color: "#B81918" }}
            />
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              Drag and drop <br></br>
              <span style={{ color: "#B81918" }}>or browse files</span>
            </Typography>
          </Box>
        </Grid>
      </Grid> */}
      <Box marginBottom={"2rem"} marginTop={"5rem"}>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "24px",
            color: "#000000",
          }}
        >
          Pending Actions
        </Typography>
      </Box>
      <Grid
        container
        maxHeight={"20rem"}
        overflow={"auto"}
        marginBottom={"2rem"}
      >
        {details?.pending_actions && details.pending_actions.length > 0 ? (
          details?.pending_actions?.map((item, index) => (
            <Grid
              item
              marginBottom={"0.5rem"}
              border={"1px solid #F1F1F1"}
              padding={2.5}
              borderRadius={"1rem"}
              xs={8}
              key={item.id}
            >
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: "400",
                }}
              >
                {index + 1}- {item.action}
              </Typography>
            </Grid>
          ))
        ) : (
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "400",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            No pending actions
          </Typography>
        )}
      </Grid>
    </>
  );
}

export default PendingActions;
