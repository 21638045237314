
import React, { useState, useEffect } from "react";
import { useStyles } from "./ChatStyle";
import { Box, Avatar, Typography, Divider, Button, Input, TextField } from "@mui/material";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore, collection, addDoc, query, ref, storage, orderBy, uploadBytes, getDownloadURL, doc, onSnapshot, updateDoc } from '../../../firebase';
import { auth, onAuthStateChanged } from '../../../firebase';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const ChatBox = () => {
  const classes = useStyles();
  const location = useLocation();
  const { first_name, email } = location.state || {};
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [recipient] = useState(email);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [unreadMessages, setUnreadMessages] = useState(0);
  const navigate = useNavigate();

  const handlesubmit = () => {
    navigate('/finance/chat');
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : '');
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user && recipient) {
      const chatId = [user.email, recipient].sort().join('_');
      const q = query(collection(firestore, `chats/${chatId}/messages`), orderBy('timestamp'));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedMessages = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setMessages(fetchedMessages);

        const unreadCount = fetchedMessages.filter(msg => msg.receiverId === user.email && !msg.read).length;
        setUnreadMessages(unreadCount);
      });

      return () => unsubscribe();
    }
  }, [user, recipient]);

  const markMessagesAsRead = async () => {
    const chatId = [user.email, recipient].sort().join('_');
    messages.forEach(async (msg) => {
      if (msg.receiverId === user.email && !msg.read) {
        const messageDocRef = doc(firestore, `chats/${chatId}/messages`, msg.id);
        await updateDoc(messageDocRef, {
          read: true
        });
      }
    });
    setUnreadMessages(0);
  };

  const sendMessage = async () => {
    if (!user) return;

    const chatId = [user.email, recipient].sort().join('_');

    
    const textMessageData = {
      senderId: user.email,
      receiverId: recipient,
      message: message.trim(),
      timestamp: new Date(),
      type: 'text',
      read: false
    };

  
    if (file) {
      const fileRef = ref(storage, `chats/${chatId}/files/${file.name}`);
      await uploadBytes(fileRef, file).then(async (snapshot) => {
        const downloadURL = await getDownloadURL(snapshot.ref);
        const fileMessageData = {
          ...textMessageData,
          message: downloadURL,
          type: 'file',
          fileType: file.type || 'unknown'
        };

      
        await addDoc(collection(firestore, `chats/${chatId}/messages`), fileMessageData);
        setFile(null);
        setFileName('');
      }).catch((error) => {
        console.error('Error uploading file:', error.message);
      });
    }

    if (message.trim()) {
      await addDoc(collection(firestore, `chats/${chatId}/messages`), textMessageData);
      setMessage('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const formatTime = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp.seconds * 1000); // Firestore timestamp to JS Date
    return new Intl.DateTimeFormat('en', { hour: '2-digit', minute: '2-digit' }).format(date);
  };

  if (!user) {
    return <div>Please log in to chat</div>;
  }

  return (
    <>
      <Box>
        <KeyboardBackspaceIcon style={{ color: "#B81918" }} onClick={handlesubmit} />
      </Box>

      <Box onKeyDown={handleKeyPress} tabIndex={0} className={classes.mianroot} onFocus={markMessagesAsRead}>
        <Box>
          <Box style={{ margin: "10px 0px 0px 0px", padding: "0px 0px 0px 20px" }}>
            <Box style={{ display: "flex", alignItems: 'center' }}>
              <Avatar src="" />
              <Typography style={{
                color: "#060606",
                fontFamily: "Outfit",
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 700,
                margin: "0px 0px 0px 10px",
                borderRadius: '10px'
              }}>
                {first_name}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box style={{ width: "100%" }}>
          <Divider style={{ margin: "10px 0px 0px 0px", color: "red" }} />
        </Box>

        <Box style={{ display: 'flex', flexDirection: 'column', height: '400px', overflowY: 'auto', padding: "0px 20px 0px 20px" }}>
          {messages.map((msg, index) => (
            <Box
              key={index}
              style={{
                alignSelf: msg.senderId === user.email ? 'flex-end' : 'flex-start',
                backgroundColor: msg.senderId === user.email ? '#e1ffc7' : '#f1f1f1',
                padding: '8px',
                margin: '5px',
                borderRadius: '10px',
                maxWidth: '80%',
                wordWrap: 'break-word',
                position: 'relative'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  {msg.type === 'text' ? (
                    <span>{msg.message}</span>
                  ) : (
                    <a href={msg.message} target="_blank" rel="noopener noreferrer">
                      {msg.fileType.startsWith('image/') ? (
                        <img src={msg.message} alt="file" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                      ) : msg.fileType.startsWith('application/pdf') ? (
                        <iframe src={msg.message} title="file" style={{ width: '100%', height: '500px', border: 'none' }} />
                      ) : (
                        <span>Unsupported file type</span>
                      )}
                    </a>
                  )}
                </div>
                <Typography style={{ color: '#666', fontSize: '10px', textAlign: 'right', marginTop: '4px' }}>
                  {formatTime(msg.timestamp)}
                </Typography>
              </div>
            </Box>
          ))}
        </Box>

        <Box style={{ color: "red", width: "100%" }}>
          <Divider style={{ margin: "15px 0px 0px 0px" }} />
        </Box>

        <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', padding: "15px" }}>
          <label htmlFor="fileInput">
            <AddPhotoAlternateOutlinedIcon
              sx={{ fontSize: "45px", color: "#737679", cursor: "pointer" }}
            />
          </label>
          <Input
            id="fileInput"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />

          {fileName && <Typography>{fileName}</Typography>}
          <TextField
            style={{ width: "95%", margin: "5px 0px 0px 0px" }}
            variant="standard"
            placeholder="Type a Message"
            InputProps={{
              disableUnderline: true,
              style: { border: "0px" }
            }}

            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              style={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "150px",
                height: "40px"
              }}
              variant="contained"
              onClick={sendMessage}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChatBox;
