import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  Divider,
  Link,
  Backdrop,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import toast from "react-hot-toast";
import { faL } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { Loader } from "../../../utils/Loader";

const BulkUpload = ({ open, onClose, component,listing }) => {
  const [file, setFile] = useState(null);
  const [fileLink, setFileLink] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const BulkUpload = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      let url;
      {component === "Property"
        ? (url = endpoints.propertyBulkUpoad)
        : (url = endpoints.unityBulkUpoad)}
      const requestOption = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(url, formData, requestOption);
      toast.success(response.data.message);
      if(response.status === 200){
        onClose();
        listing();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
      
    }
  };

  useEffect(() => {
    component === "Property"
      ? setFileLink(
          "https://docs.google.com/spreadsheets/d/1DDqup20zLnnvMoUZmsuXwQQNX5WsWLfCP0Ke85UJ_80/edit?gid=0#gid=0"
        )
      : setFileLink(
          "https://docs.google.com/spreadsheets/d/1mX2wqIx0_vSAkeo5hQViccKmHKrkQDcbzZajdWv6VMQ/edit?gid=0#gid=0"
        );
  }, [component]);
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // maxWidth: "600px", // Optional, you can adjust the max width of the modal
            width: "40%",
            backgroundColor: "white",
            padding: "20px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            zIndex: 1300, // Ensure modal stays on top
          }}
        >
          <Typography variant="h6">Bulk File Upload</Typography>
          <Divider style={{ margin: "10px 0" }} />

          {loading ? (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <Loader />
            </Backdrop>
          ) : (
            ""
          )}

          {/* File Upload Section */}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            marginBottom={"5px"}
          >
            <input
              type="file"
              accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="raised-button-file"
            />
            <label htmlFor="raised-button-file">
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  //   width: "140px",
                  height: "30px",
                }}
                variant="contained"
                component="span"
                // onClick={}
              >
                Select File
              </Button>
            </label>

            {/* Submit Button */}
            <Button
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{
                background: "rgba(184, 25, 24, 1)",
                height: "30px",
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                "&:hover": {
                  background: "rgba(184, 25, 24, 1)",
                },
              }}
              onClick={BulkUpload}
            >
              Upload
            </Button>
          </Box>
          <Box>
            {file && <Typography>{`Selected File: ${file.name}`}</Typography>}
          </Box>

          <Box marginTop={"1rem"}>
            <Link
              href={fileLink}
              target="_blank"
              sx={{ color: "rgba(184, 25, 24, 1)", textDecoration: "none" }}
            >
              Click Here to See Sample File
            </Link>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BulkUpload;
