import React from "react";
import { Box, Typography, Button, Grid, Tabs, Tab } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect } from "react";
import { useStyles } from "../../../AdminComponent/Pages/Properties/Propertie/PropertieStyle";
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, } from '@mui/material';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";





const Documents = () => {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const [secondSelectValue, setSecondSelectValue] = useState('');
    const [thirdSelectValue, setThirdSelectValue] = useState('');
    const [DocumentData, setDocumentData] = useState([]);
    const [loading, setLoading] = useState(true);



    const handleApplyFilter = () => {
        console.log("Search Value:", searchValue);
        console.log("Second Select Value:", secondSelectValue);
        console.log("Third Select Value:", thirdSelectValue);
    };


    const getDocument = async () => {

        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.landlordDocument}`, {
                headers: { Authorization: `Token ${token}` }
            });
            const data = response.data.data;
            setDocumentData(data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };





    const downloadFile = (fileUrl, type) => {
        fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = `${type}.${getFileExtension(fileUrl)}`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => alert("Could not fetch the file."));
    };

    function getFileExtension(url) {
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(url)[1];
        return extension;
    }


    useEffect(() => {
        getDocument();
    }, [])




    return (
        <>
            <Box sx={{ flexGrow: 1, width: '100%' }}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={8} sm={6} lg={true}>
                        <Typography sx={{
                            fontFamily: "Outfit",
                            fontSize: "24px",
                            lineHeight: "24px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 1)"
                        }}>
                            Documents
                        </Typography>
                    </Grid>
                </Grid>
            </Box>



            {loading ? (
                <Box
                    display={"flex"}
                    height={"100vh"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Loader />
                </Box>
                 ) : DocumentData.length === 0 ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="50vh"
                    >
                      <Typography>No data available</Typography>
                    </Box>
            ) : (
                <Box style={{ overflowX: 'auto' }} mt={5}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Document Name</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Tenant Name</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Unit/Property</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {DocumentData?.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <Box style={{ display: "flex" }}>
                                            <img
                                                src="/image/pdfimage.png"
                                                alt="download"
                                                style={{ cursor: "pointer", width: "30px" }}
                                            />
                                            <Typography
                                                style={{
                                                    fontFamily: "Outfit",
                                                    fontSize: "15px",
                                                    fontWeight: 400,
                                                    lineHeight: "18px",
                                                    margin: "5px 0px 0px 15px"
                                                }}
                                            >{row.type}</Typography></Box></TableCell>
                                    <TableCell>{row.tenant}</TableCell>
                                    <TableCell>{row.property}</TableCell>
                                    <TableCell>
                                        <>
                                            <a
                                                onClick={() => {
                                                    downloadFile(row.file, row.type);
                                                }}
                                            >
                                                <Tooltip title="Download">
                                                    <SystemUpdateAltOutlinedIcon
                                                        style={{ color: "rgba(185, 16, 15, 1)" }}
                                                    />
                                                </Tooltip>
                                            </a>
                                        </>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box >
            )}
        </>
    )
}

export default Documents;