import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, Grid, TextField, FormControl, MenuItem, Select } from "@mui/material";
import { useStyles } from "./SOAStyle";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import SOA from "./SOA";
import { Loader } from "../../../utils/Loader";

const { RangePicker } = DatePicker;

const RentPopup = ({ onClose }) => {
  const classes = useStyles();
  const [propertyList, setPropertyList] = useState(null);
  const [data, setData] = useState({
    property: null,
    dateRange: null,
    description: null
  })
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateRangeChange = (dates) => {
    setData((prevData) => ({
      ...prevData,
      dateRange: dates,
    }));
  };

  const formattedDates = data.dateRange?.map((dateObj) => {
    return dateObj.$d.toISOString().split("T")[0]; // Get the date in YYYY-MM-DD format
  });


  const getProperty = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem('token')}`
        }
      }
      const response = await axios.get(`${endpoints.landlordProperty}?primary_landlord_flag=${true}`, requestOptions);
      setPropertyList(response.data.data)

    } catch {

    }
  }
  useEffect(() => { getProperty() }, [])

  const createSOA = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const payload = {
        property: data.property,
        start_date: formattedDates[0],
        end_date: formattedDates[1],
        description: data.description
      }
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem('token')}`
        }
      }
      const response = await axios.post(endpoints.financeSOA, payload, requestOptions)
      toast.success(response.data.message)
      if (response.status === 200)
        onClose()

    } catch (error) {
      toast.error(error.response.data.message)
    } finally {
      setLoading(false);


    }
  }

  return (

    <Modal
      open={true}
      onClose={onClose}
      sx={{ zIndex: 1 }}
      aria-labelledby="popup-modal-title"
      aria-describedby="popup-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}

    >

      <Box
        className={classes.Poproot}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          maxHeight: "80vh", // Set maximum height for scrolling
          overflow: "auto", // Make content scrollable


        }}
      >

        <Box
          sx={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            style={{
              fontFamily: "Outfit",
              fontSize: "24px",
              lineHeight: "24px",
              fontWeight: 700,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            Request SOA
          </Typography>
        </Box>
        {loading ? (<Box display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}> <Loader /> </Box>) : (

          <form onSubmit={createSOA}>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Property
                </Typography>

                <FormControl fullWidth
                  sx={{
                    margin: "0px 0px 25px 0px",
                    // height: "5rem",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#EFEFEF",
                      },
                      "&:hover fieldset": {
                        borderColor: "#EFEFEF", // Change color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#EFEFEF", // Change color on focus
                      },
                    },
                  }}>
                  <Select
                    name='property'
                    value={data.property || ''}
                    onChange={handleChange}
                    required
                    displayEmpty
                  >
                    <MenuItem value=''>Select Property </MenuItem>
                    {propertyList?.map((property) => (
                      <MenuItem key={property.id} value={property.id}>{property.ref_id}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Date Range
                </Typography>
                <Space>
                  <RangePicker
                    name="dateRange"
                    value={data.dateRange}
                    onChange={handleDateRangeChange}
                    required
                    style={{
                      height: "3.5rem",
                      borderColor: "#EFEFEF",
                      width: "100%",
                      position: 'relative',
                      zIndex: 2


                    }}

                  />
                </Space>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Description
                </Typography>
                <TextField
                  multiline
                  rows={5}
                  fullWidth
                  variant="outlined"
                  placeholder="Any description "
                  name='description'
                  value={data.description}
                  onChange={handleChange}
                  required
                  sx={{
                    margin: "0px 0px 25px 0px",
                    // height: "3.5rem",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#EFEFEF",
                      },
                      "&:hover fieldset": {
                        borderColor: "#EFEFEF", // Change color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#EFEFEF", // Change color on focus
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "130px",
                  height: "40px",
                  margin: "0px 0px 0px 0px",
                }}
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </form>
        )}
      </Box>

    </Modal>

  );
};

export default RentPopup;
