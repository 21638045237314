import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {
  tableHeadCell,
  tableBodyCell,
  tableBodyCellRed,
  CustomTextField,
  mainTypography,
  useStyles,
  tableTotalCell,
  tableTotalCellRed,
  modalSubmit,
  modalTitleTypography,
  modalDateTypography,
  modalTableBodyCell,
  aplyfilter,
} from "./SOAStyles";
import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Modal,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { rentStatus, soaStatus } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";

function SOA() {
  const classes = useStyles();
  const{loading,setLoading} = useState(true);
  const [pdfLetter, setPdfLetter] = useState(null);
  const [pdfError, setPdfError] = useState(null);
  const [searchValues, setSearchValues] = useState({
    firstSearch: "",
    secondSearch: "",
  });
  const [SOA,setSOA] = useState(null);
  const handlePdfLoadError = (error) => {
    setPdfError(error);
  };
  

  const [modalOpen, setModalOpen] = useState(false);
  const [SOAs,setSOAs] = useState([])

  const navigate = useNavigate();

  const handleSearchChange = (name, value) => {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    console.log(
      "searchValue",
      searchValues.firstSearch,
      searchValues.secondSearch
    );
  };

  // Table Headings

  const headers = [
    "Date Range Requested",
    "Property",
    "Landlord",
    "Status (Landlord)",
    "Action",
    "Generate SOA",
  ];

 

  // Modal Table
  const modalHeader = ["Date", "Description", "Debit", "Credit", "Balance"];

  const modalRows = [
    {
      id: "1",
      date: "5/30/2024",
      description: "Property management fee for unit...",
      debit: "2,625,00",
      credit: "----",
      balance: "2,625,00",
    },
    {
      id: "2",
      date: "5/30/2024",
      description: "Property management fee for unit...",
      debit: "2,625,00",
      credit: "----",
      balance: "2,625,00",
    },
    {
      id: "3",
      date: "5/30/2024",
      description: "Property management fee for unit...",
      debit: "2,625,00",
      credit: "----",
      balance: "2,625,00",
    },
    {
      id: "4",
      date: "5/30/2024",
      description: "Property management fee for unit...",
      debit: "2,625,00",
      credit: "----",
      balance: "2,625,00",
    },
    {
      id: "5",
      date: "5/30/2024",
      description: "Property management fee for unit...",
      debit: "2,625,00",
      credit: "----",
      balance: "2,625,00",
    },
    {
      id: "6",
      date: "5/30/2024",
      description: "Property management fee for unit...",
      debit: "2,625,00",
      credit: "----",
      balance: "2,625,00",
    },
  ];

  // const handleModalOpen = () => {
  //   setModalOpen(true);
    
  // };

  

  const getSOA = async () =>{
    try{
      const requestOptions = {
        headers:{
          Authorization: `token ${localStorage.getItem('token')}`
        }
      }
      let url;
      if(searchValues.secondSearch){
        url = `${endpoints.financeSOA}?landlord=${searchValues.secondSearch}`
      }else{
        url = endpoints.financeSOA;
      }
      const response = await axios.get(url,requestOptions);
      setSOAs(response.data.data);


    }catch{
      setSOAs()

    }finally{

    }
  }

  useEffect(()=>{getSOA()},[])

  const generateSOA = async (id) =>{
    try{
      const requestOptions = {
        headers:{
          Authorization: `token ${localStorage.getItem('token')}`
        }
      }
      const response = await axios.post(`${endpoints.financeGenerateSOA}?id=${id}`,{},requestOptions);
      
      setPdfLetter(response.data.data.document)
      setSOA(response.data.data)
      if(response.status === 200){
        setModalOpen(true)
      }

    }catch(error){


    }finally{

    }
  }

  
  

  const handleModalClose = () => {
    
    setModalOpen(false);
    
  };




  return (
    <>
      <Box>
        <Typography sx={mainTypography}>SOA</Typography>
      </Box>
      <Box className={classes.searchFieldBox}>
        {/* <TextField
          variant="outlined"
          placeholder="Search by Date Range"
          value={searchValues.firstSearch}
          onChange={(e) => handleSearchChange("firstSearch", e.target.value)}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={CustomTextField}
        /> */}
        <TextField
          variant="outlined"
          placeholder="Search by Landlord"
          value={searchValues.secondSearch}
          onChange={(e) => handleSearchChange("secondSearch", e.target.value)}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={CustomTextField}
        />
        <Button sx={aplyfilter} onClick={getSOA}>Apply Filter</Button>
      </Box>
      {loading ? (<Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'50vh'}>
        <Loader/>
      </Box>):
      SOAs && SOAs?.length > 0 ?(
      <Box marginTop={"2rem"}>
        <TableContainer sx={{ height: "65vh", overflowX: "auto" }}>
          <Table sx={{ border: "1px solid #EFEFEF" }}>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index} sx={tableHeadCell}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {SOAs?.map((soa) => (
                <TableRow key={soa.id}>
                  <TableCell sx={tableBodyCell}>{soa.start_date || '-'} to {soa.end_date || '-'}</TableCell>
                  <TableCell sx={tableBodyCell}>{soa.property?.ref_id || '-'}</TableCell>
                  <TableCell sx={tableBodyCell}>{soa.landlord?.first_name || '-'}</TableCell>
                  <TableCell sx={tableBodyCell}>{soa.finance_approval === soaStatus.approved.value ? (
                    soa.landlord_approval === soaStatus.approved.value ? (soaStatus.approved.label) : soa.landlord_approval === soaStatus.pending.value ? (soaStatus.pending.label) : soa.landlord_approval === soaStatus.clarification.value ? (soaStatus.clarification.label) : ('-')
                  ):('-')}</TableCell>
                  <TableCell
                    sx={tableBodyCellRed}
                    onClick={() => navigate("/finance/soa/view-soa",{state:{soa}})}
                  >
                    View
                  </TableCell>
                  <TableCell sx={tableBodyCellRed} align="center">
                    {soa.finance_approval === rentStatus.approved.value ? (
                    <AddCircleOutlineOutlinedIcon onClick={soa.admin_approval === rentStatus.approved.value? () => generateSOA(soa.id) : undefined} 
                    />) :('-')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      ) : (<Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={'2rem'}><Typography>No data available</Typography></Box>)}
      <Dialog open={modalOpen} onClose={handleModalClose}>
        <DialogTitle sx={mainTypography}>SOA</DialogTitle>
        <Divider></Divider>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <DialogTitle sx={modalTitleTypography}>
            Statement of Account. {SOA?.landlord?.first_name || '-'}
          </DialogTitle>
          <DialogTitle sx={modalDateTypography}>{SOA?.start_date || '-'} to {SOA?.end_date || '-'}</DialogTitle>
        </Box>
        <Box>
          <DialogContent>
          {/* <Box border={"1px solid #ADADAD30"} marginBottom={"-1rem"}>
              <TableContainer sx={{ height: "60vh", overflowX: "auto" }}>
                <Table sx={{ border: "1px solid #EFEFEF" }}>
                  <TableHead>
                    <TableRow>
                      {modalHeader.map((header, index) => (
                        <TableCell key={index} sx={tableHeadCell}>
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modalRows.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell sx={modalTableBodyCell}>
                          {item.date}
                        </TableCell>
                        <TableCell sx={modalTableBodyCell}>
                          {item.description}
                        </TableCell>
                        <TableCell sx={modalTableBodyCell}>
                          {item.debit}
                        </TableCell>
                        <TableCell sx={modalTableBodyCell}>
                          {item.credit}
                        </TableCell>
                        <TableCell sx={modalTableBodyCell} align="center">
                          {item.balance}
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell colSpan={4} sx={tableTotalCell}>
                        Total Liability - Payable within due date
                      </TableCell>
                      <TableCell colSpan={1} sx={tableTotalCellRed}>
                        123124356
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box> */}
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                {pdfError ? (
                  <div>Error loading PDF: {pdfError.message}</div>
                ) : (
                  <Viewer fileUrl={pdfLetter || 'N/A'} onError={handlePdfLoadError} />
                )}
              </Worker>
          </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleModalClose} sx={modalSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SOA;
