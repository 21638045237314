import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Backdrop,
} from "@mui/material";
import { modalTypography, newIncomeTextfield } from "./InquiriesStyle";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";

import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";
import Inquiries from "./Inquiries";

const InquiriesForm = ({ inquiryData }) => {
  const [offerLetterForm, setOfferLetterForm] = useState({
    inquiry: inquiryData.id,
    total_payments: null,
    agency_fee: null,
    tawtheeq_and_processing: null,
    move_in_date: null,
    move_out_date: null,
    tenure: null,
    security_deposit: null,
    annual_rent: null,
    unit: null,
    property: null,
  });

  const [pdf, setPdf] = useState({
    pdfLetter: null,
    inquery_id: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [units, setUnits] = useState(null);
  const [properties, setProperties] = useState(null);
  const [loading,setLoading] = useState(false);
  const [isOfferLetterSent,setIsOfferLetterSent] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOfferLetterForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.post(
        endpoints.createOfferLetter,
        offerLetterForm,
        requestOptions
      );
  
      setPdf({
        pdfLetter: response.data.data.document,
        inquery_id: response.data.data.inquiry,
      });
      setIsModalOpen(true);
    } catch (error) {
   
    }
  };

  // function to send offer letter
  const sendOfferLetter = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.post(
        `${endpoints.sendOfferLetter}?inquery_id=${pdf.inquery_id}`,
        pdf.inquery_id,
        requestOptions
      );

      toast.success(response.data.message);
      if(response.status === 200){
      handleModalClose();
      setIsOfferLetterSent(true)
      }
    } catch (error) {
      toast.error(error.response.data.message)
    }finally{
      setLoading(false)
    }
  };

  // Get Properties
  const getProperties = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        endpoints.getPropertiesListing,
        requestOptions
      );
      setProperties(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Get Units
  const getUnits = async () => {
   
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      let url;
      if(offerLetterForm.property){
        url = `${endpoints.getUnitsListing}?property=${offerLetterForm.property}`
      }else{
        url = endpoints.getUnitsListing
      }
      const response = await axios.get(
        url,
        requestOptions
      );
    //   if(response.data.data.length === 0){
    //     setUnits(null)
    //     console.log('Its null');
        
    //   }else{
    //   setUnits(response.data.data)
    // console.log('Not null');
    // }
    setUnits(response.data.data)
    } catch (error) {
      console.log("error getting units:", error);
      setUnits()
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePdfLoadError = (error) => {
    setError(error);
  };

  useEffect(() => {
    getUnits();
    getProperties();
  }, []);
  useEffect(() => {
    getUnits();
  }, [offerLetterForm.property]);

  return (
    <>
    {isOfferLetterSent ? (<Inquiries/>) :(
    <>
      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          Inquiries
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={5}
        >
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter name"
              value={inquiryData.name}
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Passport No{" "}
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Passport No "
              value={inquiryData.passport_no}
              sx={newIncomeTextfield}
            />
          </Grid>
         
          {/* propertyNo */}
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Property No{" "}
            </Typography>
            <FormControl fullWidth variant="outlined" sx={newIncomeTextfield}>
              <InputLabel
                id="property-number-label"
                sx={{
                  color: "#73767960",
                  "&.Mui-focused": {
                    color: "#73767960",
                  },
                }}
              >
                Select Property
              </InputLabel>
              <Select
                labelId="property-number-label"
                name="property"
                value={offerLetterForm.property}
                onChange={handleChange}
                label="Select Property"
              >
                {properties?.map((item) => (
                  <MenuItem value={item.id}> {`${item.ref_id} - ${item.title}`}</MenuItem>
                ))}
                {/* Add more MenuItems as needed */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Unit Number
            </Typography>
            <FormControl fullWidth variant="outlined" sx={newIncomeTextfield}>
              <InputLabel
                id="unit-number-label"
                sx={{
                  color: "#73767960",
                  "&.Mui-focused": {
                    color: "#73767960",
                  },
                }}
              >
                Select Unit
              </InputLabel>
              <Select
                labelId="unit-number-label"
                name="unit"
                value={offerLetterForm.unit}
                onChange={handleChange}
                label="Select Unit"
              >
                {units?.length > 0 ?(
                units?.map((item) => (
                  <MenuItem value={item.id}> {`${item.ref_id} - ${item.title}`}</MenuItem>
                ))
              ):(<MenuItem>No Units</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Annual Rent
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Annual Rent "
              name="annual_rent"
              value={setOfferLetterForm.annual_rent}
              onChange={handleChange}
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Security Deposit
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Reference No"
              name="security_deposit"
              value={setOfferLetterForm.security_deposit}
              onChange={handleChange}
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              No. of Payments{" "}
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Total payments"
              name="total_payments"
              value={setOfferLetterForm.total_payments}
              onChange={handleChange}
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Agency Fee
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Agency Fee"
              name="agency_fee"
              value={setOfferLetterForm.agency_fee}
              onChange={handleChange}
              sx={newIncomeTextfield}
            />
          </Grid>
          {/* tawteeqNo */}
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Tawtheeq & Processing Fee{" "}
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Tawtheeq & Processing Fee "
              name="tawtheeq_and_processing"
              value={setOfferLetterForm.tawtheeq_and_processing}
              onChange={handleChange}
              sx={newIncomeTextfield}
            />
          </Grid>
          {/* tenure */}
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Tenure
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Tenure in Months"
              name="tenure"
              value={setOfferLetterForm.tenure}
              onChange={handleChange}
              sx={newIncomeTextfield}
            />
          </Grid>
          {/* moveInDate */}
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Move In Date
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              name="move_in_date"
              value={setOfferLetterForm.move_in_date}
              onChange={handleChange}
              sx={newIncomeTextfield}
            />
          </Grid>
          {/* moveOutDate */}
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Move Out Date
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              name="move_out_date"
              value={setOfferLetterForm.move_out_date}
              onChange={handleChange}
              sx={newIncomeTextfield}
            />
          </Grid>
        </Grid>
        <Box>
          <Button
            style={{
              backgroundColor: "rgba(184, 25, 24, 1)",
              fontSize: "14px",
              lineHeight: "16px",
              fontWeight: 500,
              textTransform: "none",
              width: "130px",
              height: "40px",
              margin: "20px 0px 50px 0px",
            }}
            variant="contained"
            type="submit"
          >
            Review Info
          </Button>
        </Box>
      </form>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="pdf-viewer-modal"
        aria-describedby="pdf-viewer-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: "90%",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            overflow: "auto",
          }}
        >{loading ? (
          <Backdrop sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}>
            <Loader/>
          </Backdrop>
          ):('')}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography sx={modalTypography} gutterBottom>
              Offer letter
            </Typography>
            <Button
              sx={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                color: "white",
                fontSize: "18px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "5rem",
                height: "2.5rem",
                borderRadius: "0.5rem",
                '&:hover':{
                  backgroundColor: 'rgba(184, 25, 24, 1)'
                }
              }}
              onClick={sendOfferLetter}
            >
              Send
            </Button>
          </Box>
          {/* <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
              sx={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                color: "white",
                fontSize: "18px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "5rem",
                height: "2.5rem",
                borderRadius: "0.5rem",
                // margin: "20px 0px 50px 0px",
              }}
              onClick={sendOfferLetter}
            >
              Send
            </Button>
          </Box> */}
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            {error ? (
              <div>Error loading PDF: {error.message}</div>
            ) : (
              <Viewer fileUrl={pdf.pdfLetter} onError={handlePdfLoadError} />
            )}
          </Worker>
        </Box>
      </Modal>
    </>
  )}
    </>
  );
};

export default InquiriesForm;
