import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Tabs, Tab } from "@mui/material";
import AddUser from "./AddUser";
import DataTable from "./Table";
import { Link, useLocation } from "react-router-dom";




const Admindata = [
  {
    id: "1",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "2",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "3",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "4",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "5",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "6",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "7",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "8",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "9",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
];
const Tenantdata = [
  {
    id: "1",
    label: "Aayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "2",
    label: "Aayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "3",
    label: "Aayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "4",
    label: "Aayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "5",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "6",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "7",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "8",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "9",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
];
const Landlorddata = [
  {
    id: "1",
    label: "Layan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "2",
    label: "Layan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "3",
    label: "Layan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "4",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "5",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "6",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "7",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "8",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "9",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
];
const Financedata = [
  {
    id: "1",
    label: "Fayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "2",
    label: "Fayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "3",
    label: "Fayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "4",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "5",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "6",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "7",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "8",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
  {
    id: "9",
    label: "Rayan",
    email: "Rayan@uae.com",
    number: "+1 23 456 789",
  },
];



const Client = () => {
 
  const [selectedTab, setSelectedTab] = useState(0);

  const location =  useLocation();

  useEffect(() => {
    // Extract the 'value' query parameter from the URL
    const queryParams = new URLSearchParams(location.search);
    const value = queryParams.get('value');
    setSelectedTab(value || 0)
    

    // Map the 'value' to the corresponding tab index
    switch (value) {
      case '0':
        setSelectedTab(0); // Tenants
        break;
      case '1':
        setSelectedTab(1); // Landlords
        break;
      case '2':
        setSelectedTab(2); // Admin
        break;
      case '3':
        setSelectedTab(3); // Finance
        break;
      default:
        setSelectedTab(0); // Default to Tenants if no match
        break;
    }
  }, [location.search]);


  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const TabPanel = ({ children, value, index }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ padding: "20px 0px 0px 0px" }}>{children}</Box>
      )}
    </div>
  );

  const a11yProps = (index) => ({
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  });



  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          Registered Tenants / Landlords/Admin
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="/admin/client/addUser" style={{ textDecoration: "none" }}>
            <Button
              style={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "130px",
                height: "30px",
              }}
              variant="contained"
            >
              Add New
            </Button>
          </Link>
        </Box>
      </Box>

      <Box style={{ margin: "18px 0px 0px 0px" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="navigation tabs"
          TabIndicatorProps={{
            style: { backgroundColor: "rgba(184, 25, 24, 1)", height: "1px" },
          }}
        >
          <Tab
            style={{
              color: selectedTab === 0 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 0 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Tenants"
            {...a11yProps(0)}
          />
          <Tab
            style={{
              color: selectedTab === 1 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 1 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Landlord"
            {...a11yProps(1)}
          />
          <Tab
            style={{
              color: selectedTab === 2 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 2 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Admin"
            {...a11yProps(2)}
          />
          <Tab
            style={{
              color: selectedTab === 3 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 3 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Finance"
            {...a11yProps(3)}
          />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <DataTable Data={Tenantdata} tableName="Tenant" />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <DataTable Data={Landlorddata} tableName="Landlord" />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <DataTable Data={Admindata} tableName="Admin" />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <DataTable Data={Financedata} tableName="Finance" />
        </TabPanel>
      </Box>
    </>
  );
};

export default Client;
