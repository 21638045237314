import React from "react";
import { useEffect, useState } from "react";
import { Grid, Container, Box, Paper } from "@mui/material";
import { PieChart, Pie, Cell } from "recharts";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Tab from "./Tab";
import { Divider } from "@mui/material";
import { useStyles } from "./DashboardStyle";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { statusMap } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";

// Maintenance data
const Disdata = [
  {
    id: 1,
    image: "/image/1name.jpg",
    label: "Tenant Name",
    title: "Pending",
  },
  {
    id: 2,
    image: "/image/2name.jpg",
    label: "Tenant Name",
    title: "Pending",
  },
  {
    id: 3,
    image: "/image/2name.jpg",
    label: "Tenant Name",
    title: "Resolved",
  },
  {
    id: 4,
    image: "/image/1name.jpg",
    label: "Tenant Name",
    title: "Resolved",
  },
  {
    id: 5,
    image: "/image/2name.jpg",
    label: "Tenant Name",
    title: "Resolved",
  },
];

const Dashboard = () => {
  const classes = useStyles();
  const [maintenanceData, setmaintenanceData] = useState([]);
  const navigate = useNavigate();
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);

  const getMaintenance = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${endpoints.getmaintenanceRequests}`, {
        headers: { Authorization: `Token ${token}` },
      });
      const data = response.data.data || [];
      setmaintenanceData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getAnalytics = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        endpoints.adminDashboardAnalytics,
        requestOptions
      );
      setAnalytics(response?.data?.data || []);
    } catch {
    } finally {
    }
  };

  useEffect(() => {
    getMaintenance();
    getAnalytics();
  }, []);

  


  const getmaintenance_status = (maintenance_status) => {
    return statusMap[maintenance_status] || "Unknown Status";
  };
  const handlemaintenance = () => {
    navigate("/admin/maintenance");
  };

  const handleCardClick = (request) => {
    navigate("/admin/maintenance-status", {
      state: {
        id: request.id,
        ...request,
      },
    });
  };

  const capitalize = (str) => {
    if (!str) return ""; // Return an empty string if input is falsy
    return `Welcome  ${
      str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    }!`;
  };
  const userName = capitalize(localStorage.getItem("loggedInUser"));

  // graph data
  const data = [
    {
      name: "Occupied",
      value: analytics?.unit_status_breakdown?.occupied?.percentage ?? 0,
      color: "#00B012",
    },
    {
      name: "Not Occupied",
      value: analytics?.unit_status_breakdown?.not_occupied?.percentage ?? 0,
      color: "#558FFF",
    },
    // { name: "Under Renovation", value: 12, color: "#FF5B19" },
  ];

  const CustomLegend = ({ data }) => (
    <Paper elevation={0}>
      {data?.map((entry, index) => (
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: entry.color,
              marginRight: 8,
              borderRadius: 25,
            }}
          />
          {entry.name}
        </div>
      ))}
    </Paper>
  );

  const renderLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    // if (percent !== 0.58) return;

    return (
      <text
        x={cx}
        y={cy}
        fill="black"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
       
        {/* {`${(percent * 100).toFixed(0)}% Occupied`} */}
        {/* {`${Math.floor(
          higherPercentage
        )}% Occupied`} */}
        {`${Math.floor( analytics?.unit_status_breakdown?.occupied?.percentage)}% Occupied` || 'N/A'}
      </text>
    );
  };
  return (
    <>
      <Typography
        style={{
          fontFamily: "Outfit",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 600,
          color: "#000000",
        }}
      >
        {userName}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={7} md={7}>
          <Box className={classes.graph}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "19.32px",
                  lineHeight: "28.98px",
                  fontWeight: 600,
                  color: "#101828",
                  margin: "23.5px 0px 0px 23px",
                }}
              >
                Units
              </Typography>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "13.52px",
                  lineHeight: "23.18px",
                  fontWeight: 400,
                  color: "#060606",
                  margin: "23.5px 35px 0px 0px",
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   navigate("/admin/unit");
                // }}
                onClick={() => { navigate('/admin/selection/unit') }}
              >
                See All
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={12} md={6}>
                <PieChart
                  width={249}
                  height={249}
                  tabIndex={-1}
                  className={classes.chart}
                  onClick={false}
                >
                  <Pie
                    data={data}
                    cx={125}
                    cy={124.5}
                    innerRadius={70}
                    outerRadius={95}
                    fill="#8884d8"
                    dataKey="value"
                    label={renderLabel}
                    labelLine={false}
                  >
                    {data?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.paper}>
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: 500,
                      color: "rgba(115, 115, 115, 1)",
                      margin: "0px 0px 0px 20px",
                    }}
                  >
                    Status
                  </Typography>
                  <Divider width={131} style={{ marginBottom: "5px" }} />
                  <CustomLegend data={data} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* usedByDevices  */}
        <Grid item xs={12} sm={5} md={5}>
          <Box className={classes.graph}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "19.32px",
                  lineHeight: "28.98px",
                  fontWeight: 600,
                  color: "#101828",
                  margin: "23.5px 0px 0px 0px",
                }}
              >
                Maintenance Requests
              </Typography>
              <Typography
                style={{
                  font: "Outfit",
                  fontSize: "13.52px",
                  lineHeight: "23.18px",
                  fontWeight: 400,
                  color: "#060606",
                  margin: "23.5px 20px 0px 0px",
                  cursor: "pointer",
                }}
                onClick={handlemaintenance}
              >
                See All
              </Typography>
            </Box>

            {loading ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                height={"50vh"}
              >
                <Loader />
              </Box>
            ) : maintenanceData.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                // height="24rem"
                height="63vh"
              >
                <Typography>No data available</Typography>
              </Box>
            ) : (
              <Box>
                {maintenanceData?.slice(0, 5).map((item, index) => (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #EAECF0",
                      margin: "15px 0px 0px 0px",
                      borderRadius: "5.8px",
                    }}
                    onClick={() => handleCardClick(item)}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className={classes.our}
                    >
                      <Avatar alt="Remy Sharp" src="/image/userAvatar.jpg" />
                      <Typography
                        style={{
                          color: "#060606",
                          fontFamily: "Outfit",
                          fontSize: "14px",
                          lineHeight: "22px",
                          fontWeight: 400,
                          margin: "0px 0px 0px 10px",
                          borderRadius: "10px",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "10px",
                        lineHeight: "10px",
                        fontWeight: 500,
                        margin: "0px 25px 0px 0px",
                        color:
                          item.maintenance_status === "1"
                            ? "#DD2025"
                            : item.maintenance_status === "3"
                            ? "#00E317"
                            : item.maintenance_status === "2"
                            ? "blue"
                            : "black",
                        border: `1px solid ${
                          item.maintenance_status === "1"
                            ? "#DD2025"
                            : item.maintenance_status === "3"
                            ? "#00E317"
                            : item.maintenance_status === "2"
                            ? "blue"
                            : "black"
                        }`,
                        padding: "5px 15px 5px 15px",
                        borderRadius: "24.5px",
                      }}
                    >
                      {getmaintenance_status(item?.maintenance_status)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Tab />
    </>
  );
};

export default Dashboard;
