import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import RentReimbursement from "./RentReimbursement";

const AddRent = () => {
  const [rentData, setRentData] = useState({
    amount: null,
    property: null,
    description: null,
  });
  const [propertyList, setPropertyList] = useState(null);
  const [isRentReimbursementCreated, setIsRentReimbursementCreated] =
    useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const getPropertyList = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        `${endpoints.landlordProperty}?primary_landlord_flag=${true}`,
        requestOptions
      );
      setPropertyList(response.data.data);
    } catch {}
  };

  useEffect(() => {
    getPropertyList();
  }, []);

  const createRentReimbursement = async (e) => {
    e.preventDefault()
    try {
      const payload = {
        property: rentData.property,
        amount: rentData.amount,
        description: rentData.description,
      };
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        endpoints.financeRentReimbursement,
        payload,
        requestOptions
      );
      toast.success(response.data.message);
      setIsRentReimbursementCreated(true);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      {isRentReimbursementCreated ? (
        <RentReimbursement />
      ) : (
        <>
          <Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              Name/Form No
            </Typography>
          </Box>
          <form onSubmit={createRentReimbursement}>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} md={4}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  color: "rgba(0, 0, 0, 1)",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Reimbursement Amount
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Amount here"
                name="amount"
                value={rentData.amount || ""}
                onChange={handleChange}
                
                style={{
                  margin: "0px 0px 25px 0px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Property
              </Typography>

              <FormControl fullWidth style={{ margin: "0px 0px 25px 0px" }}>
                <Select
                  name="property"
                  value={rentData.property || ""}
                  onChange={handleChange}
                  displayEmpty
                  
                >
                  <MenuItem value="">Select Property</MenuItem>
                  {propertyList?.map((property) => (
                    <MenuItem key={property.id} value={property.id}>
                      {`${property.ref_id} - ${property.title}` || "N/A"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  color: "rgba(0, 0, 0, 1)",
                  margin: "0px 0px 15px 0px",
                }}
              >
                Description
              </Typography>
              <TextField
                multiline
                rows={7}
                fullWidth
                variant="outlined"
                placeholder="Description here"
                name="description"
                value={rentData.description || ""}
                onChange={handleChange}
                style={{
                  margin: "0px 0px 25px 0px",
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              type="submit"
              style={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "130px",
                height: "40px",
                margin: "20px 0px 50px 0px",
              }}
              variant="contained"
            >
              Request
            </Button>
          </Box>
          </form>
        </>
      )}
    </>
  );
};

export default AddRent;
