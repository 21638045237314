import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Button,
  Modal,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { newIncomeTextfield } from "./AssociationStyle";
import toast from "react-hot-toast";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { AssociationStatus } from "../../../enums/UserTypes";
import { useNavigate, useLocation } from "react-router-dom";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const Tenantinfopdate = () => {
  const [fields, setFields] = useState([{ unit: "" }]);
  const [PropertyData, setPropertyData] = useState([]);
  const [TenantData, setTenantData] = useState([]);
  const [UnitData, setUnitData] = useState([]);
  const [formdata, setformdata] = useState({
    property: "",
    tenant: "",
    association_status: "",
  });
  const [reports, setReports] = useState({
    moveIn: null,
    moveOut: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfLetter, setPdfLetter] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const tenantData = location.state?.tenantData;

  const propertyList = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endpoints.Propertylist, {
        headers: { Authorization: `Token ${token}` },
      });
      const data = response.data.data;
      setPropertyData(data);
    } catch (error) {
     
    }
  };

  const getTenant = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endpoints.getUserListing}?&user_type=3`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const data = response.data.data;
      setTenantData(data);
    } catch (error) {
     
    }
  };

  const getUnit = async (propertyId) => {
    try {
      const token = localStorage.getItem("token");
      let url = propertyId
        ? `${endpoints.Unitlist}?property=${propertyId}`
        : endpoints.Unitlist; // Adapt endpoint as necessary
      const response = await axios.get(url, {
        headers: { Authorization: `Token ${token}` },
      });
      const data = response.data.data;
      setUnitData(data);
    } catch (error) {
   
    }
  };

  const updateuser = async () => {
    // const payload = {
    //   tenant: formdata.tenant,
    //   property: formdata.property,
    //   unit: fields.map((field) => field.unit),
    //   association_status: formdata.association_status,
    // };

    const unitsArray = fields.map((field) => field.unit);

    const tenantFormData = new FormData();
    tenantFormData.append("tenant", formdata.tenant);
    tenantFormData.append("property", formdata.property);
    tenantFormData.append("unit", JSON.stringify(unitsArray));
    tenantFormData.append("association_status", formdata.association_status);
    if (reports.moveIn) {
      tenantFormData.append("move_in_report", reports.moveIn);
    }

    if (reports.moveOut) {
      tenantFormData.append("move_out_report", reports.moveOut);
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${endpoints.TenantAssociation}?association_id=${tenantData.id}`,
        tenantFormData,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        handleSubmit();
      }
    } catch (error) {
      console.error("Error details:", error.response);
    
    }
  };

  const handleSubmit = () => {
    navigate("/admin/association");
  };

  const handleChange = (event, fieldId) => {
    const { name, value } = event.target;
    setformdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "unit") {
      const updatedFields = fields.map((field) =>
        field.id === fieldId ? { ...field, [name]: value } : field
      );
      setFields(updatedFields);
    }
  };

  const handleFileChange = (event, reportType) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      setReports((prevReports) => ({
        ...prevReports,
        [reportType]: file, // Update the specific report type
      }));
    }
  };

  const addField = () => {
    setFields([...fields, { id: Date.now(), unit: "" }]);
  };

  useEffect(() => {
    if (tenantData) {
      setformdata({
        property: tenantData.property.id,
        tenant: tenantData.tenant.id,
        association_status: tenantData.association_status || "",
      });
      setFields(
        tenantData.unit
          ? tenantData.unit.map((unit) => ({ unit: unit.id }))
          : []
      );
      getUnit(tenantData.property.id);
      //   setReports({
      //     moveIn: tenantData.move_in_report || "",
      //     moveOut: tenantData.move_out_report || "",
      //   });
    }
    propertyList();
    getTenant();
  }, [tenantData]);

  useEffect(() => {
    getUnit(formdata.property);
  }, [formdata.property]);

  const getAvailableUnits = (currentUnitId) => {
    const selectedUnitIds = fields
      .map((field) => field.unit)
      .filter((id) => id);
    return UnitData.filter(
      (unit) => !selectedUnitIds.includes(unit.id) || unit.id === currentUnitId
    );
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (pdfUrl) => {
    if (!pdfUrl) {
      toast.error('No report available to view.');
      return;
    }
    setPdfLetter(pdfUrl);
    setIsModalOpen(true);
  };

  return (
    <>
      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
            margin: "0px 0px 50px 0px",
          }}
        >
          Tenant Association
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Property
          </Typography>
          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="property"
              value={formdata.property}
              onChange={handleChange}
            >
              {PropertyData.map((property) => (
                <MenuItem key={property.id} value={property.id}>
                  {`${property.ref_id} - ${property.title}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Tenant
          </Typography>
          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="tenant"
              value={formdata.tenant}
              onChange={handleChange}
            >
              {TenantData.map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.id}>
                  {tenant.first_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Unit selection */}
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "20px 0px 15px 0px",
            }}
          >
            Unit
          </Typography>
          {fields.map((field, index) => (
            <Grid container spacing={2} key={field.id}>
              <Grid item xs={12}>
                <FormControl fullWidth sx={newIncomeTextfield}>
                  <Select
                    name="unit"
                    value={field.unit}
                    onChange={(e) => handleChange(e, field.id)}
                    displayEmpty
                    required
                  >
                    <MenuItem value="" disabled>
                      Select Unit
                    </MenuItem>
                    {getAvailableUnits(field.unit).map((unit) => (
                      <MenuItem key={unit.id} value={unit.id}>
                        {`${unit.ref_id} - ${unit.title}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {fields.length - 1 === index && (
                  <IconButton
                    onClick={addField}
                    style={{ color: "#B81918", marginTop: 3 }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "20px 0px 15px 0px",
            }}
          >
            Status
          </Typography>
          <FormControl fullWidth sx={newIncomeTextfield}>
            <Select
              name="association_status"
              value={formdata.association_status}
              onChange={handleChange}
              displayEmpty
              required
            >
              <MenuItem value="" disabled>
                Select your Status
              </MenuItem>
              <MenuItem value={AssociationStatus.ACTIVE}>Active</MenuItem>
              <MenuItem value={AssociationStatus.INACTIVE}>In-Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* Upload Files  */}
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Move-in Report
          </Typography>
          <Box
            sx={{
              p: 5,
              border: 1,
              borderColor: "grey.300",
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <input
              accept="*/*"
              type="file"
              onChange={(e) => handleFileChange(e, "moveIn")}
              style={{ display: "none" }}
              id="file-input-move-in"
            />
            <label htmlFor="file-input-move-in">
              <Button
                variant="contained"
                component="span"
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  textTransform: "none",
                  borderRadius: "8px",
                  margin: "100px 0px 100px 0px",
                }}
                startIcon={<CloudUploadIcon />}
              >
                Upload File
              </Button>
            </label>
            <Button
              variant="contained"
              component="span"
              style={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                textTransform: "none",
                borderRadius: "8px",
                // margin: "100px 0px 100px 0px",
                marginLeft: "8px",
              }}
              onClick={() => {
                handleModalOpen(tenantData.move_in_report);
              }}
            >
              View Uploaded File
            </Button>
            {reports.moveIn && <Typography>{reports.moveIn.name}</Typography>}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "18px",
              margin: "0px 0px 15px 0px",
            }}
          >
            Move-out Report
          </Typography>
          <Box
            sx={{
              p: 5,
              border: 1,
              borderColor: "grey.300",
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <input
              accept="*/*"
              type="file"
              onChange={(e) => handleFileChange(e, "moveOut")}
              style={{ display: "none" }}
              id="file-input-move-out"
            />
            <label htmlFor="file-input-move-out">
              <Button
                variant="contained"
                component="span"
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  textTransform: "none",
                  borderRadius: "8px",
                  margin: "100px 0px 100px 0px",
                }}
                startIcon={<CloudUploadIcon />}
              >
                Upload File
              </Button>
            </label>
            <Button
              variant="contained"
              component="span"
              style={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                textTransform: "none",
                borderRadius: "8px",
                // margin: "100px 0px 100px 0px",
                marginLeft: "8px",
              }}
              onClick={() => {
                handleModalOpen(tenantData.move_out_report);
              }}
            >
              View Uploaded File
            </Button>
            {reports.moveOut && <Typography>{reports.moveOut.name}</Typography>}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", margin: "20px 0 50px 0" }}>
        <Button
          style={{
            backgroundColor: "rgba(184, 25, 24, 1)",
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: 500,
            textTransform: "none",
            width: "130px",
            height: "40px",
          }}
          variant="contained"
          onClick={updateuser}
        >
          Save
        </Button>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="pdf-viewer-modal"
        aria-describedby="pdf-viewer-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: "90%",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            overflow: "auto",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography gutterBottom>Uploaded File</Typography>
            <Button
              sx={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                color: "white",
                fontSize: "18px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "5rem",
                height: "2.5rem",
                borderRadius: "0.5rem",
                // margin: "20px 0px 50px 0px",
              }}
              onClick={handleModalClose}
            >
              Close
            </Button>
          </Box>

          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            {error ? (
              <div>Error loading PDF: {error.message}</div>
            ) : (
              <Viewer fileUrl={pdfLetter || ''} />
            )}
          </Worker>
        </Box>
      </Modal>
    </>
  );
};

export default Tenantinfopdate;
