import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { mainTypography, tableBodyCell, tableHeadCell } from "./AmcStyles";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { Loader } from "../../../utils/Loader";
import { PropertyType } from "../../../enums/UserTypes";

function AmcSchedule() {

  const [Amcs, setAmcs] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAMCs = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(endpoints.AmcSchedule, requestOptions);
      setAmcs(response.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAMCs();
  }, []);

  
  return (
    <>
      <Box>
        <Typography sx={mainTypography}>AMC Schedule</Typography>
      </Box>

      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : Amcs.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <Box marginTop={"2rem"}>
          <TableContainer sx={{ height: "65vh", overflowX: "auto" }}>
            <Table sx={{ border: "1px solid #EFEFEF", minWidth: "170rem" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeadCell}>Serial No</TableCell>
                  <TableCell sx={tableHeadCell}>Refernce No</TableCell>
                  <TableCell sx={tableHeadCell}>Date</TableCell>
                  <TableCell sx={tableHeadCell}>Property</TableCell>
                  <TableCell sx={tableHeadCell}>Type</TableCell>
                  <TableCell sx={tableHeadCell}>Category</TableCell>
                  <TableCell sx={tableHeadCell}>Service Provider</TableCell>
                  <TableCell sx={tableHeadCell}>Start Date</TableCell>
                  <TableCell sx={tableHeadCell}>End Date</TableCell>
                  <TableCell sx={tableHeadCell}>Description</TableCell>
                  <TableCell sx={tableHeadCell}>Amount</TableCell>
                  <TableCell sx={tableHeadCell}>Number of Payments</TableCell>
                  <TableCell sx={tableHeadCell}>1st Date</TableCell>
                  <TableCell sx={tableHeadCell}>1st Amount</TableCell>
                  <TableCell sx={tableHeadCell}>2nd Date</TableCell>
                  <TableCell sx={tableHeadCell}>2nd Amount</TableCell>
                  <TableCell sx={tableHeadCell}>3rd Date</TableCell>
                  <TableCell sx={tableHeadCell}>3rd Amount</TableCell>
                  <TableCell sx={tableHeadCell}>4th Date</TableCell>
                  <TableCell sx={tableHeadCell}>4th Amount</TableCell>
                  <TableCell sx={tableHeadCell}>5th Date</TableCell>
                  <TableCell sx={tableHeadCell}>5th Amount</TableCell>
                  <TableCell sx={tableHeadCell}>6th Date</TableCell>
                  <TableCell sx={tableHeadCell}>6th Amount</TableCell>
                  <TableCell sx={tableHeadCell}>Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Amcs?.map((amc, index) => (
                  <TableRow key={amc.id}>
                    <TableCell sx={tableBodyCell}>{`S.No ${index + 1
                      }`}</TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.reference_no || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>{amc.date || "-"}</TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.property?.ref_id || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc?.property?.type == PropertyType.RESIDENTIAL
                        ? "Residential"
                        : amc?.property?.type == PropertyType.COMMERCIAL
                          ? "Comercial"
                          : amc?.property?.type == PropertyType.COMMUNITY
                            ? "Community"
                            : "" || "-"}
                    </TableCell>

                    <TableCell sx={tableBodyCell}>
                      {amc.category || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.service_provider || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.start_date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.end_date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.description || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.amount || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.number_of_payments || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.first_date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.first_amount || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.second_date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.second_amount || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.third_date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.third_amount || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.fourth_date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.fourth_amount || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.fifth_date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.fifth_amount || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.sixth_date || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.sixth_amount || "-"}
                    </TableCell>
                    <TableCell sx={tableBodyCell}>
                      {amc.comments || "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
}

export default AmcSchedule;
