import React, { useState, useRef } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import Popup from "./Popup";
import { useStyles } from "./ClientStyle";

const UserAdd = ({ userFormData }) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);

  const [formData, setFormData] = useState({
    EmiratesId: "",
    PassportNumber: "",
  });

  const [file, setFile] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowPopup(true);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <Popup
          onClose={handleClosePopup}
          userFormData={userFormData}
          userAddFormData={formData}
          userFile={file}
        />
      )}
      <Box className={classes.root}>
        <Box className={classes.Sroot}>
          <Box className={classes.rootimage}>
            <Box className={classes.image}>
              <img
                src="/image/logo.png"
                alt="logo pic"
                style={{ width: "100px", height: "80px" }}
              />
            </Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: 400,
                color: "rgba(6, 6, 6, 1)",
              }}
            >
              User Management
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Typography
              className={classes.typography}
              sx={{
                margin: "12px 0px 3px 0px",
              }}
            >
              Emirates Id
            </Typography>
            <TextField
              className={classes.INput}
              InputProps={{
                style:{
                  backgroundColor:'rgba(239, 239, 239, 0.5)'
                }
              }}
              size="small"
              placeholder="111 222 333 444 555"
              name="EmiratesId"
              value={formData.EmiratesId}
              onChange={handleChange}
            />
            <Typography
              className={classes.typography}
              sx={{
                margin: "12px 0px 3px 0px",
              }}
            >
              Passport Number
            </Typography>
            <TextField
              className={classes.INput}
              InputProps={{
                style:{
                  backgroundColor:'rgba(239, 239, 239, 0.5)'
                }
              }}
              placeholder="24856587456"
              size="small"
              name="PassportNumber"
              type="tel"
              value={formData.PassportNumber}
              onChange={handleChange}
            />
            <Typography
              className={classes.typography}
              sx={{
                margin: "12px 0px 3px 0px",
              }}
            >
              Add Documents
            </Typography>
            <TextField
              className={classes.INput}
              variant="outlined"
              size="small"
              value={file ? file.name : "Select File"}
              readOnly
              InputProps={{
                style : { backgroundColor:'rgba(239, 239, 239, 0.5)'
                },
            
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleFileButtonClick}
                      sx={{
                        color: "#B81918",
                      }}
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <input
              ref={fileInputRef}
              type="file"
              //   accept="application/pdf"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <br />
            <Button
              className={classes.bph}
              type="submit"
              variant="contained"
              style={{
                fontSize: "15px",
                fontWeight: 500,
                lineHeight: "23.48px",
                fontFamily: "Outfit",
                borderRadius: "6px",
                height: "10%",
                backgroundColor: "rgba(184, 25, 24, 1)",
                textTransform: "none",
                margin: "40px 0px 3px 0px",
              }}
              onClick={handleSubmit}
            >
              Register
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default UserAdd;
