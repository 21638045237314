import React from "react";
import { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { FormControl, Select, MenuItem, TextField, Button } from "@mui/material";
import StatusPopup from "./StatusPopup";


const UnitStatus = () => {
    const [thirdSelectValue, setThirdSelectValue] = useState('');
    const [showPopup, setShowPopup] = useState(false);


    const handleSubmit = () => {
        setShowPopup(true);
    }

    const handleClosePopup = () => {
        setShowPopup(false);
    };


    return (
        <>
            {showPopup && <StatusPopup onClose={handleClosePopup} />}
            <Grid container >
                <Grid item xs={12} md={4} alignItems="center">
                    <Box>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <img src="/image/unimage1.jpg" alt="this image is not show" width={340} />
                        </Box>
                        <Box sx={{ display: "flex", margin: "10px 0px 0px 0px", color: "rgba(184, 25, 24, 1)" }}>
                            <LocationOnRoundedIcon style={{ width: "20px" }} />
                            <Typography style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "16px",
                                margin: "0px 0px 0px 10px"
                            }}>
                                305 block C, Abu Dhabi
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* Empty Grid */}
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={5}>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Status</Typography>
                    <FormControl fullWidth>
                        <Select

                            value={thirdSelectValue}
                            onChange={(e) => setThirdSelectValue(e.target.value)}
                            style={{
                                border: "0px solid rgba(239, 239, 239, 1)",

                            }}
                        >
                            <MenuItem value="Available For Rent">Available For Rent</MenuItem>
                            <MenuItem value="one">One</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={4}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Availability Date</Typography>
                    <TextField fullWidth variant="outlined" placeholder='05 Apr 2024'
                        style={{ margin: "0px 0px 15px 0px" }}
                    />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', margin: '20px 0 50px 0' }}>
                <Button
                    style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "170px",
                        height: "40px",
                    }}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Confirm Changes
                </Button>
            </Box>
        </>
    )
}

export default UnitStatus;