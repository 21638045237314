import React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, Typography, Divider, useMediaQuery, useTheme } from "@mui/material";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useStyles, calendarTypography, selectedCalendarTypography, } from "./PropertyStyle";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import PropertyTable from './PropertyTable';
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";






const data = [
    { name: 'Jan', Occupied: 80, Vacant: 30 },
    { name: 'Feb', Occupied: 20, Vacant: 60 },
    { name: 'Mar', Occupied: 65, Vacant: 25 },
    { name: 'Apr', Occupied: 5, Vacant: 2 },
    { name: 'May', Occupied: 25, Vacant: 10 },
    { name: 'Jun', Occupied: 70, Vacant: 40 },
    { name: 'Jul', Occupied: 20, Vacant: 60 },
    { name: 'Aug', Occupied: 10, Vacant: 20 },
    { name: 'Sep', Occupied: 30, Vacant: 45 },
    { name: 'Oct', Occupied: 55, Vacant: 35 },
    { name: 'Nov', Occupied: 65, Vacant: 50 },
    { name: 'Dec', Occupied: 15, Vacant: 5 }
];



const Property = () => {
    const classes = useStyles();
    const lines = ["Quarter", "Half Year", "Year"];
    const [selected, setSelected] = useState(lines[0]);
    const [analyticData, setanalyticData] = useState(null);





    const getPropertyanalytics = async () => {

        try {
            let filter;
            selected === 'Quarter' ? filter = 'quarter' : selected === 'Half Year' ? filter = 'half_year' : filter = 'year'
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.landlordPropertyAnalytics}?occupancy_filter=${filter}`, {
                headers: { Authorization: `Token ${token}` }
            });

            const data = response.data.data
            setanalyticData(data);



        } catch (error) {

        }
    };

    useEffect(() => {
        getPropertyanalytics();
    }, [selected]);




    const totalproperties = analyticData?.total_properties_data || [];
    const PropertyStatus = analyticData?.monthly_status || [];













    // To select Quarter, Half Year, Yearly
    const handleSelect = (type) => {
        setSelected(type);
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Define width based on screen size
    const chartWidth = isSmallScreen ? 300 : 650;  // Adjust as per your layout requirements


    console.log("this is selected value", selected)

    return (
        <>

            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "#000000"
                }}>
                    Property Analytics
                </Typography>
            </Box>
            <Grid container spacing={3} mt={1} >
                <Grid item xs={12} sm={4} md={4}  >
                    <Box className={classes.graph}>
                        <Box>
                            <Typography style={{
                                fontFamily: "Outfit",
                                fontSize: "20px",
                                lineHeight: "24px",
                                fontWeight: 600,
                                color: "#000000",
                                margin: "20px 0px 20px 20px"
                            }}>
                                Total Properties
                            </Typography>
                        </Box>
                        <Box style={{ margin: "25px 0px 25px 10px" }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ position: 'relative', width: 200, height: 200 }}>
                                    <CircularProgressbar
                                        value={100}
                                        styles={buildStyles({
                                            pathColor: '#d6d6d6', // grey color for vacant
                                            trailColor: '#EFEFEF',
                                            pathWidth: 8,
                                            trailWidth: 8,
                                        })}
                                    />
                                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                                        <CircularProgressbar
                                            value={totalproperties.occupied}
                                            styles={buildStyles({
                                                pathColor: '#B51E24', // red color for occupied
                                                trailColor: '#EFEFEF',
                                                pathWidth: 8,
                                                trailWidth: 8,
                                            })}
                                        />
                                    </div>
                                    <div style={{ position: 'absolute', top: '10%', left: '10%', width: '80%', height: '80%' }}>
                                        <CircularProgressbar
                                            value={totalproperties.vacant}
                                            styles={buildStyles({
                                                pathColor: '#6c757d', // grey color for vacant
                                                trailColor: '#EFEFEF',
                                                pathWidth: 8,
                                                trailWidth: 8,
                                            })}
                                        />
                                    </div>
                                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                                        <span style={{ color: '#B51E24', fontSize: '20px' }}>{totalproperties.total}</span><br />
                                        <span>Total</span>
                                    </div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#B51E24', marginRight: '5px' }}></div>
                                        <span>Occupied</span>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#6c757d', marginRight: '5px' }}></div>
                                        <span>Vacant</span>
                                    </div>
                                </div>
                            </div>
                        </Box>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={8}  >
                    <Box className={classes.graph}>
                        <Box style={{ padding: "20px 0px 0px 15px" }}>
                            <Grid container spacing={3} >
                                <Grid item xs={12} sm={3} md={3}  >
                                    <Typography style={{
                                        fontFamily: "Outfit",
                                        fontSize: "20px",
                                        lineHeight: "24px",
                                        fontWeight: 600,
                                        color: "#000000"
                                    }}>
                                        Property Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}  >
                                    <Typography>
                                        <span className={`${classes.legendDot} ${classes.occupied}`} /> Occupied
                                        <span className={`${classes.legendDot} ${classes.vacant}`} /> Vacant
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5} md={5}  >
                                    <Box className={classes.option}>
                                        {lines.map((item) => (
                                            <Typography
                                                key={item}
                                                sx={{
                                                    ...calendarTypography,
                                                    ...(selected === item && selectedCalendarTypography),
                                                }}
                                                onClick={() => handleSelect(item)}
                                            >
                                                {item}
                                            </Typography>
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box style={{ margin: "19px 0px 30px 0px" }}>
                            <Divider />
                        </Box>

                        <Box style={{ width: chartWidth, height: 200 }}>
                            <BarChart
                                width={chartWidth}
                                height={200}
                                data={PropertyStatus}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis tickFormatter={(value) => Math.round(value)} />
                                <Tooltip formatter={(value) => Math.round(value)} />
                                <Bar dataKey="occupied" fill="#B81918" />
                                <Bar dataKey="vacant" fill="#808080" />
                            </BarChart>
                        </Box>

                    </Box>
                </Grid>
            </Grid>
            <PropertyTable />
        </>
    )
}


export default Property;


