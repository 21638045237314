import React, { useState } from "react";
import { useEffect } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import {
  StatusTypography,
  StatusButton,
  UserDetailsTypography,
  UserDetailsTypography2,
  ComplaintsDetailBox,
  ComplaintAdresBox,
  ComplaintDescriptionBox,
} from "./MaintenanceStyles";
import axios from 'axios';
import toast from "react-hot-toast";
import { endpoints } from "../../../apiEndpoints";
import { useLocation } from "react-router-dom";





function ComplaintStatus() {
  const [complainstatus, setcomplainstatus] = useState({})
  const location = useLocation();
  const { state } = location;
  const { id } = state || {};


  useEffect(() => {
    const getcomplainRequests = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${endpoints.getComplainrequest}?id=${id}`, {
          headers: { Authorization: `Token ${token}` }
        });
        console.log("API Response Data:", response.data.data);
        setcomplainstatus(response.data.data[0] || {});
      } catch (error) {
        console.error("API Error:", error);
        toast.error(error.response.data.message);
      }
    };

    if (id) {
      getcomplainRequests();
    }
  }, [id]);


  console.log("complainstatus", complainstatus)

  const Updaterequest = async (approvalValue) => {
    const data = { admin_approval: approvalValue.toString() };
    try {
      const token = localStorage.getItem('token');
      const response = await axios.patch(`${endpoints.getComplainrequest}?complaint_id=${id}`, data,
        {
          headers: { Authorization: `Token ${token}` }
        });


      setcomplainstatus(prevState => ({
        ...prevState,
        admin_approval: approvalValue
      }));


    } catch (error) {
      console.error("API Error:", error);
      toast.error(error.response.data.message);
    }
  };





  return (
    <>
      <Grid>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
          }}
        >
          <StatusTypography
            sx={{ fontSize: { xs: "25px", sm: "25px", md: "36px" } }}
          >
            Complaints
          </StatusTypography>
          <Box>
            {complainstatus?.admin_approval == 1 && (
              <>
                <StatusButton style={{margin:"0px 15px 0px 0px"}} onClick={() => Updaterequest(3)}>Disapprove</StatusButton>
                <StatusButton style={{margin:"0px 0px 0px 0px"}} onClick={() => Updaterequest(2)}>Approve</StatusButton>
              </>
            )}
            {complainstatus?.admin_approval == 2 && (
              <>
                <StatusButton>Approved</StatusButton>
              </>
            )}
            {complainstatus?.admin_approval == 3 && (
              <StatusButton>Disapproved</StatusButton>
            )}


          </Box>


        </Box>





        {/* Name Fields */}
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          gap={{ sm: "2rem" }}
        >
          <ComplaintsDetailBox>
            <UserDetailsTypography>First Name</UserDetailsTypography>
            <UserDetailsTypography2>{complainstatus?.first_name || "Loading..."}</UserDetailsTypography2>
          </ComplaintsDetailBox>
          <ComplaintsDetailBox>
            <UserDetailsTypography>Last Name</UserDetailsTypography>
            <UserDetailsTypography2>{complainstatus?.last_name || "Loading..."}</UserDetailsTypography2>
          </ComplaintsDetailBox>
        </Box>

        {/* Contact Fields */}
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          gap={{ sm: "2rem" }}
        >
          <ComplaintsDetailBox>
            <UserDetailsTypography>Phone Number</UserDetailsTypography>
            <UserDetailsTypography2>{complainstatus?.phone || "Loading..."}</UserDetailsTypography2>
          </ComplaintsDetailBox>
          <ComplaintsDetailBox>
            <UserDetailsTypography>Email</UserDetailsTypography>
            <UserDetailsTypography2>
              {complainstatus?.email || "Loading..."}
            </UserDetailsTypography2>
          </ComplaintsDetailBox>
        </Box>

        {/* Address Field */}
        <ComplaintDescriptionBox>
          <ComplaintAdresBox>
            <UserDetailsTypography>Address</UserDetailsTypography>
            <UserDetailsTypography2>{complainstatus?.address || "Loading..."}</UserDetailsTypography2>
          </ComplaintAdresBox>
        </ComplaintDescriptionBox>

        {/* Complaint Description */}
        <ComplaintDescriptionBox>
          <ComplaintAdresBox>
            <UserDetailsTypography>
              Description of Request

            </UserDetailsTypography>
            <UserDetailsTypography2
              sx={{ height: "11.188rem", alignItems: "start",paddingTop:'0.5rem' }}
            >
              {complainstatus?.description || "Loading..."}
            </UserDetailsTypography2>
          </ComplaintAdresBox>
        </ComplaintDescriptionBox>
      </Grid>
    </>
  );
}

export default ComplaintStatus;
