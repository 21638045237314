import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';


export const useStyles = makeStyles(() => {
    const theme = useTheme();
    return {
        Poproot: {
            width: "800px",
            height: "550px",
            transform: 'translate(-70%, -50%)',
            border: "1px solid rgba(154, 25, 21, 1)",
            borderRadius: "30px",
            margin: "0px 0px 40px 70px",
            [theme.breakpoints.down("sm")]: {
                width: "350px",
                transform: 'translate(-50%, -50%)',
                margin: "0px 0px 0px 0px"
            }
        },

    };
});

