import { makeStyles, styled } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    mainBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },

    subBox: {
      display: "flex",
      // justifyContent: "space-between",
      gap: "10px",
      alignItems: "center",
    },
    gridItem: {
      border: "1px solid #EFEFEF",
      borderRadius: "1rem",
      display: "flex",
      padding: "1rem",
      gap: "0.5rem",
      marginBottom: "10px",
    },
    gridTypoBox: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
    emptyNotification: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "1rem",
    },
  };
});

export const mainTypography = {
  fontFamily: "Outfit",
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "24px",
};

export const muteTypography = {
  fontFamily: "Outfit",
  fontSize: "16px",
  fontWeight: "350",
  lineHeight: "20px",
  color: "#B81918",
};

export const notificationTypography = {
  fontFamily: "Outfit",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "20px",
};
export const notificationTitleTypography = {
  fontFamily: "Outfit",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "20px",
};

export const timeTypography = {
  fontFamily: "Outfit",
  fontSize: "16px",
  fontWeight: "350",
  lineHeight: "20px",
  color: "#A5ACB8",
};


