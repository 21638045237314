import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { newIncomeTextfield } from "./InquiriesStyle";
import axios from "axios";
import toast from "react-hot-toast";
import { endpoints } from "../../../apiEndpoints";
import Inquiries from "./Inquiries";




const AddInquiries = () => {
  const [inquiresFormData, setInquiresFormdata] = useState({
    name: "",
    email: "",
    emirates_id: "",
    address: "",
    phone: "",
    emergency_contact: "",
    passport_no: "",
    passport_expiry: "",
    nationality: "",
    uid: "",
  });
  const [isUpdateScreen, setIsUpdateScreen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };

      console.log("form data of inquiry", inquiresFormData);
      const response = await axios.post(
        endpoints.createInquiry,
        inquiresFormData,
        requestOptions
      );

      toast.success(response.data.message);
      setIsUpdateScreen(true);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInquiresFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <>
      {isUpdateScreen ? (
        <Inquiries />
      ) : (
        <>
          <Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              Inquiries
            </Typography>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "18px",
                lineHeight: "18px",
                fontWeight: 400,
                color: "rgba(0, 0, 0, 1)",
                margin: "50px 0px 25px 0px",
              }}
            >
              Details
            </Typography>
          </Box>

          {loading && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}



          <form onSubmit={handleSubmit}>
            {/* lesse and email fields */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Lessee
                </Typography>
                <TextField
                  fullWidth
                  
                  variant="outlined"
                  placeholder="Rayan"
                  name="name"
                  value={inquiresFormData.name}
                  onChange={handleChange}
                  style={{
                    margin: "0px 0px 25px 0px",
                  }}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  fullWidth
                  
                  variant="outlined"
                  placeholder="Michaelrayan@capstoneuae.com"
                  name="email"
                  value={inquiresFormData.email}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>
            {/* emirateId and address fields */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Emirates Id
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="111-2222-3344556-7"
                  name="emirates_id"
                  value={inquiresFormData.emirates_id}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Address
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Block C. Unit 305"
                  name="address"
                  value={inquiresFormData.address}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>
            {/* phoneNo and emergencyContact fields */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Phone No
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="phone"
                  value={inquiresFormData.phone}
                  onChange={handleChange}
                  placeholder="+1 23 456 789 00"
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Emergency Contact
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="+1 23 456 789 00"
                  name="emergency_contact"
                  value={inquiresFormData.emergency_contact}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>
            {/* passportNo and expirtDate fields */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Passport Number
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="457896542"
                  name="passport_no"
                  value={inquiresFormData.passport_no}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Passport Expiry
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  placeholder="02/28"
                  name="passport_expiry"
                  value={inquiresFormData.passport_expiry}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>
            {/* nationality and uidNo fields */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Nationality
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Arabian"
                  name="nationality"
                  value={inquiresFormData.nationality}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 15px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  UID No
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="1234567890"
                  name="uid"
                  value={inquiresFormData.uid}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 15px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", margin: "20px 0 50px 0" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "130px",
                  height: "40px",
                }}
                variant="contained"
                type="submit"
                disabled={loading}
              >
                Save
              </Button>
            </Box>
          </form>
        </>
      )}
    </>
  );
};

export default AddInquiries;
