import React from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateMediaData, uploadImage } from '../../../../Redux/AdminReducer/formsSlice';
import AddProperty from "./AddProperty";
import AddDetails from "./AddDetails";
import { useStyles, newIncomeTextfield, } from './PropertieStyle';

const AddMedia = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [backcom, setbackcom] = useState(false);
    const uploadedImages = useSelector(state => state.form.uploadedImages);



    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length + Object.keys(uploadedImages).length > 5) {
            alert("You can only upload up to 5 images.");
            return;
        }
        files.forEach(file => {
            if (file instanceof Blob) { 
                const url = URL.createObjectURL(file);
                console.log(url); 
                dispatch(uploadImage(file));
            } else {
                console.error('Invalid file object:', file);
            }
        });
    };

    const mediaData = useSelector(state => state.form.mediaData);
    const [localData, setLocalData] = useState(mediaData);

    useEffect(() => {
        setLocalData(mediaData);
    }, [mediaData]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLocalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleBack = () => {
        dispatch(updateMediaData(localData));
        setbackcom(true)
    }
    if (backcom) {
        return (
            <AddProperty />
        )
    }

    const handleNext = () => {
        dispatch(updateMediaData(localData));
        setOpen(true);
    };

    if (open) {
        return <AddDetails />;
    }

    return (
        <>
            <Typography variant="h6" style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "0px 0px 50px 0px"
            }} >
                Add Property
            </Typography>
            <Typography variant="subtitle1"
                style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "60px 0px 30px 0px"
                }}
            >
                Media
            </Typography>
            <Typography style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "10px 0px 20px 0px"
            }}>
                Add Images*
            </Typography>
            <Box sx={{ p: 5, border: 1, borderColor: 'grey.300', borderRadius: 2, textAlign: 'center' }}>
                <input
                    accept="image/*"
                    type="file"
                    multiple
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                />
                <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span"
                        style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            textTransform: "none",
                            borderRadius: "8px",
                            margin: "100px 0px 100px 0px"
                        }}
                    >
                        Select and Upload
                    </Button>
                </label>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 2 }}>
                    {Object.keys(uploadedImages).map((id, index) => (
                        <img key={index} src={URL.createObjectURL(uploadedImages[id])} alt={`Uploaded Preview ${index}`} style={{ width: 100, height: 100, margin: 10 }} />
                    ))}
                </Box>
            </Box>

            <Box sx={{ mt: 2, mb: 4 }}>
                <Typography variant="subtitle2" style={{
                    margin: "10px 0px 15px 0px",
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px"
                }}>Add Video URL</Typography>
                <TextField
                    fullWidth
                    placeholder="URL Supported"
                    variant="outlined"
                    name="media_url"
                    value={localData.media_url || ''}
                    onChange={handleInputChange}
                    sx={newIncomeTextfield}
                />
            </Box>
            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                <Button style={{
                    border: "1px solid rgba(154, 25, 21, 1)",
                    color: "rgba(154, 25, 21, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "40px",
                    margin: "20px 0px 50px 0px"
                }}
                    variant="outlined"
                    onClick={handleBack}

                >Back</Button>
                <Box sx={{ display: 'flex', }}>
                    <Button style={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 0px"

                    }}
                        variant="contained"
                        onClick={handleNext}
                    >Next</Button>
                </Box>
            </Box>
        </>
    );
};

export default AddMedia;



