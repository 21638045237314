import React from "react";
import { Box, Typography, Button, Grid, Divider } from "@mui/material";
import { useStyles } from "./FinancialStyle";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FormControl, Select, MenuItem } from "@mui/material";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { useState, useEffect } from "react";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



const Document = [
  {
    id: "1",
    Date: "Jan 2023",
    Amount: "500K AED",
    Expenses: "500K AED",
    Profit: "500K AED"
  },
  {
    id: "2",
    Date: "Jan 2023",
    Amount: "500K AED",
    Expenses: "500K AED",
    Profit: "500K AED"
  },
  {
    id: "3",
    Date: "Jan 2023",
    Amount: "500K AED",
    Expenses: "500K AED",
    Profit: "500K AED"
  },
  {
    id: "4",
    Date: "Jan 2023",
    Amount: "500K AED",
    Expenses: "500K AED",
    Profit: "500K AED"
  },
  {
    id: "5",
    Date: "Jan 2023",
    Amount: "500K AED",
    Expenses: "500K AED",
    Profit: "500K AED"
  },
  {
    id: "6",
    Date: "Jan 2023",
    Amount: "500K AED",
    Expenses: "500K AED",
    Profit: "500K AED"
  },
  {
    id: "7",
    Date: "Jan 2023",
    Amount: "500K AED",
    Expenses: "500K AED",
    Profit: "500K AED"
  },
  {
    id: "8",
    Date: "Jan 2023",
    Amount: "500K AED",
    Expenses: "500K AED",
    Profit: "500K AED"
  },


]








const Graph = () => {
  const classes = useStyles();
  const [financialData, setFinancialData] = useState([]);
  const [selectedValue, setSelectedValue] = useState({
    filter: "",

  });

  const getFinancialData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log("Token not found in localStorage");
        return;
      }
      const response = await axios.get(`${endpoints.landlordFinancialReport}?filter=${selectedValue.filter}`, {
        headers: { Authorization: `Token ${token}` }
      });
      if (response.data) {
        setFinancialData(response.data);
      } else {
        console.log("Unexpected data structure:", response.data);
      }
    } catch (error) {
      console.log("Error fetching user data", error.response || error);
    }
  };

  useEffect(() => {
    getFinancialData();
  }, [selectedValue.filter]);

  const totalAmount = financialData?.monthly_data?.map((item) => item.total_amount) || [];
  const totalExpenses = financialData?.monthly_data?.map((item) => item.total_expenses) || [];
  const totalProfit = financialData?.monthly_data?.map((item) => item.total_profit) || [];
  const Month = financialData?.monthly_data?.map((item) => item.month) || [];

  // Calculate the maximum value to adjust the y-axis dynamically
  const allDataPoints = [...totalAmount, ...totalExpenses, ...totalProfit];
  const maxValue = Math.max(...allDataPoints);
  const stepSize = Math.ceil(maxValue / 5);  

  // const options = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   scales: {
  //     y: {
  //       beginAtZero: true,
  //       ticks: {
  //         callback: function (value) {
  //           if (value >= 1000000) {
  //             return `${value / 1000000}M`;
  //           } else if (value >= 1000) {
  //             return `${value / 1000}K`;
  //           }
  //           return value;
  //         },
  //         stepSize: stepSize,
  //       },
  //       max: maxValue + stepSize, // Adding some padding above max value
  //     }
  //   },
  //   plugins: {
  //     legend: {
  //       display: false,
  //       position: "top"
  //     }
  //   },
  // };


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            if (value >= 1000000) {
              return `${(value / 1000000).toFixed(0)}M`; // No decimals for millions
            } else if (value >= 1000) {
              return `${(value / 1000).toFixed(0)}K`; // No decimals for thousands
            }
            return value.toFixed(0); // No decimals for smaller values
          },
          stepSize: stepSize,
        },
        max: maxValue + stepSize, // Adding some padding above max value
      }
    },
    plugins: {
      legend: {
        display: false,
        position: "top"
      },
      datalabels: {
        display: true,
        color: '#444',
        anchor: 'end',
        align: 'top',
        formatter: function (value, context) {
          return value.toFixed(0); // No decimals on data points
        }
      }
    },
  };




  const data = {
    labels: Month,
    datasets: [
      {
        label: 'Total Amount',
        data: totalAmount,
        backgroundColor: '#B81918',
      },
      {
        label: 'Total Expenses',
        data: totalExpenses,
        backgroundColor: '#ADADAD',
      },
      {
        label: 'Total Profit',
        data: totalProfit,
        backgroundColor: '#4AC440',
      }
    ],
  };



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>

      <Grid container spacing={2} alignItems="center" mt={2}>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth>
            <Select
              name="filter"
              value={selectedValue.filter}
              onChange={handleInputChange}
              displayEmpty
              required
              style={{
                width: "100%",
                height: "40px",
                border: "1px solid rgba(239, 239, 239, 1)",
                borderRadius: "20px",
                padding: "0px 0px 0px 20px",
              }}
            >
              <MenuItem value="">
                All
              </MenuItem>
              <MenuItem value='month'>
                Month
              </MenuItem>
              <MenuItem value='half_year'>
                Half_year
              </MenuItem>
              <MenuItem value='year'>Year</MenuItem>
            </Select>
          </FormControl>
        </Grid>

      </Grid>




      <Grid container spacing={3} >
        <Grid item xs={12} sm={12} md={12}  >
          <Box className={classes.graph}>
            <Box style={{ padding: "20px 0px 20px 30px" }}>
              <Grid container spacing={3} >
                <Grid item xs={12} sm={3} md={3}  >
                  <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "24px",
                    fontWeight: 600,
                    color: "#000000"
                  }}>
                    Total Amount
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}  >
                  <Typography>
                    <span className={`${classes.legendDot} ${classes.occupied}`} /> Total Amount
                    <span className={`${classes.legendDot} ${classes.vacant}`} /> Total Expenses
                    <span className={`${classes.legendDot} ${classes.profit}`} /> Total Profit
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ margin: "0px 0px 20px 0px" }}>
              <Divider />
            </Box>
            <div style={{ height: '300px', padding: "0px 30px 20px 30px" }}>
              <Bar data={data} options={options} />
            </div>

          </Box>
        </Grid>
      </Grid>

      {/* FinancialTable */}


      <Grid container spacing={3} >
        <Grid item xs={12} sm={12} md={12}  >
          <Box className={classes.grap}>
            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
              <Typography style={{
                fontFamily: "Outfit",
                fontSize: "20px",
                lineHeight: "20px",
                fontWeight: 500,
                color: "#000000",
                margin: "0px 0px 20px 0px"
              }}>
                Detailed Financial Reports
              </Typography>
            </Box>



            {/* Table start */}
            <Box style={{ overflowX: 'auto' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                    <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Date</TableCell>
                    <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Total Amount</TableCell>
                    <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Total Expenses</TableCell>
                    <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Total Profit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {financialData.monthly_data?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell style={{
                        fontFamily: "Outfit",
                        fontWeight: 600,
                        color: "#000000"

                      }}>{row.month}</TableCell>
                      <TableCell>{row.total_amount}</TableCell>
                      <TableCell>{row.total_expenses}</TableCell>
                      <TableCell>{row.total_profit}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box >



          </Box>


        </Grid>
      </Grid>

    </>
  )
}

export default Graph;
