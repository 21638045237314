import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  maintenanceForm: {},
  photo: null,
};

const maintenanceFormSlice = createSlice({
  name: "maintenanceForms",
  initialState,
  reducers: {
    updateMaintenanceForm(state, action) {
      state.maintenanceForm = action.payload;
    },
    setPhoto(state, action) {
      state.photo = action.payload;
    },
  },
});
export const { updateMaintenanceForm, setPhoto } = maintenanceFormSlice.actions;
export default maintenanceFormSlice.reducer;
