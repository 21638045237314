import { Box, TextField, Typography, Grid, Button, FormControl, Select, MenuItem, Backdrop } from "@mui/material";
import React, { useState } from "react";
import { newIncomeTextfield } from "./InquiriesStyle";
import axios from "axios";
import toast from "react-hot-toast";
import { endpoints } from "../../../apiEndpoints";
import Inquiries from "./Inquiries";
import { inquiryStatus } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";



function UpdateInquiry({ editInquiryData }) {
  const [inquiresFormData, setInquiresFormdata] = useState({
    name: editInquiryData.name,
    email: editInquiryData.email,
    emirates_id: editInquiryData.emirates_id,
    address: editInquiryData.address,
    phone: editInquiryData.phone,
    emergency_contact: editInquiryData.emergency_contact,
    passport_no: editInquiryData.passport_no,
    passport_expiry: editInquiryData.passport_expiry,
    nationality: editInquiryData.nationality,
    uid: editInquiryData.uid,
    inquiry_status:editInquiryData.inquiry_status
  });
  const [isUpdateScreen, setIsUpdateScreen] = useState(false);
  const [loading,setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInquiresFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      setLoading(true)
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.patch(
        `${endpoints.updateInquiry}?id=${editInquiryData.id}`,
        inquiresFormData,
        requestOptions
      );
      toast.success(response.data.message);
      if (response.status === 200){
      setIsUpdateScreen(true); 
      }
    } catch (error) {
    }finally{
      setLoading(false)
    }
  };

  return (
    <>
      {isUpdateScreen ? (
        <Inquiries />
      ) : (
        <>
        {loading? (
                    <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <Loader/>
            </Backdrop>
            ):(<>
        
          <Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              Update Inquiry
            </Typography>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "18px",
                lineHeight: "18px",
                fontWeight: 400,
                color: "rgba(0, 0, 0, 1)",
                margin: "50px 0px 25px 0px",
              }}
            >
              Details
            </Typography>
          </Box>
          <form onSubmit={handleUpdate}>
            {/* lesse and email fields */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Lessee
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Rayan"
                  name="name"
                  value={inquiresFormData.name}
                  onChange={handleChange}
                  style={{
                    margin: "0px 0px 25px 0px",
                  }}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Michaelrayan@capstoneuae.com"
                  name="email"
                  value={inquiresFormData.email}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>
            {/* emirateId and address fields */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Emirates Id
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="111-2222-3344556-7"
                  name="emirates_id"
                  value={inquiresFormData.emirates_id}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Address
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Block C. Unit 305"
                  name="address"
                  value={inquiresFormData.address}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>
            {/* phoneNo and emergencyContact fields */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Phone No
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="phone"
                  value={inquiresFormData.phone}
                  onChange={handleChange}
                  placeholder="+1 23 456 789 00"
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Emergency Contact
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="+1 23 456 789 00"
                  name="emergency_contact"
                  value={inquiresFormData.emergency_contact}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>
            {/* passportNo and expirtDate fields */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Passport Number
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="457896542"
                  name="passport_no"
                  value={inquiresFormData.passport_no}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Passport Expiry
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  placeholder="02/28"
                  name="passport_expiry"
                  value={inquiresFormData.passport_expiry}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 25px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>
            {/* nationality and uidNo fields */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Nationality
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Arabian"
                  name="nationality"
                  value={inquiresFormData.nationality}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 15px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  UID No
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="1234567890"
                  name="uid"
                  value={inquiresFormData.uid}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 15px 0px" }}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>

            {/* Lead status */}
            <Grid container>
              <Grid item xs={12} md={4}>
              <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Lead Status
                </Typography>
                <FormControl sx={newIncomeTextfield}  fullWidth>
                  <Select
                 
                  name="inquiry_status"
                  value={inquiresFormData.inquiry_status}
                  onChange={handleChange}
                  style={{ margin: "0px 0px 15px 0px" }}
                  
                  displayEmpty
                  >
                    <MenuItem value='' disabled>Select Lead Status</MenuItem>
                    <MenuItem value={inquiryStatus.qualified.value} > {inquiryStatus.qualified.label}</MenuItem>
                    <MenuItem value={inquiryStatus.notQualified.value} >{inquiryStatus.notQualified.label}</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", margin: "20px 0 50px 0" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "130px",
                  height: "40px",
                }}
                variant="contained"
                type="submit"
              >
                Save Changes
              </Button>
            </Box>
          </form>
          </>)}
        </>
      )}
    </>
  );
}

export default UpdateInquiry;
