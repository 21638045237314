import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { colors } from "@mui/material";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    mainBox: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    // textBox: {
    //   backgroundColor: "#F10A0A08",
    //   borderRadius: "0.5rem",
    //   padding: "1rem",
    // },
    searchBox: {
      marginTop: "2rem",
      display: "flex",
      gap: "1rem",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    buttonsBox:{
      display:'flex',
      gap:'10px',
      marginBottom:'1rem'
    }
  };
});

export const mainTypography = {
  fontFamily: "Outfit",
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "16px",
};

export const fieldHeading = {
  fontFamily: "Outfit",
  fontSize: "18px",
  fontWeight: "400",
  lineHeight: "18px",
};

export const fieldText = {
  fontFamily: "Outfit",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "14px",
  color: "#737679",
};

export const CustomTextField = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    height: "2.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
    "& .MuiOutlinedInput-input[type='date']": {
      color: "#73767960", // Change the color of the input text for date type
    },
    "& .MuiSvgIcon-root": {
      color: "#737679", // Change the color of the calendar icon
    },
    "& .MuiOutlinedInput-input::placeholder": {
      color: "#737679", // Change placeholder color here
    },
  },
};

export const tableHeadCell = {
  backgroundColor: "#ADADAD30",
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "19px",
  lineHeight: "23px",
  borderRight: "1px solid #ADADAD50",
};

export const tableBodyCell = {
  fontFamily: "Outfit",
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: "400",
  color: "#737679",
  cursor: "pointer",
};
export const tableBodyCellRed = {
  fontFamily: "Outfit",
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: "500",
  color: "#B81918",
  cursor: "pointer",
};
export const tableBodyCellgreen = {
  fontFamily: "Outfit",
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: "500",
  color: "#00E317",
  cursor: "pointer",
};
export const tableBodyCellyelow = {
  fontFamily: "Outfit",
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: "500",
  color: "#FFD100",
  cursor: "pointer",
};

export const disApproveBtn = {
  fontFamily: "Outfit",
  fontWeight: "350",
  fontSize: "16px",
  lineHeight: "16px",
  color: "#FFFFFF",
  borderRadius: "0.5rem",
  textTransform: "none",
  backgroundColor: "#9A1915",
  padding: "0.5rem 0.5rem 0.5rem",
  '&:hover':{
    backgroundColor :'#9A1915',
    boxShadow: 4
  }

};

export const approveBtn = {
  fontFamily: "Outfit",
  fontWeight: "350",
  fontSize: "16px",
  lineHeight: "16px",
  color: "#9A1915",
  borderRadius: "0.5rem",
  textTransform: "none",
  borderColor: "#9A1915",
  padding: "0.5rem 0.5rem 0.5rem",
  '&:hover':{
    color :'#9A1915',
    borderColor: "#9A1915",
    boxShadow: 4
  }

};

export const aplyfilter = {
  border: "1px solid #B81918",
  color: "#B81918",
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "12px",
  borderRadius: "30px",
  height: "2.5rem",
  padding: "0.5rem 1rem 0.5rem",
  textTransform: "none",
};

export const rentTextfield = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#F10A0A08",
    // height: "10.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      // borderColor: "#EFEFEF", // Default border color
      borderColor: "#F10A0A08",
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },

  "& .MuiOutlinedInput-input[type='date']": {
    color: "#73767960", // Change the color of the input text for date type
  },
  "& .MuiSvgIcon-root": {
    color: "#737679", // Change the color of the calendar icon
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679", // Change placeholder color here
  },
};

export const rentDescriptionfield = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#F10A0A08",
    height: "12rem", // Adjust the height here
    alignItems: "flex-start",
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      // borderColor: "#EFEFEF", // Default border color
      borderColor: "#F10A0A08",
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },

  "& .MuiOutlinedInput-input[type='date']": {
    color: "#73767960", // Change the color of the input text for date type
  },
  "& .MuiSvgIcon-root": {
    color: "#737679", // Change the color of the calendar icon
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679", // Change placeholder color here
  },
};

export const rentCommentfield = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#F10A0A08",
    height: "8rem", // Adjust the height here
    alignItems: "flex-start",
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      // borderColor: "#EFEFEF", // Default border color
      borderColor: "#F10A0A08",
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },

  "& .MuiOutlinedInput-input[type='date']": {
    color: "#73767960", // Change the color of the input text for date type
  },
  "& .MuiSvgIcon-root": {
    color: "#737679", // Change the color of the calendar icon
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679", // Change placeholder color here
  },
};
