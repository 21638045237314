import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useStyles } from '../Properties/Propertie/PropertieStyle';
import SearchIcon from '@mui/icons-material/Search';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { endpoints } from "../../../apiEndpoints";
import axios from "axios";
import { rentenums } from '../../../enums/UserTypes';
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../utils/Loader";

const Rent = () => {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const [open, setopen] = useState(false);
    const [rentdata, setrentdata] = useState([]);
    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState({ name: "" });
    const [loading, setLoading] = useState(false);

    const Getrent = async () => {
        try {
            setLoading(true); // Set loading state to true before request
            const token = localStorage.getItem("token");
            const response = await axios.get(
                `${endpoints.financeRentReimbursement}?landlord=${selectedValue.name}`,
                {
                    headers: { Authorization: `Token ${token}` },
                }
            );
            const data = response.data.data;
            setrentdata(data || []); // Ensure rentdata is always an array
        } catch (error) {
            console.error("Error fetching rent data:", error);
        } finally {
            setLoading(false); // Stop loading after request
        }
    };

    useEffect(() => {
        Getrent();
    }, [selectedValue.name]);

    const getStatusText = (status) => {
        return rentenums[status] || "Unknown Status";
    };

    const handleopen = (request) => {
        navigate("/admin/rent/update", {
            state: {
                id: request.id,
                ...request,
            },
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSelectedValue({ ...selectedValue, [name]: value });
    };

    // Filter rentdata based on search input
    const filteredRentData = rentdata.filter((row) =>
        row.landlord?.first_name?.toLowerCase().includes(selectedValue.name.toLowerCase())
    );

    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Rent Reimbursement
                </Typography>
            </Box>

            <Box className={classes.root} sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6} lg={5}>
                        <div style={{ position: 'relative' }}>
                            <input
                                type="search"
                                style={{ width: '100%', height: '40px', border: '1px solid rgba(239, 239, 239, 1)', borderRadius: '30px', paddingLeft: '40px' }}
                                placeholder="Search by Name"
                                name="name"
                                value={selectedValue.name}
                                onChange={handleInputChange}
                                size="small"
                            />
                            <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
                                <SearchIcon />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>

            <Box style={{ overflowX: 'auto' }} mt={4}>
                {loading ? (
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={"50vh"}
                    >
                        <Loader />
                    </Box>
                ) : filteredRentData.length === 0 ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="50vh"
                    >
                        <Typography>No data available</Typography>
                    </Box>
                ) : (
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Request Name/No</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Landlord Name</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Admin Name</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Amount</TableCell>
                                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRentData.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell onClick={() => handleopen(row)} style={{ padding: "10px 0px 10px 20px" }}>{row.ref_id}</TableCell>
                                    <TableCell style={{ padding: "10px 0px 10px 20px" }}>{row.landlord?.first_name}</TableCell>
                                    <TableCell style={{ padding: "10px 0px 10px 20px" }}>{row.approved_by_admin?.first_name}</TableCell>
                                    <TableCell style={{ padding: "10px 0px 10px 20px" }}>{row.amount}</TableCell>
                                    <TableCell style={{
                                        padding: "10px 0px 10px 20px", color:
                                            row.admin_approval === "1"
                                                ? "rgba(184, 25, 24, 1)"
                                                : row.admin_approval === "3"
                                                    ? "rgba(184, 25, 24, 1)"
                                                    : row.admin_approval === "2"
                                                        ? "rgba(0, 227, 23, 1)"
                                                        : "black",
                                    }}>{getStatusText(row.admin_approval)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Box>

        </>
    );
}

export default Rent;
