import React from "react";
import { Box, Button, Modal } from "@mui/material";
import { useStyles } from "./PopupStyle";




const StatusPopup = ({ onClose }) => {
    const classes = useStyles();
    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="popup-modal-title"
            aria-describedby="popup-modal-description"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                className={classes.Poproot}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    backgroundColor: '#fff',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
            >

                <img className={classes.img}
                    src="/image/Group.png"
                    alt="popup image"
                />
                <h6 className={classes.heading}>
                    Confirmation Of Status Changing
                </h6>
                <Box className={classes.btns} >
                    <Button
                        variant="outlined"
                        style={{
                            border: "1px solid rgba(154, 25, 21, 1)",
                            color: "rgba(154, 25, 21, 1)",
                            textTransform: "none",
                            width: "150px",
                            height: "30px"
                        }}
                    >Discard Changes</Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            textTransform: "none",
                            margin: "0px 0px 0px 10px",
                            width: "150px",
                            height: "30px"
                        }}
                    >Save Changes</Button>
                </Box>
            </Box>

        </Modal>
    );
};

export default StatusPopup;

