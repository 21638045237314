import React, { useEffect } from "react";
import { useState } from "react";
import { Box, Typography, Button, Grid, Tabs, Tab } from "@mui/material";
import { useStyles } from "../Properties/Propertie/PropertieStyle";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "./Table";
import Popup from "./Popup";
import RenewContract from "./RenewContract";
import AddTentant from "./AddTentant";
import AddLandlord from "./AddLandlord";
import DraftContract from "./DraftContract";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const landlordColumns = [
  { id: "CName", label: "Contract Name" },
  { id: "LName", label: "Landlord Name" },
  { id: "property", label: "Property" },
  { id: "StartDate", label: "Start-Date" },
  { id: "EndDate", label: "End-Date" },
  { id: "CreateBy", label: "Created By" },
  { id: "Status", label: "Status" },
  { id: "Action", label: "Action" },
];

const tenantColumns = [
  { id: "CName", label: "Contract Name" },
  { id: "TName", label: "Tenant Name" },
  { id: "Unit", label: "Unit" },
  { id: "StartDate", label: "Start-Date" },
  { id: "EndDate", label: "End-Date" },
  { id: "CreateBy", label: "Created By" },
  { id: "Status", label: "Status" },
  { id: "Action", label: "Action" },
];

const Contracts = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const TabPanel = ({ children, value, index }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ padding: "20px 0px 0px 0px" }}>{children}</Box>
      )}
    </div>
  );

  const a11yProps = (index) => ({
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  });

  const handleopenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const [open, setopen] = useState(false);
  const [Draft, setDraft] = useState(false);
  const [New, setNew] = useState(false);
  const [Renew, setRenew] = useState(false);

  const handlerenew = () => {
    setRenew(true);
  };

  if (Renew) {
    return <RenewContract />;
  }

  const handleOpen = () => {
    navigate('/admin/contracts/draft-contract');
  };

 
  const handleSubmit = () => {
    navigate('/admin/contracts/add-tentant');
  };

 

  const handlelandlord = () => {
    navigate('/admin/contracts/add-landlord');
  };

 

  return (
    <>
      {showPopup && <Popup onClose={handleClosePopup} />}
      <Box>
        {/* Tentants Data */}
        {selectedTab === 0 && (
          <>
            <>
              <Box sx={{ flexGrow: 1, width: "100%" }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={8} sm={6} lg={true}>
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "24px",
                        lineHeight: "24px",
                        fontWeight: 500,
                        color: "rgba(0, 0, 0, 1)",
                      }}
                    >
                      Contracts
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} lg="auto">
                    <Button
                      sx={{
                        border: "1px solid rgba(154, 25, 21, 1)",
                        color: "rgba(154, 25, 21, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                        "&:hover": {
                          border: "1px solid rgba(154, 25, 21, 1)",
                        },
                      }}
                      variant="outlined"
                      onClick={handleOpen}
                    >
                      Drafts
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={3} lg="auto">
                    <Button
                      sx={{
                        border: "1px solid rgba(154, 25, 21, 1)",
                        color: "rgba(154, 25, 21, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                        "&:hover": {
                          border: "1px solid rgba(154, 25, 21, 1)",
                        },
                      }}
                      variant="outlined"
                      onClick={handlerenew}
                    >
                      Renewal
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={3} lg="auto">
                    <Button
                      sx={{
                        backgroundColor: "rgba(184, 25, 24, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100%",
                        height: "30px",
                        "&:hover": {
                          backgroundColor: "rgba(184, 25, 24, 1)",
                        },
                      }}
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Create New
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
            {/* Tentants Components End */}
          </>
        )}
        {/* Landlord Data */}
        {selectedTab === 1 && (
          <>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                Contracts
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "130px",
                    height: "30px",
                  }}
                  variant="contained"
                  onClick={handlelandlord}
                >
                  Create New
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box style={{ margin: "18px 0px 0px 0px" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="navigation tabs"
          TabIndicatorProps={{
            style: { backgroundColor: "rgba(184, 25, 24, 1)", height: "1px" },
          }}
        >
          <Tab
            style={{
              color: selectedTab === 0 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 0 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Tenants"
            {...a11yProps(0)}
          />
          <Tab
            style={{
              color: selectedTab === 1 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 1 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Landlord"
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <DataTable Data={"Tenant"} columns={tenantColumns} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <DataTable Data={"Landlord"} columns={landlordColumns} />
        </TabPanel>
      </Box>
    </>
  );
};

export default Contracts;
