import { configureStore } from "@reduxjs/toolkit";
import formReducer from "./AdminReducer/formsSlice";
import unitSlice from "./AdminReducer/unitSlice";
import maintenanceFormsReducer from "./TenantReducer/maintenanceSlice";
import complaintFormsReducer from "./TenantReducer/complaintSlice";
import complaintFeedbackReducer from "./TenantReducer/complaintFeedbackSlice";
import documentReducer from "./TenantReducer/documentSlice";

export const store = configureStore({
  reducer: {
    form: formReducer,
    unit: unitSlice,
    maintenanceForms: maintenanceFormsReducer,
    complaintForms: complaintFormsReducer,
    complaintFeedback: complaintFeedbackReducer,
    document: documentReducer,
  },
});
