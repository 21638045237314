import React from "react";
import Login from "./Login/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./utils/ProtectedRoute";
import ForgetPassword from "./Login/ForgetPassword";
import Logout from "./logout/logout";

const App = () => {
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/admin/*"
            element={<ProtectedRoute usertypeRequired={['Admin', 'SuperAdmin']} />}
          />
          <Route
            path="/tenant/*"
            element={<ProtectedRoute usertypeRequired="Tenant" />}
          />
          <Route
            path="/landlord/*"
            element={<ProtectedRoute usertypeRequired="Landlord" />}
          />
          <Route
            path="/finance/*"
            element={<ProtectedRoute usertypeRequired="Finance" />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;

