import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    Poproot: {
      width: "800px",
      height: "550px",
      transform: "translate(-70%, -50%)",
      border: "1px solid rgba(154, 25, 21, 1)",
      borderRadius: "30px",
      margin: "0px 0px 40px 70px",
      [theme.breakpoints.down("sm")]: {
        width: "350px",
        transform: "translate(-50%, -50%)",
        margin: "0px 0px 0px 0px",
      },
    },
  };
});
export const newIncomeTextfield = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    // height: "3rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },

  "& .MuiOutlinedInput-input[type='date']": {
    color: "#73767960", // Change the color of the input text for date type
  },
  "& .MuiSvgIcon-root": {
    color: "#737679", // Change the color of the calendar icon
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679", // Change placeholder color here
  },
};

export const selectTextField = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    height: "2.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", // Border color when focused
    },
  },

  "& .MuiOutlinedInput-input[type='date']": {
    color: "#73767960", // Change the color of the input text for date type
  },
  "& .MuiSvgIcon-root": {
    color: "#737679", // Change the color of the calendar icon
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679", // Change placeholder color here
  },
};
