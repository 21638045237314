import { Box, Typography, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./PropertiesStyles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { Loader } from "../../../utils/Loader";
import { PropertyType, PropertyList } from '../../../enums/UserTypes';




const Properties = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const images = {
    property1: "/image/proimage1.jpg",
    property2: "/image/proimage2.jpg",
    property3: "/image/proimage3.jpg",
  };

  const getProperties = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        endpoints.tenantProperties,
        requestOptions
      );
      setProperties(response.data.data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProperties();
  }, []);



  const getStatusText = (status) => {
    return PropertyList[status] || "Unknown Status";
  };

  return (
    <>
      <Box>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "24px",
          }}
        >
          My Properties
        </Typography>
      </Box>
      {loading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"70vh"}
        >
          <Loader />
        </Box>
      ) : properties.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {properties?.map((property) => (
            <Grid item key={property.id} xs={12} sm={12} md={6} lg={4}>
              <Box
                className={classes.itemBox}
                onClick={() =>
                  navigate(`/tenant/properties/view-property?id=${property.id}`, {
                    state: { property },
                  })
                }
              >
                <Box>
                  <img
                    src={property?.attachments[0]?.media || ""}
                    alt="Property Image"
                    onError={(e) => (e.target.src = "/image/propertyicon.png")}
                    className={classes.boxImg}
                  />
                </Box>



                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "12px",
                    margin: "0px 0px 0px 5px",
                    color: "#000000"
                  }}
                >
                  {property.ref_id}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 900,
                    fontSize: "18px",
                    lineHeight: "12px",
                    margin: "12px 0px 9px 5px",
                  }}
                >
                  {property.title}
                </Typography>
                <Box style={{ display: "flex" }}>
                  <Box style={{ color: "rgba(115, 118, 121, 1)" }}>
                    <LocationOnIcon style={{ fontSize: "20px" }} />
                  </Box>
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "12px",
                      margin: "2px 0px 0px 3px",
                      color: "#737679"
                    }}
                  >
                    {`${property.address}, ${getStatusText(property.type)}`}
                  </Typography>
                </Box>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "12px",
                    margin: "0px 0px 0px 25px",
                  }}
                >

                </Typography>





              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default Properties;
