import { styled, Typography, TextField, Grid, Box } from "@mui/material";

export const StyledMainTypography = styled(Typography)({
  fontSize: "34px",
  fontWeight: "500",
  fontFamily: "Outfit",
  color: "#000000",
  marginBottom: "2rem",
});

export const StyledWelcomeBox = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse", // Width for md and above breakpoints
  },
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 2px 15px -2.5px rgba(0, 0, 0, 0.1)",
  padding: "1.5rem",
  borderRadius: "10px",
}));

export const WelcomeTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Outfit",
  fontWeight: "300",
  fontSize: theme.breakpoints.only("xs")
    ? "25px"
    : theme.breakpoints.only("sm")
    ? "30px"
    : "40px",
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: theme.breakpoints.only("xs")
    ? "90%"
    : theme.breakpoints.only("sm")
    ? "100%"
    : theme.breakpoints.only("md")
    ? "65%"
    : "30vw",
  marginTop: "0.5rem",
  "& input": { color: "black" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B81918",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#B81918", // Change to your desired hover color
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B81918", // Change to your desired focused color
    },
  },
}));

export const SubHeadingTypography = styled(Typography)({
  fontSize: "38px",
  fontWeight: "400",
  fontFamily: "Outfit",
  color: "#000000",
  marginTop: "3rem",
  marginBottom: "2.5rem",
});

export const StyledInfoBox = styled(Box)({
  height: "40vh",
  border: "1px solid #EAEAEA",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});
