
const DateFormatter = (dateStr) => {
  const date = new Date(dateStr);
  if (isNaN(date)) return "Date not available"; // Handle invalid dates

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit",
    hour12: false,
    timeZone: "UTC"
  };
  return date.toLocaleString("en-US", options);
};

export default DateFormatter;




