import React from "react";
import { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  StyledGrid,
  StyledItemGrid,
  InfoBox,
  InfoTypography,
} from "../../../../AdminComponent/Pages/Maintenance/MaintenanceStyles";
import { useNavigate } from "react-router-dom";





const MaintenanceData = [
  {
    id: "1",
    Dates: "11-Apr-2024",
    name: "Maintenance Request Name",
  },
  {
    id: "2",
    Dates: "11-March-2024",
    name: "Maintenance Request Name",
  },
  {
    id: "3",
    Dates: "12-Dec-2023",
    name: "Maintenance Request Name",
  },
  {
    id: "4",
    Dates: "12-Dec-2023",
    name: "Maintenance Request Name",
  },
];

const TenantComplain = ({ complaintsDetails }) => {
  const navigate = useNavigate();




  return (
    <>
      <Box>
        <Typography
          style={{
            fontFamily: "Raleway",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
            margin: "0px 0px 50px 0px",
          }}
        >
          Complaints
        </Typography>
      </Box>

      <StyledGrid container xs={12} style={{ margin: "0px 0px 0px 0px" }}>
        {complaintsDetails?.complaints && complaintsDetails.complaints.length > 0 ? (
          complaintsDetails?.complaints.map((item, index) => (
            <StyledItemGrid
              key={index}
              item
              xs={12}
              style={{ padding: "0px 0px 0px 0px" }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: { xs: "15px", sm: "15px", md: "20px" },
                  }}
                >
                  {item?.created_at?.split("T")[0] || "Date not available"}
                </Typography>
              </Box>
              <InfoBox>
                <InfoTypography

                  onClick={() =>
                    navigate(`/landlord/complainprofile?id=${item.id}`, {
                      state: { complaintDetails: item },
                    })
                  }

                >
                  {item.first_name}
                </InfoTypography>
              </InfoBox>
            </StyledItemGrid>
          ))
        ) : (
          <InfoTypography>No Complaints</InfoTypography>
        )}
      </StyledGrid>
    </>
  );
};

export default TenantComplain;
