import { Grid, Box, Typography, IconButton, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./PropertieStyle";


const ViewDocuments = ({ documentData , image , title }) => {
    const classes = useStyles();
    const [secondTable, setSecondTable] = useState([]);



  
    useEffect(() => {
      
        console.log("Full documentData:", documentData);
        console.log("documentData.document_status:", documentData.document_status);

      
        const documentStatus = Object.entries(documentData.document_status).map(([key, value]) => ({
            id: key,
            name: key.replace(/AMC-/, ''), 
            status: value ? "Received" : "Not Received"
        }));

        setSecondTable(documentStatus);
    }, [documentData]);

    

    return (
        <>
            <Grid
                display={"flex"}
                flexDirection={{ xs: "column", sm: "row", md: "row" }}
                alignItems={"center"}
                gap={"2rem"}
                mb={5}
            >
                <Box className={classes.propertyViewBox}>
                    <Box className={classes.imgBox}>
                        <img
                            src={image}
                            alt="Property Image"
                            className={classes.propertyViewImg}
                        />
                    </Box>
                    <Box className={classes.locationBox}>
                        <Typography
                            sx={{
                                fontFamily: "Outfit",
                                fontSize: "20px",
                                fontWeight: "500",
                                color: "#B81918",
                            }}
                        >
                            {title}
                        </Typography>
                    </Box>
                </Box>

                <Box className={classes.appartmentBox}>
                    <Typography
                        sx={{
                            fontFamily: "Outfit",
                            fontSize: "20px",
                            fontWeight: "700",
                            lineHeight: "50px",
                            color: "#B81918",
                            borderBottom: "1px solid #21212140",
                        }}
                    >
                        {documentData.ref_id}
                    </Typography>
                    <Box className={classes.rentBox} >
                        <Typography
                            sx={{
                                fontFamily: "Outfit",
                                fontSize: "15px",
                                fontWeight: "500",
                                lineHeight: "18px",
                                color: "#B81918",
                            }}
                        >
                            {documentData.primary_landlord.first_name}
                        </Typography>
                    </Box>
                    <Box className={classes.rentBox} >
                        <Typography
                            sx={{
                                fontFamily: "Outfit",
                                fontSize: "15px",
                                fontWeight: "500",
                                lineHeight: "18px",
                                color: "#B81918",
                            }}
                        >
                            {documentData?.unit
                                ?.map((item) => item.ref_id)
                                .join(", ") || "units not available"}
                        </Typography>
                    </Box>
                </Box>
            </Grid>

            <Box mt={5}>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "24px",
                    margin: "30px 0px 30px 0px"
                }}>
                    Documents
                </Typography>
            </Box>

            <Box style={{ overflowX: 'auto' }} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}>
                            <TableCell style={{ padding: "10px 0px 10px 20px", borderRight: "1px solid #e0e0e0" }}>Document Name</TableCell>
                            <TableCell style={{ padding: "10px 0px 10px 20px", borderRight: "1px solid #e0e0e0" }}>Document Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {secondTable.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell style={{ padding: "10px 0px 10px 20px" }}>{row.name}</TableCell>
                                <TableCell style={{ padding: "10px 0px 10px 40px", color: row.status === 'Received' ? 'rgba(0, 227, 23, 1)' : 'rgba(184, 25, 24, 1)' }}>{row.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </>
    );
}

export default ViewDocuments;

