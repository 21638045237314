import React from "react";
import { Grid, Box, Typography, Hidden } from "@mui/material";
import { TextField, Button, Link, Container } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useStyles } from "./LoginStyle";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { endpoints } from "../apiEndpoints";




const ConfirmPassword = ({ email }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [NewPassword, setNewPassword] = React.useState(false);
  const handleClickNewPassword = () => setNewPassword((show) => !show);
  const [ConfirmPassword, setConfirmPassword] = React.useState(false);
  const handleClickConfirmPassword = () => setConfirmPassword((show) => !show);




  const [login, setLogin] = useState({
    email: email.email,
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "password" || name === "confirmPassword") {
      setPasswordError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (login.password !== login.confirmPassword) {
      setPasswordError(true);
      return;
    }

    try {
      const response = await axios.post(endpoints.ResetPassword, {
        email: login.email,
        password: login.password,
      });
      // if (response == 200) {
      //   toast.success(response.data.message);

      // }
      toast.success(response.data.message);
      navigate("/");
    } catch (error) {
      toast.error(error.response.data.message);
    }



  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };






  return (
    <>
      <Grid container style={{ margin: "0px" }}>
        <Grid item xs={12} sm={6} md={6}>
          <Box className={classes.root}>
            <Box className={classes.image}>
              <img
                src="/image/logo.png"
                alt="logo pic"
                style={{ width: "100px", height: "80px" }}
              />
            </Box>
            <Box className={classes.typogone}>
              <Typography
                style={{
                  font: "Raleway",
                  fontSize: "15px",
                  lineHeight: "23.48px",
                  fontWeight: 400,
                  color: "rgba(77, 77, 77, 1)",
                  margin: "5px 0px 0px 13px",
                }}
              >
                Welcome to
              </Typography>
            </Box>
            <Box className={classes.typogtwo}>
              <Typography
                style={{
                  font: "Raleway",
                  fontSize: "18px",
                  lineHeight: "48px",
                  fontWeight: 500,
                  color: "rgba(6, 6, 6, 1)",
                }}
              >
                Capstone PMS
              </Typography>
            </Box>
            <Box className={classes.typogthree}>
              <Typography
                style={{
                  font: "Raleway",
                  fontSize: "16px",
                  lineHeight: "30px",
                  fontWeight: 400,
                  color: "rgba(115, 118, 121, 1)",
                }}
              >
                Create New Password
              </Typography>
            </Box>
            <Box className={classes.formcontrol}>
              <Container component="main" maxWidth="xs">
                <form>
                  <FormControl sx={{ width: "50ch" }} variant="outlined">
                    <Typography
                      style={{
                        font: "Raleway",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "18.78px",
                        color: "rgba(115, 118, 121, 1)",
                        margin: "4% 0% 0% 0%",
                      }}
                    >
                      New Password
                    </Typography>
                    <OutlinedInput
                      className={classes.passwordinput}
                      size="small"
                      id="outlined-adornment-password"
                      type={NewPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickNewPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {NewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Enter new password"
                      name="password"
                      value={login.password}
                      onChange={handleChange}

                    />
                  </FormControl>
                  <FormControl sx={{ width: "50ch" }} variant="outlined">
                    <Typography
                      style={{
                        font: "Raleway",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "18.78px",
                        color: "rgba(115, 118, 121, 1)",
                        margin: "4% 0% 0% 0%",
                      }}
                    >
                      Confirm Password
                    </Typography>
                    <OutlinedInput
                      className={classes.passwordinput}
                      size="small"
                      id="outlined-adornment-password"
                      type={ConfirmPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {ConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Confirm new password"
                      name="confirmPassword"
                      value={login.confirmPassword}
                      onChange={handleChange}

                    />
                  </FormControl>
                  {passwordError && (
                    <Typography variant="body2" style={{ color: "red" }}>
                      Passwords do not match.
                    </Typography>
                  )}

                  <Button
                    className={classes.bph}
                    type="submit"
                    variant="contained"
                    style={{
                      margin: "3% 0% 0% 0%",
                      fontSize: "15px",
                      fontWeight: 500,
                      lineHeight: "23.48px",
                      font: "Raleway",
                      borderRadius: "6px",
                      height: "43px",
                      backgroundColor: "rgba(184, 25, 24, 1)",
                      textTransform: "none",
                    }}
                    onClick={handleSubmit}

                  >
                    Confirm
                  </Button>
                </form>
              </Container>
            </Box>
          </Box>
        </Grid>

        <Hidden smDown>
          <Grid
            item
            lg={6}
            xl={6}
            md={6}
            style={{
              padding: "0px",
              margin: "0px",
              backgroundImage: 'url("/image/login.jpg")',
              backgroundSize: "cover",
              backgroundPosition: "center",
              // height: '100%',  // Adjust this to '100vh' or '100%' depending on your layout needs
              minHeight: "100vh",
            }}
          ></Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default ConfirmPassword;
