import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AddRent from "./AddRent";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { rentStatus } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";

const FirstTable = [
  {
    id: "1",
    name: "Name/no",
    Admin: "Admin 1",
    Status: "Approved",
  },
  {
    id: "2",
    name: "Name/no",
    Admin: "Admin 1",
    Status: "Pending",
  },
  {
    id: "3",
    name: "Name/no",
    Admin: "Admin 1",
    Status: "Disapproved",
  },
  {
    id: "4",
    name: "Name/no",
    Admin: "Admin 1",
    Status: "Processed",
  },
  {
    id: "5",
    name: "Name/no",
    Admin: "Admin 1",
    Status: "Processed",
  },
  {
    id: "6",
    name: "Name/no",
    Admin: "Admin 1",
    Status: "Processed",
  },
  {
    id: "7",
    name: "Name/no",
    Admin: "Admin 1",
    Status: "Processed",
  },
];

function getStatusColor(status) {
  switch (status) {
    case "2":
      return "rgba(0, 227, 23, 1)";
    case "1":
      return "rgba(255, 209, 0, 1)";
    case "3s":
      return "rgba(184, 25, 24, 1)";
    // case "Processed":
    //   return "rgba(0, 227, 23, 1)";
    default:
      return "inherit";
  }
}

const RentReimbursement = () => {
  const [Add, setAdd] = useState(false);
  const [rentData, setRentData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getRentReimbursement = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        endpoints.financeRentReimbursement,
        requestOptions
      );
      setRentData(response.data.data);
    } catch {

    }finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    getRentReimbursement();
  }, []);

  const handleadd = () => {
    setAdd(true);
  };
  if (Add) {
    return <AddRent />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          Rent Reimbursement
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{
              backgroundColor: "rgba(184, 25, 24, 1)",
              fontSize: "14px",
              lineHeight: "16px",
              fontWeight: 500,
              textTransform: "none",
              width: "130px",
              height: "30px",
            }}
            variant="contained"
            onClick={handleadd}
          >
            Create New
          </Button>
        </Box>
      </Box>

      {/* Start Table */}

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Loader />
        </Box>
      ) : rentData?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <Box style={{ overflowX: "auto" }} mt={4}>
          <Table sx={{ border: "1px solid #EFEFEF" }}>
            <TableHead>
              <TableRow
                style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
              >
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Request Name/No
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Amount
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rentData?.map((rent) => (
                <TableRow key={rent.id}>
                  <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                    {rent.ref_id || "-"}
                  </TableCell>
                  <TableCell style={{ padding: "10px 0px 10px 20px" }}>
                    {rent.amount || "-"}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "10px 0px 10px 20px",
                      color: getStatusColor(rent.admin_approval),
                    }}
                  >
                    {rent.admin_approval === rentStatus.pending.value
                      ? rentStatus.pending.label
                      : rent.admin_approval === rentStatus.approved.value
                      ? rentStatus.approved.label
                      : rent.admin_approval === rentStatus.disapproved.value
                      ? rentStatus.disapproved.label
                      : "-" || "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </>
  );
};

export default RentReimbursement;
