import React from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";



const UnitMasterSheet = () => {
    return (
        <>
            <Typography style={{
                font: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)"
            }}>
                Master Sheets
            </Typography>
            <Grid container mt={3} >
                <Grid item xs={12} md={6} alignItems="center">
                    <Box>
                        <Box sx={{ display: "flex",  }}>
                            <img src="/image/landlordP1.png" alt="this image is not show" width={450} height={250} />
                        </Box>

                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontWeight: 500,
                        fontSize: "28px",
                        lineHeight: "35px",
                        margin: "40px 0px 0px 0px"
                    }}>
                        3 Bedrooms Brand New Houses Located In London Premium Areas
                    </Typography>
                    <Typography style={{
                        fontFamily: "Raleway",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                        margin: "10px 0px 0px 0px"
                    }}>
                        Lorem ipsum dolor sit amet consectetur. Lectus fusce nisl blandit viverra fringilla laoreet. Tempor nunc dictum nisl massa pulvinar aliquam venenatis. Dolor non est mattis accumsan donec tempor. Maecenas eu eget est et vel a at tincidunt.
                    </Typography>
                </Grid>
            </Grid>

            <Box style={{ margin: "25px 0px 40px 0px" }}>
                <Divider />
            </Box>


            <Box>
                <Typography style={{
                    fontFamily: "Raleway",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "28px"
                }}>
                    Master Sheets <span style={{
                        fontFamily: "Raleway",
                        fontSize: "24px",
                        fontWeight: 400,
                        lineHeight: "28px"
                    }}> (Details)</span>
                </Typography>
                <Typography style={{
                    fontFamily: "Raleway",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "23px",
                    margin: "15px 0px 0px 0px"
                }}>
                    Master Sheets is the entire summary of:
                </Typography>


                <ul>
                    <li>Buildings</li>
                    <li>Apartments</li>
                    <li>Units</li>
                    <li>Tenants (Organized under their respective occupied units)</li>
                    <li>Tenancy Documents</li>
                    <li>Income-To-Date</li>
                </ul>
            </Box>


            <Box>
                <Button style={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "200px",
                    height: "40px",
                    margin: "10px 0px 50px 0px"

                }}
                    variant="contained"

                >Download Master Sheets</Button>
            </Box>

        </>
    )
}

export default UnitMasterSheet;