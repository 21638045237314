import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";



export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    graph: {
      backgroundColor: "rgb(255,255,255)",
      border: "1px solid rgb(235,235,235)",
      borderRadius: "5.8px",
      // boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.1)",
      margin: "25px 0px 0px 0px",
      padding: "0px 22px 22px 22px",
      minHeight:'29rem'
    },
    Tabstyle: {
      backgroundColor: "rgb(255,255,255)",
      border: "1px solid rgb(235,235,235)",
      borderRadius: "5.8px",
      // boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.1)",
      margin: "25px 0px 0px 0px",
      padding: "0px 22px 22px 22px",
      height: "650px",
      [theme.breakpoints.down("sm")]: {
        height: "650px",
        margin: "0px 0px 0px 0px",
      },
    },
    TabstyleS: {
      backgroundColor: "rgb(255,255,255)",
      border: "1px solid rgb(235,235,235)",
      borderRadius: "5.8px",
      // boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.1)",
      margin: "25px 0px 0px 0px",
      padding: "0px 22px 22px 22px",
      height: "650px",
      [theme.breakpoints.down("sm")]: {
        height: "790px",
       
      },
    },
    our: {
      padding: "10px 0px 10px 20px",
    },
    chart: {
      '&:focus, & svg:focus, & svg *:focus': {
        outline: 'none !important',
        border: 'none !important',
      },
      margin: "65px 0px 72px 0px",
      [theme.breakpoints.down("md")]: {
        margin: "65px 0px 20px 30px",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "45px 0px 0px 10px",
      },
     
    },
    paper: {
      margin: "150px 0px 0px 0px",
      [theme.breakpoints.down("md")]: {
        margin: "10px 0px 20px 100px",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0px 0px 0px 75px",
      },
    },
  };
});

export const newIncomeTextfield = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#EFEFEF",
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF",
    },
  },

  "& .MuiOutlinedInput-input[type='date']": {
    color: "#73767960",
  },
  "& .MuiSvgIcon-root": {
    color: "#737679",
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679",
  },
};

export const tableHeadCell = {
  backgroundColor: "#ADADAD30",
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "20px",
  borderRight: "1px solid #ADADAD50",
};

export const tableBodyCell = {
  fontFamily: "Outfit",
  fontSize: "15px",
  lineHeight: "17px",
  fontWeight: "400",
  color: "#737679",
};
export const tableBodyCellRed = {
  fontFamily: "Outfit",
  fontSize: "15px",
  lineHeight: "17px",
  fontWeight: "400",
  color: "#B81918",
};
