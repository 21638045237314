import React from "react";
import { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useStyles } from '../Propertie/PropertieStyle';
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
import { endpoints } from "../../../../apiEndpoints";
import axios from 'axios';
import toast from "react-hot-toast";
import PropertyMaintenance from './PropertyMaintenance';
import { Loader } from "../../../../utils/Loader";




const PropertyUnitHistory = ({ id }) => {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const [unithistorydata, setunithistorydata] = useState(null);
    const [close, setClose] = useState({ open: false, unitId: null });
    const [loading, setLoading] = useState(true);

    const getUnithistory = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.getpropertyhistory}?id=${id}`, {
                headers: { Authorization: `Token ${token}` }
            });
            setunithistorydata(response.data.data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        getUnithistory();
    }, []);

    const UnitHistory = unithistorydata?.unit_association_history || [];

    const handleOpen = (unitId) => {
        setClose({ open: true, unitId });
    };

    if (close.open) {
        return <PropertyMaintenance unitId={close.unitId} />;
    }

    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Unit History
                </Typography>
            </Box>


            {loading ? (
                <Box
                    display={"flex"}
                    height={"100vh"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Loader />
                </Box>
            ) : UnitHistory.length === 0 ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50vh"
                >
                    <Typography>No data available</Typography>
                </Box>
            ) : (
                <Grid item xs={12} md={12}>
                    <Box>
                        {UnitHistory.map((historyItem, historyIndex) => (
                            historyItem.unit.map((unit, unitIndex) => (
                                <Box key={`${historyIndex}-${unitIndex}`} style={{
                                    display: "flex",
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: "1px solid #EAECF0",
                                    margin: "15px 0px 0px 0px",
                                    borderRadius: "5.8px"
                                }}>
                                    <Box style={{
                                        display: "flex",
                                        alignItems: 'center',
                                    }} className={classes.our}>
                                        <Avatar />
                                        <Typography style={{
                                            color: "#060606",
                                            fontFamily: "Outfit",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            fontWeight: 700,
                                            margin: "0px 0px 0px 10px",
                                            borderRadius: '10px'
                                        }}>
                                            {`${historyItem.tenant.first_name} ||`}
                                        </Typography>
                                        <Typography style={{
                                            color: "#060606",
                                            fontFamily: "Outfit",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            fontWeight: 700,
                                            margin: "0px 0px 0px 10px",
                                            borderRadius: '10px'
                                        }}>
                                            {`${historyItem.landlord.first_name} ||`}
                                        </Typography>
                                        <Typography style={{
                                            color: "#060606",
                                            fontFamily: "Outfit",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            fontWeight: 700,
                                            margin: "0px 0px 0px 10px",
                                            borderRadius: '10px'
                                        }}>
                                            {unit.ref_id}
                                        </Typography>
                                    </Box>
                                    <Button
                                        style={{
                                            textTransform: "none",
                                            fontFamily: "Outfit",
                                            fontSize: "10px",
                                            lineHeight: "10px",
                                            fontWeight: 500,
                                            margin: "0px 25px 0px 0px",
                                            padding: "5px 15px 5px 15px",
                                            borderRadius: "5px",
                                            backgroundColor: "rgba(184, 25, 24, 1)",
                                            color: "rgba(255, 255, 255, 1)",
                                            height: "30px"
                                        }}
                                        onClick={() => handleOpen(unit.id)}
                                    >
                                        Maintenance Requests
                                    </Button>
                                </Box>
                            ))
                        ))}
                    </Box>
                </Grid>
            )}
        </>
    )
}

export default PropertyUnitHistory;
