import React from "react";
import { useState } from "react";
import { Box, Typography, Button, Grid, Tabs, Tab } from "@mui/material";
import { useStyles } from "../Properties/Propertie/PropertieStyle";
import DataTable from "./Table";
import { useNavigate } from "react-router-dom";






const landlordColumns = [
    { id: 'CName', label: 'Primary landlord' },
    { id: 'LName', label: 'Property' },
    { id: 'property', label: 'Unit' },
    { id: 'CreateAt', label: 'Secondary landlord' },
    { id: 'CreateBy', label: 'Created at' },
    { id: 'Status', label: 'Action' },
];

const tenantColumns = [
    { id: 'CName', label: 'Property' },
    { id: 'TName', label: 'Unit' },
    { id: 'property', label: 'Tenant' },
    { id: 'CreateAt', label: 'Created at' },
    { id: 'Status', label: 'Association Status' },
    { id: 'Action', label: 'Action' },
];



const PropertyAssociation = () => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const TabPanel = ({ children, value, index }) => (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
            {value === index && <Box sx={{ padding: "20px 0px 0px 0px" }}>{children}</Box>}
        </div>
    );

    const a11yProps = index => ({
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    });

    const handletenant = () => {
        navigate('/admin/association/tenant-association')
    }
    const handlelandlord = () => {
        navigate('/admin/association/landlord-associationform')
    }

    return (
        <>
            <Box>
                {/* Tentants Data */}
                {selectedTab === 0 && <>
                    <>
                        <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                            <Typography style={{
                                fontFamily: "Outfit",
                                fontSize: "24px",
                                lineHeight: "24px",
                                fontWeight: 500,
                                color: "rgba(0, 0, 0, 1)"
                            }}>
                                Property/Unit Association
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button style={{
                                    backgroundColor: "rgba(184, 25, 24, 1)",
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    fontWeight: 500,
                                    textTransform: "none",
                                    width: "130px",
                                    height: "30px"
                                }}
                                    variant="contained"
                                    onClick={handletenant}
                                >Create New</Button>
                            </Box>
                        </Box>

                    </>
                </>}
                {/* Landlord Data */}
                {selectedTab === 1 &&
                    <>
                        <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                            <Typography style={{
                                fontFamily: "Outfit",
                                fontSize: "24px",
                                lineHeight: "24px",
                                fontWeight: 500,
                                color: "rgba(0, 0, 0, 1)"
                            }}>
                                Property/Unit Association
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button style={{
                                    backgroundColor: "rgba(184, 25, 24, 1)",
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    fontWeight: 500,
                                    textTransform: "none",
                                    width: "130px",
                                    height: "30px"
                                }}
                                    variant="contained"
                                    onClick={handlelandlord}

                                >Create New</Button>
                            </Box>
                        </Box>

                    </>

                }
            </Box>
            <Box style={{ margin: "18px 0px 0px 0px" }}>
                <Tabs value={selectedTab} onChange={handleChange} aria-label="navigation tabs" TabIndicatorProps={{ style: { backgroundColor: 'rgba(184, 25, 24, 1)', height: '1px' } }}>
                    <Tab style={{ color: selectedTab === 0 ? 'rgba(184, 25, 24, 1)' : 'inherit', borderBottom: selectedTab === 0 ? '2px solid rgba(184, 25, 24, 1)' : 'none' }} label="Tenants" {...a11yProps(0)} />
                    <Tab style={{ color: selectedTab === 1 ? 'rgba(184, 25, 24, 1)' : 'inherit', borderBottom: selectedTab === 1 ? '2px solid rgba(184, 25, 24, 1)' : 'none' }} label="Landlord" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                    <DataTable Data={"Tenant"} columns={tenantColumns} />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <DataTable Data={"Landlord"} columns={landlordColumns} />

                </TabPanel>

            </Box>
        </>
    )
}

export default PropertyAssociation;



