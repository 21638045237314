import React, { useEffect, useState } from "react";
import { useStyles } from "./MaintenanceStyles";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { maintenanceStatus } from "../../../enums/UserTypes";
import { Loader } from "../../../utils/Loader";

function MaintenanceRequest() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [maintenanceRequests, setMaintenanceRequests] = useState(null);
  const navigate = useNavigate();

  // Function to determine the color of the button based on the btn property
  const getButtonColor = (btn) => {
    switch (btn) {
      case "1":
        return "#B81918"; // Orange color for pending status
      case "2":
        return "#558FFF"; // Yellow color for in progress status
      default:
        return "#00E317"; // Green color for other statuses
    }
  };

  const getMaintenanceRequests = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        endpoints.maintenanceRequests,
        requestOptions
      );
      setMaintenanceRequests(response.data.data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMaintenanceRequests();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : maintenanceRequests && maintenanceRequests?.length > 0 ? (
        <Grid container xs={12} spacing={1} className={classes.mainGrid}>
          {maintenanceRequests?.map((request) => (
            <Grid item key={request.id} xs={12} className={classes.gridItem}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontWeight: "500",
                    fontSize: { xs: "13px", sm: "15px" },
                  }}
                >
                  {request?.created_at.split("T")[0] || "Date not available"}
                </Typography>
              </Box>
              <Box className={classes.infoBox}>
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontWeight: "500",
                    fontSize: { xs: "13px", sm: "15px" },
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }}
                >
                  {request?.name || "Not available"}
                </Typography>
                {request.maintenance_status == maintenanceStatus.resolved ? (
                  <Box
                    className={classes.btnBox}
                    justifyContent={{ xs: "center", sm: "flex-end" }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        // color: getButtonColor(request.btn),
                        color: "#00E317",
                        borderColor: "#00E317",

                        // borderColor: getButtonColor(request.btn),
                        width: { xs: "42%", sm: "45%" },
                        fontSize: "10px",
                        fontWeight: "400",
                        height: { xs: "2.5rem", sm: "" },
                        "&:hover": {
                          // backgroundColor: "#B81918", // Same color as default to "unset" hover
                          color: "#00E317",
                          borderColor: "#00E317",
                          boxShadow: "none", // Remove any shadow on hover
                        },
                      }}
                    >
                      Resolved
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => navigate("/tenant/feedback")}
                      sx={{
                        // backgroundColor: getButtonColor(request.btn),
                        backgroundColor: "#00E317",
                        borderColor: "#00E317",
                        color: "#ffff",
                        // borderColor: getButtonColor(request.btn),
                        width: { xs: "42%", sm: "45%" },
                        fontSize: "10px",
                        fontFamily:"Outfit",
                        fontWeight: "400",
                        height: { xs: "2.5rem", sm: "" },
                        "&:hover": {
                          backgroundColor: "#00E317", // Same color as default to "unset" hover
                          borderColor: "#00E317",
                          boxShadow: "none", // Remove any shadow on hover
                        },
                      }}
                    >
                      Add Feedback
                    </Button>
                  </Box>
                ) : request.maintenance_status == maintenanceStatus.progress ? (
                  <Button
                    variant="outlined"
                    sx={{
                      // color: getButtonColor(request.btn),
                      color: "#558FFF",
                      borderColor: "#558FFF",
                      // borderColor: getButtonColor(request.btn),
                      width: { xs: "42%", sm: "18%" },
                      fontSize: "10px",
                      fontWeight: "400",
                      "&:hover": {
                        color: "#558FFF",
                        borderColor: "#558FFF",
                        boxShadow: "none", // Remove any shadow on hover
                      },
                    }}
                  >
                    In Progress
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{
                      // color: getButtonColor(request.btn),
                      // borderColor: getButtonColor(request.btn),
                      color: "#B81918",
                      borderColor: "#B81918",
                      width: { xs: "42%", sm: "18%" },
                      fontSize: "10px",
                      fontWeight: "400",
                      "&:hover": {
                        color: "#B81918",
                        borderColor: "#B81918",
                        boxShadow: "none", // Remove any shadow on hover
                      },
                    }}
                  >
                    Pending
                  </Button>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Typography>No data available</Typography>
        </Box>
      )}
    </>
  );
}

export default MaintenanceRequest;