import React from "react";
import { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useStyles } from '../Propertie/PropertieStyle';
import Avatar from '@mui/material/Avatar';
import { endpoints } from "../../../../apiEndpoints";
import axios from 'axios';
import toast from "react-hot-toast";
import ViewDocuments from './ViewDocuments';
import { Loader } from "../../../../utils/Loader";




const SubPropertyDocument = ({ id, image, title }) => {
    const classes = useStyles();
    const [SubPropertyData, setgetSubPropertyData] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [loading, setLoading] = useState(true);



    const getSubProperty = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.getpropertyhistory}?id=${id}`, {
                headers: { Authorization: `Token ${token}` }
            });
            setgetSubPropertyData(response.data.data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        getSubProperty();
    }, []);

    const SubProperty = SubPropertyData?.sub_property_history || [];

    const handleOpen = (documentData) => {
        setSelectedDocument(documentData);
    };

    if (selectedDocument) {
        return <ViewDocuments documentData={selectedDocument} image={image} title={title} />;
    }

    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Sub-Property Documents
                </Typography>
            </Box>


            {loading ? (
                <Box
                    display={"flex"}
                    height={"100vh"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Loader />
                </Box>
            ) : SubProperty.length === 0 ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50vh"
                >
                    <Typography>No data available</Typography>
                </Box>
            ) : (
                <Grid item xs={12} md={12}>
                    <Box>
                        {SubProperty.map((historyItem, historyIndex) => (
                            <Box key={`${historyIndex}`} style={{
                                display: "flex",
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: "1px solid #EAECF0",
                                margin: "15px 0px 0px 0px",
                                borderRadius: "5.8px"
                            }}>
                                <Box style={{
                                    display: "flex",
                                    alignItems: 'center',
                                }} className={classes.our}>
                                    <Avatar />
                                    <Typography style={{
                                        color: "#060606",
                                        fontFamily: "Outfit",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        fontWeight: 700,
                                        margin: "0px 0px 0px 10px",
                                        borderRadius: '10px'
                                    }}>
                                        {`${historyItem.ref_id} ||`}
                                    </Typography>
                                    <Typography style={{
                                        color: "#060606",
                                        fontFamily: "Outfit",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        fontWeight: 700,
                                        margin: "0px 0px 0px 10px",
                                        borderRadius: '10px'
                                    }}>
                                        {`${historyItem.primary_landlord.first_name} ||`}
                                    </Typography>
                                    <Typography style={{
                                        color: "#060606",
                                        fontFamily: "Outfit",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        fontWeight: 700,
                                        margin: "0px 0px 0px 10px",
                                        borderRadius: '10px'
                                    }}>
                                        {historyItem.unit.length}
                                    </Typography>
                                </Box>
                                <Button
                                    style={{
                                        textTransform: "none",
                                        fontFamily: "Outfit",
                                        fontSize: "10px",
                                        lineHeight: "10px",
                                        fontWeight: 500,
                                        margin: "0px 25px 0px 0px",
                                        padding: "5px 15px 5px 15px",
                                        borderRadius: "5px",
                                        backgroundColor: "rgba(184, 25, 24, 1)",
                                        color: "rgba(255, 255, 255, 1)",
                                        height: "30px"
                                    }}
                                    onClick={() => handleOpen(historyItem)}
                                >
                                    View Documents
                                </Button>
                            </Box>
                        ))}
                    </Box>
                </Grid>
            )}
        </>
    )
}

export default SubPropertyDocument;
