import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const newIncomeTextfield = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    // color: "#EFEFEF",
    // height: "10.5rem", // Adjust the height here
    // "& .MuiInputBase-input": {
    //   padding: "10px 14px", // Adjust padding to center the text vertically
    // },
    "& fieldset": {
      borderColor: "#EFEFEF", 
    },
    "&:hover fieldset": {
      borderColor: "#EFEFEF", 
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EFEFEF", 
    },
  },

  "& .MuiOutlinedInput-input[type='date']": {
    color: "#73767960", 
  },
  "& .MuiSvgIcon-root": {
    color: "#737679", // Change the color of the calendar icon
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#737679", // Change placeholder color here
  },
};
