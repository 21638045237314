import React, { useState } from "react";
import { Box, Button, Modal, Typography, Divider } from "@mui/material";
import { useStyles } from "./SOARequestStyle"
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { soaStatus } from "../../../enums/UserTypes";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";









const RentPopup = ({ onClose,soa }) => {
    const [pdfLetter, setPdfLetter] = useState(soa?.document);
    const [pdfError, setPdfError] = useState(null);
    const [loading,setLoading] = useState(false);
    const classes = useStyles();
    const handlePdfLoadError = (error) => {
        setPdfError(error);
      };

    const updateSOA = async (landlordApproval) =>{
        try{
            setLoading(true)
            const payload ={ landlord_approval : landlordApproval}
            const requestOptions ={
                headers:{
                    Authorization : `token ${localStorage.getItem('token')}`
                }
            }
            const response = await axios.patch(`${endpoints.financeSOA}?id=${soa.id}`,payload,requestOptions)
            toast.success(response.data.message)

        }catch(error){
            toast.error(error.response.data.message)

        }finally{
            setLoading(false)
            onClose()

        }
    }  
    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="popup-modal-title"
            aria-describedby="popup-modal-description"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                className={classes.Poproot}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#fff',

                    borderRadius: '8px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    maxHeight: '80vh', // Set maximum height for scrolling
                    overflow: 'auto', // Make content scrollable
                }}

            >

                <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', padding: "20px" }}>
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontSize: "24px",
                        lineHeight: "24px",
                        fontWeight: 500,
                        color: "rgba(0, 0, 0, 1)"
                    }}>
                        SOA Request
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {soa.landlord_approval === soaStatus.pending.value ? (
                            <>
                        <Button style={{
                            fontFamily: "Outfit",
                            fontSize: "17px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            color: "#B81918",
                            border: "1px solid rgba(154, 25, 21, 1)",
                        }}
                            variant="outlined"
                            disabled={loading}
                            onClick={()=>{updateSOA(soaStatus.clarification.value)}}

                        >Clarification</Button>
                        <Button style={{
                            backgroundColor: "#0EB000",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "100px",
                            height: "40px",
                            margin: "0px 0px 0px 10px"
                        }}
                            variant="contained"
                            disabled={loading}
                            onClick={()=>{updateSOA(soaStatus.approved.value)}}
                            

                        >Approve</Button>
                        </>
                    ): soa.landlord_approval === soaStatus.approved.value ? (<Button style={{
                        backgroundColor: "#0EB000",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "100px",
                        height: "40px",
                        margin: "0px 0px 0px 10px"
                    }}
                        variant="contained"
                        onClick={onClose}
                        

                    >Approved</Button>) : soa.landlord_approval === soaStatus.clarification.value ? (                        <Button style={{
                        fontFamily: "Outfit",
                        fontSize: "17px",
                        lineHeight: "20px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "250px",
                        height: "40px",
                        color: "#B81918",
                        border: "1px solid rgba(154, 25, 21, 1)",
                    }}
                        variant="outlined"
                        onClick={onClose}

                    >Clarification Requested</Button>) : ('-')}
                    </Box>
                </Box>




                <Box >
                    <Divider />
                </Box>
                {loading ? (<Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'50vh'}>
                    <Loader/>
                </Box>):(<>
                <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', padding: '20px', }}>
                    <Typography style={{
                        fontFamily: "Outfit",
                        fontSize: "18px",
                        lineHeight: "18px",
                        fontWeight: 600,
                        color: "rgba(0, 0, 0, 1)"
                    }}>
                        Statement of Account {soa.landlord?.first_name}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{
                            fontFamily: "Outfit",
                            fontSize: "18px",
                            lineHeight: "18px",
                            fontWeight: 600,
                            color: "rgba(0, 0, 0, 1)"
                        }}>
                            {soa.start_date } to {soa.end_date}
                        </Typography>
                    </Box>
                </Box>

              
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                {pdfError ? (
                  <div>Error loading PDF: {pdfError.message}</div>
                ) : (
                  <Viewer fileUrl={pdfLetter || 'N/A'} onError={handlePdfLoadError} />
                )}
              </Worker>
              </>)}
            </Box>
        </Modal>
    );
};

export default RentPopup;

