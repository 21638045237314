import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useStyles } from "./FinancialStyle";
import FinancialTable from "./FinancialTable";
import Graph from "./Graph";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { useState, useEffect } from "react";






const Financial = () => {
    const classes = useStyles();
    const [financialData, setFinancialData] = useState(null);




    const getFinancialData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }

            const response = await axios.get(`${endpoints.landlordFinancialReport}`, {
                headers: { Authorization: `Token ${token}` }
            });

            if (response.data) {
                setFinancialData(response.data); 
            } else {
                console.log("Unexpected data structure:", response.data);
            }
        } catch (error) {
            console.log("Error fetching user data", error.response || error);
        }
    };

    useEffect(() => {
        getFinancialData();
    }, []);


   


    return (
        <>
            <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', margin: "0px 0px 20px 0px" }}>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "#000000"
                }}>
                    Financial Reports
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                </Box>
            </Box>



            <Grid container spacing={2} >
                <Grid item xs={12} sm={4} md={4}  >
                    <Box className={classes.box}>
                        <Box className={classes.boxone}>
                            <img src="/image/line.png" height={70} style={{ margin: "20px 10px 0px 30px" }}></img>
                            <Typography style={{
                                fontFamily: "Outfit",
                                fontSize: "20px",
                                lineHeight: "23px",
                                fontWeight: 500,
                                margin: "30px 0px 0px 10px"
                            }}>
                                Rent Received
                                <Typography style={{
                                    fontFamily: "Outfit",
                                    fontSize: "30px",
                                    lineHeight: "28px",
                                    fontWeight: 700,
                                    color: "rgba(184, 25, 24, 1)",
                                    margin: "2px 0px 35px 0px"
                                }}>
                                    {financialData?.total_rent_received}
                                </Typography>
                            </Typography>

                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxone}>
                            <img src="/image/line.png" height={70} style={{ margin: "20px 10px 0px 30px" }}></img>
                            <Typography style={{
                                fontFamily: "Outfit",
                                fontSize: "20px",
                                lineHeight: "23px",
                                fontWeight: 500,
                                margin: "30px 0px 0px 10px"
                            }}>
                                Total Expenses
                                <Typography style={{
                                    fontFamily: "Outfit",
                                    fontSize: "30px",
                                    lineHeight: "28px",
                                    fontWeight: 700,
                                    color: "rgba(184, 25, 24, 1)",
                                    margin: "0px 0px 35px 10px"
                                }}>
                                    {financialData?.total_expenses}
                                </Typography>
                            </Typography>

                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxone}>
                            <img src="/image/line.png" height={70} style={{ margin: "20px 10px 0px 30px" }}></img>
                            <Typography style={{
                                fontFamily: "Outfit",
                                fontSize: "20px",
                                lineHeight: "23px",
                                fontWeight: 500,
                                margin: "30px 0px 0px 10px"
                            }}>
                                Total Profit
                                <Typography style={{
                                    fontFamily: "Outfit",
                                    fontSize: "30px",
                                    lineHeight: "28px",
                                    fontWeight: 700,
                                    color: "rgba(184, 25, 24, 1)",
                                    margin: "2px 0px 35px 0px"
                                }}>
                                    {financialData?.total_profit}
                                </Typography>
                            </Typography>

                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Graph />

        </>
    )
}

export default Financial;