import { Box, Typography, Grid, Avatar, Tooltip } from "@mui/material";
import React from "react";
import { useStyles } from "./PropertiesStyles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

function ViewRentalAgremnt() {
  const classes = useStyles();
  const location = useLocation();
  const { details, property } = location.state || {};

  const downloadFile = (fileUrl, type) => {
    if (!fileUrl) {
      alert("Document not available.");
      return;
    }

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${type}.${getFileExtension(fileUrl)}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("Could not fetch the file."));
  };

  function getFileExtension(url) {
    // Use a regular expression to match the file extension
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(url)[1];
    return extension;
  }

  return (
    <>
      <Box marginBottom={"2rem"}>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "24px",
            color: "#000000",
          }}
        >
          View Rental Agreement
        </Typography>
      </Box>
      <Grid
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row", md: "row" }}
        gap={"2rem"}
        marginBottom={"3rem"}
      >
        <Box className={classes.propertyViewBox}>
          <Box className={classes.imgBox}>
            <img
              // src="/image/proimage1.jpg"
              src={property?.attachments[0]?.media || ""}
              alt="property image"
              onError={(e) => (e.target.src = "/image/propertyicon.png")}
              className={classes.propertyViewImg}
            />
          </Box>
          <Box className={classes.locationBox}>
            <LocationOnIcon sx={{ color: "#B81918" }} />
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#B81918",
              }}
            >
              {property?.address || "Not available"}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.appartmentDetail}>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "24px",
              color: "#B81918",
            }}
          >
            {property?.address || "Not available"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "40px",
              color: "#000000",
            }}
          >
            Full Address:{" "}
            <span style={{ fontWeight: "400" }}>
              {details?.agreements?.tenant_address || "not available"}
            </span>
          </Typography>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "20px",
              color: "#000000",
            }}
          >
            Current Tenant Details:
          </Typography>
          <Avatar
            src="/image/userAvatar.jpg"
            alt="img"
            className={classes.tenantImg}
            sx={{ width: "6rem", height: "6rem", color: "#ADADAD" }}
          />
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#000000",
            }}
          >
            {details?.agreements?.tenant?.first_name || "not available"}
            <br></br>
            {details?.agreements?.tenant?.phone || "not available"}
            <br></br>
            {details?.agreements?.tenant?.email || "not available"}
          </Typography>
        </Box>
      </Grid>
      <Box marginTop={"5rem"}>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "30.24px",
            color: "#000000",
          }}
        >
          Documents
        </Typography>
      </Box>
      <Box
        className={classes.documntBox}
        width={{
          xs: "90%",
          sm: "45%",
          md: "35%",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "25px",
            color: "#000000",
          }}
        >
          Documents
        </Typography>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "25px",
            color: "#000000",
          }}
        >
          Action
        </Typography>
      </Box>
      <Box
        className={classes.documntDownloadBox}
        width={{
          xs: "90%",
          sm: "45%",
          md: "35%",
        }}
      >
        <Typography>
          {details?.agreements?.document
            ? details.agreements.document.split("/").pop()
            : "Document not available"}
        </Typography>
        <Tooltip title="Download">
          <SystemUpdateAltIcon
            sx={{ color: "#B9100F", cursor: "pointer" }}
            onClick={() => {
              if (details?.agreements?.document) {
                downloadFile(
                  details.agreements.document,
                  details.agreements.document.split("/").pop()
                );
              } else {
                toast.error("Document not available.");
              }
            }}
          />
        </Tooltip>

        {/* <SystemUpdateAltIcon
          sx={{ color: "#B9100F", cursor: "pointer" }}
          onClick={() => {
            downloadFile(
              details.agreements.document,
              details.agreements.document.split("/").pop()
            );
          }}
        /> */}
      </Box>
    </>
  );
}

export default ViewRentalAgremnt;
