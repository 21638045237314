import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

const TermsConditions = () => {
  return (
    <>
      <Box marginBottom={"10px"}>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: "500",
          }}
        >
          Privacy Policy
        </Typography>
      </Box>
      <Typography
        sx={{
          fontFamily: "Outfit",
          fontSize: "17px",
          lineHeight: "24px",
          fontWeight: "300",
        }}
      >
        Capstone Real Estate LLC ("we," "us," or "our") operates the CPMS
        (Capstone Smart Property Management System) app, designed to streamline
        property management for our users. We respect your privacy and are
        committed to protecting your personal data. This Privacy Policy outlines
        how we collect, use, store, and disclose your information, including,
        but not limited to, your rights and our obligations concerning data
        protection. <br></br>By using the CPMS app, you agree to the collection
        and use of your data in accordance with this policy. Please read this
        policy thoroughly to understand how your data is handled.
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="privacy1-content"
          id="privacy1-header"
        >
          1. Scope and Purpose
        </AccordionSummary>

        <AccordionDetails>
          This Privacy Policy applies to:
          <li>
            All users of the CPMS app, including, but not limited to, landlords,
            tenants, property owners, agents, and administrators.
          </li>
          <li>
            All services provided via the app, including, but not limited to,
            property listing, lease management, maintenance requests, financial
            reporting, and administrative functions.
          </li>
          We collect personal and property-related data only for specified,
          legitimate business purposes. Our data practices comply with relevant
          UAE, UK, and international privacy regulations.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="privacy2-content"
          id="privacy2-header"
        >
          2. Information We Collect
        </AccordionSummary>
        <AccordionDetails>
          <b>2.1 Personal Identification Information</b> <br></br>To manage user
          relationships effectively and perform administrative tasks, we collect
          personal data, including, but not limited to:
          <li>
            <b> Identity Verification Data:</b> Full name, Emirates ID, passport
            information, government-issued IDs.
          </li>
          <li>
            <b> Employment and Financial Data:</b> Income certificates,
            employment contracts, salary details, visa status, and banking
            information.
          </li>
          <li>
            <b> Contact Information:</b> Mailing address, email address, phone
            number, emergency contacts.
          </li>
          <li>
            <b>Property-Specific Information:</b> Ownership records,
            registration numbers, legal documentation, and capital split
            percentages where applicable.
          </li>
          <b> 2.2 Property and Transactional Information</b> <br></br>We gather
          comprehensive data on the properties we manage, such as:
          <li>
            <b> Property Characteristics:</b> Location, unit numbers, property
            types, and specifications.
          </li>
          <li>
            <b>Furnishings and Condition Reports:</b> Details about the state of
            furnishings, fittings, and fixtures, including, but not limited to,
            maintenance needs, wear and tear, damages, and repair records.
          </li>
          <li>
            <b> Financial Records:</b> Revenue and expense summaries, potential
            income forecasts, statements of account, and financial history
            related to property investments.
          </li>
          <b> 2.3 App Usage and Device Information</b>
          <br></br>
          For user experience optimization and troubleshooting, we collect:
          <li>
            <b> Device Data:</b> IP addresses, device model and type, operating
            system details, and browser types.
          </li>
          <li>
            <b> Usage Information:</b> App interaction logs, login activity,
            user preferences, and feature engagement metrics.
          </li>
          <li>
            <b>Location Data:</b> Based on user consent, location information
            may be collected for personalized service options and location-based
            alerts.
          </li>
          <b> 2.4 Sensitive Data</b>
          <br></br>
          In line with legal requirements, sensitive information such as
          government IDs, financial records, and personal health information
          (where relevant to maintenance, e.g., accessibility needs) is
          collected and handled with strict access controls and encryption.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="privacy3-content"
          id="privacy3-header"
        >
          3. Legal Basis for Processing Data
        </AccordionSummary>

        <AccordionDetails>
          Our legal basis for data processing includes:
          <li>
            <b>Contractual Necessity:</b> Processing data to fulfill property
            management contracts and service agreements.
          </li>
          <li>
            <b>Legitimate Interest:</b> Ensuring operational efficiency,
            safeguarding assets, and optimizing service delivery.
          </li>
          <li>
            <b>Compliance with Legal Obligations:</b> Fulfilling regulatory
            requirements, responding to audits, and maintaining transaction
            records.
          </li>
          <li>
            <b>User Consent:</b> For cases requiring explicit user permission,
            such as marketing communications, data analytics, and location-based
            services.
          </li>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="privacy4-content"
          id="privacy4-header"
        >
          4. How We Use Your Information
        </AccordionSummary>

        <AccordionDetails>
          Our primary purpose in collecting data is to provide and improve
          property management services, as well as to ensure compliance with
          applicable laws. Specifically, we use your information for:<br></br>
          <b>4.1 Operational Management</b>
          <br></br>
          Efficient data management enables us to:
          <li>Process tenant leases, renewals, and other legal documents.</li>
          <li>Conduct property inspections, maintenance, and repairs.</li>
          <li>
            Track and manage tenant communications, requests, and complaints.
          </li>
          <b>4.2 Financial and Transactional Processing</b>
          <br></br>
          To manage property finances, we:
          <li>
            Handle rental payments, security deposits, and refund processing.
          </li>
          <li>Generate statements of account and expense reports.</li>
          <li>
            Assess financial performance, profitability, and forecasted returns
            for properties.
          </li>
          <b> 4.3 Compliance and Regulatory Obligations</b>
          <br></br>
          We use your data to meet legal obligations, including, but not limited
          to:
          <li>
            Maintaining records for audits, tax submissions, and regulatory
            inspections.
          </li>
          <li>
            Ensuring compliance with tenancy, property, and data privacy laws.
          </li>
          <li>
            Safeguarding our platform from fraud, misuse, or unauthorized
            access.
          </li>
          <b>4.4 Marketing and Promotional Use</b>
          <br></br>
          We may use contact information to send promotional materials, special
          offers, or surveys, provided you have opted in. Users may opt out at
          any time via account settings or by contacting customer service.
          <br></br>
          <b>4.5 Analytical and Research Purposes</b>
          <br></br>
          Data analytics helps us improve the app by:
          <li>Conducting usage analysis and app performance studies.</li>
          <li>Enhancing user experience by understanding user preferences.</li>
          <li>
            Tailoring services to meet user needs based on behavioral trends.
          </li>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="privacy5-content"
          id="privacy5-header"
        >
          5. Information Sharing and Disclosure
        </AccordionSummary>
        <AccordionDetails>
          We respect your privacy and only share information with third parties
          under strict conditions.<br></br>
          <b>5.1 Service Providers</b>
          <br></br>Authorized third parties may access your data to perform
          specific tasks, such as:
          <li>
            <b>Property Maintenance Vendors:</b> For completing repairs and
            other services.
          </li>
          <li>
            <b>Payment Processors:</b> For rent collection and security deposit
            management.
          </li>
          <li>
            <b>Marketing Firms and Data Analysts:</b> For insights and
            promotional activities, where you’ve provided consent.
          </li>
          <b>5.2 Compliance with Laws and Legal Obligations</b> <br></br>
          We may share data with regulatory bodies or law enforcement if
          required to:
          <li>
            Respond to subpoenas, legal orders, or other official requests.
          </li>
          <li>Comply with mandatory reporting laws.</li>
          <b>5.3 Business Transfers</b>
          <br></br>
          If we undergo a business transaction such as a merger or sale, your
          information may be transferred. We will notify users of such changes
          and provide options regarding their data rights.<br></br>
          <b>5.4 Aggregated or Anonymized Information</b>
          <br></br>
          For research or statistical purposes, we may share anonymized data
          that cannot be linked back to individual users.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="privacy6-content"
          id="privacy6-header"
        >
          6. Security Measures
        </AccordionSummary>

        <AccordionDetails>
          We prioritize the security of your data by employing industry-standard
          practices such as:
          <li>
            <b>Data Encryption:</b> For secure storage and transmission of
            sensitive information.
          </li>
          <li>
            <b>Access Control Protocols:</b> To restrict access based on job
            roles and responsibilities.
          </li>
          <li>
            <b>Regular Security Audits:</b> Routine assessments to identify and
            mitigate vulnerabilities.
          </li>
          Despite these measures, no system is completely secure. We advise
          users to maintain secure credentials and notify us of any unauthorized
          access.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="privacy7-content"
          id="privacy7-header"
        >
          7. Data Retention
        </AccordionSummary>

        <AccordionDetails>
          We retain data only as long as necessary for the purposes outlined,
          complying with legal retention periods. When no longer required, data
          is securely deleted or anonymized.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="privacy8-content"
          id="privacy8-header"
        >
          8. International Data Transfers
        </AccordionSummary>

        <AccordionDetails>
          Your data may be stored or processed in other countries. We use
          safeguards like data encryption and contractual clauses to ensure
          compliance with data protection laws in all regions of operation.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="privacy9-content"
          id="privacy9-header"
        >
          9. User Rights
        </AccordionSummary>

        <AccordionDetails>
          You may exercise your data rights by contacting us. Depending on your
          location, these rights may include:
          <li>
            <b>Access, Rectification, and Deletion:</b> Accessing your data,
            correcting inaccuracies, or requesting deletion.
          </li>
          <li>
            <b>Restriction and Objection:</b> Limiting certain types of data
            processing.
          </li>
          <li>
            <b>Data Portability:</b> Receiving a copy of your data in a
            transferable format.
          </li>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="privacy10-content"
          id="privacy10-header"
        >
          10. Changes to Privacy Policy
        </AccordionSummary>

        <AccordionDetails>
          This policy may be updated to reflect changes in practices, legal
          requirements, or technology. Updates will be posted within the app and
          on our website.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="privacy11-content"
          id="privacy11-header"
        >
          11. Contact Us
        </AccordionSummary>

        <AccordionDetails>
          For questions about this policy, contact:
          <li>
            <b>Email:</b>info@capstoneuae.com
          </li>
        </AccordionDetails>
      </Accordion>

      {/* Terms and conditions */}
      <Box marginBottom={"10px"} marginTop={"2rem"}>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: "500",
          }}
        >
          Terms and Conditions
        </Typography>
      </Box>
      <Typography
        sx={{
          fontFamily: "Outfit",
          fontSize: "17px",
          lineHeight: "24px",
          fontWeight: "300",
        }}
      >
        These Terms govern your use of the CPMS app. By using this service, you
        agree to these Terms, our Privacy Policy, and all applicable laws.
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          1. Eligibility and Account Registration
        </AccordionSummary>

        <AccordionDetails>
          To access the app, users must:
          <li>Be of legal age to engage in binding contracts.</li>
          <li>
            Register with accurate information and maintain updated credentials.
          </li>
          <li>
            {" "}
            Agree to notify us of any unauthorized access or account security
            concerns.
          </li>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          2. Account Responsibilities
        </AccordionSummary>
        <AccordionDetails>
          Users are responsible for:
          <li>Maintaining secure passwords.</li>
          <li>Notifying us immediately if unauthorized access is detected.</li>
          <li>Ensuring compliance with all applicable laws and regulations.</li>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          3. Intellectual Property
        </AccordionSummary>
        <AccordionDetails>
          We retain all intellectual property rights over the app and its
          contents. Users are prohibited from:
          <li>
            Copying, reproducing, or distributing content without permission.
          </li>
          <li>Altering or reverse-engineering software.</li>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          4. Acceptable Use Policy
        </AccordionSummary>
        <AccordionDetails>
          Prohibited uses include:
          <li>Engaging in fraudulent activities.</li>
          <li>
            Attempting to bypass security controls or gain unauthorized access.
          </li>
          <li>Sharing credentials with third parties or unauthorized users.</li>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          5. Fees and Payments
        </AccordionSummary>
        <AccordionDetails>
          For paid services, users agree to:
          <li>Make timely payments as outlined.</li>
          <li>
            Understand that late or missed payments may incur additional fees.
          </li>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6-content"
          id="panel6-header"
        >
          6. Liability Limitation
        </AccordionSummary>
        <AccordionDetails>
          Our liability is limited to direct damages and excludes incidental or
          consequential damages to the extent allowed by law.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7-content"
          id="panel7-header"
        >
          7. Disclaimer of Warranties
        </AccordionSummary>
        <AccordionDetails>
          The app is provided "as is," without warranties regarding
          uninterrupted service, error-free operation, or security from
          unauthorized access.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8-content"
          id="panel8-header"
        >
          8. Indemnification
        </AccordionSummary>
        <AccordionDetails>
          Users agree to indemnify us against claims arising from misuse,
          unauthorized access, or breach of these Terms.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9-content"
          id="panel9-header"
        >
          9. Termination
        </AccordionSummary>
        <AccordionDetails>
          We may terminate accounts for violations of these Terms. Upon
          termination:
          <li>Access to all services will be revoked.</li>
          <li>We may retain certain data to comply with legal obligations.</li>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10-content"
          id="panel10-header"
        >
          10. Dispute Resolution
        </AccordionSummary>
        <AccordionDetails>
          These Terms are governed by UAE law. Disputes will be resolved by UAE
          courts unless agreed otherwise in writing.
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ marginBottom: "1rem" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11-content"
          id="panel11-header"
        >
          11. Contact Information
        </AccordionSummary>
        <AccordionDetails>
          Questions about these Terms can be addressed to:
          <li>
            <b>Email:</b> info@capstoneuae.com
          </li>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default TermsConditions;
