import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  Modal,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  FormControl,
  Button,
} from "@mui/material";
import { newIncomeTextfield } from "./ContractStyle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { UserTypesNum, PdcType } from "../../../enums/UserTypes";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const EditTenant = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfLetter, setPdfLetter] = useState(null);
  const [pdfError, setPdfError] = useState(null);
  const { tenantContract } = location.state || {};
  const [dates, setDates] = useState({
    startDate: tenantContract?.start_date,
    endDate: tenantContract?.end_date,
  });
  const [numRows, setNumRows] = useState(tenantContract?.no_of_payments);
  const [rows, setRows] = useState([tenantContract?.pdcs] || null);

  const [depositedAmount, setDepositedAmount] = useState({
    payment_date: null,
    amount: null,
    vat: null,
    cheque_number: null,
    bank: null,
    type: "2",
  });
  const [tenantListingData, setTenantListingData] = useState();
  const [propertyListing, setPropertyListing] = useState(null);
  const [unitListing, setUnitListing] = useState(null);

  const [tenantObj, setTenantObj] = useState(tenantContract?.tenant);
  const [tenantObjId, setTenantObjId] = useState(tenantContract?.tenant.id);

  const [propertyObj, setPropertyObj] = useState(tenantContract?.property);
  const [propertyObjId, setPropertyObjId] = useState(
    tenantContract?.property.id
  );
  const [units, setUnits] = useState([]);

  const [tenantData, setTenantData] = useState({
    startDate: null,
    endDate: null,
    lesse: null,
    nationality: tenantContract?.tenant_nationality,
    address: tenantContract?.tenant_address,
    email: tenantContract?.tenant.email,
    passportNo: tenantContract?.tenant_passport,
    uidNo: tenantContract?.tenant_uid,
    passportExpiry: tenantContract?.passport_expiry,
    emergencyContact: tenantContract?.tenant_emergency_contact,
    emiratesId: tenantContract?.tenant_emirates_id,
  });

  const [propertyDetails, setPropertyDetails] = useState({
    // unitNo: units,
    prpNo: tenantContract?.property.ref_id,
    propertyTitle: tenantContract?.property.title,
    rooms: tenantContract?.rooms,
    parking: tenantContract?.parking,

    period: tenantContract?.contract_period,
    propertyType: tenantContract?.property.type,
    rentValue: tenantContract?.rent_value,
    // payments: numRows,
  });

  const [clausesData, setClausesData] = useState({
    article1: tenantContract?.article_1_clause,
    article2: tenantContract?.article_2_clause,
    article3: tenantContract?.article_3_clause,
    article4: tenantContract?.article_4_clause,
    article5: tenantContract?.article_5_clause,
  });

  const unitIds = units.map((item) => item.id);

  const addUnits = () => {
    setUnits([...units, ""]);
  };

  const handleDatesChange = (e) => {
    const { name, value } = e.target;
    setDates((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleUnitsChange = (index, value) => {
    const updatedUnits = units.map((unit, i) => {
      if (i === index) {
        return { ...unit, id: value };
      }
      return unit;
    });
    setUnits(updatedUnits);
  };

  const handleTenantIdChange = (e) => {
    setTenantObjId(e.target.value);
    const tenantUpdate = tenantListingData.filter((value, index) => {
      return e.target.value == value.id;
    });
    setTenantObj(tenantUpdate[0]);
  };

  const handlePropertyIdChange = (e) => {
    setPropertyObjId(e.target.value);
    const propertyUpdate = propertyListing.filter((value, index) => {
      return e.target.value == value.id;
    });
    setPropertyObj(propertyUpdate[0]);
    setUnits([""]);
  };

  const handleTenantDataChange = (e) => {
    const { name, value } = e.target;

    setTenantData({
      ...tenantData,
      [name]: value,
    });
  };

  const handleClausesDetailChange = (e) => {
    const { name, value } = e.target;
    setClausesData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePropertyDetailsChange = (e) => {
    const { name, value } = e.target;

    setPropertyDetails({
      ...propertyDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    const number = parseInt(numRows);
    setNumRows(number);
    const newRows = Array.from({ length: number }, (v, i) => {
      if (tenantContract.pdcs[i]?.type != PdcType.SecurityDeposit) {
        return {
          payment_number: tenantContract.pdcs[i]?.payment_number,
          payment_date: tenantContract.pdcs[i]?.payment_date,
          amount: tenantContract.pdcs[i]?.amount,
          vat: tenantContract.pdcs[i]?.vat,
          cheque_number: tenantContract.pdcs[i]?.cheque_number,
          bank: tenantContract.pdcs[i]?.bank,
          type: tenantContract.pdcs[i]?.type,
        };
      } else {
        return {
          payment_number: i + 1,
          payment_date: null,
          amount: null,
          vat: null,
          cheque_number: null,
          bank: null,
          type: null,
        };
      }
    });
    const sDeposit = tenantContract.pdcs?.map((pdc, index) => {
      if (pdc.type == PdcType.SecurityDeposit) {
        setDepositedAmount({
          payment_date: pdc.payment_date,
          amount: pdc.amount,
          vat: pdc.vat,
          cheque_number: pdc.cheque_number,
          bank: pdc.bank,
          type: pdc.type,
        });
      }
    });
    setRows(newRows);
  }, []);

  const handleNumRowsChange = (event) => {
    const number = parseInt(event.target.value, 10);
    setNumRows(number);
    const newRows = Array.from({ length: number }, (v, i) => ({
      payment_number: "",
      payment_date: "",
      amount: "",
      vat: "",
      cheque_number: "",
      bank: "",
      type: "",
    }));
    setRows(newRows);
  };

  const handleInputChange = (rowIndex, field) => (event) => {
    if (rowIndex == "-1") {
      setDepositedAmount((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    } else {
      const newRows = rows.map((row, rIndex) =>
        rowIndex === rIndex ? { ...row, [field]: event.target.value } : row
      );
      setRows(newRows);
    }
  };

  const tenantListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };

      const response = await axios.get(
        `${endpoints.getUserListing}?user_type=${UserTypesNum.tenant}`,
        requestOptions
      );
      setTenantListingData(response.data.data);
    } catch (error) { }
  };

  const getPropertyListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        endpoints.getPropertiesListing,
        requestOptions
      );
      setPropertyListing(response.data.data);
    } catch (error) { }
  };

  const getUnitListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      let url;
      if (propertyObj) {
        url = `${endpoints.getUnitsListing}?property=${propertyObj.id}`;
      } else {
        url = endpoints.getUnitsListing;
      }
      const response = await axios.get(url, requestOptions);
      setUnitListing(response.data.data);
    } catch (error) { }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePdfLoadError = (error) => {
    setPdfError(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };

      const payload = {
        start_date: dates.startDate,
        end_date: dates.endDate,
        tenant: tenantObj?.id,
        tenant_address: tenantData.address,
        tenant_emergency_contact: tenantData.emergencyContact,
        tenant_emirates_id: tenantData.emiratesId,
        tenant_passport: tenantData.passportNo,
        tenant_nationality: tenantData.nationality,
        tenant_uid: tenantData.uidNo,
        passport_expiry: tenantData.passportExpiry,
        unit: unitIds,
        property: propertyObj?.id,
        rooms: propertyDetails.rooms,
        parking: propertyDetails.parking,
        contract_period: propertyDetails.period,
        rent_value: propertyDetails.rentValue,
        property_type: propertyDetails.propertyType,
        no_of_payments: numRows,
        article_1_clause: clausesData.article1,
        article_2_clause: clausesData.article2,
        article_3_clause: clausesData.article3,
        article_4_clause: clausesData.article4,
        article_5_clause: clausesData.article5,
        pdcs: [...rows, depositedAmount],
      };

      const response = await axios.patch(
        `${endpoints.tenantContracts}?contract_id=${tenantContract.id}`,
        payload,
        requestOptions
      );
      if (tenantContract.is_draft == true) {
        setPdfLetter(response.data.data[0].document);
        toast.success(response.data.message);
        setIsModalOpen(true);
      } else {

        navigate("/admin/contracts");
      }
    } catch (error) {

    }
  };
  const sendOfferLetter = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.post(
        `${endpoints.tenantContracts}send/?contract_id=${tenantContract.id}`,
        tenantContract.id,
        requestOptions
      );
      toast.success(response.data.message);
      handleModalClose();
      navigate("/admin/contracts");
    } catch (error) {

    }
  };

  useEffect(() => {
    tenantListing();
    getPropertyListing();
  }, []);
  useEffect(() => {
    getUnitListing();

    let propertyType =
      propertyObj?.type == "1"
        ? "Residential"
        : propertyObj?.type == "2"
          ? "Comercial"
          : propertyObj?.type == "3"
            ? "Community"
            : null;

    setPropertyDetails({
      prpNo: propertyObj?.ref_id,
      propertyTitle: propertyObj?.title,
      rooms: propertyObj?.bedrooms,
      propertyType: propertyType,
    });
  }, [propertyObj]);

  useEffect(() => {
    setTenantData({
      lesse: tenantObj?.first_name,
      email: tenantObj?.email,
      passportNo: tenantObj?.passport_no,
      emiratesId: tenantObj?.emirates_id,

    });
  }, [tenantObj]);

  useEffect(() => {
    if (tenantContract.unit) {
      setUnits(tenantContract.unit);
    }
  }, []);

  return (
    <>
      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          Edit Tenant Contract
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={2}
        >
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Start Date
            </Typography>
            <TextField
              type="date"
              fullWidth
              variant="outlined"
              name="startDate"
              value={dates.startDate}
              onChange={handleDatesChange}
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              End Date
            </Typography>
            <TextField
              type="date"
              fullWidth
              name="endDate"
              value={dates.endDate}
              onChange={handleDatesChange}
              variant="outlined"
              sx={newIncomeTextfield}
            />
          </Grid>
        </Grid>
        <Box>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontSize: "24px",
              lineHeight: "24px",
              fontWeight: 400,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <Box>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "rgba(0, 0, 0, 1)",
                  margin: "30px 0px 30px 0px",
                }}
              >
                Lessee details
              </Typography>
            </Box>
          </Typography>
        </Box>

        <Grid item xs={12} md={4} lg={2}>
          <Select
            style={{
              width: "30%",
              height: "40px",
              border: "1px solid rgba(239, 239, 239, 1)",
              borderRadius: "30px",
              padding: "0px 0px 0px 20px",
              backgroundColor: "white",
              appearance: "none",
            }}
            // value={tenantObj.id}
            value={tenantObjId || ""}
            onChange={(e) => {
              handleTenantIdChange(e);
            }}
            required
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Tenant
            </MenuItem>
            {/* Add options here */}

            {tenantListingData?.map((tenant, index) => (
              <MenuItem value={tenant.id} key={index}>
                {tenant.first_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={2}
        >
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Lessee
            </Typography>
            <TextField
              fullWidth
              name="lesse"
              value={tenantData.lesse}
              onChange={handleTenantDataChange}
              variant="outlined"
              placeholder="Tenant name"
              sx={newIncomeTextfield}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Nationality
            </Typography>
            <TextField
              fullWidth
              name="nationality"
              value={tenantData.nationality}
              onChange={handleTenantDataChange}
              variant="outlined"
              placeholder="Nationality"
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Address
            </Typography>
            <TextField
              fullWidth
              name="address"
              value={tenantData.address}
              onChange={handleTenantDataChange}
              variant="outlined"
              placeholder="Address"
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Email Address
            </Typography>
            <TextField
              fullWidth
              name="email"
              value={tenantData.email}
              onChange={handleTenantDataChange}
              variant="outlined"
              placeholder="Email Address"
              sx={newIncomeTextfield}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Passport No
            </Typography>
            <TextField
              fullWidth
              name="passportNo"
              value={tenantData.passportNo}
              onChange={handleTenantDataChange}
              variant="outlined"
              placeholder="Passport Number"
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              UID No
            </Typography>
            <TextField
              fullWidth
              name="uidNo"
              value={tenantData.uidNo}
              onChange={handleTenantDataChange}
              variant="outlined"
              placeholder="UID Number"
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Emergency Contact
            </Typography>
            <TextField
              fullWidth
              name="emergencyContact"
              value={tenantData.emergencyContact}
              onChange={handleTenantDataChange}
              variant="outlined"
              placeholder="Emergency Contact"
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Emirates Id
            </Typography>
            <TextField
              fullWidth
              name="emiratesId"
              value={tenantData.emiratesId}
              onChange={handleTenantDataChange}
              variant="outlined"
              placeholder="Emirates ID"
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Passport Expiry
            </Typography>
            <TextField
              fullWidth
              type="date"
              name="passportExpiry"
              value={tenantData.passportExpiry}
              onChange={handleTenantDataChange}
              variant="outlined"
              placeholder="Passport Expiry Date"
              sx={newIncomeTextfield}
            />
          </Grid>
        </Grid>

        <Box>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontSize: "24px",
              lineHeight: "24px",
              fontWeight: 400,
              color: "rgba(0, 0, 0, 1)",
              margin: "30px 0px 30px 0px",
            }}
          >
            Property Details
          </Typography>
        </Box>

        <Grid item xs={12} md={4} lg={2}>
          <Select
            style={{
              width: "30%",
              height: "40px",
              border: "1px solid rgba(239, 239, 239, 1)",
              borderRadius: "30px",
              padding: "0px 0px 0px 20px",
              backgroundColor: "white",
              appearance: "none",
            }}
            required
            displayEmpty
            value={propertyObjId || ""}
            onChange={(e) => {
              handlePropertyIdChange(e);
            }}
            placeholder="Select Property"
          >
            <MenuItem value="" disabled>
              Select Property
            </MenuItem>
            {propertyListing?.map((property, index) => (
              <MenuItem value={property.id} key={index}>
                {`${property.ref_id} - ${property.title}`}
              </MenuItem>
            ))}


          </Select>
        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={2}
        >
          {units.map((unit, index) => {
            return (
              <Grid item xs={12} md={12} key={index}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Unit No {index + 1}
                </Typography>

                <FormControl fullWidth sx={newIncomeTextfield}>
                  <Select
                    fullWidth
                    variant="outlined"
                    placeholder="Unit Number"
                    value={unit.id || ''}
                    onChange={(e) => handleUnitsChange(index, e.target.value)}
                    displayEmpty
                  >
                    <MenuItem disabled value=''>Select Unit</MenuItem>

                    {unitListing
                      ?.filter((item) => {
                        const isSelected = units.some(
                          (unit) => unit.id === item.id
                        );
                        return !isSelected || item.id === unit.id;
                      })
                      .map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {`${item.ref_id} - ${item.title}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            );
          })}
          <Grid item xs={12} md={12}>
            <IconButton
              // variant="contained"
              onClick={addUnits}
              sx={{ color: "#B81918" }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              PRP No
            </Typography>
            <TextField
              fullWidth
              name="prpNo"
              value={propertyDetails.prpNo}
              onChange={handlePropertyDetailsChange}
              variant="outlined"
              placeholder="PRP Number"
              sx={newIncomeTextfield}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Property
            </Typography>
            <TextField
              fullWidth
              name="propertyTitle"
              value={propertyDetails.propertyTitle}
              onChange={handlePropertyDetailsChange}
              variant="outlined"
              placeholder="Property"
              sx={newIncomeTextfield}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Rooms
            </Typography>
            <TextField
              fullWidth
              name="rooms"
              value={propertyDetails.rooms}
              onChange={handlePropertyDetailsChange}
              variant="outlined"
              placeholder="Rooms"
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Parking
            </Typography>
            <TextField
              fullWidth
              name="parking"
              value={propertyDetails.parking}
              onChange={handlePropertyDetailsChange}
              variant="outlined"
              placeholder="Parking"
              sx={newIncomeTextfield}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Period
            </Typography>
            <TextField
              fullWidth
              name="period"
              value={propertyDetails.period}
              onChange={handlePropertyDetailsChange}
              variant="outlined"
              placeholder="Time Period"
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Property Type
            </Typography>
            <TextField
              fullWidth
              name="propertyType"
              value={propertyDetails.propertyType}
              onChange={handlePropertyDetailsChange}
              variant="outlined"
              placeholder="Property Type"
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Rent Value
            </Typography>
            <TextField
              fullWidth
              name="rentValue"
              value={propertyDetails.rentValue}
              onChange={handlePropertyDetailsChange}
              variant="outlined"
              placeholder="Rent Value"
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              No. of Payments
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Number of Payments"
              type="number"
              disabled
              value={numRows}
              onChange={handleNumRowsChange}
              sx={newIncomeTextfield}
            />
          </Grid>
        </Grid>

        {/* additional clauses */}
        <Box>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontSize: "24px",
              lineHeight: "24px",
              fontWeight: 500,
              color: "rgba(0, 0, 0, 1)",
              margin: "30px 0px 30px 0px",
            }}
          >
            Additional Clauses
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={2}
        >
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Article 1
            </Typography>
            <TextField
              fullWidth
              name="article1"
              value={clausesData.article1}
              onChange={handleClausesDetailChange}
              variant="outlined"
              placeholder="Article 1"
              multiline
              maxRows={4}
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Article 2
            </Typography>
            <TextField
              fullWidth
              name="article2"
              value={clausesData.article2}
              onChange={handleClausesDetailChange}
              variant="outlined"
              placeholder="Article 2"
              multiline
              maxRows={4}
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Article 3
            </Typography>
            <TextField
              fullWidth
              name="article3"
              value={clausesData.article3}
              onChange={handleClausesDetailChange}
              variant="outlined"
              placeholder="Article 3"
              multiline
              maxRows={4}
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Article 4
            </Typography>
            <TextField
              fullWidth
              name="article4"
              value={clausesData.article4}
              onChange={handleClausesDetailChange}
              variant="outlined"
              multiline
              maxRows={4}
              placeholder="Article 4"
              sx={newIncomeTextfield}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "18px",
                margin: "0px 0px 15px 0px",
              }}
            >
              Article 5
            </Typography>
            <TextField
              fullWidth
              name="article5"
              value={clausesData.article5}
              onChange={handleClausesDetailChange}
              variant="outlined"
              placeholder="Article 5"
              multiline
              maxRows={4}
              sx={newIncomeTextfield}
            />
          </Grid>
        </Grid>

        <Box>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontSize: "24px",
              lineHeight: "24px",
              fontWeight: 400,
              color: "rgba(0, 0, 0, 1)",
              margin: "30px 0px 30px 0px",
            }}
          >
            PDCs
          </Typography>
        </Box>

        {/* Table Start */}

        <Box style={{ overflowX: "auto" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{ backgroundColor: "rgba(239, 239, 239, 0.72)" }}
              >
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Payment Number
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Payment Date
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Amount
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 40px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  VAT
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Cheque No./<br></br> Money Transfer.<br></br>No. Cash
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Drawee Bank
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px 0px 10px 20px",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>-</TableCell>
                <TableCell>
                  <TextField
                    type="date"
                    // value={depositedAmount.payment_date}
                    value={depositedAmount.payment_date}
                    onChange={handleInputChange(-1, "payment_date")}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    // value={depositedAmount.amount}
                    value={depositedAmount.amount}
                    onChange={handleInputChange(-1, "amount")}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    // value={depositedAmount.vat}
                    value={depositedAmount.vat}
                    onChange={handleInputChange(-1, "vat")}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    // value={depositedAmount.chequeNo}
                    value={depositedAmount.cheque_number}
                    onChange={handleInputChange(-1, "cheque_number")}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={depositedAmount.bank}
                    onChange={handleInputChange(-1, "bank")}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <Select
                      // value={depositedAmount.type}
                      value={depositedAmount.type}
                      onChange={handleInputChange(-1, "type")}
                    >
                      <MenuItem value="1">Rental</MenuItem>
                      <MenuItem value="2">Security Deposit</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>

              {/* {rows?.map((row, rowIndex) => ( */}
              {rows?.map((row, rowIndex) => (
                // <TableRow key={rowIndex}>
                <TableRow>
                  <TableCell>{row.payment_number}</TableCell>
                  <TableCell>
                    <TextField
                      type="date"
                      value={row.payment_date}
                      onChange={handleInputChange(rowIndex, "payment_date")}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.amount}
                      onChange={handleInputChange(rowIndex, "amount")}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.vat}
                      onChange={handleInputChange(rowIndex, "vat")}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.cheque_number}
                      onChange={handleInputChange(rowIndex, "cheque_number")}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.bank}
                      onChange={handleInputChange(rowIndex, "bank")}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select
                        value={row.type ? row.type : ""}
                        onChange={handleInputChange(rowIndex, "type")}
                      >
                        <MenuItem value="1">Rent</MenuItem>
                        <MenuItem value="2">Security Deposit</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ display: "flex", margin: "20px 0 50px 0" }}>
          <Button
            style={{
              backgroundColor: "rgba(184, 25, 24, 1)",
              fontSize: "14px",
              lineHeight: "16px",
              fontWeight: 500,
              textTransform: "none",
              width: "130px",
              height: "40px",
            }}
            variant="contained"
            type="submit"
          >
            Save Contract
          </Button>
        </Box>
      </form>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="pdf-viewer-modal"
        aria-describedby="pdf-viewer-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: "100%",
            bgcolor: "white",
            boxShadow: 18,
            p: 4,
            borderRadius: "8px",
            overflow: "auto",
          }}
        >
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            {pdfError ? (
              <div>Error loading PDF: {pdfError.message}</div>
            ) : (
              <Viewer fileUrl={pdfLetter} onError={handlePdfLoadError} />
            )}
          </Worker>
          <Box
            display={"flex"}
            marginBottom={"0.5rem"}
            marginTop={"0.5rem"}
            gap={"10px"}
          >
            <Button
              sx={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                color: "white",
                fontSize: "12px",
                lineHeight: "12px",
                fontWeight: 500,
                textTransform: "none",
                width: "8rem",
                height: "2rem",
                borderRadius: "0.5rem",
                '&:hover': {
                  bgcolor: 'rgba(184, 25, 24, 1)',
        
                },
              }}
              onClick={sendOfferLetter}
            >
              Send Contract
            </Button>
            <Button
              sx={{
                border: "1px solid rgba(184, 25, 24, 1)",
                color: "rgba(184, 25, 24, 1)",
                fontSize: "12px",
                lineHeight: "12px",
                fontWeight: 500,
                textTransform: "none",
                width: "8rem",
                height: "2rem",
                borderRadius: "0.5rem",
                // margin: "20px 0px 50px 0px",
              }}
              onClick={handleModalClose}
            >
              Save as Draft
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditTenant;
