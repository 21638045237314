import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  fieldHeading,
  fieldText,
  mainTypography,
  procesBtn,
  disApproveBtn,
  approveBtn,
  rentCommentfield,
  rentDescriptionfield,
  rentTextfield,
  useStyles,
} from "./RentStyles";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { Loader } from "../../../utils/Loader";
import { rentStatus } from "../../../enums/UserTypes";

function ViewForm() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { rent } = location.state || {};

  const [data, setData] = useState({
    amount: null,
    porperty: null,
    descrpition: null,
    notes: null,
  });
  // const [loading, setLoading] = useState({ approve: false, disapprove: false})
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    setData({
      amount: rent?.amount || "Not available",
      porperty: rent?.property?.ref_id || "Not available",
      descrpition: rent?.description || "Not available",
      notes: rent?.additional_comments || "Not available",
    });
  }, []);

  

  const updateRentReimbursement = async (approvalStatus) => {
    try {
     setLoading(true);
      const payload = {
        additional_comments: data.notes,
        finance_approval: approvalStatus,
      };
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.patch(
        `${endpoints.financeRentReimbursement}?id=${rent.id}`,
        payload,
        requestOptions
      );
      toast.success(response.data.message)
      navigate('/finance/rent-reimburstment')
    } catch(error) {
      toast.error(error.response.data.message)
    } finally {
     setLoading(false)
    }
  };

  return (
    <>
    {loading ? (
      <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"50vh"}
    >
      <Loader />
    </Box>
    ):(<>
      <Box>
        <Typography sx={mainTypography}>Name/Form No</Typography>
      </Box>
      <Grid container spacing={3} marginTop={"2rem"} marginBottom={"1rem"}>
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Reimbursement Amount</Typography>
            </Box>
            <Box className={classes.textBox}>
              {/* <Typography sx={fieldText}>3000 AED</Typography> */}
              <TextField
                placeholder="3000 AED"
                name="amount"
                value={data.amount}
                onChange={handleChange}
                sx={rentTextfield}
                fullWidth
                disabled
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Property</Typography>
            </Box>
            <Box className={classes.textBox}>
              {/* <Typography sx={fieldText}>Ramada Plaza</Typography> */}
              <TextField
                placeholder="Ramada Plaza"
                name="porperty"
                value={data.porperty}
                onChange={handleChange}
                sx={rentTextfield}
                fullWidth
                disabled
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Description</Typography>
            </Box>
            <Box className={classes.textBox}>
              {/* <Typography sx={fieldText}>Description Here</Typography> */}
              <TextField
                placeholder="Description Here"
                name="descrpition"
                value={data.descrpition}
                onChange={handleChange}
                sx={rentTextfield}
                fullWidth
                multiline
                rows={5}
                disabled
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Box className={classes.mainBox}>
            <Box>
              <Typography sx={fieldHeading}>Additional Comments</Typography>
            </Box>
            <Box className={classes.textBox}>
              {/* <Typography sx={fieldText}>Additional Comments Here</Typography> */}
              <TextField
                placeholder="Description Here"
                name="notes"
                value={data.notes}
                onChange={handleChange}
                sx={rentTextfield}
                fullWidth
                multiline
                rows={5}
              />
            </Box>
          </Box>
        </Grid>
        
      </Grid>
      {rent?.finance_approval === "1" ? (
        <Box className={classes.buttonsBox}>
          
          <Button variant="outlined" sx={approveBtn} onClick= {()=>{updateRentReimbursement(rentStatus.approved.value)}} > 
          Approve
          </Button>
         
          
          <Button sx={disApproveBtn} onClick= {()=>{updateRentReimbursement(rentStatus.disapproved.value)}} >
          Diapprove
            </Button>
          
        </Box>
      ) : (
        <Box className={classes.buttonsBox}>
          <Button sx={disApproveBtn} >
            {rent?.finance_approval}
            </Button>
        </Box>
      )}
      </>)}
    </>
  );
}

export default ViewForm;
