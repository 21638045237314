import React, { useState, useEffect } from "react";
import {
  headingTypography,
  notificationTimeTypography,
  notificationTypography,
  rightHeadingTypography,
  useStyles,
} from "./NotificationStyles";
import { Box, Typography, Grid, Avatar } from "@mui/material";
// import Switch from "@mui/material/Switch";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { UserTypesNum } from "../../../enums/UserTypes";
import DateFormatter from "../../../utils/DateFormatter";
import { Loader } from "../../../utils/Loader";

const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#B81918",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 1.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#B81918",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Notifications = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [alerts, setAlerts] = useState(null);
  const classes = useStyles();
  // const label = { inputProps: { "aria-label": "Switch demo" } };

  const tenantNotifications = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        `${endpoints.getNotifications}?user_type=${
          UserTypesNum.tenant
        }&user_id=${localStorage.getItem("id")}`,
        requestOptions
      );
      setAlerts(response.data.data);
    } catch {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    tenantNotifications();
  }, []);

  return (
    <>
      <Box
        className={classes.mainBox}
        // flexDirection={{ xs: "column", sm: "column", md: "row" }}
      >
        <Box>
          <Typography sx={headingTypography}>All Notification</Typography>
        </Box>
        {/* <Box display={"flex"} alignItems={"center"} gap={"10px"}>
          <Typography sx={rightHeadingTypography}>
            Mute All Notifications
          </Typography>
          <IOSSwitch
          />
          <SettingsOutlinedIcon
            sx={{ fontSize: "22px", color: "#9A1915" }}
            onClick={() => navigate("/tenant/notifications-setting")}
          />
        </Box> */}
      </Box>
      {loading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"50vh"}
        >
          <Loader />
        </Box>
      ) : alerts && alerts.length > 0 ?  (
        <Grid container height={{ sm: "30rem", xs: "50rem" }} overflow={"auto"}>
          {alerts?.map((alert) => (
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              key={alert.id}
              marginBottom={"10px"}
            >
              <Box className={classes.notificationBox}>
                <Box>
                  <Avatar src="/image/profile.jpg" />
                </Box>
                <Box>
                  <Typography sx={notificationTypography}>
                    <b>{alert?.title || "Untitled Notification"}</b>
                  </Typography>
                  <Typography sx={notificationTypography}>
                    {alert?.body || "Content not available"}
                  </Typography>
                  <Typography sx={notificationTimeTypography}>
                    {DateFormatter(alert?.created_at) || "Date not available"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Typography sx={notificationTypography}>No notifications</Typography>
        </Box>
      )}
    </>
  );
};

export default Notifications;
