import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { newIncomeTextfield, uploadBtn } from "./DocumentStyle";
import { documentTypes, UserTypesNum } from "../../../enums/UserTypes";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import Documents from "./Documents";




const AddDocument = () => {
  const [isDocCreated, setIsDocCreated] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentDetails, setDocumentDetails] = useState({
    documentType:'',
    unit: null,
    landlordName: null,
    tenantName: null,
    subProperty: null,
    contractExpiry: null,
  });
  const [unitsListing, setUnitsListing] = useState(null);
  const [landlordListing, setLandlordListing] = useState(null);
  const [tenantListing, setTenantListing] = useState(null);
  const [subProperties, setSubProperties] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const listingData = async (url, setter) => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };

      const response = await axios.get(url, requestOptions);
      setter(response.data.data);
    } catch (error) { }
  };

  const getUnits = useCallback(() => {
    listingData(endpoints.Unitlist, setUnitsListing);
  }, []);

  const getSubProperties = useCallback(() => {
    const url = endpoints.subPropertiesList;
    listingData(url, setSubProperties);
  }, []);

  useEffect(() => {
    getUnits();
    getSubProperties();
  }, [getUnits, getSubProperties]);

  const fetchLandlordAndTenant = async (unitId) => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };

      const landlordResponse = await axios.get(
        `${endpoints.getUserListing}?user_type=${UserTypesNum.landlord}&unit_id=${unitId}`,
        requestOptions
      );

      const tenantResponse = await axios.get(
        `${endpoints.getUserListing}?user_type=${UserTypesNum.tenant}&unit_id=${unitId}`,
        requestOptions
      );

      setDocumentDetails((prevDetails) => ({
        ...prevDetails,
        landlordName: landlordResponse.data.data[0]?.id || null,
        tenantName: tenantResponse.data.data[0]?.id || null,
      }));

      setLandlordListing(landlordResponse.data.data);
      setTenantListing(tenantResponse.data.data);

    } catch (error) {
      console.error("Error fetching unit details:", error);
      toast.error("Failed to fetch landlord and tenant details");
    }
  };

  const handleDocumnetDetailsChange = (field, value) => {
    setDocumentDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));

    if (field === "unit" && value) {
      fetchLandlordAndTenant(value);
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const formData = new FormData();
      formData.append("type", documentDetails.documentType);
      formData.append("file", selectedFile);
      if (documentDetails.unit) {
        formData.append("unit", documentDetails.unit);
      }
      if (documentDetails.landlordName) {
        formData.append("landlord", documentDetails.landlordName);
      }
      if (documentDetails.tenantName) {
        formData.append("tenant", documentDetails.tenantName);
      }
      if (documentDetails.subProperty) {
        formData.append("sub_property", documentDetails.subProperty);
      }
      if (documentDetails.contractExpiry) {
        formData.append("expiry_date", documentDetails.contractExpiry);
      }
      setLoading(true);
      const response = await axios.post(
        endpoints.documents,
        formData,
        requestOptions
      );
      toast.success(response.data.message);
      setIsDocCreated(true);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isDocCreated ? (
        <Documents />
      ) : (
        <>
          <Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              Add Document
            </Typography>
          </Box>
          {loading && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <form onSubmit={handelSubmit}>
            <Grid container spacing={2} mt={4}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Document Type
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={documentDetails.documentType || ""}
                    onChange={(e) =>
                      handleDocumnetDetailsChange(
                        "documentType",
                        e.target.value
                      )
                    }
                    displayEmpty
                    style={{
                      margin: "0px 0px 25px 0px",
                      height: "50px",
                    }}
                    sx={newIncomeTextfield}
                    variant="outlined"
                    
                  >
                    <MenuItem value="">Select Document Type</MenuItem>
                    {/* Add additional options here */}

                    {/* <MenuItem value="Option 1">Option 1</MenuItem> */}
                    {Object.values(documentTypes).map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Unit
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={documentDetails.unit || ""}
                    onChange={(e) =>
                      handleDocumnetDetailsChange("unit", e.target.value)
                    }
                    displayEmpty
                    style={{ margin: "0px 0px 25px 0px", height: "50px" }}
                    sx={newIncomeTextfield}
                    variant="outlined"
                  >
                    <MenuItem value="" disabled>
                      Select Unit
                    </MenuItem>
                    {/* Add additional options here */}
                    {unitsListing?.map((unit, index) => (
                      <MenuItem value={unit.id} key={index}>
                        {`${unit.ref_id} - ${unit.title}` || "N/A"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Landlord Name
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={documentDetails.landlordName || ""}
                    onChange={(e) =>
                      handleDocumnetDetailsChange(
                        "landlordName",
                        e.target.value
                      )
                    }
                    displayEmpty
                    style={{ height: "50px" }}
                    sx={newIncomeTextfield}
                    variant="outlined"
                  
                  >
                    <MenuItem value="" disabled>
                      Select Landlord Name
                    </MenuItem>
                    {/* Add landlord options here */}
                    {landlordListing?.map((landlord, index) => (
                      <MenuItem value={landlord.id} key={index}>
                        {landlord.first_name || "N/A"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Tenant Name
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={documentDetails.tenantName || ""}
                    onChange={(e) =>
                      handleDocumnetDetailsChange("tenantName", e.target.value)
                    }
                    displayEmpty
                    style={{ height: "50px" }}
                    sx={newIncomeTextfield}
                    variant="outlined"
                  >
                    <MenuItem value="" disabled>
                      Select Tenant Name
                    </MenuItem>
                    {/* Add tenant options here */}
                    {tenantListing?.map((tenant, index) => (
                      <MenuItem value={tenant.id} key={index}>
                        {tenant.first_name || "N/A"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Sub Property
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={documentDetails.subProperty || ""}
                    onChange={(e) =>
                      handleDocumnetDetailsChange("subProperty", e.target.value)
                    }
                    displayEmpty
                    // style={{ height: "50px" }}
                    sx={newIncomeTextfield}
                    variant="outlined"
                  >
                    <MenuItem value="" disabled>
                      Select Sub Property
                    </MenuItem>
                    {/* Add tenant options here */}
                    {subProperties?.map((subProperty) => (
                      <MenuItem value={subProperty.id} key={subProperty.id}>
                        {subProperty.ref_id || "N/A"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Document Expiry Date
                </Typography>
                <TextField
                  type="date"
                  // style={{ height: "50px" }}
                  value={documentDetails.contractExpiry || ""}
                  onChange={(e) =>
                    handleDocumnetDetailsChange(
                      "contractExpiry",
                      e.target.value
                    )
                  }
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={8}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "18px",
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Upload Documents
                </Typography>
                <Box
                  sx={{
                    p: 5,
                    border: 1,
                    borderColor: "grey.300",
                    borderRadius: 2,
                    textAlign: "center",
                  }}
                >
                  <input
                    accept="*"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="raised-button-file"
                    
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span" sx={uploadBtn}>
                      Select and Upload
                    </Button>
                  </label>
                  {selectedFile && (
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      {selectedFile.name}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
            </Grid>
            <Box sx={{ display: "flex", margin: "20px 0 50px 0" }}>
              <Button type="submit" style={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "170px",
                height: "40px",
                margin: "20px 0px 50px 15px"

              }}
                variant="contained"
                disabled={loading} 

              > Save Document</Button>
            </Box>
          </form>
        </>
      )}
    </>
  );
};

export default AddDocument;
