import React from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import AddPropertyDocument from "./AddPropertyDocument";
import AddAgreement from "./AddAgreement";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updatePrivateData } from '../../../../Redux/AdminReducer/formsSlice';
import { useStyles, newIncomeTextfield, } from './PropertieStyle';


const AddPrivateNote = () => {
    const dispatch = useDispatch();
    const [open, setopen] = useState(false);
    const [backcom, setbackcom] = useState(false);
    const privateData = useSelector(state => state.form.privateData);
    const [localData, setLocalData] = useState(privateData);


    useEffect(() => {
        setLocalData(privateData);
    }, [privateData]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLocalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleBack = () => {
        dispatch(updatePrivateData(localData));
        setbackcom(true)
    }
    if (backcom) {
        return (
            <AddPropertyDocument />
        )
    }

    const handleNext = () => {
        dispatch(updatePrivateData(localData));
        setopen(true)
    }
    if (open) {
        return (
            <AddAgreement />
        )
    }
    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Add Property
                </Typography>
            </Box>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Private Note
                </Typography>
            </Box>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Write Private note for this property, it will not display in public.</Typography>
                    <TextField fullWidth multiline rows={5} variant="outlined" placeholder='Enter note here.'
                        name="note"
                        value={localData.note || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                {/* <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                    <Button style={{
                        border: "1px solid rgba(154, 25, 21, 1)",
                        color: "rgba(154, 25, 21, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"
                    }}
                        variant="outlined"
                        onClick={handleBack}

                    >Back</Button>
                    <Box sx={{ display: 'flex', }}>
                        <Button style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            margin: "20px 0px 50px 0px"

                        }}
                            variant="contained"
                            onClick={handleNext}
                        >Next</Button>
                    </Box>
                </Box> */}
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Box sx={{ marginTop: 'auto', display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                    <Button style={{
                        border: "1px solid rgba(154, 25, 21, 1)",
                        color: "rgba(154, 25, 21, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"
                    }}
                        variant="outlined"
                        onClick={handleBack}
                    >Back</Button>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            margin: "20px 0px 50px 15px"
                        }}
                            variant="contained"
                            onClick={handleNext}
                        >Next</Button>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

export default AddPrivateNote;


















// import React, { useState } from 'react';
// import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';


// const PropertyType = {
//   RESIDENTIAL: 'Residential',
//   COMMERCIAL: 'Commercial',
//   COMMUNITY: 'Community'
// };

// function PropertySelector() {
//   const [propertyType, setPropertyType] = useState('');
//   const [subType, setSubType] = useState('');

//   const handleTypeChange = (event) => {
//     const { value } = event.target;
//     setPropertyType(value);
//     // Reset subtype when main type changes
//     setSubType('');
//   };

//   const handleSubTypeChange = (event) => {
//     setSubType(event.target.value);
//   };

//   return (
//     <div>
//       <FormControl fullWidth>
      
//         <Select
//           value={propertyType}
//           onChange={handleTypeChange}
//           displayEmpty
//         >
//           <MenuItem value="" disabled>Select your Type</MenuItem>
//           <MenuItem value={PropertyType.RESIDENTIAL}>Residential</MenuItem>
//           <MenuItem value={PropertyType.COMMERCIAL}>Commercial</MenuItem>
//           <MenuItem value={PropertyType.COMMUNITY}>Community</MenuItem>
//         </Select>
//       </FormControl>

//       {propertyType === PropertyType.RESIDENTIAL && (
//         <FormControl fullWidth>
//           <Select
//             value={subType}
//             onChange={handleSubTypeChange}
//             displayEmpty
//           >
//             <MenuItem value="">Select Subtype</MenuItem>
//             <MenuItem value="Apartment">Apartment</MenuItem>
//             <MenuItem value="House">House</MenuItem>
//           </Select>
//         </FormControl>
//       )}

//       {propertyType === PropertyType.COMMERCIAL && (
//         <FormControl fullWidth>
//           <Select
//             value={subType}
//             onChange={handleSubTypeChange}
//             displayEmpty
//           >
//             <MenuItem value="">Select Subtype</MenuItem>
//             <MenuItem value="Office">Office</MenuItem>
//             <MenuItem value="Retail">Retail</MenuItem>
//             <MenuItem value="Industrial">Industrial</MenuItem>
//           </Select>
//         </FormControl>
//       )}
//     </div>
//   );
// }

// export default PropertySelector;