import React, { useState, useEffect } from "react";
import {
  tableHeadCell,
  tableBodyCell,
  CustomTextField,
  mainTypography,
  useStyles,
  aplyfilter,
  tableBodyCellRed,
} from "./MasterSheetStyles";
import {
  TextField,
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";







function MasterSheet() {
  const classes = useStyles();
  const [userData, setuserData] = useState([]);
  const [selectedValue, setSelectedValue] = useState({
    name: "",
  });





  const getUserListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(`${endpoints.getUserListing}?search=${selectedValue.name}&user_type=2`, requestOptions);
      const Data = response.data.data
      setuserData(Data);
    } catch (error) {

    }
  };


  const downloadMasterSheet = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
        responseType: 'blob'
      };
      const response = await axios.get(`${endpoints.AdminMasterSheet}?landlord_id=${id}`, requestOptions);

      const blob = response.data;
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Landlord_${id}_MasterSheet.xlsx`);
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };


  useEffect(() => {
    getUserListing();

  }, []);


  const [tableRows, setTableRows] = useState([
    { name: "Lanlord1", action: <SystemUpdateAltIcon /> },
    { name: "Lanlord2", action: <SystemUpdateAltIcon /> },
    { name: "Lanlord3", action: <SystemUpdateAltIcon /> },
    { name: "Lanlord4", action: <SystemUpdateAltIcon /> },
    { name: "Lanlord5", action: <SystemUpdateAltIcon /> },
    { name: "Lanlord6", action: <SystemUpdateAltIcon /> },
    { name: "Lanlord7", action: <SystemUpdateAltIcon /> },
  ]);



  const handleSortAscending = () => {
    const sortedRows = [...tableRows].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setTableRows(sortedRows);
  };

  const handleSortDescending = () => {
    const sortedRows = [...tableRows].sort((a, b) =>
      b.name.localeCompare(a.name)
    );
    setTableRows(sortedRows);
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleApplyFilter = () => {
    getUserListing();
  };

  

  return (
    <>
      <Box>
        <Typography sx={mainTypography}>Master Sheet</Typography>
      </Box>
      <Box className={classes.searchFieldBox}>
        <TextField
          variant="outlined"
          placeholder="Search by Landlord Name"
          name="name"
          value={selectedValue.name}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={CustomTextField}
        />
        <Button sx={aplyfilter} onClick={handleApplyFilter}>Apply Filter</Button>
      </Box>
      <Box marginTop={"2rem"}>
        <TableContainer sx={{ height: "65vh", overflowX: "auto" }}>
          <Table sx={{ border: "1px solid #EFEFEF" }} width={"70rem"}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeadCell}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        // marginRight: 1,
                      }}
                    >
                      <IconButton
                        onClick={handleSortAscending}
                        sx={{
                          padding: "0",
                          height: "8px",
                          minHeight: "8px",
                        }}
                      >
                        <ArrowDropUpIcon
                          sx={{ fontSize: "30px", color: "black" }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={handleSortDescending}
                        sx={{ padding: "0", height: "8px", minHeight: "8px" }}
                      >
                        <ArrowDropDownIcon
                          sx={{ fontSize: "30px", color: "black" }}
                        />
                      </IconButton>
                    </Box>
                    Landlord Name
                  </Box>
                </TableCell>
                <TableCell sx={tableHeadCell}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={tableBodyCell}>{item.first_name || "N/A"}</TableCell>
                  <TableCell sx={tableBodyCellRed} onClick={() => downloadMasterSheet(item.id)}>{<SystemUpdateAltOutlinedIcon style={{ color: "rgba(185, 16, 15, 1)" }} />}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default MasterSheet;
