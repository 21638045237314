import React from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import AddUdocument from "./AddUdocument";
import AddUagreement from './AddUagreement'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUnoteData } from '../../../../Redux/AdminReducer/unitSlice';
import { useStyles, newIncomeTextfield, } from './UnitStyle';


const AddUnote = () => {
    const dispatch = useDispatch();
    const [open, setopen] = useState(false);
    const [backcom, setbackcom] = useState(false);


    const unoteData = useSelector(state => state.unit.unoteData);
    const [localData, setLocalData] = useState(unoteData);


    useEffect(() => {
        setLocalData(unoteData);
    }, [unoteData]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLocalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleBack = () => {
        dispatch(updateUnoteData(localData));
        setbackcom(true)
    }
    if (backcom) {
        return (
            <AddUdocument />
        )
    }

    const handleNext = () => {
        dispatch(updateUnoteData(localData));
        setopen(true)
    }
    if (open) {
        return (
            <AddUagreement />
        )
    }
    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Add Unit
                </Typography>
            </Box>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 1)",
                    margin: "0px 0px 50px 0px"
                }}>
                    Private Note
                </Typography>
            </Box>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Write Private note for this property, it will not display in public.</Typography>
                    <TextField fullWidth multiline rows={5} variant="outlined" placeholder='Enter note here.'
                        name="note"
                        value={localData.note || ''}
                        onChange={handleInputChange}
                        sx={newIncomeTextfield}
                    />
                </Grid>
                {/* <Box sx={{ display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                    <Button style={{
                        border: "1px solid rgba(154, 25, 21, 1)",
                        color: "rgba(154, 25, 21, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"
                    }}
                        variant="outlined"
                        onClick={handleBack}

                    >Back</Button>
                    <Box sx={{ display: 'flex', }}>
                        <Button style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            margin: "20px 0px 50px 0px"

                        }}
                            variant="contained"
                            onClick={handleNext}
                        >Next</Button>
                    </Box>
                </Box> */}
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Box sx={{ marginTop: 'auto', display: 'flex', flexFlow: "row", justifyContent: 'space-between', width: '100%', }}>
                    <Button style={{
                        border: "1px solid rgba(154, 25, 21, 1)",
                        color: "rgba(154, 25, 21, 1)",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                        margin: "20px 0px 50px 15px"
                    }}
                        variant="outlined"
                        onClick={handleBack}
                    >Back</Button>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button style={{
                            backgroundColor: "rgba(184, 25, 24, 1)",
                            fontSize: "14px",
                            lineHeight: "16px",
                            fontWeight: 500,
                            textTransform: "none",
                            width: "130px",
                            height: "40px",
                            margin: "20px 0px 50px 15px"
                        }}
                            variant="contained"
                            onClick={handleNext}
                        >Next</Button>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

export default AddUnote;