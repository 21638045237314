import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useStyles,
  textField,
  decsriptionTextField,
  textFieldTypography,
  submitBtnTypo,
} from "./MaintenanceStyles";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ComplaintsHistory from "./ComplaintsHistory";





const ComplaintForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isComplaintSubmit, setIsComplaintSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const[emailError,setEmailError] = useState('');
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNum: "",
    email: "",
    adress: "",
    unitNumber: "",
    description: "",
  });
  const [units, setUnits] = useState(null);
  const [tenantData, setTenantData] = useState({});

  const handleFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    if (fieldName === "email") {
      if (!validateEmail(value)) {
        setEmailError("Please enter a valid email address (e.g., example@domain.com).");
      } else {
        setEmailError(""); // Clear the error if valid
      }
    }

    // Auto-populate address when a unit is selected
    if (fieldName === "unitNumber") {
      const selectedUnit = units.find(unit => unit.id === value);
      if (selectedUnit) {
        setFormData((prevData) => ({
          ...prevData,
          adress: selectedUnit.address, // Assuming the unit object has an 'address' field
        }));
      }
    }
  };


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getUnitsListing = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const response = await axios.get(
        endpoints.tenantUnitListing,
        requestOptions
      );
      setUnits(response.data.data);
      
      if (response.data.data.length > 0) {
        const tenant = response.data.data[0].tenant_association[0].tenant;
        setFormData((prevData) => ({
          ...prevData,
          email: tenant.email,
          firstName: tenant.first_name,
          lastName: tenant.last_name,
          phoneNum: tenant.phone,
        }));
      }
    } catch { }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      setEmailError("Please enter a valid email address (e.g., example@domain.com).");
      return; // Prevent submission if the email is invalid
    }
    
    try {
      const payload = {
        first_name: formData.firstName,
        // last_name: formData.lastName,
        address: formData.adress,
        email: formData.email,
        description: formData.description,
        unit: formData.unitNumber,
        // phone: formData.phoneNum,
      };
      if (formData.lastName) {
        payload.last_name = formData.lastName;
      }
      if (formData.phoneNum) {
        payload.phone = formData.phoneNum;
      }

      const token = localStorage.getItem("token");
      const requestOptions = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      setLoading(true);
      const response = await axios.post(
        endpoints.complaintRequests,
        payload,
        requestOptions
      );
      toast.success(response.data.message);
      // navigate("/tenant/maintenance");
      navigate("/tenant/maintenance", { state: { navigateTo: "Complaints" } });
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUnitsListing();
  }, []);

  return (
    <>
      <Box>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: "500",
          }}
        >
          Complaint Form
        </Typography>
      </Box>

      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <form onSubmit={handleSubmit}>
        <Grid
          container
          border={"1px solid #EFEFEF"}
          borderRadius={"1rem"}
          marginTop={"2rem"}
          // padding={"1rem"}
          spacing={2}
        >
          {/* First Name */}
          <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Name</Typography>
            </Box>
            <TextField
              sx={textField}
              value={formData.firstName}
              onChange={handleFieldChange("firstName")}
              fullWidth
              
            />
          </Grid>
          {/* Last Name */}
          {/* <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Last Name</Typography>
            </Box>
            <TextField
              sx={textField}
              value={formData.lastName}
              onChange={handleFieldChange("lastName")}
              fullWidth
            />
          </Grid> */}
          {/* Phone Number */}
          <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Phone Number</Typography>
            </Box>
            <TextField
              sx={textField}
              value={formData.phoneNum}
              onChange={handleFieldChange("phoneNum")}
              fullWidth
            />
          </Grid>
          {/* Email */}
          <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Email</Typography>
            </Box>
            <TextField
              type="email"
              sx={textField}
              name='email'
              value={formData.email}
              onChange={handleFieldChange("email")}
              fullWidth
            
              error={!!emailError} // Show error state
              helperText={emailError} // Display error message

            />
          </Grid>
          {/* Full Address  */}
          <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Full Address</Typography>
            </Box>
            <TextField
              sx={textField}
              value={formData.adress}
              onChange={handleFieldChange("adress")}
              fullWidth
              
            />
          </Grid>

          {/* Unit Number */}
          <Grid item xs={12} sm={6} md={5}>
            <Box>
              <Typography sx={textFieldTypography}>Unit Number</Typography>
            </Box>

            <FormControl fullWidth sx={{ ...textField }}>
              {/* <InputLabel id="unitNumber-label">Unit Number</InputLabel> */}
              <Select
                // labelId="unitNumber-label"
                // id="unitNumber"
                value={formData.unitNumber}
                onChange={handleFieldChange("unitNumber")}
                fullWidth
                // label="Unit Number"
              
                displayEmpty
              >
                <MenuItem value="">Select unit</MenuItem>
                {units?.map((unit) => (
                  <MenuItem value={unit.id} key={unit.id}>
                    {`${unit.ref_id} - ${unit.title}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Request Description */}
          <Grid item xs={12} sm={12} md={10}>
            <Box>
              <Typography sx={textFieldTypography}>
                Description of Request
              </Typography>
            </Box>
            <TextField
              sx={textField}
              value={formData.description}
              onChange={handleFieldChange("description")}
              fullWidth
              multiline
              rows={5}
            
            />
          </Grid>
          {/* Submit Button */}
          <Grid item xs={12} sm={12} md={10}>
            <Button sx={submitBtnTypo} type="submit" disabled={loading} >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default ComplaintForm;