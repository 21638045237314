import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Modal,
  FormControl,
} from "@mui/material";
import { newIncomeTextfield } from "./ContractStyle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import toast from "react-hot-toast";
import RenewContract from "./RenewContract";
import { useLocation } from "react-router-dom";
import { UserTypesNum } from "../../../enums/UserTypes";

const EditRenewForm = () => {
  const location = useLocation();
  const { renewContract } = location.state || {};
  const [isRenewalNoticeSend, setIsRenewalNoticeSend] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfLetter, setPdfLetter] = useState(null);
  const [contractId, setContractId] = useState(null);
  const [pdfError, setPdfError] = useState(null);
  const [tenantName, setTenantName] = useState(renewContract?.tenant || null);

  const [tenantId, setTenantId] = useState(renewContract?.tenant.id);
  const handleTenantIdChange = (e) => {
    setTenantId(e.target.value);
    const tenantUpdate = tenantList.filter((value, index) => {
      return e.target.value == value.id;
    });
    setTenantName(tenantUpdate[0]);
  };



  const [tenantInfo, setTenantInfo] = useState({
    // tenantName: null,
    phone: renewContract?.tenant.phone || null,
    unifiedNo: renewContract?.tenant_uid || null,
    emergencyContact: renewContract?.tenant_emergency_contact || null,
    eidNo: renewContract?.tenant_emirates_id || null,
    email: renewContract?.tenant.email || null,
  });

  const handleTenantInfoChange = (event) => {
    const { name, value } = event.target;
    setTenantInfo({
      ...tenantInfo,
      [name]: value,
    });
  };

  const [propertyInfo, setPropertyInfo] = useState({
    property: renewContract?.property.id || null,
    unitUsage: renewContract?.unit_usage || null,
    premiseNo: renewContract?.premise_number || null,
    tawtheeqNo: renewContract?.tawtheeq_number || null,
  });
  const handlePropertyInfoChange = (event) => {
    const { name, value } = event.target;
    setPropertyInfo({
      ...propertyInfo,
      [name]: value,
    });
  };

  const [renewalInfo, setRenewalInfo] = useState({
    renewalDate: renewContract?.renewal_start_date || null,
    expiryDate: renewContract?.renewal_end_date || null,
    noOfpayments: renewContract?.no_of_payments || null,
    rentalValue: renewContract?.rent_value || null,
    securityDeposit: renewContract?.security_deposit || null,
    paymentType: renewContract?.payment_type || null,
    durantion: renewContract?.renewal_period || null,
    accountNo: renewContract?.addc_account_no || null,
    tawtheeqFees: renewContract?.tawtheeq_fees || null,
    otherFees: renewContract?.other_fees || null,
  });

  const handleRenewalInfoChange = (event) => {
    const { name, value } = event.target;
    setRenewalInfo({
      ...renewalInfo,
      [name]: value,
    });
  };
  const unitIds = renewContract?.unit.map((unit) => unit.id);

  const [units, setUnits] = useState(unitIds);

  const addUnitField = () => {
    setUnits([...units, ""]);
  };

  const handleUnitChange = (index, event) => {
    const newUnits = [...units];
    newUnits[index] = event.target.value;
    setUnits(newUnits);
  };

  const getAvailableUnits = (selectedUnits) => {
    return unitList?.filter((unit) => !selectedUnits.includes(unit.id));
  };

  const [tenantList, setTenantList] = useState([]);
  const [propertyList, setPropertyList] = useState(null);
  const [unitList, setUnitList] = useState(null);

  const listingData = async (url, setter) => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(url, requestOptions);
      setter(response.data.data);
    } catch (error) { }
  };

  const getTenantsList = () => {
    listingData(
      `${endpoints.getUserListing}?user_type=${UserTypesNum.tenant}`,
      setTenantList
    );
  };

  const getPropertyList = () => {
    listingData(endpoints.Propertylist, setPropertyList);
  };
  const getUnitsList = () => {
    listingData(endpoints.Unitlist, setUnitList);
  };



  useEffect(() => {
    getTenantsList();
    getPropertyList();
    getUnitsList();
  }, []);

  useEffect(() => {
    setTenantInfo({
      phone: tenantName?.phone,
      eidNo: tenantName?.emirates_id,
      email: tenantName?.email,
    });
  }, [tenantName]);

  const updateTenantContract = async (e) => {
    e.preventDefault();
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const payload = {
        tenant: tenantName.id,
        tenant_emergency_contact: tenantInfo.emergencyContact,
        tenant_uid: tenantInfo.unifiedNo,
        property: propertyInfo.property,
        unit_usage: propertyInfo.unitUsage,
        premise_number: propertyInfo.premiseNo,
        tawtheeq_number: propertyInfo.tawtheeqNo,
        unit: units,
        renewal_start_date: renewalInfo.renewalDate,
        renewal_end_date: renewalInfo.expiryDate,
        no_of_payments: renewalInfo.noOfpayments,
        rent_value: renewalInfo.rentalValue,
        renewal_period: renewalInfo.durantion,
        security_deposit: renewalInfo.securityDeposit,
        payment_type: renewalInfo.paymentType,
        addc_account_no: renewalInfo.accountNo,
        tawtheeq_fees: renewalInfo.tawtheeqFees,
        other_fees: renewalInfo.otherFees,
      };
      const response = await axios.patch(
        `${endpoints.tenancyContractsRenewal}?contract_id=${renewContract?.id}`,
        payload,
        requestOptions
      );
      setPdfLetter(response.data.data[0].document);
      setContractId(response.data.data[0].id);
      setIsRenewalNoticeSend(true);
      // setIsModalOpen(true);
      // console.log("this is response", response);
    } catch { }
  };

  const sendContract = async (e) => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.post(
        `${endpoints.tenancyContractsRenewal}send/?contract_id=${contractId}`,
        { contract_id: contractId },
        requestOptions
      );
      toast.success(response.data.message);
      handleModalClose();
      setIsRenewalNoticeSend(true);
    } catch { }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePdfLoadError = (error) => {
    setPdfError(error);
  };

  

  return (
    <>
      {!isRenewalNoticeSend ? (
        <>
          <Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              Renewal Form
            </Typography>
          </Box>
          <form onSubmit={updateTenantContract}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              // justifyContent="center"
              mt={5}
            >
              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "15px",
                    margin: "20px 0px 20px 0px",
                  }}
                >
                  TENANT/ COMPANY INFORMATION
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  TENANT NAME:
                </Typography>
                <FormControl fullWidth sx={newIncomeTextfield}>
                  <Select
                    fullWidth
                    variant="outlined"
                    // name={"tenantName"}
                    // value={tenantName || ""}
                    //   onChange={handleTenantInfoChange}
                    value={tenantId || ""}
                    onChange={(e) => {
                      handleTenantIdChange(e);
                    }}
                    // onChange={(e) => {
                    //   setTenantName(e.target.value);
                    // }}
                    displayEmpty
                  // sx={newIncomeTextfield}
                  >
                    <MenuItem value="">Select Tenant Name</MenuItem>
                    {tenantList?.map((tenant) => (
                      <MenuItem value={tenant.id} key={tenant.id}>
                        {tenant.first_name}
                      </MenuItem>
                    ))}

                    {/* Add more options as needed */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  PHONE NUMBER:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter Phone no"
                  name={"phone"}
                  value={tenantInfo.phone}
                  onChange={handleTenantInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  UNIFIED NO.:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="unified no"
                  name={"unifiedNo"}
                  value={tenantInfo.unifiedNo}
                  onChange={handleTenantInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  EMERG. CONT. NO.{" "}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter emerg No"
                  name={"emergencyContact"}
                  value={tenantInfo.emergencyContact}
                  onChange={handleTenantInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  EID NUMBER:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter eid number"
                  name={"eidNo"}
                  value={tenantInfo.eidNo}
                  onChange={handleTenantInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  EMAIL ADDRESS:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter email address "
                  name={"email"}
                  value={tenantInfo.email}
                  onChange={handleTenantInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "15px",
                    margin: "20px 0px 20px 0px",
                  }}
                >
                  THE PROPERTY
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  Select Property:
                </Typography>
                <FormControl fullWidth sx={newIncomeTextfield}>
                  <Select
                    fullWidth
                    variant="outlined"
                    name={"property"}
                    value={propertyInfo.property || ""}
                    onChange={handlePropertyInfoChange}
                    displayEmpty
                    sx={newIncomeTextfield}
                  >
                    <MenuItem value="">Select Property</MenuItem>
                    {propertyList?.map((property) => (
                      <MenuItem value={property.id} key={property.id}>
                        {`${property.title} - ${property.title}`}
                      </MenuItem>
                    ))}

                    {/* Add more options as needed */}
                  </Select>
                </FormControl>
              </Grid>
            
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  UNIT USAGE:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="enter usage unit"
                  name={"unitUsage"}
                  value={propertyInfo.unitUsage}
                  onChange={handlePropertyInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  PREMISE NO.:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter Premise No"
                  name={"premiseNo"}
                  value={propertyInfo.premiseNo}
                  onChange={handlePropertyInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  TAWTHEEQ NO.:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter tawtheeq No"
                  name={"tawtheeqNo"}
                  value={propertyInfo.tawtheeqNo}
                  onChange={handlePropertyInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
             
              {units.map((unit, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontWeight: 300,
                      fontSize: "15px",
                      lineHeight: "15px",
                      margin: "0px 0px 15px 0px",
                    }}
                  >
                    UNIT NO:
                  </Typography>
                  <FormControl fullWidth sx={newIncomeTextfield}>
                    <Select
                      fullWidth
                      variant="outlined"
                      value={unit}
                      onChange={(e) => handleUnitChange(index, e)}
                      displayEmpty
                      sx={newIncomeTextfield}
                    >
                      <MenuItem value="" disabled>
                        Select unit No
                      </MenuItem>
                      {unitList?.map((unit) => (
                        <MenuItem value={unit.id} key={unit.id}>
                          {`${unit.ref_id} - ${unit.title}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ))}
              <Grid item xs={12} md={12}>
                <IconButton sx={{ color: "#B81918" }} onClick={addUnitField}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "15px",
                    margin: "20px 0px 20px 0px",
                  }}
                >
                  RENEWAL
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  RENEWAL DATE:
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  variant="outlined"
                  placeholder="Enter renew Date"
                  name={"renewalDate"}
                  value={renewalInfo.renewalDate}
                  onChange={handleRenewalInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  EXPIRY DATE:
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  variant="outlined"
                  placeholder="Enter Expiry Date"
                  name={"expiryDate"}
                  value={renewalInfo.expiryDate}
                  onChange={handleRenewalInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  NO. OF PAYEMENTS:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter No of Payments"
                  name={"noOfpayments"}
                  value={renewalInfo.noOfpayments}
                  onChange={handleRenewalInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  RENTAL AMOUNT:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter rental amount"
                  name={"rentalValue"}
                  value={renewalInfo.rentalValue}
                  onChange={handleRenewalInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  SECURITY DEPOSIT:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter Security deposit"
                  name={"securityDeposit"}
                  value={renewalInfo.securityDeposit}
                  onChange={handleRenewalInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  PAYMENT TYPE:
                </Typography>
                <Select
                  fullWidth
                  variant="outlined"
                  name="paymentType"
                  value={renewalInfo.paymentType || ""}
                  onChange={handleRenewalInfoChange}
                  displayEmpty
                  sx={newIncomeTextfield}
                >
                  <MenuItem value="" disabled>
                    Select Payment Type
                  </MenuItem>
                  <MenuItem value="Cheque">Cheque</MenuItem>
                  <MenuItem value="Online Transfer">Online Transfer</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  RENEWAL DURATION:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter Duration"
                  name="durantion"
                  value={renewalInfo.durantion}
                  onChange={handleRenewalInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  ADDC ACCOUNT NO.:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter Addc account No"
                  name="accountNo"
                  value={renewalInfo.accountNo}
                  onChange={handleRenewalInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  TAWTHEEQ FEES:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter tawtheeq fees"
                  name="tawtheeqFees"
                  value={renewalInfo.tawtheeqFees}
                  onChange={handleRenewalInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 300,
                    fontSize: "15px",
                    lineHeight: "15px",
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  OTHER FEES:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter Other Fees"
                  name="otherFees"
                  value={renewalInfo.otherFees}
                  onChange={handleRenewalInfoChange}
                  sx={newIncomeTextfield}
                />
              </Grid>
            </Grid>
            <Box>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "200px",
                  height: "40px",
                  margin: "20px 0px 50px 0px",
                }}
                variant="contained"
                type="submit"
              >
                Update Renewal Contract
              </Button>
            </Box>
          </form>
          <Modal
            open={isModalOpen}
            onClose={handleModalClose}
            aria-labelledby="pdf-viewer-modal"
            aria-describedby="pdf-viewer-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "60%",
                height: "100%",
                bgcolor: "white",
                boxShadow: 18,
                p: 4,
                borderRadius: "8px",
                overflow: "auto",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                {pdfError ? (
                  <div>Error loading PDF: {pdfError.message}</div>
                ) : (
                  <Viewer fileUrl={pdfLetter} onError={handlePdfLoadError} />
                )}
              </Worker>
              <Box
                display={"flex"}
                marginBottom={"0.5rem"}
                marginTop={"0.5rem"}
                gap={"10px"}
              >
                <Button
                  sx={{
                    backgroundColor: "rgba(184, 25, 24, 1)",
                    color: "white",
                    fontSize: "12px",
                    lineHeight: "12px",
                    fontWeight: 500,
                    textTransform: "none",
                    width: "8rem",
                    height: "2rem",
                    borderRadius: "0.5rem",
                  }}
                  onClick={sendContract}
                >
                  Send Contract
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <RenewContract />
      )}
    </>
  );
};

export default EditRenewForm;
