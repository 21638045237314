import {
  Box,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
} from "@mui/material";

import React, { useState } from "react";
import {
  useStyles,
  feedBackHeading,
  feedBackTxt,
  feedBackItem,
  feedBackDescriptionField,
  feedBackSubmitBtn,
} from "./MaintenanceStyles";
import { useDispatch, useSelector } from "react-redux";
// import { updateFeedBack } from "../../../Redux/TenantReducer/maintenanceSlice";
import { styled } from "@mui/system";
import { updateFeedBack } from "../../../Redux/TenantReducer/complaintFeedbackSlice";

function FeedBack() {
  const dispatch = useDispatch();
  const feedBackData = useSelector((state) => state.complaintFeedback.feedBack);
  const classes = useStyles();
  const [value, setValue] = useState({
    set1: "",
    set2: "",
    set3: "",
    set4: "",
    set5: "",
  });
  const [description, setDescription] = useState("");

  const ratings = [
    { label: "Excellent", color: "primary" },
    { label: "Good", color: "secondary" },
    { label: "Average", color: "success" },
    { label: "Poor", color: "error" },
  ];

  const handleChange = (setName) => (event) => {
    setValue({ ...value, [setName]: event.target.value });
  };

  const handleTextChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = () => {
    console.log("this is values of radio buttons", value);
    console.log("Description", description);
    dispatch(updateFeedBack({ ...value, description }));
    console.log(feedBackData, "Separate Slice");
  };

  const CustomRadio = styled(Radio)(({ theme }) => ({
    "& .MuiSvgIcon-root": {
      color: "blue", // Customize color when unchecked
    },
    "& .MuiSvgIcon-root::before": {
      borderColor: "blue", // Customize border color for unchecked state
    },
    "&.Mui-checked .MuiSvgIcon-root": {
      color: "blue", // Customize color when checked
    },
  }));
  return (
    <>
      <Box>
        <Typography sx={feedBackHeading}>Feedback</Typography>
        <Typography sx={feedBackTxt}>Your Feedback Matters!</Typography>
      </Box>
      <Grid className={classes.mainGrid}>
        <Grid item>
          {/* <Box className={classes.ratingBox}>
            {ratings.map((item, index) => (
              <Typography key={index}>{item.label}</Typography>
            ))}
          </Box> */}
          <Grid item>
            <Box
              className={classes.infoBox}
              height={{ xs: "10rem", sm: "5rem" }}
            >
              <Box width={"35%"}>
                <Typography sx={feedBackItem}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                </Typography>
              </Box>
              <Box width={"60%"} className={classes.radionBtnsBox}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="rating"
                    name="rating"
                    value={value.set1}
                    onChange={handleChange("set1")}
                  >
                    <Box>
                      {ratings.map((rating, index) => (
                        <FormControlLabel
                          key={index}
                          value={rating.label}
                          control={<Radio color={rating.color} />}
                          label={rating.label}
                          sx={feedBackItem}
                        />
                      ))}
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item>
            <Box
              className={classes.infoBox}
              height={{ xs: "10rem", sm: "5rem" }}
            >
              <Box width={"35%"}>
                <Typography sx={feedBackItem}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                </Typography>
              </Box>
              <Box width={"60%"} className={classes.radionBtnsBox}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="rating"
                    name="rating"
                    value={value.set2}
                    onChange={handleChange("set2")}
                  >
                    <Box>
                      {ratings.map((rating, index) => (
                        <FormControlLabel
                          key={index}
                          value={rating.label}
                          control={<Radio color={rating.color} />}
                          label={rating.label}
                          sx={feedBackItem}
                        />
                      ))}
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box
              className={classes.infoBox}
              height={{ xs: "10rem", sm: "5rem" }}
            >
              <Box width={"35%"}>
                <Typography sx={feedBackItem}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                </Typography>
              </Box>
              <Box width={"60%"} className={classes.radionBtnsBox}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="rating"
                    name="rating"
                    value={value.set3}
                    onChange={handleChange("set3")}
                  >
                    <Box>
                      {ratings.map((rating, index) => (
                        <FormControlLabel
                          key={index}
                          value={rating.label}
                          control={<Radio color={rating.color} />}
                          label={rating.label}
                          sx={feedBackItem}
                        />
                      ))}
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box
              className={classes.infoBox}
              height={{ xs: "10rem", sm: "5rem" }}
            >
              <Box width={"35%"}>
                <Typography sx={feedBackItem}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                </Typography>
              </Box>
              <Box width={"60%"} className={classes.radionBtnsBox}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="rating"
                    name="rating"
                    value={value.set4}
                    onChange={handleChange("set4")}
                  >
                    <Box>
                      {ratings.map((rating, index) => (
                        <FormControlLabel
                          key={index}
                          value={rating.label}
                          control={<Radio color={rating.color} />}
                          label={rating.label}
                          sx={feedBackItem}
                        />
                      ))}
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box
              className={classes.infoBox}
              height={{ xs: "10rem", sm: "5rem" }}
            >
              <Box width={"35%"}>
                <Typography sx={feedBackItem}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                </Typography>
              </Box>
              <Box width={"60%"} className={classes.radionBtnsBox}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="rating"
                    name="rating"
                    value={value.set5}
                    onChange={handleChange("set5")}
                  >
                    <Box>
                      {ratings.map((rating, index) => (
                        <FormControlLabel
                          key={index}
                          value={rating.label}
                          control={<Radio color={rating.color} />}
                          label={rating.label}
                          sx={feedBackItem}
                        />
                      ))}
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Grid> */}
          <Grid item>
            <Box className={classes.feedBackDescriptionBox}>
              <TextField
                sx={feedBackDescriptionField}
                value={description}
                onChange={handleTextChange}
                label="Additional feedback"
                fullWidth
              />
            </Box>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <Button variant="contained" sx={feedBackSubmitBtn} >

            </Button> */}
            <Button style={{
              backgroundColor: "rgba(184, 25, 24, 1)",
              fontFamily: "Outfit",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "16px",
              textTransform: "none",
              width: "11rem",
              height: "2rem",

            }}
              variant="contained"
              onClick={handleSubmit}
            > Submit Feedback</Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default FeedBack;
