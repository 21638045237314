import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, TextField, Button, } from "@mui/material";
import { useStyles, ImgsBox, } from "./MaintenanceStyle";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import { updateRequestsenums, statusMap } from '../../../enums/UserTypes';





const images = [
    "/image/ktchn1 (1).png",
    "/image/ktchn1 (2).png",
    "/image/ktchn1 (3).png",
    "/image/ktchn1 (4).png",
    "/image/ktchn1 (3).png",
];

const MaintenanceReport = () => {
    const classes = useStyles();
    const [maintenancereport, setmaintenancereport] = useState({});

    const location = useLocation();
    const { state } = location;
    const { id } = state || {};




    const getMaintenance = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoints.landlordmaintenance}?id=${id}`, {
                headers: { Authorization: `Token ${token}` }
            });
            setmaintenancereport(response.data.data[0] || {});;
        } catch (error) {
        }
    };

    useEffect(() => {
        getMaintenance();
    }, [])

   


    const getStatusText = (status) => {
        return updateRequestsenums[status] || "Unknown Status";
    };

    const getmaintenance_status = (maintenance_status) => {
        return statusMap[maintenance_status] || "Unknown Status";
    }


    return (
        <>
            <Box>
                <Typography style={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "#000000"
                }}>
                    Maintenance Reports
                </Typography>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "50px 0px 15px 0px"
                        }}
                    >Full Name</Typography>
                    <input className={classes.input} fullWidth variant="outlined" placeholder='Michael Rayan'
                        value={maintenancereport?.name}
                        size="small"
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={4} >
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "50px 0px 15px 0px"
                        }}
                    >Status</Typography>
                    <Box style={{ border: "1px solid #9A1915", borderRadius: "8px" }}>
                        <Typography
                            style={{
                                fontFamily: "Outfit",
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "16px",
                                color: "#B81918",
                                padding: "10px 10px 10px 10px"
                            }}
                        >
                           {getmaintenance_status(maintenancereport?.maintenance_status|| "Loading...")}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Email</Typography>
                    <input className={classes.input} fullWidth variant="outlined" placeholder='Michaelrayan@capstoneuae.com'
                        value={maintenancereport?.email}
                        size="small"
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Unit Number</Typography>
                    <input className={classes.input} fullWidth variant="outlined" placeholder='305'
                        value={maintenancereport?.unit}
                        size="small"
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Full Address</Typography>
                    <input className={classes.input} fullWidth variant="outlined" placeholder='305 apartment uae'
                        value={maintenancereport?.address}
                        size="small"
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Urgency</Typography>
                    <input className={classes.input} fullWidth variant="outlined" placeholder='High'
                        value={getStatusText(maintenancereport?.priority || "Loading...")}
                        size="small"
                        style={{
                            margin: "0px 0px 25px 0px",
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "0px 0px 15px 0px"
                        }}
                    >Images/Video</Typography>
                    <ImgsBox>
                        <img src={maintenancereport?.media} alt="ktchn" style={{ width: "7rem" }} />
                    </ImgsBox>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        style={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "rgba(0, 0, 0, 1)",
                            margin: "25px 0px 15px 0px"
                        }}
                    >Description of Request</Typography>
                    <Box className={classes.box}>
                        <Box>
                            <Typography style={{
                                fontFamily: "Outfit",
                                fontSize: "15px",
                                lineHeight: "50px",
                                fontWeight: 400,
                                color: "#737679"
                            }}>
                               {maintenancereport?.description || "Loading..."}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

        </>
    )
}

export default MaintenanceReport;