import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import { useLocation } from "react-router-dom";
import TenantsUnit from "./TenantsUnit";
import UnitDocuments from "./UnitDocuments";
import UnitPendingAction from "./UnitPendingAction";
import UnitMasterSheet from "./UnitMasterSheet";
import UnitComplain from "./UnitComplain";
import axios from "axios";
import { endpoints } from "../../../../apiEndpoints";
import UnitCoOwners from "./unitCoOwners";

const AllUnitDetail = () => {
  const location = useLocation();
  const { unit } = location.state;

  const [unitDetails, setUnitDetails] = useState(null);
  const [open, setopen] = useState(false);
  const [Document, setDocument] = useState(false);
  const [Action, setAction] = useState(false);
  const [master, setmaster] = useState(false);
  const [complain, setcomplain] = useState(false);
  const [coOwners, setCoOwners] = useState(false);

  const getUnitDetail = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        `${endpoints.landlordUnitDetails}?id=${unit.id}`,
        requestOptions
      );
      setUnitDetails(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getUnitDetail();
  }, []);

  const handleopen = () => {
    setopen(true);
  };
  if (open) {
    return <TenantsUnit details={unitDetails} unit={unit} />;
  }

  const handleDocument = () => {
    setDocument(true);
  };
  if (Document) {
    return <UnitDocuments details={unitDetails} unit={unit} />;
  }

  const handleAction = () => {
    setAction(true);
  };
  if (Action) {
    return <UnitPendingAction details={unitDetails} unit={unit} />;
  }

  const handlemaster = () => {
    setmaster(true);
  };
  if (master) {
    return <UnitMasterSheet />;
  }
  const handleComplain = () => {
    setcomplain(true);
  };
  if (complain) {
    return <UnitComplain details={unitDetails} />;
  }

  const handleCoOwners = () => {
    setCoOwners(true);
  };
  if (coOwners) {
    return <UnitCoOwners unit={unit} details={unitDetails} />;
  }

  return (
    <>
      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        Unit Details
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12} md={6} alignItems="center">
          <Box>
            <Box sx={{ display: "flex" }}>
              <img
                src={
                  unit?.attachments.length > 0
                    ? unit?.attachments[0]?.media
                    : "No Image Available"
                }
                onError={(e) => (e.target.src = "/image/propertyicon.png")}
                alt="this image is not show"
                width={450}
                height={250}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "35px",
              margin: "40px 0px 0px 0px",
            }}
          >
            {unit?.title || "Title not available"}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              margin: "10px 0px 0px 0px",
            }}
          >
            {unit?.description || "Description not available"}
          </Typography>
        </Grid>
      </Grid>

      <Box style={{ margin: "25px 0px 0px 0px" }}>
        <Divider />
      </Box>

      <Typography
        style={{
          font: "Raleway",
          fontSize: "24px",
          lineHeight: "24px",
          fontWeight: 700,
          color: "rgba(0, 0, 0, 1)",
          margin: "25px 0px 0px 0px",
        }}
      >
        Details
      </Typography>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Tenants
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handleopen}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Documents
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handleDocument}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Tenant Compaints
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handleComplain}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Pending Actions
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handleAction}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Co-Owner
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handleCoOwners}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={4} mb={4}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
                margin: "5px 0px 0px 0px",
              }}
            >
              Master Sheets
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  backgroundColor: "rgba(184, 25, 24, 1)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "92px",
                  height: "35px",
                }}
                variant="contained"
                onClick={handlemaster}
              >
                View
              </Button>
            </Box>
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
};

export default AllUnitDetail;
