import React from "react";
import { useEffect, useState } from "react";
import { Grid, Container, Box } from "@mui/material";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useStyles } from "./DashboardStyle";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import toast from "react-hot-toast";
import DateFormatter from "../../../utils/DateFormatter";

// Tabdata data

const Tabdata = [
  {
    id: 1,
    image: "/image/1name.jpg",
    label: "Pending Actions Show here",
    time: "15 minutes ago",
  },
  {
    id: 2,
    image: "/image/2name.jpg",
    label: "Pending Actions Show here",
    time: "15 minutes ago",
  },
  {
    id: 3,
    image: "/image/2name.jpg",
    label: "Pending Actions Show here",
    time: "15 minutes ago",
  },
  {
    id: 4,
    image: "/image/1name.jpg",
    label: "Pending Actions Show here",
    time: "15 minutes ago",
  },
  {
    id: 5,
    image: "/image/2name.jpg",
    label: "Pending Actions Show here",
    time: "15 minutes ago",
  },
];

const Tab = () => {
  const classes = useStyles();
  const [pendingaction, setpendingaction] = useState(null);
  const [Activities, setActivities] = useState(null);
  const navigate = useNavigate();

  const handleaction = (Value) => {
    navigate(`/admin/notification?value=${Value}`);
  };

  const getPendingAction = async () => {
    try {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("id");
      const response = await axios.get(
        `${endpoints.getPendingActions}?user_type=1&user_id=${id}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const data = response.data.data;
      setpendingaction(data);
    } catch (error) {
     
    }
  };

  const getActivities = async () => {
    try {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("id");
      const response = await axios.get(
        `${endpoints.AuditLogs}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const data = response.data.data;
      setActivities(data);
    } catch (error) {
     
    }
  };

  useEffect(() => {
    getPendingAction();
    getActivities();
  }, []);

  return (
    <>
      <Grid container spacing={4} mb={5}>
        <Grid item xs={12} sm={7} md={7}>
          <Box className={classes.TabstyleS}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "19.32px",
                  lineHeight: "28.98px",
                  fontWeight: 600,
                  color: "#101828",
                  margin: "23.5px 0px 0px 0px",
                }}
              >
                Pending Actions
              </Typography>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "13.52px",
                  lineHeight: "23.18px",
                  fontWeight: 400,
                  color: "#060606",
                  margin: "23.5px 20px 0px 0px",
                  cursor:'pointer'
                }}
                onClick={()=>{handleaction(1)}}
              >
                See All
              </Typography>
            </Box>
            {pendingaction?.slice(0, 6).map((item, index) => (
              <Box
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #EAECF0",
                  margin: "15px 0px 0px 0px",
                  borderRadius: "5.8px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  className={classes.our}
                >
                  <Avatar alt="Remy Sharp" src="/image/userAvatar.jpg" />
                  <Typography
                    style={{
                      color: "#060606",
                      fontFamily: "Outfit",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: 400,
                      margin: "0px 0px 0px 10px",
                    }}
                  >
                    {item?.action || "Content not available"}
                    <Typography
                      style={{
                        color: "rgba(29, 41, 57, 0.52)",
                        fontFamily: "Outfit",
                        fontSize: "11px",
                        lineHeight: "22px",
                        fontWeight: 400,
                      }}
                    >
                      {DateFormatter(item?.created_at)}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>

        {/* usedByDevices  */}
        <Grid item xs={12} sm={5} md={5}>
          <Box className={classes.Tabstyle}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "19.32px",
                  lineHeight: "28.98px",
                  fontWeight: 600,
                  color: "#101828",
                  margin: "23.5px 0px 0px 0px",
                  
                }}
              >
                Recent Activities
              </Typography>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "13.52px",
                  lineHeight: "23.18px",
                  fontWeight: 400,
                  color: "#060606",
                  margin: "23.5px 20px 0px 0px",
                  cursor:'pointer',
                }}
                onClick={()=>{handleaction(2)}}
              >
                See All
              </Typography>
            </Box>
            {Activities?.slice(0, 4).map((item, index) => (
              <Box
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #EAECF0",
                  margin: "15px 0px 0px 0px",
                  borderRadius: "5.8px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  className={classes.our}
                >
                  <Avatar alt="Remy Sharp" src="/image/userAvatar.jpg" />
                  <Typography
                    style={{
                      color: "rgba(26, 31, 54, 1)",
                      fontFamily: "Outfit",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: 600,
                      margin: "0px 0px 0px 10px",
                    }}
                  >
                    {item.created_by?.user_type}
                    <Typography
                      style={{
                        color: "#060606",
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        lineHeight: "22px",
                        fontWeight: 400,
                        margin: "0px 0px 0px 0px",
                      }}
                    >
                      {item?.action || "Content not available"}
                      <Typography
                        style={{
                          color: "rgba(29, 41, 57, 0.52)",
                          fontFamily: "Outfit",
                          fontSize: "11px",
                          lineHeight: "22px",
                          fontWeight: 400,
                        }}
                      >
                        {DateFormatter(item?.created_at)}
                      </Typography>
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Tab;
