import { Box, Grid, Typography, Paper, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  barBox,
  boxHeading,
  boxSubHeading,
  calendarTypography,
  gridItemNumbers,
  gridItemText,
  mainTypography,
  selectedCalendarTypography,
  useStyles,
} from "./DashboardStyles";
import { PieChart, Pie, Cell } from "recharts";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { BarChart } from "@mui/x-charts/BarChart";
import axios from "axios";
import { endpoints } from "../../../apiEndpoints";
import { Loader } from "../../../utils/Loader";
import { UserTypesNum } from "../../../enums/UserTypes";
import DateFormatter from "../../../utils/DateFormatter";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [analytics, setAnalytics] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [loading, setLoading] = useState(true);
  const options = ["Yearly", "Half Year", "Quarterly"];
  const [selected, setSelected] = useState(options[0]); // Default to the first option
  const earnings = [
    { text: "Total Earned", numbers: analytics?.total_earned ?? 0 },
    { text: "Cleared PDC", numbers: analytics?.cleared_pdc ?? 0 },
    { text: "Deposited PDC", numbers: analytics?.deposited_pdc ?? 0 },
    { text: "Bounced PDC", numbers: analytics?.bounced_pdc ?? 0 },
  ];

  

  // For PieChart
  const pdcData = [
    {
      name: "Cleared PDC",
      value: analytics?.pdc_status?.cleared?.percentage ?? 0,
      color: "#B81918",
    },
    {
      name: "Deposited PDC",
      value: analytics?.pdc_status?.deposited?.percentage ?? 0,
      color: "#737679",
    },
    {
      name: "Bounced PDC",
      value: analytics?.pdc_status?.bounced?.percentage ?? 0,
      color: "#ADADAD",
    },
  ];
  

  const contractsData = [
    {
      name: "Renewed",
      value: analytics?.contract_status?.renewed?.count ?? 0,
      color: "#B81918",
    },
    {
      name: "Expired",
      value: analytics?.contract_status?.expired?.count ?? 0,
      color: "#737679",
    },
    {
      name: "Terminated",
      value: analytics?.contract_status?.terminated?.count ?? 0,
      color: "#ADADAD",
    },
  ];

  // For BarChart
  // const occupied = [40, 2, 75, 10, 5, 80, 55, 10, 20, 40, 5, 10];
  const occupied = analytics?.property_status?.map(
    (proeprty) => proeprty.occupied
  );
  // const vacant = [75, 20, 4, 60, 1, 30, 15, 20, 10, 20, 3, 75];
  const vacant = analytics?.property_status?.map((property) => property.vacant);

  // const xLabels = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];

  const xLabels = analytics?.property_status?.map((property) => property.month);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));

  let width = 900;
  let height = 400;

  if (isXs) {
    width = 300;
    height = 200;
  } else if (isSm) {
    width = 500;
    height = 300;
  } else if (isMd) {
    width = 700;
    height = 350;
  } else if (isLg) {
    width = 900;
    height = 400;
  } else if (isXl) {
    width = 1100;
    height = 450;
  }

  // To select Monthly, Quarter, Half Year, Yearly
  const handleSelect = (type) => {
    setSelected(type);
    getDashboardAnalytics(type);
  };

  

  // For PieChart Style
  const CustomLegend = () => (
    <Paper elevation={0}>
      {pdcData?.map((entry, index) => (
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: entry.color,
              marginRight: 8,
              borderRadius: 5,
            }}
          />
          {entry.name}
        </div>
      ))}
    </Paper>
  );

  const CustomLegendContracts = () => (
    <Paper elevation={0}>
      {contractsData?.map((entry, index) => (
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: entry.color,
              marginRight: 8,
              borderRadius: 5,
            }}
          />
          {entry.name}
        </div>
      ))}
    </Paper>
  );

  // New renderLabel function that only applies to the 'Occupied' segment.
  const renderLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    // index
  }) => {
    // if (percent === 1000) return; // Only for the 'Occupied' segment
    // const entry = pdcData[index]

    return (
      <text
        x={cx}
        y={cy}
        fill="black"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {`${Math.floor(
          analytics?.pdc_status?.cleared?.percentage
        )}% Cleared` || "N/A"}
         {/* {`${Math.floor(percent * 100)}% ${entry.name}` || "N/A"} */}
      </text>
    );
  };

  const renderLabelContracts = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    // if (percent !== 0.58) return; // Only for the 'Occupied' segment

    return (
      <text
        x={cx}
        y={cy}
        fill="black"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {/* {"2000 Cleared"} */}
        {`${analytics?.contract_status?.renewed?.count} Renewed` || "N/A"}
      </text>
    );
  };




  const getDashboardAnalytics = async (type) => {
    try {
      setLoading(true)
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      let url;
      if (type === "Quarterly") {
        url = `${endpoints.financeDashboardAnalytics}?filter=quarter`;
      } else if (type === "Half Year") {
        url = `${endpoints.financeDashboardAnalytics}?filter=half_year`;
      } else if (type === "Yearly") {
        url = `${endpoints.financeDashboardAnalytics}?filter=year`;
      } else {
        url = endpoints.financeDashboardAnalytics;
      }
      const response = await axios.get(url, requestOptions);
      setAnalytics(response.data.data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const getNotifications = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        `${endpoints.getNotifications}?user_type=${UserTypesNum.finance
        }&user_id=${localStorage.getItem("id")}`,
        requestOptions
      );
      setNotifications(response.data.data);
    } catch {
    } finally {
    }
  };

  useEffect(() => {
    getDashboardAnalytics();
    getNotifications();
  }, []);

  return (
    <>
      <Box>
        <Typography sx={mainTypography}>Dashboard</Typography>
      </Box>
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'50vh'}>
          <Loader />
        </Box>
      ) : (
        <>
          <Box className={classes.calendarBox}>
            {options?.map((item) => (
              <Typography
                key={item}
                sx={{
                  ...calendarTypography,
                  ...(selected === item && selectedCalendarTypography),
                }}
                onClick={() => handleSelect(item)}
              >
                {item}
              </Typography>
            ))}
          </Box>
          <Grid container spacing={2} marginTop={"1rem"}>
            {earnings?.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box className={classes.gridBox}>
                  <Typography sx={gridItemText}>{item.text}</Typography>
                  <Typography sx={gridItemNumbers}>{item.numbers}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box className={classes.mainBox}>
            <Box className={classes.pdcBox}>
              <Box className={classes.pdcSubBox}>
                <Box display="flex" justifyContent="space-between">
                  <Typography sx={boxHeading}>PDCs</Typography>
                  <Typography sx={boxSubHeading} onClick={() => { navigate('/finance/pdcs') }}>See All</Typography>
                </Box>
                {/* PDC pie Chart */}
                {pdcData?.some((pdc)=>pdc.value !== 0) ? ( 
                <Box display={"flex"} alignItems={"center"}>
                  <PieChart width={280} height={280} className={classes.chart} >
                    <Pie
                      data={pdcData}
                      cx={125}
                      cy={124.5}
                      innerRadius={70}
                      outerRadius={95}
                      fill="#8884d8"
                      dataKey="value"
                      label={renderLabel}
                      labelLine={false}
                    >
                      {pdcData?.map((entry, index) => (
                        <Cell key={`cell-${entry}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                  <Box>
                    <Typography
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: 500,
                        color: "rgba(115, 115, 115, 1)",
                        margin: "0px 0px 0px 20px",
                      }}
                    >
                      Status
                    </Typography>
                    <Divider width={131} style={{ marginBottom: "5px" }} />
                    <CustomLegend data={pdcData} />
                  </Box>
                </Box>
                ): (<Box display={'flex'} justifyContent={'center'} alignItems={'flex-end'} height={'10rem'} >
                  <Typography>No data available</Typography>
                </Box>) }
              </Box>
              <Box className={classes.pdcSubBox}>
                <Box display="flex" justifyContent="space-between">
                  <Typography sx={boxHeading}>Contracts</Typography>
                  <Typography sx={boxSubHeading} onClick={() => { navigate('/finance/contracts') }}>See All</Typography>
                </Box>
                {/* Contracts Pie chart */}
                {/* {contractsData && contractsData?.contract_status?.renewed?.count !== 0 || contractsData?.contract_status?.expired?.count !== 0  || contractsData?.contract_status?.terminated?.count !== 0 ? ( */}
                {contractsData?.some((contract)=>contract.value !==0) ? (
                <Box display={"flex"} alignItems={"center"}>
                  
                  <PieChart width={280} height={280} className={classes.chart}>
                    <Pie
                      data={contractsData}
                      cx={125}
                      cy={124.5}
                      innerRadius={70}
                      outerRadius={95}
                      fill="#8884d8"
                      dataKey="value"
                      label={renderLabelContracts}
                      labelLine={false}
                    >
                      {contractsData?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                  <Box>
                    <Typography
                      style={{
                        font: "Outfit",
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: 500,
                        color: "rgba(115, 115, 115, 1)",
                        margin: "0px 0px 0px 20px",
                      }}
                    >
                      Status
                    </Typography>
                    <Divider width={131} style={{ marginBottom: "5px" }} />
                    <CustomLegendContracts data={contractsData} />
                  </Box>
                </Box>
                ):(
                  <Box display={'flex'} justifyContent={'center'} alignItems={'flex-end'} height={'10rem'} >
                  <Typography>No data available</Typography>
                </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.notificationBox}>
              <Box className={classes.notificationSubBox}>
                <Typography sx={boxHeading}>Notification Overview</Typography>
                {notifications && notifications?.length > 0 ? (
                  <Typography sx={boxSubHeading} onClick={() => { navigate('/finance/notification') }}>See All</Typography>
                ) : ('')}
              </Box>
              <Grid container marginTop={"1rem"} gap={"0.5rem"}>
                {notifications && notifications?.length > 0 ? (
                  notifications?.map((notification) => (
                    <Grid
                      item
                      xs={12}
                      key={notification.id}
                      className={classes.notificationGridItem}
                    >
                      <Box>
                        <Avatar src="/image/profile.jpg" />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontFamily: "Outfit",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "22px",
                          }}
                        >
                          {notification.title || 'Untitled'}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Outfit",
                            fontWeight: "400",
                            fontSize: "11px",
                            color: "#ADADAD",
                          }}
                        >
                          {DateFormatter(notification.created_at)}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "Outfit",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "22px",
                      textAlign: "center",
                      color: "#ADADAD",
                    }}
                  >
                    No notifications
                  </Typography>
                )
                }

              </Grid>
            </Box>
          </Box>
          <Box
            border={"1px solid #EFEFEF"}
            borderRadius={"6px"}
            padding={"1rem"}
            marginBottom={"1rem"}
          >
            <Typography sx={boxHeading}>Property Status</Typography>
            <BarChart
              width={width}
              height={height}
              series={[
                {
                  data: occupied,
                  label: "occupied",
                  id: "pvId",
                  color: "#B81918",
                },
                { data: vacant, label: "vacant", id: "uvId", color: "#ADADAD" },
              ]}
              xAxis={[{ data: xLabels, scaleType: "band" }]}
              yAxis={[{ min: 0, max: analytics?.total_properties || "10" }]}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default Dashboard;
