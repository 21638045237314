// code working process

import { createSlice } from '@reduxjs/toolkit';

const initialState = {

    unitData: {},
    umediaData: {},
    uploadedImagesUnit: [],
    udetailData: {},
    additionalFields: [
        { title: '', value: '' }
    ],
    ufeatureData: {},
    ulocationData: {},
    utourData: {},
    floorData: [{ title: '', price: '', postfix: '', planSize: '', description: '' }],
    images: {},

    uploadedDocuments: [],

    usubfloorData: {},
    unoteData: {}
};

const unitSlice = createSlice({
    name: 'unit',
    initialState,
    reducers: {
        updateUnitData(state, action) {
            state.unitData = action.payload;
        },
        updateUmediaData(state, action) {
            state.umediaData = action.payload;
        },
        uploadImageUnits(state, action) {
            const file = action.payload;
            console.log("file: ", file)
            state.uploadedImagesUnit = [...state.uploadedImagesUnit, file];
        },
        clearUploadedImages(state) {
            state.uploadedImagesUnit = {};
        },
        updateUdetailData(state, action) {
            state.udetailData = action.payload;
        },
        addAdditionalField(state, action) {
            state.additionalFields.push(action.payload); // Add a new field
        },
        updateAdditionalField(state, action) {
            const { index, data } = action.payload;
            state.additionalFields[index] = { ...state.additionalFields[index], ...data }; // Update specific field
        },


        updateUfeatureData(state, action) {
            state.ufeatureData = action.payload;
        },
        updateUlocationData(state, action) {
            state.ulocationData = action.payload;
        },
        updateUtourData(state, action) {
            state.utourData = action.payload;
        },

        updateFloorData(state, action) {
            state.floorData = action.payload.map(field =>
                field.id === action.payload.id ? { ...field } : field
            );
        },

        addFloorField(state) {
            const newField = {
                id: state.floorData.length,
                title: '',
                bedroom: '',
                bathroom: '',
                price: '',
                postfix: '',
                plan: '',
                description: '',
            };
            state.floorData = [...state.floorData, newField];
        },

        uploadFloorImage(state, action) {
            const { id, file } = action.payload;
            state.images[id] = file;
        },

        updateUnoteData(state, action) {
            state.unoteData = action.payload;
        },
        uploadDocument(state, action) {
            const file = action.payload;
            state.uploadedDocuments.push(file); // Store the uploaded document file
        },

        clearUploadedDocuments(state) {
            state.uploadedDocuments = []; // Clear all uploaded documents
        },


        // resetFormData(state) {
        //     Object.keys(initialState).forEach(key => {
        //         state[key] = initialState[key];
        //     });
        // },


        resetFormData: (state) => {
            console.log("Resetting state to initialState");
            return initialState; // Directly return the initial state
        },
    }
});


export const {
    updateUnitData, updateUmediaData, updateUdetailData, updateUfeatureData,
    updateUlocationData, updateUtourData, updateUnoteData,
    uploadImageUnits,
    clearUploadedImages,
    addAdditionalField,
    updateAdditionalField,
    updateFloorData,
    addFloorField,
    uploadFloorImage,
    uploadDocument, clearUploadedDocuments,


    resetFormData
} = unitSlice.actions;

export default unitSlice.reducer;


