import React from "react";
import { useState } from "react";
import { Box, Typography, Button, Grid, Tabs, Tab } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useStyles } from "../Properties/Propertie/PropertieStyle";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "./Table";
import RenewalNotice from "./RenewalNotice";
import RenewForm from "./RenewForm";

const landlordColumns = [
  { id: "CName", label: "Contract Name" },
  { id: "LName", label: "Landlord Name" },
  { id: "property", label: "Property" },
  { id: "Status", label: "Status" },
  { id: "Action", label: "Action" },
];

const tenantColumns = [
  { id: "CName", label: "Contract Name" },
  { id: "TName", label: "Tenant Name" },
  { id: "Unit", label: "Unit" },
  { id: "Status", label: "Status" },
  { id: "Action", label: "Action" },
];

const Landlord = [
  {
    id: "0",
    CName: "Contract 1",
    LName: "Landlord 1",
    property: "Property Name",
    Status: "Accepted",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
  {
    id: "1",
    CName: "Contract 1",
    LName: "Landlord 1",
    property: "Property Name",
    Status: "Pending",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
  {
    id: "2",
    CName: "Contract 1",
    LName: "Landlord 1",
    property: "Property Name",
    Status: "Accepted",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
  {
    id: "3",
    CName: "Contract 1",
    LName: "Landlord 1",
    property: "Property Name",
    Status: "Accepted",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
  {
    id: "5",
    CName: "Contract 1",
    LName: "Landlord 1",
    property: "Property Name",
    Status: "pending",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
  {
    id: "6",
    CName: "Contract 1",
    LName: "Landlord 1",
    property: "Property Name",
    Status: "Pending",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
];
const Tenants = [
  {
    id: "0",
    CName: "Contract 1",
    TName: "Tenant 1",
    Unit: "305",
    Status: "Accepted",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
  {
    id: "1",
    CName: "Contract 1",
    TName: "Tenant 1",
    Unit: "305",
    Status: "Pending",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
  {
    id: "2",
    CName: "Contract 1",
    TName: "Tenant 1",
    Unit: "305",
    Status: "Accepted",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
  {
    id: "3",
    CName: "Contract 1",
    TName: "Tenant 1",
    Unit: "305",
    Status: "Accepted",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
  {
    id: "4",
    CName: "Contract 1",
    TName: "Tenant 1",
    Unit: "305",
    Status: "Pending",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
  {
    id: "5",
    CName: "Contract 1",
    TName: "Tenant 1",
    Unit: "305",
    Status: "Pending",
    Action: (
      <>
        <EditNoteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
        <DeleteIcon style={{ color: "rgba(184, 25, 24, 1)" }} />
      </>
    ),
  },
];

const RenewContract = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [secondSelectValue, setSecondSelectValue] = useState("");
  const [thirdSelectValue, setThirdSelectValue] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setopen] = useState(false);
  const [New, setNew] = useState(false);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const TabPanel = ({ children, value, index }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ padding: "20px 0px 0px 0px" }}>{children}</Box>
      )}
    </div>
  );

  const a11yProps = (index) => ({
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  });

  const handleNotice = () => {
    setNew(true);
  };

  if (New) {
    return <RenewalNotice />;
  }

  const handlerenew = () => {
    setopen(true);
  };

  if (open) {
    return <RenewForm />;
  }

  return (
    <>
      <Box sx={{ width: "100%", padding: 2 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} lg={true}>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              Contracts Renewal
            </Typography>
          </Grid>
          <Grid item xs={12} lg="auto">
            <Button
              sx={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "100%",
                // maxWidth: "170px",
                height: "30px",
                marginRight: "10px",
                "&:hover": {
                  backgroundColor: "rgba(184, 25, 24, 1)",
                },
              }}
              variant="contained"
              onClick={handlerenew}
            >
              Renew Contract
            </Button>
          </Grid>
          <Grid item xs={12} lg="auto">
            <Button
              sx={{
                backgroundColor: "rgba(184, 25, 24, 1)",
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                textTransform: "none",
                width: "100%",
                // maxWidth: "170px",
                height: "30px",
                "&:hover": {
                  backgroundColor: "rgba(184, 25, 24, 1)",
                },
              }}
              variant="contained"
              onClick={handleNotice}
            >
              Bulk Renewal Notice
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* < Box className={classes.root} sx={{ flexGrow: 1 }
            }>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={4} lg={3}>
                        <div style={{ position: 'relative' }}>
                            <input
                                type="search"
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    border: '1px solid rgba(239, 239, 239, 1)',
                                    borderRadius: '30px',
                                    paddingLeft: '40px'
                                }}
                                placeholder="Search by tenant name"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                size="small"
                            />
                            <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
                                <SearchIcon />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <input type="search"
                            style={{
                                width: "100%",
                                height: "40px",
                                border: "1px solid rgba(239, 239, 239, 1)",
                                borderRadius: "30px",
                                padding: "0px 0px 0px 20px"

                            }}
                            placeholder="Search by Unit/Property"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <input type="search"
                            style={{
                                width: "100%",
                                height: "40px",
                                border: "1px solid rgba(239, 239, 239, 1)",
                                borderRadius: "30px",
                                padding: "0px 0px 0px 20px"

                            }}
                            placeholder="Search By Date"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                        <Button
                            fullWidth
                            style={{
                                border: "1px solid rgba(184, 25, 24, 1)",
                                borderRadius: "30px",
                                color: "rgba(184, 25, 24, 1)"
                            }}
                            variant="outlined"
                            onClick={handleApplyFilter}
                        >
                            Apply Filter
                        </Button>
                    </Grid>
                </Grid>
            </Box > */}

      <Box>
        <Typography
          style={{
            fontFamily: "Outfit",
            fontSize: "22px",
            lineHeight: "22px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          History
        </Typography>
      </Box>

      <Box style={{ margin: "18px 0px 0px 0px" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="navigation tabs"
          TabIndicatorProps={{
            style: { backgroundColor: "rgba(184, 25, 24, 1)", height: "1px" },
          }}
        >
          <Tab
            style={{
              color: selectedTab === 0 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 0 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Tenants"
            {...a11yProps(0)}
          />
          {/* <Tab
            style={{
              color: selectedTab === 1 ? "rgba(184, 25, 24, 1)" : "inherit",
              borderBottom:
                selectedTab === 1 ? "2px solid rgba(184, 25, 24, 1)" : "none",
            }}
            label="Landlord"
            {...a11yProps(1)}
          /> */}
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <DataTable Data={"TenantRenewal"} columns={tenantColumns} />
        </TabPanel>
        {/* <TabPanel value={selectedTab} index={1}>
          <DataTable Data={"LandlordRenewal"} columns={landlordColumns} />
        </TabPanel> */}
      </Box>
    </>
  );
};

export default RenewContract;
